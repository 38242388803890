import React, { useEffect, useState } from "react";
import { Table } from 'reactstrap';

import { isEmpty } from 'lodash';

import { notificationServices } from 'js/services';
import { PaginationComponent, ListLoader } from 'js/components/common';
import { useParamChange } from 'js/hooks';

export const NotificationList = (props) => {
    const [notifications, setNotifications] = useState({});
    const [loading, setLoading] = useState(false);


    const apiCalls = {
        getAll: (params) => {
            setLoading(true);
            notificationServices.getAll(params).then((response) => {
                setNotifications(response);
                setLoading(false);
            }).catch((err) => {
                setLoading(false);
            });
        },
    }

    // eslint-disable-next-line
    const [params, onParamsChange] = useParamChange(apiCalls.getAll);

    useEffect(() => {
        apiCalls.getAll();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {
                props.type === 'admin' ?
                    <div className="generic-page-header">
                        <div className="header-data-section">
                            <h2 className="page-head my-0">Notifications</h2>
                        </div>
                    </div>
                    :
                    <div className="generic-page-header">
                        <div className="header-data-section">

                        </div>
                    </div>
            }
            <div className="tt-admin-card">

                <div className="tt-admin-card--data">
                    <div className="table-wrap">
                        {loading ?
                            <ListLoader />
                            :
                            <Table responsive className="tt-admin-table-compact tt-admin-table-big-data ">
                                <thead>
                                    <tr>
                                        {/* {
                                            props.type === 'admin' &&
                                            <th>User Name</th>
                                        } */}
                                        <th>Notification</th>
                                        {/* <th style={{ width: '25%' }}>Trip 2</th>
                                        <th>Description</th>
                                        <th className="sortable">Status</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {

                                        notifications && !isEmpty(notifications.data) && notifications.data.map((notification) => (
                                            notification.data &&
                                            <tr key={`trade_${notification.trade_id}`}>
                                                {/* {
                                                    props.type === 'admin' &&
                                                    <td>{
                                                        !isEmpty(notification.data.properties) ? notification.data.properties.full_name : ''
                                                    }</td>
                                                } */}
                                                <td>
                                                    {
                                                        notification.data && notification.data.message ? notification.data.message : ''
                                                    }
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </Table>
                        }
                    </div>

                </div>
                <div className="tt-admin-card--footer tt-admin-card--footer--pagination">
                    <div className="tt-admin-pagination">
                        {notifications && notifications.meta &&
                            <PaginationComponent page={notifications.meta} onChange={(page) => onParamsChange('page', page)} />
                        }
                    </div>
                </div>
            </div>
        </>
    );
}
