import React, { useEffect, useState } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col, Button } from 'reactstrap';
import moment from 'moment';
import { isEmpty, cloneDeep } from 'lodash';

import { reportServices } from 'js/services';
import { ListLoader, DateFilterComponent } from 'js/components/common';
import { TotalListingsReport } from 'js/components/admin/components/reports/trips/components';


export const ParamProvider = React.createContext({
    params: {},
    setParams: () => { }
});

export const TripsReport = () => {


    const [loading, setLoading] = useState(false);
    const [trip_reports, setTripReports] = useState({});
    const [state, setState] = useState({
        activeTab: 'trip_categories'
    })

    const [params, setParams] = useState({
        start_date: moment().subtract(7, 'days').format('YYYY-MM-DD'),
        end_date: moment().format('YYYY-MM-DD'),
        type: 'trip_categories'
    });


    const apiCalls = {
        getTripReports: (params) => {
            setLoading(true);
            reportServices.tripReport(params).then((response) => {
                setTripReports(cloneDeep(response));
                setLoading(false);
            }).catch((err) => {
                setLoading(false);
                setTripReports({});
            });
        },
    }

    const value = { params, setParams };


    useEffect(() => {
        apiCalls.getTripReports(params);

        setState((state) => ({
            ...state,
            activeTab: params.type
        }))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params]);


    const exportToExcel = () => {
        reportServices.exportToExcel(params, 'trips').then((response) => {
            window.open(response);
        });
    }

    return (
        <div>
            <div className="generic-page-header">
                <div className="header-data-section">
                    <h2 className="page-head my-0">Reports / Trips</h2>
                </div>
                <div className="header-data-section">
                    {trip_reports && trip_reports.data && trip_reports.data.length > 0 ?
                    <Button
                        color="secondary"
                        className="export-to-excel-btn mr-3"
                        onClick={() => exportToExcel()}
                    >
                        <i>
                            <svg width="19" height="17" viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M14.728 12.8206H11.9003L9.97152 10.0926L7.94478 12.8206H5.10477L8.62819 8.38936L5.78315 4.51387H8.6517L9.98439 6.62121L11.4044 4.51387H14.3233L11.3406 8.38936L14.728 12.8206ZM18.4585 16.6071H1.66699V0.570107H18.4585V16.6071ZM3.2801 14.994H16.8426V2.18433H3.2801" fill="#10C56E" />
                            </svg>
                        </i>Export to Excel
                    </Button> : ''
                    }
                    <DateFilterComponent params={ params } onChange={ (data) => setParams(data)}/>
                </div>
            </div>
            <Row>
                <Col xs="12">
                    <div className="">
                        {
                            loading ?
                                <ListLoader />
                                :

                                <div>
                                    {
                                        !isEmpty(trip_reports) && trip_reports.all_counts &&
                                        <Nav tabs className="tt-dashboard-tabs">
                                            <NavItem>
                                                <NavLink>
                                                    <div className="tab-label"><img src="/images/admin/dashboard/total_listings.svg" alt="" /> Total Listings</div>
                                                    <div className="tab-count">{trip_reports.all_counts.total_listings ? trip_reports.all_counts.total_listings : '0'}</div>
                                                </NavLink>
                                            </NavItem>

                                            <NavItem>
                                                <NavLink>
                                                    <div className="tab-label"><img src="/images/admin/dashboard/total_countries.svg" alt="" /> Total Countries</div>
                                                    <div className="tab-count">{trip_reports.all_counts.total_countries ? trip_reports.all_counts.total_countries : '0'}</div>
                                                </NavLink>
                                            </NavItem>

                                            <NavItem>
                                                <NavLink>
                                                    <div className="tab-label"><img src="/images/admin/dashboard/total_states.svg" alt="" /> Total States</div>
                                                    <div className="tab-count">{trip_reports.all_counts.total_states ? trip_reports.all_counts.total_states : '0'}</div>
                                                </NavLink>
                                            </NavItem>

                                            <NavItem>
                                                <NavLink>
                                                    <div className="tab-label"><img src="/images/admin/dashboard/total_active_users.svg" alt="" /> Total Active Users</div>
                                                    <div className="tab-count">{trip_reports.all_counts.total_active_users ? trip_reports.all_counts.total_active_users : '0'}</div>
                                                </NavLink>
                                            </NavItem>
                                        </Nav>
                                    }
                                    <ParamProvider.Provider value={value}>
                                        <TabContent activeTab={state.activeTab}>
                                            <TabPane tabId={params.type}>
                                                {
                                                    <TotalListingsReport
                                                        type={params.type}
                                                        table_data={trip_reports && cloneDeep(trip_reports.data)}
                                                        map_data={trip_reports && cloneDeep(trip_reports.map_data)}
                                                    />
                                                }

                                            </TabPane>
                                        </TabContent>
                                    </ParamProvider.Provider>
                                </div>
                        }
                    </div>
                </Col>
            </Row>
        </div>
    );
}


