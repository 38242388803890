import React, { useState, useEffect, useContext } from "react";
import { Row, Col, Button, Nav, NavItem, Navbar, NavbarToggler, Collapse, Input } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import classNames from 'classnames';
import { toast } from "react-toastify";
import { find, debounce, concat, first, slice, truncate } from 'lodash';
import moment from 'moment';

import { history } from 'js/helpers';
import { LookupContext } from 'js/contexts';
import BlogCard from "js/components/legos/blogCard";
import { LoadMoreComponent } from 'js/components/common';
import { cmsServices } from 'js/services';
import { useRef } from "react";


export const BlogHome = (props) => {

    const lookups = useContext(LookupContext);

    let mounted = useRef();

    const category = props.match && props.match.params && props.match.params.category ? props.match.params.category : 'recent';

    const search = props.match && props.match.params && props.match.params.search ? props.match.params.search : '';

    const [params, setParam] = useState({
        status: 20
    });
    const [articles, setArticles] = useState({});
    const [recent, setRecent] = useState({});
    const [ state, setState ] = useState({
        is_search_open: search ? true :false,
        search: search
    });

    const getArticles = (params, mode) => {
        cmsServices.getArticles(params)
            .then((response) => {
                if (!mounted.current) return;
                if (mode === 'append' && articles.data) {
                    response.data = concat(articles.data, response.data);
                    setArticles(response);
                }
                else {
                    setArticles(response);
                }
            }).catch(() => {
                toast.error('Failed To Fetch Experiences');
            });
    };
    const getRecentArticles = (params) => {
        cmsServices.getRecentArticles(params)
            .then((response) => {
                if (!mounted.current) return;
                setRecent(response);
            }).catch(() => {
                toast.error('Failed To Fetch Experiences');
            });
    };

    useEffect(() => {
        mounted.current = true;
        if( lookups.article_categories ){
            if (category && category !== 'recent') {
                let category_item = find(lookups.article_categories, { 'slug': category });
                if (category_item) {
                    let new_params = {
                        ...params,
                        category: category_item.article_category_id
                    };
                    setParam(new_params);
                    getRecentArticles(params);
                    getArticles(new_params);
                }
            }
            else if( search ){
                getArticles({search});
                getRecentArticles(params);
            }
            else{
                getArticles(params);
                getRecentArticles(params);
            }
        }
        return () => {
            mounted.current = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if( search ){
            getArticles({search});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    const changeParam = debounce(( key, value ) => {
        let new_params = params;
        !value ? delete new_params[key] : new_params[key] = value;
        key !== 'page' && delete new_params['page'];
        if (!mounted.current) return;
        setParam(new_params);
        getArticles(new_params);
    }, 300);

    const loadMore = debounce((page) => {
        let new_params = { ...params, page: page };
        if (!mounted.current) return;
        setParam(new_params);
        getArticles(new_params, 'append');
    }, 300);

    const onSearch = ( e ) => {
        setState({ ...state, search : e.target.value });
    }
    const onKeyPress = (e ) => {
        if (e.key === "Enter") {
            history.push(`/experience/search/${state.search}`);
        }
    }

    let featured_article = recent && recent.data && first( recent.data );
    let top_articles = recent && recent.data && slice( recent.data, 1,4 );
    return (
        <div>
            <Helmet>
                <title>Blog - Trips4Trade</title>
                <meta name="description" content="Blogs - Trips4Trade." />
                <meta name="keywords" content="Trips4Trade,blog,outdoor,sports" />
            </Helmet>

            <div className="inner-banner-blog" style={{ backgroundImage: `url(/images/web/global/banners/static-banner.png)` }}>
                <div className="container">
                    <Row>
                        {featured_article &&
                            <Col xs="12">
                                <div className="featured-blog">
                                    <div className="blog-data-head">
                                        {
                                            <div className="blog-img">
                                                <img src={featured_article.attachment ?
                                                featured_article.attachment.thumbnail_url ?
                                                featured_article.attachment.thumbnail_url : featured_article.attachment.attachment_url
                                                :''} alt="" />
                                            </div>
                                        }
                                        <h2>{featured_article.title} </h2>
                                    </div>
                                    <Button onClick={() => history.push(`/experience/detail/${featured_article.slug}`)} className="btn btn-outline">Read</Button>
                                </div>
                            </Col>
                        }
                    </Row>
                    <Row className="mini-blog-post">
                        {top_articles && top_articles.map((item, index) => (
                            <Col xs="12" md="4" key={index}>
                                <Link to={`/experience/detail/${item.slug}`} className="featured-mini-card">
                                    {
                                        <div className="blog-img">
                                            <img src={item.attachment ? item.attachment.thumbnail_url?item.attachment.thumbnail_url:item.attachment.attachment_url :''} alt="" />
                                        </div>
                                    }
                                    <h2>{item.title}</h2>
                                </Link>
                            </Col>
                        ))}
                    </Row>
                </div>
            </div>
            <div className="wider-data-frame wider-data-frame--blog-sub-nav">
                <div className="container">
                    <Navbar expand="lg">
                        { !search &&
                        <>
                            <NavbarToggler onClick={()=>setState({ ...state, is_menu_open:!state.is_menu_open })} />
                            <Collapse isOpen={state.is_menu_open} navbar>
                                <Nav className="mr-auto" navbar>
                                    <NavItem active={category === 'recent' ? true : false } >
                                        <Link
                                            to={{
                                                pathname:`/experience/recent`,
                                                state: { skip_scrol_to_top: true }
                                            }}
                                            onClick={ () => changeParam('category', '')}
                                            className="nav-link"
                                        >Most Recent</Link>
                                    </NavItem>
                                    { lookups.article_categories && lookups.article_categories.map( (item, index) => (
                                    <NavItem key={index} active={ category === item.slug }>
                                        <Link
                                            to={{
                                                pathname:`/experience/${item.slug}`,
                                                state: { skip_scrol_to_top: true }
                                            }}
                                            onClick={ () => changeParam('category', item.article_category_id)}
                                            className="nav-link"
                                        >
                                            {item.label}
                                        </Link>
                                    </NavItem>
                                    ))}
                                </Nav>
                            </Collapse>
                        </>
                        }
                        <div className="blog-search-wrap" >
                            <div className="d-flex align-items-center" >
                                <Input
                                    type="text"
                                    className={classNames("tt-search-input", { "input-active": state.is_search_open  })}
                                    onChange={ (e) => onSearch(e) }
                                    onKeyPress={onKeyPress}
                                    value={state.search}
                                />
                                <Button className="btn btn-search btn-search-nav-js" onClick={() => setState({ ...state, is_search_open: !state.is_search_open })} type="button"></Button>
                            </div>
                        </div>
                    </Navbar>
                </div>
            </div>
            <div className="wider-data-frame wider-data-frame--grey">
                <div className="container">
                    { search &&
                        <Row>
                            <Col xs="12" className="search-head">
                                <h3 className="header4 text-uppercase">Search Results for
                                    <span>: </span>
                                    <em>{search}</em>
                                </h3>
                            </Col>
                        </Row>
                    }
                    <Row>
                        {articles && articles.data && articles.data.map((item, index) => (
                            <Col xs="12" md="6" lg="4" key={index}>
                                <BlogCard
                                    blogLink={`/experience/detail/${item.slug}`}
                                    blogDate={item.published && moment(item.published).format("MMM D, YYYY")}
                                    blogTimer={item.read_time}
                                    blogTitle={item.title}
                                    blogText={truncate(item.text_description, { 'length': 120, 'separator': /,? +/ })}
                                    blogImage={item.attachment ? item.attachment.thumbnail_url?item.attachment.thumbnail_url:item.attachment.attachment_url :''}
                                />
                            </Col>
                        ))}
                    </Row>
                    {articles && articles.meta &&
                        <LoadMoreComponent page={articles.meta} onChange={(page) => loadMore(page)} />
                    }
                </div>
            </div>
        </div>


    );
}
