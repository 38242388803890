import React, { useState, useEffect, useContext } from "react";
import { Row, Col, Button, Input, Table, Form, FormGroup, CustomInput } from 'reactstrap';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import ReactTooltip from 'react-tooltip';
import { forEach, keys, isEmpty, last } from 'lodash';

import { history } from 'js/helpers';
import { leadsServices, userServices } from 'js/services';
import { PaginationComponent, ListLoader } from 'js/components/common';
import { LookupContext } from 'js/contexts';
import { useParamChange, useModal } from 'js/hooks';
import { toast } from "react-toastify";

const styles = {
    option: (styles, state) => ({
        ...styles,
        backgroundColor: state.isSelected ? "#FFA405 " : state.isFocused ? "#FFA40520" : ""
    })
};

export const Leads = (props) => {
    const [leads, setLeads] = useState([]);
    const [state, setState] = useState({
        is_modal_open: false,
        lead_id: null,
        selected_lead: {},
    });
    const [loading, setLoading] = useState(false);
    const lookups = useContext(LookupContext);
    const [show, hide] = useModal();
    const bulk_action_lookup = [
        { value: 'delete', label: 'Delete' },
    ];

    const image_url_status = {
        registered: 'success.svg',
        pending: 'warning.svg',
        rejected: 'error.svg'
    }

    const apiCalls = {
        getAllLeads: (params) => {
            setLoading(true);
            leadsServices.getAll(params).then((response) => {
                setLeads(response);
                setLoading(false);

            }).catch((err) => {
                setLoading(false);
            });
        },
        bulkAction: (details) => {
            setLoading(true);
            leadsServices.bulkAction(details)
                .then(() => {
                    setLoading(false);
                    hide('ConfirmModal');
                    apiCalls.getAllLeads(params);
                    setState({ ...state, selected_lead: {}, action_type: null, select_all: false });
                })
                .catch(() => {
                    setLoading(false);
                    setState({ ...state, action_type: null });
                })
        },
        deleteLead: (lead_id) => {
            leadsServices.remove(lead_id).then(() => {
                apiCalls.getAllLeads(params);
                hide('ConfirmModal');
                toast.success('Deleted Successfully');
            }).catch((err) => {
                toast.error('Failed to delete')
            })
        }
    }

    useEffect(() => {
        const { location: { state } } = props;
        if (state && state.current_page) {
            onParamsChange('page', props.location.state.current_page);
        }
        else {
            apiCalls.getAllLeads();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [params, onParamsChange] = useParamChange(apiCalls.getAllLeads);

    const exportToExcel = () => {
        leadsServices.exportToExcel(params).then((response) => {
            window.open(response);
        });
    }

    const bulkAction = (value) => {
        let selected_lead_keys = keys(state.selected_lead);
        let selected_items_array = [];

        forEach(selected_lead_keys, (item) => {
            if (state.selected_lead[item]) {
                selected_items_array.push(item);
            }
        });

        if (value === 'delete') {
            if (!isEmpty(selected_items_array)) {
                let title = selected_items_array.length > 1 ? 'Delete Leads' : 'Delete Lead';
                show('ConfirmModal', {
                    title,
                    body: `Do you want to ${title} ?`,
                    button_name: 'Delete',
                    action: () => apiCalls.bulkAction({ action_type: value, items: selected_items_array }),
                    loading
                });
            }
        }
    }

    const selectAll = (value) => {
        let selected_lead = {};

        if (!isEmpty(leads.data)) {
            forEach(leads.data, (lead) => {
                selected_lead[lead.lead_id] = value;
            });
        }
        setState({ ...state, selected_lead, select_all: value });
    }

    const loadAdmins = (inputValue, callback) => {
        let params = { show_admins: true };
        if (inputValue !== '') {
            params.search = inputValue;
        }
        userServices.getAll(params)
            .then((response) => {
                let data = response && response.data ? response.data.map((item) => ({ label: item.full_name, value: item.user_id })) : [];
                callback(data);
            });
    };

    return (
        <div>
            <div className="generic-page-header">
                <div className="header-data-section">
                    <h2 className="page-head my-0">Leads</h2>
                </div>
                <div className="header-data-section">
                    {leads && leads.meta && leads.meta.total > 0 &&
                    <Button
                        color="secondary"
                        className="export-to-excel-btn mr-3"
                        onClick={() => exportToExcel()}
                    >
                        <i>
                            <svg width="19" height="17" viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M14.728 12.8206H11.9003L9.97152 10.0926L7.94478 12.8206H5.10477L8.62819 8.38936L5.78315 4.51387H8.6517L9.98439 6.62121L11.4044 4.51387H14.3233L11.3406 8.38936L14.728 12.8206ZM18.4585 16.6071H1.66699V0.570107H18.4585V16.6071ZM3.2801 14.994H16.8426V2.18433H3.2801" fill="#10C56E" />
                            </svg>
                        </i>Export to Excel
                    </Button>
                    }
                    <Button
                        color="primary"
                        className="add-new-btn"
                        onClick={() => history.push('/admin/leads/add-lead')}
                    >
                        <i>
                            <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.03027 7.77295H0.171875V5.6001H5.03027V0.619629H7.22754V5.6001H12.0981V7.77295H7.22754V12.729H5.03027V7.77295Z" fill="#fff" />
                            </svg>
                        </i>Add Lead</Button>
                </div>
            </div>
            <div className="tt-admin-card">
                <div className="tt-admin-card--header tt-admin-card--header_filter">
                    <div className="tt-filter">
                        <Form>
                            <Row className="justify-content-between">
                                <Col xs="12" lg="5">
                                    <Row>
                                        <Col xs="12" lg="6">
                                            <FormGroup>
                                                <div className="custom-react-select--no-border w-100">
                                                    <Select
                                                        placeholder="Bulk Action"
                                                        className="react-select-container"
                                                        classNamePrefix="react-select"
                                                        options={bulk_action_lookup}
                                                        styles={styles}
                                                        onChange={(e) => {
                                                            bulkAction(e.value);
                                                        }}
                                                    />
                                                </div>
                                            </FormGroup>
                                        </Col>
                                        <Col xs="12" lg="6">
                                            <FormGroup>
                                                <div className="filter-input--outer">
                                                    <Input
                                                        type="text"
                                                        name=""
                                                        id=""
                                                        placeholder="Search by Lead Name or Email"
                                                        onChange={(e) => onParamsChange('search', e.target.value)}
                                                    />
                                                </div>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs="12" lg="5">
                                    <Row>
                                        <Col xs="12" md="6">
                                            <FormGroup>
                                                <div className="custom-react-select--no-border w-100">
                                                    <AsyncSelect
                                                        placeholder="Filter by Admin"
                                                        cacheOptions
                                                        defaultOptions
                                                        loadOptions={loadAdmins}
                                                        className="react-select-container"
                                                        classNamePrefix="react-select"
                                                        styles={styles}
                                                        onChange={(e) => {
                                                            onParamsChange('support_user_id', e.value);
                                                        }
                                                        }
                                                    />
                                                </div>
                                            </FormGroup>
                                        </Col>
                                        <Col xs="12" md="6">
                                            <FormGroup>
                                                <div className="custom-react-select--no-border w-100">
                                                    <Select
                                                        placeholder="Status"
                                                        className="react-select-container"
                                                        classNamePrefix="react-select"
                                                        options={lookups.lead_status}
                                                        styles={styles}
                                                        onChange={(e) => {
                                                            onParamsChange('status', e.value);
                                                        }}
                                                    />
                                                </div>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </div>
                <div className="tt-admin-card--data">
                    <div className="table-wrap">
                        <Table responsive className="tt-admin-table-compact tt-admin-table-compact--checkbox ">
                            <thead>
                                <tr>
                                    <th>
                                        <div className="tt-admin-checkbox">
                                            <CustomInput
                                                type="checkbox"
                                                id="tt-check-lead-select-all"
                                                className="tt-checkbox"
                                                onChange={(e) => selectAll(e.target.checked)}
                                                checked={state.select_all ? state.select_all : false}
                                            />
                                        </div>
                                    </th>
                                    <th>Lead Name</th>
                                    <th>Email Address</th>
                                    <th>Admin (Sales Person)</th>
                                    <th>Phone Number</th>
                                    <th>Trip Inquiry</th>
                                    <th className="sortable" onClick={() => onParamsChange('sort', 'status')}>Status</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                            {loading ?
                                <tr>
                                    <td colSpan="7" align="center"><ListLoader /></td>
                                </tr>
                                :
                                leads && isEmpty(leads.data) ?
                                    <tr>
                                        <td colSpan="7" align="center">No Records Found.</td>
                                    </tr>
                                    :
                                    leads && leads.data && leads.data.map((lead, index) => (
                                        <tr key={`lead${index}`}>
                                            <td>
                                                <div
                                                    className="tt-admin-checkbox"
                                                >
                                                    <CustomInput
                                                        type="checkbox"
                                                        id={'tt-check' + lead.lead_id}
                                                        className="tt-checkbox"
                                                        onChange={(e) => {
                                                            setState({
                                                                ...state, selected_lead: { ...state.selected_lead, [lead.lead_id]: e.target.checked }
                                                            });
                                                        }}
                                                        checked={state.selected_lead[lead.lead_id] ? state.selected_lead[lead.lead_id] : false}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <Link
                                                    to={{
                                                        pathname: `/admin/leads/details/${lead.lead_id}`,
                                                        state: { current_page: params.page ? params.page : 1 }
                                                    }}
                                                >
                                                    {lead.full_name ? lead.full_name : ''}
                                                </Link>
                                            </td>
                                            <td>{lead.email ? lead.email : ''}</td>
                                            <td>{lead.support_user ? lead.support_user.full_name : '-'}</td>
                                            <td>{lead.phone ? lead.phone : ''}</td>
                                            <td>
                                                <span
                                                    data-tip="trip-inquiry"
                                                    data-for={`trip-enquiry${lead.lead_id}`} >
                                                    {lead.inquiries && last(lead.inquiries)?.trip?.trip_no?
                                                        last(lead.inquiries).trip.trip_no : '-'
                                                    }
                                                </span>
                                                {last(lead.inquiries)?.trip &&
                                                    <ReactTooltip
                                                        className='tt-admin-tooltip text-left'
                                                        aria-haspopup='true'
                                                        id={`trip-enquiry${lead.lead_id}`}>
                                                        <h4>{`${last(lead.inquiries).trip.title} - ${last(lead.inquiries).trip.trip_no}`}</h4>
                                                        {lead.inquiries.map((item, index) => (
                                                            <p key={index}>{`${item.message}. `}<br /></p>
                                                        ))}

                                                    </ReactTooltip>
                                                }
                                            </td>
                                            {lead.lead_status &&
                                                <td>
                                                    <img src={`/images/admin/global/status/${image_url_status[lead.lead_status.slug]}`} alt="" data-tip="status" data-for={lead.lead_status.slug} />
                                                    <ReactTooltip className='tt-admin-tooltip' aria-haspopup='true' id={lead.lead_status.slug}>{lead.lead_status.label ? lead.lead_status.label : ''}</ReactTooltip>
                                                </td>
                                            }
                                            <td>
                                                <div className="action-wrap">
                                                    <Link
                                                        to={{
                                                            pathname: `/admin/leads/details/${lead.lead_id}`,
                                                            state: { current_page: params.page ? params.page : 1 }
                                                        }}
                                                    >
                                                        <img src="/images/admin/global/edit.svg" alt="" />
                                                    </Link>
                                                    <Link
                                                        to={"#"}
                                                        onClick={() =>
                                                            show('ConfirmModal', {
                                                                title: 'Delete Lead',
                                                                body: 'Do you want to delete this lead ?',
                                                                button_name: 'Delete',
                                                                action: () => apiCalls.deleteLead(lead.lead_id),
                                                            })
                                                        }
                                                    >
                                                        <img
                                                            src="/images/admin/global/delete.svg"
                                                            alt=""
                                                        />
                                                    </Link>
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </Table>
                    </div>
                </div>
                {leads && leads.meta &&
                    <PaginationComponent page={leads.meta} onChange={(page) => onParamsChange('page', page)} />
                }
            </div>
        </div>
    );
}
