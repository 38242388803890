import { request } from "js/helpers/api";

export const userServices = {
    getAll,
    create,
    exportToExcel,
    update,
    remove,
    get,
    bulkAction,

    selectPlan,
    assignPerk
};

function getAll(params = {}, hideModal) {
    return request({
        method: 'GET',
        url: 'users',
        param: params,
        is_authenticated: true,
        cancel_token: 'userServices.getAll',
        hideModal
    });
}

function create(details) {
    return request({
        method: 'POST',
        url: 'users',
        param: details,
        is_authenticated: true,
        content_type: 'json',
    });
}

function update(details, user_id) {
    return request({
        method: 'PUT',
        url: `users/${user_id}`,
        param: details,
        is_authenticated: true,
        content_type: 'json',
    });
}

function remove(user_id) {
    return request({
        method: 'DELETE',
        url: `users/${user_id}`,
        param: {},
        is_authenticated: true,
    });
}

function get(user_id, params = {}) {
    return request({
        method: 'GET',
        url: `users/${user_id}`,
        param: params,
        is_authenticated: true,
    });
}

function exportToExcel(params) {
    return request({
        method: 'GET',
        url: `users/generate-export-link`,
        param: params,
    });
}

function bulkAction(details) {
    return request({
        method: 'POST',
        url: `users/bulk-action`,
        param: details,
        content_type: 'json',

    });
}

function selectPlan(details, user_id) {
    return request({
        method: 'POST',
        url: `users/select-plan/${user_id}`,
        param: details,
        content_type: 'json',
    });
}

function assignPerk(user_id, reward_id) {
    return request({
        method: 'GET',
        url: `users/${user_id}/membership-rewards/${reward_id}/toggle`,
        param: {},
        content_type: 'json',
    });
}

