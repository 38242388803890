import React from "react";
import { Row, Col } from 'reactstrap';
import 'filepond/dist/filepond.min.css';
import { isEmpty } from 'lodash';
import { FileUploadComponent, ImageSortable } from 'js/components/common';

export const TripMedia = (props) => {
    let files = props.files && props.files.filter(item => item);
    return(
        <div>
            <Row>
                <Col xs="12" className="choose-photos">
                    <p className="header-txt">Choose Photos *</p>
                    <p className="sub-header-txt">Your first 3 photos will be the header of your listing.</p>
                </Col>
            </Row>
            <div className="upload-photo-wrap">
                <div className="photo-uploader">
                    <FileUploadComponent
                        entity_type='TripMedia'
                        multiple={true}
                        max={ 10 }
                        accepted={['image/*']}
                        onSuccess={props.onAddNewFiles}
                    />
                </div>
                {files && !isEmpty(files ) &&
                    <ImageSortable files={files} onUpdate={props.onChange} onRemove={props.onRemoveFile}/>
                }
                <div className="drag-drop-text">
                    <h4>
                        <img src="/images/web/add-listing/arrow.svg" alt="" />
                        Drag and drop photos to best represent your listing.
                    </h4>

                </div>
                <div className="drag-drop-text">
                    <h4>
                        <img src="/images/admin/global/info.svg" alt="" />
                        You can only add upto 10 files at a time, maximum size of the file should be less than 32mb
                    </h4>
                </div>
            </div>
        </div>
    )
}
