import { request } from "js/helpers/api";

export const tradeServices = {
    getAll,
    create,
    exportToExcel,
    update,
    remove,
    get,
    calendar,
    cancel,
    updateStatus,
    confirmTrade,
};


function create(details, hideModal) {
    return request({
        method: 'POST',
        url: 'trades',
        param: details,
        content_type: 'json',
        hideModal
    });
}

function update(details, trade_id, hideModal) {
    return request({
        method: 'PUT',
        url: `trades/${trade_id}`,
        param: details,
        content_type: 'json',
        hideModal
    });
}


function getAll(params = {}) {
    return request({
        method: 'GET',
        url: 'trades',
        param: params,
    });
}


function get(trade_id, hideModal) {
    return request({
        method: 'GET',
        url: `trades/${trade_id}`,
        param: {},
        hideModal
    });
}


function remove(trade_id) {
    return request({
        method: 'DELETE',
        url: `trades/${trade_id}`,
        param: {},
    });
}

function exportToExcel(params = {}) {
    return request({
        method: 'GET',
        url: `trades/generate-export-link`,
        param: params,
    });
}

function calendar(params) {
    return request({
        method: 'GET',
        url: `trip-trades/calendar`,
        param: params,
    });
}

function cancel(trade_id, user_id, details = {}, hideModal) {
    return request({
        method: 'PUT',
        url: `trade/${trade_id}/cancel/${user_id}`,
        param: details,
        content_type: 'json',
        hideModal
    });
}


function updateStatus(trade_id, details, hideModal) {
    return request({
        method: 'PUT',
        url: `trade/${trade_id}/update-status`,
        param: details,
        content_type: 'json',
        hideModal
    });
}

function confirmTrade(trip_trade_id, params){
    return request({
        method:'GET',
        url: `trip-trade/${trip_trade_id}/confirm`,
        param: params,
    });
}
