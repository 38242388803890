import React, { useEffect, useState } from 'react';
import { Route, Switch, Router } from "react-router-dom";
import { IndexRoutes } from "js/routes/index";
import { history } from "js/helpers";
import { isEmpty } from 'lodash';
import { BounceLoader } from "react-spinners";

import { AuthWrapper, ModalWrapper } from 'js/contexts';
import { LookupContext } from 'js/contexts';
import { lookupServices } from 'js/services';
import { lookupFucntions } from 'js/components/common/fucntions';
import { ModalContainer } from 'js/components/common';
import "react-datepicker/dist/react-datepicker.css";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PageView, initGA } from 'js/components/Tracking';


import { authServices } from 'js/services/auth-service';
import { configConstants } from 'js/constants';

toast.configure({
    position: toast.POSITION.BOTTOM_RIGHT
})



const App = () => {

    const [lookups, setLookups] = useState({});
    const [ partner_token_success, setPartnerTokenSuccess ] = useState(false);

    useEffect(() => {
        if(process.env.GOOGLE_ANALYTICS_TRACK_ID) {
            initGA(process.env.GOOGLE_ANALYTICS_TRACK_ID);
        }

        if( !partner_token_success ){
            authServices.partnerLogin().then((response) => {
                localStorage.setItem(configConstants.PARTNER_TOKEN, response.partner_token);
                setPartnerTokenSuccess(true);
                lookupServices.getAll()
                .then(async (response) => {
                    await lookupFucntions.getLookups(response)
                        .then((res) => setLookups(res));
                });
            }).catch((err) => console.log('errors', err));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    if(process.env.GOOGLE_ANALYTICS_TRACK_ID) {
        PageView();
    }
    return (

        <Router history={history} >
            {
                !isEmpty(lookups) && partner_token_success ?
                    <AuthWrapper>
                        <LookupContext.Provider value={lookups}>
                            <ModalWrapper>
                                <Switch>
                                    {IndexRoutes.map((prop, key) => {
                                        return <Route path={prop.path} component={prop.component} key={key} />;
                                    })}
                                </Switch>
                                <ModalContainer />
                            </ModalWrapper>
                        </LookupContext.Provider>
                    </AuthWrapper>
                    :
                    <React.Fragment>
                        <div className="outer-loader">
                            <div className="sweet-loading">
                                <BounceLoader
                                    size={50}
                                    color={"#FFA405"}
                                />
                            </div>
                        </div>

                    </React.Fragment>
            }
        </Router>
    );

}

export default App;
