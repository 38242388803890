import React, { useState, useEffect } from "react";
import { Row, Col } from 'reactstrap';
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import dompurify from 'dompurify';

import { cmsServices } from 'js/services';
import { history } from 'js/helpers';

export const TermsConditions = (props) => {
    const [ state, setState] = useState({
        content : {
            page_title : '',
            top_section : '',
        }
    });
    const getStaticPage = () => {
        cmsServices.getStaticPage('terms-and-conditions')
        .then((response) => {
            setState({ ...state, ...response })
        }).catch((err) => {
            toast.error('Failed to Load Page');
            history.push('/')
        });
    }
    useEffect(() => {
        getStaticPage();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const content = state.content;
    const sanitizer = dompurify.sanitize;
    return (
        <div>
            <Helmet>
                <title>{content.page_title} - Trips4Trade</title>
                <meta name="description" content={`${content.page_title} - Trips4Trade`} />
            </Helmet>

            <div className="inner-banner" style={{backgroundImage: `url(images/web/global/banners/static-banner.png)`}}>
                <div className="container">
                    <Row>
                        <Col xs="12">
                            <h1 className="header2 color--white text-uppercase">{content.page_title}</h1>
                        </Col>

                    </Row>
                </div>
            </div>
            <div className="wider-data-frame wider-data-frame--grey">
                <div className="container">
                    <Row>
                        <Col  dangerouslySetInnerHTML={{__html: sanitizer(content.top_section) }} >
                        </Col>
                    </Row>

                </div>
            </div>

        </div>


    );
}
