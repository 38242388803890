import React from "react"
import { Link } from 'react-router-dom';

import "js/components/legos/styles/trip-id-card-module.css"

export default props => (
    <div className="trip-id-card">
        <Link to={props.linkName}>
            <div className="trip-id-card--top-section">
                <h3><strong>Trip ID :</strong> {props.tripId}</h3>
            </div>
            
            <img className="trip-id-card--img" src={props.tripIdCardImage} alt="" />
        </Link>
    </div>
)