import React, { useState, useEffect } from "react";
import { Row, Col, Modal, ModalHeader, ModalBody, Button, Form, FormGroup, Label, Input, Spinner } from 'reactstrap';
import { toast } from "react-toastify";
import { isEmpty, forEach, pick, cloneDeep } from 'lodash';
import DatePicker from "react-datepicker";
import moment from 'moment';
import { useModal } from 'js/hooks';

import { tradeServices } from 'js/services';
import { ListLoader, StaticLink, ErrorFormFeedback } from 'js/components/common';

export const EditTradeModal = (props) => {
    const { is_open, hide } = props;
    const [selected_trade, setSelectedTrade] = useState([]);
    const [user_trip_detials, setUserTrip] = useState({});
    const [guest_trip_details, setGuestTrip] = useState({});
    const [createErrors, setErrors] = useState({});
    const [loading, setLoading] = useState({
        modal_loading: false,
        button_loading: false
    });
    const [description, setDescription] = useState('');

    const [show] = useModal();

    const user_id = props.user_id ? props.user_id : ''
    const selected_trade_id = props.selected_trade_id ? props.selected_trade_id : '';

    const apiCalls = {
        getSelectedTrade: (trade_id) => {
            setLoading((loading) => ({
                ...loading,
                modal_loading: true
            }));
            tradeServices.get(trade_id, hide).then((response) => {
                setLoading((loading) => ({
                    ...loading,
                    modal_loading: false
                }));
                if (!isEmpty(response)) {
                    setSelectedTrade(response);
                    if(!isEmpty(response.description)){
                        setDescription(response.description);
                    }
                }
                if (!isEmpty(response.trip_trades)) {
                    forEach(response.trip_trades, (trade) => {
                        if (trade.guest_user_id === user_id) {
                            let selected_user_trip = pick(trade, ['trip_trade_id', 'guest_user_id', 'arrival_time', 'departure_time', 'accountability_deposit']);
                            setUserTrip(selected_user_trip);
                        }
                        else {
                            let guest_trip = pick(trade, ['trip_trade_id', 'guest_user_id', 'arrival_time', 'departure_time', 'accountability_deposit']);
                            setGuestTrip(guest_trip);
                        }
                    })
                }
            }).catch(() => {
                setLoading((loading) => ({
                    ...loading,
                    modal_loading: false
                }));
            });
        },
        updateTrade: (details, trade_id) => {
            setLoading((loading) => ({
                ...loading,
                button_loading: true
            }));
            tradeServices.update(details, trade_id, hide).then(() => {
                setLoading((loading) => ({
                    ...loading,
                    button_loading: false
                }));
                toast.success('Updated Successfully');
                hide();
                props.callBack();
            }).catch((err) => {
                let errors = err.data && err.data.errors;
                setLoading((loading) => ({
                    ...loading,
                    button_loading: false
                }));
                setErrors(errors);
            });
        },
    }

    useEffect(() => {
        if (selected_trade_id) {
            apiCalls.getSelectedTrade(selected_trade_id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const update = () => {
        let details = {};
        let trip_trades = [];

        trip_trades[0] = cloneDeep(user_trip_detials);
        trip_trades[1] = cloneDeep(guest_trip_details);

        if(!isEmpty(description)){
            details.description = description;
        }

        details.trip_trades = trip_trades;

        if (!isEmpty(details.trip_trades)) {
            details.trip_trades.map((item) => {
                forEach(item, (value, key) => {
                    if (key === 'arrival_time' || key === 'departure_time') {
                        item[key] = moment(value).format('YYYY-MM-DD HH:mm:ss')
                    }
                })
                return item;
            })
        }
        apiCalls.updateTrade(details, selected_trade_id);
    }
    return (
        <div>
            <Modal
                isOpen={is_open}
                toggle={() => hide()}
                className="tt-modal--admin modal-dialog-centered"
            >
                <ModalHeader toggle={() => hide()}>Trip Details</ModalHeader>
                <ModalBody>
                    {
                        loading['modal_loading'] ? <ListLoader />
                            :
                            <>
                                <Row>
                                    <Col xs="12">
                                        <p className="mt-3">Changing the dates here will notify the other host to ask for confirmation</p>
                                    </Col>
                                </Row>
                                <Form className="profile-form">
                                    <Row>
                                        <Col xs="12">
                                            <h4><span>{props.type === 'admin' ? 'Trip 1' : 'Your Trip'}</span></h4>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs="12" md="6">
                                            <FormGroup>
                                                <Label for="" >Arrival</Label>
                                                <div className="tt-custom-react-select">
                                                    <DatePicker
                                                        className='custom-date-picker form-control'
                                                        selected={user_trip_detials.arrival_time ? moment(user_trip_detials.arrival_time).toDate() : ''}
                                                        onChange={
                                                            (e) => {
                                                                setUserTrip({ ...user_trip_detials, 'arrival_time': e });
                                                            }
                                                        }
                                                        showYearDropdown
                                                        dateFormat="MMM d, yyyy"
                                                        minDate={new Date()}
                                                    />
                                                    <ErrorFormFeedback errors={createErrors} field={'trip_trades.0.arrival_time'} is_invalid={true}/>

                                                </div>
                                            </FormGroup>
                                        </Col>
                                        <Col xs="12" md="6">
                                            <FormGroup>
                                                <Label for="" >Departure</Label>
                                                <div className="tt-custom-react-select">
                                                    <DatePicker
                                                        className='custom-date-picker form-control'
                                                        selected={user_trip_detials.departure_time ? moment(user_trip_detials.departure_time).toDate() : ''}
                                                        onChange={
                                                            (e) => {
                                                                setUserTrip({ ...user_trip_detials, 'departure_time': e });
                                                            }
                                                        }
                                                        showYearDropdown
                                                        dateFormat="MMM d, yyyy"
                                                        minDate={user_trip_detials.arrival_time ? moment(user_trip_detials.arrival_time).toDate(): new Date()}
                                                    />
                                                    <ErrorFormFeedback errors={createErrors} field={'trip_trades.0.departure_time'} is_invalid={true}/>

                                                </div>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs="12">
                                            <h4><span>{props.type === 'admin' ? 'Trip 2' : 'Their Trip'}</span></h4>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs="12" md="6">
                                            <FormGroup>
                                                <Label for="" >Arrival</Label>
                                                <div className="tt-custom-react-select">
                                                    <DatePicker
                                                        className='custom-date-picker form-control'
                                                        selected={guest_trip_details.arrival_time ? moment(guest_trip_details.arrival_time).toDate() : ''}
                                                        onChange={
                                                            (e) => {
                                                                setGuestTrip({ ...guest_trip_details, 'arrival_time': e });
                                                            }
                                                        }
                                                        showYearDropdown
                                                        dateFormat="MMM d, yyyy"
                                                        minDate={new Date()}
                                                    />
                                                    <ErrorFormFeedback errors={createErrors} field={'trip_trades.1.arrival_time'} is_invalid={true}/>
                                                </div>
                                            </FormGroup>
                                        </Col>
                                        <Col xs="12" md="6">
                                            <FormGroup>
                                                <Label for="" >Departure</Label>
                                                <div className="tt-custom-react-select">
                                                    <DatePicker
                                                        className='custom-date-picker form-control'
                                                        selected={guest_trip_details.departure_time ? moment(guest_trip_details.departure_time).toDate() : ''}
                                                        onChange={
                                                            (e) => {
                                                                setGuestTrip({ ...guest_trip_details, 'departure_time': e });
                                                            }
                                                        }
                                                        showYearDropdown
                                                        dateFormat="MMM d, yyyy"
                                                        minDate={guest_trip_details.arrival_time ? moment(guest_trip_details.arrival_time).toDate() : new Date()}
                                                    />
                                                    <ErrorFormFeedback errors={createErrors} field={'trip_trades.1.departure_time'} is_invalid={true}/>
                                                </div>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs="12">
                                            <h4><span>Trip Protection</span></h4>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs="12" md="6">
                                            <FormGroup>
                                                <Label for="" >Accountability Deposit of {props.type === 'admin' ? 'Trip 1' : 'Your Trip'}</Label>
                                                <Input type="text"
                                                    onChange={
                                                        (e) => {
                                                            setUserTrip({ ...user_trip_detials, 'accountability_deposit': e.target.value });
                                                        }
                                                    }
                                                    value={user_trip_detials.accountability_deposit ? user_trip_detials.accountability_deposit : ''}
                                                />
                                                <ErrorFormFeedback errors={createErrors} field={'trip_trades.0.accountability_deposit'} is_invalid={true}/>
                                            </FormGroup>
                                        </Col>
                                        {/* <Col xs="12" md="6">
                                            <FormGroup>
                                                <Label for="" >Accountability Deposit of {props.type === 'admin' ? 'Trip 2' : 'Their Trip'}</Label>
                                                <Input type="text"
                                                    onChange={
                                                        (e) => {
                                                            setGuestTrip({ ...guest_trip_details, 'accountability_deposit': e.target.value });
                                                        }
                                                    }
                                                    value={guest_trip_details.accountability_deposit ? guest_trip_details.accountability_deposit : ''}
                                                />
                                                <ErrorFormFeedback errors={createErrors} field={'trip_trades.1.accountability_deposit'} is_invalid={true}/>
                                            </FormGroup>
                                        </Col> */}
                                    </Row>
                                    <Row>
                                        <Col xs="12">
                                            <p>An accountability deposit keeps each party accountable until both trips take place. Deposit will be be released after the swap is complete minus a 3% holding fee.</p>
                                        </Col>
                                    </Row>
                                    {/* <Row>
                                        <Col xs="12" md="6">
                                            <FormGroup>
                                                <Label for="" >Description</Label>
                                                <Input type="text"
                                                    onChange={
                                                        (e) => {
                                                            setDescription(e.target.value);
                                                        }
                                                    }
                                                    value={description}
                                                />
                                                <ErrorFormFeedback errors={createErrors} field={'description'} is_invalid={true}/>
                                            </FormGroup>
                                        </Col>
                                    </Row> */}
                                    <Row>
                                        <Col xs="12">
                                            <hr />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs="12">
                                            <p>We have partnered with Redpoint Resolutions to offer trip insurance to our members. Click the button below to learn more. </p>
                                        </Col>
                                        <Col xs="12" md="6" className="mb-3">
                                        {!isEmpty(selected_trade) &&
                                            <StaticLink
                                                url={selected_trade.trip_insurance}
                                                content={
                                                    <Button className="btn-outline text-capitalize">Trip Insurance</Button>
                                                }
                                            />
                                        }
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs="12">
                                            <h4><span>Gear For Your Trip</span></h4>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs="12">
                                            <p>We have partnered with Bass Pro Shops to offer you 10% off any gear needed on your trip. Click the button below to browse gear.</p>
                                        </Col>
                                        <Col xs="12" md="6" className="mb-3">
                                        {!isEmpty(selected_trade) &&
                                            <StaticLink
                                                url={selected_trade.browse_gear}
                                                content={
                                                    <Button className="btn-outline text-capitalize">Browse Gear</Button>
                                                }
                                            />
                                        }
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs="12 text-center">
                                            <ErrorFormFeedback errors={createErrors} field={'general'} is_invalid={true} />
                                        </Col>
                                        <Col xs="12">
                                            <hr />
                                        </Col>
                                    </Row>

                                </Form>
                                <Row>

                                    <Col xs="12">
                                        <div className="d-flex justify-content-center">
                                            <div className="add-listing-btn-group">
                                                {
                                                    !isEmpty(selected_trade) && !isEmpty(selected_trade.trade_status)
                                                    && selected_trade.trade_status.slug === 'in-progress' &&
                                                    <Button
                                                        className="btn-outline btn-outline--light-grey margin-right-15"
                                                        onClick={() =>
                                                            show('CancelTripModal', {
                                                                selected_trade_id,
                                                                user_id,
                                                                parentCallBack: () => {
                                                                    props.callBack()
                                                                }
                                                            })
                                                        }
                                                    >
                                                        Cancel Trip
                                                    </Button>
                                                }
                                                <Button
                                                    className="btn-outline"
                                                    onClick={update}
                                                >
                                                    {loading['button_loading'] ? <Spinner size="sm"/> : 'Save'}
                                                </Button>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </>
                    }

                </ModalBody>
            </Modal>
        </div>
    )
}
