import React, { useState, useEffect } from 'react';
import { Row, Col, Form, FormGroup, Label, Input, CustomInput, Button, Spinner } from 'reactstrap';
import { isEmpty } from 'lodash';
import ReactTooltip from 'react-tooltip';

import { ErrorFormFeedback} from 'js/components/common';
import { UseErrors } from 'js/hooks';
import { tripHash } from 'js/components/common/fucntions';

export const DescriptionTab = (props) => {
    const [ description, setDescription ] = useState({});
    const [form_errors, getErrors] = UseErrors({'initial': true})
    const error_hash = [
        tripHash.description,
        tripHash.accomodation_description,
        tripHash.interested_trips_to_trade,
        tripHash.is_interested_in_selling,
        tripHash.fair_market_value,
    ];

    useEffect(() => {
        if(isEmpty(form_errors) ) {
            props.onChange({ details: description})
        }
    //eslint disabled for props
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form_errors])

    const handleChange = (key, value) => {
        let data = { ...description, [key]: value };
        setDescription(data)
    }
    const handleNext = (next_tab) => {
        if(next_tab !== 'details') {
            getErrors(error_hash, description)
        }
        else {
            props.onChange({ details: description, next_tab})
        }
    }

    return(
        <div className="signup-tab-data-wrap add-listing-data-wrap">
            <Row>
                <Col xs="12">
                    <h3 className="header5 mb-0">Trip Description</h3>
                    <p className="p p-0 text-left">Each question is vital to a credible listing. Make sure to add plenty of details in the description.</p>
                </Col>
            </Row>
            <Form className="profile-form mt-3 add-listing-form">
                <Row>
                    <Col xs="12">
                        <FormGroup>
                            <img src="/images/web/add-listing/description.svg" alt="" width="22px" />
                            <Label for="" >Trip Description *</Label>
                            <Input
                                type="textarea"
                                value={description.description}
                                onChange ={(e) => handleChange('description', e.target.value)}
                                invalid={form_errors.description && true}
                            />
                            <ErrorFormFeedback field="description" errors={form_errors}/>
                            <small>What all you can do on trip, how many acres, success of previous trips, etc. Detailed listings receive more trade requests.</small>
                        </FormGroup>
                    </Col>
                    <Col xs="12">
                        <FormGroup>
                            <img src="/images/web/add-listing/trip-accomodations.svg" alt="" width="24px" />
                            <Label for="" >Trip Accommodations *</Label>
                            <Input
                                type="textarea"
                                value={description.accomodation_description || ''}
                                onChange ={(e) => handleChange('accomodation_description', e.target.value)}
                                invalid={form_errors.accomodation_description && true}
                            />
                            <ErrorFormFeedback field="accomodation_description" errors={form_errors}/>
                            <small>List which accommodations are included and excluded on this trip. e.g. "Lodging, Meals, Equipment, airport transportation, etc.”</small>
                        </FormGroup>
                    </Col>
                    <Col xs="12">
                        <FormGroup>
                            <img src="/images/web/add-listing/trading-interest.svg" alt="" width="29px" />
                            <Label for="" >What trips are you interested in trading for?</Label>
                            <Input
                                type="text"
                                value={description.interested_trips_to_trade || ''}
                                onChange ={(e) => handleChange('interested_trips_to_trade', e.target.value)}
                                invalid={form_errors.interested_trips_to_trade && true}
                            />
                            <ErrorFormFeedback field="interested_trips_to_trade" errors={form_errors}/>
                            <small>Can be broad or specific. Hunting, Deep Sea Fishing, Beach Vacation Trips, Open To Any Requests, etc.</small>
                        </FormGroup>
                    </Col>
                    <Col xs="12" md="6">
                        <FormGroup>
                            <img src="/images/web/add-listing/fair-market-value.svg" alt="" width="15px" />
                            <Label for="" >Fair Market Value *</Label>
                            <Input
                                type="text"
                                value={description.fair_market_value || ''}
                                onChange ={(e) => handleChange('fair_market_value', e.target.value)}
                                invalid={form_errors.fair_market_value && true}
                            />
                            <ErrorFormFeedback field="fair_market_value" errors={form_errors}/>
                            <small>Used internally only for matching you with trips of similar value.</small>
                        </FormGroup>
                    </Col>
                    <Col xs="12" md="6">
                        <FormGroup className="pl-0 pt-4" inline>
                            <Label for="" className="font-small" >In addition to trading this trip, are you interested in selling it?</Label>
                            <div className="listing-form-radio">
                                <Row>
                                    <Col xs="6" md="6">
                                        <CustomInput
                                            type="radio"
                                            id="customRadio1"
                                            name="customRadio"
                                            label="Yes"
                                            onChange ={() => handleChange('is_interested_in_selling', true)}
                                        />
                                        <span className="add-list-info">
                                            <img src="/images/admin/global/info.svg" alt="" data-tip="info" data-for="sell_info"/>
                                            <ReactTooltip
                                                className='tt-admin-tooltip text-left fixed-width--tooltip'
                                                aria-haspopup='true'
                                                id="sell_info">
                                                Our focus at Trips4Trade is trading trips but we do allow members to sell trips if they desire. However, you must offer a discount to Trips4Trade members if you choose to sell. Most members do 10-20%. We don’t charge any transaction fees so that you can put this towards the discount. This will help you sell more trips.
                                            </ReactTooltip>
                                        </span>
                                    </Col>
                                    <Col xs="6" md="6">
                                        <CustomInput
                                            type="radio"
                                            id="customRadio2"
                                            name="customRadio"
                                            label="No"
                                            onChange ={() => handleChange('is_interested_in_selling', false)}
                                        />
                                    </Col>

                                </Row>
                                <div className="invalid-feedback d-block">{form_errors['is_interested_in_selling']}</div>
                            </div>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col xs="12">
                        <div className="d-flex justify-content-center add-listing-button-wrap">
                            <div className="add-listing-btn-group">
                                <Button className="btn-outline btn-outline--light-grey margin-right-15"  onClick={() => handleNext('details')}>Prev</Button>
                                <Button
                                    className="btn-outline"
                                    onClick={ () => handleNext() }>
                                    {props.loading ?
                                    <Spinner size="sm"/> : 'Next'
                                    }
                                </Button>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}
