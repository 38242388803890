import React, { useState, useEffect } from "react";
import {Row, Col, Button, Form, FormGroup, Input, Label, Modal, ModalHeader, ModalBody, ModalFooter, Spinner, Table } from 'reactstrap';
import { toast } from "react-toastify";
import { debounce, isEmpty, omit, cloneDeep, isEqual } from 'lodash';

import { cmsServices } from 'js/services';
import { ErrorFormFeedback, FileUploadComponent } from 'js/components/common';

export const PartnerLogoAddEditModal = (props) => {

    const { is_open, hide, callBack } = props;

    const [ state, setState] = useState({
        name: '',
        order: '',
        external_link: '',
        is_active : 1,
        attachments: []
    });

    const [ loading, setLoading ] = useState();
    const [ footerErrors, setError ] = useState();
    const [fileUploadLoading, setFileUploadLoading] = useState(false);
    const [ attachment_memo, setAttachmentMemo ] = useState([]);



    const addEditFooterMenu = debounce(() =>{
        let form_data = {
            ...state,
            attachments: state.attachments ? state.attachments.map( (item) => (item.attachment_id)) : [],
        };
        setLoading(true);
        if( props.type === 'add' ){
            cmsServices.addPartnerLogo(form_data, hide)
            .then((response) => {
                toast.success('Logo Added Successfully');
                setError({});
                callBack();
                hide();
                setLoading(false);
            }).catch((error) => {
                setError(error.data && error.data.errors);
                setLoading(false);
            });
        }
        else if( props.type === 'edit' && state.partner_logo_id ) {
            let details = cloneDeep(form_data);

            if(isEqual(details.attachments, attachment_memo)){
                delete details.attachments;
            }
            cmsServices.updatePartnerLogo(state.partner_logo_id, details, hide)
            .then((response) => {
                toast.success('Logo Updated Successfully');
                callBack();
                hide();
            }).catch((error) => {
                setError(error.data && error.data.errors);
                setLoading(false);
            });
        }
    }, 300 );

    const handleChange = ( value, key ) => {
        setState({ ...state, [key] : value });
    }

    const uploadFile = ( response ) => {
        let { attachments } = state;
        if( !attachments  ){
            attachments = [];
        }
        attachments.push(response);
        setState({ ...state, attachments });
    }

    const removeFile = ( index ) => {
        let { attachments  }=  state ;
        attachments.splice( index, 1 );
        setState({ ...state, attachments });
    }

    useEffect(() => {
        if( props.type === 'edit' ){
            let attachment_id = null;
            setState( { ...omit(props.state, 'attachment'), attachments : props.state.attachment ? [ props.state.attachment ] : [] } );
            attachment_id = props.state && props.state.attachment && props.state.attachment.attachment_id;
            setAttachmentMemo([attachment_id]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <div>
            <Modal
                isOpen={is_open}
                toggle={() => hide()}
                className="tt-modal--admin modal-dialog-centered"
            >
                    <ModalHeader toggle={() => hide()}>{ props.type === 'edit'  ? 'Edit Partner Logo' : 'Add Partner Logo'}</ModalHeader>
                    <ModalBody>
                        <Form className="tt-admin-form">
                            <div className="tt-admin-form-inner p-0">
                                <Row form>
                                    <Col xs="12" md="6">
                                        <FormGroup>
                                            <Label for="">Name</Label>
                                            <Input
                                                type="text"
                                                value={state.name}
                                                onChange={ (e) => handleChange(e.target.value, 'name') }
                                                invalid={ !isEmpty(footerErrors) && (footerErrors.name) && true }
                                            />
                                            <ErrorFormFeedback field={'name'} errors={footerErrors} />
                                        </FormGroup>
                                    </Col>
                                    <Col xs="12" md="6">
                                        <FormGroup>
                                            <Label for="">Order</Label>
                                            <Input
                                                type="text"
                                                value={state.order}
                                                onChange={ (e) => handleChange(e.target.value, 'order') }
                                                invalid={ !isEmpty(footerErrors) && (footerErrors.order) && true }
                                            />
                                            <ErrorFormFeedback field={'order'} errors={footerErrors} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row form>
                                    <Col xs="12">
                                        <FormGroup>
                                            <Label for="">External Link</Label>
                                            <Input
                                                type="external_link"
                                                value={state.external_link}
                                                onChange={ (e) => handleChange(e.target.value, 'external_link') }
                                                invalid={ !isEmpty(footerErrors) && (footerErrors.external_link) && true }
                                            />
                                            <ErrorFormFeedback field={'external_link'} errors={footerErrors} />
                                        </FormGroup>
                                    </Col>

                                </Row>
                                <Row form>
                                    <Col xs="12">
                                    { state.attachments && ! isEmpty(state.attachments) ?
                                        <FormGroup>
                                            <Label for="">Uploaded File</Label>
                                            <Table responsive>
                                                <tbody>
                                                    {state.attachments.map( (item, index ) => (
                                                        <tr key={index}>
                                                            <td>
                                                                <p className="resources-upload--name">
                                                                    <img src={` ${item.attachment_url}`} alt="" />
                                                                    {` ${item.filename}`}
                                                                </p>
                                                            </td>
                                                            <td>
                                                                <i
                                                                    className="resources-upload--delete-icon"
                                                                    onClick={() => removeFile(index)}
                                                                >
                                                                    <img
                                                                    src="/images/admin/global/close.svg"
                                                                    alt=""
                                                                />
                                                                </i>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </FormGroup>
                                        :
                                        <FormGroup>
                                            <Label for="">Upload Partner Logo</Label>
                                            <div>
                                                <FileUploadComponent
                                                    entity_type='PartnerLogo'
                                                    multiple={false}
                                                    accepted={['image/*']}
                                                    onSuccess={uploadFile}
                                                    fileUploadLoading={(loading) => setFileUploadLoading(loading)}
                                                />
                                            </div>
                                        </FormGroup>
                                    }
                                    </Col>
                                </Row>
                            </div>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="primary"
                            className="btn-outline-grey"
                            onClick={() => hide()}
                            disabled={loading}
                        >
                            Cancel
                        </Button>
                        <Button
                            color="primary"
                            className="add-new-btn"
                            onClick={addEditFooterMenu}
                            disabled={loading || fileUploadLoading}
                        >
                            { loading ? <Spinner size="sm" /> : 'Save' }
                        </Button>
                    </ModalFooter>
                </Modal>
        </div>
    );
}
