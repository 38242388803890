import React from "react"
import Slider from "react-slick";

import "js/components/legos/styles/big-image-slider-module.css"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const  BigImageSlider = ( props ) =>  {
    const settings = {
        dots: true,
        infinite: true,
        autoplay: true,
        fade: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        className: 'big-image-slider'
    };
    return (
        <div>
            <Slider {...settings}>
                { props.images && props.images.map((item, index ) => (
                <div className="big-slider-wrapper" key={index} >
                    <img src={item.image_url} alt="Slider" />
                    <h4 className="header3 color--white">{item.description}</h4>
                </div>
                ))}
            </Slider>
        </div>
    );
}
export default BigImageSlider;
