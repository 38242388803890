import React, { useState, useEffect, useContext } from 'react';
import {
    Row,
    Col,
    Button,
    Form,
    FormGroup,
    CustomInput,
    Label,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Input,
    Table,
    Spinner
} from 'reactstrap';

import Select from 'react-select';
import { debounce, omit, isEmpty, forEach, cloneDeep, isEqual } from 'lodash';
import DatePicker from "react-datepicker";
import moment from 'moment';
import { toast } from "react-toastify";

import { advertisementServices } from 'js/services';
import { LookupContext } from 'js/contexts';
import { ErrorFormFeedback, FileUploadComponent } from 'js/components/common';


export const AddEditAdvertisementModal = (props) => {
    const { is_open, hide, callBack } = props;

    const file_types = {
        image: ['image/jpeg', 'image/png'],
    }

    const [advertisment, setAdvertisment] = useState({});
    let [state, setState] = useState({
        files: [],
        memo_file: [],
    });
    const [createErrors, setErrors] = useState({});
    const lookups = useContext(LookupContext);
    const [loading, setLoading] = useState(false);
    const [fileUploadLoading, setFileUploadLoading] = useState(false);
    const [ position_lookups, setPositionLookups ] = useState([]);

    const apiCalls = {
        getAdvertisement: (params) => {
            advertisementServices.get(params, hide).then((response) => {
                let selected_advertisement = cloneDeep(response);

                if (!isEmpty(selected_advertisement.attachment)) {
                    let exist_file = [];
                    let memo_file = [];
                    exist_file.push(response.attachment);

                    forEach(exist_file, (file) => {
                        memo_file.push(file.attachment_id);
                    });

                    setState({
                        ...state,
                        files: exist_file,
                        memo_file
                    });
                }

                if (!isEmpty(selected_advertisement.expiry_date)) {
                    selected_advertisement.expiry_date = new Date(selected_advertisement.expiry_date);
                }

                setAdvertisment({ ...omit(selected_advertisement, 'attachment') });
            }).catch((err) => {
                setErrors(err);
            });
        },
        createAdvertisement: debounce((details) => {
            setLoading(true)
            advertisementServices.create(details, hide).then(() => {
                setErrors({});
                callBack();
                setLoading(false)
                toast.success('Created Successfully');
            })
                .catch((err) => {
                    let errors = err && err.data && err.data.errors;
                    setErrors(errors);
                    setLoading(false)
                });
        }, 300),
        updateAdvertisement: debounce((details, advertisement_id, hide) => {
            setLoading(true)
            advertisementServices.update(details, advertisement_id).then(() => {
                setErrors({});
                callBack();
                setLoading(false)
                toast.success('Updated Successfully');
            })
                .catch((err) => {
                    let errors = err.data && err.data.errors;
                    setErrors(errors);
                    setLoading(false)
                });
        }, 300),
    }

    useEffect(() => {
        if (props && props.advertisement_id) {
            apiCalls.getAdvertisement(props.advertisement_id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        let advertisement_type_id = advertisment.advertisement_type ?
            advertisment.advertisement_type.advertisement_type_id : '';
        let position_lookups = [];

        forEach(lookups.advertisement_positions, (item) => {
            if(item.advertisement_type_id === advertisement_type_id){
                position_lookups.push(item);
            }
        });

        setPositionLookups(position_lookups);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [advertisment.advertisement_type]);


    const submit = () => {
        let details = cloneDeep(advertisment);
        let attachments = [];

        details.expiry_date = details.expiry_date ? moment(details.expiry_date).format('YYYY-MM-DD') + ' 23:59:59' : null;


        if (!(isEmpty(details.advertisement_type))) {
            details.advertisement_type = details.advertisement_type ?
                details.advertisement_type.slug : '';
        }

        if (!(isEmpty(details.advertisement_position))) {
            details.advertisement_position_id = details.advertisement_position ?
                details.advertisement_position.advertisement_position_id : '';
        }

        if (!isEmpty(state.files)) {
            forEach(state.files, (file) => {
                attachments.push(file.attachment_id);
            });

            if(!isEqual(attachments, state.memo_file)){
                details.attachments = attachments;
            }
        }
        else{
            details.attachments = attachments;
        }

        if (props && props.advertisement_id) {
            apiCalls.updateAdvertisement(
                { ...omit(details, 'advertisement_id', 'advertisement_position', 'click_count', 'view_count') },
                props.advertisement_id);
        }
        else {
            apiCalls.createAdvertisement(details);
        }
    }

    const handleFile = (response) => {
        let files = state.files;
        files.push(response);
        setState({
            ...state,
            files,
        });
    }

    const removeFile = (index) => {
        let files = state.files.filter((item, ind) => index !== ind);
        setState({
            ...state,
            files,
        });
    }

    return (
        <div>
            <Modal
                isOpen={is_open}
                toggle={() => hide()}
                className="tt-modal--admin modal-dialog-centered"
            >
                <ModalHeader toggle={() => hide()}>{!props.advertisement_id ? 'Add' : 'Edit'} Advertisement Banner</ModalHeader>
                <ModalBody>
                    <Form className="tt-admin-form">
                        <div className="tt-admin-form-inner p-0">
                            <Row form>
                                <Col xs="12" md="6">
                                    <FormGroup>
                                        <Label for="">Advertisement Type</Label>
                                        <div className="tt-custom-react-select">
                                            <Select
                                                className="react-select-container"
                                                classNamePrefix="react-select"
                                                options={lookups.advertisement_types}
                                                onChange={
                                                    (e) => {
                                                        setAdvertisment({ 'advertisement_type': e });
                                                        setErrors({});
                                                    }
                                                }
                                                value={advertisment.advertisement_type ? advertisment.advertisement_type : null}
                                                isDisabled={props && props.advertisement_id ? true : false}
                                            />
                                            {!isEmpty(createErrors) && createErrors.advertisement_type && (
                                                <div>
                                                    <div className="invalid-feedback d-block">{createErrors.advertisement_type}</div>
                                                </div>
                                            )}
                                        </div>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="12">
                                    <hr className="hr-solid mt-1 mb-3" />
                                </Col>
                            </Row>
                            <Row form>
                                <Col xs="12" md="6">
                                    <FormGroup>
                                        <Label for="">{advertisment.advertisement_type &&
                                            advertisment.advertisement_type.slug === 'text-link' ? 'Text Label' : 'Title'}</Label>
                                        <Input
                                            type="text"
                                            name="text"
                                            onChange={
                                                (e) => {
                                                    setAdvertisment({ ...advertisment, 'title': e.target.value });
                                                }
                                            }
                                            value={advertisment.title ? advertisment.title : ''}
                                            invalid={!isEmpty(createErrors) && (createErrors.title)
                                                && true
                                            }
                                        />
                                        <ErrorFormFeedback field={'title'} errors={createErrors} />
                                    </FormGroup>
                                </Col>
                                <Col xs="12" md="6">
                                    <FormGroup>
                                        <Label for="">Expiration Date</Label>
                                        <div className="tt-custom-react-select">
                                            <DatePicker
                                                className='custom-date-picker'
                                                selected={advertisment.expiry_date ? moment(advertisment.expiry_date).toDate() : ''}
                                                onChange={
                                                    (e) => {
                                                        setAdvertisment({ ...advertisment, 'expiry_date': e });
                                                    }
                                                }
                                                showYearDropdown

                                            />
                                            {!isEmpty(createErrors) && createErrors.expiry_date && (
                                                <div>
                                                    <div className="invalid-feedback d-block">{createErrors.expiry_date}</div>
                                                </div>
                                            )}
                                        </div>
                                    </FormGroup>
                                </Col>
                                {
                                    !isEmpty(advertisment.advertisement_type) &&
                                    <Col xs="12" md="12">
                                        <FormGroup>
                                            <Label for="">Position</Label>
                                            <div className="tt-custom-react-select">
                                                <Select
                                                    className="react-select-container"
                                                    classNamePrefix="react-select"
                                                    options={position_lookups}
                                                    onChange={
                                                        (e) => {
                                                            setAdvertisment({ ...advertisment, 'advertisement_position': e });
                                                        }
                                                    }
                                                    value={advertisment.advertisement_position ? advertisment.advertisement_position : null}
                                                />
                                                {!isEmpty(createErrors) && createErrors.advertisement_position_id && (
                                                    <div>
                                                        <div className="invalid-feedback d-block">{createErrors.advertisement_position_id}</div>
                                                    </div>
                                                )}
                                            </div>
                                        </FormGroup>
                                    </Col>
                                }
                            </Row>
                            <Row form>
                                {
                                    advertisment.advertisement_type &&
                                    advertisment.advertisement_type.slug === 'trip-box' &&
                                    <Col xs="12">
                                        <FormGroup>
                                            <Label for="">Sub Text</Label>
                                            <Input
                                                type="text"
                                                name="text"
                                                onChange={
                                                    (e) => {
                                                        setAdvertisment({ ...advertisment, 'sub_title': e.target.value });
                                                    }
                                                }
                                                value={advertisment.sub_title ? advertisment.sub_title : ''}
                                                invalid={!isEmpty(createErrors) && (createErrors.sub_title)
                                                    && true
                                                }
                                            />
                                        </FormGroup>
                                    </Col>
                                }

                                {
                                    advertisment.advertisement_type &&
                                    (advertisment.advertisement_type.slug === 'picture' ||
                                        advertisment.advertisement_type.slug === 'trip-box')
                                    &&
                                    <Col xs="12">
                                        <FormGroup>
                                            <Label for="">External Link</Label>
                                            <Input
                                                type="text"
                                                name="text"
                                                onChange={
                                                    (e) => {
                                                        setAdvertisment({ ...advertisment, 'external_link': e.target.value });
                                                    }
                                                }
                                                value={advertisment.external_link ? advertisment.external_link : ''}
                                                invalid={!isEmpty(createErrors) && (createErrors.external_link)
                                                    && true
                                                }
                                            />
                                            <ErrorFormFeedback field={'external_link'} errors={createErrors}  />
                                        </FormGroup>
                                    </Col>
                                }
                                {
                                    advertisment.advertisement_type &&
                                    advertisment.advertisement_type.slug === 'text-link' &&
                                    <Col xs="12">
                                        <FormGroup>
                                            <Label for="">Text Link</Label>
                                            <Input
                                                type="text"
                                                name="text"
                                                onChange={
                                                    (e) => {
                                                        setAdvertisment({ ...advertisment, 'external_link': e.target.value });
                                                    }
                                                }
                                                value={advertisment.external_link ? advertisment.external_link : ''}
                                                invalid={!isEmpty(createErrors) && (createErrors.external_link)
                                                    && true
                                                }
                                            />
                                            <ErrorFormFeedback field={'external_link'} errors={createErrors}  />
                                        </FormGroup>
                                    </Col>
                                }
                            </Row>
                            {
                                advertisment.advertisement_type &&
                                (advertisment.advertisement_type.slug === 'picture' ||
                                    advertisment.advertisement_type.slug === 'trip-box'
                                ) && state.files.length < 1 &&
                                <Row form>
                                    <Col xs="12" className="mt-4">
                                        <FormGroup>
                                            <Label for="">Upload Feature Image</Label>
                                            <div>
                                                <FileUploadComponent
                                                    entity_type='Advertisement'
                                                    accepted={file_types['image']}
                                                    onSuccess={handleFile}
                                                    fileUploadLoading={(loading) => setFileUploadLoading(loading)}
                                                    onError={(err) => {
                                                        let errors = err && err.data && err.data.errors;
                                                        setErrors(errors);
                                                    }}
                                                />
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    {!isEmpty(createErrors) && createErrors.filename && (
                                        <div>
                                            <div className="invalid-feedback d-block mb-2">{createErrors.filename}</div>
                                        </div>
                                    )}
                                </Row>
                            }
                            {
                                !isEmpty(state.files) &&
                                <Row form>
                                    <Col xs="12">
                                        <Label>Uploaded Files</Label>
                                        <Table responsive>
                                            <tbody>
                                                {state.files.map((file, index) => (
                                                    <tr key={index}>
                                                        <td>
                                                            <p className="resources-upload--name">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 100 125">
                                                                    <rect fill="none" d="M65.311,24.573c-0.7-0.075-1.231-0.665-1.231-1.369V7.755H19.888v84.49h60.223V26.158L65.311,24.573z     M70.038,75.537H29.389c-0.761,0-1.378-0.617-1.378-1.378s0.617-1.378,1.378-1.378h40.649c0.761,0,1.378,0.617,1.378,1.378    S70.799,75.537,70.038,75.537z M70.038,64.659H29.389c-0.761,0-1.378-0.617-1.378-1.378c0-0.761,0.617-1.378,1.378-1.378h40.649    c0.761,0,1.378,0.617,1.378,1.378C71.416,64.042,70.799,64.659,70.038,64.659z M70.038,53.78H29.389    c-0.761,0-1.378-0.617-1.378-1.378c0-0.761,0.617-1.378,1.378-1.378h40.649c0.761,0,1.378,0.617,1.378,1.378    C71.416,53.164,70.799,53.78,70.038,53.78z M70.038,42.901H29.389c-0.761,0-1.378-0.617-1.378-1.378s0.617-1.378,1.378-1.378    h40.649c0.761,0,1.378,0.617,1.378,1.378S70.799,42.901,70.038,42.901z M70.038,32.023H29.389c-0.761,0-1.378-0.617-1.378-1.378    c0-0.761,0.617-1.378,1.378-1.378h40.649c0.761,0,1.378,0.617,1.378,1.378C71.416,31.406,70.799,32.023,70.038,32.023z" />
                                                                    <polygon fill="none" points="66.835,10.077 66.835,21.966 78.162,23.179   " />
                                                                    <path fill="#137878" d="M82.856,24.804c-0.002-0.024-0.003-0.046-0.006-0.07c-0.016-0.117-0.046-0.23-0.091-0.339    c-0.008-0.02-0.019-0.039-0.028-0.058c-0.053-0.113-0.117-0.221-0.2-0.317L66.499,5.477c-0.083-0.096-0.182-0.174-0.286-0.243    c-0.028-0.019-0.057-0.035-0.087-0.051c-0.089-0.05-0.182-0.09-0.28-0.119c-0.022-0.007-0.043-0.016-0.065-0.022    C65.676,5.016,65.568,5,65.457,5H18.511c-0.761,0-1.378,0.617-1.378,1.378v87.245c0,0.761,0.617,1.378,1.378,1.378h62.978    c0.761,0,1.378-0.617,1.378-1.378V24.92C82.867,24.881,82.86,24.843,82.856,24.804z M66.835,10.077l11.328,13.102l-11.328-1.213    V10.077z M19.888,92.245V7.755H64.08v15.449c0,0.704,0.531,1.295,1.231,1.369l14.801,1.585v66.087H19.888z" />
                                                                    <path fill="#137878" d="M70.038,29.268H29.389c-0.761,0-1.378,0.617-1.378,1.378c0,0.761,0.617,1.378,1.378,1.378h40.649    c0.761,0,1.378-0.617,1.378-1.378C71.416,29.885,70.799,29.268,70.038,29.268z" />
                                                                    <path fill="#137878" d="M70.038,40.146H29.389c-0.761,0-1.378,0.617-1.378,1.378s0.617,1.378,1.378,1.378h40.649c0.761,0,1.378-0.617,1.378-1.378    S70.799,40.146,70.038,40.146z" />
                                                                    <path fill="#137878" d="M70.038,51.025H29.389c-0.761,0-1.378,0.617-1.378,1.378c0,0.761,0.617,1.378,1.378,1.378h40.649    c0.761,0,1.378-0.617,1.378-1.378C71.416,51.642,70.799,51.025,70.038,51.025z" />
                                                                    <path fill="#137878" d="M70.038,61.904H29.389c-0.761,0-1.378,0.617-1.378,1.378c0,0.761,0.617,1.378,1.378,1.378h40.649    c0.761,0,1.378-0.617,1.378-1.378C71.416,62.52,70.799,61.904,70.038,61.904z" />
                                                                    <path fill="#137878" d="M70.038,72.782H29.389c-0.761,0-1.378,0.617-1.378,1.378s0.617,1.378,1.378,1.378h40.649c0.761,0,1.378-0.617,1.378-1.378    S70.799,72.782,70.038,72.782z" />
                                                                </svg>
                                                                {` ${file.filename}`}
                                                            </p>
                                                        </td>
                                                        <td>
                                                            <i
                                                                className="resources-upload--delete-icon"
                                                                onClick={() => removeFile(index)}
                                                            >
                                                                <img
                                                                    src="/images/admin/global/close.svg"
                                                                    alt=""
                                                                />
                                                            </i>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </Col>
                                    {!isEmpty(createErrors) && createErrors.attachments && (
                                        <div>
                                            <div className="invalid-feedback d-block mb-2">{createErrors.attachments}</div>
                                        </div>
                                    )}
                                </Row>
                            }
                            <Row form>
                                <Col xs="12" md="6">
                                    <div>
                                        <Label for="">Initial State</Label>
                                    </div>
                                    <FormGroup check inline>
                                        <CustomInput
                                            type="radio"
                                            id="initialState1"
                                            name="customRadio"
                                            label="Active"
                                            className="mr-3"
                                            value={1}
                                            onChange={
                                                (e) => {
                                                    setAdvertisment({ ...advertisment, 'is_active': Number(e.target.value) });
                                                }
                                            }
                                            checked={advertisment.is_active === 1}
                                        />
                                        <CustomInput
                                            type="radio"
                                            id="initialState2"
                                            name="customRadio"
                                            label="Inactive"
                                            value={0}
                                            onChange={
                                                (e) => {
                                                    setAdvertisment({ ...advertisment, 'is_active': Number(e.target.value) });
                                                }
                                            }
                                            checked={advertisment.is_active === 0}
                                        />
                                    </FormGroup>
                                    {!isEmpty(createErrors) && createErrors.is_active && (
                                        <div>
                                            <div className="invalid-feedback d-block">{createErrors.is_active}</div>
                                        </div>
                                    )}
                                </Col>
                            </Row>
                        </div>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        className="btn-outline-grey"
                        onClick={() => hide()}
                        disabled={loading}
                    >Cancel
                    </Button>
                    <Button
                        color="primary"
                        className="add-new-btn"
                        onClick={() => submit()}
                        disabled={loading || fileUploadLoading}
                    >
                    {loading ?
                        <Spinner size="sm"/> : props.advertisement_id ? 'Submit' : 'Add an Advertisement'
                    }
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}
