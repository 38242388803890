import React, { useState, useEffect } from "react";
import {Row, Col, Button, Form, FormGroup, Input, Label, Modal, ModalHeader, ModalBody, ModalFooter, Spinner, Table, CustomInput } from 'reactstrap';
import { toast } from "react-toastify";
import { debounce, isEmpty, omit, cloneDeep, isEqual } from 'lodash';

import { cmsServices } from 'js/services';
import { ErrorFormFeedback, FileUploadComponent } from 'js/components/common';

export const TestimonialAddEditModal = (props) => {

    const { is_open, hide, callBack } = props;
    const [ state, setState] = useState({
        full_name: '',
        order: '',
        description: '',
        is_active : '1',
        attachments: []
    });

    const [ loading, setLoading ] = useState();
    const [ error, setError ] = useState();
    const [fileUploadLoading, setFileUploadLoading] = useState(false);
    const [ attachment_memo, setAttachmentMemo ] = useState([]);


    const addTestimonial = debounce(() =>{
        let form_data = {
            ...state,
            attachments: state.attachments ? state.attachments.map( (item) => (item.attachment_id)) : [],
        };
        setLoading(true);
        if( props.type === 'add' ){
            cmsServices.addTestimonial(form_data, hide)
            .then((response) => {
                toast.success('Testimonial Added Successfully');
                callBack();
                hide();
            }).catch((error) => {
                setError(error.data && error.data.errors);
                setLoading(false);
            });
        }
        else if( props.type === 'edit' && state.testimonial_id ) {
            let details = cloneDeep(form_data);

            if(isEqual(details.attachments, attachment_memo)){
                delete details.attachments;
            }

            cmsServices.updateTestimonial(state.testimonial_id, details, hide)
            .then((response) => {
                toast.success('Testimonial Updated Successfully');
                callBack();
                hide();
            }).catch((error) => {
                setError(error.data && error.data.errors);
                setLoading(false);
            });
        }
    }, 300 );

    const handleChange = ( value, key ) => {
        setState({ ...state, [key] : value });
    }

    const uploadFile = ( response ) => {
        let { attachments } = state;
        if( !attachments  ){
            attachments = [];
        }
        attachments.push(response);
        setState({ ...state, attachments });
    }

    const removeFile = ( index ) => {
        let { attachments  }=  state ;
        attachments.splice( index, 1 );
        setState({ ...state, attachments });
    }

    useEffect(() => {
        if( props.type === 'edit' ){
            let attachment_id = null;
            setState( { ...omit(props.state, 'attachment'), attachments : props.state.attachment ? [ props.state.attachment ] : [] } );
            attachment_id = props.state && props.state.attachment && props.state.attachment.attachment_id;
            setAttachmentMemo([attachment_id]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <Modal
                isOpen={is_open}
                toggle={() => hide()}
                className="tt-modal--admin modal-dialog-centered"
            >
                    <ModalHeader toggle={() => hide()}>{ props.type === 'edit'  ? 'Edit Testimonial' : 'Add New Testimonial'}</ModalHeader>
                    <ModalBody>
                        <Form className="tt-admin-form">
                            <div className="tt-admin-form-inner p-0">
                                <Row form>
                                    <Col xs="12" md="6">
                                        <FormGroup>
                                            <Label for="">Name</Label>
                                            <Input
                                                type="text"
                                                value={state.full_name}
                                                onChange={ (e) => handleChange(e.target.value, 'full_name') }
                                                invalid={ !isEmpty(error) && (error.full_name) && true }
                                            />
                                            <ErrorFormFeedback field={'full_name'} errors={error} />
                                        </FormGroup>
                                    </Col>
                                    <Col xs="12" md="6">
                                        <FormGroup>
                                            <Label for="">Order</Label>
                                            <Input
                                                type="text"
                                                value={state.order}
                                                onChange={ (e) => handleChange(e.target.value, 'order') }
                                                invalid={ !isEmpty(error) && (error.order) && true }
                                            />
                                            <ErrorFormFeedback field={'order'} errors={error} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row form>
                                    <Col xs="12">
                                        <FormGroup>
                                            <Label for="">Testimonial Text</Label>
                                            <Input
                                                type="textarea"
                                                value={state.description}
                                                onChange={ (e) => handleChange(e.target.value, 'description') }
                                                invalid={ !isEmpty(error) && (error.description) && true }
                                            />
                                            <ErrorFormFeedback field={'description'} errors={error} />
                                        </FormGroup>
                                    </Col>

                                </Row>
                                <Row form>
                                    <Col xs="12">
                                    { state.attachments && ! isEmpty(state.attachments) ?
                                        <FormGroup>
                                            <Label for="">Uploaded File</Label>
                                            <Table responsive>
                                                <tbody>
                                                    {state.attachments.map( (item, index ) => (
                                                        <tr key={index}>
                                                            <td>
                                                                <p className="resources-upload--name">
                                                                    <img src={` ${item.attachment_url}`} alt="" />
                                                                    {` ${item.filename}`}
                                                                </p>
                                                            </td>
                                                            <td>
                                                                <i
                                                                    className="resources-upload--delete-icon"
                                                                    onClick={() => removeFile(index)}
                                                                >
                                                                    <img
                                                                    src="/images/admin/global/close.svg"
                                                                    alt=""
                                                                />
                                                                </i>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </FormGroup>
                                        :
                                        <FormGroup>
                                            <Label for="">Upload User Avatar</Label>
                                            <div>
                                                <FileUploadComponent
                                                    entity_type='Testimonial'
                                                    multiple={false}
                                                    accepted={['image/*']}
                                                    onSuccess={uploadFile}
                                                    fileUploadLoading={(loading) => setFileUploadLoading(loading)}
                                                />
                                            </div>
                                        </FormGroup>
                                    }
                                    </Col>
                                </Row>
                                <Row form>
                                    <Col xs="12" md="6">
                                        <div>
                                            <Label for="">Initial State</Label>
                                        </div>
                                        <FormGroup check inline>
                                            <CustomInput
                                                type="radio"
                                                id="initialState1"
                                                name="status"
                                                label="Active"
                                                className="mr-5"
                                                value={1}
                                                checked={ Number(state.is_active) === 1 }
                                                onChange={ () => handleChange( '1', 'is_active')}
                                            />
                                            <CustomInput
                                                type="radio"
                                                id="initialState0"
                                                name="status"
                                                label="Inactive"
                                                value={0}
                                                checked={ Number(state.is_active) === 0 }
                                                onChange={ () => handleChange( '0', 'is_active')}
                                             />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </div>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="primary"
                            className="btn-outline-grey"
                            onClick={() => hide()}
                            disabled={loading}
                        >
                            Cancel
                        </Button>
                        <Button
                            color="primary"
                            className="add-new-btn"
                            onClick={addTestimonial}
                            disabled={loading || fileUploadLoading}
                        >
                            { loading ? <Spinner size="sm" /> : 'Save' }
                        </Button>
                    </ModalFooter>
                </Modal>
        </div>
    );
}
