import { useState } from 'react';
import { forEach, isEmpty, isObject } from 'lodash';
import moment from 'moment';

export const UseErrors = () => {
    const [errors, setErrors] = useState({initial: true})

    const getErrors = ( error_hash, data ) => {
        let form_errors = {}
        forEach( error_hash, ( value ) => {
            console.log('from error', value)
            if (

                data[value.key] === ''
                || data[value.key] === undefined
                || data[value.key] === null
                || (isObject(data[value.key]) && isEmpty(data[value.key]) && value.type !== 'date')
                ) {
                form_errors[value.key]  = [`The ${value['label']} field is required`];
            }
            else{
                let data_errors = [];
                if(data[value.key]) {
                    if(value['type']) {
                        switch(value['type']) {
                            case 'number': if(isNaN(Number(data[value.key])) || Math.sign(data[value.key]) === -1)
                                data_errors.push(`The ${value['label']} must be a number`)
                            break;
                            case 'object': if(typeof(data[value.key]) !== 'object')
                                data_errors.push(`The given ${value['label']} is invalid`)
                            break;
                            case 'boolean': if(typeof(data[value.key]) !== 'boolean')
                                data_errors.push(`The ${value['label']} must be either true or false`)
                            break;
                            case 'email': if(!data[value.key].match(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/))
                                data_errors.push(`The ${value['label']} is invalid`)
                            break;
                            // eslint-disable-next-line no-useless-escape
                            case 'phone': if(!data[value.key].match(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/))
                                data_errors.push(`The ${value['label']} is invalid`)
                            break;

                            case 'date': if ( !moment( data[ value.key ] ).isValid )
                                data_errors.push( `The ${ value[ 'label' ] } is invalid` );
                            break;
                            default:
                            break;
                        }
                    }
                }
                if(value.key ===  'date' && !isEmpty(data['date'])) {
                    let date_errors = [];
                    let date_labels = {
                        0: 'Trip start date',
                        1: 'Trip end date'
                    }
                    forEach(data.date, (value, index)  => {
                        if(value) {
                            if(!moment(value).isValid()) {
                                date_errors.push(`${date_labels[index]} is invalid`)
                            }
                        }
                        else {
                            date_errors.push(`${date_labels[index]} field is Required`)
                        }
                    })

                    if(isEmpty(date_errors)) {
                        if(moment(data.date[1]).isBefore(moment(data.date[0]))) {
                            date_errors.push('Trip end date must be greater than Trip start date')
                        }
                    }
                    if(!isEmpty(date_errors))
                    form_errors['date'] = date_errors
                }
                if(value.key === 'accomodating_min_seats' || value.key === 'duration_min_days') {
                    if(Number(data[value.key]) >= Number(data[value.key.replace('min', 'max')])) {
                        data_errors.push(`The ${value['label']} must be less than ${value['label'].replace('Min', 'Max')}`)
                    }
                }
                if(!isEmpty(data_errors))
                form_errors[value.key]  = data_errors;
            }
        })
        setErrors(form_errors)
    }
    return [ errors, getErrors]

}
