import React from "react";
import { CustomInput } from 'reactstrap';

import "js/components/legos/styles/trips-review-card-module.css"

const rated_star = '/images/web/global/star.svg';
const unrated_star = '/images/web/global/star-grey.svg';

export default props => {
    let star_rate = Array.from(Array(5).map((index) => (index)));
    star_rate = star_rate.map((item, index) => {
        let data = props.trip_rating > index ? rated_star: unrated_star;
        return data
    })

    return(
        <div className="review-wrapper">
            <div className="review-slider-top">
                <div className="user-data">
                    {props.tripUserImage ?
                    <div className="user-image">
                        <img src={props.tripUserImage} alt="" />
                    </div>
                    :
                    <img alt="user" src={props.ownerImage ? props.ownerImage : '/images/web/global/logo-icon.svg' } alta="" />
                    }
                    <div className="user-details">
                        <h5>{props.tripUser}</h5>
                        <h6>{props.tripUserType}</h6>
                    </div>
                </div>
                <div className="star-rating">
                    <div className="star-rating--left">
                        {star_rate.map((item, index) => (
                            <img src={item} alt="" key={index}/>
                        ))}
                        <span>{props.trip_rating ? `  ${props.trip_rating.toFixed(1)}`: ' 0.0'}</span>
                    </div>
                    <div className="star-rating--right ">
                        <CustomInput
                            type="switch"
                            id="exampleCustomSwitch2"
                            name="customSwitch"
                            label=""
                            checked={props.review_status}
                            onChange={(e) => props.callBack && props.callBack(e.target.checked)}
                        />
                    </div>
                </div>
            </div>
            <div className="review-slider-bottom">
                <p className="p">{props.tripUserReview}</p>
            </div>
            {/* <div className="review-info">
                <div className="trip-name"><b>Trip: </b>
                <Link to={`/trips/details/${props.trip_id}`}>
                    <span >{props.tripName}</span>
                </Link>

                </div>

                <div className="trip-date"><b>Date:</b> {props.tripDate ? moment(props.tripDate).format('MMM D, YYYY'): ''}</div>
            </div> */}
        </div>
    )
}


