import React from "react"
import Slider from "react-slick";
import { isEmpty } from 'lodash';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import TripIdCard from "../legos/tripIdCard"

export const TripIdSlider = (images, trip_id) => {

    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: false,
        arrows: false,
        dots: true,
        fade: true,
        className: 'trip-id-slider',
        responsive: [
            {
            breakpoint: 1199.98,
            settings: {
                slidesToShow: 1
            }
            },
            {
            breakpoint: 991.98,
            settings: {
                slidesToShow: 1
            }
            },
            {
            breakpoint: 575.98,
            settings: {
                slidesToShow: 1,
                arrows: false
            }
            }
        ]
    };
    return (
        <div>
            {!isEmpty(images) &&
            <Slider {...settings}>
                { images.map((img, index) => (
                <div key ={index}>
                    <TripIdCard
                        linkName="#"
                        tripIdCardImage={img.attachment ? img.attachment.thumbnail_url?img.attachment.thumbnail_url:img.attachment.attachment_url :''}
                        tripId={`#${trip_id}`}
                    />
                </div>
                ))}
            </Slider>
            }
        </div>
    );
}
