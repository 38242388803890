import React, { useState, useContext } from "react";
import { Row, Col, FormGroup, Label, Input, Button, CustomInput, Spinner } from 'reactstrap';
import { Link } from 'react-router-dom';
import {
    CardNumberElement,
    CardCvcElement,
    CardExpiryElement,
    useStripe,
    useElements
} from '@stripe/react-stripe-js';
import { isEmpty, omit } from 'lodash';

import { authServices } from 'js/services';
import { AuthProvider } from 'js/contexts';


export const SignupPayment = (props) => {

    const options = {
        style: {
            base: {
                color: "#424770",
                letterSpacing: "0.025em",
                fontFamily: "Source Code Pro, monospace",
                "::placeholder": {
                    color: "#aab7c4"
                },
                borderRadius: '4px',
                height: 40,
                backgroundColor: '#ffff',
                fontSize:"17px",
                lineHeight:"23px",
            },
            invalid: {
                color: "#9e2146"
            }
        }
    }

    const { auth } = useContext(AuthProvider);
    const [errors, setErrors] = useState({});
    const [stripeErrors, setStripeErrors] = useState({});
    const [state, setState] = useState({
        checked: false
    });
    const [loading, setLoading] = useState(false);
    const stripe = useStripe();
    const elements = useElements();

    const apiCalls = {
        signupTwo: (details, user_id) => {
            setLoading(true);
            authServices.signUpTwo(details, user_id).then((response) => {
                setLoading(false);
                props.parentCallBack('confirmation');
                props.parentCallBackData(response);
            }).catch((err) => {
                setLoading(false);
                let error = err && err.data && err.data.errors;
                setErrors({
                    error
                });
                props.callBackErrors({ ...error });
            });
        }
    }

    const submit = (details) => {
        details.credibility_upgrade_ids = details.credibility_upgrade_id;
        details.membership_plan_upgrade_ids = details.membership_plan_upgrade_id;
        if (!isEmpty(auth)) {
            if (auth.user && auth.user.user_id) {
                apiCalls.signupTwo({ ...omit(details, 'membership_plan_upgrade_id', 'credibility_upgrade_id') }, auth.user.user_id);
            }
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        let details = { ...props.payment_information };
        if (!state.checked) {
            setErrors({ ...errors, 'checkbox': ['Please Accept Terms and Conditions'] });
            return;
        }
        if (!stripe || !elements) {
            return;
        }

        const cardElement = elements.getElement(CardNumberElement);

        const { error, token } = await stripe.createToken(cardElement);

        if (error) {
            setStripeErrors(error);
        } else {
            details.payment_token = token.id;
            setStripeErrors({});
        }

        submit(details);
    };
    const handleChange = (key, value) => {
        setState(state => ({ ...state, [key]: value }))
    }

    return (
        <>
            <Row>
                <Col xs="12">
                    <div className="product-table-wrap">
                        <div>
                            <Row form>
                                <Col xs="12" md="5">
                                    <FormGroup>
                                        <Label for="">Card Number</Label>
                                        <CardNumberElement
                                            className="form-control"
                                            options={options}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col xs="12" md="3">
                                    <FormGroup>
                                        <Label for="">Name on Card</Label>
                                        <Input type="text" name="text" />
                                    </FormGroup>
                                </Col>
                                <Col xs="12" md="2">
                                    <FormGroup>
                                        <Label for="">CVV</Label>
                                        <CardCvcElement
                                            options={options}
                                            className="form-control"
                                        />
                                    </FormGroup>
                                </Col>
                                <Col xs="12" md="2">
                                    <FormGroup>
                                        <Label for="">Exp ( MM / YY )</Label>
                                        <div className="tt-custom-react-select">
                                            <CardExpiryElement
                                                className="form-control"
                                                options={options}
                                            />
                                        </div>
                                    </FormGroup>
                                </Col>
                            </Row>
                            {!isEmpty(stripeErrors) && stripeErrors.message && (
                                <div>
                                    <div className="invalid-feedback d-block">{stripeErrors.message}</div>
                                </div>
                            )}
                            {!isEmpty(errors) && errors.payment_token && (
                                <div>
                                    <div className="invalid-feedback d-block">{errors.payment_token}</div>
                                </div>
                            )}
                        </div>
                        <div className="account-billing-preference-card-payment pt-3">
                            <div className="secure-pay--text"> <img src="/images/web/auth/secure.svg" alt="" /> Pay securely with Credit or Debit Card</div>
                            <div className="secure-pay--card">
                                <img src="/images/web/auth/visa.svg" alt="" />
                                <img src="/images/web/auth/master-card.svg" alt="" />
                                <img src="/images/web/auth/discover.svg" alt="" />
                                <img src="/images/web/auth/american-express.svg" alt="" />
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs="12" md={{ size: 6, offset: 3 }}>
                    <div className="tt-agree-terms">
                        <Label check>
                            <CustomInput id="agree" type="checkbox" className="signup-agreement-checkbox" checked={state.checked} onChange={(e) => handleChange('checked', e.target.checked)} />
                            <span className="agreement-text">I have read and agree to the website <Link target="_blank" to={"/terms-conditions"}>Terms and Conditions.</Link></span>
                        </Label>
                        <div className="invalid-feedback d-block">{errors.checkbox}</div>
                    </div>

                </Col>
            </Row>
            <Row>
                <Col className="d-flex justify-content-center py-5">
                    <Button
                        className="btn btn-outline text-uppercase"
                        onClick={(e) => handleSubmit(e)}
                    >
                        {
                            loading ? <Spinner size="sm"/> : 'Proceed to checkout'
                        }
                    </Button>
                </Col>
            </Row>
        </>
    );
}
