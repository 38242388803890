import { request } from "js/helpers/api";

export const cmsServices = {
    getStaticPage,
    setStaticPage,

    getFooterMenu,
    addFooterMenu,
    editFooterMenu,
    deleteFooterMenu,
    footermenuBulkAction,

    getArticles,
    getArticle,
    addArticle,
    updateArticle,
    deleteArticle,
    articleBulkAction,
    getRecentArticles,

    getPartnerLogos,
    addPartnerLogo,
    updatePartnerLogo,
    deletePartnerLogo,
    partnerLogoBulkAction,

    getFeatureTags,

    getTestimonials,
    getTestimonial,
    addTestimonial,
    updateTestimonial,
    deleteTestimonial,
    testimonialBulkAction
};

function getStaticPage(page){
    return request({
        method:'GET',
        url: `static-content/${page}`,
        content_type: 'json',
        cancel_token : 'cmsServices.getStaticPage'
    });
}

function setStaticPage(page, details){
    return request({
        method:'PUT',
        url: `static-content/${page}`,
        param: details,
        content_type: 'json',
    });
}

function getFooterMenu( params ){
    return request({
        method:'GET',
        url: `footer-menus`,
        param: params,
        cancel_token : 'cmsServices.getFooterMenu'
    });
}
function addFooterMenu( details, hideModal ){
    return request({
        method:'POST',
        url: `footer-menus`,
        param: details,
        content_type: 'json',
        hideModal
    });
}
function editFooterMenu( menu_id, details, hideModal ){
    return request({
        method:'PUT',
        url: `footer-menus/${menu_id}`,
        param: details,
        content_type: 'json',
        hideModal
    });
}
function deleteFooterMenu( menu_id ){
    return request({
        method:'DELETE',
        url: `footer-menus/${menu_id}`,
        param: {},
        content_type: 'json',
    });
}
function footermenuBulkAction( data ){
    return request({
        method:'POST',
        url: `footer-menus/bulk-action`,
        param: data,
        content_type: 'json',
    });
}


function getArticles ( params ){
    return request({
        method:'GET',
        url: `articles`,
        param: params,
        cancel_token : 'cmsServices.getArticles'
    });
}
function getArticle ( article, params ){
    return request({
        method:'GET',
        url: `articles/${article}`,
        param: params,
        cancel_token : 'cmsServices.getArticle'
    });
}

function addArticle ( details ){
    return request({
        method:'POST',
        url: `articles`,
        param: details,
        content_type: 'json',
    });
}

function updateArticle ( article, details ){
    return request({
        method:'PUT',
        url: `articles/${article}`,
        param: details,
        content_type: 'json',
    });
}

function deleteArticle ( article ){
    return request({
        method:'DELETE',
        url: `articles/${article}`,
        param: {},
    });
}

function articleBulkAction( data ){
    return request({
        method:'POST',
        url: `articles/bulk-action`,
        param: data,
        content_type: 'json',
    });
}
function getRecentArticles ( params ){
    return request({
        method:'GET',
        url: `articles`,
        param: params,
        cancel_token : 'cmsServices.getRecentArticles'
    });
}


function getPartnerLogos ( params, cancel_token ){
    return request({
        method:'GET',
        url: `partner-logos`,
        param: params,
        cancel_token : `cmsServices.getPartnerLogos.${cancel_token}`
    });
}

function addPartnerLogo ( details, hideModal ){
    return request({
        method:'POST',
        url: `partner-logos`,
        param: details,
        content_type: 'json',
        hideModal
    });
}

function updatePartnerLogo ( logo_id, details, hideModal ){
    return request({
        method:'PUT',
        url: `partner-logos/${logo_id}`,
        param: details,
        content_type: 'json',
        hideModal
    });
}

function deletePartnerLogo ( logo_id ){
    return request({
        method:'DELETE',
        url: `partner-logos/${logo_id}`,
        param: {},
    });
}

function partnerLogoBulkAction( data ){
    return request({
        method:'POST',
        url: `partner-logos/bulk-action`,
        param: data,
        content_type: 'json',
    });
}

function getFeatureTags ( params ){
    return request({
        method:'GET',
        url: `feature-tags`,
        param: params,
        cancel_token : 'cmsServices.getFeatureTags'
    });
}


function getTestimonials ( params ){
    return request({
        method:'GET',
        url: `testimonials`,
        param: params,
        cancel_token : 'cmsServices.getTestimonials'
    });
}
function getTestimonial ( testimonial_id ){
    return request({
        method:'GET',
        url: `testimonials/${testimonial_id}`,
        param: {},
    });
}

function addTestimonial ( details, hideModal ){
    return request({
        method:'POST',
        url: `testimonials`,
        param: details,
        content_type: 'json',
        hideModal
    });
}

function updateTestimonial ( testimonial_id, details, hideModal ){
    return request({
        method:'PUT',
        url: `testimonials/${testimonial_id}`,
        param: details,
        content_type: 'json',
        hideModal
    });
}

function deleteTestimonial ( testimonial_id ){
    return request({
        method:'DELETE',
        url: `testimonials/${testimonial_id}`,
        param: {},
    });
}

function testimonialBulkAction( data ){
    return request({
        method:'POST',
        url: `testimonials/bulk-action`,
        param: data,
        content_type: 'json',
    });
}
