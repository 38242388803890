import { request } from "js/helpers/api";

export const mcServices = {
    getThreads,
    createThreads,
    getRecent,
    createMessage,
    getMessages,
    getUnread
};

function getThreads(){
    return request({
        method:'GET',
        url: 'message-center/threads',
        param: {},
    });
}

function createThreads(details, hideModal){
    return request({
        method:'POST',
        url: 'message-center/threads',
        param: details,
        content_type: 'json',
    });
}

function createMessage(details, thread_id, hideModal){
    return request({
        method:'POST',
        url: `message-center/threads/${thread_id}/messages`,
        param: details,
        content_type: 'json',
        hideModal
    });
}

function getMessages(thread_id){
    return request({
        method:'GET',
        url: `message-center/threads/${thread_id}/messages`,
        param: {},
    });
}

function getRecent(thread_id){
    return request({
        method:'GET',
        url: `message-center/messages/recent`,
        param: {},
    });
}

function getUnread(params = {}){
    return request({
        method:'GET',
        url: `message-center/messages/unread`,
        param: params,
    });
}
