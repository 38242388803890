import React, { useState, useEffect, useContext } from "react";
import { Row, Col, Button, Input, Table, Form, FormGroup, CustomInput } from 'reactstrap';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { debounce, isEmpty, forEach } from 'lodash';

import { partnerAccountServices } from 'js/services';
import { PaginationComponent, ListLoader } from 'js/components/common';
import { useModal } from 'js/hooks';
import { AuthProvider } from 'js/contexts';
import { history } from 'js/helpers'


export const PartnerAccounts = () => {

    const [state, setState] = useState({
        checked : [],
        loading: false
    });
    const [partner_accounts, setPartnerAccounts] = useState([]);
    const [params, setParam] = useState({});
    const [show, hide] = useModal();
    const { auth } = useContext(AuthProvider);

    const status_lookups = [
        { value: 1, label: 'Active' },
        { value: 0, label: 'Inactive' }
    ];


    const styles = {
        option: (styles, state) => ({
            ...styles,
            backgroundColor: state.isSelected ? "#FFA405 " : state.isFocused ? "#FFA40520" : ""
        })
    };

    const bulk_actions = [
        { value: 'delete', label: 'Delete' }
    ];


    useEffect(() => {
        if(auth.user_role === 'super-admin') {
            setState({...state, loading: true})
            apiCalls.getAll(params);
        }
        else {
            history.push('/admin')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const apiCalls = {
        getAll: debounce((params) => {
            setState({...state, loading: true})
            partnerAccountServices.getAll(params).then((response) => {
                setPartnerAccounts(response);
                setState({...state, loading: false})
            });
        }, 300),
        remove: debounce((partner_id) => {
            partnerAccountServices.remove(partner_id).then(() => {
                let current_params = { ...params };
                if (partner_accounts.data.length === 1) {
                    if (current_params.page !== 1) {
                        current_params.page = current_params.page - 1;
                    }
                }
                hide('ConfirmModal');
                setParam(current_params);
                apiCalls.getAll(current_params);
            })
        }, 300),
        bulkAction: debounce((value, selected_items_array) => {
            partnerAccountServices.bulkAction({ action_type: value, items: selected_items_array })
                .then(() => {
                    apiCalls.getAll(params);
                    hide('ConfirmModal');
                })
                .catch(() => {
                })
        }, 300),
        update: debounce((details, partner_id, value) => {
            partnerAccountServices.update(details, partner_id).then((res) => {
                let data = partner_accounts.data.map(item => {
                    if(item.partner_id === partner_id) {
                        return res
                    }
                    else return item
                })
                let updated_partner_accounts = {
                    ...partner_accounts,
                    data
                }
                setPartnerAccounts(updated_partner_accounts)

            });
        }, 300),
    }

    const onParamsChange = (key, value) => {
        let current_params = { ...params, [key]: value };
        setParam({ ...current_params });

        apiCalls.getAll(current_params);
    }

    const checkbox = (id) => {
        let { checked } = state;
        if( id === 'all'){
            if( state.check_all ) {
                checked = [];
            }
            else{
                forEach(partner_accounts.data, function(item) {
                    checked[item.partner_id] = true;
                });
            }
            setState( {...state, checked, check_all: !state.check_all } );
        }
        else{
            checked[id] ? delete checked[id] :  checked[id] = true;
            setState( {...state, checked} );
        }
    }

    const bulkAction = ( type ) => {
        if( type === 'delete' ){
            let selected_items = Object.keys(state.checked);
            let delete_string = selected_items.length > 1 ? 'Multiple Partners' : 'This Partner'
            if(!isEmpty(selected_items)){
                show('ConfirmModal', {
                    title: `Delete ${delete_string} `,
                    body:  `Do you want to delete ${delete_string} ? `,
                    button_name: 'Delete',
                    action:bulkDelete
                });
            }
        }
    }
    const bulkDelete = () => {
        apiCalls.bulkAction('delete', Object.keys(state.checked));
    }
    const refresh = () =>{
        apiCalls.getAll(params);
    }

    const toggle = (value, partner_id) => {
        let details = value ? { is_active: 1 } : { is_active: 0 };
        apiCalls.update(details, partner_id)
    }
    const deletePartner = ( item ) => {
        apiCalls.remove(item.partner_id);
    }

    return (
        <div>
            <div className="generic-page-header">
                <div className="header-data-section">
                    <h2 className="page-head my-0">Partner Accounts</h2>
                </div>
                <div className="header-data-section">
                    <Button
                        color="primary"
                        className="add-new-btn"
                        onClick={
                            () => show('AddEditPartnerAccountModal',{
                                callBack: refresh
                            })
                        }

                    >
                        <i>
                            <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.03027 7.77295H0.171875V5.6001H5.03027V0.619629H7.22754V5.6001H12.0981V7.77295H7.22754V12.729H5.03027V7.77295Z" fill="#fff" />
                            </svg>
                        </i>Add New Account
                        </Button>
                </div>
            </div>
            <div className="tt-admin-card">
                <div className="tt-admin-card--header tt-admin-card--header_filter">
                    <div className="tt-filter">
                        <Form>
                            <Row className="justify-content-between">
                                <Col xs="12" lg="5">
                                    <Row>
                                        <Col xs="12" lg="6">
                                            <FormGroup>
                                                <div className="custom-react-select--no-border w-100">
                                                    <Select
                                                        placeholder="Bulk Action"
                                                        className="react-select-container"
                                                        classNamePrefix="react-select"
                                                        styles={styles}
                                                        options={bulk_actions}
                                                        onChange={ (e) => bulkAction( e.value) }
                                                    />
                                                </div>
                                            </FormGroup>

                                        </Col>
                                        <Col xs="12" lg="6">
                                            <FormGroup>
                                                <div className="filter-input--outer">
                                                    <Input
                                                        type="text"
                                                        name=""
                                                        id=""
                                                        placeholder="Search by Name or Email"
                                                        onChange={(e) => onParamsChange('search', e.target.value)}
                                                    />
                                                </div>
                                            </FormGroup>
                                        </Col>
                                    </Row>


                                </Col>
                                <Col xs="12" lg="2">
                                    <Row>
                                        <Col xs="12">
                                            <FormGroup>
                                                <div className="custom-react-select--no-border w-100">
                                                    <Select
                                                        placeholder="Status"
                                                        className="react-select-container"
                                                        classNamePrefix="react-select"
                                                        options={status_lookups}
                                                        styles={styles}
                                                        onChange={(e) => { onParamsChange('status', e.value); }}
                                                    />
                                                </div>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </div>
                <div className="tt-admin-card--data">
                    <div className="table-wrap">
                        <Table responsive className="tt-admin-table-compact tt-admin-table-compact--checkbox ">
                            <thead>
                                <tr>
                                    <th><div className="tt-admin-checkbox">
                                        <CustomInput
                                            type="checkbox"
                                            id="tt-check-lead-select-all"
                                            className="tt-checkbox"
                                            checked={state.check_all}
                                            onChange={ () => checkbox('all') }
                                        />
                                    </div>
                                    </th>
                                    <th>Organization</th>
                                    <th>Name</th>
                                    <th>Email Address</th>
                                    <th>Status</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {state.loading ?
                                    <tr>
                                        <td colSpan="6" align="center"><ListLoader /></td>
                                    </tr>
                                    :
                                    partner_accounts && isEmpty(partner_accounts.data) ?
                                        <tr>
                                            <td colSpan="6" align="center">No Records Found.</td>
                                        </tr>
                                        :
                                        partner_accounts && !isEmpty(partner_accounts.data) &&
                                        partner_accounts.data.map((item, index) => (
                                        <tr key={index}>
                                            <td>
                                                <div className="tt-admin-checkbox">
                                                    <CustomInput
                                                        type="checkbox"
                                                        id={item.partner_id}
                                                        className="tt-checkbox"
                                                        checked={ state.checked && state.checked[item.partner_id] ? state.checked[item.partner_id] : false }
                                                        onChange={ () => checkbox(item.partner_id) }
                                                    />
                                                </div>
                                            </td>
                                            <td>{item.company_name}</td>
                                            <td>{`${item.first_name} ${item.last_name}`}</td>
                                            <td>{item.email}</td>
                                            <td>
                                                <div className="ads-switch">
                                                    <CustomInput
                                                        type="switch"
                                                        id={`status${index}switch`}
                                                        name="customSwitch"
                                                        checked={item.is_active ? true : false}
                                                        onChange={(e) => toggle(e.target.checked, item.partner_id)}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="action-wrap">
                                                    <Link
                                                        to={"#"}
                                                        onClick={
                                                            () => show('AddEditPartnerAccountModal',{
                                                                partner_id: item.partner_id,
                                                                callBack: refresh
                                                            })
                                                        }

                                                    >
                                                        <img src="/images/admin/global/edit.svg" alt="" />
                                                    </Link>
                                                    <Link
                                                        to={"#"}
                                                        onClick={() =>
                                                            show('ConfirmModal', {
                                                                title: 'Delete Partner Account',
                                                                body: 'Do you want to delete this Partner Account ?',
                                                                button_name: 'Delete',
                                                                action: () => deletePartner(item),
                                                            })
                                                        }
                                                    >
                                                        <img src="/images/admin/global/delete.svg" alt="" />
                                                    </Link>
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </Table>
                    </div>
                </div>
                {partner_accounts && partner_accounts.meta &&
                    <PaginationComponent page={partner_accounts.meta} onChange={(page) => onParamsChange('page', page)} />
                }
            </div>
        </div>
    );
}

