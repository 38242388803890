import React from "react";
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

export const PaginationComponent = (props) => {

    const onPageChange = (item) => {
        props.onChange(item)
    }

    let page_array = [];
    const { page } = props;
    let current_page = Number(page.current_page);
    let start_page = 1;
    let end_page = 10;
    let total_pages = 0;
    if( page ){
        total_pages = Math.ceil(page.total / page.per_page);
        start_page = Number(current_page) - 4 > 1 ? current_page - 4 : start_page ;
        end_page = Number(start_page) + 9 > total_pages ? total_pages : start_page + 9;
        for(var i= start_page; i<= end_page; i++){
            page_array.push(i);
        }
    }

    return (
        <React.Fragment>
            { page.total > page.per_page &&
                <div className="tt-admin-card--footer tt-admin-card--footer--pagination">
                    <div className="tt-admin-pagination">
                        <Pagination aria-label="Page navigation example">
                                {current_page !== 1 &&
                                    <PaginationItem>
                                        <PaginationLink onClick={() => onPageChange(Number(current_page) -1)}>
                                            Previous
                                        </PaginationLink>
                                    </PaginationItem>
                                }
                                {page_array.map((item, index) => (
                                    <PaginationItem key={index} active={item === current_page}>
                                        <PaginationLink onClick={() => onPageChange(item)} >
                                            {item}
                                        </PaginationLink>
                                    </PaginationItem>
                                ))}
                                { page && current_page !== page.last_page &&
                                    <PaginationItem>
                                        <PaginationLink onClick={() => onPageChange(Number(current_page) +1)}>
                                            Next
                                        </PaginationLink>
                                    </PaginationItem>
                                }
                            </Pagination>

                    </div>
                </div>
            }
        </React.Fragment>
    );
}

