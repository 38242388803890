import React, { useState, useEffect } from "react";
import { Button, Table, CustomInput } from 'reactstrap';
import { Link } from 'react-router-dom';
import { toast } from "react-toastify";
import { debounce, orderBy } from 'lodash';
// import { ReactSortable } from "react-sortablejs";
import { Container, Draggable } from 'react-smooth-dnd';
import arrayMove from 'array-move';
import { BarLoader } from "react-spinners";

import { cmsServices } from 'js/services';
import { useModal } from 'js/hooks';
import { ListLoader } from "js/components/common";
import { utilFunctions } from 'js/components/common/fucntions';

export const FooterMenusCMS = (props) => {

    const [ show, hide ] = useModal();
    const [ menu, setMenu] = useState();
    const [ state, setState ] = useState({
        loading: false
    })
    const [ loader, setLoader ] = useState({});
    const getFooterMenu = () => {
        setState({...state, loading: true})
        cmsServices.getFooterMenu()
        .then((response) => {
            setMenu(orderBy(response, ['order'], ['asc']))
            setState({...state, loading: false})
        }).catch((err) => {
            toast.error('Failed to Load Page');
        });
    }
    useEffect(() => {
        getFooterMenu();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const refreshFooterMenu = () => {
        getFooterMenu();
    }

    const updateMenu = debounce(( item ) => {
        setLoader( loader => ({...loader, [item.slug] : true }));
        item.is_visible = item.is_visible === 1 ? 0 : 1;
        cmsServices.editFooterMenu( item.slug, item )
        .then((response) => {
            setLoader( loader => ({...loader, [item.slug] : false }));
            const new_data = utilFunctions.findAndReplaceArray(menu, { slug : item.slug }, response );
            setMenu([...new_data]);
        }).catch((err) => {
            setLoader( loader => ({...loader, [item.slug] : false }));
            toast.error('Failed to Update Status');
        });
    }, 300 );

    const deleteMenu = debounce(( selected ) => {
        if( selected ){
            cmsServices.deleteFooterMenu( selected.slug )
            .then((response) => {
                toast.success('Deleted Successfully');
                hide('ConfirmModal')
                refreshFooterMenu();
            }).catch((err) => {
                toast.error('Failed to Delete Footer Menu');
            });
        }
    }, 300 );

    const updateSortOrder = debounce((updateHash) => {
        cmsServices.footermenuBulkAction( updateHash );
    }, 300 );

    const setFooterMenuOrder = (dragResult) => {
        const { removedIndex, addedIndex } = dragResult;
        let updateHash = {};
        const newMenu =  arrayMove(menu, removedIndex, addedIndex).map((item, index)=>{
            item.order = index + 1;
            updateHash[item.footer_menu_id] = { order : index + 1  };
            return item;
        });
        setMenu(newMenu)
        updateSortOrder( { type : 'update', items : updateHash} );
    };
    return (
        <div>
            <div className="generic-page-header">
                <div className="header-data-section">
                    <h2 className="page-head my-0">Footer Menu</h2>
                </div>
                <div className="header-data-section">
                    <Button color="primary" className="add-new-btn"
                        onClick={() => show( 'FooterMenuAddEditModal', {
                            callBack: () => refreshFooterMenu(),
                            type: 'add'
                        })
                    }
                    >
                        <i>
                            <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.03027 7.77295H0.171875V5.6001H5.03027V0.619629H7.22754V5.6001H12.0981V7.77295H7.22754V12.729H5.03027V7.77295Z" fill="#fff" />
                            </svg>
                        </i>Add Menu Item
                    </Button>
                </div>
            </div>
            <div className="tt-admin-card">
                <div className="tt-admin-card--data">
                    <div className="table-wrap">
                    {state.loading ?
                        <ListLoader/>
                        :
                        <Table responsive className="tt-admin-table-compact tt-admin-table-compact--checkbox tt-admin-draggable">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th style={{ width: "80%" }}>Menu Label</th>
                                    <th>Visible</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td colSpan="4">
                                        <Container lockAxis="y"  dragHandleSelector=".footer-menu-handle" onDrop={setFooterMenuOrder} dropPlaceholder={{
                                            animationDuration: 150,
                                            showOnTop: true,
                                            className: 'cards-drop-preview'
                                        }} >
                                            { menu && menu.map((item, index) => (
                                                <Draggable key={item.footer_menu_id} className="footer-menu-row">
                                                    <div key={item.footer_menu_id} className="footer-menu-inner">
                                                        <div className="footer-menu-handle" ><img src="/images/admin/static-content/move.svg" alt="" /></div>
                                                        <div className="footer-menu-label">{item.label}</div>
                                                        <div className="footer-menu-switch">
                                                            <div className="ads-switch">
                                                            { loader[item.slug] ?
                                                                    <BarLoader  width={40} color={"#FFA405"} loading={true} />
                                                                :
                                                                <CustomInput
                                                                    id={`visisble${index}`}
                                                                    type="switch"
                                                                    checked={item.is_visible === 1 ? true : false}
                                                                    onChange={() => updateMenu(item)}
                                                                />
                                                            }
                                                            </div>
                                                        </div>
                                                        <div className="action-wrap">
                                                            <Link to={"#"}
                                                                onClick={() => show( 'FooterMenuAddEditModal', {
                                                                        callBack: () => refreshFooterMenu(),
                                                                        state: item,
                                                                        type: 'edit'
                                                                    })
                                                                }
                                                            >
                                                                <img src="/images/admin/global/edit.svg" alt="" />
                                                            </Link>
                                                            <Link
                                                                to={"#"}
                                                                onClick={() =>
                                                                    show('ConfirmModal', {
                                                                        title: 'Delete Footer Menu',
                                                                        body: 'Do you want to delete this Footer Menu ?',
                                                                        button_name: 'Delete',
                                                                        action: () => deleteMenu(item),
                                                                    })
                                                                }
                                                            >
                                                                <img src="/images/admin/global/delete.svg" alt="" />
                                                            </Link>
                                                        </div>

                                                    </div>
                                                </Draggable>
                                            ))}
                                        </Container>
                                    </td>
                                </tr>
                            </tbody>

                        </Table>
                    }
                    </div>
                </div>
            </div>
        </div>
    );
}
