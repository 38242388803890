import { request } from "js/helpers/api";

export const dashboardServices = {
    getDashboard
};

function getDashboard(params = {}) {
    return request({
        method: 'GET',
        url: 'dashboard',
        param: params,
        cancel_token: 'dashboardServices.getDashboard'
    });
}
