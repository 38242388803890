import React,{ useState, useEffect, useContext } from "react";
import { Row, Col, Form, FormGroup, Label, Input, CustomInput } from 'reactstrap';
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';
import moment from 'moment';
import dompurify from 'dompurify';
import { find, isEmpty } from 'lodash';
import { toast } from 'react-toastify';

import SectionHeader from "js/components/legos/sectionHeader";
import GiveAwaySlider from "js/components/legos/giveawaySlider";
import { ErrorFormFeedback } from 'js/components/common';

import { LookupContext } from 'js/contexts';

import { contestServices } from 'js/services';
import { StaticLink } from 'js/components/common';
import { history } from 'js/helpers';


const sanitizer = dompurify.sanitize;

export const ContestsGiveawayDetails = ( props ) => {
    const { location } = props;

    const lookup = useContext(LookupContext);

    const [ state, setState ] = useState ({});
    const [ form, setForm ] = useState({});
    const [ errors, setErrors ] = useState( {} );
    const [ campaign_id, setCamapaignId ] = useState();
    const from = location && location.state && location.state.from ? location.state.from : '';

    const getContest = (slug) => {
        contestServices.get(slug)
        .then((response) => {
            setState({
                ...response,
                applicable_user_type : find(lookup.promotional_campaign_applicable_user_types, ['value',  response.promotional_campaign_applicable_user_type_id])
            } );
            setCamapaignId(response.promotional_campaign_id)
        }).catch((err) => {
            history.push('/')
        });
    }

    useEffect(() => {
        if( props.match && props.match.params && props.match.params.slug  ){
            getContest(props.match.params.slug);
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChange = ( value, key) => {
        setForm(form => ({ ...form, [key] : value }));
    }

    const submit = () => {
        let data = { ...form, promotional_campaign_id : campaign_id};
        contestServices.submitEntry(data)
        .then((response) => {
            toast.success('Contest Entry Submitted Successfully');
            setForm({});
            setErrors('');
        }).catch((error) => {
            if( error.data && error.data.errors ){
                setErrors(error.data.errors);
            }
            else{
                toast.error('Failed To Submitted For Contest');
            }
        });
    }


    return (
        <div>
            <Helmet>
                <title>CONTESTS & GIVEAWAYS - Details - Trips4Trade</title>
                <meta name="description" content={ state.short_description } />
                <meta name="keywords" content="trip4trade, CONTESTS & GIVEAWAYS" />
            </Helmet>

            <div className="inner-banner" style={{ backgroundImage: `url(/images/web/global/banners/static-banner.png)` }}>
                <div className="container">
                    <Row>
                        <Col xs="12">
                            <h1 className="header2 color--white text-uppercase">CONTESTS & GIVEAWAYS</h1>
                        </Col>

                    </Row>
                </div>
            </div>
            <div className="wider-data-frame wider-data-frame--grey">
                <div className="container">
                    <Row>
                        <SectionHeader
                            HeaderText={state.title}
                        />
                    </Row>
                    <Row>
                        <Col xs="12" md={{ size: 10, offset: 1 }} className="d-flex justify-content-center">
                            <ul className="contest-detail-features">
                                <li>
                                    <p><img src="/images/web/contests/t4t-logo.png" alt="" /> <b>{ state.applicable_user_type && state.applicable_user_type.label } </b></p>
                                </li>
                                <li>
                                    <p><img src="/images/web/contests/calender.svg" width="21px" alt="" /> Drawing Deadline: <b>{state.scheduled_at ? moment(state.scheduled_at).format("MMMM DD, YYYY") : '' }</b></p>
                                </li>
                                <li>
                                    <p><img src="/images/web/contests/ad-value.svg" width="23px" alt="" /> Value: <b>{state.value}</b></p>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                    { state.promotional_campaign_media && state.promotional_campaign_media.length > 0 &&
                    <Row>
                        <Col xs="12">
                            <GiveAwaySlider items={state.promotional_campaign_media} />
                        </Col>
                    </Row>
                    }
                    <Row>

                        <Col xs="12" md="6" className="rules-section">
                            <p className="p mb-0">{state.short_description}</p>
                            { state.rules_for_entry &&
                                <React.Fragment>
                                    <div className="contest-rules">
                                        <h2 className="header3 my-3">Rules for Entry</h2>
                                    </div>
                                    <div className="rules-ol" dangerouslySetInnerHTML={{__html: sanitizer(state.rules_for_entry)}} ></div>
                                </React.Fragment>
                            }
                        </Col>
                        <Col xs="12" md="6" className="rules-section">
                            <div className="tt-entry-form-outer">
                                <h2 className="header3 mb-4">Entry Form</h2>
                                <Form className="tt-sidebar-from">
                                    <Row form>
                                        <Col xs="12" md="6">
                                            <FormGroup>
                                                <Label for="">Name <span className="required">*</span></Label>
                                                <Input
                                                    type="text"
                                                    value={form.full_name ? form.full_name : ''}
                                                    onChange = { (e) => handleChange( e.target.value, 'full_name' ) }
                                                    invalid={ !isEmpty(errors) && (errors.full_name) && true }
                                                />
                                                <ErrorFormFeedback field={'full_name'} errors={errors} />
                                            </FormGroup>
                                        </Col>
                                        <Col xs="12" md="6">
                                            <FormGroup>
                                                <Label for="">Phone <span className="required">*</span></Label>
                                                <Input
                                                    type="text"
                                                    value={form.phone ? form.phone : ''}
                                                    onChange = { (e) => handleChange( e.target.value, 'phone' ) }
                                                    invalid={ !isEmpty(errors) && (errors.phone) && true }
                                                />
                                                <ErrorFormFeedback field={'phone'} errors={errors} />
                                            </FormGroup>
                                        </Col>
                                        <Col xs="12">
                                            <FormGroup>
                                                <Label for="">Email <span className="required">*</span></Label>
                                                <Input
                                                    type="email"
                                                    value={form.email ? form.email : ''}
                                                    onChange = { (e) => handleChange( e.target.value, 'email' ) }
                                                    invalid={ !isEmpty(errors) && (errors.email) && true }
                                                />
                                                <ErrorFormFeedback field={'email'} errors={errors} />
                                            </FormGroup>
                                        </Col>
                                        <Col xs="12" className="py-3">
                                            <Row>
                                                <Col xs="12" md="6">
                                                    <FormGroup>
                                                        <b>Trips4Trade Member *</b>
                                                    </FormGroup>

                                                </Col>
                                                <Col xs="12" md="6">
                                                    <FormGroup>
                                                        <Row>
                                                            <Col xs="12" md="6">
                                                                <CustomInput
                                                                    type="radio"
                                                                    id="trip4trade_member_yes"
                                                                    name="customRadio"
                                                                    label="Yes"
                                                                    onChange ={() => handleChange(1, 'is_trips4trade_member' )}
                                                                    checked={form.is_trips4trade_member === 1 || false}
                                                                />
                                                            </Col>
                                                            <Col xs="12" md="6">
                                                                <CustomInput
                                                                    type="radio"
                                                                    id="trip4trade_member_no"
                                                                    name="customRadio"
                                                                    label="No"
                                                                    onChange ={() => handleChange(0, 'is_trips4trade_member')}
                                                                    checked={form.is_trips4trade_member === 0  || false}
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <div className="invalid-feedback d-block">{errors.is_trips4trade_member}</div>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs="12">
                                            <FormGroup>
                                                <Label for="">What is a trip on your bucket list? <span className="required">*</span></Label>
                                                <Input
                                                    type="textarea"
                                                    value={form.trip_on_bucket_list ? form.trip_on_bucket_list : ''}
                                                    onChange = { (e) => handleChange( e.target.value, 'trip_on_bucket_list' ) }
                                                    invalid={ !isEmpty(errors) && (errors.trip_on_bucket_list) && true }
                                                />
                                                <ErrorFormFeedback field={'trip_on_bucket_list'} errors={errors} />
                                            </FormGroup>
                                        </Col>
                                        <Col xs="12" className="d-flex justify-content-center mt-3">
                                            <Link to={"#"} onClick={submit} className="btn btn-outline">SUBMIT ENTRY</Link>
                                        </Col>
                                    </Row>
                                </Form>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
            <div className="wider-data-frame">
                <div className="container">
                    <Row>
                        <Col xs="12">
                            <h2 className="header3 mb-3">Details</h2>
                            <p className="p">{state.description}</p>
                        </Col>
                    </Row>
                </div>
            </div>
            <div className="wider-data-frame wider-data-frame--grey">
                <div className="container">
                    {state.promotional_campaign_milestones && !isEmpty(state.promotional_campaign_milestones) &&
                    <>
                    <Row>
                        <Col xs="12">
                            <h2 className="header3">Giveaway Milestones</h2>
                        </Col>
                    </Row>
                    <Row>
                        { state.promotional_campaign_milestones.map( (item, index) =>(
                            <Col xs="12" md="6" key={index}>
                                <div className="milestone-card">
                                    <div className="ms-img">
                                        <StaticLink
                                            url={item.url}
                                            content={
                                                <img
                                                    src={ item.attachment ?
                                                        item.attachment.thumbnail_url ?
                                                            item.attachment.thumbnail_url : item.attachment.attachment_url
                                                        : '' }
                                                    alt={item.description}
                                                    style={ { width: '100%' } }
                                                />
                                            }
                                        />
                                    </div>

                                    <p>{item.description}</p>
                                </div>
                            </Col>
                        ))}
                    </Row>
                    </>
                    }
                    {state.checklist && !isEmpty(state.checklist) &&
                    <>
                    <Row>
                        <Col xs="12">
                            <h2 className="header3 my-5">Your Checklist to Win</h2>
                        </Col>
                    </Row>
                    <Row className="checklist-wrap">
                        {state.checklist.map( (item, index) =>(
                            <Col xs="12" md="3" key={index}>
                                <StaticLink url={item.link} content={item.label} key={index} class="btn-outline btn-block" />
                            </Col>
                        ))}
                    </Row>
                    <Row>
                        <Col xs="12" className="d-flex justify-content-center custom-mt-100">
                            <Link
                                className="btn btn-outline"
                                to={from && from === 'admin' ? '/admin/contest-giveaways' : '/contests-giveaways'}
                            >
                                Back to Contests & Giveaways
                            </Link>
                        </Col>
                    </Row>
                    </>
                    }
                </div>
            </div>
        </div>
	);
}
