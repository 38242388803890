import { Dashboard } from "js/components/admin/components/dashboard/dashboard";
import { TripManagement } from "js/components/admin/components/trip-management/trip-management";
import { TripDetails } from "js/components/admin/components/trip-management/details/details";
import { UserManagement } from "js/components/admin/components/user-management/user-management";
import { UserDetails } from "js/components/admin/components/user-management/details/details";
import { Leads } from "js/components/admin/components/leads/leads";
import { LeadsDetails } from "js/components/admin/components/leads/details/details";
import { Exchanges } from "js/components/admin/components/exchanges/exchanges";
import { Advertising } from "js/components/admin/components/advertising/advertising";
import { PartnerAccounts } from "js/components/admin/components/partner-accounts/partner-accounts";
import { SupportEmails } from "js/components/admin/components/support-emails/support-emails";
import { MembershipPlans } from "js/components/admin/components/membership-plans/membership-plans";
import { StaticContent } from "js/components/admin/components/static-content/static-content";
import { AboutUsCMS } from "js/components/admin/components/static-content/about-us/about-us";
import { TestimonialsCMS } from "js/components/admin/components/static-content/testimonials/testimonials";
import { PartnerLogosCMS } from "js/components/admin/components/static-content/partner-logos/partner-logos";
import { ArticlesCMS } from "js/components/admin/components/static-content/articles/articles";
import { AddNewArticle } from "js/components/admin/components/static-content/articles/add-new-article/add-new-article";
import { TermsConditionsCMS } from "js/components/admin/components/static-content/terms-conditions/terms-conditions";
import { HowItWorksCMS } from "js/components/admin/components/static-content/how-it-works/how-it-works";
import { FooterMenusCMS } from "js/components/admin/components/static-content/footer-menu/footer-menu";
import { Settings } from "js/components/admin/components/static-content/settings/settings";
import { ContestsGiveaways } from "js/components/admin/components/contest-giveaways/contest-giveaways";
import { AddContest } from "js/components/admin/components/contest-giveaways/add-contest/add-contest";
import { EditContest } from "js/components/admin/components/contest-giveaways/edit-contest/edit-contest";
import { RevenueReport } from "js/components/admin/components/reports/revenue/revenue";
import { WebsiteTrafficReport } from "js/components/admin/components/reports/website-traffic/website-traffic";
import { ExchangesReport } from "js/components/admin/components/reports/exchanges/exchanges";
import { TripsReport } from "js/components/admin/components/reports/trips/trips";
import { UsersReport } from "js/components/admin/components/reports/users/users";
import { AddTrip } from "js/components/admin/components/trip-management/details/add-trip";
import { CreateUser } from 'js/components/admin/components/user-management/details/create-user';
import { CredibilityUpgrades } from 'js/components/admin/components/membership-plans/credibility-upgrades';
import { MembershipUpgrades } from 'js/components/admin/components/membership-plans/membership-upgrades.js'
import { EditMembershipPlan } from 'js/components/admin/components/membership-plans/edit-membership-plan';
import { Notifications } from 'js/components/admin/components/notifications/notifications';
import { Promotions } from 'js/components/admin/components/promotions/promotions';

export const portalRoutes = [
    {
        path: "/admin/reports/users",
        component: UsersReport
    },
    {
        path: "/admin/reports/trips",
        component: TripsReport
    },
    {
        path: "/admin/reports/exchanges",
        component: ExchangesReport
    },
    {
        path: "/admin/reports/website-traffic",
        component: WebsiteTrafficReport
    },
    {
        path: "/admin/reports/revenue",
        component: RevenueReport
    },
    {
        path: "/admin/contest-giveaways/add-contest",
        component: AddContest
    },
    {
        path: "/admin/contest-giveaways/:slug",
        component: EditContest
    },
    {
        path: "/admin/contest-giveaways",
        component: ContestsGiveaways
    },

    {
        path: "/admin/static-content/footer-menu",
        component: FooterMenusCMS
    },
    {
        path: "/admin/static-content/how-it-works",
        component: HowItWorksCMS
    },
    {
        path: "/admin/static-content/terms-conditions",
        component: TermsConditionsCMS
    },
    {
        path: "/admin/static-content/experience/add-new-article",
        component: AddNewArticle
    },
    {
        path: "/admin/static-content/experience/:article",
        component: AddNewArticle
    },
    {
        path: "/admin/static-content/experience",
        component: ArticlesCMS
    },
    {
        path: "/admin/static-content/partner-logos",
        component: PartnerLogosCMS
    },
    {
        path: "/admin/static-content/testimonials",
        component: TestimonialsCMS
    },
    {
        path: "/admin/static-content/about-us",
        component: AboutUsCMS
    },
    {
        path: "/admin/static-content/settings",
        component: Settings
    },
    {
        path: "/admin/static-content",
        component: StaticContent
    },
    {
        path: "/admin/membership-plans",
        component: MembershipPlans
    },
    {
        path: "/admin/membership-plan-edit/:membership_plan_id",
        component: EditMembershipPlan
    },
    {
        path: "/admin/membership-upgrades",
        component: MembershipUpgrades
    },
    {
        path: "/admin/credibility-upgrades",
        component: CredibilityUpgrades
    },
    {
        path: "/admin/support-emails",
        component: SupportEmails
    },
    {
        path: "/admin/partner-accounts",
        component: PartnerAccounts
    },
    {
        path: "/admin/advertising",
        component: Advertising
    },
    {
        path: "/admin/promotions",
        component: Promotions
    },
    {
        path: "/admin/exchanges",
        component: Exchanges
    },
    {
        path: "/admin/leads/details/:lead_id",
        component: LeadsDetails
    },
    {
        path: "/admin/leads/add-lead",
        component: LeadsDetails
    },
    {
        path: "/admin/leads/:page",
        component: Leads
    },
    {
        path: "/admin/leads",
        component: Leads
    },
    {
        path: "/admin/user-management/add-user",
        component: CreateUser
    },
    {
        path: "/admin/user-management/:user_id",
        component: UserDetails
    },
    {
        path: "/admin/user-management",
        component: UserManagement
    },
    {
        path: "/admin/trip-management/details/:slug",
        component: TripDetails
    },
    {
        path: "/admin/trip-management/add",
        component: AddTrip
    },
    {
        path: "/admin/trip-management",
        component: TripManagement
    },

    {
        path: "/admin/dashboard",
        component: Dashboard
    },
    {
        path: "/admin/notifications",
        component: Notifications
    },
    {
        path: "/admin",
        component: Dashboard
    },
    {
        path: "/",
        component: Dashboard
    }
];
