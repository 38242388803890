import React, { useState, useEffect } from "react";
import { Row, Col, Button, Table, Form, FormGroup, CustomInput } from 'reactstrap';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { debounce, forEach, isEmpty } from 'lodash';
import { BarLoader } from "react-spinners";

import { useParamChange, useModal } from 'js/hooks';
import { cmsServices } from 'js/services';
import { PaginationComponent, ListLoader } from 'js/components/common';
import { utilFunctions } from 'js/components/common/fucntions';

const styles = {
    option: (styles, state) => ({
        ...styles,
        backgroundColor: state.isSelected ? "#FFA405 " : state.isFocused ? "#FFA40520" : ""
    })
};

const status_dropdown = [
    { value: 1, label: 'Active ' },
    { value: 0, label: 'Inactive' },
];

const bulk_actions = [
    { value: 'delete', label: 'Delete' }
];

export const TestimonialsCMS = () => {

    const [testimonials, setTestimonials] = useState({});
    const [state, setState] = useState({
        checked: [],
        check_all: false,
        loading: false
    });
    const [ loader, setLoader ] = useState({});

    const getTestimonials = (params) => {
        setState({ ...state, loading: true })
        cmsServices.getTestimonials(params)
            .then((response) => {
                setTestimonials(response);
                setState({ ...state, loading: false })
            }).catch((err) => {
            });
    }

    const [show, hide] = useModal();
    const [params, onParamsChange] = useParamChange(getTestimonials);

    const updateStatus = debounce((item) => {
        setLoader( loader => ({...loader, [item.testimonial_id] : true }));
        let data = { is_active: Number(item.is_active) === 1 ? 0 : 1 };
        cmsServices.updateTestimonial(item.testimonial_id, data)
            .then((response) => {
                setLoader( loader => ({...loader, [item.testimonial_id] : false }));
                const new_testimonials = utilFunctions.findAndReplaceArray(testimonials.data, { testimonial_id : item.testimonial_id }, response );
                setTestimonials(testimonials => ( { ...testimonials, data: new_testimonials } ));
            }).catch((error) => {
                setLoader( loader => ({...loader, [item.testimonial_id] : false }));
            });
    }, 300);

    const deleteTestimonial = (selected) => {
        if (selected) {
            cmsServices.deleteTestimonial(selected.testimonial_id).then(() => {
                getTestimonials(params);
                hide('ConfirmModal');
            }).catch((err) => {
            })
        }
    }

    const checkbox = (id) => {
        let { checked } = state;
        if (id === 'all') {
            if (state.check_all) {
                checked = [];
            }
            else {
                forEach(testimonials.data, function (item) {
                    checked[item.testimonial_id] = true;
                });
            }
            setState({ ...state, checked, check_all: !state.check_all });
        }
        else {
            checked[id] ? delete checked[id] : checked[id] = true;
            setState({ ...state, checked });
        }
    }

    const bulkAction = (type) => {
        let selected_array = Object.keys(state.checked);
        if (selected_array.length > 0) {
            if (type === 'delete') {
                let type_string = selected_array.length > 1 ? 'Multiple ' : 'This';
                show('ConfirmModal', {
                    title: `Delete ${type_string} Testimonials`,
                    body: `Do you want to delete ${type_string} Testimonials ?`,
                    button_name: 'Delete',
                    action: () => bulkDelete(),
                });
            }
        }
    }

    const bulkDelete = () => {
        let data = {
            'action_type': 'delete',
            'items': Object.keys(state.checked)
        };

        cmsServices.testimonialBulkAction(data)
            .then((response) => {
                hide('ConfirmModal');
                getTestimonials(params);
            });
    }

    useEffect(() => {
        getTestimonials();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <div className="generic-page-header">
                <div className="header-data-section">
                    <h2 className="page-head my-0">Testimonials</h2>
                </div>
                <div className="header-data-section">
                    <Button
                        color="primary"
                        className="add-new-btn"
                        onClick={() =>
                            show('TestimonialAddEditModal', {
                                type: 'add',
                                callBack: () => getTestimonials(params)
                            })
                        }
                    >
                        <i>
                            <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.03027 7.77295H0.171875V5.6001H5.03027V0.619629H7.22754V5.6001H12.0981V7.77295H7.22754V12.729H5.03027V7.77295Z" fill="#fff" />
                            </svg>
                        </i>Add Testimonial
                    </Button>
                </div>
            </div>
            <div className="tt-admin-card">
                <div className="tt-admin-card--header tt-admin-card--header_filter">
                    <div className="tt-filter">
                        <Form>
                            <Row className="justify-content-between">
                                <Col xs="12" lg="5">
                                    <Row>
                                        <Col xs="12" lg="6">
                                            <FormGroup>
                                                <div className="custom-react-select--no-border w-100">
                                                    <Select
                                                        className="react-select-container"
                                                        classNamePrefix="react-select"
                                                        options={bulk_actions}
                                                        styles={styles}
                                                        placeholder="Bulk Actions"
                                                        onChange={(e) => bulkAction(e.value)}
                                                    />
                                                </div>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs="12" lg="2">
                                    <Row>
                                        <Col xs="12">
                                            <FormGroup>
                                                <div className="custom-react-select--no-border w-100">
                                                    <Select
                                                        className="react-select-container"
                                                        classNamePrefix="react-select"
                                                        options={status_dropdown}
                                                        styles={styles}
                                                        onChange={(e) => onParamsChange('status', e.value)}
                                                    />
                                                </div>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </div>
                <div className="tt-admin-card--data">
                    <div className="table-wrap">
                        <Table responsive className="tt-admin-table-compact tt-admin-table-compact--checkbox ">
                            <thead>
                                <tr>
                                    <th>
                                        <div className="tt-admin-checkbox">
                                            <CustomInput
                                                type="checkbox"
                                                id="tt-check-1"
                                                label=""
                                                className="tt-checkbox"
                                                checked={state.check_all}
                                                onChange={() => checkbox('all')}
                                            />
                                        </div>
                                    </th>
                                    <th>Avatar</th>
                                    <th>Name</th>
                                    <th className="text-left">Testimonial</th>
                                    <th className="sortable" onClick={() => onParamsChange('sort', 'order')}  >Order</th>
                                    <th className="sortable" onClick={() => onParamsChange('sort', 'status')}  >Status</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                            {state.loading ?
                                <tr>
                                    <td colSpan="7" align="center"><ListLoader /></td>
                                </tr>
                                :
                                testimonials && isEmpty(testimonials.data) ?
                                    <tr>
                                        <td colSpan="7" align="center">No Records Found.</td>
                                    </tr>
                                    :
                                    testimonials && testimonials.data && testimonials.data.map((item, index) => (
                                    <tr key={index}>
                                        <td>
                                            <div className="tt-admin-checkbox">
                                                <CustomInput
                                                    type="checkbox"
                                                    id={`checkbox${item.testimonial_id}`}
                                                    label=""
                                                    className="tt-checkbox"
                                                    checked={state.checked[item.testimonial_id] ? state.checked[item.testimonial_id] : ''}
                                                    onChange={() => checkbox(item.testimonial_id)}
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <img
                                                src={item.attachment ? item.attachment.thumbnail_url ? item.attachment.thumbnail_url : item.attachment.attachment_url : ''}
                                                style={{ width: '50px', height: '50px', overflow: 'hidden' }}
                                                alt={item.attachment && item.attachment.attachment_url ? item.full_name : ''}
                                                className="rounded-img"
                                            />
                                        </td>
                                        <td>{item.full_name}</td>
                                        <td className="text-left">{item.description.substring(0, 100)}{item.description.length > 100 && '...'}
                                            {/* <Link className="view-more" to={"#"}> View More</Link> */}
                                        </td>
                                        <td>{item.order}</td>
                                        <td>
                                            <div className="ads-switch">
                                                { loader[item.testimonial_id] ?
                                                    <BarLoader  width={40} color={"#FFA405"} loading={true} />
                                                :
                                                    <CustomInput
                                                        type="switch"
                                                        id={item.slug}
                                                        checked={Number(item.is_active) === 1 ? true : false}
                                                        onChange={() => updateStatus(item)}
                                                    />
                                                }
                                            </div>
                                        </td>
                                        <td>
                                            <div className="action-wrap">
                                                <Link
                                                    to={"#"}
                                                    onClick={() =>
                                                        show('TestimonialAddEditModal', {
                                                            state: item,
                                                            type: 'edit',
                                                            callBack: () => getTestimonials(params)
                                                        })
                                                    }
                                                >
                                                    <img src="/images/admin/global/edit.svg" alt="" />
                                                </Link>
                                                <Link
                                                    to={"#"}
                                                    onClick={() =>
                                                        show('ConfirmModal', {
                                                            title: 'Delete Testimonial',
                                                            body: 'Do you want to delete this Testimonial ?',
                                                            button_name: 'Delete',
                                                            action: () => deleteTestimonial(item)
                                                        })
                                                    }
                                                >
                                                    <img src="/images/admin/global/delete.svg" alt="" />
                                                </Link>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                </div>
                {testimonials && testimonials.meta &&
                    <PaginationComponent page={testimonials.meta} onChange={(page) => onParamsChange('page', page)} />
                }
            </div>
        </div>


    );

}
