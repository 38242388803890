import React, { useState, useEffect } from "react";
import { Row, Col } from 'reactstrap';
import { forEach, isEmpty } from 'lodash';

import { tradeServices } from 'js/services';
import { TradeInProgressComponent, PaginationComponent, ListLoader } from 'js/components/common';


export const UserTradeInProgress = (props) => {

    const { user_id } = props;


    const [in_progress, setInprogress] = useState([]);
    const [completed, setCompleted] = useState([]);
    const [canceled, setCanceled] = useState([]);
    const [params, setParams] = useState({
        user_id: user_id
    });

    const [loading, setLoading] = useState({});

    const apiCalls = {
        getAllTrades: (params) => {
            setLoading(loading => ({
                ...loading,
                [params.status]: true
            }));
            tradeServices.getAll(params).then((response) => {
                setLoading(loading => ({
                    ...loading,
                    [params.status]: false
                }));
                if (params.status === 'in-progress') {
                    setInprogress(response);
                }
                else if (params.status === 'completed') {
                    setCompleted(response);
                }
                else {
                    setCanceled(response);
                }
            })
                .catch(() => {
                    setLoading(loading => ({
                        ...loading,
                        [params.status]: false
                    }));
                });
        },
    }

    useEffect(() => {
        let status = ['in-progress', 'completed', 'canceled'];
        forEach(status, (item) => {
            apiCalls.getAllTrades({ status: item, ...params });
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onParamsChange = (page, status) => {
        let selected_params = { ...params };
        selected_params.page = page;
        selected_params.status = status;

        setParams(selected_params);
        apiCalls.getAllTrades(selected_params);
    }
    return (
        <Row className="tt-admin-form">
            <div className="tt-admin-form-inner user-trade-in-progress">
                <Row>
                    <Col xs="12">
                        <h4 className="in-form-heading im-form-heading--black"><span>Trade In Progress</span></h4>
                    </Col>
                </Row>
                {loading['in-progress'] ?
                    <ListLoader />
                    :
                    <Row className="tt-card-trade-in-progress">
                        {
                            in_progress && !isEmpty(in_progress.data) && in_progress.data.map((item, index) => (
                                <TradeInProgressComponent
                                    trade={item}
                                    user_id={Number(user_id)}
                                    key={`in-progress${index}`}
                                    type="admin"
                                />
                            ))
                        }
                    </Row>
                }
                <Row>
                    {in_progress && in_progress.meta &&
                        <PaginationComponent
                            page={in_progress.meta}
                            onChange={(page) => onParamsChange(page, 'in-progress')}
                        />
                    }
                </Row>
                <Row>
                    <Col xs="12">
                        <h4 className="in-form-heading im-form-heading--black"><span>Completed Trips</span></h4>
                    </Col>
                </Row>
                {loading.tbd ?
                    <ListLoader />
                    :
                    <Row className="tt-card-trade-in-progress" >
                        {
                            completed && !isEmpty(completed.data) && completed.data.map((item, index) => (

                                <TradeInProgressComponent
                                    trade={item}
                                    user_id={Number(user_id)}
                                    key={`completed${index}`}
                                    type="admin"
                                />
                            ))

                        }
                    </Row>
                }
                < Row >
                    {completed && completed.meta &&
                        <PaginationComponent
                            page={completed.meta}
                            onChange={(page) => onParamsChange(page, 'tbd')}
                        />
                    }
                </Row>
                <Row>
                    <Col xs="12">
                        <h4 className="in-form-heading im-form-heading--black"><span>Canceled Trips</span></h4>
                    </Col>
                </Row>
                {loading.canceled ?
                    <ListLoader />
                    :
                    <Row className="tt-card-trade-in-progress">
                        {
                            canceled && !isEmpty(canceled.data) && canceled.data.map((item, index) => (
                                <TradeInProgressComponent
                                    trade={item}
                                    user_id={Number(user_id)}
                                    key={`canceled${index}`}
                                    type="admin"
                                />
                            ))
                        }
                    </Row>
                }
                <Row>
                    {canceled && canceled.meta &&
                        <PaginationComponent
                            page={canceled.meta}
                            onChange={(page) => onParamsChange(page, 'canceled')}
                        />
                    }
                </Row>
            </div>

        </Row >

    );
}
