import { request } from "js/helpers/api";

export const advertisementServices = {
    getAll,
    create,
    update,
    remove,
    get,
    getRandomisedLimited,
    updateClickCount
};

function getAll(params = {}){
    return request({
        method:'GET',
        url: 'advertisements',
        param: params,
    });
}

function create(details, hideModal){
    return request({
        method:'POST',
        url: 'advertisements',
        param: details,
        content_type: 'json',
        hideModal
    });
}

function update(details, advertisement_id, hideModal){
    return request({
        method:'PUT',
        url: `advertisements/${advertisement_id}`,
        param: details,
        content_type: 'json',
        cancel_token: 'advertisementServices.update',
        hideModal
    });
}

function remove(advertisement_id){
    return request({
        method:'DELETE',
        url: `advertisements/${advertisement_id}`,
        param: {},
    });
}

function get(advertisement_id, hideModal){
    return request({
        method:'GET',
        url: `advertisements/${advertisement_id}`,
        param: {},
        hideModal
    });
}

function getRandomisedLimited(params = {}){
    return request({
        method:'GET',
        url: `advertisements/random-limited-list`,
        param: params,
    });
}

function updateClickCount(advertisement_id){
    return request({
        method:'GET',
        url: `advertisements/${advertisement_id}/click`,
        param: {},
    });
}
