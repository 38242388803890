import React, { useState, useEffect } from "react";
import {Row, Col, Button, Form, FormGroup, Input, Label, Modal, ModalHeader, ModalBody, ModalFooter, Spinner, Table } from 'reactstrap';
import { isEmpty } from 'lodash';

import { contestServices } from 'js/services';
import { ErrorFormFeedback, FileUploadComponent } from 'js/components/common';

export const MilestoneAddEditModal = (props) => {

    const { is_open, hide } = props;

    const [ state, setState] = useState({
        description: '',
        attachment: '',
        attachment_temp: '',
        url: ''
    });
    const [ errors, setError ] = useState();
    const [ loading, setLoading ] = useState();

    const handleChange = ( value, key ) => {
        setState({ ...state, [key] : value });
    }

    const uploadFile = ( response ) => {
        setState({ ...state, attachment : response });
    }
    const removeFile = ( ) => {
        setState({ ...state, attachment : '' });
    }

    const addMileStone = () => {
        let data = {
            description : state.description,
            attachments: state.attachment && state.attachment_temp !== state.attachment ? [ state.attachment.attachment_id ] : [],
            url: state.url
        }
        setLoading(true);
        if( props.type === 'edit' && state.promotional_campaign_milestone_id ) {
            contestServices.editMileStone( data, state.promotional_campaign_milestone_id, hide  )
            .then(function (response) {
                props.callBack && props.callBack( response );
                setLoading(false);
            })
            .catch(function (error) {
                setLoading(false);
                error && error.data && error.data.errors && setError(error.data.errors);
                return;
            });
        }
        else{
            data.promotional_campaign_id = props.promotional_campaign_id;
            contestServices.addMileStone( data, hide )
            .then(function (response) {
                setLoading(false);
                props.callBack && props.callBack( response );
            })
            .catch(function (error) {
                setLoading(false);
                error && error.data && error.data.errors && setError(error.data.errors);
                return;
            });
        }
    }

    useEffect(() => {
        if( props.type === 'edit' ){
            setState( { ...props.state, attachment_temp: props.state.attachment  });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <div>
            <Modal
                isOpen={is_open}
                toggle={() => hide()}
                className="tt-modal--admin modal-dialog-centered"
            >
                    <ModalHeader toggle={() => hide()}>{ props.type === 'edit'  ? 'Edit Milestone' : 'Add New Milestone'}</ModalHeader>
                    <ModalBody>
                        <Form className="tt-admin-form">
                            <div className="tt-admin-form-inner p-0">
                                <Row form>
                                    <Col xs="12">
                                        <FormGroup>
                                            <Label for="">Milestone Description</Label>
                                            <Input
                                                type="textarea"
                                                value={state.description}
                                                onChange={ (e) => handleChange(e.target.value, 'description') }
                                                invalid={ !isEmpty(errors) && (errors.description) && true }
                                            />
                                            <ErrorFormFeedback field={'description'} errors={errors} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row form>
                                    <Col xs="12">
                                        <FormGroup>
                                            <Label for="">Milestone Link</Label>
                                            <Input
                                                type="text"
                                                value={state.url}
                                                onChange={ (e) => handleChange(e.target.value, 'url') }
                                                invalid={ !isEmpty(errors) && (errors.url) && true }
                                            />
                                            <ErrorFormFeedback field={'url'} errors={errors} />
                                        </FormGroup>
                                    </Col>

                                </Row>
                                <Row form>
                                    <Col xs="12">
                                    { state.attachment ?
                                        <FormGroup>
                                            <Label for="">Uploaded File</Label>
                                            <Table responsive>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <p className="resources-upload--name">
                                                                <img src={state.attachment.thumbnail_url ? state.attachment.thumbnail_url : state.attachment.attachment_url} alt="" />
                                                                {` ${state.attachment.filename}`}
                                                            </p>
                                                        </td>
                                                        <td>
                                                            <i
                                                                className="resources-upload--delete-icon"
                                                                onClick={() => removeFile()}
                                                            >
                                                                <img
                                                                src="/images/admin/global/close.svg"
                                                                alt=""
                                                            />
                                                            </i>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </FormGroup>
                                        :
                                        <FormGroup>
                                            <Label for="">Upload Logo</Label>
                                            <div>
                                                <FileUploadComponent
                                                    entity_type='PromotionalCampaignMilestone'
                                                    multiple={false}
                                                    accepted={['image/*']}
                                                    onSuccess={uploadFile}
                                                />
                                            </div>
                                        </FormGroup>
                                    }
                                    { errors &&  errors.attachments &&  errors.attachments.map( (item,index) =>(
                                    <div className="invalid-feedback d-block">{item}</div>
                                    ))}
                                    </Col>
                                </Row>
                            </div>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="primary"
                            className="btn-outline-grey"
                            onClick={() => hide()}
                            disabled={loading}
                        >
                            Cancel
                        </Button>
                        <Button
                            color="primary"
                            className="add-new-btn"
                            onClick={addMileStone}
                            disabled={loading}
                        >
                        {loading ?
                            <Spinner size="sm"/> : 'Save'
                        }
                        </Button>
                    </ModalFooter>
                </Modal>
        </div>
    );
}
