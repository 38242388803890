import React from "react";
import { Row, Col, Button, Input, Table, Form, FormGroup, CustomInput } from 'reactstrap';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { tripsServices } from "js/services";
import { useState, useEffect, useContext } from "react";
import { isEmpty, forEach } from 'lodash';

import { PaginationComponent, ListLoader, TreeContainer } from "js/components/common";
import { LookupContext } from 'js/contexts';
import { useParamChange, useModal } from 'js/hooks';
import { history } from 'js/helpers';
import { utilFunctions } from "js/components/common/fucntions";

const icons = {
    approved : "/images/admin/global/status/success.svg",
    pending: "/images/admin/global/status/warning.svg",
    rejected: "/images/admin/global/status/error.svg"
}

const styles = {
    option: (styles, state) => ( {
        ...styles,
        backgroundColor: state.isSelected ? "#FFA405 " : state.isFocused ? "#FFA40520" : "#ffffff"
    })
};

export const TripManagement = (props) => {
    const [ trips, setTrips ] = useState({});
    const [ state, setState ] = useState({
        show_modal: false,
        selected_trip: {},
        checked_trips: {},
        is_checked_all: false,
        loading: false
    });
    const [ show, hide ] = useModal();
    const lookups = useContext( LookupContext );
    const [ category_lookups, setCategoryLookups ] = useState( [] );
    const bulk_action_lookup = [
        { value: 'delete', label: 'Delete' },
    ];
    const featuredOptions = [
        { value: '', label: 'All' },
        { value: '1', label: 'Featured' },
    ]

    const getAllTrips = (params) => {
        setState({...state, loading: true})
        tripsServices.getAll(params).then(response => {
            if(isEmpty(response.data) && params.page > 1) {
                getAllTrips({...params, page: Number(params.page) -1})
            }
            else {
                setTrips(response)
                setState({...state, loading: false})
            }

        }).catch(error => {
            console.log(error)
        })
    }
    const [ params, onParamsChange ] = useParamChange( getAllTrips );
    const modal_props = {
        title: 'Delete Trip',
        button_name: 'Delete',
    }
    useEffect(() => {
        const { location: { state } } = props;
        if (state && state.current_page) {
            onParamsChange('page', props.location.state.current_page);
        }
        else {
            getAllTrips()
        }
        let category_filter = lookups.trip_categories ?
        lookups.trip_categories.concat({label: 'All'}) : []
        setCategoryLookups(category_filter);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const deleteTrip = (slug) => {
        tripsServices.remove(slug).then(response => {
            hide('ConfirmModal');
            getAllTrips(params)
        }).catch(error => {
            console.log(error);
            error && error.data.errors &&
            show('ConfirmModal', {
                ...modal_props,
                action: () => deleteTrip( slug ),
                errors: error && error.data.errors
            }
        )
        })

    }

    const onCheckTrip = (status, trip_id) => {
        let checked_trips = {...state.checked_trips}
        let new_state = {...state}
        if(trip_id){
            checked_trips[trip_id] = checked_trips[trip_id] ? status : true
            new_state.checked_trips = checked_trips;
            new_state.is_checked_all = false;
        }
        else {
            if (!isEmpty(trips.data)) {

                forEach(trips.data, (trip) => {
                    checked_trips[trip.trip_id] = status;
                });
            }
            new_state.checked_trips = checked_trips;
            new_state.is_checked_all = status;
        }
        setState(new_state)
    }

    const bulkAction = (type) => {
        let bulk_items = [];
        forEach(state.checked_trips, (value, key ) => {
            if (value) {
                bulk_items.push(key);
            }
        });

        if (!isEmpty(bulk_items)) {
            if(type === 'delete') {
                show('ConfirmModal',
                    {
                        title: 'Delete Multiple Trips',
                        body: 'Do you want to delete Multiple Trips ?',
                        button_name: 'Delete',
                        action: () => onSelectBulk(type, bulk_items),
                    }
                )
            }
            else {
                onSelectBulk(type, bulk_items)
            }
        }
    }

    const onSelectBulk = (action_type, items) => {
        tripsServices.bulkAction({ action_type, items})
        .then(() => {
            getAllTrips(params);
            setState({
                ...state,
                checked_trips: {},
                is_checked_all: false
            });
            hide('ConfirmModal');
        })
        .catch((err) => {
            console.log(err)
        })
    }

    const exportToExcel = () => {
        tripsServices.exportToExcel(params).then((response) => {
            window.open(response);
        });
    }

    const onTreeChange = (selectedNodes) => {

        let selected_category = selectedNodes.map(item => item?.trip_category_id)
        onParamsChange( 'category', selected_category?.join(','))

    }


    return (
        <div>
            <div className="generic-page-header">
                <div className="header-data-section">
                    <h2 className="page-head my-0">Trips Management</h2>
                </div>
                <div className="header-data-section">
                    <Button color="secondary" className="export-to-excel-btn mr-3" onClick={exportToExcel}>
                        <i>
                            <svg width="19" height="17" viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M14.728 12.8206H11.9003L9.97152 10.0926L7.94478 12.8206H5.10477L8.62819 8.38936L5.78315 4.51387H8.6517L9.98439 6.62121L11.4044 4.51387H14.3233L11.3406 8.38936L14.728 12.8206ZM18.4585 16.6071H1.66699V0.570107H18.4585V16.6071ZM3.2801 14.994H16.8426V2.18433H3.2801" fill="#10C56E" />
                            </svg>
                        </i>Export to Excel
                    </Button>
                    <Button
                        color="primary"
                        className="add-new-btn"
                        onClick={() => history.push('/admin/trip-management/add')}
                    >
                        <i>
                            <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.03027 7.77295H0.171875V5.6001H5.03027V0.619629H7.22754V5.6001H12.0981V7.77295H7.22754V12.729H5.03027V7.77295Z" fill="#fff" />
                            </svg>
                        </i>Add a Trip
                    </Button>
                </div>
            </div>
            <div className="tt-admin-card">
                <div className="tt-admin-card--header tt-admin-card--header_filter">
                    <div className="tt-filter">
                        <Form>
                            <Row className="justify-content-between">
                                <Col xs="12" lg="4">
                                    <Row>
                                        <Col xs="12" lg="6">
                                            <FormGroup>
                                                <div className="custom-react-select--no-border w-100">
                                                    <Select
                                                        className="react-select-container"
                                                        classNamePrefix="react-select"
                                                        placeholder="Bulk Action"
                                                        options={bulk_action_lookup}
                                                        styles={styles}
                                                        onChange={(e) => bulkAction(e.value)}
                                                    />
                                                </div>
                                            </FormGroup>
                                        </Col>
                                        <Col xs="12" lg="6">
                                            <FormGroup>
                                                <div className="filter-input--outer">
                                                    <Input
                                                        type="text"
                                                        placeholder="Search by Trip Name or Host"
                                                        onChange={(e) => onParamsChange('search', e.target.value)}
                                                    />
                                                </div>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Col>

                                <Col xs="12" lg="2"></Col>
                                <Col xs="12" lg="5">
                                    <Row>
                                        {/* <Col xs="12" lg="4">
                                            <FormGroup>
                                            <div className="custom-react-select--no-border w-100">
                                                <Select
                                                    className="react-select-container"
                                                    classNamePrefix="react-select"
                                                    placeholder="State"
                                                    options={BulkAction}
                                                    styles={styles}
                                                    onChange={(e) => onParamsChange('state', e.value)}
                                                />
                                            </div>
                                            </FormGroup>
                                        </Col> */}
                                        {/* TODO_HERE */}
                                        <Col xs="12" lg="3">
                                            <FormGroup>
                                                <div className="custom-react-select--no-border w-100">
                                                    <Select
                                                        className="react-select-container"
                                                        classNamePrefix="react-select"
                                                        placeholder="Featured"
                                                        options={featuredOptions}
                                                        styles={styles}
                                                        onChange={(e) => onParamsChange('featured', e.value)}
                                                    />
                                                </div>
                                            </FormGroup>
                                        </Col>
                                        <Col xs="12" lg="5">
                                            <FormGroup>
                                                <div className="custom-react-select--no-border w-100">
                                                <TreeContainer
                                                    data={ category_lookups }
                                                    onChange={onTreeChange}
                                                    mode="multiSelect"
                                                    className="tt-tree-filter-container"
                                                    placeholder="Trip Category"
                                                />
                                                </div>
                                            </FormGroup>
                                        </Col>
                                        <Col xs="12" lg="4">
                                            <FormGroup>
                                                <div className="custom-react-select--no-border w-100">
                                                    <Select
                                                        className="react-select-container"
                                                        classNamePrefix="react-select"
                                                        placeholder="Status"
                                                        options={lookups.trip_statuses}
                                                        styles={styles}
                                                        onChange={(e) => onParamsChange('status', e.value)}
                                                    />
                                                </div>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </div>
                <div className="tt-admin-card--data">
                    <div className="table-wrap">
                        <Table responsive className="tt-admin-table-compact tt-admin-table-compact--checkbox ">
                            <thead>
                                <tr>
                                    <th>
                                        <div className="tt-admin-checkbox">
                                            <CustomInput
                                                type="checkbox"
                                                id="tt-check-2"
                                                label=""
                                                className="tt-checkbox"
                                                onChange={(e) => onCheckTrip(e.target.checked)}
                                                checked={state.is_checked_all}
                                            />
                                        </div>
                                    </th>
                                    <th>Trip ID</th>
                                    <th>Title</th>
                                    <th className="sortable" onClick={() => onParamsChange('sort', 'country_id')}>Location</th>
                                    <th className="sortable" onClick={() => onParamsChange('sort', 'host')}>Host</th>
                                    <th className="sortable" onClick={() => onParamsChange('sort', 'trip_category_id')}>Category</th>
                                    <th className="sortable" onClick={() => onParamsChange('sort', 'trip_status_id')}>Status</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {state.loading ?
                                    <tr>
                                        <td colSpan="8" align="center"><ListLoader /></td>
                                    </tr>
                                    :
                                    trips && isEmpty(trips.data) ?
                                        <tr>
                                            <td colSpan="8" align="center">No Records Found.</td>
                                        </tr>
                                        :
                                        trips && trips.data && trips.data.map( trip => {
                                            trip.trip_category = utilFunctions.findCategory( trip.trip_categories );
                                            return (
                                                <tr key={ trip.trip_id }>
                                                    <td>
                                                        <div className="tt-admin-checkbox">
                                                            <CustomInput
                                                                type="checkbox"
                                                                id={ `trip${ trip.trip_id }` }
                                                                label=""
                                                                className="tt-checkbox"
                                                                onChange={ ( e ) => onCheckTrip( e.target.checked, trip.trip_id ) }
                                                                checked={ state.checked_trips[ trip.trip_id ] ? state.checked_trips[ trip.trip_id ] : false }
                                                            />
                                                        </div>
                                                    </td>
                                                    <td>{ trip.trip_no }</td>
                                                    <td><Link to={ {
                                                        pathname: `/admin/trip-management/details/${ trip.slug }`,
                                                        state: { current_page: params.page ? params.page : 1 }
                                                    } }>{ trip.title }</Link></td>
                                                    <td>{ trip.state ? trip.state.long_name : '-' }, { trip.country ? trip.country.long_name : '-' }</td>
                                                    <td>{ trip.user ? trip.user.full_name : '' }</td>
                                                    <td>


                                                    <img src={ trip?.parent_trip_category?.icon_image_url ?? trip?.trip_category?.icon_image_url } alt="" />
                                                    { `  ${ trip?.parent_trip_category?.label ?? trip?.trip_category?.label }` }

                                                    </td>
                                                    <td>
                                                        { trip.trip_status ?
                                                            <img src={ icons[ trip.trip_status.slug ] } alt="" />
                                                            :
                                                            '-'
                                                        }
                                                    </td>
                                                    <td>
                                                        <div className="action-wrap">
                                                            <Link to={ `/trips/details/${ trip.slug }` }><img src="/images/admin/global/view.svg" alt="" /></Link>
                                                            <Link
                                                                to={ {
                                                                    pathname: `/admin/trip-management/details/${ trip.slug }`,
                                                                    state: { current_page: params.page ? params.page : 1 }
                                                                } }>
                                                                <img src="/images/admin/global/edit.svg" alt="" />
                                                            </Link>
                                                            <Link
                                                                to={ "#" }
                                                                onClick={ () =>
                                                                    show( 'ConfirmModal', {
                                                                        ...modal_props,
                                                                        body: `Do you want to delete ${ trip.title }?`,
                                                                        action: () => deleteTrip( trip.slug ),
                                                                    }
                                                                    )
                                                                }>
                                                                <img src="/images/admin/global/delete.svg" alt="" />
                                                            </Link>
                                                        </div>
                                                    </td>
                                                </tr>
                                            );
                                        })
                                }
                            </tbody>
                        </Table>
                    </div>
                </div>
                {trips && trips.meta &&
                    <PaginationComponent page={trips.meta} onChange={(page) => onParamsChange('page', page)} />
                }
            </div>
        </div>
    );
}
