import React from "react";
import { Row,
    Col,
    Button,
    Form,
    FormGroup,
    Input,
    Modal,
    ModalHeader,
    ModalBody,
    Spinner
} from 'reactstrap';
import { useState } from "react";
import { useEffect } from "react";
import { forEach } from 'lodash';
import { toast } from "react-toastify";
import { settingServices } from "js/services/setting-service";
import { ErrorFormFeedback } from 'js/components/common';

export const SettingModal = ( props ) => {
    const { is_open, hide, setting_slug, modal_header, modal_footer, callBack } = props;
    const [ loading, setLoading ] = useState(false);
    const [ state, setState] = useState({
        name:'',
        content: '',
        loading: false
    });
    const [ errors, setErrors ] = useState({})

    const api_calls = {
        getSetting: () => settingServices.get(setting_slug)
        .then(res => {
            let content = '';
            let name = ''
            forEach(res.content, (value, key) => {
                content = value;
                name = key
            })
            setState({
                name,
                content,
                loading:false
            })
        }),
        updateSetting: (details) => settingServices.update(details, setting_slug)
        .then(res => {
            toast.success('Setting has been updated, Please refresh the page');
            setLoading(false);
            hide();
            callBack();
        }).catch(err => {
            err && err.data && err.data.errors && setErrors(err.data.errors);
            setLoading(false);
        })
    }
    const handleSubmit = () => {
        let details = {
            content: {
                [state.name] : state.content
            }
        }
        if(setting_slug) {
            api_calls.updateSetting(details)
        }
        else {
            details.name = state.name
        }
        setLoading(true);
    }
    useEffect(() => {
        if(setting_slug) {
            api_calls.getSetting();
            setState({...state, loading: true})
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <div>
            <Modal
                isOpen={is_open}
                toggle={() => hide()}
                className="tt-modal--admin modal-dialog-centered"
            >
                <ModalHeader toggle={() => hide()}>{modal_header}</ModalHeader>
                <ModalBody>
                    <Form className="profile-form">
                        <Row form>
                            {!setting_slug &&
                            <Col>
                                <FormGroup>
                                    <Input
                                        type="text"
                                        name="text"
                                        placeholder="setting name"
                                        onChange={(e) => setState({name: e.target.value})}
                                    />
                                </FormGroup>
                            </Col>
                            }
                            {!state.loading &&
                            <Col xs="12">
                                <FormGroup>
                                    <Input
                                        type="textarea"
                                        name="page_to_link"
                                        id="page_to_link"
                                        rows="4"
                                        cols="50"
                                        onChange={(e) => setState({...state, content: e.target.value})}
                                        disabled={!state.name}
                                        value={state.content}
                                        invalid={errors.message && true}
                                    />
                                    <ErrorFormFeedback field={'content'} errors={errors} />
                                </FormGroup>
                            </Col>
                            }
                        </Row>
                        <Row>
                            <Col xs="12">
                                <div className="d-flex justify-content-center">
                                    <Button
                                        className="btn-outline"
                                        onClick={handleSubmit}
                                        disabled={loading || state.loading}
                                    >
                                        { loading ? <Spinner color="#ffa405" size="sm" /> : modal_footer }
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
            </Modal>
        </div>
    )
}
