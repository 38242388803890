import React, { useState, useEffect, useContext } from 'react';
import { Row, Col } from 'reactstrap';
import { Helmet } from "react-helmet";
import { forEach, isEmpty } from 'lodash';

import { tradeServices } from 'js/services';
import { NavigationSidebar } from "js/components/web/components/layouts/components/navigation-sidebar";
import { TradeInProgressComponent, PaginationComponent, ListLoader, RelatedExperience, Ads } from 'js/components/common';
import { AuthProvider } from 'js/contexts';


export const TradesInProgress = () => {

    const [in_progress, setInprogress] = useState([]);
    const [completed, setCompleted] = useState([]);
    const [canceled, setCanceled] = useState([]);
    const [loading, setLoading] = useState({});
    const { auth } = useContext(AuthProvider);
    const ad_params = {
        limit: 1,
        position: 1,
        type: 'picture'
    }

    const user_id = auth && auth.user && auth.user.user_id;

    const [params, setParams] = useState({
        user_id: user_id
    });
    const apiCalls = {
        getAllTrades: (params) => {
            setLoading(loading => ({
                ...loading,
                [params.status]: true
            }));
            tradeServices.getAll(params).then((response) => {
                setLoading(loading => ({
                    ...loading,
                    [params.status]: false
                }));
                if (params.status === 'in-progress') {
                    setInprogress(response);
                }
                else if (params.status === 'completed') {
                    setCompleted(response);
                }
                else {
                    setCanceled(response);
                }
            })
                .catch(() => {
                    setLoading(loading => ({
                        ...loading,
                        [params.status]: false
                    }));
                });
        },
    }


    useEffect(() => {
        let status = ['in-progress', 'completed', 'canceled'];
        forEach(status, (item) => {
            apiCalls.getAllTrades({ status: item, ...params });
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onParamsChange = (page, status) => {
        let selected_params = { ...params };
        selected_params.page = page;
        selected_params.status = status;

        setParams(selected_params);
        apiCalls.getAllTrades(selected_params);
    }



    return (
        <div>
            <Helmet>
                <title>Trip4Trade :: Trips in progress</title>
                <meta name="description" content="Trades in orogress" />
                <meta name="keywords" content="trip4trade, trades" />
            </Helmet>

            <div className="inner-banner-profile" style={{ backgroundImage: `url(/images/web/global/banners/profile-banner.png)` }}>

            </div>
            <div className="wider-data-frame wider-data-frame--grey pt-0">
                <div className="container">
                    <div className="profile-outer-wrapper">
                        <Row className="no-gutters">
                            <Col xs="12" lg="9">
                                <div className="profile-data-section">
                                    <div className="profile-data-section--top">
                                        <Row>
                                            <Col xs="12" className="pb-4">
                                                <h3><img src="/images/web/global/side-navigation/trade-in-progress.svg" alt="" style={{ width: `30px` }} /> Trips in Progress</h3>
                                            </Col>
                                        </Row>

                                    </div>
                                    <div className="profile-data-section--bottom pt-5">
                                        <Row>
                                            {/* <Col xs="12" className="mb-5"> */}
                                                {/* <div className="advertisement-box"> */}
                                                    <Ads
                                                        params={ad_params}
                                                        position="trades-in-progress"
                                                    />
                                                    {/* <p>ADVERTISEMENT HERE</p> */}
                                                {/* </div> */}
                                            {/* </Col> */}
                                        </Row>
                                        <Row>
                                            <Col xs="12">
                                                <div className="trade-header-outer">
                                                    <h5 className="header6 trade-header">Trips in Progress</h5>
                                                </div>
                                            </Col>
                                        </Row>
                                        {loading['in-progress'] ?
                                            <ListLoader />
                                            :
                                            <Row className="tt-card-trade-in-progress">
                                                {
                                                    in_progress && !isEmpty(in_progress.data) && in_progress.data.map((item, index) => (
                                                        <TradeInProgressComponent
                                                            trade={item}
                                                            user_id={user_id}
                                                            type={'my-profile'}
                                                            key={`in-progress${index}`}
                                                            parentCallBack={() => apiCalls.getAllTrades({ status: 'in-progress', ...params })}
                                                        />
                                                    ))

                                                }
                                            </Row>
                                        }
                                        <Row>
                                            {in_progress && in_progress.meta &&
                                                <PaginationComponent
                                                    page={in_progress.meta}
                                                    onChange={(page) => onParamsChange(page, 'in-progress')}
                                                />
                                            }
                                        </Row>

                                        <Row>
                                            <Col xs="12">
                                                <div className="trade-header-outer">
                                                    <h5 className="header6 trade-header">Completed Trips</h5>
                                                </div>
                                            </Col>
                                        </Row>
                                        {loading.tbd ?
                                            <ListLoader />
                                            :
                                            <Row className="tt-card-trade-in-progress"  >
                                                {
                                                    completed && !isEmpty(completed.data) && completed.data.map((item, index) => (
                                                        <TradeInProgressComponent
                                                            trade={item}
                                                            user_id={user_id}
                                                            type={'my-profile'}
                                                            key={`completed${index}`}
                                                            parentCallBack={() => apiCalls.getAllTrades({ status: 'completed', ...params })}

                                                        />
                                                    ))
                                                }
                                            </Row>
                                        }
                                        <Row>
                                            {completed && completed.meta &&
                                                <PaginationComponent
                                                    page={completed.meta}
                                                    onChange={(page) => onParamsChange(page, 'completed')}
                                                />
                                            }
                                        </Row>
                                        <Row>
                                            <Col xs="12">
                                                <div className="trade-header-outer">
                                                    <h5 className="header6 trade-header">Canceled Trips</h5>
                                                </div>
                                            </Col>
                                        </Row>
                                        {loading.canceled ?
                                            <ListLoader />
                                            :
                                            <Row className="tt-card-trade-in-progress" >
                                                {
                                                    canceled && !isEmpty(canceled.data) && canceled.data.map((item, index) => (
                                                        <TradeInProgressComponent
                                                            trade={item}
                                                            user_id={user_id}
                                                            type={'my-profile'}
                                                            key={`canceled${index}`}
                                                            parentCallBack={() => apiCalls.getAllTrades({ status: 'canceled', ...params })}
                                                        />
                                                    ))
                                                }
                                            </Row>
                                        }
                                        <Row>
                                            {canceled && canceled.meta &&
                                                <PaginationComponent
                                                    page={canceled.meta}
                                                    onChange={(page) => onParamsChange(page, 'canceled')}
                                                />
                                            }
                                        </Row>
                                    </div>
                                </div>
                            </Col>
                            <NavigationSidebar />
                        </Row>
                    </div>
                </div>
            </div>
            <RelatedExperience user_id={user_id}/>
        </div>
    );
}


