import React, { useState, useEffect } from 'react';
import {
    Row,
    Col,
    Button,
    Form,
    FormGroup,
    CustomInput,
    Label,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Input,
    Spinner
} from 'reactstrap';

import { isEmpty, debounce, omit } from "lodash";
import { toast } from "react-toastify";

import { supportEmailTemplateServices } from 'js/services';


export const EditSupportEmailTemplate = (props) => {

    let [state, setState] = useState({});
    const [createErrors, setErrors] = useState({});
    const { is_open, hide, support_email_id, callBack } = props;
    const [loading, setLoading] = useState(false);

    const apiCalls = {
        update: debounce((details) => {
            setLoading(true);
            supportEmailTemplateServices.update(details, support_email_id, hide)
                .then(() => {
                    toast.success('Updated Successfully');
                    hide();
                    callBack();
                    setLoading(false);
                })
                .catch((err) => {
                    let errors = err.data && err.data.errors;
                    setErrors(errors);
                    setLoading(false);
                });
        }, 300),
        get: debounce((support_email_id, hide) => {
            supportEmailTemplateServices.get(support_email_id)
                .then((response) => {
                    setState(response);
                })
                .catch((err) => {
                    let errors = err.data && err.data.errors;
                    setErrors(errors);
                });
        }, 300),

    }

    useEffect(() => {
        if (props && props.support_email_id) {
            apiCalls.get(props.support_email_id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const submit = () => {
        apiCalls.update(
            {
                ...omit(state, 'support_email_template_id', 'slug')
            }
        );
    }

    return (
        <React.Fragment>
            <Modal
                isOpen={is_open}
                toggle={() => hide()}
                className="tt-modal--admin modal-dialog-centered"
            >
                <ModalHeader toggle={() => hide()}>Edit Support Email</ModalHeader>
                <ModalBody>
                    <Form className="tt-admin-form">
                        <div className="tt-admin-form-inner p-0">
                            <Row form>
                                <Col xs="12">
                                    <FormGroup>
                                        <Label for="">Title</Label>
                                        <Input
                                            type="text"
                                            name="title"
                                            onChange={(e) => setState({ ...state, [e.target.name]: e.target.value })}
                                            value={state.title ? state.title : ''}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col xs="12">
                                    <FormGroup>
                                        <Label for="">Description</Label>
                                        <Input
                                            type="textarea"
                                            name="description"
                                            onChange={(e) => setState({ ...state, [e.target.name]: e.target.value })}
                                            value={state.description ? state.description : ''}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="12">
                                    <hr className="hr-solid" />
                                </Col>
                            </Row>
                            <Row form>
                                <Col xs="12">
                                    <FormGroup>
                                        <Label for="">Email Subject</Label>
                                        <Input
                                            type="text"
                                            name="subject"
                                            onChange={(e) => setState({ ...state, [e.target.name]: e.target.value })}
                                            value={state.subject ? state.subject : ''}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col xs="12">
                                    <FormGroup>
                                        <Label for="">Email Content</Label>
                                        <Input
                                            type="textarea"
                                            name="content"
                                            onChange={(e) => setState({ ...state, [e.target.name]: e.target.value })}
                                            value={state.content ? state.content : ''}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row form>
                                <Col xs="12" md="6">
                                    <div>
                                        <Label for="">Initial State</Label>
                                    </div>
                                    <FormGroup check inline>
                                        <CustomInput
                                            type="radio"
                                            id="initialState1"
                                            name="customRadio"
                                            label="Active"
                                            className="mr-3"
                                            value={1}
                                            onChange={
                                                (e) => {
                                                    setState({ ...state, 'is_active': Number(e.target.value) });
                                                }
                                            }
                                            checked={state.is_active === 1}
                                        />
                                        <CustomInput
                                            type="radio"
                                            id="initialState2"
                                            name="customRadio"
                                            label="Inactive"
                                            value={0}
                                            onChange={
                                                (e) => {
                                                    setState({ ...state, 'is_active': Number(e.target.value) });
                                                }
                                            }
                                            checked={state.is_active === 0}
                                        />
                                    </FormGroup>
                                    {!isEmpty(createErrors) && createErrors.is_active && (
                                        <div>
                                            <div className="invalid-feedback d-block">{createErrors.is_active}</div>
                                        </div>
                                    )}
                                </Col>
                            </Row>
                        </div>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        className="btn-outline-grey"
                        onClick={() => hide()}
                        disabled={loading}
                    >Cancel
                    </Button>
                    <Button
                        color="primary"
                        className="add-new-btn"
                        onClick={() => submit()}
                        disabled={loading}
                    >
                    {loading ?
                        <Spinner size="sm"/> : 'Save'
                    }
                    </Button>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    );
}
