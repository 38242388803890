import React from "react";
import { Row,
    Col,
    Button,
    Form,
    FormGroup,
    Input,
    Modal,
    ModalHeader,
    ModalBody,
    Spinner
} from 'reactstrap';
import { mcServices } from 'js/services';
import { ErrorFormFeedback } from 'js/components/common';
import { useState } from "react";


export const MessageToHost = ( props ) => {
    const { is_open, hide, host_id} = props;
    const [ loading, setLoading ] = useState(false);
    const [ message, setMessage] = useState('');
    const [ errors, setErrors ] = useState({})

    const api_calls = {

        createThread: (recipient_id) => {
            setLoading( true );
            let users = [{
                user_id: host_id
            }]
            mcServices.createThreads({ users }, hide)
            .then(res => {
                api_calls.createMessage(res.mc_thread_id);
            })
        },

        createMessage: (thread_id) => {
            setLoading( true );
            mcServices.createMessage({message}, thread_id, hide)
            .then(response => {
                hide();
                setLoading( false );
            })
            .catch(err => {
                err && err.data && err.data.errors && setErrors(err.data.errors);
                setLoading( false );
            })
        },

    }
    return (
        <div>
            <Modal
                isOpen={is_open}
                toggle={() => hide()}
                className="tt-modal--admin modal-dialog-centered"
            >
                <ModalHeader toggle={() => hide()}>Message to Host</ModalHeader>
                <ModalBody>
                    <Form className="profile-form">
                        <Row form>
                            <Col xs="12">
                                <FormGroup>
                                    <Input
                                        type="textarea"
                                        name="page_to_link"
                                        id="page_to_link"
                                        rows="4"
                                        cols="50"
                                        onChange={(e) => setMessage( e.target.value)}
                                        invalid={errors.message && true}
                                    />
                                    <ErrorFormFeedback field={'message'} errors={errors} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12">
                                <div className="d-flex justify-content-center">
                                    <Button
                                        className="btn-outline"
                                        onClick={() => api_calls.createThread()}
                                        disabled={loading}
                                    >
                                        { loading ? <Spinner color="#ffa405" size="sm" /> : 'Send Message' }
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
            </Modal>
        </div>
    )
}
