import React from 'react';
import { Row, Col } from 'reactstrap';
import { Helmet } from "react-helmet";

import { NavigationSidebar } from "js/components/web/components/layouts/components/navigation-sidebar";
import { MessageCenter, RelatedExperience } from 'js/components/common'

export const Messages = (props) => {

let user_id = props.match && props.match.params && props.match.params.user_id ? props.match.params.user_id : null;
let thread_id = props.match && props.match.params && props.match.params.thread_id ? props.match.params.thread_id : null;
    return (
        <div>
            <Helmet>
                <title>Trip4Trade :: Messages</title>
                <meta name="description" content=" My Listings page desription" />
                <meta name="keywords" content="trip4trade,  My Listings" />
            </Helmet>

            <div className="inner-banner-profile" style={{ backgroundImage: `url(/images/web/global/banners/profile-banner.png)` }}>

            </div>
            <div className="wider-data-frame wider-data-frame--grey pt-0">
                <div className="container">
                    <div className="profile-outer-wrapper">
                        <Row className="no-gutters">
                            <Col xs="12" lg="9">
                                <div className="profile-data-section">
                                    <div className="profile-data-section--top">
                                        <Row>
                                            <Col xs="12" className="pb-4">
                                                <h3><img src="/images/web/global/side-navigation/messages.svg" alt="" style={{ width: `30px`, height: `40px` }} /> Messages</h3>
                                            </Col>
                                        </Row>

                                    </div>
                                    <MessageCenter user_id={user_id} thread_id={thread_id}/>
                                </div>
                            </Col>
                            <NavigationSidebar />
                        </Row>
                    </div>
                </div>
            </div>
            <RelatedExperience user_id={user_id}/>
        </div>
    );
}

