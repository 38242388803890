import React from "react";
import { Row, Col } from 'reactstrap';
import { BarLoader } from "react-spinners";
import { useEffect } from "react";
import { reportServices } from "js/services";
import { useState } from "react";
import moment from 'moment'
import { Bar, Line } from 'react-chartjs-2';
import 'chartjs-plugin-datalabels';


export const WebsiteTrafficReport = () => {
    const [ data, setData ] = useState({});
    const [ loading, setLoading ] = useState(false)
    const getWebsiteTraffic = () => {
        setLoading(true)
        reportServices.analytics()
            .then(res => {
                setData({...data, active_users: res.active_users});
                dataFeeder(res);
                setLoading(false)
            })
    }
    const dataFeeder = (traffic) => {
        Promise.all([traffic.sessions_this_week, traffic.sessions_last_week]).then(function (results) {
            var data1 = results[0].rows.map(function (row) {
                return +row[2];
            });
            var data2 = results[1].rows.map(function (row) {
                return +row[2];
            });
            var labels = results[1].rows.map(function (row) {
                return +row[0];
            });

            labels = labels.map(function (label) {
                return moment(label, "YYYYMMDD").format("ddd");
            });

            var session = {
                labels: labels,
                datasets: [
                    {
                        label: "Last Week",
                        backgroundColor: 'rgba(255, 164, 5, 0.6)',
                        borderColor: 'rgba(255, 164, 5, 0.6)',
                        data: data2
                    },
                    {
                        label: "This Week",
                        backgroundColor: 'rgba(16, 197, 110, 0.2)',
                        borderColor: 'rgba(16, 197, 110, 0.2)',
                        data: data1
                    }
                ]
            };
            setData(data => ({ ...data, session: session }));
        });

        Promise.all([traffic.users_this_year, traffic.users_last_year]).then(function (results) {
            var data1 = results[0].rows.map(function (row) {
                return +row[1];
            });
            var data2 = results[1].rows.map(function (row) {
                return +row[1];
            });
            var labels = results[1].rows.map(function (row) {
                return +row[0];
            });

            const month_labels = {
                "1": "Jan",
                "2": "Feb",
                "3": "Mar",
                "4": "Apr",
                "5": "May",
                "6": "Jun",
                "7": "Jul",
                "8": "Aug",
                "9": "Sep",
                "10": "Oct",
                "11": "Nov",
                "12": "Dec"
            };
            labels = labels.map(function (label) {
                return month_labels[label];
            });

            var users = {
                labels: labels,
                datasets: [
                    {
                        label: "This Year",
                        backgroundColor: 'rgba(255, 164, 5, 0.6)',
                        borderColor: 'rgba(255, 164, 5, 0.6)',
                        data: data1
                    },
                    {
                        label: "Last Year",
                        backgroundColor: 'rgba(16, 197, 110, 0.2)',
                        borderColor: 'rgba(16, 197, 110, 0.2)',
                        data: data2
                    }
                ]
            };

            setData(data => ({ ...data, users: users }));
        });

    }

    useEffect(() => {
        getWebsiteTraffic()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <div>
            <div className="generic-page-header">
                <div className="header-data-section">
                    <h2 className="page-head my-0">Report / Website Traffic</h2>
                </div>
                <div className="header-data-section">
                    <div className="user-status active-users">
                        <div className="user-label"><img src="/images/admin/global/users.svg" alt="" /> Active Users</div>
                        <div className="user-count">{data.active_users}</div>
                    </div>
                </div>
            </div>
            <div className="tt-admin-card tt-admin-card--website-traffic">
                <Row>
                    <Col xs="12" md="6">
                        <h4>This Week vs Last Week</h4>
                        <p>By Sessions</p>
                        <div className="graph-card">
                            {loading ?
                                <div className="sweet-loading">
                                    <BarLoader

                                        size={50}
                                        color={"#FFA405"}
                                        loading={true}
                                    />
                                </div>
                                :
                                data && data.session &&
                                <Line
                                    data={data.session}
                                    width={100}
                                    height={50}
                                    options={{
                                        legend: {
                                            position: "bottom"
                                        },
                                        plugins:{
                                            datalabels:{
                                                display: false
                                            }
                                        }
                                    }}
                                />
                            }
                        </div>
                    </Col>
                    <Col xs="12" md="6">
                        <h4>This Year vs Last Year</h4>
                        <p>By Users</p>
                        <div className="graph-card">
                            {loading ?
                                <div className="sweet-loading">
                                    <BarLoader

                                        size={50}
                                        color={"#FFA405"}
                                        loading={true}
                                    />
                                </div>
                                :
                                data && data.users &&
                                <Bar
                                    data={data.users}
                                    width={100}
                                    height={50}
                                    options={{
                                        legend: {
                                            position: "bottom"
                                        },
                                        plugins:{
                                            datalabels:{
                                                display: false
                                            }
                                        }
                                    }}
                                />
                            }
                        </div>
                    </Col>
                </Row>
            </div>
        </div>


    );


}
