import React, { useEffect, useContext, useState } from "react";
import { Route, Switch } from "react-router-dom";
import { BounceLoader } from "react-spinners";
import { cloneDeep } from 'lodash';


import { Header } from "js/components/web/components/layouts/components/header";
import { Footer } from "js/components/web/components/layouts/components/footer";
import ScrollToTop from "js/components/web/components/layouts/components/scroll-to-top";
import { authServices } from 'js/services/auth-service';
import { AuthProvider } from 'js/contexts';
import { portalRoutes } from "js/routes/Web/portal";
import { PrivateRoute } from 'js/components/common';
import { configConstants } from 'js/constants';
import { history } from 'js/helpers'

export const SiteLayout = (props) => {
    const [ loaded, setLoaded ] = useState(false);
    const { auth, setAuth } = useContext(AuthProvider);

    const switchRoutes = (
        <Switch>
            {portalRoutes.map((prop, key) => {
                return prop.needsAuth ?
                    <PrivateRoute exact path={prop.path} component={prop.component} key={key} />
                :
                    <Route path={prop.path} component={prop.component} key={key} />
            })}
        </Switch>
    );

    useEffect(() => {
        const token = localStorage.getItem(configConstants.TOKEN_NAME);
        const partner_token = localStorage.getItem(configConstants.PARTNER_TOKEN);

        if (token && partner_token){
            authServices.getProfile().then((response) => {
                setAuth({ ...auth, user: cloneDeep(response), is_logged_in: true });
                setLoaded(true);
            });
        }
        else {
            setAuth({ ...auth, is_logged_in: false, user : {}, previous_location: history.location.pathname });
            setLoaded(true);
        }
            // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <React.Fragment>
            { loaded ?
            <React.Fragment>
                <ScrollToTop />
                <Header />
                <main className="global-data-wrap">
                    { switchRoutes }
                </main>
                <Footer />
            </React.Fragment>
            :
                <div className="outer-loader">
                    <div className="sweet-loading">
                        <BounceLoader
                            size={50}
                            color={"#FFA405"}
                        />
                    </div>
                </div>
            }
        </React.Fragment>
    );
}
