import React, { useState, useEffect, useContext } from "react";
import { Row, Col, Button, Form, FormGroup, Label, Input, CustomInput, Spinner } from 'reactstrap';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { find, debounce, isEmpty, isEqual } from 'lodash';
import { toast } from "react-toastify";
import classnames from 'classnames';
import CreatableSelect from 'react-select/creatable';

import { LookupContext } from 'js/contexts';
import { cmsServices } from 'js/services';
import { EditorComponent, ErrorFormFeedback, FileUploadComponent } from 'js/components/common';
import { history } from 'js/helpers';


const styles = {
    option: (styles, state) => ({
        ...styles,
        backgroundColor: state.isSelected ? "#FFA405 " : state.isFocused ? "#FFA40520" : ""
    })
};

export const AddNewArticle = ( props ) => {
    const [ state, setState ] = useState({
        title: '',
        article_category_id:'',
        article_status_id : '',
        meta_description:'',
        description:'',
        feature_tags: [],
        attachments : []
    });
    const [ article_errors, setErrors ] = useState({});
    const [ feature_tags, setFeatureTags ] = useState({ tags : [] });
    const [loading, setLoading] = useState(false);
    const [fileUploadLoading, setFileUploadLoading] = useState(false);
    const [ attachment_memo, setAttachmentMemo ] = useState([]);


    const lookups = useContext(LookupContext);

    const handleChange = ( value, key, parent_key ) => {
        const newState = { ...state };
        if( parent_key ){
            newState[parent_key][key] = value;
        }
        else{
            newState[key] = value;
        }
        setState(newState);
    }
    const addArticle = debounce(() => {
        let data = {
            ...state,
            feature_tags: state.feature_tags ? state.feature_tags.map( (item) => (item.value)) : [],
            attachments: state.attachments ? state.attachments.map( (item) => (item.attachment_id)) : [],
        };
        data.description = data.description.replace(/<\s*adcomponenttinymce[^>]*>(.*?)<\s*\/\s*adcomponenttinymce>/g, '{{advertisement}}');


        //Replace Tag For Add
        setLoading(true);
        if( props.match && props.match.params && props.match.params.article  ){
            if(isEqual(data.attachments, attachment_memo)){
               delete data.attachments;
            }
            cmsServices.updateArticle(props.match.params.article, data)
            .then((response) => {
                setLoading(false);
                toast.success('Blog Updated Successfully');
                history.push('/admin/static-content/experience');
            }).catch((error) => {
                setLoading(false);
                if( error.data && error.data.errors ){
                    setErrors(error.data.errors);
                }
                else{
                    toast.error('Failed To Edit Article');
                }
            });
        }
        else{
            cmsServices.addArticle(data)
            .then((response) => {
                setLoading(false);
                toast.success('Blog Added Successfully');
                history.push('/admin/static-content/experience');
            }).catch((error) => {
                setLoading(false);
                if( error.data && error.data.errors ){
                    setErrors(error.data.errors);
                }
                else{
                    toast.error('Failed To Add Article');
                }
            });
        }
    },300);

    const addAvatar = ( response ) => {
        let { attachments } = state;
        if( ! attachments ){
            attachments = [];
        }
        attachments.push(response);
        setState({ ...state, attachments });
    }

    const removeFile = ( index ) => {
        let { attachments } =  state;
        attachments.splice( index, 1 );
        setState({ ...state, attachments });
    }

    const getArticle = ( article ) => {
        cmsServices.getArticle( article)
        .then((response) => {
            response.edit_mode_description = response.edit_mode_description.replace(/{{advertisement}}/g, '<adcomponenttinymce class="mceNonEditable" /></adcomponenttinymce>');
            let attachment_id = response.attachment ? response.attachment.attachment_id : null;
            setState({
                title: response.title,
                article_category_id: response.article_category && response.article_category.article_category_id ?
                    response.article_category.article_category_id :   null,
                article_status_id: response.article_status && response.article_status.article_status_id,
                meta_description:response.meta_description,
                description: response.edit_mode_description,
                feature_tags : response.feature_tags ? response.feature_tags.map( (item) => ( { value: item.label, label: item.label })) : [],
                attachments : response.attachment ? [response.attachment] : []
            });
            setAttachmentMemo([attachment_id]);
        }).catch((error) => {
            toast.error('Failed To Fetch Blog');
            history.push('/admin')
        });
    }
    const getFeatureTags = () => {
        cmsServices.getFeatureTags( )
        .then((response) => {
            setFeatureTags({
                tags : response ? response.map( (item) => ( { value: item.label, label: item.label })) : []
            });
        });
    }

    useEffect(() => {
        getFeatureTags();
        if( props.match && props.match.params && props.match.params.article  ){
            getArticle(props.match.params.article);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <div className="generic-page-header">
                <div className="header-data-section">
                    <Link to={"/admin/static-content/experience"} className="back-btn"><img src="/images/admin/global/back.svg" alt="" /></Link>
                    <h2 className="page-head my-0">{ props.match && props.match.params && props.match.params.article ? 'Edit' : 'Add'} Blog</h2>
                </div>

                <div className="header-data-section">
                    <Button
                        color="primary"
                        className="add-new-btn"
                        onClick={addArticle}
                        disabled={loading || fileUploadLoading}
                    >{loading ? <Spinner size="sm"/> : 'Save Post'}
                    </Button>
                </div>
            </div>
            <div className="tt-admin-card">
                <div className="tt-admin-card--data">
                    <Row className="no-gutters">
                        <Col xs="12">
                            <div className="trip-details-data-section">
                                <Form className="tt-admin-form">
                                    <div className="tt-admin-form-inner">
                                        <Row>
                                            <Col xs="12" md="8">
                                                <Row form>
                                                    <Col xs="12">
                                                        <FormGroup>
                                                            <Label for="">Page Title</Label>
                                                            <Input
                                                                type="text"
                                                                value={state.title}
                                                                onChange = { (e) => handleChange( e.target.value, 'title' ) }
                                                                invalid={ !isEmpty(article_errors) && (article_errors.title) && true }
                                                            />
                                                            <ErrorFormFeedback field={'title'} errors={article_errors} />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs="12">
                                                        <div className={classnames("editor-outer-wrap", { 'is-invalid' : article_errors.description })}>
                                                            <EditorComponent
                                                                advertisement
                                                                id="description"
                                                                content={state.description}
                                                                onChange = { (content) => handleChange( content, 'description' ) }
                                                            />
                                                        </div>
                                                        <ErrorFormFeedback field={'description'} errors={article_errors} />
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xs="12" md="4">
                                                <Row form>
                                                    <Col xs="12">
                                                        <FormGroup>
                                                            <Label for="">Categories</Label>
                                                            <div className="tt-custom-react-select">
                                                                <Select
                                                                    className={classnames("react-select-container", { 'is-invalid' : article_errors.article_category_id })}
                                                                    classNamePrefix="react-select"
                                                                    options={lookups.article_categories}
                                                                    styles={styles}
                                                                    onChange = { (e) => handleChange( e.value, 'article_category_id' ) }
                                                                    value={find(lookups.article_categories, ['article_category_id',  state.article_category_id] )}
                                                                />
                                                                <ErrorFormFeedback field={'article_category_id'} errors={article_errors} />
                                                            </div>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row form>
                                                    <Col xs="12">
                                                        <FormGroup>
                                                            <Label for="">Meta Description ( { state.meta_description.length } / 150)</Label>
                                                            <Input
                                                                type="textarea"
                                                                maxLength={150}
                                                                value={state.meta_description}
                                                                onChange = { (e) => handleChange( e.target.value, 'meta_description' ) }
                                                                invalid={ !isEmpty(article_errors) && (article_errors.meta_description) && true }
                                                            />
                                                            <ErrorFormFeedback field={'meta_description'} errors={article_errors} />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row form>
                                                    <Col xs="12">
                                                        <FormGroup className="tt-custom-react-select">
                                                            <Label for="">Tags</Label>
                                                            <CreatableSelect
                                                                className={classnames("react-select-multi-container", { 'is-invalid' : article_errors.tags })}
                                                                classNamePrefix="react-select"
                                                                styles={styles}
                                                                isMulti
                                                                value={state.feature_tags}
                                                                onChange = { (e) => handleChange( e, 'feature_tags' ) }
                                                                options={feature_tags.tags}
                                                            />
                                                            <ErrorFormFeedback field={'meta_description'} errors={article_errors} />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row form>
                                                    <Col xs="12" md="6">
                                                        <div>
                                                            <Label for="">Status</Label>
                                                        </div>
                                                        <FormGroup check inline className={classnames({ 'is-invalid' : article_errors.article_status_id })}>
                                                            { lookups.article_statuses && lookups.article_statuses.map( (item, index ) => (
                                                                <CustomInput
                                                                    key={index}
                                                                    type="radio"
                                                                    id={item.slug}
                                                                    name="article_status"
                                                                    label={item.label}
                                                                    checked={item.article_status_id === state.article_status_id ? true : false }
                                                                    onChange = { () => handleChange( item.article_status_id, 'article_status_id' ) }
                                                                    className="mr-3"
                                                                />
                                                            ))}
                                                        </FormGroup>
                                                        <ErrorFormFeedback field={'article_status_id'} errors={article_errors} />
                                                    </Col>
                                                </Row>
                                                <Row form>
                                                    <Col xs="12" className="mt-4">
                                                        { isEmpty(state.attachments) ?
                                                            <FormGroup>
                                                                <div>
                                                                    <Label for="">Upload Feature Image</Label>
                                                                    <FileUploadComponent
                                                                        entity_type='Article'
                                                                        multiple={false}
                                                                        accepted={['image/*']}
                                                                        onSuccess={addAvatar}
                                                                        fileUploadLoading={(loading) => setFileUploadLoading(loading)}
                                                                    />
                                                                </div>
                                                            </FormGroup>
                                                        :
                                                            <React.Fragment>
                                                            { state.attachments.map( (item, index ) => (
                                                                <FormGroup key={index} >
                                                                    <Label for="">Feature Image</Label>
                                                                    <div className="featured-image-wrapper">
                                                                        <Link to={"#"} onClick={ () => removeFile(index)} className="close-icon"><img src="/images/admin/global/close.svg" alt="" /></Link>
                                                                        <img className="img-item" src={item.attachment_url} alt="Featured" />
                                                                    </div>
                                                                </FormGroup>
                                                            ))}
                                                            </React.Fragment>
                                                        }
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                </Form>
                            </div>
                        </Col>
                    </Row>
                </div>

            </div>
        </div>
    );
}
