import React, { useState, useEffect } from "react";
import { Row, Col, Button, Form, FormGroup, Label, Input, CustomInput, Spinner } from 'reactstrap';
import { Link } from 'react-router-dom';
import { cmsServices } from 'js/services';
import { toast } from "react-toastify";

import { EditorComponent, ListLoader } from 'js/components/common';
import { history } from 'js/helpers';

export const AboutUsCMS = (props) => {
    const [ state, setState] = useState({
        content : {
            page_title :'',
            top_section : '',
            quote: '',
            bottom_section : ''
        },
        is_footer_link : 0,
        loading: false
    });
    const [loadingButton, setLoadingButton] = useState(false);

    const getStaticPage = () => {
        setState({...state, loading: true})
        cmsServices.getStaticPage('about-us')
        .then((response) => {
            setState({...state, loading: false})
            setState({ ...state, ...response })
        }).catch((err) => {
            // setState({ ...state, 'error' : err });
            setState({...state, loading: false})
            toast.error('Failed to Load Page');
            history.push('/admin')
        });
    }

    const updateFooterLinkStatus = () => {
        const newState = { ...state };
        newState.is_footer_link = state.is_footer_link === 1 ? 0 :  1;
        setState(newState);
        cmsServices.setStaticPage(
            'about-us',
            newState
        ).then((response) => {
            toast.success('Updated Successfully');
        }).catch((err) => {
            toast.error('Failed to Update Page');
        });
    }

    const handleChange = ( value, key, parent_key ) => {
        const newState = { ...state };
        if( parent_key ){
            newState.content[parent_key][key] = value;
        }
        else{
            newState.content[key] = value;
        }
        setState(newState);
    }

    const setStaticPage = () => {
        setLoadingButton(true)
        cmsServices.setStaticPage(
            'about-us',
            state
        ).then((response) => {
            setLoadingButton(false);
            toast.success('Updated Successfully');
        }).catch((err) => {
            setLoadingButton(false);
            toast.error('Failed to Update Page');
        });
    }

    useEffect(() => {
        getStaticPage();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const content = state.content;
    return (
        <div>
            <div className="generic-page-header">
                <div className="header-data-section">
                    <Link to={"/admin/static-content"} className="back-btn"><img src="/images/admin/global/back.svg" alt="" /></Link>
                    <h2 className="page-head my-0">About Us</h2>
                </div>

                <div className="header-data-section">

                    <div className="user-status">
                        <span>Footer Link
                            <CustomInput
                                type="switch"
                                id="footer_link_switch"
                                checked={ state.is_footer_link === 1 ? true : false }
                                onChange={updateFooterLinkStatus}
                            />
                        </span>
                    </div>
                    <Button
                        color="primary"
                        className="add-new-btn"
                        onClick={setStaticPage} >
                            { loadingButton ? <Spinner size="sm"/> : 'Save' }
                    </Button>
                </div>
            </div>
            <div className="tt-admin-card">
                <div className="tt-admin-card--data">
                    <Row className="no-gutters">
                        <Col xs="12">
                            <div className="trip-details-data-section">
                                <Form className="tt-admin-form">
                                {state.loading ?
                                    <ListLoader/>
                                    :
                                    <div className="tt-admin-form-inner">
                                        <Row form>
                                            <Col xs="12">
                                                <FormGroup>
                                                    <Label for="">Page Title</Label>
                                                    <Input
                                                        type="text"
                                                        name="title"
                                                        id="title"
                                                        value={content.page_title}
                                                        onChange = { (e) => handleChange( e.target.value, 'page_title') }
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs="12">
                                                <h4 className="in-form-heading"><span>Top Section</span></h4>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs="12">
                                                <div className="editor-outer-wrap">
                                                    <EditorComponent
                                                        id="top_section"
                                                        content={content.top_section}
                                                        onChange = { (content) => handleChange( content, 'top_section' ) }
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs="12">
                                                <h4 className="in-form-heading"><span>Quote</span></h4>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col xs="12">
                                                <FormGroup>
                                                    <Input
                                                        type="textarea"
                                                        name="quote"
                                                        id="quote"
                                                        value={content.quote}
                                                        onChange = { (e) => handleChange(e.target.value, 'quote' ) }
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs="12">
                                                <h4 className="in-form-heading"><span>Bottom Section</span></h4>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs="12">
                                                <div className="editor-outer-wrap">
                                                    <EditorComponent
                                                        id="bottom-section"
                                                        content={content.bottom_section}
                                                        onChange = { (content) => handleChange( content, 'bottom_section' ) }
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                }
                                </Form>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
}
