import { request, downloadAttachment } from "js/helpers/api";

export const attachmentServices = {
    upload,
    uploadPostMedia,
    remove,
    download,
};

function upload(details){
    return request({
        method:'POST',
        url: 'attachments',
        param: details,
    });
}

function uploadPostMedia(details){
    return request({
        method:'POST',
        url: 'post-media',
        param: details,
    });
}

function remove(attachment_id){
    return request({
        method:'DELETE',
        url: `attachments/${attachment_id}`,
        param: {},
    });
}

function download(attachment_id) {
    return downloadAttachment({
        url: `attachments/${attachment_id}`,
        param: {},
        is_authenticated: true,
    });
}
