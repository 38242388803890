import React, { useState, useEffect, useContext } from "react";
import { Row, Col, Label, Input, Form, FormGroup, Button, Table, Spinner } from 'reactstrap';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { ErrorFormFeedback, MyCheckoutForm, NumberInputComponent, BadgesComponent } from 'js/components/common';
import { userServices, invoiceServices } from 'js/services';
import { isEmpty, omit, cloneDeep, forEach, keys, find, isEqual, pick } from 'lodash';
import { toast } from "react-toastify";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Geosuggest from 'react-geosuggest';
import moment from 'moment';
import { LookupContext } from 'js/contexts';


const styles = {
    option: (styles, state) => ({
        ...styles,
        backgroundColor: state.isSelected ? "#FFA405 " : state.isFocused ? "#FFA40520" : ""
    })
};
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);


export const UserGeneralInformation = (props) => {

    const [user_details, setDetails] = useState({});

    const [user_errors, setErrors] = useState({});
    const [invoices, setInvoices] = useState({});
    const [state, setState] = useState({});
    const lookups = useContext(LookupContext);
    const [loading, setLoading] = useState(false);
    const [geoSuggestError, setGeosuggetsError] = useState(false);

    const apiCalls = {
        updateUser: (details, user_id) => {
            setLoading(true);
            userServices.update(details, user_id).then((response) => {
                setLoading(false);
                toast.success('Updated Successfully');
                setUserDetails(response.data)
            })
                .catch((err) => {
                    setLoading(false);
                    let errors = {};
                    if (err.data) {
                        errors = err.data && err.data.errors;
                    }
                    setErrors(errors);
                })
        },
        getAllInvoices: (params) => {
            invoiceServices.getAll(params).then((response) => {
                let details = { ...response };
                if (!isEmpty(details.data)) {
                    details.data = details.data.map((item) => {
                        let subject_name = [];
                        forEach(item.invoice_items, (subject) => {
                            subject_name.push(subject.label);
                        })
                        return {
                            ...item,
                            subject_name
                        }

                    })
                }
                setInvoices(details);
            })
        },
    }

    useEffect(() => {
        setUserDetails(props.data)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.data]);

    useEffect(() => {
        if (props.user_id) {
            apiCalls.getAllInvoices({ user_id: props.user_id })
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.user_id]);

    const inputChange = (key, value, sub_key) => {
        let details = {};
        if (sub_key) {
            details = {
                ...state,
                [key]: { ...state[key], [sub_key]: value }
            }
            setState(details);
        }
        else {
            details = {
                ...user_details,
            }
            if(key === 'country') {
                details.address = ''
                details.zip_code = ''
                details.city = ''
            }

            details[key]= value

            setDetails(details);
        }
    }
    const setUserDetails = (data) => {
        let social_media = {};
        let user_data = cloneDeep(data);

        const data_filter = ['first_name', 'last_name', 'email',
            'phone_country_code', 'phone', 'state', 'city', 'zip_code', 'address', 'biography', 'attachment'];

        let selected_user = pick(user_data, data_filter);

        selected_user.country = user_data.country ?
            find(lookups.countries, ['country_id', user_data.country.country_id]) : '';

        setDetails(selected_user);

        if (data && !isEmpty(data.social_media)) {
            forEach(social_media, (item) => {
                social_media[item.social_media_id] = item.social_media_link
            });

            setState({
                ...state,
                social_media
            });
        }
    }

    const handleSubmit = () => {
        let formated_data = cloneDeep(user_details);
        let remove_items = ['password', 'password_confirmation', 'current_password', 'last_name', 'zip_code', 'state', 'city'];
        let social_media = [];
        let attachments = [];

        if (!isEmpty(formated_data.attachment)) {
            let attachments = [];
            attachments.push(formated_data.attachment.attachment_id)

            if (!isEqual(attachments, props.files)) {
                formated_data.attachments = attachments;
            }
            else{
                formated_data.attachments = [];
            }
        }
        else {
            formated_data.attachments = attachments;
        }

        forEach(remove_items, (item) => {
            if (isEmpty(formated_data[item])) {
                delete formated_data[item];
            }
        })


        if (!isEmpty(formated_data.city)) {
            let modified_city = pick(formated_data.city, ['long_name', 'short_name']);
            formated_data.city = modified_city;
        }
        if (!isEmpty(formated_data.state)) {
            let modified_state = pick(formated_data.state, ['long_name', 'short_name']);
            formated_data.state = modified_state;
        }
        if (!isEmpty(formated_data.country)) {
            formated_data.country_id = formated_data.country ? formated_data.country.value: '';
        }


        if (state.social_media && !isEmpty(state.social_media)) {

            let social_media_keys = keys(state.social_media);

            forEach(social_media_keys, (item) => {
                if (!isEmpty(state.social_media[item])) {
                    social_media.push({
                        social_media_id: item,
                        social_media_link: state.social_media[item]
                    });
                }
            });
        }

        formated_data.social_media = social_media;

        if (props.user_id) {
            apiCalls.updateUser({ ...omit(formated_data, 'country', 'email', 'attachment')}, props.user_id);
        }
    }

    const handleChangePhone = (value) => {
        setDetails({ ...user_details, phone: value.phone, phone_country_code: value.phone_country_code });
    }

    const generateInvoiceLink = (invoice_id, params) => {
        invoiceServices.generateInvoiceLinks(invoice_id, params).then((response) => {
            if(response.url){
                window.open(response.url);
            }
        });
    }

    const handleAddressChange = (place) => {
        let state = {};
        let city = {};
        let country = {};

        if (!isEmpty(place)) {
            setGeosuggetsError(false);
            if (place.gmaps && place.gmaps.address_components) {
                forEach(place.gmaps.address_components, (value, key) => {
                    value.types && forEach(value.types, type => {
                        let is_state = false
                        let is_city = false
                        if (type === "administrative_area_level_1") {
                            state = { ...omit(value, 'types') }
                            is_state = true
                        }
                        if (type === "administrative_area_level_2" && !is_state) {
                            state = { ...omit(value, 'types') }
                            is_state = true
                        }
                        if (type === "locality") {
                            city = { ...omit(value, 'types') }
                            is_city = true
                            if(!(is_state)) {
                                state = {...omit(value, 'types') }
                            }
                        }
                        if(type === 'sublocality_level_1' && !is_city) {
                            is_city = true
                            city = {...omit(value, 'types') }

                        }
                        if( type === "country" ){
                            country = { ...omit(value, 'types') }
                        }
                        if(type === 'route' && isEqual(state, city)){
                            city = { ...omit(value, 'types') }
                        }
                    })
                })
            }
        }

        if( country.short_name ){
            country = find(lookups.countries, ['short_name',  country.short_name] );
        }

        setDetails((user_details) => ({
            ...user_details,
            state: state,
            city,
            address: place && place.description ? place.description : '',
            country: isEmpty(country) ? user_details.country : country
        }));
    }

    return (

        <Form className="tt-admin-form">
            <div className="tt-admin-form-inner">
                <Row form>
                    <Col xs="12" md="4">
                        <FormGroup>
                            <Label for="">First Name</Label>
                            <Input
                                type="text"
                                name="first_name"
                                onChange={(e) => inputChange(e.target.name, e.target.value)}
                                value={user_details.first_name || ''}
                                invalid={user_errors.first_name && true}
                            />
                            <ErrorFormFeedback field={'first_name'} errors={user_errors} />
                        </FormGroup>
                    </Col>
                    <Col xs="12" md="4">
                        <FormGroup>
                            <Label for="">Last Name</Label>
                            <Input
                                type="text"
                                name="last_name"
                                onChange={(e) => inputChange(e.target.name, e.target.value)}
                                value={user_details.last_name || ''}
                                invalid={user_errors.last_name && true}
                            />
                            <ErrorFormFeedback field={'last_name'} errors={user_errors} />
                        </FormGroup>
                    </Col>
                    <Col xs="12" md="4">
                        <FormGroup>
                            <Label for="">Email Address</Label>
                            <Input
                                type="text"
                                name="email"
                                //onChange={(e) => inputChange(e.target.name, e.target.value)}
                                value={user_details.email || ''}
                                invalid={user_errors.email && true}
                                disabled
                            />
                            <ErrorFormFeedback field={'email'} errors={user_errors} />
                        </FormGroup>
                    </Col>
                </Row>
                <Row form>
                    <Col xs="12" md="4">
                        <FormGroup>
                            <Label for="">Phone</Label>
                            <NumberInputComponent
                                phone={`+${user_details.phone_country_code}${user_details.phone}`}
                                onChange={handleChangePhone}
                            />

                            {!isEmpty(user_errors) && user_errors.phone && (
                                <div>
                                    <div className="invalid-feedback d-block">{user_errors.phone}</div>
                                </div>
                            )}
                            {!isEmpty(user_errors) && user_errors.phone_country_code && (
                                <div>
                                    <div className="invalid-feedback d-block">{user_errors.phone_country_code}</div>
                                </div>
                            )}
                        </FormGroup>
                    </Col>
                </Row>
                <Row form>
                    <Col xs="12">
                        <FormGroup>
                            <Label for="">Biography</Label>
                            <Input
                                type="textarea"
                                name="biography"
                                onChange={(e) => inputChange(e.target.name, e.target.value)}
                                value={user_details.biography || ''}
                                invalid={user_errors.biography && true}
                            />
                            <ErrorFormFeedback field={'biography'} errors={user_errors} />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col xs="12">
                        <h4 className="in-form-heading"><span>Billing Information</span></h4>
                    </Col>
                </Row>
                <Row form>
                    <Col xs="12" md="4">
                        <FormGroup>
                            <Label for="">Country</Label>
                            <div className="tt-custom-react-select">
                                <Select
                                    className="react-select-container"
                                    classNamePrefix="react-select"
                                    options={lookups.countries}
                                    styles={styles}
                                    onChange={(e) => {
                                        inputChange('country', e);
                                    }
                                    }
                                    value={user_details.country || null}
                                />
                                {!isEmpty(user_errors) && user_errors.country && (
                                    <div>
                                        <div className="invalid-feedback d-block">{user_errors.country}</div>
                                    </div>
                                )}
                            </div>
                        </FormGroup>
                    </Col>
                    <Col xs="12" md="8">
                        <FormGroup>
                            <Label for="">Address</Label>
                            <div className="tt-custom-react-select">
                                <Geosuggest type="text"
                                    name="address"
                                    className="react-select-container"
                                    inputClassName={"form-control"}
                                    placeholder=""
                                    initialValue={user_details.address ? user_details.address :''}
                                    value={user_details.address ? user_details.address : ''}
                                    autoComplete="nope"
                                    onSuggestSelect={handleAddressChange}
                                    country={user_details.country ? user_details.country.short_name : ''}
                                    onChange={(e) => setDetails({
                                        ...user_details,
                                        address: e
                                    })}
                                />
                                <div className="invalid-feedback d-block">{user_errors['city']}</div>
                                <div className="invalid-feedback d-block">{user_errors['address']}</div>
                                <div className="invalid-feedback d-block">{user_errors['state']}</div>
                                {
                                    geoSuggestError &&
                                        <div className="invalid-feedback d-block">Please select the address from suggestion.</div>
                                }
                            </div>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col xs="12" md="4">
                        <FormGroup>
                            <Label for="">Zip Code</Label>
                            <Input
                                type="text"
                                name="zip_code"
                                onChange={(e) => inputChange(e.target.name, e.target.value)}
                                value={user_details.zip_code || ''}
                                invalid={user_errors.zip_code && true}
                            />
                            <ErrorFormFeedback field={'zip_code'} errors={user_errors} />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col xs="12">
                        <h4 className="in-form-heading"><span>Payment Information</span></h4>
                    </Col>
                </Row>
                <Elements stripe={stripePromise}>
                    <MyCheckoutForm
                        type="admin"
                        user_id={props.user_id ? props.user_id : ''}
                    />
                </Elements>
                <Row>
                    <Col xs="12">
                        <h4 className="in-form-heading"><span>Payment List</span></h4>
                    </Col>
                </Row>
                <Row>
                    <Col xs="12">
                        <div className="table-wrap payment-table">
                            <Table responsive className="tt-admin-table-outline">
                                <thead>
                                    <tr>
                                        <th>Subject</th>
                                        <th>Payment Date</th>
                                        <th>Credit Card</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        !isEmpty(invoices) && !isEmpty(invoices.data) &&
                                        invoices.data.map((item, index) => (
                                            <tr key={`payment_list${index}`}>
                                                <td>
                                                {
                                                    !isEmpty(item.subject_name) && item.subject_name.map((subject, ind) => (
                                                        ind === item.subject_name.length - 1 && item.subject_name.length === 1 ? subject
                                                            : ind === item.subject_name.length - 1 ? 'and '+subject : subject+', '
                                                    ))
                                                }
                                                </td>
                                                <td>{item.created_at ? moment(item.created_at).format('lll') : ''}</td>
                                                <td>**** {item.transaction_history && item.transaction_history.last4 ? item.transaction_history.last4 : ''}</td>
                                                <td>
                                                    <div className="action-wrap">
                                                        <Link
                                                            to={"#"}
                                                            onClick={() => generateInvoiceLink(item.invoice_id, {type: 'download'})}
                                                        ><img src="/images/admin/global/download.svg" alt="" /></Link>
                                                        <Link
                                                            to={"#"}
                                                            onClick={() => generateInvoiceLink(item.invoice_id, {type: 'preview'})}
                                                        >
                                                            <img src="/images/admin/global/print.svg" alt="" />
                                                        </Link>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </Table>

                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs="12">
                        <h4 className="in-form-heading"><span>Change Password</span></h4>
                    </Col>
                </Row>
                <Row form>
                    {/* <Col xs="12" md="4">
                        <FormGroup>
                            <Label for="">Current Password</Label>
                            <Input
                                type="password"
                                name="password"
                                onChange={(e) => inputChange(e.target.name, e.target.value)}
                                value={user_details.password || ''}
                                invalid={user_errors.password && true}
                            />
                            <ErrorFormFeedback field={'password'} errors={user_errors} />
                        </FormGroup>
                    </Col> */}
                    <Col xs="12" md="4">
                        <FormGroup>
                            <Label for="">New Password</Label>
                            <Input
                                type="password"
                                name="password"
                                onChange={(e) => inputChange(e.target.name, e.target.value)}
                                value={user_details.password || ''}
                                invalid={user_errors.password && true}
                                autoComplete="new-password"
                            />
                            <ErrorFormFeedback field={'password'} errors={user_errors} />
                        </FormGroup>
                    </Col>
                    <Col xs="12" md="4">
                        <FormGroup>
                            <Label for="">Repeat New Password</Label>
                            <Input
                                type="password"
                                name="password_confirmation"
                                onChange={(e) => inputChange(e.target.name, e.target.value)}
                                value={user_details.password_confirmation || ''}
                                invalid={user_errors.password_confirmation && true}
                            />
                            <ErrorFormFeedback field={'password_confirmation'} errors={user_errors} />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col xs="12">
                        <h4 className="in-form-heading"><span>Social Links</span></h4>
                    </Col>
                </Row>
                <Row form>
                    {
                        lookups.social_media.map((item, index) => (
                            <Col xs="12" md="6" key={index}>
                                <FormGroup>
                                    <Label for="">{item.label}</Label>
                                    <Input
                                        type="text"
                                        name="social_media"
                                        onChange={(e) => inputChange(e.target.name, e.target.value, item.value)}
                                        value={state.social_media && state.social_media[item.value] ? state.social_media[item.value] : ''}
                                    />
                                </FormGroup>
                            </Col>
                        ))
                    }
                </Row>
                <Row>
                    <Col xs="12">
                        <h4 className="in-form-heading"><span>Badges Earned</span></h4>
                    </Col>
                </Row>
                <Row>
                    <Col xs="12">
                        <BadgesComponent
                            user_id = {props.user_id ? props.user_id : ''}
                            callBack={(membership_reward_id) => {
                                inputChange('membership_reward_id', membership_reward_id);
                            }}
                        />
                    </Col>
                </Row>
            </div>
            <div className="tt-admin-form-footer">
                <Button
                    color="primary"
                    className="add-new-btn"
                    onClick={handleSubmit}
                    disabled={loading || props.profile_image_loading}
                >
                    {
                        loading ? <Spinner size="sm" /> : 'Save Changes'
                    }
                </Button>
            </div>
        </Form>

    );

}
