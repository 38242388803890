import { request } from "js/helpers/api";

export const supportEmailServices = {
    getAll,
    get,
    update,
};

function getAll(params = {}, hideModal){
    return request({
        method:'GET',
        url: 'support-emails',
        param: params,
        hideModal
    });
}

function get(support_email_id, hideModal){
    return request({
        method:'GET',
        url: `support-emails/${support_email_id}`,
        param: {},
        hideModal
    });
}


function update(details, support_email_id, hideModal){
    return request({
        method:'PUT',
        url: `support-emails/${support_email_id}`,
        param: details,
        content_type: 'json',
        cancel_token: 'supportEmailTemplateServices.update',
        hideModal
    });
}


