import { AuthLogin } from "js/components/auth/login/login";
import { ResetPassword } from "js/components/auth/reset-password/reset-password";
import { SignUp } from "js/components/auth/signup/signup";
export const AuthRoutes = [
    {
		path: "/auth/signup/:referral_token",
		component: SignUp
	},
	{
		path: "/auth/signup",
		component: SignUp
	},
    {
		path: "/auth/login",
		component: AuthLogin
	},
    {
		path: "/auth/reset-password",
		component: ResetPassword
    },
    {
		path: "/auth/verify",
		component: ResetPassword
	},
	{
		path: "/auth",
		component: AuthLogin
	},
];
