import React, { useState, useEffect } from "react"
import { Link } from 'react-router-dom';
import { Form, FormGroup, Input } from 'reactstrap';
import "js/components/legos/styles/admin-notes-module.css"
import classNames from 'classnames';
import { map, isEmpty, find, omit } from 'lodash';
import moment from 'moment';

import { adminNotesServices } from 'js/services';
import { ErrorFormFeedback } from 'js/components/common';


const AdminNotes = (props) => {

    let [admin_notes, setAdminNotes] = useState([]);
    let [state, setState] = useState({});
    let [create_errors, setErrors] = useState({});

    useEffect(() => {
        if (!isEmpty(props.admin_notes)) {
            setAdminNotes(props.admin_notes)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const apiCall = {
        createNote: (details) => {
            adminNotesServices.create(details)
                .then((response) => {
                    setErrors({});
                    admin_notes[state.current_index] = { ...response, description: response.note }
                    setAdminNotes(admin_notes);
                    setState({ ...state, current_index: null });
                })
                .catch((err) => {
                    let errors = {};
                    if (err.data) {
                        errors = err.data && err.data.errors;
                    }
                    setErrors(errors);
                });
        },
        updateNote: (details, note_id) => {
            adminNotesServices.update(details, note_id)
                .then((response) => {
                    setErrors({});
                    admin_notes[state.current_index] = { ...response, description: response.note }
                    setAdminNotes(admin_notes);
                    setState({ ...state, current_index: null });
                })
                .catch((err) => {
                    let errors = {};
                    if (err.data) {
                        errors = err.data && err.data.errors;
                    }
                    setErrors(errors);
                });
        },
        deleteNote: (note_id, index) => {
            adminNotesServices.remove(note_id)
                .then(() => {
                    setErrors({});
                    admin_notes = admin_notes.filter((item, ind) => index !== ind);
                    setAdminNotes(admin_notes);
                    setState({ ...state, current_index: null });
                })
                .catch((err) => {
                    let errors = {};
                    if (err.data) {
                        errors = err.data && err.data.errors;
                    }
                    setErrors(errors);
                });
        }
    }


    const inputChange = (key, value, ind) => {
        admin_notes = map(admin_notes, (item, index) => {
            if (ind !== index) {
                return item
            }
            return { ...item, [key]: value }
        });

        setAdminNotes(admin_notes);
    }

    const addNote = (e, index, note_id) => {
        e.preventDefault();
        let selected_note = find(admin_notes, (item, ind) => index === ind);

        const details = {
            ...selected_note,
            entity_type: props.entity_type,
            entity_id: props.entity_id,
        }

        if (note_id) {
            apiCall.updateNote({
                ...omit(details,
                    'note',
                    'created_by_user',
                    'created_at',
                    'updated_at',
                    'admin_note_id',
                    'created_by_user_id',
                    'deleted_at',
                    'partner_id',
                )
            }, note_id);
        }
        else {
            apiCall.createNote(details);
        }
    }

    const onDeleteNote = (admin_note_id, index) => {
        if (admin_note_id) {
            apiCall.deleteNote(admin_note_id, index);
        }
        else {
            admin_notes = admin_notes.filter((item, ind) => index !== ind);
            setAdminNotes(admin_notes);
            setState({ ...state, current_index: null });
        }
    }

    return (
        <div className="admin-notes-outer">
            <div className="admin-notes--header">
                <h5>Admin Notes</h5>
                <Link
                    to={"#"}
                    onClick={() => {
                        admin_notes.unshift({ description: '' });
                        setAdminNotes(admin_notes)
                    }
                    }
                >
                    <img src="/images/admin/global/add-square.svg" alt="" />
                </Link>
            </div>
            <div className="admin-data">
                {
                    !isEmpty(admin_notes) && admin_notes.map((data, index) => (
                        <div
                            key={'admin_note' + index}
                        >
                            {
                                state.current_index === index || isEmpty(data.description) ?
                                    <div
                                        className={classNames("add-admin-note d-none", { "d-flex": true })}
                                    >
                                        <Form>
                                            <FormGroup
                                                onClick={() => setState({ ...state, current_index: index })}
                                            >
                                                <Input
                                                    type="textarea"
                                                    name="text"
                                                    onChange={
                                                        (e) => inputChange('description', e.target.value, index)
                                                    }
                                                    value={data.description}
                                                    invalid={!isEmpty(create_errors) && (create_errors.description)
                                                        && true}
                                                />
                                                <ErrorFormFeedback field={'description'} errors={create_errors} />
                                            </FormGroup>
                                            <div className="admin-note--btn-wrap">
                                                <Link
                                                    to={'#'}
                                                    className="mr-2"
                                                    onClick={
                                                        () => onDeleteNote(data.admin_note_id, index)
                                                    }
                                                >
                                                    <img src="/images/admin/global/cancel.svg" alt="" />
                                                </Link>
                                                <Link
                                                    to={'#'}
                                                    onClick={(e) => addNote(e, index, data.admin_note_id)}
                                                >
                                                    <img src="/images/admin/global/apply.svg" alt="" />
                                                </Link>
                                            </div>
                                        </Form>
                                    </div>
                                    :
                                    <div className="admin-note"
                                        onClick={() => setState({ ...state, current_index: index })}>
                                        <p>{data.description}</p>

                                        <small>
                                            {data.updated_at ?
                                                moment(data.updated_at).format('MMM DD, YYYY - h:mm:ss a') : ''
                                            }
                                        </small>
                                    </div>
                            }
                        </div>
                    ))
                }
            </div>
        </div>
    );
}


export default AdminNotes;
