import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import {
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu
} from 'reactstrap';
import InfiniteScroll from 'react-infinite-scroller';
import { isEmpty, concat, uniqBy } from 'lodash';
import { notificationServices } from 'js/services';
import { ListLoader } from 'js/components/common';
import { useModal } from 'js/hooks';



export const NotificationHeader = (props) => {
    const [notifications, setNotifications] = useState([]);
    const [state, setState] = useState({
        has_more_notifications: true,
        toggle_menu: false,
    });
    const [count, setCount] = useState(null)
    // eslint-disable-next-line
    const [show, hide, modal, hideAllModal] = useModal();

    const apiCalls = {
        getAllNotifications: (params, type) => {
            notificationServices.getAll(params, hideAllModal).then((response) => {
                if (response.data) {
                    if (type === 'scroll_page') {
                        setNotifications(uniqBy(concat(notifications, response.data), 'id'));
                    }
                    else {
                        setNotifications(uniqBy(concat(response.data, notifications), 'id'));
                    }
                }
                if (response.meta) {
                    let meta = response.meta;
                    if (meta.total <= meta.current_page * meta.per_page) {
                        setState({
                            ...state,
                            has_more_notifications: false
                        })
                    }
                }

            });
        },
        getUnreadNotifications: (params) => {
            notificationServices.getAll(params, hideAllModal).then((response) => {
            });
        },
        getUnreadCount: () => {
            let params = {
                unread: true,
                type: 'count'
            }
            notificationServices.getUnreadCount(params)
            .then(res => {
                setCount(res)
            })
        }
    }

    useEffect(() => {
        let interval_id = setInterval(pullNotifications, 20000);
        return () => {
            clearInterval(interval_id);
        }

    })

    const pullNotifications = () => {
        apiCalls.getAllNotifications();
        apiCalls.getUnreadCount();
    }

    useEffect(() => {
        pullNotifications();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadMore = (page) => {
        apiCalls.getAllNotifications({
            page
        }, 'scroll_page');
    }

    const toggleMenu = () => {
        setState((state) => ({
            ...state,
            toggle_menu: !state.toggle_menu
        }));
    }

    useEffect(() => {
        if (!state.toggle_menu) {
            pullNotifications();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.toggle_menu]);
    return (
        <>
            <UncontrolledDropdown inNavbar isOpen={state.toggle_menu} toggle={toggleMenu}>
                <DropdownToggle
                    caret
                    className="navbar__link-btn navbar__notification-bell btn-link"
                    onClick={() => apiCalls.getUnreadNotifications({
                        unread: true
                    })}
                >
                    <svg width="20" height="21" viewBox="0 0 22 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M17.4366 11.389V8.71827C17.4366 4.97329 14.4007 1.93739 10.6557 1.93739C6.91073 1.93739 3.87484 4.97329 3.87484 8.71827V11.389C3.87484 14.0209 2.82962 16.5444 0.96875 18.4052V20.3426H20.3427V18.4052C18.4818 16.5444 17.4366 14.0209 17.4366 11.389Z" fill="#fff" />
                        <path d="M10.6556 24.6079C12.2607 24.6079 13.5617 23.307 13.5617 21.7018H7.74951C7.74951 23.307 9.05047 24.6079 10.6556 24.6079Z" fill="#fff" />
                        <path d="M12.593 3.87479H8.71826V1.93739C8.71826 0.866983 9.58524 0 10.6557 0C11.7261 0 12.593 0.866983 12.593 1.93739V3.87479Z" fill="#fff" />
                        <path d="M20.3427 20.3426C20.8777 20.3426 21.3114 19.9089 21.3114 19.3739C21.3114 18.8389 20.8777 18.4052 20.3427 18.4052C19.8077 18.4052 19.374 18.8389 19.374 19.3739C19.374 19.9089 19.8077 20.3426 20.3427 20.3426Z" fill="#fff" />
                        <path d="M0.968696 20.3426C1.50369 20.3426 1.93739 19.9089 1.93739 19.3739C1.93739 18.8389 1.50369 18.4052 0.968696 18.4052C0.4337 18.4052 0 18.8389 0 19.3739C0 19.9089 0.4337 20.3426 0.968696 20.3426Z" fill="#fff" />
                    </svg>


                    {
                        count && count.message_count > 0 &&
                        <span className="navbar__notification-badge"></span>
                    }
                    <span className="navbar__notification-badge">6</span>
                </DropdownToggle>
                <DropdownMenu
                    className="navbar__notification-dropdown" right
                >
                    <div className="navbar__notification-dropdown-head d-flex w-100">
                        <label className="navbar__notification-dropdown-head--text mr-auto">
                            <span className="pl-3 active">Notifications</span>
                        </label>
                    </div>
                    <ul
                        className="navbar__notification-dropdown-messages-area"
                        style={{ 'height': 200, 'overflow': 'auto' }}
                    >
                        <InfiniteScroll
                            pageStart={1}
                            initialLoad={false}
                            loadMore={loadMore}
                            hasMore={state.has_more_notifications}
                            loader={<ListLoader key={0} />}
                            useWindow={false}
                        >
                            {
                                notifications && !isEmpty(notifications) && notifications.map((item, index) => {
                                    let link_to = item.data && item.data.properties && item.data.properties.mc_thread_id ?
                                    `/messages/threads/${item.data.properties.mc_thread_id}` :'#notify-link'
                                    return(
                                    <li className="navbar__notification-dropdown-messages" key={`notification${item.id}`}>
                                        <Link to={link_to} className="navbar__notification-dropdown-item">
                                            {
                                                item.data.message ? item.data.message : ''
                                            }
                                        </Link>
                                    </li>
                                )})
                            }

                        </InfiniteScroll>
                    </ul>
                    {
                        props.type === 'admin' &&
                        <div className="d-flex mt-auto w-100 navbar__notification-dropdown-link">
                            <Link
                                to={props.type === 'admin' ? "/admin/notifications" : "/notifications"}
                                className="navbar__notification-dropdown-link--text"
                                onClick={() => {
                                    setState((state) => ({
                                        ...state,
                                        toggle_menu: false
                                    }))
                                }}
                            >
                                View All Notifications
                        </Link>
                        </div>
                    }
                </DropdownMenu>
            </UncontrolledDropdown>
        </>
    )
}
