import React, { useEffect, useRef, useState, useContext } from "react";
import { Row, Col, TabContent, TabPane, Nav, NavItem, NavLink, CustomInput, Spinner } from 'reactstrap';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import classnames from 'classnames';
import { pick, isEmpty, find, forEach, cloneDeep, clone } from 'lodash';

import AboutHost from "js/components/legos/aboutHost"
import UserBadges from "js/components/legos/userBadges"
import StarRatingMinimal from "js/components/legos/starRatingMinimal"
import Verifications from "js/components/legos/verifications"
import SocialLinks from "js/components/legos/socialLinks"
import AdminNotes from "js/components/legos/adminNotes"
import {
    UserGeneralInformation,
    UserTradeInProgress,
    UserListingTab,
    UserTripReviews
} from 'js/components/admin/components/user-management/details/components';
import ReferenceCard from "js/components/legos/referenceCard";
import EndorsementCard from "js/components/legos/endorsementCard";
import { ListLoader } from 'js/components/common';

import { userServices, attachmentServices } from 'js/services';
import { LookupContext } from 'js/contexts';
import { toast } from "react-toastify";
import { history } from 'js/helpers';

const styles = {
    option: (styles, state) => ({
        ...styles,
        backgroundColor: state.isSelected ? "#FFA405 " : state.isFocused ? "#FFA40520" : ""
    })
};
const customSingleValue = ({ data }) => (
    <div className="input-select">
        <div className="input-select__single-value-selected">
            {data.icon && <span className="input-select__icon mr-2">{data.icon}</span>}
            <span>{data.label}</span>
        </div>
    </div>
);
const CustomOption = ({ data, innerProps, isDisabled }) => !isDisabled ? (
    <div {...innerProps}>
        <div className="input-select__single-value">
            {data.icon && <span className="input-select__icon mr-2">{data.icon}</span>}
            <span>{data.label}</span>
        </div>
    </div>
) : null;

export const UserDetails = (props) => {
    const [active_tab, setTab] = useState("general_info");
    const file_ref = useRef(null);
    const [user, setUser] = useState({});
    const [state, setState] = useState({});
    const [memo_files, setFiles] = useState([]);
    const lookups = useContext(LookupContext);
    const [loading, setLoading] = useState({
        user: false,
        user_status: false,
        active_status: false,
        user_role: false,
        profile_uploading: false,
        is_auto_renewal_enabled: false
    });
    const [user_status, setUserStatus] = useState([]);
    const [user_roles_lookups, setUserRolesLookups] = useState([]);

    const image_url_status = {
        approved: 'success.svg',
        pending: 'warning.svg',
        rejected: 'error.svg'
    }
    const { match, location } = props;

    const apiCalls = {
        getSelectedUser: (user_id) => {
            setLoading({...loading, user: true});
            userServices.get(user_id).then((response_data) => {
                setSelectedUser(response_data, 'user')
            }).catch(err => {
                toast.error('Failed to fetch the user');
                history.push('/admin')
            });
        },
        updateUser: (details, key) => {
            setLoading({...loading, [key]: true});
            userServices.update(details, user_id)
            .then(res => {
                setSelectedUser(res, key)
            })
        }
    }

    const setSelectedUser = (data, key) => {
        let response = cloneDeep(data);
        let selected_role = {};
        let modified_role = {};
        let selected_status = {};

        if (!isEmpty(response.attachment)) {
            let memo_file = [];
            memo_file.push(response.attachment.attachment_id);
            setFiles(memo_file);
        }
        setUser(
            {
                ...response,
                user_status_id: response.user_status ?
                    response.user_status.user_status_id : '',
                role_name: response.user_role ? response.user_role : ''
            });

        if (!isEmpty(response.user_role)) {
            selected_role = find(lookups.user_roles, (item) => {
                return item.value === response.user_role;
            });
            modified_role = {
                ...selected_role,
                icon: <img src="/images/admin/global/users.svg" alt="" />,
                label: `Role: ${selected_role.label}`
            }
        }
        if (!isEmpty(response.country)) {
            let selected_country = find(lookups.countries, (o) => { return o.value === response.country });
            response.country = selected_country
        }
        if (!isEmpty(response.user_status)) {
            selected_status = { ...response.user_status, value: response.user_status.user_status_id }
            selected_status.icon = <img src={`/images/admin/global/status/${image_url_status[selected_status.slug]}`} width="16px" alt="" />
        }
        setState({
            ...state,
            selected_status,
            selected_role_name: clone(modified_role)
        })
        setLoading({...loading, [key]: false});
    }

    useEffect(() => {


        if (match && match.params && match.params.user_id) {
            apiCalls.getSelectedUser(match.params.user_id);
        }
        if(lookups.user_status){
            const icon_user_status = [];
            forEach(lookups.user_status, (item) => {
                icon_user_status.push({
                    ...item,
                    icon: <img src={`/images/admin/global/status/${image_url_status[item.slug]}`} width="16px" alt="" />
                })
            });
            setUserStatus(icon_user_status);
        }

        if(lookups.user_roles){
            const user_roles_lookups = [];
            forEach(lookups.user_roles, (item) => {
                user_roles_lookups.push({
                    ...item,
                    label: `Role: ${item.label}`,
                    icon: <img src={`/images/admin/global/users.svg`} width="16px" alt="" />
                })
            });
            setUserRolesLookups(user_roles_lookups);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const toggle = (tab) => {
        setTab(tab)
    }

    const onAddFile = (e) => {
        e.preventDefault();
        file_ref.current.click()
    }

    const fileHandler = (e) => {
        const file = e.target.files[0];
        const fileFormat = new FormData();
        setLoading((loading) => ({
            ...loading,
            profile_uploading: true
        }));

        fileFormat.append('filename', file);
        fileFormat.append('entity_type', 'User');

        attachmentServices.upload(fileFormat)
            .then(function (response) {
                // setLoading((loading) => ({
                //     ...loading,
                //     profile_uploading: false
                // }));
                // setUser({
                //     ...user,
                //     attachment: response
                // })
                let attachments = [response.attachment_id]
                apiCalls.updateUser({attachments}, 'profile_uploading')
            })
            .catch(function (error) {
                setLoading((loading) => ({
                    ...loading,
                    profile_uploading: false
                }));
                props.onError && props.onError(error);
                return;
            });
    }
    let user_id = props.match && props.match.params && props.match.params.user_id ? props.match.params.user_id : null;
    const current_page = location && location.state && location.state.current_page ? location.state.current_page : '';
    return (
        <div>
            <div className="generic-page-header">
                <div className="header-data-section">
                    <Link to={{
                        pathname: "/admin/user-management",
                        state: { current_page }
                    }} className="back-btn"><img src="/images/admin/global/back.svg" alt="" /></Link>
                    <h2 className="page-head my-0">{user.full_name ? user.full_name : ''} <span>({user.email ? user.email : ''})</span></h2>
                </div>

                <div className="header-data-section">
                    <div className="custom-react-select--white mr-3">
                    {loading.user_status ?  <Spinner size="sm"/>:
                        <Select
                            components={{ SingleValue: customSingleValue, Option: CustomOption }}
                            className="react-select-container"
                            classNamePrefix="react-select"
                            options={user_status}
                            onChange={(e) =>
                                apiCalls.updateUser({user_status_id: e.value}, 'user_status')
                            }
                            value={state.selected_status || null}
                            styles={styles}
                        />
                    }
                    </div>
                    <div className="custom-react-select--white mr-3">
                        {loading.user_role ?  <Spinner size="sm"/>:
                        <Select
                            components={{ SingleValue: customSingleValue, Option: CustomOption }}
                            className="react-select-container"
                            classNamePrefix="react-select"
                            options={user_roles_lookups}
                            onChange={(e) =>
                                apiCalls.updateUser({role_name: e.value}, 'user_role')
                            }
                            value={state.selected_role_name || null}
                            styles={styles}
                        />
                        }
                    </div>
                    <div className="user-status">
                        <span>Status: {user.is_active ? 'Active' : 'Inactive'}
                        {loading.active_status ?
                            <Spinner size="sm"/>:
                            <CustomInput
                                type="switch"
                                id="user_status"
                                name="customSwitch"
                                label=""
                                onChange={(e) =>
                                    apiCalls.updateUser({is_active: e.target.checked ? 1 : 0 }, 'active_status')
                                }
                                checked={user.is_active || false}
                            />
                        }
                        </span>
                    </div>
                    <div className="user-status">
                        <span>Auto renewal: {user.is_auto_renewal_enabled ? 'Enabled ' : 'Disabled'}
                        {loading.is_auto_renewal_enabled ?
                            <Spinner size="sm"/>:
                            <CustomInput
                                type="switch"
                                id="user_auto_renewal"
                                name="customSwitch"
                                label=""
                                onChange={(e) =>
                                    apiCalls.updateUser({is_auto_renewal_enabled: e.target.checked ? 1 : 0 }, 'is_auto_renewal_enabled')
                                }
                                checked={user.is_auto_renewal_enabled || false}
                            />
                        }
                        </span>
                    </div>
                    <Link color="primary" className="add-new-btn" to={`/profile/${user_id}`}>View Profile</Link>
                </div>
            </div>
            <div className="tt-admin-card">
                <div className="tt-admin-card--data">
                    <Row className="no-gutters">
                        <Col xs="12" md="12" lg="12" xl="9">
                            <div className="trip-details-data-section">
                                <div className="nav-tabs-scroll">
                                    <Nav tabs className="tt-admin-tabs">
                                        <NavItem>
                                            <NavLink
                                                className={classnames({ active: active_tab === 'general_info' })}
                                                onClick={() => { toggle('general_info'); }}>
                                                <div className="tab-label">General Information</div>
                                            </NavLink>
                                        </NavItem>

                                        <NavItem>
                                            <NavLink
                                                className={classnames({ active: active_tab === 'trade_in_progress' })}
                                                onClick={() => { toggle('trade_in_progress'); }}>
                                                <div className="tab-label">{`Trade in Progress (${user.trades_in_progress_count ? user.trades_in_progress_count : ''})`}</div>
                                            </NavLink>
                                        </NavItem>

                                        <NavItem>
                                            <NavLink
                                                className={classnames({ active: active_tab === 'listings' })}
                                                onClick={() => { toggle('listings'); }}>
                                                <div className="tab-label">Listings</div>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                className={classnames({ active: active_tab === 'trip_reviews' })}
                                                onClick={() => { toggle('trip_reviews'); }}>
                                                <div className="tab-label">Trip Reviews</div>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                className={classnames({ active: active_tab === 'endorsements' })}
                                                onClick={() => { toggle('endorsements'); }}>
                                                <div className="tab-label">Endorsements & References</div>
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                </div>
                                {user_id &&
                                    <TabContent activeTab={active_tab}>
                                        <TabPane tabId="general_info">
                                            {
                                                active_tab === 'general_info' &&
                                                loading.user ? <ListLoader />
                                                :
                                                <UserGeneralInformation
                                                    data={user}
                                                    files={memo_files}
                                                    user_id={user_id}
                                                    profile_image_loading={loading['profile_uploading']}
                                                />
                                            }
                                        </TabPane>
                                        <TabPane tabId="trade_in_progress">
                                            {
                                                active_tab === 'trade_in_progress' &&
                                                <UserTradeInProgress
                                                    user_id={user_id}
                                                />
                                            }
                                        </TabPane>
                                        <TabPane tabId="listings">
                                            <UserListingTab user_id={user_id} />
                                        </TabPane>
                                        <TabPane tabId="trip_reviews">
                                            <UserTripReviews user_id={user_id} />
                                        </TabPane>
                                        <TabPane tabId="endorsements">
                                            <Row className="tt-admin-form user-endorsement">
                                                <div className="tt-admin-form-inner">
                                                    <Row>
                                                        {user.endorsements && user.endorsements && user.endorsements.map((item, index) => (
                                                            <React.Fragment key={`endrosment${index}`}>
                                                                {item.endorsement_status_id === 20 &&
                                                                    <Col xs="12" key={index}>
                                                                        <EndorsementCard
                                                                            data={item}
                                                                        />
                                                                    </Col>
                                                                }
                                                            </React.Fragment>
                                                        ))}
                                                    </Row>
                                                    <Row>
                                                        {user.references && user.references && user.references.map((item, index) => (
                                                            <Col xs="12" md="6" key={index}>
                                                                <ReferenceCard
                                                                    refImage="images/web/global/logo-icon.svg"
                                                                    refUser={item.full_name}
                                                                    refEmail={item.email}
                                                                />
                                                            </Col>
                                                        ))}
                                                    </Row>
                                                </div>
                                            </Row>
                                        </TabPane>
                                    </TabContent>
                                }
                            </div>
                        </Col>
                        <Col xs="12" md="12" lg="12" xl="3">
                            <div className="trip-details-sidebar user-details-sidebar">
                                <Row>
                                    <Col>
                                        <AboutHost data={user} loading={loading['profile_uploading']}/>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div className="personal-details">

                                                <Link to={"#"} onClick={(e) => onAddFile(e)} className="add-new-link ml-0">
                                                    Replace Profile Image
                                                </Link>
                                                <input
                                                    type="file"
                                                    ref={file_ref}
                                                    onChange={(e) => fileHandler(e)}
                                                    style={{ display: "none" }}
                                                    accept={"image/*"}
                                                    disabled={loading['profile_uploading']}
                                                />

                                            {/* TODO_HERE */}
                                            {/* <h4>Member Since: <span>July 2018</span></h4> */}
                                            {/* <h4>Texas, United States</h4> */}
                                        </div>

                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <UserBadges
                                            data={pick(user, ['response_rate', 'exchange_count', 'endorsements'])}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <StarRatingMinimal data={user} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <hr className="hr-sidebar" />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Verifications data={user} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <hr className="hr-sidebar" />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <SocialLinks data={user} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <hr className="hr-sidebar" />
                                    </Col>
                                </Row>
                                <Row>
                                    {
                                        props.match && props.match.params && props.match.params.user_id && !isEmpty(user) &&
                                        <Col>
                                            <AdminNotes
                                                entity_type="User"
                                                entity_id={props.match.params.user_id}
                                                admin_notes={user.admin_notes}
                                            />
                                        </Col>
                                    }
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
}
