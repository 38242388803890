import React from "react"
import Slider from "react-slick";
import "js/components/legos/styles/partners-slider-module.css"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { StaticLink } from 'js/components/common';
import { orderBy, isArray } from 'lodash'

const PartnersSlider = (props) => {

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        centerMode: false,
        arrows: true,
        autoplay: true,
        autoplaySpeed: 3000,
        className: 'partners-slider',
        responsive: [
            {
                breakpoint: 991.98,
                settings: {
                slidesToShow: 3
                }
            },
            {
                breakpoint: 575.98,
                settings: {
                    slidesToShow: 2,
                    arrows: false
                }
                }
            ]
    };
    return (
        <div>
            <Slider {...settings}>
                {isArray(props.logos) && orderBy(props.logos, ['order'], ['asc']).map((item, index) => (
                    <div key={ index } >
                        <StaticLink
                            url={item.external_link}
                            content={
                                <>
                                    <img
                                        src={
                                            item.attachment ?
                                            item.attachment.thumbnail_url ?
                                            item.attachment.thumbnail_url:
                                            item.attachment.attachment_url : ''
                                        }
                                        alt={ item.name }
                                        style={ { width: '100px' } }
                                    />
                                </>
                            }

                        />

                    </div>
                ))}
            </Slider>
        </div>
    );
}
export default PartnersSlider;
