import React, { useState } from "react";
import { toast } from "react-toastify";
import { Row, Col, Modal, ModalHeader, ModalBody, Button, Form, FormGroup, Label, Input, Spinner } from 'reactstrap';
import { isEmpty } from 'lodash';

import { tradeServices } from 'js/services';
import { ErrorFormFeedback } from 'js/components/common';





export const CancelTripModal = (props) => {
    const { is_open, hide } = props;
    const [state, setState] = useState({});
    const [loading, setLoading] = useState(false);
    const [createErrors, setErrors] = useState({});


    const user_id = props.user_id ? props.user_id : '';
    const selected_trade_id = props.selected_trade_id ? props.selected_trade_id : '';

    const apiCalls = {
        cancelTrade: (details) => {
            setLoading(true);
            tradeServices.cancel(selected_trade_id, user_id, details, hide).then(() => {
                setLoading(false);
                toast.success('Cancelled Successfully');
                hide();
                props.parentCallBack();
            }).catch((err) => {
                let errors = err.data && err.data.errors;
                setLoading(false);
                setErrors(errors);
            });
        },
    }

    const cancelTrip = () => {
        let details = { ...state };

        apiCalls.cancelTrade(details);
    }

    return (
        <div>
            <Modal
                isOpen={is_open}
                toggle={() => hide()}
                className="tt-modal--admin modal-dialog-centered"
            >
                <ModalHeader toggle={() => hide()}>Cancel Trip</ModalHeader>
                <ModalBody>
                    <Form className="profile-form">

                        <Row>
                            <Col xs="12" className="mt-4">
                                <FormGroup>
                                    <Label for="" >Please let us know why trip was canceled to help us improve Trips4Trade.</Label>
                                    <Input
                                        type="textarea"
                                        onChange={(e) => setState({
                                            ...state,
                                            reason: e.target.value
                                        })}
                                        invalid={!isEmpty(createErrors) && (createErrors.reason)
                                            && true
                                        }
                                    />
                                    <ErrorFormFeedback field={'reason'} errors={createErrors} />

                                </FormGroup>

                            </Col>

                        </Row>

                    </Form>
                    <Row>
                        <Col xs="12">
                            <div className="d-flex justify-content-center">
                                <div className="add-listing-btn-group">
                                    <Button
                                        className="btn-outline btn-outline--red"
                                        onClick={cancelTrip}
                                    >
                                        {
                                            loading ? <Spinner size="sm"/> : 'Cancel Trip'
                                        }
                                    </Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        </div>
    )
}
