import React, { useState } from "react";
import { Row, Col, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { membershipPlanService } from 'js/services';
import { useEffect } from "react";
import { useModal } from 'js/hooks';
import { isEmpty } from 'lodash';

import { ListLoader } from "js/components/common";




export const MembershipUpgrades = () => {

    const [membership_plan_upgrades, setMembershipPlanUpgrades] = useState([]);
    const [state, setState] = useState({
        title: '',
        loading: false
    });
    const [show] = useModal();


    const apiCalls = {
        getAllMembershipPlanUpgrades: (params) => {
            setState({ ...state, loading: true })
            membershipPlanService.getAllMembershipPlanUpgrades(params).then((response) => {
                setState({ ...state, loading: false })
                setMembershipPlanUpgrades(response);
            }).catch((err) => {
                setState({ ...state, loading: false })
            });
        }
    }

    useEffect(() => {
        apiCalls.getAllMembershipPlanUpgrades();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <div>
            <div className="generic-page-header">
                <div className="header-data-section justify-content-start">
                    <h2 className="page-head my-0">Membership Upgrades</h2>
                </div>
            </div>
            <div>
                <div className="tt-admin-card membership-card">
                    <div className="tt-admin-card--data tt-admin-card--data_membership ">
                        <Row>
                            <Col xs="12">
                                <div className="membership-card-outer">
                                    <Row className="justify-content-between align-items-center mb-4">
                                        <Col xs="12" lg="5">
                                        </Col>
                                        <Col xs="12" lg="5" className="text-right">
                                            <Button
                                                color="primary"
                                                className="add-new-btn"
                                                onClick={() =>
                                                    show('AddEditMembershipPlanUpgradeModal',
                                                        {
                                                            ...state,
                                                            title: 'Add Membership Upgrade',
                                                            callBack: () => apiCalls.getAllMembershipPlanUpgrades(),
                                                        })
                                                }
                                            >
                                                <i>
                                                    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M5.03027 7.77295H0.171875V5.6001H5.03027V0.619629H7.22754V5.6001H12.0981V7.77295H7.22754V12.729H5.03027V7.77295Z" fill="#fff" />
                                                    </svg>
                                                </i>
                                                Add Upgrade
                                            </Button>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                            {state.loading ?
                                <ListLoader />
                                :
                                isEmpty(membership_plan_upgrades) ?
                                <div className="no-records-found">
                                    <p>No Records Found.</p>
                                </div>
                                :
                                !isEmpty(membership_plan_upgrades) && membership_plan_upgrades.map((item, index) => (
                                    <Col xs="12" className="mt-1 mb-1" key={`member_ship_upgrade${index}`}>
                                        <div className="membership-list-card">
                                            <div className="membership-data">
                                                <h5>{item.label ? item.label : ''} ( <Link to={"#"} onClick={() =>
                                                    show('AddEditMembershipPlanUpgradeModal',
                                                        {
                                                            ...state,
                                                            title: 'Edit Membership Upgrade',
                                                            callBack: () => apiCalls.getAllMembershipPlanUpgrades(),
                                                            membership_plan_upgrade_id: item.membership_plan_upgrade_id

                                                        })
                                                }>Edit</Link> )</h5>
                                                <p>{item.description ? item.description : ''}</p>
                                            </div>
                                            <div className="membership-amount">
                                                + ${item.price ? item.price : ''}
                                            </div>
                                        </div>
                                    </Col>
                                ))
                            }
                        </Row>
                    </div>
                </div>
            </div>
        </div>
    );
}


