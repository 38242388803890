import React, {useEffect, useState} from "react";
import { Form } from 'reactstrap';
import { concat, isEmpty } from 'lodash';

import { tripsServices } from "js/services";
import { ListLoader, TripListing } from 'js/components/common';

export const  UserListingTab = (props) => {
    const [ trips, setTrips ] = useState({});
    const [ loading, setLoading ] = useState(false);
    const [ loading_more, setLoadMoreLoading ] = useState(false);

    const getUserTrips = (params, mode) => {
        mode === 'append' ? setLoadMoreLoading(true) :  setLoading(true);
        tripsServices.getAll(params).then(response => {
            if(mode === 'append') {
                response.data = concat( trips.data, response.data );
            }
            setTrips(response);
            mode === 'append' ? setLoadMoreLoading(false) :  setLoading(false);
        }).catch(error => {
            console.log(error)
        })
    }

    useEffect(() => {
        getUserTrips({user: props.user_id})
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (

        <Form className="tt-admin-form">
            <div className="tt-admin-form-inner">
                {loading ?
                    <ListLoader/>
                    :
                    <>
                    {!isEmpty(trips) && trips.data &&
                        <TripListing
                            trips={trips}
                            disable_bookmark={true}
                            onLoadMore={(page) => getUserTrips({
                                page,
                                user: props.user_id
                            }, 'append')}
                            load_more_loading={loading_more}
                        />
                    }
                    </>
                }
            </div>
        </Form>
    );
}
