import React from "react"
import Slider from "react-slick";
import { isEmpty } from 'lodash';
import "js/components/legos/styles/small-image-slider-module.css"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export const SmallImageSlider = (props) => {
    let settings = {
        dots: false,
        infinite: true,
        autoplay: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
    //    arrows: true,
        className: 'small_image_slider',
        responsive: [
            {
                breakpoint: 1199.98,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                }
            },
            {
                breakpoint: 767.98,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 565.98,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false
                }
            }
        ]
    };
    if( props.images && !isEmpty(props.images)) {
        if(props.images.length < 3) {
            settings.slidesToShow = props.images.length
            settings.arrows = false
            settings.autoplay = false
        }
    }
    return (
        <div>

            <Slider {...settings}>
                {props.images && !isEmpty(props.images) && props.images.map((img, index) => (
                <div key={index}>
                    <div className="small_slider_wrapper">
                        <img src={img.attachment ? img.attachment.thumbnail_url?img.attachment.thumbnail_url:img.attachment.attachment_url :''} alt="" />
                    </div>
                </div>
                ))}
            </Slider>
        </div>
    );
}
