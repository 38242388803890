import { request } from "js/helpers/api";

export const reportServices = {
    userReport,
    tripReport,
    exchangeReport,
    revenueReport,
    revenueSource,
    analytics,
    exportToExcel,

    usersPromoCodes,
    promoCodeDetails
};

function userReport(params = {}) {
    return request({
        method: 'GET',
        url: 'report/users',
        param: params,
        is_authenticated: true,
        cancel_token: 'reportServices.userReport'
    });
}

function tripReport(params = {}) {
    return request({
        method: 'GET',
        url: 'report/trips',
        param: params,
        is_authenticated: true,
        cancel_token: 'reportServices.tripReport'
    });
}

function exchangeReport(params = {}) {
    return request({
        method: 'GET',
        url: 'report/exchanges',
        param: params,
        is_authenticated: true,
        cancel_token: 'reportServices.exchangeReport'
    });
}

function revenueReport(params = {}) {
    return request({
        method: 'GET',
        url: 'report/revenues',
        param: params,
        is_authenticated: true,
        cancel_token: 'reportServices.revenueReport'
    });
}

function revenueSource(params = {}) {
    return request({
        method: 'GET',
        url: 'report/revenues/invoices',
        param: params,
        is_authenticated: true,
        cancel_token: 'reportServices.revenueSource'
    });
}

function exportToExcel(params, type) {
    return request({
        method: 'GET',
        url: `report/${type}/generate-export-link`,
        param: params,
    });
}

function analytics(params) {
    return request({
        method: 'GET',
        url: `report/analytics`,
        param: params,
    });
}

function usersPromoCodes(params) {
    return request({
        method: 'GET',
        url: `report/users/promo-codes`,
        param: params,
    });
}

function promoCodeDetails(promo_code, params={}) {
    return request({
        method: 'GET',
        url: `report/users/promo_code/${promo_code}`,
        param: params,
    });
}


