import React, { useState } from "react";
import { Row, Col, FormGroup, Label, Input, Button, Spinner } from 'reactstrap';
import {
    CardNumberElement,
    CardCvcElement,
    CardExpiryElement,
    useStripe,
    useElements
} from '@stripe/react-stripe-js';
import { isEmpty } from 'lodash';
import { stripeServices } from 'js/services';
import { toast } from "react-toastify";




export const MyCheckoutForm = (props) => {

    const options = {
        style: {
            base: {
                color: "#424770",
                letterSpacing: "0.025em",
                fontFamily: "Source Code Pro, monospace",
                "::placeholder": {
                    color: "#aab7c4"
                },
                borderRadius: '4px',
                height: 40,
                backgroundColor: '#ffff',
                fontSize:"17px",
                lineHeight:"40px",
            },
            invalid: {
                color: "#9e2146"
            }
        }

    }

    const [errors, setErrors] = useState({});
    const stripe = useStripe();
    const elements = useElements();
    const formated_style = props.type && props.type === 'admin' ? {} : options;
    const [loading, setLoading] = useState(false);

    const user_id = props.user_id ? props.user_id : '';

    const apiCalls = {
        customerCardMapping: (user_id, params) => {
            setLoading(true);
            stripeServices.customerCardMapping(user_id, params).then((response) => {
                setLoading(false);
                toast.success('Updated Successfully');
            }).catch((err) => {
                setLoading(false);
                setErrors(err);
            });
        },
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);


        if (!stripe || !elements) {
            return;
        }

        const cardElement = elements.getElement(CardNumberElement);

        const { error, token } = await stripe.createToken(cardElement);

        if (error) {
            setLoading(false);
            console.log('[error]', error);
            setErrors(error);
        } else {
            setLoading(false);
            apiCalls.customerCardMapping(user_id, { payment_token: token.id });
        }

    };

    return (
        <div>
            <Row form>
                <Col xs="12" md="8">
                    <FormGroup>
                        <Label for="">Card Number</Label>
                        <CardNumberElement
                            options={formated_style}
                            className="form-control"
                        />
                    </FormGroup>
                </Col>
                <Col xs="12" md="4">
                    <FormGroup>
                        <Label for="">CVV</Label>
                        <CardCvcElement
                            options={formated_style}
                            className="form-control"
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row form>
                <Col xs="12" md="6">
                    <FormGroup>
                        <Label for="">Name of Card</Label>
                        <Input type="text" name="text" />
                    </FormGroup>
                </Col>
                <Col xs="12" md="6">
                    <FormGroup>
                        <Label for="">Expiration Date</Label>
                        <div className="tt-custom-react-select">
                            <CardExpiryElement
                                options={formated_style}
                                className="form-control"
                            />
                        </div>
                    </FormGroup>
                </Col>
            </Row>
            {!isEmpty(errors) && errors.message && (
                <div>
                    <div className="invalid-feedback d-block">{errors.message}</div>
                </div>
            )}
            {!isEmpty(errors) && errors.customer && (
                <div>
                    <div className="invalid-feedback d-block">{errors.customer}</div>
                </div>
            )}
            <div className="d-flex justify-content-center mt-1">
                <Button
                    className="btn btn-outline text-capitalize"
                    onClick={handleSubmit}
                >
                    {loading ? <Spinner size="sm" /> : "Submit Card"}
                </Button>
            </div>
        </div>
    );
}
