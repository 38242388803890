import React, {useEffect, useState} from "react";
import { Row, Col, Form } from 'reactstrap';
import { isEmpty } from 'lodash';

import TripsReviewCard from "js/components/legos/tripsReviewCard"
import { tripsServices } from "js/services";

export const UserTripReviews = (props) => {
    const [reviews, setReviews] = useState([])
    useEffect(() => {
        getReviews();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getReviews = () => {
        let params = {user_id: props.user_id}
        tripsServices.getReviews(params)
        .then(res => {
            setReviews(res)
        })
    }
    const toggleReview = (review_id, status) => {
        let params= {
            is_active: status ? 1: 0
        }
        tripsServices.toggleReview(review_id, params)
        .then(res => {
            getReviews();
        })
    }

    return (

        <Form className="tt-admin-form user-trip-reviews">
            <div className="tt-admin-form-inner">
                <Row>
                    {!isEmpty(reviews) && reviews.data && reviews.data.map((item, index) => (
                    <Col xs="12" key={index}>
                        <TripsReviewCard
                            tripUserImage={item.reviewed_by_user  && item.reviewed_by_user.attachment ?
                                item.reviewed_by_user.attachment.thumbnail_url ?
                                item.reviewed_by_user.attachment.thumbnail_url: item.reviewed_by_user.attachment.attachment_url : ''
                            }
                            tripUser={item.reviewed_by_user ? item.reviewed_by_user.full_name : ''}
                            tripUserType="Guest"
                            tripUserReview={item.description}
                            tripName={`${item.trip} (#${item.trip_id}) `}
                            tripDate={item.trip_date? item.trip_date: 'TODO'}
                            trip_id={item.trip_id}
                            trip_rating={Number(item.rating)}
                            callBack={(status) => toggleReview(item.review_id, status)}
                            review_status={item.is_active}
                        />
                    </Col>
                    ))}
                </Row>
            </div>

        </Form>

    );

}
