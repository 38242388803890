import { findIndex, first } from "lodash";

const checkExtenalUrl = ( param ) => {

    const regEx = /^http|^\/\//i;
    let parser = document.createElement( 'a' );
    parser.href = param;

    if ( window.location.hostname === parser.hostname )
    {

        return {external_url : false , path: parser.pathname + parser.search}
    }
    else
    {
        return param && param.match( regEx ) ? { external_url: true } : { external_url: false };
    }

}
const findAndReplaceArray = ( arr, match, new_obj ) => {
    var index = findIndex(arr, match);
    arr.splice(index, 1, new_obj);
    return arr;
}
const findCategory = ( categories, filter ) => {

    let category = first(categories)

    return category
}
export const utilFunctions = {
    checkExtenalUrl,
    findAndReplaceArray,
    findCategory,
}
