import React, { useState, useEffect, useContext } from "react";
import { Button, Col, CustomInput, Form, FormGroup, Input, Row, Table } from 'reactstrap';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { forEach, isEmpty, keys } from 'lodash';
import moment from "moment";

import { promotionServices } from 'js/services';
import { PaginationComponent, ListLoader } from 'js/components/common';
import { LookupContext } from 'js/contexts';
import { useParamChange, useModal } from 'js/hooks';

export const Promotions = () => {
    const [state, setState] = useState({
        is_modal_open: false,
        is_delete_modal_open: false,
        promotion_id: null,
        selected_status: null,
        promotion_toggle_value: {},
        promotion_type: null,
        selected_promotions: {},

    });
    const [ promotions, setPromotions ] = useState([]);
    const [ promotion_toggle_value, setToggle ] = useState({});
    const [ show, hide] = useModal();
    const [ loading, setLoading] = useState(false);

    const lookups = useContext(LookupContext);

    const status_lookups = [
        { value: 1, label: 'Active' },
        { value: 0, label: 'Inactive' }
    ];
    const bulk_action_lookup = [
        { value: 'delete', label: 'Delete' },
    ];
    const styles = {
        option: (styles, state) => ({
            ...styles,
            backgroundColor: state.isSelected ? "#FFA405 " : state.isFocused ? "#FFA40520" : ""
        })
    };

    useEffect(() => {
        getAllPromotion(params);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getAllPromotion = async (params) => {
        setLoading(true);
        await promotionServices.getAll(params)
            .then((response) => {
                let promotion_toggle_value = {};
                setPromotions(response);
                if (!isEmpty(response.data)) {
                    forEach(response.data, (item) => {
                        promotion_toggle_value[item.promotion_id] = item.is_active === 1 ? true : false;
                    });
                }

                setToggle(promotion_toggle_value);
                setLoading(false);
            }).catch(() => {
                setLoading(false);
            })
    }

    const [params, onParamsChange] = useParamChange(getAllPromotion);

    const deletePromotion = (promotion_id) => {
        promotionServices.remove(promotion_id).then(() => {
            let current_params = { ...params };
            if (promotions.data.length === 1) {
                if (current_params.page !== 1) {
                    current_params.page = current_params.page - 1;
                }
            }
            getAllPromotion(current_params);
            hide('ConfirmModal');
        });
    }

    const bulkAction = (value) => {
        let selected_promotion_keys = keys(state.selected_promotions);
        let selected_items_array = [];

        forEach(selected_promotion_keys, (item) => {
            if (state.selected_promotions[item]) {
                selected_items_array.push(item);
            }
        });

        if (value === 'delete') {
            if (!isEmpty(selected_items_array)) {
                let title = selected_items_array.length > 1 ? 'Delete Promotions' : 'Delete Promotion';
                show('ConfirmModal', {
                    title,
                    body: `Do you want to ${title} ?`,
                    button_name: 'Delete',
                    action: () => bulkActionService({ action_type: value, items: selected_items_array }),
                    loading
                });
            }
        }
    }

    const bulkActionService = (details) => {
        setLoading(true);
        promotionServices.bulkAction(details)
            .then(() => {
                setLoading(false);
                hide('ConfirmModal');
                getAllPromotion(params)
                setState({ ...state, selected_promotions: {}, action_type: null, select_all: false });
            })
            .catch((error) => {
                setLoading(false);
                hide('ConfirmModal');
                setState({ ...state, action_type: null });
                error.data && error.data.errors && error.data.errors.general && show('ErrorModal', {
                    title: 'Error',
                    body: error.data.errors.general,
                });
            })
    }

    const selectAll = (value) => {
        let selected_promotions = {};

        if (!isEmpty(promotions.data)) {
            forEach(promotions.data, (promotion) => {
                selected_promotions[promotion.promotion_id] = value;
            });
        }
        setState({ ...state, selected_promotions, select_all: value });
    }

    const toggle = (value, promotion_id) => {
        let details = value ? { is_active: 1 } : { is_active: 0 };

        promotionServices.update(details, promotion_id).then(() => {
            setToggle({ ...promotion_toggle_value, [promotion_id]: value });
        });
    }

    return (
        <div>
            <div className="generic-page-header">
                <div className="header-data-section">
                    <h2 className="page-head my-0">Promotions</h2>
                </div>
                <div className="header-data-section">
                    <Button
                        color="primary"
                        className="add-new-btn"
                        onClick={() =>
                            show('AddEditPromotionModal', {
                                callBack: () => {
                                    getAllPromotion(params);
                                    hide('AddEditPromotionModal');
                                }
                            })
                        }
                    >
                        <i>
                            <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.03027 7.77295H0.171875V5.6001H5.03027V0.619629H7.22754V5.6001H12.0981V7.77295H7.22754V12.729H5.03027V7.77295Z" fill="#fff" />
                            </svg>
                        </i>Add a promotion
                    </Button>
                </div>
            </div>
            <div className="tt-admin-card">
                <div className="tt-admin-card--header tt-admin-card--header_filter">
                    <div className="tt-filter">
                        <Form>
                            <Row className="justify-content-between">
                                <Col xs="12" lg="5">
                                    <Row>
                                        <Col xs="12" lg="6">
                                            <FormGroup>
                                                <div className="custom-react-select--no-border w-100">
                                                    <Select
                                                        placeholder="Bulk Action"
                                                        className="react-select-container"
                                                        classNamePrefix="react-select"
                                                        options={bulk_action_lookup}
                                                        styles={styles}
                                                        onChange={(e) => {
                                                            bulkAction(e.value);
                                                        }}
                                                    />
                                                </div>
                                            </FormGroup>

                                        </Col>
                                        <Col xs="12" lg="6">
                                            <FormGroup>
                                                <div className="filter-input--outer">
                                                    <Input
                                                        type="text"
                                                        name=""
                                                        id=""
                                                        placeholder="Search"
                                                        onChange={(e) => onParamsChange('search', e.target.value)}
                                                    />
                                                </div>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs="12" lg="5">
                                    <Row>
                                        <Col xs="12" md="6">
                                            <FormGroup>
                                                <div className="custom-react-select--no-border w-100">
                                                    <Select
                                                        placeholder="Promotion Type"
                                                        className="react-select-container"
                                                        classNamePrefix="react-select"
                                                        options={lookups.promotion_types}
                                                        styles={styles}
                                                        onChange={
                                                            (e) => {
                                                                onParamsChange('promotion_type', e.value);
                                                                setState({ ...state, promotion_type: e })
                                                            }
                                                        }
                                                        value={state.promotion_type ? state.promotion_type : null}
                                                    />
                                                </div>
                                            </FormGroup>
                                        </Col>
                                        <Col xs="12" md="6">
                                            <FormGroup>
                                                <div className="custom-react-select--no-border w-100">
                                                    <Select
                                                        placeholder="Status"
                                                        className="react-select-container"
                                                        classNamePrefix="react-select"
                                                        options={status_lookups}
                                                        styles={styles}
                                                        onChange={(e) => {
                                                            onParamsChange('is_active', e.value);
                                                            setState({ ...state, selected_status: e })
                                                        }
                                                        }
                                                        value={state.selected_status ? state.selected_status : null}
                                                    />
                                                </div>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </div>
                <div className="tt-admin-card--data">
                    <div className="table-wrap">
                        <Table responsive className="tt-admin-table-compact tt-admin-table-compact--checkbox ">
                            <thead>
                                <tr>
                                    <th>
                                        <div className="tt-admin-checkbox">
                                            <CustomInput
                                                type="checkbox"
                                                id="tt-check-promotion-select-all"
                                                className="tt-checkbox"
                                                onChange={(e) => selectAll(e.target.checked)}
                                                checked={state.select_all ? state.select_all : false}
                                            />
                                        </div>
                                    </th>
                                    <th>Promo Name</th>
                                    <th className="sortable" onClick={() => onParamsChange('sort', 'code')}>Code</th>
                                    <th className="sortable" onClick={() => onParamsChange('sort', 'value')}>value</th>
                                    <th className="sortable" onClick={() => onParamsChange('sort', 'maximum_attempts')}>Max Att</th>
                                    <th className="sortable" onClick={() => onParamsChange('sort', 'maximum_attempts_per_user')}>Max Att Per User</th>
                                    <th className="sortable" onClick={() => onParamsChange('sort', 'applicable_minimum_price')}>Appl Min Price</th>
                                    <th className="sortable" onClick={() => onParamsChange('sort', 'applicable_maximum_price')}>Appl Max Price</th>
                                    <th className="sortable" onClick={() => onParamsChange('sort', 'expire_at')}>Expires</th>
                                    <th className="sortable" onClick={() => onParamsChange('sort', 'is_active')}>Status</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                            {loading ?
                                <tr>
                                    <td colSpan="11" align="center"><ListLoader /></td>
                                </tr>
                                :
                                promotions && isEmpty(promotions.data) ?
                                    <tr>
                                        <td colSpan="11" align="center">No Records Found.</td>
                                    </tr>
                                    :
                                    promotions && !isEmpty(promotions.data) && promotions.data.map((item, index) => (
                                    <tr key={'promotion' + index}>
                                        <td>
                                            <div className="tt-admin-checkbox" >
                                                <CustomInput
                                                    type="checkbox"
                                                    id={'tt-check' + item.promotion_id}
                                                    className="tt-checkbox"
                                                    onChange={(e) => {
                                                        setState({
                                                            ...state, selected_promotions: { ...state.selected_promotions, [item.promotion_id]: e.target.checked }
                                                        });
                                                    }}
                                                    checked={state.selected_promotions[item.promotion_id] ? state.selected_promotions[item.promotion_id] : false}
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="advertising-table-ad-wrap">
                                                <h5
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() =>
                                                        show('AddEditPromotionModal', {
                                                            promotion_id: item.promotion_id,
                                                            callBack: () => {
                                                                getAllPromotion(params);
                                                                hide('AddEditPromotionModal');
                                                            }
                                                        })
                                                    }
                                                >{item.label ? item.label : ''}</h5>
                                                <p>Type: {item.promotion_type ? item.promotion_type.label : '-'}</p>
                                            </div>
                                        </td>
                                        <td>{item.code ? item.code : '-'}</td>
                                        <td>{item.value ? item.value : '-'}</td>
                                        <td>{item.maximum_attempts ? item.maximum_attempts : '-'}</td>
                                        <td>{item.maximum_attempts_per_user ? item.maximum_attempts_per_user : '-'}</td>
                                        <td>{item.applicable_minimum_price ? item.applicable_minimum_price : '-'}</td>
                                        <td>{item.applicable_maximum_price ? item.applicable_maximum_price : '-'}</td>
                                        <td>{item.expire_at ? moment(item.expire_at).format('MM/DD/YYYY') : '-'}</td>
                                        <td>
                                            <div className="ads-switch">
                                                <CustomInput
                                                    type="switch"
                                                    id={`status${index}switch`}
                                                    name="customSwitch"
                                                    checked={promotion_toggle_value[item.promotion_id] ? promotion_toggle_value[item.promotion_id] : false}
                                                    onChange={(e) => toggle(e.target.checked, item.promotion_id)}
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="action-wrap">
                                                <Link
                                                    to={"#"}
                                                    onClick={() =>
                                                        show('AddEditPromotionModal', {
                                                            promotion_id: item.promotion_id,
                                                            callBack: () => {
                                                                getAllPromotion(params);
                                                                hide('AddEditPromotionModal');
                                                            }
                                                        })
                                                    }
                                                >
                                                    <img src="/images/admin/global/edit.svg" alt="" />
                                                </Link>
                                                <Link
                                                    to={"#"}
                                                    onClick={() =>
                                                        show('ConfirmModal', {
                                                            title: 'Delete Promotion',
                                                            body: 'Do you want to delete this Promotion ?',
                                                            button_name: 'Delete',
                                                            action: () => deletePromotion(item.promotion_id),
                                                        })
                                                    }
                                                >
                                                    <img src="/images/admin/global/delete.svg" alt="" />
                                                </Link>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                </div>
                {promotions && promotions.meta &&
                    <PaginationComponent page={promotions.meta} onChange={(page) => onParamsChange('page', page)} />
                }
            </div>
        </div >
    );
}

