import React  from "react"
import "js/components/legos/styles/social-links-module.css"


export default props => {
    const { data } = props;
    const image = {
        Facebook : '/images/web/global/profile/social/fb.svg',
        Twitter : '/images/web/global/profile/social/tw.svg',
        LinkedIn : '/images/web/global/profile/social/in.svg',
        Instagram : '/images/web/global/profile/social/ins.svg',
        Youtube : '/images/web/global/profile/social/yt.svg',
        GoWild : '/images/web/global/profile/social/gw.svg',
        YUDU : '/images/web/global/profile/social/yd.svg',
    };
    return (
        <div className="social-links-outer">
            <h5>Social Links :</h5>
            <div className="social-links-connect">
                { data && data.social_media && data.social_media.map( (item, index) =>(
                    <a href={`https://${item.social_media_link}`} target="_blank" rel="noopener noreferrer" key={index} ><img src={image[item.social_media_label]} alt={item.social_media_label} /></a>
                ))}
            </div>
        </div>
    );
}
