import React, { useState, useEffect } from 'react';
import {
    Row,
    Col,
    Button,
    Form,
    FormGroup,
    Label,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Input,
    Spinner
} from 'reactstrap';

import { omit, isEmpty } from 'lodash';

import { membershipPlanService } from 'js/services';
import { ErrorFormFeedback } from 'js/components/common';
import { ListLoader } from '../list-loader';


export const AddEditCredibilityUpgradeModal = (props) => {
    const { is_open, hide, callBack } = props;
    let [credibility_upgrades, setCredibilityUpgrades] = useState([]);
    const [createErrors, setErrors] = useState({});
    const [loading, setLoading] = useState({
        modal_loading: false,
        button_loading: false,
    });

    const apiCalls = {
        getCredibilityUpgrades: (credibility_upgrade_id) => {
            setLoading({
                ...loading,
                modal_loading: true
            });
            membershipPlanService.getCredibilityUpgrades(credibility_upgrade_id, hide).then((response) => {
                setLoading({
                    ...loading,
                    modal_loading: false
                });
                if (!isEmpty(response)) {
                    setCredibilityUpgrades(response);
                }
            })
                .catch((err) => {
                    setLoading({
                        ...loading,
                        modal_loading: false
                    });
                    let errors = {};
                    if(err){
                        errors = err.data && err.data.errors;
                    }
                    setErrors(errors);
                });
        },
        createCredibilityUpgrades: (details) => {
            setLoading({
                ...loading,
                button_loading: true
            });
            membershipPlanService.createCredibilityUpgrades(details, hide).then((response) => {
                props.createInsideModal && props.createInsideModal(response);
                setLoading({
                    ...loading,
                    button_loading: false
                });
                setErrors({});
                hide();
                callBack();
            })
                .catch((err) => {
                    let errors = err.data && err.data.errors;
                    setErrors(errors);
                    setLoading({
                        ...loading,
                        button_loading: false
                    });
                });
        },
        updateCredibilityUpgrades: (details, credibility_upgrade_id) => {
            setLoading({
                ...loading,
                button_loading: true
            });
            membershipPlanService.updateCredibilityUpgrades(details, credibility_upgrade_id, hide).then(() => {
                hide();
                callBack();
                setLoading({
                    ...loading,
                    button_loading: false
                });
            })
                .catch((err) => {
                    setLoading({
                        ...loading,
                        button_loading: false
                    });
                    let errors = err.data && err.data.errors;
                    setErrors(errors);
                });
        },
    }

    useEffect(() => {
        if (props && props.credibility_upgrade_id) {
            apiCalls.getCredibilityUpgrades(props.credibility_upgrade_id);
        }
        if (props.label) {
            setCredibilityUpgrades({
                ...credibility_upgrades,
                label: props.label
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const submit = () => {
        const data_remover = ['credibility_upgrade_id', 'slug', 'content', 'order']
        let details = { ...omit(credibility_upgrades, data_remover) };

        if (props && props.credibility_upgrade_id) {
            apiCalls.updateCredibilityUpgrades(details, props.credibility_upgrade_id);
        }
        else {
            apiCalls.createCredibilityUpgrades(details);
        }
    }

    return (
        <div>
            <Modal
                isOpen={is_open}
                toggle={() => hide()}
                className="tt-modal--admin modal-dialog-centered"
            >
                <ModalHeader toggle={() => hide()}>{props.title}</ModalHeader>
                <ModalBody>
                    {
                        loading['modal_loading'] ? <ListLoader />
                            :
                            <Form className="tt-admin-form">
                                <div className="tt-admin-form-inner p-0">
                                    <Row form>
                                        <Col xs="12" md="8">
                                            <FormGroup>
                                                <Label for="">Plan Title</Label>
                                                <Input
                                                    type="text"
                                                    name="label"
                                                    onChange={(e) => setCredibilityUpgrades({ ...credibility_upgrades, [e.target.name]: e.target.value })}
                                                    value={credibility_upgrades.label ? credibility_upgrades.label : ''}
                                                    invalid={!isEmpty(createErrors) && (createErrors.label)
                                                        && true
                                                    }
                                                />
                                                <ErrorFormFeedback field={'label'} errors={createErrors} />
                                            </FormGroup>
                                        </Col>
                                        <Col xs="12" md="4">
                                            <FormGroup>
                                                <Label for="">Cost</Label>
                                                <Input
                                                    type="text"
                                                    name="price"
                                                    placeholder='$'
                                                    onChange={(e) => setCredibilityUpgrades({ ...credibility_upgrades, [e.target.name]: e.target.value })}
                                                    value={credibility_upgrades.price ? credibility_upgrades.price : ''}
                                                    invalid={!isEmpty(createErrors) && (createErrors.price)
                                                        && true
                                                    }
                                                />
                                                <ErrorFormFeedback field={'price'} errors={createErrors} />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row form>
                                        <Col xs="12">
                                            <FormGroup>
                                                <Label for="">Description</Label>
                                                <Input
                                                    type="textarea"
                                                    name="description"
                                                    onChange={(e) => setCredibilityUpgrades({ ...credibility_upgrades, [e.target.name]: e.target.value })}
                                                    value={credibility_upgrades.description ? credibility_upgrades.description : ''}
                                                    invalid={!isEmpty(createErrors) && (createErrors.description)
                                                        && true
                                                    }
                                                />
                                                <ErrorFormFeedback field={'description'} errors={createErrors} />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </div>
                            </Form>
                    }
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        className="btn-outline-grey"
                        onClick={() => hide()}
                        disabled={loading['button_loading'] || loading['modal_loading']}
                    >Cancel
                    </Button>
                    <Button
                        color="primary"
                        className="add-new-btn"
                        onClick={() => submit()}
                        disabled={loading['button_loading'] || loading['modal_loading']}
                    >
                        {loading['button_loading'] ?
                            <Spinner size="sm" /> : 'Submit'
                        }
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}
