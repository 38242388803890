import React, { useContext, useState } from "react"
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { BarLoader } from 'react-spinners';
import "js/components/legos/styles/experience-card-module.css"
import { tripsServices } from "js/services";
import { AuthProvider } from 'js/contexts';
import { history } from 'js/helpers';



export const ExperienceCard = (props)=> {
    const { auth } = useContext(AuthProvider);
    const [ loader, setLoader ] = useState(false);

    const onBookmark = (slug, e) => {
        e.preventDefault();
        setLoader(true);
        slug && tripsServices.bookmarkTrip(slug)
        .then(response => {
            setLoader(false);
            props.onBookMarkToggle && props.onBookMarkToggle(response)
        }).catch(err => {
            setLoader(false);
            console.log('failed to bookmark', err)
        })
    }
    const onEdit = (e) => {
        e.preventDefault();
        history.push(props.edit_link)
    }
    return(
        <div className="experience-card">
            <Link to={props.linkName}>
                <div className="experience-card--top-section">
                    <img className="experience-icon" src={props.experienceIcon} width="26" alt="" />
                    {auth.is_logged_in && !props.disable_bookmark &&
                        <>
                            { loader ?
                                <BarLoader  width={"100%"} color={"#FFA405"} loading={true} />
                                :
                                <div className={classNames("bookmark", { "bookmarked" : props.bookmark})} onClick={(e) => onBookmark(props.slug, e)}></div>
                            }
                        </>
                    }
                    {props.enable_edit &&
                    <div className="edit-trip-icon" onClick={(e) => onEdit(e)}>
                        <i>
                            <svg width="18px" height="18px" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                                <path d="m14.313 17.822h-13.483c-0.4582 0-0.82978-0.3716-0.82978-0.8298v-13.483c0-0.4582 0.37158-0.82978 0.82978-0.82978h6.7414c0.4582 0 0.82978 0.37158 0.82978 0.82978s-0.37158 0.82978-0.82978 0.82978h-5.9118v11.824h11.824v-5.9119c0-0.45815 0.3716-0.82974 0.8298-0.82974 0.4581 0 0.8297 0.37159 0.8297 0.82974v6.7415c-1e-4 0.4582-0.3716 0.8298-0.8297 0.8298z" fill="#E59200" />
                                <path d="m17.579 2.2104-1.9374-1.9374c-0.3237-0.3256-0.8505-0.3256-1.1741 0l-1.7341 1.7341c-0.0062 0.0041-0.0125 0.01034-0.0187 0.01871h-0.0021l-6.7725 6.7748c-0.07467 0.07467-0.13491 0.16378-0.17626 0.2593l-1.4519 3.3893c-0.13277 0.3112-0.06219 0.6721 0.17626 0.9127 0.15968 0.1597 0.37123 0.2427 0.58705 0.2427 0.10996 0 0.22188-0.0206 0.32774-0.0663l3.3874-1.45c0.09749-0.0415 0.18463-0.1015 0.25931-0.1762l8.5295-8.5295c0.3234-0.32365 0.3234-0.8485-2e-4 -1.1721zm-9.5896 8.4156-1.3359 0.5704 0.57243-1.3359 5.9284-5.9263 0.7633 0.76331-5.9283 5.9284zm6.0447-7.5734 0.7633 0.76544 1.0204-1.0226-0.7633-0.76331-1.0204 1.0205z" clipRule="evenodd" fill="#E59200" fillRule="evenodd" />
                            </svg>
                        </i>
                    </div>

                    }
                </div>
                <div className="experience-card--bottom-section">
                    <div className="name--location">
                        <h3>{props.experienceName}</h3>
                        <h5><i><img src="/images/web/global/map.svg" alt="" /></i>{props.experienceLocation}</h5>
                    </div>
                    <div className="people-days-promo">
                        <div className="people"><i><img src="/images/web/global/people.svg" alt="" /></i>{props.peopleCount} Ppl</div>
                        <div className="days"><i><img src="/images/web/global/calender.svg" alt="" /></i>{props.days} Days</div>
                        <div className="links">
                            <div className={classNames({"promoted" : props.promoted })} >{props.promoted}</div>
                        </div>
                    </div>
                </div>
                <img className="experience-card--img" src={props.experienceCardImage} alt="" />
            </Link>
        </div>
    )
}
