import React from "react"
import Slider from "react-slick";

import "js/components/legos/styles/experience-slider-module.css"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {ExperienceCard} from "../legos/experienceCard";
import { isEmpty } from 'lodash';
import { AdCard } from 'js/components/common';
import { utilFunctions } from 'js/components/common/fucntions';

export default ( props ) => {
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        centerMode: false,
        arrows: true,
        className: 'experience-slider',
        responsive: [
            {
                breakpoint: 1199.98,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 991.98,
                settings: {
                    slidesToShow: 1
                }
            },
            {
                breakpoint: 575.98,
                settings: {
                    slidesToShow: 1,
                    arrows: false
                }
            }
        ]
    };
    const onBookmarkSuccess = ( updated_trip) => {
        let updated_trips = props.trips.map(item => {
            if(item.trip_id === updated_trip.trip_id) {
                return updated_trip
            }
            else return item
        })
        console.log( updated_trips )
        props.onUpdate && props.onUpdate(updated_trips, updated_trip )
    }
    return   (
        <div>
        { props.trips && !isEmpty(props.trips) &&
            <Slider {...settings}>
                { props.trips && props.trips.map( ( trip, index ) => {
                    trip.trip_category = utilFunctions.findCategory(trip.trip_categories)
                    return (
                        <div key={ index }>
                            { trip.type && trip.type === 'ad' ?
                                <AdCard
                                    linkName={ `${ trip.external_link }` }
                                    adImage={ trip.attachment ? trip.attachment.thumbnail_url ?
                                        trip.attachment.thumbnail_url : trip.attachment.attachment_url
                                        : '/images/web/dummy/experience-card/1.png'
                                    }
                                    adName={ trip.title }
                                    advertisement_id={ trip.advertisement_id }
                                />
                                :
                                <ExperienceCard
                                    linkName={ `/trips/details/${ trip.slug }` }
                                    experienceCardImage={ trip?.trip_media[ 0 ]?.attachment
                                        ? trip.trip_media[ 0 ].attachment.thumbnail_url
                                            ?? trip.trip_media[ 0 ].attachment.attachment_url
                                        : trip?.parent_trip_category?.icon_image_url
                                            ?? trip.trip_category?.featured_image_url
                                            ?? '/images/web/dummy/experience-card/1.png'
                                    }
                                    experienceIcon={ trip?.parent_trip_category?.icon_image_url ?? trip?.trip_category?.icon_image_url }
                                    experienceName={ trip.title }
                                    experienceLocation={ `${ trip.city ? trip.city.long_name : '' } ${ trip.state && trip.state.long_name }, ${ trip.country && trip.country.short_name }` }
                                    peopleCount={ `${ trip.accomodating_min_seats } - ${ trip.accomodating_max_seats }` }
                                    days={ `${ trip.duration_min_days } - ${ trip.duration_max_days }` }
                                    bookmark={ trip.is_bookmarked }
                                    onBookMarkToggle={ ( data ) => onBookmarkSuccess( data ) }
                                    disable_bookmark={ props.enable_bookmark ? false : true }
                                    trip_id={ trip.trip_id }
                                    slug={ trip.slug }
                                />
                            }
                        </div>
                    );
                })}
            </Slider>
        }
        </div>
    );
}
