import React from 'react';
import { BarLoader } from "react-spinners";
export const ListLoader = () => {
    return (
        <div className="graph-wrap">
            <div className="sweet-loading">
                <BarLoader

                    size={50}
                    color={"#FFA405"}
                    loading={true}
                />
            </div>
        </div>
    )
}
