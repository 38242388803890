import React, { useState, useEffect } from 'react';
import {
    Row,
    Col,
    Button,
    Form,
    FormGroup,
    CustomInput,
    Label,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Input,
    Table,
    Spinner
} from 'reactstrap';

import { debounce, omit, isEmpty} from 'lodash';

import { partnerAccountServices } from 'js/services';
import { ErrorFormFeedback, FileUploadComponent } from 'js/components/common';


export const AddEditPartnerAccountModal = (props) => {
    const { is_open, hide, callBack } = props;

    const file_types = {
        image: ['image/jpeg', 'image/png'],
    }

    const [partner, setPartner] = useState({});
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [fileUploadLoading, setFileUploadLoading] = useState(false);


    const apiCalls = {
        get: (params) => {
            partnerAccountServices.get(params).then((response) => {
                setPartner( response );
            }).catch((err) => {
                setErrors(err);
            });
        },
        create: debounce((details) => {
            setLoading(true);
            partnerAccountServices.create(details).then(() => {
                callBack && callBack();
                hide();
            })
            .catch((err) => {
                err.data && err.data.errors && setErrors(err.data && err.data.errors);
                setLoading(false);
            });
        }, 300),
        update: debounce((details, partner_id) => {
            setLoading(true)
            partnerAccountServices.update(details, partner_id).then(() => {
                callBack && callBack();
                hide();
            })
            .catch((err) => {
                err.data && err.data.errors && setErrors(err.data && err.data.errors);
                setLoading(false);
            });
        }, 300),
    }

    useEffect(() => {
        if (props && props.partner_id) {
            apiCalls.get(props.partner_id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const submit = () => {
        let data = {
            ...omit(partner,['attachment','partner_id']),
            attachments: partner.attachment ? [ partner.attachment.attachment_id ] : []
        }
        if (props && props.partner_id) {
            apiCalls.update(data,props.partner_id);
        }
        else{
            apiCalls.create(data,props.partner_id);
        }
    }

    const handleFile = (response) => {
        setPartner({
            ...partner,
            attachment: response
        });
    }

    const removeFile = (index) => {
        setPartner({
            ...partner,
            attachment: '',
        });
    }
    return (
        <div>
            <Modal
                isOpen={is_open}
                toggle={() => hide()}
                className="tt-modal--admin modal-dialog-centered"
            >
                <ModalHeader toggle={() => hide()}>{!props.partner_id ? 'Add New' : 'Edit'} Account</ModalHeader>
                <ModalBody>
                    <Form className="tt-admin-form">
                        <div className="tt-admin-form-inner p-0">
                            <Row form>
                                <Col xs="12" md="6">
                                    <FormGroup>
                                        <Label for="">Organization Name</Label>
                                        <Input
                                            type="text"
                                            name="company_name"
                                            onChange={(e) => setPartner({ ...partner, [e.target.name]: e.target.value })}
                                            value={partner.company_name ? partner.company_name : ''}
                                            invalid={ !isEmpty(errors) && (errors.company_name) && true }
                                        />
                                        <ErrorFormFeedback field={'company_name'} errors={errors} />
                                    </FormGroup>
                                </Col>
                                <Col xs="12" md="6">
                                    <FormGroup>
                                        <Label for="">Email Address</Label>
                                        <Input
                                            type="text"
                                            name="email"
                                            onChange={(e) => setPartner({ ...partner, [e.target.name]: e.target.value })}
                                            value={partner.email ? partner.email : ''}
                                            invalid={ !isEmpty(errors) && (errors.email) && true }
                                        />
                                        <ErrorFormFeedback field={'email'} errors={errors} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row form>
                                <Col xs="12" md="6">
                                    <FormGroup>
                                        <Label for="">Account Owner First Name</Label>
                                        <Input
                                            type="text"
                                            name="first_name"
                                            onChange={(e) => setPartner({ ...partner, [e.target.name]: e.target.value })}
                                            value={partner.first_name ? partner.first_name : ''}
                                            invalid={ !isEmpty(errors) && (errors.first_name) && true }
                                        />
                                        <ErrorFormFeedback field={'first_name'} errors={errors} />
                                    </FormGroup>
                                </Col>
                                <Col xs="12" md="6">
                                    <FormGroup>
                                        <Label for="">Account Owner Last Name</Label>
                                        <Input
                                            type="text"
                                            name="last_name"
                                            onChange={(e) => setPartner({ ...partner, [e.target.name]: e.target.value })}
                                            value={partner.last_name ? partner.last_name : ''}
                                            invalid={ !isEmpty(errors) && (errors.last_name) && true }
                                        />
                                        <ErrorFormFeedback field={'last_name'} errors={errors} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row form>
                                <Col xs="12">
                                    <FormGroup>
                                        <Label for="">API Key (Optional)</Label>
                                        <Input
                                            type="text"
                                            name="api_key"
                                            onChange={(e) => setPartner({ ...partner, [e.target.name]: e.target.value })}
                                            value={partner.api_key ? partner.api_key : ''}
                                            invalid={ !isEmpty(errors) && (errors.api_key) && true }
                                        />
                                        <ErrorFormFeedback field={'api_key'} errors={errors} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="12">
                                    <h4 className="in-form-heading my-3"><span>Branding</span></h4>
                                </Col>
                            </Row>


                            { partner.attachment ?
                                <FormGroup>
                                    <Label for="">Partner Logo</Label>
                                    <Table responsive >
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <p className="resources-upload--name">
                                                        <img
                                                            src={partner.attachment ? partner.attachment.thumbnail_url ?
                                                                partner.attachment.thumbnail_url:partner.attachment.attachment_url : ''
                                                            }
                                                            alt={partner.attachment.filename}
                                                        />
                                                        {partner.attachment.filename}
                                                    </p>
                                                </td>
                                                <td>
                                                    <i
                                                        className="resources-upload--delete-icon"
                                                        onClick={() => removeFile()}
                                                    >
                                                        <img
                                                        src="/images/admin/global/close.svg"
                                                        alt="Delete"
                                                    />
                                                    </i>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                    <div className="invalid-feedback d-block">{errors.attachments}</div>
                                </FormGroup>
                            :
                                <Row form>
                                    <Col xs="12">
                                        <FormGroup>
                                            <Label for="">Upload Logo</Label>
                                            <div>
                                                <FileUploadComponent
                                                    entity_type='Partner'
                                                    accepted={file_types['image']}
                                                    onSuccess={handleFile}
                                                    onError={(err) => {
                                                        let errors = err.data && err.data.errors;
                                                        setErrors(errors);
                                                    }}
                                                    fileUploadLoading={(loading) => setFileUploadLoading(loading)}
                                                />

                                            </div>

                                            <div className="invalid-feedback d-block">{errors.attachments}</div>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            }
                            <Row form>
                                <Col xs="12" md="6">
                                    <FormGroup>
                                        <Label for="">Sub Domain</Label>
                                        <Input
                                            type="text"
                                            name="sub_domain"
                                            onChange={(e) => setPartner({ ...partner, [e.target.name]: e.target.value })}
                                            value={partner.sub_domain ? partner.sub_domain : ''}
                                            invalid={ !isEmpty(errors) && (errors.sub_domain) && true }
                                        />
                                        <ErrorFormFeedback field={'sub_domain'} errors={errors} />
                                    </FormGroup>
                                </Col>
                                <Col xs="12" md="6" className="d-flex align-items-center">
                                    <p className="m-0">.trips4trade.com</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="12">
                                    <h4 className="in-form-heading my-3"><span>Billing</span></h4>
                                </Col>
                            </Row>
                            <Row form>
                                <Col xs="12" md="6">
                                    <div>
                                        <Label for="">Billable?</Label>
                                    </div>
                                    <FormGroup check inline>
                                        <CustomInput
                                            type="radio"
                                            id="initialState1"
                                            name="is_billable"
                                            label="Yes"
                                            value={1}
                                            className="mr-3"
                                            onChange={ (e) => { setPartner({ ...partner, 'is_billable': Number(e.target.value) }); }}
                                            checked={partner.is_billable === 1}
                                        />
                                        <CustomInput
                                            type="radio"
                                            id="initialState2"
                                            name="is_billable"
                                            value={0}
                                            label="No"
                                            onChange={ (e) => { setPartner({ ...partner, 'is_billable': Number(e.target.value) }); }}
                                            checked={partner.is_billable === 0}
                                        />
                                    </FormGroup>
                                    <div className="invalid-feedback d-block">{errors.is_billable}</div>
                                </Col>
                                <Col xs="12" md="6">
                                    <FormGroup>
                                        <Label for="">Monthly Payment Amount</Label>
                                        <Input
                                            type="text"
                                            name="monthly_fee"
                                            onChange={(e) => setPartner({ ...partner, [e.target.name]: e.target.value })}
                                            value={partner.monthly_fee ? partner.monthly_fee : ''}
                                            invalid={ !isEmpty(errors) && (errors.monthly_fee) && true }
                                        />
                                        <ErrorFormFeedback field={'monthly_fee'} errors={errors} />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </div>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        className="btn-outline-grey"
                        onClick={() => hide()}
                        disabled={loading || fileUploadLoading}
                    >Cancel
                    </Button>
                    <Button
                        color="primary"
                        className="add-new-btn"
                        onClick={() => submit()}
                        disabled={loading || fileUploadLoading}
                    >
                    {loading ?
                        <Spinner size="sm"/> : props.partner_id ? 'Submit' : 'Add Account'
                    }
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}
