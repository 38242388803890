import React from "react";
import { Row,
    Col,
    Button,
    Form,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Spinner
} from 'reactstrap';
import { tripsServices } from 'js/services';
import { ErrorFormFeedback } from 'js/components/common';
import { useState } from "react";

const rated_star = '/images/web/global/star.svg';
const unrated_star = '/images/web/global/star-grey.svg';

export const AddReviewModal = ( props ) => {
    const { is_open, hide, trip_trade_id, callBack } = props;
    const [ loading, setLoading ] = useState(false);
    const [ state, setState ] = useState({
        description: '',
        rating: 0
    });
    const [ errors, setErrors ] = useState({})

    const submitReview = () => {
        setLoading(true)
        tripsServices.addReviews(trip_trade_id, {...state}, hide)
        .then(res => {
            callBack();
            setLoading(false)
        }).catch(err => {
            setLoading(false);
            err&& err.data && err.data.errors && setErrors(err.data.errors)
        })
    }
    let star_rate = Array.from(Array(5).map((index) => (index)));
    star_rate = star_rate.map((item, index) => {
        let data = state.rating <= index ? unrated_star : rated_star;
        return data
    })
    return (
        <div>
            <Modal
                isOpen={is_open}
                toggle={() => hide()}
                className="tt-modal--admin modal-dialog-centered"
            >
                <ModalHeader toggle={() => hide()}>Leave a Review</ModalHeader>
                <ModalBody>
                    <Form className="tt-admin-form">
                        <div className="tt-admin-form-inner p-0">
                            <Row form>
                                <Col xs="12">
                                    <FormGroup>
                                        <Label for="">Review</Label>
                                        <Input
                                            type="textarea"
                                            name="page_to_link"
                                            id="page_to_link"
                                            rows="4"
                                            cols="50"
                                            onChange={(e) => setState({...state, description: e.target.value})}
                                            invalid={errors.description && true}
                                        />
                                        <ErrorFormFeedback field={'description'} errors={errors} />
                                    </FormGroup>
                                </Col>
                                <Col xs="12">
                                    <FormGroup>
                                        <Label for="">Rating</Label>
                                            <div className="star-rating--left">
                                                {star_rate.map((item, index) => (
                                                    <img src={item} alt="" onClick={() => setState({...state, rating: index+1})} key={index}/>
                                                ))}
                                                <span>{`  ${state.rating.toFixed(1)}`}</span>
                                            </div>
                                        <ErrorFormFeedback field={'rating'} errors={errors} is_invalid={true}/>
                                        <ErrorFormFeedback field={'general'} errors={errors} is_invalid={true}/>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </div>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        className="btn-outline-grey"
                        onClick={() => hide()}
                        disabled={loading}
                    >
                        Cancel
                    </Button>
                    <Button
                        color="primary"
                        className="add-new-btn"
                        onClick={submitReview}
                        disabled={loading}
                    >
                        { loading ? <Spinner color="#ffa405" size="sm" /> : 'Save' }
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}
