import React, { useEffect, useState, useContext } from "react";
import { portalRoutes } from "js/routes/Admin/portal";
import { Route, Switch, Redirect } from "react-router-dom";

import { Header } from "js/components/admin/components/layouts/components/header";
import { Sidebar } from "js/components/admin/components/layouts/components/sidebar";
import { Footer } from "js/components/admin/components/layouts/components/footer";
import { history } from 'js/helpers';
import { authServices } from 'js/services/auth-service';
import { configConstants } from 'js/constants';
import { AuthProvider } from 'js/contexts';
import { cloneDeep } from 'lodash';



export const PortalLayout = () => {
    const [ loaded, setLoaded ] = useState(false);
    const { auth, setAuth } = useContext(AuthProvider);
    const switchRoutes = (
        <Switch>
            {portalRoutes.map((prop, key) => {
                return prop.needsAuth ?
                    <Redirect  key={key} from={prop.path} to="/auth/login" />
                :
                    <Route path={prop.path} component={prop.component} key={key} />
            })}
        </Switch>
    );
    useEffect(() => {
        const token = localStorage.getItem(configConstants.TOKEN_NAME);
        const partner_token = localStorage.getItem(configConstants.PARTNER_TOKEN);
        setAuth({ ...auth, previous_location: history.location.pathname});
        if(token && partner_token){
            authServices.getProfile().then((response) => {
                setAuth({ ...auth, user: cloneDeep(response), is_logged_in: true, user_role: response.user_role });
                setLoaded(true);
                if(response.user_role === 'customer'){
                    history.push('/');
                }
            });

        }
        else {
            history.push('/auth/login');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <>
            {
                (auth.user_role === 'super-admin' || auth.user_role === 'admin') && loaded &&
                    <div className="base-container">
                        <Sidebar />
                        <div className="global-content">
                            <Header />
                            <div className="global-datawrap">
                                <div className="global-datawrap__inner">
                                    {switchRoutes}
                                </div>
                            </div>
                            <Footer />
                        </div>
                    </div>
            }
        </>
    );
}
