import React, { useState, useEffect } from 'react';
import { Row, Col, Form, FormGroup, CustomInput, Table, Spinner } from 'reactstrap';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { isEmpty, forEach, omit } from 'lodash';


import { membershipPlanService, authServices } from 'js/services';
import { SignupPayment } from 'js/components/common';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';



export const PaymentInformation = (props) => {
    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

    const [membership_plans, setMembershipPlan] = useState([]);
    const [payment_information, setPaymentInformation] = useState({
        membership_plan_id: '',
        membership_plan_upgrade_id: [],
        credibility_upgrade_id: [],
    });
    const [createErrors, setErrors] = useState({});
//    const [selected_upgrades, setSelectedUpgrades] = useState({});
    const [selected_products, setSelectedProducts] = useState([]);
    const [total_price, setTotalPrice] = useState(0);
    const [ credibilty_upgrades, setCredibiltyUpgrades ] = useState([]);
    const [ membership_upgrades, setMembershipUpgrades ] = useState([]);
    const [loading, setLoading] = useState(false);
    const [ upgrade_checked_value, setUpgradeCheckedValue ] = useState({
        membership_plan_upgrade_id: {},
        credibility_upgrade_id: {},
    });
    const [ discount_value, setDiscount ] = useState('0.00');

    const apiCalls = {
        getAllMemberShipPlan: () => {
            membershipPlanService.getAll().then((response) => {
                let upgrade_hash = {};

                defineDataStructre(response).then((data_structre) => {
                    upgrade_hash = { ...data_structre };

                    forEach(response, (item) => {
                        upgrade_hash[item.membership_plan_id]['membership_plan_upgrades'] = item.membership_plan_upgrades;
                        upgrade_hash[item.membership_plan_id]['credibility_upgrades'] = item.credibility_upgrades;
                    });

                //    setSelectedUpgrades(upgrade_hash);
                });

                setMembershipPlan([...response]);

            }).catch((err) => {
                setErrors(err);
            });
        },
        calculatePlan: (details, from) => {
            if (from === 'promo_code_input') {
                setLoading(true);
            }
            authServices.calculatePlan(details).then((response) => {
                if (from === 'promo_code_input') {
                    setLoading(false);
                }
                if (!isEmpty(response)) {
                    let total_price = response.total_price ? response.total_price : '';
                    let total_price_before_promotion = response.total_price_before_promotion ?
                        response.total_price_before_promotion : '';
                    let discounted_price = total_price_before_promotion - total_price;

                    setTotalPrice(total_price.toFixed(2));
                    setDiscount(discounted_price.toFixed(2));
                }
                setErrors({});
            }).catch((err) => {
                if (from === 'promo_code_input') {
                    setLoading(false);
                }
                setPaymentInformation({
                    ...payment_information,
                    promo_code: ''
                })
                let errors = err && err.data && err.data.errors;
                setErrors(errors);
            });
        }
    }

    const defineDataStructre = (data) => {
        let upgrade_hash = {};
        return new Promise((resolve) => {
            forEach(data, (item) => {
                upgrade_hash[item.membership_plan_id] = {};
            });

            resolve(upgrade_hash);
        })
    }

    useEffect(() => {
        apiCalls.getAllMemberShipPlan();
        window.scrollTo(0, 0)
        if (props.referral_token) {
            setPaymentInformation((payment_information) => {
                let selected_payment_information = {
                    ...payment_information,
                    promo_code: props.referral_token
                }
                findTotalAmount(selected_payment_information, 'promo_code_input');

                return selected_payment_information;
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const customCheckboxChange = (key, value, selected_data) => {
        let selected_array = payment_information[key];
        let selected_products_array = selected_products;

        if (value) {
            let selected_id = selected_data[key];

            selected_array.push(selected_data[key]);
            selected_products_array.push(selected_data);

            setUpgradeCheckedValue((upgrade_checked_value) => ({
                ...upgrade_checked_value,
                [key]: {
                    ...upgrade_checked_value[key],
                    [`${payment_information.membership_plan_id}_${selected_id}`]: value
                }
            }))
        }
        else {
            let selected_id = selected_data[key];

            selected_array = selected_array.filter((item) => item !== selected_data[key]);
            selected_products_array = selected_products_array.filter((item) => item[key] !== selected_data[key]);
            setUpgradeCheckedValue((upgrade_checked_value) => ({
                ...upgrade_checked_value,
                [key]: {
                    ...upgrade_checked_value[key],
                    [`${payment_information.membership_plan_id}_${selected_id}`]: value
                }
            }))
        }

        setPaymentInformation((payment_information) => {
            let selected_payment_information = {
                ...payment_information,
                [key]: selected_array
            }
            findTotalAmount(selected_payment_information);

            return selected_payment_information;
        });

        setSelectedProducts(selected_products_array);

    }

    const findTotalAmount = (selected_payment_information, from) => {
        let details = { ...selected_payment_information };
        details.credibility_upgrade_ids = details.credibility_upgrade_id;
        details.membership_plan_upgrade_ids = details.membership_plan_upgrade_id;

        apiCalls.calculatePlan({ ...omit(details, 'membership_plan_upgrade_id', 'credibility_upgrade_id') }, from);
    }
    const onMembershipSelect = (membership) => {
        if(membership.membership_plan_id !== payment_information.membership_plan_id) {
            let payment_info =  {
                membership_plan_id: membership.membership_plan_id,
                membership_plan_upgrade_id: [],
                credibility_upgrade_id: []
            }
            setPaymentInformation({...payment_info});
            setSelectedProducts([membership]);
            setCredibiltyUpgrades(membership.credibility_upgrades);
            setMembershipUpgrades(membership.membership_plan_upgrades)
            findTotalAmount(payment_info);
        }

    }
    return (
        <Row>
            <Col xs="12">
                <div className="signup-tab-data-wrap">
                    <Row>
                        <Col xs="12">
                            <h3 className="header5">Payment Information</h3>
                        </Col>
                    </Row>
                    {
                        !isEmpty(membership_plans) && membership_plans.map((membership_plan, index) => (
                            <Row key={`membership_plans_${index}`}>
                                <Col xs="12" className="mt-5">
                                    <div
                                        className={classnames({
                                            "annual-membership-unchecked" : payment_information['membership_plan_id']  !== membership_plan.membership_plan_id,
                                            "annual-membership":payment_information['membership_plan_id']  === membership_plan.membership_plan_id
                                        })}
                                        onClick={() => onMembershipSelect(membership_plan)}
                                    >
                                        <h5 className="header5 text-capitalize">
                                            {payment_information && payment_information['membership_plan_id']  === membership_plan.membership_plan_id?
                                                <img src="/images/web/auth/tick.svg" alt="" />
                                            :
                                                <img src="/images/web/auth/unchecked.svg" alt="" />

                                            }
                                            {
                                                membership_plan.label ? ` ${membership_plan.label}` : ''
                                            }
                                        </h5>
                                        {/* <CustomInput
                                            type="radio"
                                            id={membership_plan.slug}
                                            name="membershipPlanRadio"
                                            label={membership_plan.label ? membership_plan.label : ''}
                                            value={membership_plan.membership_plan_id ? membership_plan.membership_plan_id : ''}
                                            onChange={(e) => {
                                                setPaymentInformation({
                                                    ...payment_information,
                                                    membership_plan_id: e.target.value,
                                                    promo_code: '',
                                                    credibility_upgrade_id: [],
                                                    membership_plan_upgrade_id: [],
                                                });

                                                setSelectedProducts([membership_plan]);
                                                findTotalAmount({
                                                    ...payment_information,
                                                    membership_plan_id: e.target.value,
                                                    credibility_upgrade_id: [],
                                                    membership_plan_upgrade_id: [],
                                                    promo_code: ''
                                                }, 'membership_plan');
                                                setUpgradeCheckedValue({
                                                    membership_plan_upgrade_id: {},
                                                    credibility_upgrade_id: {},
                                                })
                                            }
                                            }
                                        /> */}
                                        <ul className="trade-resources">
                                            {
                                                membership_plan.content.map((item, ind) => (
                                                    <li key={`content_${ind}`}>
                                                        {item}
                                                    </li>
                                                ))
                                            }
                                        </ul>
                                    </div>
                                </Col>
                            </Row>
                        ))
                    }
                    {!isEmpty(createErrors) && createErrors.membership_plan_id && (
                        <div>
                            <div className="invalid-feedback d-block pt-3">{createErrors.membership_plan_id}</div>
                        </div>
                    )}
                    <Row>
                        <Col xs="12">
                            <Form className="tt-membership-form">
                                <Row>
                                    <Col className="credibility-sub-head">
                                        <h4 className="header4">Membership Upgrades <span>(Optional)</span></h4>
                                    </Col>
                                </Row>
                                {
                                    !isEmpty(membership_upgrades) && membership_upgrades.map((membership_plan_upgrade, index) => (
                                        <Row key={`membership_plan_upgrades_${index}`}>
                                            <Col>
                                                <div className="membership-outer">
                                                    <FormGroup>
                                                        <Row>
                                                            <Col>
                                                                <CustomInput
                                                                    type="checkbox"
                                                                    id={`membership_plan_upgrades${membership_plan_upgrade.membership_plan_upgrade_id}${
                                                                        membership_plan_upgrade.label
                                                                        }`}
                                                                    label={membership_plan_upgrade.label ? membership_plan_upgrade.label : ''}
                                                                    onChange={(e) => customCheckboxChange('membership_plan_upgrade_id', e.target.checked, membership_plan_upgrade)}
                                                                    checked={upgrade_checked_value['membership_plan_upgrade_id'][`${payment_information.membership_plan_id}_${membership_plan_upgrade.membership_plan_upgrade_id}`] || false}
                                                                />
                                                                <p className="small-text">{membership_plan_upgrade.description ? membership_plan_upgrade.description : ''}</p>
                                                            </Col>
                                                            <Col className="d-flex justify-content-end align-items-center">
                                                                <div className="membership-amount">+ ${membership_plan_upgrade.price ? membership_plan_upgrade.price : ''} </div>
                                                            </Col>
                                                        </Row>
                                                    </FormGroup>
                                                </div>
                                            </Col>
                                        </Row>
                                    ))
                                }
                                {!isEmpty(createErrors) && createErrors.membership_plan_upgrade_ids && (
                                    <div>
                                        <div className="invalid-feedback d-block pt-3">{createErrors.membership_plan_upgrade_ids}</div>
                                    </div>
                                )}
                                <Row>
                                    <Col className="credibility-sub-head">
                                        <h4 className="header4">Credibility Upgrades <span>(Optional)</span></h4>
                                    </Col>
                                </Row>
                                {
                                    !isEmpty(credibilty_upgrades) && credibilty_upgrades.map((credibility_upgrade, index) => (
                                        <Row key={`membership_plan_upgrades_${index}`}>
                                            <Col>
                                                <div className="membership-outer">
                                                    <FormGroup>
                                                        <Row>
                                                            <Col>
                                                                <CustomInput
                                                                    type="checkbox"
                                                                    id={`credibility_upgrade${credibility_upgrade.credibility_upgrade_id}${credibility_upgrade.label}`}
                                                                    label={credibility_upgrade.label ? credibility_upgrade.label : ''}
                                                                    onChange={(e) => {
                                                                        customCheckboxChange('credibility_upgrade_id', e.target.checked, credibility_upgrade);
                                                                    }
                                                                    }
                                                                    checked={upgrade_checked_value['credibility_upgrade_id'][`${payment_information.membership_plan_id}_${credibility_upgrade.credibility_upgrade_id}`] || false}
                                                                />
                                                                <p className="small-text">
                                                                    {credibility_upgrade.description ? credibility_upgrade.description : ''}
                                                                </p>
                                                            </Col>
                                                            <Col className="d-flex justify-content-end align-items-center">
                                                                <div className="membership-amount">
                                                                    + ${credibility_upgrade.price ? credibility_upgrade.price : ''}
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </FormGroup>
                                                </div>
                                            </Col>
                                        </Row>
                                    ))
                                }
                                {!isEmpty(createErrors) && createErrors.credibility_upgrade_ids && (
                                    <div>
                                        <div className="invalid-feedback d-block pt-3">{createErrors.credibility_upgrade_ids}</div>
                                    </div>
                                )}
                                <Row>
                                    <Col xs="12">
                                        <div className="product-table-wrap">
                                            <Table responsive>
                                                <thead>
                                                    <tr>
                                                        <th>Product</th>
                                                        <th>Total</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        !isEmpty(selected_products) && selected_products.map((selected_product, index) => (
                                                            <>
                                                                {
                                                                    selected_product.label !== 'Discount' &&
                                                                    <tr key={'selected_product' + index}>
                                                                        <td>{selected_product.label ? selected_product.label : ''}</td>
                                                                        <td>{selected_product.price ? selected_product.price : ''}</td>
                                                                    </tr>
                                                                }
                                                            </>
                                                        ))
                                                    }
                                                    {
                                                        discount_value !== '0.00' &&
                                                            <>
                                                                {
                                                                    <tr>
                                                                        <td>Discount</td>
                                                                        <td>{discount_value}</td>
                                                                    </tr>
                                                                }
                                                            </>
                                                    }
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <td>Total</td>
                                                        <td>${total_price}</td>
                                                    </tr>
                                                </tfoot>
                                            </Table>
                                            <div className="promocode-wrap">
                                                <Row>
                                                    <Col className="d-flex justify-content-between">
                                                        <input
                                                            type="text"
                                                            placeholder="Promo Code"
                                                            value={payment_information.promo_code || ''}
                                                            onChange={(e) =>
                                                                setPaymentInformation({
                                                                    ...payment_information,
                                                                    promo_code: e.target.value
                                                                })
                                                            }
                                                            readOnly={props.referral_token ? true : false}
                                                        />
                                                        <Link
                                                            to={"#"}
                                                            className="btn-outline--grey"
                                                            onClick={() => findTotalAmount(payment_information, 'promo_code_input')}
                                                        >
                                                            {
                                                                loading ? <Spinner size="sm" /> : 'Apply'
                                                            }
                                                        </Link>
                                                    </Col>
                                                </Row>
                                            </div>
                                            {!isEmpty(createErrors) && createErrors.promotion && (
                                                <div>
                                                    <div className="invalid-feedback d-block mt-3">{createErrors.promotion}</div>
                                                </div>
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="12">
                                        <div className="tt-guarantee-wrap">
                                            <div className="guarantee-icon">
                                                <img src="/images/web/auth/guarantee.svg" alt="" width="45px" />
                                            </div>
                                            <div className="guarantee-text">
                                                <h4>TRIPS4TRADE GUARANTEE:</h4>
                                                <p>By taking one trip through Trips4Trade, you will save substantially more than the cost of a membership.​</p>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="credibility-sub-head">
                                        <h4 className="header4">PAYMENT INFORMATION</h4>
                                    </Col>
                                </Row>
                                <Elements stripe={stripePromise}>
                                    <SignupPayment
                                        payment_information={payment_information}
                                        callBackErrors={(errors) => setErrors(errors)}
                                        parentCallBack={(tab_name) => props.callBack(tab_name)}
                                        parentCallBackData={(response) => props.callBackData(response)}
                                    />
                                </Elements>
                            </Form>
                        </Col>
                    </Row>
                </div >
            </Col >
        </Row >

    )
}
