import React, { useState, useEffect, useContext } from 'react';
import {
    Row,
    Col,
    Button,
    Form,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Spinner,
    FormGroup
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { isEmpty, find, forEach } from 'lodash';
import { toast } from "react-toastify";
import Select from 'react-select';
import classnames from 'classnames';



import { tradeServices, supportEmailServices } from 'js/services';
import { ListLoader } from 'js/components/common';
import moment from 'moment';
import { LookupContext } from 'js/contexts';
import { useModal } from 'js/hooks';



const styles = {
    option: (styles, state) => ({
        ...styles,
        backgroundColor: state.isSelected ? "#FFA405 " : state.isFocused ? "#FFA40520" : ""
    })
};
const customSingleValue = ({ data }) => (
    <div className="input-select">
        <div className="input-select__single-value-selected">
            {data.icon && <span className="input-select__icon mr-2">{data.icon}</span>}
            <span>{data.label}</span>
        </div>
    </div>
);
const CustomOption = ({ data, innerProps, isDisabled }) => !isDisabled ? (
    <div {...innerProps}>
        <div className="input-select__single-value">
            {data.icon && <span className="input-select__icon mr-2">{data.icon}</span>}
            <span>{data.label}</span>
        </div>
    </div>
) : null;

export const ExchangeDetailsModal = (props) => {
    const { is_open, hide } = props;
    let [trade, setTrade] = useState();
    // eslint-disable-next-line
    const [createErrors, setErrors] = useState({});
    const [icon_lookups, setIconLookups] = useState([]);
    const [loading, setLoading] = useState({
        trade_loading: false,
        update_status: false
    });
    const [state, setState] = useState({});
    const [support_emails, setSupportEmails] = useState({});
    const [show] = useModal();
    const lookups = useContext(LookupContext);


    const image_name = {
        'in-progress': 'warning.svg',
        completed: 'success.svg',
        canceled: 'error.svg',
        tbd: 'pending.svg',
        interested: 'interested.svg',
        'not-interested': 'not-interested.svg'
    }

    const apiCalls = {
        getTrade: (trade_id) => {
            setLoading((loading) => ({
                ...loading,
                trade_loading: true
            }));
            tradeServices.get(trade_id, hide).then((response) => {
                setLoading((loading) => ({
                    ...loading,
                    trade_loading: false
                }));

                if (!isEmpty(response)) {
                    setTrade(response);
                }

                let status = response.trade_status && response.trade_status.slug && find(lookups.trade_status, ['value', response.trade_status.slug]);
                status.icon = <img src={`/images/admin/global/status/${image_name[status.slug]}`} width="16px" alt="" />
                setState({
                    ...state,
                    status
                });
            })
                .catch((err) => {
                    setLoading((loading) => ({
                        ...loading,
                        trade_loading: false
                    }));
                    let errors = err.data && err.data.errors;
                    setErrors(errors);
                });
        },
        updateStatus: (details) => {
            setLoading((loading) => ({
                ...loading,
                update_status: true
            }));
            tradeServices.updateStatus(props.trade_id, details, hide).then(() => {
                setLoading((loading) => ({
                    ...loading,
                    update_status: false
                }));
                toast.success('Status Updated Successfully');
                hide();
                props.callBack();
            }).catch((err) => {
                let errors = err.data && err.data.errors;
                setLoading((loading) => ({
                    ...loading,
                    update_status: false
                }));
                setErrors(errors);
            });
        },
        getAllSupportEmails: () => {
            supportEmailServices.getAll({ notifiable_id: props.trade_id, notifiable_type: 'trade' }, hide)
                .then((response) => {
                    setSupportEmails(response);
                })
                .catch((err) => {
                    let errors = err.data && err.data.errors;
                    setErrors(errors);
                });
        },
    }

    useEffect(() => {
        let icon_lookups = [];
        if (props && props.trade_id) {
            apiCalls.getTrade(props.trade_id);
            apiCalls.getAllSupportEmails();
        }
        if (!isEmpty(lookups.trade_status)) {
            forEach(lookups.trade_status, (status) => {
                icon_lookups.push({
                    ...status,
                    icon: <img src={`/images/admin/global/status/${image_name[status.slug]}`} width="16px" alt="" />
                })
            });
            setIconLookups(icon_lookups);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const submit = () => {
        let details = { ...state };
        details.status = !isEmpty(details.status) ? details.status.value : '';
        apiCalls.updateStatus(details);
    }

    return (
        <div>
            <Modal
                isOpen={is_open}
                toggle={() => hide()}
                className="tt-modal--admin modal-dialog-centered"
            >
                <ModalHeader toggle={() => hide()}>Exchange Details</ModalHeader>
                <ModalBody>
                    {
                        loading['trade_loading'] ?
                            <ListLoader />
                            :
                            <Form className="tt-admin-form">
                                {
                                    !isEmpty(trade) &&
                                    <div className="tt-admin-form-inner trip-details-modal p-0">
                                        <Row>
                                            <Col xs="12" md="6">
                                                <h6>Trip 1</h6>
                                                <h5>  {trade && trade.trip_trades[0] && trade.trip_trades[0].trip && trade.trip_trades[0].trip.title ?
                                                    trade.trip_trades[0].trip.title : ''}</h5>
                                                <div className="owner-detail-outer">
                                                    <Link to={"#"} className="owner"><img src="/images/admin/global/user.svg" alt="" />  {trade.trip_trades[0] && trade.trip_trades[0].trip && trade.trip_trades[0].trip.user &&
                                                        trade.trip_trades[0].trip.user.full_name ? trade.trip_trades[0].trip.user.full_name : ''}</Link>
                                                    <span className="date"><img src="/images/admin/global/date.svg" alt="" />
                                                        {
                                                            trade.trip_trades[0] && trade.trip_trades[0].arrival_time ?
                                                                ` ${moment(trade.trip_trades[0].arrival_time, 'YYYY-MM-DD HH:mm:ss').format('ll')}` : ''
                                                        }
                                                    </span>
                                                </div>
                                            </Col>
                                            <Col xs="12" md="6">
                                                <h6>Trip 2</h6>
                                                <h5> {trade.trip_trades[1] && trade.trip_trades[1].trip && trade.trip_trades[1].trip.title}</h5>
                                                <div className="owner-detail-outer">
                                                    <Link to={"#"} className="owner"><img src="/images/admin/global/user.svg" alt="" />   {trade.trip_trades[1] && trade.trip_trades[1].trip && trade.trip_trades[1].trip.user &&
                                                        trade.trip_trades[1].trip.user.full_name ? trade.trip_trades[1].trip.user.full_name : ''}</Link>
                                                    <span className="date"><img src="/images/admin/global/date.svg" alt="" />
                                                        {
                                                            trade.trip_trades[1] && trade.trip_trades[1].arrival_time ?
                                                                ` ${moment(trade.trip_trades[1].arrival_time, 'YYYY-MM-DD HH:mm:ss').format('ll')}` : ''
                                                        }
                                                    </span>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs="12">
                                                <hr className="hr-solid" />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs="12">
                                                <h6 className="my-0">Status</h6>
                                                <FormGroup>
                                                    <div className="custom-react-select--no-border w-100">
                                                        <Select
                                                            components={{ SingleValue: customSingleValue, Option: CustomOption }}
                                                            //className="react-select-container"
                                                            classNamePrefix="react-select"
                                                            options={icon_lookups}
                                                            onChange={(e) =>
                                                                setState({
                                                                    ...state,
                                                                    status: e
                                                                })
                                                            }
                                                            styles={styles}
                                                            value={state.status || null}
                                                        />
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs="12">
                                                <hr className="hr-solid" />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs="12">
                                                <h6 className="my-0">Description</h6>
                                                <p>
                                                    {
                                                        trade.description ? trade.description : ''
                                                    }
                                                </p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs="12">
                                                <hr className="hr-solid" />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs="12">
                                                <h6 className="my-0">Support Emails</h6>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs="12">
                                                {
                                                    !isEmpty(support_emails) && !isEmpty(support_emails.data) &&
                                                    support_emails.data.map((emails, index) => (
                                                        <div
                                                            className={classnames("lead-support-email", { 'email-sent': emails.support_email_status && emails.support_email_status.slug === 'sent' ? true : false })}
                                                            key={`support_emails${index}`}
                                                        >
                                                            <h4>{emails.subject ? emails.subject : ''} (<Link to={"#"} onClick={() => {
                                                                show('EditSupportEmail', {
                                                                    support_email_id: emails.support_email_id,
                                                                    status: emails.support_email_status && emails.support_email_status.slug ?  emails.support_email_status.slug: '',
                                                                    callBack: () => {
                                                                        apiCalls.getAllSupportEmails();
                                                                    }
                                                                })
                                                            }}> {emails.support_email_status && emails.support_email_status.slug === 'sent' ? 'View Message' : 'Edit Message'}</Link>)</h4>
                                                            <h5>{emails.support_email_status && emails.support_email_status.label ? emails.support_email_status.label : ''}</h5>
                                                        </div>
                                                    ))
                                                }
                                            </Col>
                                        </Row>
                                    </div>
                                }
                            </Form>
                    }
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        className="btn-outline-grey"
                        onClick={() => hide()}
                    >Cancel
                    </Button>
                    <Button
                        color="primary"
                        className="add-new-btn"
                        onClick={submit}
                        disabled={loading['update_status']}
                    >
                        {loading['update_status'] ?
                            <Spinner size="sm" /> : 'Submit'
                        }

                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}
