import React, { useContext } from "react";
import { find } from 'lodash';

import { LookupContext } from 'js/contexts';
import moment from "moment";

//

export default props => {

    const lookup = useContext(LookupContext);
    const data = props.data;

    const type = find(lookup.endorsement_types, ['endorsement_type_id',  data.endorsement_type_id] );

	return <div className="endorsement-card">
        { data.endorsed_user && data.endorsed_user.attachment ?
        <div className="endorse-card--img">
            <img src={data.endorsed_user.attachment.attachment_url } alt=" User" />
        </div>
        :
        <div className="endorse-card--img border-0">
            <img src={'/images/web/global/logo-icon.svg'} alt=" User" />
        </div>
        }
        {/* TODO_HERE */}
        <div className="endorse-card--data">
            { data.endorsed_user ?
                <>
                    <h6><strong className="color--orange" >{data.endorsed_user.full_name}</strong> endorsed {data.user && data.user.first_name} for his { type && type.label } on {data.endorsed_at ? moment(data.endorsed_at).format('MM/DD/YYYY') :'' }</h6>
                    <p>Trips4Trade Member</p>
                </>
            :
                <>
                    <h6 ><strong>{data.full_name}</strong> endorsed {data.user && data.user.first_name} for his { type && type.label } on {data.endorsed_at ?moment(data.endorsed_at).format('MM/DD/YYYY') : ''}</h6>
                    <p>Non Member</p>
                </>
            }

            { data.description && <h5><strong>Comments:</strong>{data.description}</h5> }
        </div>
    </div>
}
