import { request } from "js/helpers/api";
import queryString from 'query-string';

export const tripsServices = {
    getAll,
    create,
    update,
    remove,
    get,
    exportToExcel,
    bulkAction,
    updateStatus,
    bookmarkTrip,
    getBookmarkedTrips,
    updateOwner,
    changeCover,

    inquiry,
    inquiryUpdate,

    getReviews,
    addReviews,
    toggleReview,

    getMapData,
    getTripHomePage
};

function getAll(params = {}, cancel_token, hideModal) {
    return request({
        method: 'GET',
        url: 'trips',
        param: params,
        cancel_token : `tripsServices.getAll${cancel_token}`,
        hideModal
    });
}

function create(details) {
    return request({
        method: 'POST',
        url: 'trips',
        param: details,
        content_type: 'json',
    });
}

function update(details, slug) {
    return request({
        method: 'PUT',
        url: `trips/${slug}`,
        param: details,
        content_type: 'json',
    });
}

function remove(slug) {
    return request({
        method: 'DELETE',
        url: `trips/${slug}`,
        param: {},
    });
}

function get(slug, params = {}) {
    return request({
        method: 'GET',
        url: `trips/${slug}`,
        param: params,
        cancel_token : `tripsServices.get`
    });
}

function exportToExcel(params) {
    return request({
        method: 'GET',
        url: `trip/generate-export-link`,
        param: params,
    });
}

function bulkAction(details) {
    return request({
        method: 'POST',
        url: `trips/bulk-action`,
        param: details,
        content_type: 'json',

    });
}

function updateStatus(details, slug) {
    return request({
        method: 'PUT',
        url: `trips/${slug}/update-status`,
        param: details,
        content_type: 'json',
    });
}

function bookmarkTrip(slug) {
    return request({
        method: 'GET',
        url: `bookmarks/trips/${slug}/toggle`,
        param: {},
    });
}

function getBookmarkedTrips(params) {
    return request({
        method: 'GET',
        url: `bookmarks`,
        param: params,
    });
}

function updateOwner ( slug, details, hideModal ){
    return request({
        method: 'PUT',
        url: `trips/${slug}/change-owner`,
        param: details,
        content_type: 'json',
        hideModal
    });
}

function changeCover ( slug, trip_media_id ){
    return request({
        method: 'PUT',
        url: `trips/${slug}/change-cover-image/${trip_media_id}`,
        param: {},
    });
}


function inquiry(slug, details, is_authenticated = false) {
    return request({
        method: 'POST',
        url: `inquiry/trip/${slug}`,
        param: details,
        content_type: 'json',
        is_authenticated
    });
}

function inquiryUpdate(inquiry_id, details) {
    return request({
        method: 'PUT',
        url: `inquiries/${inquiry_id}`,
        param: details,
        content_type: 'json',
    });
}

function getReviews(params) {
    return request({
        method: 'GET',
        url: `reviews`,
        param: params,
        content_type: 'json',
        cancel_token : `tripsServices.getReviews`
    });
}

function addReviews(trip_trade_id, details, hideModal) {
    return request({
        method: 'POST',
        url: `reviews/${trip_trade_id}`,
        param: details,
        content_type: 'json',
        hideModal
    });
}

function toggleReview(review_id, details) {
    let query = queryString.stringify(details)
    return request({
        method: 'PUT',
        url: `reviews/${review_id}?${query}`,
        param: {},
    });
}

function getMapData (){
    return request({
        method:'GET',
        url: `maps/trips`,
        param: {},
    });
}

function getTripHomePage(params){
    return request({
        method: 'GET',
        url: `trip-categories/trips/explore`,
        param: params,
        content_type: 'json',
        cancel_token:"tripsServices.getTripHomePage"
    });
}
