import React from "react"
import "js/components/legos/styles/trip-specs-module.css"

export default props => (
  
        <div className="trip-specs-outer">
           <ul>
                <li>
                    <img src="/images/admin/global/availability.svg" alt="" />
                    <div>
                        <h5>Availability</h5>
                    <h4>{props.availability}&nbsp;</h4>
                    </div>
                </li>
                <li>
                    <img src="/images/admin/global/duration.svg" alt="" />
                    <div>
                        <h5>Duration</h5>
                    <h4>{props.duration}&nbsp;</h4>
                    </div>
                </li>
                <li>
                    <img src="/images/admin/global/accomodates.svg" alt="" />
                    <div>
                        <h5>Accomodates</h5>
                    <h4>{props.accomodates}&nbsp;</h4>
                    </div>
                </li>
                <li>
                    <img src="/images/admin/global/for-sale.svg" alt="" />
                    <div>
                        <h5>For Sale?</h5>
                    <h4>{props.spec_sale}&nbsp;</h4>
                    </div>
                </li>
           </ul>
        </div>
  
   
)



