import React, {useState, useEffect} from "react";
import { Form, Button, Spinner } from 'reactstrap';
import { TripMedia } from "js/components/common";
import { isEmpty, omit } from 'lodash';

export const TripPhotosTab = (props) => {

    const [files, addFiles] = useState([]);

    useEffect(() => {
        if(props.trip){
            if(props.trip.trip_media) {
                let existing_files = props.trip.trip_media.map(item => {
                    return{...omit(item, 'attachment'), ...item.attachment}
                })
                addFiles(existing_files);
            }
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.trip]);

    const handleSubmit = () => {
        let attachments = files.map(item => item.attachment_id)
        let errors = {}
        if(isEmpty(attachments)) {
            errors['attachments'] =  ['Please choose any photo']
        }
        if(props.trip) {
            props.onUpdate({attachments})
        }
        else {
            props.onChange(attachments)
        }
    //    setErrors(errors)

    }

    const addNewFiles = ( response ) => {
        addFiles(files => ( [...files, response ]));
    }

    const removeFile = (removed_item, final_files) => {
        addFiles(final_files)
    }
    let form_errors = props.errors

    return (
        <Form className="tt-admin-form">
            <div className="tt-admin-form-inner">
                <TripMedia files={files} onChange={(file) => addFiles(file)} onAddNewFiles={addNewFiles} onRemoveFile={removeFile}/>
                <div className="invalid-feedback d-block">{form_errors['attachments']}</div>
                <div className="invalid-feedback d-block">{form_errors['general']}</div>
            </div>
                <div className="tt-admin-form-footer">
                {props.trip ?
                    <Button
                        color="primary"
                        className="add-new-btn"
                        onClick={handleSubmit}
                        disabled={props.loading}
                    >{props.loading ? <Spinner size="sm"/>: 'Save Trip Media'}
                    </Button>
                    :
                    <Button
                        color="primary"
                        className="add-new-btn"
                        onClick={handleSubmit}
                        disabled={props.loading}
                    >{props.loading ? <Spinner size="sm"/>: 'Submit'}
                    </Button>
                }
                </div>
        </Form>
    );
}
