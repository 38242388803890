import React, { useState, useEffect } from 'react';
import {
    Row,
    Col,
    Button,
    Form,
    FormGroup,
    Label,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Input,
    Spinner
} from 'reactstrap';
import { isEmpty, forEach } from 'lodash';

import { membershipPlanService } from 'js/services';
import { ErrorFormFeedback } from 'js/components/common';


export const AddEditMembershipPlanModal = (props) => {
    const { is_open, hide } = props;
    const [member_ship_plan, setMemberShipPlan] = useState({});
    const [createErrors, setErrors] = useState({});
    const [loading, setLoading] = useState({
        loading_button: false
    });


    const apiCalls = {
        createMembershipPlan: (details) => {
            setLoading((loading) => ({
                ...loading,
                loading_button: true
            }));
            membershipPlanService.create(details, hide).then((response) => {
                setLoading((loading) => ({
                    ...loading,
                    loading_button: false
                }));
                setErrors({});
                hide();
                props.callBack && props.callBack();
            })
                .catch((err) => {
                    let errors = err.data && err.data.errors;
                    setErrors(errors);
                    setLoading((loading) => ({
                        ...loading,
                        loading_button: false
                    }));
                });
        },
    }

    useEffect(() => {
        setMemberShipPlan({
            ...member_ship_plan,
            content: ['']
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onChangeContent = (value, ind) => {
        let current_contents = member_ship_plan.content;

        forEach(current_contents, (item, index) => {
            if (ind === index) {
                current_contents[index] = value;
            }
        })

        setMemberShipPlan({
            ...member_ship_plan,
            content: [...current_contents]
        });
    }

    const addOrDeleteButton = (ind) => {
        let current_contents = member_ship_plan.content;
        if (ind === 0) {
            current_contents.push('');
            setMemberShipPlan({
                ...member_ship_plan,
                content: current_contents
            });
        }
        else {
            let filterd_content = current_contents.filter((content, index) => index !== ind);
            setMemberShipPlan({
                ...member_ship_plan,
                content: filterd_content
            });
        }
    }

    const submit = () => {
        let details = { ...member_ship_plan };

        apiCalls.createMembershipPlan(details);
    }

    return (
        <div>
            <Modal
                isOpen={is_open}
                toggle={() => hide()}
                className="tt-modal--admin modal-dialog-centered"
            >
                <ModalHeader toggle={() => hide()}>{props.title}</ModalHeader>
                <ModalBody>
                    <Form className="tt-admin-form">
                        <div className="tt-admin-form-inner p-0">
                            <Row form>
                                <Col xs="12" md="8">
                                    <FormGroup>
                                        <Label for="">Plan Title</Label>
                                        <Input
                                            type="text"
                                            name="label"
                                            onChange={(e) => setMemberShipPlan({ ...member_ship_plan, [e.target.name]: e.target.value })}
                                            value={member_ship_plan.label ? member_ship_plan.label : ''}
                                            invalid={!isEmpty(createErrors) && (createErrors.label)
                                                && true
                                            }
                                        />
                                        <ErrorFormFeedback field={'label'} errors={createErrors} />
                                    </FormGroup>
                                </Col>
                                <Col xs="12" md="4">
                                    <FormGroup>
                                        <Label for="">Cost</Label>
                                        <Input
                                            type="text"
                                            name="price"
                                            placeholder='$'
                                            onChange={(e) => setMemberShipPlan({ ...member_ship_plan, [e.target.name]: e.target.value })}
                                            value={member_ship_plan.price ? member_ship_plan.price : ''}
                                            invalid={!isEmpty(createErrors) && (createErrors.price)
                                                && true
                                            }
                                        />
                                        <ErrorFormFeedback field={'price'} errors={createErrors} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            {
                                !isEmpty(member_ship_plan.content) &&
                                member_ship_plan.content.map((content, ind) => (
                                    <Row form key={`content_modal_${ind}`}>
                                        <Col xs="12" md="10">
                                            <FormGroup>
                                                {ind === 0 &&
                                                    <label>Content</label>
                                                }
                                                <Input
                                                    type="text"
                                                    name="label"
                                                    onChange={(e) => {
                                                        onChangeContent(e.target.value, ind)
                                                    }
                                                    }
                                                    value={member_ship_plan.content && member_ship_plan.content[ind] ? member_ship_plan.content[ind] : ''}
                                                    invalid={!isEmpty(createErrors) && (createErrors['content.0'])
                                                        && true
                                                    }
                                                />
                                                <ErrorFormFeedback field={'content.0'} errors={createErrors} />
                                            </FormGroup>
                                        </Col>
                                        <Col xs="12" md="2">
                                            <FormGroup>
                                                {ind === 0 &&
                                                    <label className="w-100">&nbsp;</label>
                                                }
                                                <Button
                                                    color="primary"
                                                    className="add-new-btn"
                                                    onClick={() => {
                                                        addOrDeleteButton(ind)
                                                    }}
                                                >
                                                    {
                                                        ind === 0 ?
                                                            <i className="mr-0">
                                                                <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M5.03027 7.77295H0.171875V5.6001H5.03027V0.619629H7.22754V5.6001H12.0981V7.77295H7.22754V12.729H5.03027V7.77295Z" fill="#fff" />
                                                                </svg>
                                                            </i>
                                                            : '-'
                                                    }
                                                </Button>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                ))
                            }
                            <Row form>
                                <Col xs="12" md="6">
                                    <FormGroup>
                                        <Label for="">Expiry Days</Label>
                                        <Input
                                            type="number"
                                            name="expiry_days"
                                            onChange={(e) => setMemberShipPlan({ ...member_ship_plan, [e.target.name]: e.target.value })}
                                            value={member_ship_plan.expiry_days ? member_ship_plan.expiry_days : ''}
                                            invalid={!isEmpty(createErrors) && (createErrors.expiry_days)
                                                && true
                                            }
                                        />
                                        <ErrorFormFeedback field={'expiry_days'} errors={createErrors} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row form>
                                <Col xs="12">
                                    <FormGroup>
                                        <Label for="">Description</Label>
                                        <Input
                                            type="textarea"
                                            name="description"
                                            onChange={(e) => setMemberShipPlan({ ...member_ship_plan, [e.target.name]: e.target.value })}
                                            value={member_ship_plan.description ? member_ship_plan.description : ''}
                                            invalid={!isEmpty(createErrors) && (createErrors.description)
                                                && true
                                            }
                                        />
                                        <ErrorFormFeedback field={'description'} errors={createErrors} />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </div>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        className="btn-outline-grey"
                        onClick={() => hide()}
                        disabled={loading['loading_button']}
                    >Cancel
                    </Button>
                    <Button
                        color="primary"
                        className="add-new-btn"
                        onClick={() => submit()}
                        disabled={loading['loading_button']}
                    >
                        {loading['loading_button'] ?
                            <Spinner size="sm" /> : 'Add Plan'
                        }
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}
