import React, { useState, useRef, useEffect, useContext } from 'react';
import { Row, Col, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import { Helmet } from "react-helmet";
import { forEach, concat } from 'lodash';
import { Link } from 'react-router-dom';

import AboutHost from "js/components/legos/aboutHost";
import UserBadges from "js/components/legos/userBadges";
import StarRatingMinimal from "js/components/legos/starRatingMinimal";
import Verifications from "js/components/legos/verifications";
import SocialLinks from "js/components/legos/socialLinks";
import { LookupContext } from 'js/contexts';
import { tripsServices, userServices } from 'js/services';
import { ListLoader,  TripListing, RelatedExperience } from 'js/components/common';
import { AuthProvider } from 'js/contexts';

export const ViewListing = (props) => {
    const [ active_tab, setActiveTab ] = useState('all');
    const [ trips, setTrip ] = useState({});
    const [ user, setUser ] = useState({});
    const [ active_category, setCategory ] = useState(null);
    const lookups = useContext(LookupContext);
    let tabs = useRef({});
    let user_id = props.match && props.match.params && props.match.params.user_id ? props.match.params.user_id : null;
    const [ state, setState ] = useState({
        loading: false,
        load_more_loading: false
    })
    const { auth } = useContext(AuthProvider);

    const toggle = tab => {
        let active_tab = tab === 'all' ? tab : tab.slug
        setActiveTab(active_tab);
        let params = {user: user_id}
        params['category'] = tab === 'all' ? null : tab.trip_category_id;
        setCategory(params.category)
        getAllTrips(params, tab.slug)
    }

    const getAllTrips =  function (params, category, mode) {
        mode === 'append' ?  setState({...state, load_more_loading: true}) : setState({...state, loading: true});
        tripsServices.getAll({...params, user: user_id } , params.category)
        .then(response => {
            if( !category ) {
                category = 'all'
            }
            console.log( category );
            if(mode === 'append') {
                let data = category &&  trips[category] && trips[category].data  ? trips[category].data : [];
                response.data = concat( data, response.data );
            }

            mode === 'append' ?  setState({...state, load_more_loading: false}) : setState({...state, loading: false});
            setTrip( trips => ({
                ...trips,
                [category]: response
            }));
        }).catch(err => {
            console.log('failed', err)
        })
    }

    const getUser = (user_id) => {
        userServices.get(user_id, {public_profile:1})
        .then(response => {
            setUser(response)
        })
    }

    useEffect(() => {
        setState({...state, loading: true})
        getAllTrips({user:user_id})
        getUser(user_id)
        Promise.all(forEach(lookups.trip_categories, item => {
            let params = {
                user:user_id,
                category: item.trip_category_id
            }
            getAllTrips(params, item.slug)

        })).then(res => {

            setState({...state, loading: false})
        }).catch(error => {
            console.log('promise failed', error)
        })
        return () => {
            //cleanup
            tabs.current = null
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onUpdate = (category, updated_trips) => {
        setTrip(trips => ({
            ...trips,
            [category] : {
                ...trips[category],
                data : updated_trips
            }
        }));
    }

    return (
        <div>
            <Helmet>
                <title>Trip4Trade :: All Listings</title>
                <meta name="description" content=" All Listings page desription" />
                <meta name="keywords" content="trip4trade,  All Listings" />
            </Helmet>

            <div className="inner-banner-profile" style={{ backgroundImage: `url(/images/web/global/banners/profile-banner.png)` }}>

            </div>
            <div className="wider-data-frame wider-data-frame--grey pt-0">
                <div className="container">
                    <div className="profile-outer-wrapper">
                        <Row className="no-gutters">
                            <Col xs="12" lg="9">
                                <div className="profile-data-section">
                                    <div className="profile-data-section--top">
                                        <Row>
                                            <Col xs="12" md="8" className="pb-4">
                                                <h3>
                                                    <img src="/images/web/global/profile/listings.svg" alt="" style={{ width: `37px` }} />
                                                    {`  ${user.full_name ? user.full_name: ''}`}
                                                </h3>
                                            </Col>

                                        </Row>
                                        <Row>
                                            <Col xs="12">
                                                <Nav tabs className="tt-tabs">
                                                    <NavItem>
                                                    <NavLink
                                                        className={classnames({ active: active_tab === 'all' })}
                                                        onClick={() => { toggle('all'); }}
                                                    >
                                                            {`All (${ trips.all && trips.all.meta ? trips.all.meta.total : '' })`}
                                                    </NavLink>
                                                    </NavItem>
                                                    {lookups.trip_categories && lookups.trip_categories.map((category, index) => (
                                                        <NavItem key={index}>
                                                            <NavLink
                                                                className={classnames({ active: active_tab === category.slug })}
                                                                onClick={() => { toggle(category); }}>
                                                                {`${category.label} (${ trips
                                                                                        && trips[category.slug]
                                                                                        && trips[category.slug].meta ?
                                                                                        trips[category.slug].meta.total : '' })`}
                                                            </NavLink>
                                                        </NavItem>
                                                    ))}
                                                </Nav>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className="profile-data-section--bottom">
                                        <Row>
                                            <Col xs="12">
                                                <TabContent activeTab={active_tab}>
                                                    <TabPane tabId="all" className="my-3">
                                                    {state.loading ?
                                                        <ListLoader/>
                                                        :
                                                        <>
                                                        { trips.all && trips.all.data &&
                                                            <TripListing
                                                                class_name="col-md-6 col-xs-12"
                                                                trips={trips.all}
                                                                onUpdate ={ ( trips) => onUpdate('all', trips) }
                                                                onLoadMore={(page) => getAllTrips({page}, 'all', 'append')}
                                                                load_more_loading={state.load_more_loading}
                                                            />
                                                        }
                                                        </>
                                                    }
                                                    </TabPane>
                                                    {lookups.trip_categories && lookups.trip_categories.map((category, index) => (
                                                        <TabPane tabId={category.slug} className="my-3" key={index}>
                                                        {state.loading ?
                                                            <ListLoader/>
                                                            :
                                                            <>
                                                            { trips[category.slug] && trips[category.slug].data &&
                                                                <TripListing
                                                                    class_name="col-md-6 col-xs-12"
                                                                    trips={trips[category.slug]}
                                                                    onUpdate ={ ( trips) => onUpdate(category.slug, trips) }
                                                                    onLoadMore={(page) => getAllTrips({
                                                                        category: category.trip_category_id,
                                                                        page,
                                                                    }, category.slug, 'append')}
                                                                    load_more_loading={state.load_more_loading}
                                                                />
                                                            }
                                                            </>
                                                        }
                                                        </TabPane>
                                                    ))}
                                                </TabContent>
                                            </Col>
                                        </Row>

                                    </div>
                                </div>
                            </Col>
                            <Col xs="12" lg="3">
                                <div className="profile-sidebar">
                                    <Row>
                                        <Col>
                                            <AboutHost
                                                data={user}
                                            />
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            <UserBadges
                                                data={user}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <StarRatingMinimal data={user} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs="12">
                                            <div className="profile-btn-wrap">
                                                <Link class="btn-outline btn-block" to={`/messages/${user_id}`}>Send Message</Link>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <hr className="hr-sidebar" />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Verifications data={user} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <hr className="hr-sidebar" />
                                        </Col>
                                    </Row>
                                    {
                                        auth?.is_logged_in &&
                                        <Row>
                                            <Col>
                                                <SocialLinks data={user} />
                                            </Col>
                                        </Row>
                                    }
                                    <Row>
                                        <Col>
                                            <hr className="hr-sidebar" />
                                        </Col>
                                    </Row>
                                </div>

                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
            {active_category && !state.loading &&
            <RelatedExperience category_id={active_category}/>
            }
        </div>
    );

}







