import React from "react";
import { Row, Col, Button } from 'reactstrap';

export const LoadMoreComponent = (props) => {

    const onPageChange = (item) => {
        props.onChange(item)
    }

    const { page } = props;
    let current_page = 1;
    let total_pages = 0;
    if( page ){
        total_pages = Math.ceil(page.total / page.per_page);
        current_page = page.current_page;
    }

    return (
        <React.Fragment>
            { current_page <  total_pages &&
            <div className="dual-btn-wrapper">
                <Row>
                    <Col xs="12" className="d-flex justify-content-center mt-5">
                        <Button to={"#"} className="btn btn-outline" onClick={() => onPageChange(Number(current_page) +1)} >View More</Button>
                    </Col>
                </Row>
            </div>
            }
        </React.Fragment>
    );
}
