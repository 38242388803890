import React, { useState, useEffect, useContext } from "react";
import { Row, Col, Form, FormGroup, Label, Input } from 'reactstrap';
import classNames from 'classnames';
import Select from 'react-select';
import { omit, forEach, isObject, trimStart, isEmpty } from 'lodash';
import DatePicker from 'react-datepicker';
import { LookupContext } from 'js/contexts';
import moment from "moment";
import Geosuggest from 'react-geosuggest';
import { find } from 'lodash';

import { TreeContainer } from 'js/components/common';
import { lookupServices } from "js/services";

const styles = {
    option: (styles, state) => ({
        ...styles,
        backgroundColor: state.isSelected ? "#FFA405 " : state.isFocused ? "#FFA40520" : "#ffffff"
    })
};

export const SearchHeader = (props) => {
    const [state, setState] = useState({
        isPressed: false,
        country: '',
        viewFilterInMobile: false,
    });
    const [ params, setParams ] = useState( {} );
    const [ sub_category_lookups, setSubCategory ] = useState( [] );
    const [ state_lookups, setLookups ] = useState( [] );
    const lookups = useContext(LookupContext);

    const sales_lookups = [
        { label: 'Yes', value: 1 },
        { label: 'No', value: 0 }
    ]

    useEffect( () => {
        let filter = {};
        let state_params = {
            ...params
        };
        if ( props.location && props.location.search )
        {
            let search = trimStart( props.location.search, '?' );
            filter[ 'search' ] = search;
            state_params[ 'search' ] = search;
        }
        if ( props.match && props.match.params && props.match.params.category )
        {
            let category = props.match.params.category;
            state_params[ 'category' ] = lookups.trip_categories.filter( item =>
                item.slug === category
            )[ 0 ];
            filter = {
                ...filter,
                category: state_params[ 'category' ] ?
                    state_params[ 'category' ].value : ''
            };
            getSubCategory( state_params[ 'category' ] );
        }
        setParams( state_params );
        !props.disable_auth_load && props.onSearch && props.onSearch( filter );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ props.location, props.match ] );

    useEffect( () => {
        let country_id = find( lookups.countries, [ 'short_name', 'US' ] )?.country_id;
        lookupServices.getStates( country_id ).then( res => {
            let states = ( [
                { long_name: 'All'},
                { long_name: 'Not in US', short_name: 'not_in_us', not_in_us: true}
            ].concat( res ) )?.map( item => {
                return {...item, label:item.long_name, value: item.short_name }
            } )
            setLookups(states)
        } ).catch( err => {
            setLookups([{label: 'All', value: null}])
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])


    const onParamsChange = ( key, param ) => {

        let new_params = {}
        new_params = {
            ...params,
            [key]: param
        }
        if ( key === 'category' )
        {
            getSubCategory(param)
            new_params.sub_category = ''
        }
        if ( key === 'state' )

        {
            setState( { ...state, country: param.country_id ? 'US' : '', address: '' } )
            let omit_value = ['not_in_us', 'city']
            if ( param.not_in_us )
            {
                key = 'not_in_us'
                param.value = 1
                omit_value = ['state', 'city']
            }
            new_params = {...omit(new_params, omit_value), [ key ]: param}
        }

        setParams(new_params)

    }
    const getSubCategory = (category) => {
        let sub_category_options = category?.children?.map( item => ( {
            ...item, value: item.trip_category_id
        } ) );
        setSubCategory( sub_category_options )

    }
    const handleSearch = (e) => {
        e.preventDefault();
        let filter = { ...params }
        filter.start_date = params.start_date ? moment(params.start_date).startOf('month').format('YYYY-MM-DD') : null
        filter.end_date = params.end_date ? moment( params.end_date ).endOf( 'month' ).format( 'YYYY-MM-DD' ) : null

        if ( params.sub_category )
        {
            filter.category = params.sub_category
        }
        forEach(filter, (value, key) => {
            if (isObject(value)) {
                filter[key] = value.value
            }
        })
        props.onSearch(filter)
    }

    const handleAddressChange = (place) => {
        let city = {};
        let state = {};
        let country = {};
        if (!isEmpty(place)) {
            if (place.gmaps && place.gmaps.address_components) {
                forEach(place.gmaps.address_components, (value, key) => {
                    value.types && forEach(value.types, type => {
                        if (type === "locality") {
                            city = { ...omit(value, 'types') }
                        }
                        if( type === "administrative_area_level_1" ){
                            state = { ...omit(value, 'types') }
                        }
                        if( type === "country" ){
                            country = { ...omit(value, 'types') }
                        }
                    })
                })
            }

        }
        let country_new = ''
        if( country.short_name ){
            country_new = find(lookups.countries, ['short_name',  country.short_name] );
        }
        setParams ({
            ...params,
            city: city.long_name ? city.long_name: null,
            state: state.short_name ? state.short_name: params.state
        })

        setState((state) => ({
            ...state,
            address: place && place.description ? place.description : '',
            country: country_new
        } ) );

    }

    const onTreeChange = (selectedNodes) => {
        let selected_category = { ...selectedNodes?.[0], value: selectedNodes?.[0]?.trip_category_id}
        onParamsChange( 'sub_category', selected_category)
    }

    const toggleFilter = () => {
        setState({
            viewFilterInMobile: !state.viewFilterInMobile
        });
    }

    let categories = lookups.trip_categories?.concat( { label: 'All' } )

    return (
        <div className="wider-data-frame wider-data-frame--trips">
            <div className="container">
                <Row className="d-block d-md-none">
                    <Col xs="12">
                        <h4 className="search-filter-toggle"><p className={classNames({ "toggled": state.viewFilterInMobile})} onClick={toggleFilter}>Filters</p></h4>
                    </Col>
                </Row>
                <Row  className={classNames({ "d-none d-md-block d-lg-block": !state.viewFilterInMobile})}>
                    <Col xs="12">
                        <Form className='tt-search-form' >
                            <Row form>
                                <Col xs="12" md="6" lg="2">
                                    <FormGroup>
                                        <Label for="">Category </Label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            options={categories}
                                            styles={styles}
                                            onChange={(e) => onParamsChange('category', e)}
                                            value={params.category || null}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col xs="12" md="6" lg="2">
                                    <FormGroup>
                                        <Label for="">Trip Title</Label>
                                        <Input
                                            type="text"
                                            onChange={(e) => onParamsChange('search', e.target.value)}
                                            value={params.search || ''}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col xs="12" md="6" lg="2">
                                    <FormGroup>
                                        <Label for="">Country/State</Label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            options={state_lookups}
                                            styles={styles}
                                            onChange={ ( e ) =>
                                                onParamsChange( 'state', e )
                                            }
                                            value={params.country || params.not_in_us}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col xs="12" md="6" lg="2">
                                    <FormGroup>
                                        <Label for="">For Sale </Label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            options={sales_lookups}
                                            styles={styles}
                                            onChange={(e) => onParamsChange('for_sale', e)}
                                            value={params.for_sale || null}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col xs="12" md="6" lg="2">
                                    <FormGroup>
                                        <Label for="">Trade Interests</Label>
                                        <Input
                                            type="text"
                                            onChange={(e) => onParamsChange('trade_interests', e.target.value)}
                                            value={params.trade_interests || ''}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col xs="12" md="6" lg="2">
                                    <FormGroup className="mt-10">
                                        <span
                                            onClick={() => {
                                                setState((state) => {
                                                    let new_state = {
                                                        ...state, isPressed: !state.isPressed
                                                    }
                                                    if (!new_state.isPressed) {
                                                        setParams({ ...omit(params, 'accommodates', 'date', 'town') })
                                                        new_state = { ...omit(new_state, 'address') }
                                                    }
                                                    return new_state;
                                                })
                                            }
                                            }
                                            className="btn-outline-black mr-3">{state.isPressed ? '-' : '+'}
                                        </span>
                                        <button className="btn-solid-black" onClick={(e) => handleSearch(e)}>Search</button>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row form className={classNames("d-none", { "d-flex": state.isPressed })} >
                                <Col xs="12">
                                    <hr className="hr-dotted" />
                                </Col>
                                <Col xs="12" md="6" lg="3">
                                    <FormGroup>
                                        <Label for="">Sub-Category </Label>
                                        <TreeContainer
                                            data={ sub_category_lookups }
                                            onChange={onTreeChange}
                                            mode="radioSelect"
                                            className="tt-tree-filter-container"
                                            placeholder="Trip Category"
                                        />
                                    </FormGroup>
                                </Col>
                                <Col xs="12" md="6" lg="3">
                                    <FormGroup>
                                        <Label for="">Accommodates</Label>
                                        <Input
                                            type="number"
                                            onChange={(e) => onParamsChange('accommodates', e.target.value)}
                                            value={params.accommodates || ''}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col xs="12" md="6" lg="3">
                                    <FormGroup>
                                        <Label for="">Date Range</Label>
                                        <Row>
                                            <Col xs="6" >
                                                <div className="tt-custom-react-select custom-month-picker">
                                                    <DatePicker
                                                        placeholderText="Start date"
                                                        selected={params.start_date}
                                                        onChange={(e) => onParamsChange('start_date', e)}
                                                        selectsStart
                                                        startDate={params.start_date}
                                                        endDate={params.end_date}
                                                        dateFormat="MM/yyyy"
                                                        showMonthYearPicker
                                                        className='tt-month-range-picker-edit'
                                                    />
                                                </div>
                                            </Col>
                                            <Col xs="6" >
                                                <div className="tt-custom-react-select custom-month-picker">
                                                    <DatePicker
                                                        placeholderText="End date"
                                                        selected={params.end_date}
                                                        onChange={(e) => onParamsChange('end_date', e)}
                                                        selectsEnd
                                                        startDate={params.start_date}
                                                        endDate={params.end_date}
                                                        dateFormat="MM/yyyy"
                                                        showMonthYearPicker
                                                        className='tt-month-range-picker-edit'
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                        {/* DESIGN_TODO */}
                                    </FormGroup>
                                </Col>
                                <Col xs="12" md="6" lg="3">
                                    <FormGroup>
                                        <Label for="">City/Town </Label>
                                        <div className="tt-custom-react-select">
                                            <Geosuggest type="text"
                                                name="address"
                                                placeholder=""
                                                className="react-select-container"
                                                inputClassName={"form-control"}
                                                initialValue={state.address ? state.address : ''}
                                                value={state.address ? state.address : ''}
                                                autoComplete="nope"
                                                onSuggestSelect={handleAddressChange}
                                                country={state.country}
                                                onChange={(e) => setParams({...params, city: e, })}
                                            />
                                        </div>
                                    </FormGroup>
                                </Col>
                                {/* <Col xs="12" md="6" lg="2">
                                    <FormGroup>
                                        <Label for="">Corporate Connection</Label>
                                        <Input
                                            type="text"
                                            onChange={(e) => onParamsChange('corporate', e.target.value)}
                                            value={params.corporate || ''}
                                        />
                                    </FormGroup>
                                </Col> */}
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </div>
        </div>
    )
}
