import React, { useEffect, useState, useContext, useCallback } from "react";
import { Row, Col, Nav, NavItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import ScrollableAnchor from 'react-scrollable-anchor'
import { configureAnchors } from 'react-scrollable-anchor'
import moment from "moment";
import { isEmpty, last } from 'lodash';

import {SmallImageSlider} from "js/components/legos/smallImageSlider"
import {GallerySlider} from "js/components/legos/gallerySlider"
import { ReviewSlider } from "js/components/legos/reviewSlider"
import {InquireAbout} from "js/components/legos/inquireAbout"
import AboutHost from "js/components/legos/aboutHost"
import UserBadges from "js/components/legos/userBadges"
import StarRatingMinimal from "js/components/legos/starRatingMinimal"
import Verifications from "js/components/legos/verifications"

import {  GoogleMapWrapper, RelatedExperience, ListLoader } from "js/components/common";
import { AuthProvider } from 'js/contexts';
import { tripsServices } from "js/services";
import { history } from 'js/helpers';
import { useModal } from 'js/hooks';

configureAnchors({offset: -110});

export const TripDetails = (props) => {

    const [loading, setLoading] = useState(false)
    const [ trip, setTrip ] = useState({});
    const { auth } = useContext(AuthProvider);
    const [ show, hide ] = useModal();
    const { match, location } = props;
    const [trip_slug, setTripSlug] = useState('');

    const getSelectedTrip = useCallback((slug) => {
        setLoading(true)
        tripsServices.get(slug, {visibility : 'public' }).then(response => {
            setLoading(false)
            setTripSlug(slug)
            setTrip(response)
        }).catch(err => {
            history.push('/')
        })
    },[])

    useEffect(() => {

        if(match?.params?.slug && trip_slug !== match?.params?.slug){
            getSelectedTrip(match.params.slug);
        }
    }, [getSelectedTrip, location.hash, match, trip_slug]);

    let is_logged_in_user = true
    if(trip && trip.user) {
        is_logged_in_user = auth.user.user_id === trip.user.user_id
    }
    // let categories = trip?.trip_categories?.map(item => item.trip_category_id)
    return (
        <div>
            <Helmet>
                <title>Trip4Trade :: Trip Details</title>
                <meta name="description" content={`${trip ?trip.description : 'Trips details'}`} />
                <meta name="keywords" content="trip4trade, trip details" />
            </Helmet>
            <div className="inner-banner-trip-details">
            </div>
            <div className="wider-data-frame wider-data-frame--trip-details">
                {loading ? <ListLoader/>
                :
                <div className="container">
                    <Row>
                        <Col xs="12">
                            <SmallImageSlider images={trip.trip_media}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12" lg="8" className="mt-3">
                            <Row>
                                <Col xs="12" md="8">
                                    <h4 className="trip-name">{trip.title}</h4>
                                    <h6 className="trip-location">
                                        <img src="/images/web/global/map-outline.svg" alt="" />
                                        {`${trip.city ? trip.city.long_name : trip.state ?
                                            trip.state.long_name: ''},
                                            ${trip.state ? trip.state.short_name: ''},
                                            ${trip.country ? trip.country.short_name : ''}`
                                        }
                                    </h6>
                                </Col>
                                <Col xs="12" md="4">
                                    <h5 className="trip-id">Trip ID : {`#${trip.trip_no}`}</h5>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="6" md="3">
                                    <div className="trip-details-card">
                                        <div className="twisted-square">
                                            <img src="/images/web/trips/details/availability.svg" alt="" />
                                        </div>
                                        <div className="details-data">
                                            <label>Availability</label>
                                            <h5>
                                                {`${trip.trip_start_date ? moment(trip.trip_start_date).format('MMM'): ''}. -`}
                                                <br/>
                                                {`${trip.trip_end_date ? moment(trip.trip_end_date).format('MMM'): ''}.`}

                                            </h5>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs="6" md="3">
                                    <div className="trip-details-card">
                                        <div className="twisted-square">
                                            <img src="/images/web/trips/details/duration.svg" alt="" />
                                        </div>
                                        <div className="details-data">
                                            <label>Duration</label>
                                            <h5>
                                                {trip.trip_duration ? trip.trip_duration :`${trip.duration_min_days ? trip.duration_min_days : ''} -
                                                    ${trip.duration_max_days ? trip.duration_max_days : ''} Days`
                                                }
                                            </h5>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs="6" md="3">
                                    <div className="trip-details-card">
                                        <div className="twisted-square">
                                            <img src="/images/web/trips/details/accomodates.svg" alt="" />
                                        </div>
                                        <div className="details-data">
                                            <label>Accommodates</label>
                                            <h5>
                                                {`${trip.accomodating_min_seats ? trip.accomodating_min_seats : ''} -
                                                    ${trip.accomodating_max_seats ? trip.accomodating_max_seats : ''} People`
                                                }
                                            </h5>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs="6" md="3">
                                    <div className="trip-details-card">
                                        <div className="twisted-square">
                                            <img src="/images/web/trips/details/sale.svg" alt="" />
                                        </div>
                                        <div className="details-data">
                                            <label>For Sale?</label>
                                            <h5>
                                                {trip.is_interested_in_selling ? 'Yes' : 'No'}
                                            </h5>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="12">
                                    <Nav className="trip-details--sub-nav">
                                        <NavItem >
                                        <a href={"#overview"}>Overview</a>
                                        </NavItem>
                                        <NavItem>
                                        <a href={"#trade-interests"}>Trade Interests</a>
                                        </NavItem>
                                        <NavItem>
                                        <a href={"#gallery"}>Gallery</a>
                                        </NavItem>
                                        <NavItem>
                                        <a href={"#features"}>Features</a>
                                        </NavItem>
                                        <NavItem>
                                        <a href={"#location"}>Location</a>
                                        </NavItem>
                                        <NavItem>
                                            <a href={"#ratings"}>Ratings & Reviews</a>
                                        </NavItem>
                                    </Nav>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
                }
            </div>
            <div className="wider-data-frame wider-data-frame--grey wider-data-frame-trip-grey">
                {loading ? <ListLoader/>
                :
                <div className="container">
                    <Row>
                        <Col xs="12"  lg="8">
                            <ScrollableAnchor id={'overview'}>
                                <div className="tt-card">
                                    <h3>OVERVIEW</h3>
                                    <h4>Details</h4>
                                    <p>{trip.description}</p>
                                    <h4>Accomodation</h4>
                                    <p>{trip.accomodation_description}</p>
                                </div>
                            </ScrollableAnchor>
                            <ScrollableAnchor id={'trade-interests'}>
                                <div className="tt-card">
                                    <h3>Trade Interests</h3>
                                    <p>{trip.interested_trips_to_trade}</p>
                                </div>
                            </ScrollableAnchor>
                            <ScrollableAnchor id={'gallery'}>
                                <div className="tt-card">
                                    <h3>Gallery</h3>
                                    <GallerySlider images={trip.trip_media} />
                                </div>
                            </ScrollableAnchor>
                            <ScrollableAnchor id={'features'}>
                                <div className="tt-card">
                                    <h3>Features</h3>
                                    <ul>
                                        {trip.feature_tags && trip.feature_tags.map((tag, index) => (
                                            <li key={index}>{tag.label ? tag.label: ''}</li>
                                        ))}
                                    </ul>
                                </div>
                            </ScrollableAnchor>
                            <ScrollableAnchor id={'location'}>
                                <div className="tt-card">
                                    <h3>Location</h3>
                                    <h4>{`${trip.city ? trip.city.long_name : trip.state ?
                                            trip.state.long_name: ''},
                                            ${trip.state ? trip.state.short_name: ''},
                                            ${trip.country ? trip.country.short_name : ''}`
                                        }
                                    </h4>
                                    { trip.latitude && trip.longitude &&
                                    <div style={{ height: '298px', width: '100%' }}>
                                        <GoogleMapWrapper data={ [ { latitude : trip.latitude  , longitude : trip.longitude }] } fitBounds/>
                                    </div>
                                    }
                                </div>
                            </ScrollableAnchor>
                            <ScrollableAnchor id={'ratings'}>
                                <div className="tt-card" id="ratings">
                                    <h3>Ratings & Reviews</h3>
                                    { trip &&
                                    <Row>
                                        <Col>
                                            <ReviewSlider trip_id={trip.trip_id} />
                                        </Col>
                                    </Row>
                                    }
                                    { !trip.is_reviewed
                                        && trip?.trip_trades && last(trip.trip_trades) &&
                                    <Row>
                                        <Col xs="12" className="d-flex justify-content-center mt-2">
                                            <Link
                                                to={"#"}
                                                className="btn btn-outline text-capitalize"
                                                onClick={() =>
                                                    show('AddReviewModal', {
                                                        trip_trade_id: last(trip.trip_trades).trip_trade_id,
                                                        callBack: () => {
                                                            getSelectedTrip(trip.trip_id)
                                                            hide('AddReviewModal');
                                                        }
                                                    })
                                                }
                                            >Leave a Review</Link>
                                        </Col>
                                    </Row>
                                    }
                                     {/* TODO_HERE */}
                                </div>
                            </ScrollableAnchor>
                        </Col>
                        <Col xs="12" lg="4">
                            <div className="detail-sidebar-outer">
                                <div>
                                    <InquireAbout
                                        trip_id={trip.trip_id}
                                        slug={trip.slug}
                                        callBack={(trip) => setTrip(trip)}
                                        is_logged_in_user={is_logged_in_user}
                                        is_bookmarked={trip.is_bookmarked}
                                    />
                                </div>
                                {trip.user &&
                                <div className="about-outer-sidebar">
                                    <Row>
                                        <Col>
                                            <h4>About The Host</h4>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <AboutHost data={trip.user}/>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <UserBadges data={trip.user} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <StarRatingMinimal data={trip.user} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <hr className="hr-sidebar" />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Verifications data={trip.user} />
                                        </Col>
                                    </Row>
                                    {!is_logged_in_user &&
                                    <Row>
                                        <Col xs="12">
                                            <div className="profile-btn-wrap">
                                                <Link className="btn-outline btn-block" to={`/profile/${trip.user.user_id}`}>{`View ${trip.user.full_name}'s Profile`}</Link>
                                                <Link className="btn-outline btn-block" to={`/profile/${trip.user.user_id}/all-listings`}>{`View ${trip.user.full_name}'s Listings`}</Link>
                                            </div>
                                        </Col>
                                    </Row>
                                    }
                                </div>
                                }
                            </div>
                        </Col>
                    </Row>
                </div>
                }
            </div>
            {!isEmpty(trip) && trip.user &&
            <RelatedExperience trip_id={trip.trip_id}/>
            }
        </div>
    );
}
