import { request } from "js/helpers/api";

export const lookupServices = {
    getAll,
    getStates
};

function getAll(params = {}){
    return request({
        method:'GET',
        url: 'lookup',
        param: params,
        is_authenticated: false
    });
}

function getStates(country_id){
    return request({
        method:'GET',
        url: `states?country=${ country_id }`,
        is_authenticated: false,
        param: {}
    });
}
