import React from "react"
import { Col } from 'reactstrap';
import "js/components/legos/styles/section-header-module.css"
export default props => (
    <Col className="d-flex justify-content-center">
        <h2 className="header3 section-header">
            {props.HeaderText}
            <div className="line">
                <span className="box-rotate"></span>
            </div>
        </h2>
    </Col>
)