import React, { useState, useContext } from 'react';
import { Row, Col, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import Select from 'react-select';
import { Helmet } from "react-helmet";
import { toast } from 'react-toastify';
import { isEmpty } from 'lodash';
import classnames from 'classnames';

import { supportServices } from 'js/services';
import { NavigationSidebar } from "js/components/web/components/layouts/components/navigation-sidebar";
import { ErrorFormFeedback, RelatedExperience } from 'js/components/common';
import { AuthProvider } from 'js/contexts';

import { LookupContext } from 'js/contexts';

export const ReportIssues = () => {

    const lookup = useContext(LookupContext);

    const defaultState = {
        issue_type : '',
        description : ''
    };
    const [ state, setState ] = useState(defaultState);
    const [ errors, setErrors ] = useState({});
    const { auth } = useContext(AuthProvider);

    const handleChange = ( value, key) => {
        const newState = { ...state };
        newState[key] = value;
        setState(newState);
    }
    const submit = () => {
        let data = { description : state.description , issue_type_id: state.issue_type ? state.issue_type.value : ''  }
        supportServices.submitIssue(data)
        .then((response) => {
            setState(defaultState);
            setErrors('');
            toast.success('Issue Submitted Successfully');
        }).catch((error) => {
            if( error.data && error.data.errors ){
                setErrors(error.data.errors);
            }
            else{
                toast.error('Failed Submit Your Request');
            }
        });
    }

    return(
        <div>
        <Helmet>
            <title>Trip4Trade :: Report Issue</title>
            <meta name="description" content="Report Issues" />
            <meta name="keywords" content="trip4trade,  Report Issues" />
        </Helmet>

        <div className="inner-banner-profile" style={{ backgroundImage: `url(/images/web/global/banners/profile-banner.png)` }}></div>
        <div className="wider-data-frame wider-data-frame--grey pt-0">
            <div className="container">
                <div className="profile-outer-wrapper">
                    <Row className="no-gutters">
                        <Col xs="12" lg="9">
                            <div className="profile-data-section">
                                <div className="profile-data-section--top">
                                    <Row>
                                        <Col xs="12" className="pb-4">
                                            <h3><img src="/images/web/global/side-navigation/report-issues.svg" alt="" style={{ width: `30px` }} /> Report Issue</h3>
                                        </Col>
                                    </Row>

                                </div>
                                <div className="profile-data-section--bottom pt-5 pb-5">
                                    <Row>
                                        <Col xs="12" className="mb-1">
                                            <Form className="invite-form">
                                                <Row>

                                                    <Col xs="12"  className="mb-3">
                                                        <FormGroup>
                                                            <Label for="" >Issue Type</Label>
                                                            <div className="custom-react-select">
                                                                <Select
                                                                    classNamePrefix="react-select"
                                                                    value={ state.issue_type }
                                                                    onChange={(e) => handleChange(e, "issue_type")}
                                                                    options={lookup.issue_type}
                                                                    className={classnames("react-select-container", { 'is-invalid' : errors.issue_type_id })}
                                                                />
                                                                <ErrorFormFeedback field={'issue_type_id'} errors={errors} />
                                                            </div>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col xs="12"  className="mb-3">
                                                        <FormGroup>
                                                            <Label for="" >Please describe issue</Label>
                                                            <Input
                                                                type="textarea"
                                                                value={state.description}
                                                                onChange={(e) => handleChange(e.target.value, "description")}
                                                                invalid={ !isEmpty(errors) && (errors.description) && true }
                                                            />
                                                            <ErrorFormFeedback field={'description'} errors={errors} />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col xs="12" className="d-flex justify-content-end">
                                                        <div>
                                                            <Button className="btn-outline" onClick={submit} >Send Request</Button>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>
                        <NavigationSidebar />
                    </Row>
                </div>
            </div>
        </div>
        <RelatedExperience user_id={auth.user.user_id}/>
    </div>

    );
}
