import React, { useState, useEffect, useContext } from "react";
import { Row, Col, Form, FormGroup, Input} from 'reactstrap';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { toast } from 'react-toastify';
import moment from 'moment';
import dompurify from 'dompurify';

import { history } from 'js/helpers';
import { LookupContext, AuthProvider } from 'js/contexts';
import { ListLoader, Ads } from 'js/components/common';
import { RelatedExperience } from 'js/components/common';

import {
    FacebookShareButton,
    FacebookIcon,
    TwitterShareButton,
    TwitterIcon,
    LinkedinShareButton,
    LinkedinIcon,
    PinterestShareButton,
    PinterestIcon,
    RedditShareButton,
    RedditIcon,
    WhatsappShareButton,
    WhatsappIcon,
    EmailShareButton,
    EmailIcon
} from "react-share";


import { cmsServices } from 'js/services';
export const  BlogDetail = ( props ) => {

    const sanitizer = dompurify.sanitize;
    const lookups = useContext(LookupContext);
    const { auth } = useContext(AuthProvider)
    const searched_article = props.match && props.match.params && props.match.params.article;

    const [ state, setState ] = useState({});
    const [ search, setSearch ] = useState({});
    const [ recent, setRecent] = useState({});
    const [ loading_state, setLoading ] = useState({
        articles: false,
        recent: false,
        ads: false
    })
    const ad_params = {
        limit: 1,
        position: 7,
        type: 'picture'
    }

    const getArticle = ( article ) => {
        setLoading({...loading_state, articles: true});
        cmsServices.getArticle( article )
        .then((response) => {
            if( response ){
                setState(response);
            }
            setLoading({...loading_state, articles: false});
        }).catch((error) => {
            toast.error('Failed To Fetch Experience');
            history.push('/')
        });
    }

    const getRecentArticles =  ( params ) => {
        setLoading({...loading_state, recent: true});
        cmsServices.getRecentArticles( params )
        .then((response) => {
            setRecent(response);
            setLoading({...loading_state, recent: false});
        }).catch(() => {
            toast.error('Failed To Fetch Experiences');
        });
    };

    useEffect(() => {
        getRecentArticles();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if( searched_article  ){
            getArticle(searched_article);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searched_article]);

    const onSearch = ( e ) => {
        setSearch(e.target.value);
    }
    const onKeyPress = (e ) => {
        if (e.key === "Enter") {
            history.push(`/experience/search/${search}`);
        }
    }

    return (

        <div>
            { state &&
            <Helmet>
                <title>{ `${state.title}- Trips4Trade` } </title>
                <meta name="description" content={ state.meta_description } />
                <meta name="keywords" content="" />
            </Helmet>
            }

            <div className="inner-banner blog-detail-banner" style={{backgroundImage: `url(/images/web/global/banners/static-banner.png)`}}>
                <div className="container">
                    <Row>
                    { ( loading_state.articles || !state ) ?
                        <ListLoader/>
                        :
                        <Col xs="12" lg="8" className="blog-detail-head">
                            <h1>{state.title}</h1>
                            <ul className="misc-details">
                                <li><img src="/images/web/blog/date.svg" alt="" /> {state.published && moment(state.published).format("MMM D, YYYY") } </li>
                                <li><img src="/images/web/blog/user.svg" alt="" /> {state.user && state.user.full_name }</li>
                                <li><img src="/images/web/blog/timer.svg" alt="" />{state && state.read_time}</li>
                            </ul>
                            <ul className="blog-tags">
                                { state.feature_tags && state.feature_tags.map( (item, index) => (
                                <li key={index}>
                                    <Link to={"#"}>{ item.label}</Link>
                                </li>
                                ))}
                            </ul>
                        </Col>
                    }
                    </Row>

                </div>
            </div>
            <div className="wider-data-frame wider-data-frame--grey">
                <div className="container">
                    <Row>
                        <Col xs="12" lg="8">
                            <div className="blog-content">
                            { ( loading_state.articles || !state ) ?
                                <ListLoader/>
                            :
                            <>
                                <div dangerouslySetInnerHTML={{__html: sanitizer(state.description) }} ></div>
                                <div className="blog-share-wrap">
                                    <div className="blog-share--head">
                                        <h4><img src="/images/web/blog/social/share.png" alt="" /> Share</h4>
                                    </div>
                                    <div className="blog-share--icons">
                                        <Link to={"#"}><FacebookShareButton url={window.location.href} >  <FacebookIcon size={31} round /> </FacebookShareButton></Link>
                                        <Link to={"#"}><TwitterShareButton url={window.location.href} >  <TwitterIcon size={31} round /> </TwitterShareButton></Link>
                                        <Link to={"#"}><LinkedinShareButton url={window.location.href} >  <LinkedinIcon size={31} round /> </LinkedinShareButton></Link>
                                        <Link to={"#"}><PinterestShareButton url={window.location.href} media={window.location.href}>  <PinterestIcon size={31} round /> </PinterestShareButton></Link>
                                        <Link to={"#"}><RedditShareButton url={window.location.href} >  <RedditIcon size={31} round /> </RedditShareButton></Link>
                                        <Link to={"#"}><WhatsappShareButton url={window.location.href} >  <WhatsappIcon size={31} round /> </WhatsappShareButton></Link>
                                        <Link to={"#"}><EmailShareButton url={window.location.href} >  <EmailIcon size={31} round /> </EmailShareButton></Link>
                                        {/* <Link to={"#"}>
                                            <img src="/images/web/blog/social/sh.png" alt="" />
                                            <span>
                                                <h4>41M</h4>
                                                <small>Shares</small>
                                            </span>
                                        </Link> */}
                                    </div>
                                </div>
                            </>
                            }
                            </div>
                        </Col>
                        <Col xs="12" lg="4">
                            <div className="blog-sidebar">
                            {loading_state.recent  ?
                                <ListLoader/>
                                :
                                <div className="blog-sidebar--card">
                                    <div className="blog-sidebar--search">
                                        <h4>Search</h4>
                                        <Form>
                                            <FormGroup>
                                                <Input type="text" placeholder="Keyword" onChange={onSearch} onKeyPress={onKeyPress} />
                                            </FormGroup>
                                        </Form>
                                    </div>
                                    <hr className="full-width" />
                                    <div className="blog-sidebar--list">
                                        <h3>Blog Categories</h3>
                                        <ul>
                                            { lookups.article_categories && lookups.article_categories.map( (item, index) =>(
                                                <li key={index}><Link to={`/experience/${item.slug}`}>{item.label}</Link></li>
                                            ))}
                                        </ul>
                                    </div>
                                    <hr className="full-width" />
                                    <div className="blog-sidebar--list">
                                        <h3>Most Recent Posts</h3>
                                        <ul>
                                        { recent && recent.data && recent.data.map( ( item, index ) => (
                                            <li  key={index} >
                                                <Link to={`/experience/detail/${item.slug}`}>{item.title}</Link>
                                                <small>{item.published && moment(item.published).format("MMM D, YYYY") }</small>
                                            </li>
                                        ))}
                                        </ul>
                                    </div>
                                </div>
                            }
                                <div className="blog-sidebar--card">
                                    <div className="infographics">
                                        <div className="column">
                                            <div>
                                                <img src="/images/web/global/countries.svg" alt="" />
                                            </div>
                                            <h4>{lookups.trip_countries_count}</h4>
                                            <p>Countries</p>
                                        </div>
                                        <div className="column">
                                            <div>
                                                <img src="/images/web/global/usa.svg" alt="" />
                                            </div>
                                            <h4>{lookups.trip_states_count}</h4>
                                            <p>States</p>
                                        </div>
                                        <div className="column">
                                            <div>
                                                <img src="/images/web/global/group.svg" alt="" />
                                            </div>
                                            <h4>+</h4>
                                            <p className="growing-ev">Growing <br className="d-none d-md-block" />Everyday</p>
                                        </div>
                                    </div>

                                    <hr className="full-width" />
                                    {!auth.is_logged_in &&
                                        <div className="signup-sidebar">
                                            <h5>Join the Trips4Trade Community </h5>
                                            <p>Sign Up to use search filters, receive personal trip suggestions, and find your next bucket list trip!</p>
                                            <Link to={'/auth/signup'} className="btn-solid">Sign Up</Link>
                                        </div>
                                    }
                                </div>

                                {/* <div className="blog-sidebar--card sidebar-advertisement"> */}
                                    <Ads
                                        params={ad_params}
                                        position="blog"
                                    />
                                {/* </div> */}
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
            { state && state.feature_tags &&
                <RelatedExperience feature_tags={state.feature_tags}/>
            }
        </div>


    );
}
