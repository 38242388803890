import React, { useState } from "react";
import {Row, Col, Button, Form, FormGroup, Input, Label, Modal, ModalHeader, ModalBody, Spinner} from 'reactstrap';
import { isEmpty } from 'lodash';
import { Link } from 'react-router-dom'
import { profileServices } from 'js/services';
import { ErrorFormFeedback, NumberInputComponent } from 'js/components/common';
import { toast } from "react-toastify";

export const ReferenceModal = (props) => {

    const { is_open, hide, callBack } = props;
    const [references, setReferences] = useState([{}])
    const [ loading, setLoading ] = useState();
    const [ error, setError ] = useState();

    const handleChange = ( value, key, index ) => {
        let new_references = references.map((item, ind) => {
            if( ind === index) {
                if(key === 'phone') {
                    const { phone, phone_country_code } = value
                    return {...item, phone, phone_country_code}
                }
                else {
                    return {...item, [key]: value}
                }

            }
            else return item
        })
        setReferences(new_references)
    }

    const onAdd = () => {
        setReferences(references => [...references, {}])
    }

    const submit = () => {
        setLoading(true);
        profileServices.addReference({references}, hide)
        .then((response) => {
            setError({});
            toast.success('Reference has been added');
            setLoading(false);
            callBack('ReferenceModal');
        }).catch((error) => {
            setError(error.data && error.data.errors);
            setLoading(false);
        });
    }

    return (
        <div>
            <Modal
                size='lg'
                isOpen={is_open}
                toggle={() => hide()}
                className="tt-modal--profile modal-dialog-centered"
            >
                    <ModalHeader toggle={() => hide()}>List Reference</ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col xs="12">
                                <p className="text">You can list references below that are very familiar with your trips on Trips4Trade. Your friends that give you an endorsement can also list themselves as a reference. These references will provide additional comfort to potential traders.</p>
                            </Col>
                        </Row>
                        <Form className="profile-form">
                            {references.map((item, index) => (
                            <Row key={index}>
                                <Col xs="12" md="4">
                                    <FormGroup>
                                        <Label for="" >Reference's Name</Label>
                                        <Input
                                            type="text"
                                            value={item.full_name || ''}
                                            onChange={  (e) => handleChange(e.target.value, 'full_name', index)}
                                            invalid={ !isEmpty(error) && (error[`references.${index}.full_name`]) && true }
                                        />
                                        <ErrorFormFeedback field={`references.${index}.full_name`} errors={error} />
                                    </FormGroup>
                                </Col>
                                <Col xs="12" md="4">
                                    <FormGroup>
                                        <Label for="" >Email Address</Label>
                                        <Input
                                            type="email"
                                            value={item.email || ''}
                                            onChange={  (e) => handleChange(e.target.value, 'email', index)}
                                            invalid={ !isEmpty(error) && (error[`references.${index}.email`]) && true }
                                        />
                                        <ErrorFormFeedback field={`references.${index}.email`} errors={error} />
                                    </FormGroup>
                                </Col>
                                <Col xs="12" md="4">
                                    <FormGroup>
                                        <Label for="" >Phone</Label>
                                        <NumberInputComponent
                                            phone= {`+${item.phone_country_code}${item.phone}`}
                                            onChange={(e) => handleChange(e, 'phone', index)}
                                        />
                                        <ErrorFormFeedback field={`references.${index}.phone`} errors={error} is_invalid={true}/>
                                        <ErrorFormFeedback field={`references.${index}.phone_country_code`} errors={error} is_invalid={true}/>
                                    </FormGroup>
                                </Col>
                            </Row>
                            ))}
                            <Row>
                                <Col>
                                    <Link className="add-reference" onClick={() => onAdd()}>+ Add Another Reference</Link>
                                </Col>
                            </Row>
                        </Form>
                        <div className="d-flex justify-content-center py-4">
                            <Button
                                className="btn-outline text-capitalize"
                                onClick={submit}
                                disabled={loading}
                            >
                                { loading ? <Spinner size="sm" /> : 'Save' }
                            </Button>
                        </div>
                    </ModalBody>
                </Modal>
        </div>
    );
}
