import React from 'react';
import {
    Row,
    Col,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
} from 'reactstrap';

export const ErrorModal = (props) => {
    const { is_open, hide, callBack} = props;

    return (
        <div>
            <Modal
                isOpen={is_open}
                toggle={() => hide()}
                className="tt-modal--admin modal-dialog-centered"
            >
                <ModalHeader toggle={() => hide()}>&nbsp;</ModalHeader>
                <ModalBody className="error-custom-padding">
                    <Row>
                        <Col xs="12" md="12">
                            <div className="error-content">
                                <i>
                                    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M25 50C38.8071 50 50 38.8071 50 25C50 11.1929 38.8071 0 25 0C11.1929 0 0 11.1929 0 25C0 38.8071 11.1929 50 25 50Z" fill="#EC0000"/>
                                        <path d="M15.5691 34.4525C15.9059 34.7897 16.3615 34.979 16.8365 34.979C17.3114 34.979 17.7671 34.7897 18.1039 34.4525L24.4678 27.9519L30.8317 34.4525C31.1798 34.803 31.6519 35 32.1441 35C32.6363 35 33.1084 34.803 33.4565 34.4525C33.8045 34.1019 34 33.6264 34 33.1306C34 32.6348 33.8045 32.1593 33.4565 31.8088L27.0026 25.3987L33.4565 18.9886C33.7509 18.6422 33.9048 18.1966 33.8873 17.7409C33.8699 17.2852 33.6823 16.8529 33.3621 16.5304C33.042 16.2079 32.6128 16.019 32.1604 16.0013C31.7079 15.9838 31.2655 16.1388 30.9216 16.4354L24.4678 22.8455L18.1039 16.4354C17.76 16.1388 17.3176 15.9838 16.8651 16.0013C16.4127 16.019 15.9835 16.2079 15.6634 16.5304C15.3432 16.8529 15.1556 17.2852 15.1382 17.7409C15.1207 18.1966 15.2745 18.6422 15.5691 18.9886L21.933 25.3987L15.5691 31.8088C15.3894 31.9781 15.2463 32.1829 15.1484 32.4102C15.0505 32.6376 15 32.8828 15 33.1306C15 33.3784 15.0505 33.6237 15.1484 33.851C15.2463 34.0784 15.3894 34.2831 15.5691 34.4525Z" fill="white"/>
                                    </svg>
                                </i>
                                <p>{ props.body }</p>
                                <div>
                                <Button
                                    color="danger"
                                    className="btn-outline--red"
                                    onClick={() => callBack ? callBack() : hide()}
                                >
                                    { props.button_name ? props.button_name : 'Ok' }
                                </Button>
                                </div>
                            </div>

                        </Col>
                        </Row>
                    </ModalBody>
            </Modal>
        </div>
    );
}
