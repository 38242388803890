import React, { useState, useEffect, useContext } from 'react';
import { Row, Col, Modal, ModalHeader, ModalBody, Button, Form, FormGroup, Label, Spinner, CustomInput, ModalFooter, Input } from 'reactstrap';
import { isEmpty, forEach } from 'lodash';
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import moment from 'moment';

import { tripsServices, tradeServices } from 'js/services';
import { PaginationComponent, ListLoader } from 'js/components/common';
import { AuthProvider } from 'js/contexts';
import { useParamChange } from 'js/hooks';
import { ErrorFormFeedback } from 'js/components/common';

export const AcceptTradeModal = (props) => {
    const { is_open, hide } = props;
    const [trips, setTrips] = useState({});
    const [selected_trips, setSelectedTrips] = useState({});
    const [inquired_trip, setInquiredTrip] = useState({});
    const [loading, setLoading] = useState({
        list_loading: false,
        button_loading: false
    });
    const { auth } = useContext(AuthProvider);
    const [description, setDescription] = useState('');
    const [date_conditions, setDateConditions] = useState({});
    const [errors, setErrors] = useState({});

    const user_id = auth && auth.user && auth.user.user_id;

    const apiCalls = {
        getAllTrips: (params) => {
            setLoading((loading) => ({
                ...loading,
                list_loading: true
            }));
            tripsServices.getAll(params, hide).then((response) => {
                setLoading((loading) => ({
                    ...loading,
                    list_loading: false
                }));

                setTrips(response);

            }).catch(() => {
                setLoading((loading) => ({
                    ...loading,
                    list_loading: false
                }));
            });
        },
        acceptTrade: (params) => {
            setLoading((loading) => ({
                ...loading,
                button_loading: true
            }));
            tradeServices.update(params, props.trade_id, hide).then(() => {
                apiCalls.inquiryUpdateStatus(props.inquiry_id, { inquiry_status: 'accepted' })
            }).catch((err) => {
                let errors = err.data && err.data.errors;
                setLoading((loading) => ({
                    ...loading,
                    button_loading: false
                }));
                setErrors(errors);
            });
        },
        inquiryUpdateStatus: (inquiry_id, params) => {
            tripsServices.inquiryUpdate(inquiry_id, params).then(() => {
                setLoading((loading) => ({
                    ...loading,
                    button_loading: false
                }));
                props.callBack();
                toast.success('Created Successfully');
                hide();
            })
                .catch(() => {
                    setLoading((loading) => ({
                        ...loading,
                        button_loading: false
                    }));
                })
        },
    }
    // eslint-disable-next-line
    const [params, onParamsChange] = useParamChange(apiCalls.getAllLeads);

    useEffect(() => {
        if (props && props.guest_user_id) {
            let details = {
                trip_trade_id: props.trip_trade_id,
                guest_user_id: props.guest_user_id,
                arrival_time: props.arrival_time,
                departure_time: props.departure_time
            }
            apiCalls.getAllTrips({ user: props.guest_user_id, visibility: 'public', start_date: moment().format('YYYY-MM-DD') });

            setInquiredTrip({
                ...inquired_trip,
                ...details
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSelectedTrip = (trip) => {
        setSelectedTrips((selectedTrip) => ({
            ...selectedTrip,
            trip_id: trip.trip_id,
            guest_user_id: user_id,
        }))

        setDateConditions((date_conditions) => ({
            ...date_conditions,
            trip_start_date: moment(trip.trip_start_date),
            trip_end_date: moment(trip.trip_end_date)
        }));
    }

    const acceptTrade = () => {
        let details = {};
        let trips = [];

        trips[0] = { ...inquired_trip };
        trips[1] = { ...selected_trips };

        details.trip_trades = trips;

        if (!isEmpty(description)) {
            details.description = description;
        }

        (async function asyncFeeder() {
            await dateFormater(details).then((response) => {
                apiCalls.acceptTrade(response);
            })
        })();
    }

    const dateFormater = (data) => {
        return new Promise((resolve) => {
            if (!isEmpty(data)) {
                data.trip_trades.map((item) => {
                    forEach(item, (value, key) => {
                        if (key === 'arrival_time' || key === 'departure_time') {
                            item[key] = moment(value).format('YYYY-MM-DD HH:mm:ss')
                        }
                    })
                    return item;
                })
            }
            resolve(data);
        })
    }

    return (
        <div>
            <Modal isOpen={is_open} toggle={() => hide()} className="tt-modal--admin modal-dialog-centered accept-trade-modal">
                <ModalHeader toggle={() => hide()}>Create Trade</ModalHeader>
                {
                    loading['list_loading'] ?
                        <ModalBody>
                            <ListLoader />
                        </ModalBody>
                        :
                        trips && trips.data && trips.data.length > 0 ?
                            <ModalBody>
                                <Row>
                                    <Col xs="12">
                                        <h4><span>Select the Trip That you wish to Join</span></h4>
                                    </Col>
                                </Row>
                                <Form className="tt-admin-form">
                                    <div className="tt-admin-form-inner p-0">
                                        <Row form>
                                            <Col xs="12">
                                                {
                                                    !isEmpty(trips) && trips.data && trips.data.map((trip, index) => (
                                                        <div className="owner-data-wrapper" key={`trip_${index}`}>
                                                            <div className="left-section">
                                                                <img
                                                                    src={(trip.trip_media && trip.trip_media[0] && trip.trip_media[0].attachment)?
                                                                        (trip.trip_media[0].attachment.thumbnail_url ?
                                                                            (trip.trip_media[0].attachment.thumbnail_url)
                                                                            :
                                                                            (trip.trip_media[0].attachment.attachment_url)
                                                                        )
                                                                        : ''
                                                                    }
                                                                    alt=""
                                                                />
                                                                <h4>{trip.title}</h4>
                                                                <h5>{trip.description ? trip.description : ''}</h5>
                                                            </div>
                                                            <div className="right-section">
                                                                <CustomInput
                                                                    type="radio"
                                                                    id={`trip_${trip.trip_id}`}
                                                                    name="customRadio"
                                                                    label=""
                                                                    onChange={() => onSelectedTrip(trip)}
                                                                />
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </Col>
                                            {!isEmpty(trips) && trips.meta &&
                                                <PaginationComponent page={trips.meta} onChange={(page) => onParamsChange('page', page)} />
                                            }
                                        </Row>
                                    </div>
                                </Form>
                                <Form className="profile-form">
                                    <Row>
                                        <ErrorFormFeedback field={'trip_trades.1.trip_id'} errors={errors} is_invalid={true} />
                                        <Col xs="12" md="6">
                                            <FormGroup>
                                                <Label for="" >Arrival</Label>
                                                <div className="tt-custom-react-select">
                                                    <DatePicker
                                                        className='custom-date-picker form-control'
                                                        selected={selected_trips.arrival_time ? selected_trips.arrival_time : ''}
                                                        onChange={
                                                            (e) => {
                                                                setSelectedTrips({ ...selected_trips, 'arrival_time': e });
                                                            }
                                                        }
                                                        showYearDropdown
                                                        dateFormat="MMM d, yyyy"
                                                        minDate={date_conditions.trip_start_date ? date_conditions.trip_start_date.toDate() : ''}
                                                        maxDate={date_conditions.trip_end_date ? date_conditions.trip_end_date.toDate() : ''}

                                                    />
                                                    <ErrorFormFeedback field={'trip_trades.1.arrival_time'} errors={errors} is_invalid={true}/>
                                                </div>
                                            </FormGroup>
                                        </Col>
                                        <Col xs="12" md="6">
                                            <FormGroup>
                                                <Label for="" >Departure</Label>
                                                <div className="tt-custom-react-select">
                                                    <DatePicker
                                                        className='custom-date-picker form-control'
                                                        selected={selected_trips.departure_time ? selected_trips.departure_time : ''}
                                                        onChange={
                                                            (e) => {
                                                                setSelectedTrips({ ...selected_trips, 'departure_time': e });
                                                            }
                                                        }
                                                        showYearDropdown
                                                        dateFormat="MMM d, yyyy"
                                                        minDate={date_conditions.trip_start_date ? date_conditions.trip_start_date.toDate() : ''}
                                                        maxDate={date_conditions.trip_end_date ? date_conditions.trip_end_date.toDate() : ''}
                                                    />
                                                    <ErrorFormFeedback field={'trip_trades.1.departure_time'} errors={errors} is_invalid={true} />
                                                </div>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs="12" md="6">
                                            <FormGroup>
                                                <Label for="" >Description</Label>
                                                <Input type="text"
                                                    onChange={
                                                        (e) => {
                                                            setDescription(e.target.value);
                                                        }
                                                    }
                                                    value={description}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Form>
                            </ModalBody>
                            :
                            <ModalBody>
                                <Form className="tt-admin-form d-flex justify-content-center align-items-center">
                                    <h6 className="error-message">Sorry!. {props.participant_name ? props.participant_name : ''} doesn't have any upcoming trips.</h6>
                                </Form>
                            </ModalBody>
                }
                {
                    !isEmpty(trips) && !isEmpty(trips.data) &&
                    <ModalFooter>
                        <Button color="primary" className="btn-outline-grey" onClick={() => hide()}>Cancel</Button>
                        <Button
                            color="primary"
                            className="add-new-btn"
                            onClick={() => acceptTrade()}
                        >
                            {
                                loading['button_loading'] ? <Spinner size="sm" /> : 'Create'
                            }
                        </Button>
                    </ModalFooter>
                }
            </Modal>
        </div>
    );
}
