import React, { useState } from "react";
import {
    Row,
    Col,
    Button,
    Form,
    FormGroup,
    Label,
    Input,
    Spinner
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { membershipPlanService } from 'js/services';
import { useEffect } from "react";
import { useModal } from 'js/hooks';
import { ListLoader, ErrorFormFeedback } from "js/components/common";
import { isEmpty, forEach } from 'lodash';
import { toast } from "react-toastify";
import { history } from 'js/helpers';




export const EditMembershipPlan = (props) => {
    const { match } = props;
    const [membership_plan, setMembershipPlan] = useState({});
    const [loading, setLoading] = useState({get: false, update: false});
    const [createErrors, setErrors] = useState({});
    const [state, setState] = useState({
        title: '',
    });
    const [show] = useModal();


    const apiCalls = {
        getMembershipPlan: (membership_plan_id) => {
            setLoading({...loading, get: true});
            membershipPlanService.get(membership_plan_id).then((response) => {
                setLoading({...loading, get: false});
                setMembershipPlan(response);
            }).catch((err) => {
                setLoading(({...loading, get: false}));
                setErrors(err);
                console.log(createErrors);
                history.push('/admin')
            });
        },
        updateMembershipPlan: () => {
            setLoading({...loading, update: true})
            let details = { ...membership_plan };
            membershipPlanService.update(details, membership_plan.membership_plan_id)
            .then(res => {
                setErrors({});
                setLoading({...loading, update: false});
                setMembershipPlan(res)
                toast.success('Membership plan has been updated');
            }).catch(err => {
                err&& err.data && err.data.errors && setErrors(err.data.errors);
                setLoading({...loading, update: false});
            })
        }
    }
    const addOrDeleteButton = (ind) => {
        let current_contents = membership_plan.content;
        if (ind === 0) {
            current_contents.push('');
            setMembershipPlan({
                ...membership_plan,
                content: current_contents
            });
        }
        else {
            let filterd_content = current_contents.filter((content, index) => index !== ind);
            setMembershipPlan({
                ...membership_plan,
                content: filterd_content
            });
        }
    }

    const onChangeContent = (value, ind) => {
        let current_contents = membership_plan.content;

        forEach(current_contents, (item, index) => {
            if (ind === index) {
                current_contents[index] = value;
            }
        })

        setMembershipPlan({
            ...membership_plan,
            content: [...current_contents]
        });
    }

    useEffect(() => {
        if (match && match.params && match.params.membership_plan_id) {
            apiCalls.getMembershipPlan(match.params.membership_plan_id);
            setState({
                ...state,
                membership_plan_id: match.params.membership_plan_id
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <div>
            {!isEmpty(membership_plan) &&
                <div className="generic-page-header">
                    <div className="header-data-section justify-content-start">
                        <Link
                            to={"/admin/membership-plans"}
                            className="back-btn"
                        >
                            <img src="/images/admin/global/back.svg" alt="" />
                        </Link>
                        <h2 className="page-head my-0">{`Edit ${membership_plan.label}`}</h2>
                    </div>
                    <div className="header-data-section justify-content-end">
                        <Button
                            color="primary"
                            className="add-new-btn mr-3"
                            onClick={() => apiCalls.updateMembershipPlan()}
                            disabled={loading.get || loading.update}
                        >
                        {loading.update ? <Spinner size="sm"/> : 'Save Changes'}</Button>
                    </div>
                </div>
            }
            <div>
                <div className="tt-admin-card membership-card">
                    <div className="tt-admin-card--data tt-admin-card--data_membership ">
                    {loading.get ?
                        <ListLoader />
                        :
                        <Row>
                            <Col xs="12">
                                <Form className="tt-admin-form">
                                    <div className="tt-admin-form-inner p-0">
                                        <Row form>
                                            <Col xs="12" md="8">
                                                <FormGroup>
                                                    <Label for="">Plan Title</Label>
                                                    <Input
                                                        type="text"
                                                        name="label"
                                                        onChange={(e) => setMembershipPlan({ ...membership_plan, [e.target.name]: e.target.value })}
                                                        value={membership_plan.label ? membership_plan.label : ''}
                                                        invalid={!isEmpty(createErrors) && (createErrors.label)
                                                            && true
                                                        }
                                                    />
                                                    <ErrorFormFeedback field={'label'} errors={createErrors} />
                                                </FormGroup>
                                            </Col>
                                            <Col xs="12" md="4">
                                                <FormGroup>
                                                    <Label for="">Cost</Label>
                                                    <Input
                                                        type="text"
                                                        name="price"
                                                        placeholder='$'
                                                        onChange={(e) => setMembershipPlan({ ...membership_plan, [e.target.name]: e.target.value })}
                                                        value={membership_plan.price ? membership_plan.price : ''}
                                                        invalid={!isEmpty(createErrors) && (createErrors.price)
                                                            && true
                                                        }
                                                    />
                                                    <ErrorFormFeedback field={'price'} errors={createErrors} />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        {
                                            !isEmpty(membership_plan.content) &&
                                            membership_plan.content.map((content, ind) => (
                                                <Row form key={`content_modal_${ind}`}>
                                                    <Col xs="12" md="10">
                                                        <FormGroup>
                                                            {ind === 0 &&
                                                            <label>Content</label>
                                                            }
                                                            <Input
                                                                type="text"
                                                                name="label"
                                                                onChange={(e) => {
                                                                    onChangeContent(e.target.value, ind)
                                                                }
                                                                }
                                                                value={membership_plan.content && membership_plan.content[ind] ? membership_plan.content[ind] : ''}
                                                                invalid={!isEmpty(createErrors) && (createErrors['content.0'])
                                                                    && true
                                                                }
                                                            />
                                                            <ErrorFormFeedback field={'content.0'} errors={createErrors} />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col xs="12" md="2">
                                                        <FormGroup>
                                                            {ind === 0 &&
                                                                <label className="w-100">&nbsp;</label>
                                                            }
                                                            <Button
                                                                color="primary"
                                                                className="add-new-btn"
                                                                onClick={() => {
                                                                    addOrDeleteButton(ind)
                                                                }}
                                                            >
                                                                {
                                                                    ind === 0 ?
                                                                        <i className="mr-0">
                                                                            <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M5.03027 7.77295H0.171875V5.6001H5.03027V0.619629H7.22754V5.6001H12.0981V7.77295H7.22754V12.729H5.03027V7.77295Z" fill="#fff" />
                                                                            </svg>
                                                                        </i>
                                                                        : '-'
                                                                }
                                                            </Button>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            ))
                                        }
                                        <Row form>
                                            <Col xs="12" md="6">
                                                <FormGroup>
                                                    <Label for="">Expiry Days</Label>
                                                    <Input
                                                        type="number"
                                                        name="expiry_days"
                                                        onChange={(e) => setMembershipPlan({ ...membership_plan, [e.target.name]: e.target.value })}
                                                        value={membership_plan.expiry_days ? membership_plan.expiry_days : ''}
                                                        invalid={!isEmpty(createErrors) && (createErrors.expiry_days)
                                                            && true
                                                        }
                                                    />
                                                    <ErrorFormFeedback field={'expiry_days'} errors={createErrors} />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col xs="12">
                                                <FormGroup>
                                                    <Label for="">Description</Label>
                                                    <Input
                                                        type="textarea"
                                                        name="description"
                                                        onChange={(e) => setMembershipPlan({ ...membership_plan, [e.target.name]: e.target.value })}
                                                        value={membership_plan.description ? membership_plan.description : ''}
                                                        invalid={!isEmpty(createErrors) && (createErrors.description)
                                                            && true
                                                        }
                                                    />
                                                    <ErrorFormFeedback field={'description'} errors={createErrors} />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </div>
                                </Form>
                            </Col>
                            <Col xs="12" className='pb-3'>
                                <div className="membership-card-outer">
                                    <Row className="justify-content-between align-items-center mb-2">
                                        <Col xs="12" lg="5">
                                            <h4>Membership Upgrades</h4>
                                        </Col>
                                        <Col xs="12" lg="5" className="text-right">
                                            <Button
                                                color="primary"
                                                className="add-new-btn"
                                                onClick={() =>
                                                    show('AddPlanModal',
                                                        {
                                                            title: 'Add Membership Upgrade',
                                                            callBack: () => apiCalls.getMembershipPlan(state.membership_plan_id),
                                                            type: 'membership_plan_upgrades',
                                                            data: membership_plan,
                                                            membership_plan_id: state.membership_plan_id
                                                        })
                                                }
                                            >
                                                <i>
                                                    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M5.03027 7.77295H0.171875V5.6001H5.03027V0.619629H7.22754V5.6001H12.0981V7.77295H7.22754V12.729H5.03027V7.77295Z" fill="#fff" />
                                                    </svg>
                                                </i>
                                                Add Plan
                                            </Button>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                            {
                                membership_plan && !isEmpty(membership_plan.membership_plan_upgrades) &&
                                membership_plan.membership_plan_upgrades.map((item, index) => (
                                    <Col xs="12" className="mt-1 mb-1" key={`membership_plan_upgrades_${index}`}>
                                        <div className="membership-list-card">
                                            <div className="membership-draggable">
                                                <img src="/images/admin/global/draggable.svg" alt="" />
                                            </div>
                                            <div className="membership-data">
                                                <h5>{item.label ? item.label : ''} ( <Link to={"#"} onClick={() => {
                                                    show('AddEditMembershipPlanUpgradeModal',
                                                        {
                                                            title: 'Edit Membership Upgrade',
                                                            callBack: () => apiCalls.getMembershipPlan(state.membership_plan_id),
                                                            membership_plan_upgrade_id: item.membership_plan_upgrade_id
                                                        })
                                                }}>Edit</Link> )</h5>
                                                <p>{item.description ? item.description : ''}</p>
                                            </div>
                                            <div className="membership-amount">
                                                + ${item.price ? item.price : ''}
                                            </div>
                                        </div>
                                    </Col>
                                ))
                            }
                            <Col xs="12">
                                <div className="membership-card-outer">
                                    <Row className="justify-content-between align-items-center mt-2 mb-4">
                                        <Col xs="12" lg="5">
                                            <h4>Credibility Upgrades</h4>
                                        </Col>
                                        <Col xs="12" lg="5" className="text-right">
                                            <Button
                                                color="primary"
                                                className="add-new-btn"
                                                onClick={() =>
                                                    show('AddPlanModal',
                                                        {
                                                            ...state,
                                                            title: 'Add Credibility Upgrade',
                                                            callBack: () => apiCalls.getMembershipPlan(match.params.membership_plan_id),
                                                            membership_plan_id: state.membership_plan_id ? state.membership_plan_id : '',
                                                            data: membership_plan,
                                                            type: 'credibility_upgrades'
                                                        })
                                                }
                                            >
                                                <i>
                                                    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M5.03027 7.77295H0.171875V5.6001H5.03027V0.619629H7.22754V5.6001H12.0981V7.77295H7.22754V12.729H5.03027V7.77295Z" fill="#fff" />
                                                    </svg>
                                                </i>
                                                Add Upgrade</Button>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>

                            {   !isEmpty(membership_plan.credibility_upgrades) &&
                                membership_plan.credibility_upgrades.map((item, index) => (
                                    <Col xs="12" className="mt-1 mb-1" key={`credibility_upgrades_${index}`}>
                                        <div className="membership-list-card">
                                            <div className="membership-draggable">
                                                <img src="/images/admin/global/draggable.svg" alt="" />
                                            </div>
                                            <div className="membership-data">
                                                <h5>{item.label ? item.label : ''} ( <Link to={"#"} onClick={() => {
                                                    show('AddEditCredibilityUpgradeModal',
                                                        {
                                                            ...state,
                                                            title: 'Edit Credibility Upgrade',
                                                            callBack: () => apiCalls.getMembershipPlan(match.params.membership_plan_id),
                                                            credibility_upgrade_id: item.credibility_upgrade_id ? item.credibility_upgrade_id : '',
                                                        })
                                                }}>Edit</Link> )</h5>
                                                <p>{item.description ? item.description : ''}</p>
                                            </div>
                                            <div className="membership-amount">
                                                + ${item.price ? item.price : ''}
                                            </div>
                                        </div>
                                    </Col>
                                ))
                            }
                        </Row>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}


