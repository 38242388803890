import React, { useState, useEffect, useContext } from "react";
import { Row, Col, Nav, NavItem, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import ScrollableAnchor from 'react-scrollable-anchor'
import { configureAnchors } from 'react-scrollable-anchor'
import { concat, isEmpty } from 'lodash';

import { ReviewSlider } from "js/components/legos/reviewSlider";
import ReferenceCard from "js/components/legos/referenceCard";
import EndorsementCard from "js/components/legos/endorsementCard";


import { NavigationSidebar } from "js/components/web/components/layouts/components/navigation-sidebar";
import { tripsServices, authServices } from 'js/services';
import { ListLoader, TripListing, RelatedExperience } from 'js/components/common';
import { AuthProvider } from 'js/contexts';
import { useModal } from 'js/hooks';
import moment from "moment";


configureAnchors({ offset: -60 });

export const MyProfile = () => {

    const [loading, setLoading] = useState(false)
    const [trips, setTrips] = useState({});

    const { auth, setAuth } = useContext(AuthProvider);
    const [show, hide] = useModal();

    const getAllTrips = (params, mode) => {
        setLoading(true)
        tripsServices.getAll(params, params.category).then(response => {
            if (mode === 'append') {
                response.data = concat(trips.data, response.data);
            }
            setTrips(response);
            setLoading(false)
        }).catch(error => {
            console.log(error)
        })
    }


    useEffect(() => {
        if (auth && auth.user && auth.user.user_id) {
            getAllTrips({ user: auth.user.user_id })
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onCallBack  = (modal) => {

        authServices.getProfile().then((response) => {
            setAuth({ ...auth, user: { ...response } });
            hide(modal)
        });

    }

    const user = auth && auth.user;
    const user_id = auth && auth.user && auth.user.user_id;

    return (
        <div>
            <Helmet>
                <title>Trip4Trade :: My Profile</title>
                <meta name="description" content="My Profile page desription" />
                <meta name="keywords" content="trip4trade, My Profile" />
            </Helmet>

            <div className="inner-banner-profile" style={{ backgroundImage: `url(/images/web/global/banners/profile-banner.png)` }}>

            </div>
            <div className="wider-data-frame wider-data-frame--grey pt-0">
                <div className="container">
                    <div className="profile-outer-wrapper">
                        <Row className="no-gutters">
                            <Col xs="12" lg="9">
                                <div className="profile-data-section">
                                    <div className="profile-data-section--top">
                                        <Row>
                                            <Col xs="12" md="8">
                                                <h3>{user && user.full_name ? user.full_name : ''}</h3>
                                                <p className="member-section">
                                                    <img src="/images/web/global/profile/member-since.svg" alt="" /> <b>Member Since:</b>{user.created_at ? moment(user.created_at).format('MMMM, YYYY') : ''}
                                                </p>
                                                { user.address &&
                                                    <p className="location-section">
                                                        <img src="/images/web/global/map.svg" alt="" /> <b>{user.address}</b>
                                                    </p>
                                                }
                                            </Col>
                                            <Col xs="12" md="4" className="d-flex justify-content-end align-items-start">
                                                <Link
                                                    to={"#"}
                                                    onClick={() =>
                                                        show('EditProfileModal')
                                                    }
                                                    className="btn btn-outline text-capitalize"
                                                >
                                                    <i>
                                                        <svg width="18px" height="18px" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="m14.313 17.822h-13.483c-0.4582 0-0.82978-0.3716-0.82978-0.8298v-13.483c0-0.4582 0.37158-0.82978 0.82978-0.82978h6.7414c0.4582 0 0.82978 0.37158 0.82978 0.82978s-0.37158 0.82978-0.82978 0.82978h-5.9118v11.824h11.824v-5.9119c0-0.45815 0.3716-0.82974 0.8298-0.82974 0.4581 0 0.8297 0.37159 0.8297 0.82974v6.7415c-1e-4 0.4582-0.3716 0.8298-0.8297 0.8298z" fill="#E59200" />
                                                            <path d="m17.579 2.2104-1.9374-1.9374c-0.3237-0.3256-0.8505-0.3256-1.1741 0l-1.7341 1.7341c-0.0062 0.0041-0.0125 0.01034-0.0187 0.01871h-0.0021l-6.7725 6.7748c-0.07467 0.07467-0.13491 0.16378-0.17626 0.2593l-1.4519 3.3893c-0.13277 0.3112-0.06219 0.6721 0.17626 0.9127 0.15968 0.1597 0.37123 0.2427 0.58705 0.2427 0.10996 0 0.22188-0.0206 0.32774-0.0663l3.3874-1.45c0.09749-0.0415 0.18463-0.1015 0.25931-0.1762l8.5295-8.5295c0.3234-0.32365 0.3234-0.8485-2e-4 -1.1721zm-9.5896 8.4156-1.3359 0.5704 0.57243-1.3359 5.9284-5.9263 0.7633 0.76331-5.9283 5.9284zm6.0447-7.5734 0.7633 0.76544 1.0204-1.0226-0.7633-0.76331-1.0204 1.0205z" clipRule="evenodd" fill="#E59200" fillRule="evenodd" />
                                                        </svg>
                                                    </i>
                                                    Edit Profile</Link>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs="12">
                                                <Nav className="trip-details--sub-nav">
                                                    <NavItem >
                                                        <a href={"#biography"}>Biography</a>
                                                    </NavItem>
                                                    <NavItem>
                                                        <a href={"#listings"}>Listings</a>
                                                    </NavItem>
                                                    <NavItem>
                                                        <a href={"#trip-reviews"}>Trip Reviews</a>
                                                    </NavItem>
                                                    <NavItem>
                                                        <a href={"#endorsements"}>Endorsements & References</a>
                                                    </NavItem>

                                                </Nav>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className="profile-data-section--bottom">
                                        <Row>
                                            <Col xs="12">
                                                <ScrollableAnchor id={'biography'}>
                                                    <div>
                                                        <Row>
                                                            <Col xs="12">
                                                                <h3 className="header5"><img src="/images/web/global/profile/biography.svg" alt="" />Biography</h3>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xs="12">
                                                                <p>
                                                                    {user && user.biography ? user.biography : ''}
                                                                </p>
                                                            </Col>
                                                        </Row>

                                                    </div>
                                                </ScrollableAnchor>
                                                <ScrollableAnchor id={'listings'}>
                                                    <div>
                                                        <Row>
                                                            <Col xs="12">
                                                                <h3 className="header5"><img src="/images/web/global/profile/listings.svg" alt="" />Listings</h3>
                                                            </Col>
                                                        </Row>
                                                        {loading ?
                                                            <ListLoader />
                                                            :
                                                            <>
                                                                {trips && !isEmpty(trips) && trips.data &&
                                                                    <TripListing
                                                                        class_name="col-md-6 col-xs-12"
                                                                        trips={trips}
                                                                        hide_load_mode = { true }
                                                                        disable_bookmark={true}
                                                                        enable_edit={ true }
                                                                    />
                                                                }
                                                            </>
                                                        }
                                                        <Row>
                                                            <Col xs="12" md="6" className="d-flex justify-content-start mt-2 mb-4">
                                                                <Link to={"/add-listing"} className="btn btn-outline">Add New Listing</Link>
                                                            </Col>
                                                            {trips.data && trips.data.length > 0 &&
                                                            <Col xs="12" md="6" className="d-flex justify-content-end mt-2 mb-4">
                                                                <Link to={"/my-listings"} className="btn btn-outline">View All Listings</Link>
                                                            </Col>
                                                            }
                                                        </Row>

                                                    </div>
                                                </ScrollableAnchor>
                                                <ScrollableAnchor id={'trip-reviews'}>
                                                    <div>
                                                        <Row>
                                                            <Col xs="12">
                                                                <h3 className="header5"><img src="/images/web/global/profile/trip-reviews.svg" alt="" />Trip Reviews</h3>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                                <ReviewSlider user_id={user_id}/>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </ScrollableAnchor>
                                                <ScrollableAnchor id={'endorsements'}>
                                                    <div>
                                                        <Row>
                                                            <Col xs="12">
                                                                <h3 className="header5"><img src="/images/web/global/profile/guard.svg" alt="" />Endorsements & References</h3>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xs="12">
                                                                <p>
                                                                    Endorsements and References provide additional verification for each host. Members and Nonmembers can provide endorsements for trip accuracy. Members can also request contact info for references from the host if they aren't listed below.
                                                                    </p>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            {user.endorsements && user.endorsements && user.endorsements.map((item, index) => (
                                                                <React.Fragment key={index}>
                                                                    { item.endorsement_status_id === 20 &&
                                                                    <Col xs="12"  key={index}>
                                                                        <EndorsementCard
                                                                            data={item}
                                                                        />
                                                                    </Col>
                                                                    }
                                                                </React.Fragment>
                                                            ))}
                                                            <Col xs="12" className="d-flex justify-content-end mt-2 mb-4">
                                                                <Button
                                                                    className="btn btn-outline"
                                                                    onClick={ () =>
                                                                        show('EndorsmentModal', {
                                                                            callBack: (modal) => onCallBack(modal),
                                                                        })
                                                                    }
                                                                >
                                                                    Request Endorsement
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                        { user.references && user.references  && user.references.map( (item, index) => (
                                                            <Col xs="12" md="6" key={index}>
                                                                <ReferenceCard
                                                                    refImage="images/web/global/logo-icon.svg"
                                                                    refUser={item.full_name}
                                                                    refEmail={item.email}
                                                                />
                                                            </Col>
                                                        ))}
                                                        </Row>
                                                        <Row>
                                                            <Col xs="12" className="d-flex justify-content-end mt-2 mb-5">
                                                                <Button
                                                                    onClick={() =>
                                                                        show('ReferenceModal',{
                                                                            callBack: (modal) => onCallBack(modal),
                                                                        })
                                                                    }
                                                                    className="btn btn-outline"
                                                                >Add Reference
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </ScrollableAnchor>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Col>
                            <NavigationSidebar user_id={user_id} is_logged_in_user={true}/>
                        </Row>
                    </div>
                </div>
            </div>
            {!loading &&
            <RelatedExperience user_id={user_id}/>
            }
        </div>
    );
}


