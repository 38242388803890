import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Button, Form, FormGroup, Label, Input, CustomInput, Spinner } from 'reactstrap';
import { Link } from 'react-router-dom';
import { toast } from "react-toastify";
import { Container, Draggable } from 'react-smooth-dnd';
import arrayMove from 'array-move';

import { cmsServices, attachmentServices } from 'js/services';
import { EditorComponent, ListLoader } from 'js/components/common';
import { history } from 'js/helpers';

export const HowItWorksCMS = (props) => {

    const inputRef = useRef(null);

    const [ state, setState] = useState({
        content: {
            page_title: '',
            steps : [],
            real_example : {
                quote : '',
                images : [],
                section_title : ''
            },
            popular_questions : {
                section_title : '',
                questions : [],
            },
            trade_resources: {
                section_title : '',
                resources : [],
            }
        },
        is_footer_link : false,
        loading: false
    });
    const [loadingButton, setLoadingButton] = useState(false);

    const getStaticPage = () => {
        setState({...state, loading: true})
        cmsServices.getStaticPage('how-it-works')
        .then((response) => {
            setState({ ...state, ...response, loading: false })
        }).catch((err) => {
            // setState({ ...state, 'error' : err });
            setState({ ...state, loading: false })
            toast.error('Failed to Load Page');
            history.push('/admin')
        });
    }

    const updateFooterLinkStatus = () => {
        const newState = { ...state };
        newState.is_footer_link = !state.is_footer_link;
        setState(newState);
        cmsServices.setStaticPage(
            'how-it-works',
            newState
        ).then((response) => {
            toast.success('Updated Successfully');
        }).catch((err) => {
            toast.error('Failed to Update Page');
        });
    }

    const handleChange = ( value, key, parent_key ) => {
        const newState = { ...state };
        if( parent_key ){
            newState.content[parent_key][key] = value;
        }
        else{
            newState.content[key] = value;
        }
        setState(newState);
    }
    const handleArrayChange = ( value, key, parent_key, index ) => {
        const newState = { ...state };
        if( parent_key ){
            newState.content[parent_key][key][index] = value;
        }
        else{
            newState.content[key][index] = value;
        }
        setState(newState);
    }
    const addNewItem = ( value, key, parent_key ) => {
        const newState = { ...state };
        if( parent_key ){
            // value.order = size(newState.content[parent_key][key]);
            newState.content[parent_key][key].push(value);
        }
        else{
            // value.order = size(newState.content[key]);
            newState.content[key].push(value);
        }
        setState(newState);
    }
    const deleteItem = ( index, key, parent_key ) => {
        const newState = { ...state };
        if( parent_key ){
            newState.content[parent_key][key].splice(index, 1);
        }
        else{
            newState.content[parent_key][key].splice(index, 1);
        }
        setState(newState);
    }
    const setStaticPage = () => {
        setLoadingButton(true);
        cmsServices.setStaticPage(
            'how-it-works',
            state
        ).then((response) => {
            setLoadingButton(false);
            toast.success('Updated Successfully');
        }).catch((err) => {
            setLoadingButton(false);
            toast.error('Failed to Update Page');
        });
    }

    const onFileHandler = (e) => {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append('filename', file);

        attachmentServices.uploadPostMedia(formData)
        .then(function (response) {
            addNewItem( { image_url : response.post_media_url }, 'images', 'real_example' );
        })
        .catch(function (error) {
            return;
        });
    }

    useEffect(() => {
        getStaticPage();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const content  = state.content;

    return (
        <div>
            <div className="generic-page-header">
                <div className="header-data-section">
                    <Link to={"/admin/static-content"} className="back-btn"><img src="/images/admin/global/back.svg" alt="" /></Link>
                    <h2 className="page-head my-0">How It Works</h2>
                </div>

                <div className="header-data-section">

                    <div className="user-status">
                        <span>Footer Link
                            <CustomInput
                                type="switch"
                                id="footer_link_switch"
                                checked={ state.is_footer_link ? true : false }
                                onChange={updateFooterLinkStatus}
                            />
                        </span>

                    </div>

                    <Button
                        color="primary"
                        className="add-new-btn"
                        onClick={setStaticPage}
                     >
                     {loadingButton ? <Spinner size="sm"/> : 'Save'}
                    </Button>
                </div>
            </div>
            <div className="tt-admin-card">
                <div className="tt-admin-card--data">
                    <Row className="no-gutters">
                        <Col xs="12">
                            <div className="trip-details-data-section">
                                <Form className="tt-admin-form">
                                {state.loading ?
                                    <ListLoader/>
                                    :
                                    <div className="tt-admin-form-inner">
                                        <Row>
                                            <Col xs="12">
                                                <h4 className="in-form-heading"><span>Steps</span></h4>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col xs="12">
                                                <FormGroup>
                                                    <Label for="">Section Title</Label>
                                                    <Input
                                                        type="text"
                                                        name="page_title"
                                                        id="page_title"
                                                        value={content.page_title}
                                                        onChange = { (e) => handleChange( e.target.value, 'page_title' ) }
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            { content.steps && content.steps.map(( step, index ) => (
                                                <div className="steps-section" key={index}>
                                                    <div className="count">{index + 1 }</div>
                                                    <div className="trip-input-1">
                                                        <FormGroup>
                                                            <Input
                                                                type="textarea"
                                                                name={`key_${step}`}
                                                                id={`key_${step}`}
                                                                value={ step.section_title }
                                                                onChange = { (e) => handleArrayChange(  { ...step, section_title: e.target.value }, 'steps', '', index  ) }
                                                            />
                                                        </FormGroup>
                                                    </div>
                                                    <div className="trip-input-2">
                                                        <FormGroup>
                                                            <Input
                                                                type="textarea"
                                                                name={`value_${step}`}
                                                                id={`value_${step}`}
                                                                value={ step.description }
                                                                onChange = { (e) => handleArrayChange( { ...step, description: e.target.value }, 'steps', '', index  ) }
                                                            />
                                                        </FormGroup>
                                                    </div>
                                                </div>
                                            ))}
                                        </Row>

                                        <Row form >
                                            <Col xs="12">
                                                <h4 className="in-form-heading"><span>Real Life Example</span></h4>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col xs="12">
                                                <FormGroup>
                                                    <Label for="">Section Title</Label>
                                                    <Input
                                                        type="text"
                                                        name="text"
                                                        id=""
                                                        value={content.real_example && content.real_example.section_title ? content.real_example.section_title : '' }
                                                        onChange = { (e) => handleChange( e.target.value, 'section_title', 'real_example' ) }
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs="12">
                                                <div className="editor-outer-wrap">
                                                    <EditorComponent
                                                        id="real_example"
                                                        content={content.real_example && content.real_example.quote}
                                                        onChange = { (content) => handleChange( content, 'quote', 'real_example' ) }
                                                    />

                                                </div>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            { content.real_example && content.real_example.testimonials && content.real_example.testimonials.map(( item, index ) => (
                                            <Col xs="12" key={index} >
                                                <div className="trade-resources-item">
                                                    <div className="action-form">
                                                        <FormGroup>
                                                            <Input
                                                                type="text"
                                                                value={item.label}
                                                                onChange = { (e) => handleArrayChange( { ...item, label: e.target.value }, 'testimonials', 'real_example', index  ) }
                                                            />
                                                        </FormGroup>
                                                        <FormGroup>
                                                            <Input
                                                                type="text"
                                                                value={item.url}
                                                                onChange = { (e) => handleArrayChange( { ...item, url: e.target.value }, 'testimonials', 'real_example', index  ) }
                                                            />
                                                        </FormGroup>
                                                    </div>
                                                </div>
                                            </Col>
                                            ))}
                                        </Row>
                                        <Row form className="sortable-container">
                                            <Col xs="12" >
                                            { content.real_example && content.real_example.images &&
                                                <Container
                                                    dragHandleSelector=".my-handle"
                                                    onDrop={({removedIndex, addedIndex}) => handleChange(arrayMove(content.real_example.images, removedIndex, addedIndex), 'images', 'real_example' )}
                                                >
                                                { content.real_example.images.map( (item, index ) => (
                                                    <Draggable key={index}>
                                                        <div className="photo-slider-item">
                                                            <div className="slider--img">
                                                                <img src={item.image_url} alt="" style={{ width: '100%'}} />
                                                            </div>
                                                            <div className="slider--text">
                                                                <div className="slider--text_input">
                                                                    <FormGroup>
                                                                        <Input
                                                                            type="text"
                                                                            value={item.description ? item.description : '' }
                                                                            onChange = { (e) => handleArrayChange( { ...item, description: e.target.value }, 'images', 'real_example', index  ) }
                                                                            placeholder="Description"
                                                                        />
                                                                    </FormGroup>
                                                                    <FormGroup>
                                                                        <Input
                                                                            type="text"
                                                                            value={item.url ? item.url : ''}
                                                                            onChange = { (e) => handleArrayChange( { ...item, url: e.target.value }, 'images', 'real_example', index  ) }
                                                                            placeholder="Target URL"
                                                                        />
                                                                    </FormGroup>
                                                                </div>
                                                                <div className="slider--text_buttons">
                                                                    <Link to={"#"} className="my-handle"><img src="/images/admin/static-content/draggable.svg" alt="" /></Link>
                                                                    <Link to={"#"} onClick={ () => deleteItem(index, 'images', 'real_example' )}  ><img src="/images/admin/static-content/delete.svg" alt="" /></Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Draggable>
                                                ))}
                                                </Container>
                                            }
                                            </Col>
                                            <Col xs="12" className="d-flex justify-content-end mt-3">
                                                <input type="file" id="file" ref={inputRef} onChange={(e) => onFileHandler(e)}  style={{display: "none"}}/>
                                                <Button
                                                    color="primary"
                                                    className="add-new-btn"
                                                    onClick = { () => {inputRef.current.click() } }
                                                >
                                                    <i>
                                                        <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M5.03027 7.77295H0.171875V5.6001H5.03027V0.619629H7.22754V5.6001H12.0981V7.77295H7.22754V12.729H5.03027V7.77295Z" fill="#fff" />
                                                        </svg>
                                                    </i>Add New Photo
                                                </Button>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs="12">
                                                <h4 className="in-form-heading"><span>Popular Questions</span></h4>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col xs="12">
                                                <FormGroup>
                                                    <Label for="">Section Title</Label>
                                                    <Input
                                                        type="text"
                                                        value={content.popular_questions && content.popular_questions.section_title}
                                                        onChange = { (e) => handleChange( e.target.value, 'section_title', 'popular_questions'  ) }
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form className="sortable-container">
                                            <Col xs="12" >
                                            { content.popular_questions && content.popular_questions.questions &&
                                                <Container
                                                    dragHandleSelector=".my-handle"
                                                    onDrop={({removedIndex, addedIndex}) => handleChange(arrayMove(content.popular_questions.questions, removedIndex, addedIndex), 'questions', 'popular_questions' )}
                                                    lockAxis="y"
                                                >
                                                { content.popular_questions.questions.map( (item, index) => (
                                                    <Draggable key={index}>
                                                        <div className="photo-slider-item">
                                                            <div className="popular-input-wrap">
                                                                <Row>
                                                                    <Col xs="12" md="6">
                                                                        <FormGroup>
                                                                            <Input
                                                                                type="textarea"
                                                                                value={item.question ? item.question : '' }
                                                                                onChange = { (e) => handleArrayChange( { ...item, question: e.target.value }, 'questions', 'popular_questions', index  ) }
                                                                            />
                                                                        </FormGroup>
                                                                    </Col>
                                                                    <Col xs="12" md="6">
                                                                        <FormGroup>
                                                                            <Input
                                                                                type="textarea"
                                                                                value={item.answer ? item.answer : '' }
                                                                                onChange = { (e) => handleArrayChange( { ...item, answer: e.target.value }, 'questions', 'popular_questions', index  ) }
                                                                            />
                                                                        </FormGroup>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                            <div className="slider--text_buttons">
                                                                <Link to={"#"} className="my-handle"><img src="/images/admin/static-content/draggable.svg" alt="" /></Link>
                                                                <Link to={"#"} onClick={ () => deleteItem(index, 'questions', 'popular_questions' )}  ><img src="/images/admin/static-content/delete.svg" alt="" /></Link>
                                                            </div>
                                                        </div>
                                                    </Draggable>
                                                ))}
                                                </Container>
                                            }
                                            </Col>
                                            <Col xs="12" className="d-flex justify-content-end mt-3">
                                                <Button color="primary" className="add-new-btn" onClick={ () => addNewItem( {}, 'questions', 'popular_questions' ) }>
                                                    <i>
                                                        <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M5.03027 7.77295H0.171875V5.6001H5.03027V0.619629H7.22754V5.6001H12.0981V7.77295H7.22754V12.729H5.03027V7.77295Z" fill="#fff" />
                                                        </svg>
                                                    </i>Add New Question</Button>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs="12">
                                                <h4 className="in-form-heading"><span>Trade Resources</span></h4>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col xs="12">
                                                <FormGroup>
                                                    <Label for="">Section Title</Label>
                                                    <Input
                                                        type="text"
                                                        value={content.trade_resources && content.trade_resources.section_title}
                                                        onChange = { (e) => handleChange( e.target.value, 'section_title', 'trade_resources'  ) }
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form className="sortable-container" >
                                            <Col xs="12" >
                                            { content.trade_resources && content.trade_resources.resources &&
                                            <Container
                                                dragHandleSelector=".my-handle"
                                                onDrop={({removedIndex, addedIndex}) => handleChange(arrayMove(content.trade_resources.resources, removedIndex, addedIndex), 'resources', 'trade_resources' )}
                                                lockAxis="y"
                                            >
                                                { content.trade_resources.resources.map( (item, index) => (
                                                    <Draggable key={index}>
                                                        <div className="trade-resources-item">
                                                            <div className="action-icon"><Link to={"#"} className="my-handle" ><img src="/images/admin/static-content/draggable.svg" alt="" /></Link></div>
                                                            <div className="action-form">
                                                                <FormGroup>
                                                                    <Input
                                                                        type="text"
                                                                        value={item.resource ? item.resource : ''}
                                                                        onChange = { (e) => handleArrayChange( { ...item, resource: e.target.value }, 'resources', 'trade_resources', index  ) }
                                                                    />
                                                                </FormGroup>
                                                                <FormGroup>
                                                                    <Input
                                                                        type="text"
                                                                        value={item.url ? item.url : '' }
                                                                        onChange = { (e) => handleArrayChange( { ...item, url: e.target.value }, 'resources', 'trade_resources', index  ) }
                                                                    />
                                                                </FormGroup>
                                                            </div>
                                                            <div className="action-icon"><Link to={"#"} onClick={ () => deleteItem(index, 'resources', 'trade_resources' )} ><img src="/images/admin/static-content/delete.svg" alt="" /></Link></div>
                                                        </div>
                                                    </Draggable>
                                                ))}
                                            </Container>
                                            }
                                            </Col>
                                            <Col xs="12" className="d-flex justify-content-end mt-3">
                                                <Button color="primary" className="add-new-btn" onClick={ () => addNewItem( {}, 'resources', 'trade_resources' ) } >
                                                    <i>
                                                        <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M5.03027 7.77295H0.171875V5.6001H5.03027V0.619629H7.22754V5.6001H12.0981V7.77295H7.22754V12.729H5.03027V7.77295Z" fill="#fff" />
                                                        </svg>
                                                    </i>Add New Resource</Button>
                                            </Col>
                                        </Row>
                                    </div>
                                }
                                </Form>
                            </div>
                        </Col>
                    </Row>
                </div>

            </div>
        </div>


    );

}
