import React from "react"
import "js/components/legos/styles/trip-location-module.css"

export default props => (
   <div>
        <div className="trip-name-location-outer">
            <h2>{props.tripName}</h2>
            <p><img src="/images/admin/global/location.svg" alt="" />{props.tripLocation}</p>
        </div>
   </div>
   
)



