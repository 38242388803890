import React, { useEffect, useState} from "react";
import { Row, Col, Button, Spinner, Form} from 'reactstrap';
import { Helmet } from "react-helmet";
import { configureAnchors } from 'react-scrollable-anchor'
import moment from "moment";
import { isEmpty, find, omit, map, orderBy } from 'lodash';
import { toast } from "react-toastify";

import {SmallImageSlider} from "js/components/legos/smallImageSlider"
import { tripsServices } from "js/services";
import { TripDetails, TripDescription } from 'js/components/web/components/members/edit-trip/components';
import { TripMedia, ListLoader } from "js/components/common";
import { history} from 'js/helpers';


configureAnchors({offset: -110});

export const EditTrip = (props) => {
    const [state, setState] = useState({
        loading: false
    })
    const [ trip, setTrip ] = useState({});
    const [ errors, setErrors ] = useState(false);
    useEffect(() => {
        const { match } = props;
        if(match && match.params && match.params.slug){
            setState({...state, loading: true})
            apiCalls.getSelectedTrip(match.params.slug);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const apiCalls = {

        getSelectedTrip: (slug) => {
            setState({...state, trip_loading: true})
            tripsServices.get(slug).then(response => {
                setState({...state, slug, trip_loading: false})
                setData(response)
            }).catch(err => {
                console.log(err);
                history.push('/')
            })
        },

        updateTrip: (details) => {
            setState({...state, loading: true})
            tripsServices.update(details, state.slug).then(response => {
            //    setState({...state, loading: false});
            //    setData(response);
            //    toast.success('Trip has been updated successfully')
            apiCalls.changeCover(details.attachments, response)
            }).catch(err => {
                setState({...state, loading: false})
                err && err.data && err.data.errors && setErrors(err.data.errors);
                toast.error('Update failed');
                console.log(err)
            })
        },

        changeCover: (attachments, response) => {
            if(!isEmpty(attachments)) {
                let cover_image_id = attachments[0] ? attachments[0] : null
                if(cover_image_id) {
                    let cover_trip_media = find(response.trip_media, function(o) {
                        return o.attachment.attachment_id === cover_image_id;
                    })
                    if(cover_trip_media) {
                        tripsServices.changeCover(state.slug, cover_trip_media.trip_media_id)
                        .then(res => {
                            setData(res);
                            toast.success('Trip has been updated successfully');
                            setState({...state, loading: false});
                        })
                    }

                }
            }
            else {
                setData(response);
                toast.success('Trip has been updated successfully');
                setState({...state, loading: false});
            }

        }
    }
    const setData = (data) => {
        const {trip_start_date, trip_end_date, feature_tags, trip_media, state,
            accomodation_description, interested_trips_to_trade, trip_categories,
            is_interested_in_selling, fair_market_value, description, title,
            accomodating_min_seats, accomodating_max_seats, city,
            duration_max_days, duration_min_days,
        } = data
        let tags = [];
        let attachments = [];
        feature_tags && map(feature_tags, tag => {
            tags.push({ value: tag.slug, label: tag.label })
        })
        if(trip_media && trip_media.length) {
            attachments = trip_media.map(item => {
                return {...omit(item, 'attachment'), ...item.attachment}
            })

        }
        let description_data = {
            description,
            accomodation_description,
            interested_trips_to_trade,
            is_interested_in_selling: is_interested_in_selling ? true: false,
            fair_market_value,
        }
        let details = {
            state, trip_categories, accomodating_min_seats, accomodating_max_seats,
            city, duration_max_days, duration_min_days, title,
            feature_tags: tags,
            country: data.country ? { ...data.country, label: data.country.long_name } : {},
            trip_start_date: moment(trip_start_date).isValid() ? moment(trip_start_date).toDate() : '',
            trip_end_date:moment(trip_end_date).isValid() ? moment(trip_end_date).toDate() : '',
        }
        let trip_data = {
            ...data,
            description: description_data,
            details,
            attachments: orderBy(attachments, ['order'], ['asc'])
        }

        setTrip(trip_data)
    }
    const addNewFiles = ( response ) => {
        setTrip(trip => ({...trip, attachments: [...trip.attachments, response]}))
    }

    const removeFile = (removed_item, final_files) => {
        let attachments = final_files
        setTrip({...trip, attachments});
    }

    const handleSubmit = () => {
        let feature_tags = trip.details && trip.details.feature_tags && trip.details.feature_tags.map(item => item.value);
        let attachments = trip.attachments && trip.attachments.map( item => item.attachment_id )
        let trip_categories = trip.details?.trip_categories?.map( item => item?.trip_category_id )
        let start_date = moment( trip.details?.trip_start_date ).startOf( "month" ).isBefore( moment() ) ?
        moment().format( 'YYYY-MM-DD' ) : moment( trip.details?.trip_start_date ).startOf( "month" ).format( 'YYYY-MM-DD' );
        let data = {
            country_id: trip.details?.country?.country_id ,
            trip_categories,
            trip_start_date: trip.details?.trip_start_date ? start_date : '',
            trip_end_date: trip.details?.trip_end_date ? moment(trip.details.trip_end_date).endOf('month').format('YYYY-MM-DD') : '',
            country: trip.details && trip.details.country ? {
                short_name: trip.details.country.short_name,
                long_name: trip.details.country.long_name
            } : '',
            feature_tags,
            ...omit(trip.details, 'country','trip_categories', 'feature_tags', 'trip_start_date','trip_end_date' ),
            ...trip.description,
            attachments
        }
        apiCalls.updateTrip(data)
    }

    return (
        <div>
            <Helmet>
                <title>Trip4Trade :: Edit Trip</title>
                <meta name="description" content="Trips details desription" />
                <meta name="keywords" content="trip4trade, trip details" />
            </Helmet>
            <div className="inner-banner-trip-details">
            </div>
            <div className="wider-data-frame wider-data-frame--trip-details">
                <div className="container">
                {state.trip_loading ?
                    <ListLoader/>
                :
                    <>
                    <Row>
                        {trip.trip_media && !isEmpty(trip.trip_media) &&
                            <Col xs="12">
                                <SmallImageSlider images={trip.trip_media}/>
                            </Col>
                        }
                    </Row>
                    <Row>
                        <Col xs="12" lg="8" className="mt-3">
                            <Row>
                                <Col xs="12" md="8">
                                    <h4 className="trip-name">{trip.title}</h4>
                                    <h6 className="trip-location">
                                        <img src="/images/web/global/map-outline.svg" alt="" />
                                        {`${trip.city ? trip.city.long_name : trip.state ?
                                            trip.state.long_name: ''},
                                            ${trip.state ? trip.state.short_name: ''},
                                            ${trip.country ? trip.country.short_name : ''}`
                                        }
                                    </h6>
                                </Col>
                                <Col xs="12" md="4">
                                    <h5 className="trip-id">Trip ID : {`#${trip.trip_no}`}</h5>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="6" md="3">
                                    <div className="trip-details-card">
                                        <div className="twisted-square">
                                            <img src="/images/web/trips/details/availability.svg" alt="" />
                                        </div>
                                        <div className="details-data">
                                            <label>Availability</label>
                                            <h5>
                                                {`${trip.trip_start_date ? moment(trip.trip_start_date).format('DD MMM'): ''}. -`}
                                                <br/>
                                                {`${trip.trip_end_date ? moment(trip.trip_end_date).format('DD MMM'): ''}.`}

                                            </h5>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs="6" md="3">
                                    <div className="trip-details-card">
                                        <div className="twisted-square">
                                            <img src="/images/web/trips/details/duration.svg" alt="" />
                                        </div>
                                        <div className="details-data">
                                            <label>Duration</label>
                                            <h5>
                                                {trip.trip_duration ? trip.trip_duration : `${trip.duration_min_days ? trip.duration_min_days : ''} -
                                                    ${trip.duration_max_days ? trip.duration_max_days : ''} Days`
                                                }
                                            </h5>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs="6" md="3">
                                    <div className="trip-details-card">
                                        <div className="twisted-square">
                                            <img src="/images/web/trips/details/accomodates.svg" alt="" />
                                        </div>
                                        <div className="details-data">
                                            <label>Accommodates</label>
                                            <h5>
                                                {`${trip.accomodating_min_seats ? trip.accomodating_min_seats : ''} -
                                                    ${trip.accomodating_max_seats ? trip.accomodating_max_seats : ''} People`
                                                }
                                            </h5>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs="6" md="3">
                                    <div className="trip-details-card">
                                        <div className="twisted-square">
                                            <img src="/images/web/trips/details/sale.svg" alt="" />
                                        </div>
                                        <div className="details-data">
                                            <label>For Sale?</label>
                                            <h5>
                                                {trip.is_interested_in_selling ? 'Yes' : 'No'}
                                            </h5>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    </>
                }
                </div>

            </div>

            <div className="wider-data-frame wider-data-frame--grey wider-data-frame-trip-grey">
            {!isEmpty(trip) &&
                <div className="container">
                    <Row>
                        <Col xs="12">
                            <div className="tt-card">
                                <TripDetails
                                    trip={trip.details}
                                    onUpdate={(data) => setTrip({...trip, details: data})}
                                    errors={errors}
                                />
                            </div>
                            <div className="tt-card">
                                <TripDescription
                                    trip={trip.description}
                                    onUpdate={(data) => setTrip({...trip, description: data})}
                                    errors={errors}
                                />
                            </div>
                            <Form className="tt-admin-form tt-card">
                                <TripMedia
                                    files={trip.attachments}
                                    onChange={(file) => setTrip({...trip, attachments: file})}
                                    onAddNewFiles={addNewFiles}
                                    onRemoveFile={removeFile}/>
                                <div className="invalid-feedback d-block">{errors['attachments']}</div>
                                <div className="invalid-feedback d-block">{errors['general']}</div>
                            </Form>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12">
                            <div className="d-flex justify-content-center add-listing-button-wrap">
                                <div className="add-listing-btn-group">

                                    <Button
                                        className="btn-outline"
                                        onClick={handleSubmit}
                                        disabled={state.loading}
                                    >
                                        {state.loading ?
                                            <Spinner size="sm"/> : 'Save Changes'
                                        }
                                    </Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            }
            </div>
        </div>
    );
}
