import React, { useState, useContext, useEffect } from 'react';
import { Row, Col, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import Select from 'react-select';
import Geosuggest from 'react-geosuggest';
import { isEmpty, forEach, omit, find, isEqual } from 'lodash';
import CreatableSelect from 'react-select/creatable';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import { ErrorFormFeedback, TreeContainer } from 'js/components/common';
import { LookupContext } from 'js/contexts';
import { UseErrors } from 'js/hooks';
import { tripHash } from 'js/components/common/fucntions';

const styles = {
    option: (styles, state) => ({
        ...styles,
        backgroundColor: state.isSelected ? "#FFA405 " : state.isFocused ? "#FFA40520" : ""
    })
};
export const DetailsTab = (props) => {
    const lookups = useContext(LookupContext);
    const [ trip, setTrip ] = useState({});
    const [ form_errors, getErrors ] = UseErrors( {} );
    const error_hash = [
        tripHash.title,
        tripHash.country,
        tripHash.city,
        tripHash.trip_start_date,
        tripHash.trip_end_date,
        tripHash.duration_min_days,
        tripHash.duration_max_days,
        tripHash.categories,
        tripHash.feature_tags,
        tripHash.accomodating_max_seats,
        tripHash.accomodating_min_seats
    ];

    useEffect( () => {
        let start_date = moment( trip.trip_start_date ).startOf( "month" ).isBefore( moment() ) ?
            moment().format( 'YYYY-MM-DD' ) : moment( trip.trip_start_date ).startOf( "month" ).format( 'YYYY-MM-DD' );
        let feature_tags = trip.feature_tags && trip.feature_tags.map( item => item.value );
        let details = {
            ...trip,
            country_id: trip.country ? trip.country.country_id : '',
            trip_start_date: trip.trip_start_date ? start_date : '',
            trip_end_date: trip.trip_end_date ? moment(trip.trip_end_date).endOf("month").format('YYYY-MM-DD') : '',
            feature_tags,
            country: trip.country ? {
                short_name: trip.country.short_name,
                long_name: trip.country.long_name
            }: ''
        }
        if ( isEmpty( form_errors ) )
        {
            props.onChange({details, next_tab: 'description'})
        }
        //eslint disabled for props
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ form_errors ] );

    const handleChange = (key, value) => {
        let data = {
            ...trip
        }
        if(key === 'country') {
            data.location = '';
            data.city = '';
        }

        data[key] = value
        setTrip(data)
    }
    const handleAddressChange = (place) => {
        let state = {} ;
        let city = {} ;
        let country = {};
        let latitude = '';
        let longitude = '';
        if(!isEmpty(place)) {
            if(place.gmaps && place.gmaps.address_components) {
                forEach(place.gmaps.address_components, (value, key) => {
                    value.types && forEach(value.types, type => {
                        let is_state = false
                        let is_city = false
                        if (type === "administrative_area_level_1") {
                            state = { ...omit(value, 'types') }
                            is_state = true
                        }
                        if (type === "administrative_area_level_2" && !is_state) {
                            state = { ...omit(value, 'types') }
                            is_state = true
                        }
                        if (type === "locality") {
                            city = { ...omit(value, 'types') }
                            is_city = true
                            if(!(is_state)) {
                                state = {...omit(value, 'types') }
                            }
                        }
                        if(type === 'sublocality_level_1' && !is_city) {
                            is_city = true
                            city = {...omit(value, 'types') }

                        }
                        if( type === "country" ){
                            country = { ...omit(value, 'types') }
                        }
                        if(type === 'route' && isEqual(state, city)){
                            city = { ...omit(value, 'types') }
                        }
                    })
                })
            }
            if( country.short_name ){
                country = find(lookups.countries, ['short_name',  country.short_name] );
            }
            if(place.location) {
                latitude = place.location.lat
                longitude = place.location.lng
            }

            let new_trip = {
                ...trip,
                state,
                city,
                latitude,
                longitude,
                country,
                location: place && place.description ? place.description : ''
            }
            setTrip(new_trip)
        }
    }

    const onTreeChange = (selectedNodes) => {
        let trip_categories = selectedNodes.map( item => item?.trip_category_id )
        setTrip({...trip, trip_categories})
    }



    const handleNext = () => {
        getErrors(error_hash, trip)
    }

    return(
        <div className="signup-tab-data-wrap add-listing-data-wrap">
            <Row>
                <Col xs="12">
                    <h3 className="header5 mb-0">Trip Details</h3>
                    <p className="p p-0 text-left">Just select approximate ranges for now. You can finalize the specifics after finding a trade match.</p>
                </Col>
            </Row>
            <Row>
                <Col xs="12">
                    <Form className="profile-form add-listing-form">
                        <Row>
                            <Col xs="12" className="mb-5">
                                <FormGroup>
                                    <img src="/images/web/add-listing/trip-title.svg" alt="" width="30px" />
                                    <Label for="" >Trip Title *</Label>
                                    <Input
                                        type="text"
                                        placeholder='Ex: " Deep Sea Fishing"'
                                        onChange={(e) => handleChange('title', e.target.value)}
                                        invalid={form_errors.title && true}
                                    />
                                    <ErrorFormFeedback field="title" errors={form_errors}/>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12" md="4">
                                <FormGroup>
                                    <img src="/images/web/add-listing/country.svg" alt="" width="27px" />
                                    <Label for="" >Country *</Label>
                                    <div className="custom-react-select">
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            options={lookups.countries}
                                            styles={styles}
                                            onChange={(e) => handleChange('country', e)}
                                            value={trip.country}
                                        />
                                        <div className="invalid-feedback d-block">{form_errors['country']}</div>
                                    </div>
                                </FormGroup>
                            </Col>
                            <Col xs="12" md="8">
                                <FormGroup>
                                    <img src="/images/web/add-listing/town.svg" alt="" width="27px" />
                                    <Label for="" >Closest Town/City *</Label>
                                    <div className="tt-custom-react-select">
                                        <Geosuggest
                                            types={['(cities)']}
                                            name="address"
                                            className="react-select-container"
                                            inputClassName={"form-control"}
                                            placeholder=""
                                            initialValue={trip.location}
                                            value={trip.location}
                                            autoComplete="nope"
                                            onSuggestSelect={handleAddressChange}
                                            country={trip.country ? trip.country.short_name : ''}
                                            onChange={(e) => handleChange('city', e)}
                                        />
                                        <div className="invalid-feedback d-block">{form_errors['city']}</div>
                                    </div>
                                </FormGroup>
                            </Col>
                            <Col xs="12" md="4">
                                <FormGroup>
                                    <img src="/images/web/add-listing/accomodates.svg" alt="" width="26px" />
                                    <Label for="" >Accommodates *</Label>
                                    <Row>
                                        <Col xs="6">
                                            <Input
                                                placeholder="Min seats"
                                                type="text"
                                                onChange={(e) => handleChange('accomodating_min_seats', e.target.value)}
                                                invalid={form_errors.accomodating_min_seats && true}
                                            />
                                            <ErrorFormFeedback field="accomodating_min_seats" errors={form_errors}/>
                                        </Col>
                                        <Col xs="6">
                                            <Input
                                                placeholder="Max seats"
                                                type="text"
                                                onChange={(e) => handleChange('accomodating_max_seats', e.target.value)}
                                                invalid={form_errors.accomodating_max_seats && true}
                                            />
                                            <ErrorFormFeedback field="accomodating_max_seats" errors={form_errors}/>
                                        </Col>
                                    </Row>
                                    {/* <div className="custom-react-select">
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            options={TripFeatrues}
                                        />
                                    </div> */}
                                </FormGroup>
                            </Col>
                            <Col xs="12" md="4">
                                <FormGroup>
                                    <img src="/images/web/add-listing/trip-date.svg" alt="" width="28px" />
                                    <Label for="" >Trip Month Range *</Label>
                                    <Row>
                                        <Col xs="12" md="6">
                                            <div className="custom-react-select custom-month-picker">
                                                <DatePicker
                                                    placeholderText="Start date"
                                                    selected={trip.trip_start_date}
                                                    onChange={(e) => handleChange('trip_start_date', e)}
                                                    selectsStart
                                                    startDate={trip.trip_start_date}
                                                    endDate={trip.trip_end_date}
                                                    dateFormat="MM/yyyy"
                                                    showMonthYearPicker
                                                    minDate={moment().subtract(1, 'months').endOf('month').toDate()}
                                                    className='tt-month-range-picker'
                                                />
                                                <ErrorFormFeedback field="trip_start_date" errors={form_errors} is_invalid/>

                                            </div>
                                        </Col>
                                        <Col xs="12" md="6">
                                            <div className="custom-react-select custom-month-picker">
                                                <DatePicker
                                                    placeholderText="End date"
                                                    selected={trip.trip_end_date}
                                                    onChange={(e) => handleChange('trip_end_date', e)}
                                                    selectsEnd
                                                    startDate={trip.trip_start_date}
                                                    endDate={trip.trip_end_date}
                                                    dateFormat="MM/yyyy"
                                                    showMonthYearPicker
                                                    minDate={trip.trip_start_date}
                                                    className='tt-month-range-picker'
                                                />
                                                <ErrorFormFeedback field="trip_end_date" errors={form_errors} is_invalid/>
                                        </div>
                                        </Col>
                                    </Row>
                                </FormGroup>
                            </Col>
                            <Col xs="12" md="4">
                                <FormGroup>
                                    <img src="/images/web/add-listing/duration.svg" alt="" width="24px" />
                                    <Label for="" >Trip Duration *</Label>
                                    <Row>
                                    <Col xs="6">
                                        <Input
                                            placeholder="Min Duration"
                                            type="text"
                                            onChange={(e) => handleChange('duration_min_days', e.target.value)}
                                            invalid={form_errors.duration_min_days && true}
                                        />
                                        <ErrorFormFeedback field="duration_min_days" errors={form_errors}/>
                                    </Col>
                                    <Col xs="6">
                                        <Input
                                            className="max-duration"
                                            placeholder="Max Duration"
                                            type="text"
                                            onChange={(e) => handleChange('duration_max_days', e.target.value)}
                                            invalid={form_errors.duration_max_days && true}
                                        />
                                        <ErrorFormFeedback field="duration_max_days" errors={form_errors}/>
                                    </Col>
                                </Row>
                                    {/* <div className="custom-react-select">
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            options={TripFeatrues}
                                        />
                                    </div> */}
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="4">
                                <FormGroup>
                                    <img src="/images/web/add-listing/trip-features.svg" alt="" width="24px" />
                                    <Label for="" >Trip Category *</Label>
                                    <div className="custom-react-select">
                                        {/* <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            options={lookups.trip_categories}
                                            styles={styles}
                                            onChange={(e) => handleChange('trip_category_id', e.value)}
                                        /> */}
                                        <TreeContainer
                                            data={ lookups.trip_categories }
                                            onChange={onTreeChange}
                                            mode="multiSelect"
                                            className="tt-tree-container"
                                            placeholder="Trip Category"
                                        />
                                        <div className="invalid-feedback d-block">{form_errors['trip_categories']}</div>
                                    </div>
                                </FormGroup>
                            </Col>
                            <Col xs="8">
                                <FormGroup>
                                    <img src="/images/web/add-listing/trip-features.svg" alt="" width="24px" />
                                    <Label for="" >Trip Features *</Label>
                                    <div className="custom-react-select">
                                        <CreatableSelect
                                            className="react-select-multi-container"
                                            classNamePrefix="react-select"
                                            isMulti
                                            styles={styles}
                                            onChange={(e) => handleChange('feature_tags', e)}
                                            options={lookups.feature_tags}
                                        />
                                        <div className="invalid-feedback d-block">{form_errors['feature_tags']}</div>
                                    </div>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12">
                                <div className="d-flex justify-content-center add-listing-button-wrap">
                                    <div className="add-listing-btn-group">
                                        <Button
                                            className="btn-outline"
                                            onClick={handleNext}>
                                            Next
                                        </Button>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Form>

                </Col>
            </Row>
        </div>
    )
}
