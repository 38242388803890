import React, { useState, useEffect } from 'react';
import { Row, Col, Input, Button, Spinner } from 'reactstrap';
import { map, isEmpty } from 'lodash';
import classnames from 'classnames';
import moment from 'moment';
import { toast } from 'react-toastify';

import { mcServices, attachmentServices, tripsServices } from 'js/services';
import { ListLoader } from './list-loader';
import { useModal } from 'js/hooks';
import { ErrorFormFeedback } from 'js/components/common';

export const MessageCenter = (props) => {
    const [selected_thread_id, selectThread] = useState(null);
    const [messages, setMessages] = useState({});
    const [mc_threads, setThreads] = useState([]);
    const [loading_state, setLoading] = useState({
        threads: false,
        messages: false,
        cancel_inquiry: false,
    })
    const [last_message, setLastMessage] = useState(null)
    //const file_ref = useRef();
    const [show, hide] = useModal();
    const [errors, setErrors] = useState({});

    useEffect(() => {
        let interval_id = setInterval(pullMessages, 30000);

        return () => {
            clearInterval(interval_id);
        }

    })
    useEffect(() => {
        if (props.user_id) {
            api_calls.createThread(props.user_id)
        }
        if (props.thread_id) {
            selectThread(Number(props.thread_id));
            api_calls.getMessages(props.thread_id)
        }
        api_calls.getThreads();
        setLoading({ ...loading_state, threads: true })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const chat_box_element = document.getElementById(last_message)
        setTimeout(() => {
            if(chat_box_element) {
                chat_box_element.scrollIntoView({behavior: 'smooth', block: 'end' })
            }
        })
    },[last_message])

    const api_calls = {
        getMessages: (thread_id, selected_message) => {
            let selected_mc_message = selected_message ? selected_message : messages[thread_id] ? messages[thread_id] : {}
            mcServices.getMessages(thread_id)
                .then(response => {
                    let mc_messages = {
                        ...messages,
                        [thread_id]: {
                            ...selected_mc_message,
                            mc_messages: response.data,
                        }
                    }
                    setMessages(mc_messages);
                    setLoading({ ...loading_state, messages: false })
                    const last_message = response?.data?.length ? response.data[response.data.length - 1] : response?.data?.[0]
                    last_message && setLastMessage(`chat_box_${thread_id}_${last_message.mc_message_id}`)
                })
        },
        createThread: (recipient_id) => {
            setLoading({ ...loading_state, threads: true })
            let users = [{
                user_id: recipient_id
            }]
            mcServices.createThreads({ users })
                .then(res => {
                    api_calls.getThreads()
                })
        },
        getThreads: () => {

            mcServices.getThreads()
                .then(response => {
                    let threads = [];
                    response.data && map(response.data, (item) => {
                        if (!item['label']) {
                            let participants = []
                            item['to_users'] = [];
                            item['participants'] && item['participants'].map((participant) => {
                                if (!participant['is_logged_in_user']) {
                                    item['to_users'].push(participant);
                                    item['profile_image'] = participant.attachment ?
                                        participant.attachment.thumbnail_url ?
                                        participant.attachment.thumbnail_url : participant.attachment.attachment_url
                                    :"images/web/global/logo-icon.svg"
                                    let name = participant['user_name'];
                                    if (participant.is_deleted_user) {
                                        name = `${participant['user_name']} (deleted)`;
                                    }
                                    else {
                                        item['active_user_exists'] = 1;
                                    }
                                    participants.push(name);
                                }
                                return participant;
                            })
                            item['is_unread'] = item.unread_messages > 0 ;

                            item['label'] = participants.join();
                        }
                        threads.push(item);
                    });

                    if (!isEmpty(threads) && selected_thread_id === null && !props.thread_id) {

                        selectThread(threads[0].mc_thread_id);
                        api_calls.getMessages(threads[0].mc_thread_id)
                    }
                    setLoading({ ...loading_state, threads: false })
                    setThreads(threads)
                })
        },
        createMessage: (message) => {
            setLoading({ ...loading_state, messages: true })
            mcServices.createMessage(message, selected_thread_id)
                .then(response => {
                    let mc_messages = {
                        ...messages,
                        [selected_thread_id]: {
                            ...messages[selected_thread_id],
                            typed_message: ''
                        }
                    }
                    setMessages(mc_messages)
                    api_calls.getMessages(selected_thread_id, mc_messages[selected_thread_id]);
                    setErrors({});
                }).catch((error) => {
                    error && error.data && error.data.errors && setErrors(error.data.errors);
                    setLoading({ ...loading_state, messages: false })
                });
        },
        uploadFile: (file, message) => {
            const form_data = new FormData();
            form_data.append('filename', file);
            form_data.append('entity_type', 'McMessage');
            attachmentServices.upload(form_data)
                .then(response => {
                    let data = {
                        message,
                        attachment_id: response.attachment_id
                    }
                    api_calls.createMessage(data)
                })
        },
        downloadFile: (attachment_id) => {
            attachmentServices.download(attachment_id)
                .catch(err => {
                    toast.error(err);
                })
        },
        cancelInquiry: (inquiry_id, params) => {
            setLoading((loading_state) => ({
                ...loading_state,
                cancel_inquiry: true
            }));
            tripsServices.inquiryUpdate(inquiry_id, params).then(() => {
                setLoading((loading_state) => ({
                    ...loading_state,
                    cancel_inquiry: false
                }));
                toast.success('Declined Successfully');
                hide();
                pullMessages();
            });
        },
    }

    const pullMessages = (thread_id) => {
        let mc_thread_id = thread_id ? thread_id : selected_thread_id
        if (mc_thread_id) {
            api_calls.getMessages(mc_thread_id)
        }
        api_calls.getThreads();
    }
    const handleSelectedThread = (thread_id) => {
        selectThread(thread_id)
        pullMessages(thread_id)

    }

    const handleMessage = (e, type) => {
        e.preventDefault();

        let selcted_mc_messages = !isEmpty(messages[selected_thread_id]) ? messages[selected_thread_id] : {}
        if (type === 'text') {
            selcted_mc_messages['typed_message'] = e.target.value;
        }

        if (type === 'file') {
            let attachment_message = 'attachment'
            if (selcted_mc_messages['typed_message']) {
                attachment_message = selcted_mc_messages['typed_message']
            }
            const file = e.target.files[0];
            api_calls.uploadFile(file, attachment_message)
        }

        let mc_messages = {
            ...messages,
            [selected_thread_id]: selcted_mc_messages
        }
        setMessages(mc_messages)

    }
    // const onAddFile = () => {
    //     file_ref.current.click()
    // }

    const sendMessage = (e) => {
        //    e.preventDefault()
        let selcted_mc_message = !isEmpty(messages[selected_thread_id]) && messages[selected_thread_id].typed_message ?
            messages[selected_thread_id].typed_message : ''
        let message = selcted_mc_message
        api_calls.createMessage({ message })

    }
    const openAcceptTradeModal = (message) => {
        if (message && message.entity && message.entity.trade && message.entity.trade.trip_trades) {
            show('AcceptTradeModal', {
                hide: hide,
                callBack: () => pullMessages(),
                inquiry_id: message.entity.inquiry_id,
                participant_name: message.participant.user_name,

                trade_id: message.entity.trade.trade_id,
                trip_trade_id: message.entity.trade.trip_trades[0].trip_trade_id,
                guest_user_id: message.entity.trade.trip_trades[0].guest_user_id,
                arrival_time: message.entity.trade.trip_trades[0].arrival_time,
                departure_time: message.entity.trade.trip_trades[0].departure_time
            })
        }
        else{
            console.log("invalid trade details");
        }
    }

    let message = !isEmpty(messages[selected_thread_id]) && messages[selected_thread_id].typed_message ?
        messages[selected_thread_id].typed_message : ''

    return (
        <div className="profile-data-section--bottom px-0">
            {loading_state.threads ?
                <Row className="no-gutters">
                    <Col xs="12">
                        <ListLoader />
                    </Col>
                </Row>
                :
                <Row className="no-gutters">
                    <Col md="4" lg="4">
                        <div className="messagecenter-box chat-user-section">
                            <ul className="contact-list">

                                {!isEmpty(mc_threads) && mc_threads.map((item, index) => (
                                    <li
                                        key={index}
                                        className={classnames({
                                            "unread": item.is_unread,
                                            "selected": item.mc_thread_id === selected_thread_id
                                        })}
                                        onClick={() => handleSelectedThread(item.mc_thread_id)}
                                    >
                                        <div className="contact-list--img">
                                            <img src={item.profile_image} alt="" />
                                        </div>
                                        <div className="contact-list--name">
                                            <h4>{item.label}</h4>
                                            <h6>{moment(item.created_at).format('MMMM, DD')}</h6>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </Col>
                    <Col md="8" lg="8">
                        <div className="messagecenter-box">
                            <div className="chat-box--middle">
                                <div className="chat-main-content">
                                    {messages
                                        && !isEmpty(messages[selected_thread_id])
                                        && !isEmpty(messages[selected_thread_id].mc_messages)
                                        && messages[selected_thread_id].mc_messages.map((message, index) => {
                                            let message_status = !message.participant.is_logged_in_user ? message.delivered_at ? 'Received ' : '' : message.read_at ? 'Seen' : message.delivered_at ? 'Delivered' : '';
                                            let message_status_time = !message.participant.is_logged_in_user ? message.delivered_at : message.read_at ? message.read_at : message.delivered_at ? message.delivered_at : message.sent_at ? message.sent_at : null;
                                            return (
                                                <div
                                                    id={`chat_box_${message.mc_thread_id}_${message.mc_message_id}`}
                                                    key={index}
                                                    className={classnames("d-flex flex-nowrap chat-item", {
                                                        "flex-row-reverse": message.participant.is_logged_in_user
                                                    })}>
                                                    <div className="bubble">
                                                        {message.type === 'text' && isEmpty(message.entity_type) &&
                                                            <div className="bubble-inner">
                                                                {message.attachment &&
                                                                    <Button
                                                                        className="btn-primary--download"
                                                                        onClick={() => api_calls.downloadFile(message.attachment.attachment_id)} >
                                                                        <img
                                                                            src="/images/web/global/download.svg"
                                                                            width="35px"
                                                                            alt="Download"
                                                                        />
                                                                    </Button>
                                                                }
                                                                <div className="message">{message.message}</div>
                                                            </div>
                                                        }
                                                        {message.type === 'file' &&
                                                            <Button
                                                                className="btn-primary--download"
                                                                onClick={() => api_calls.downloadFile(message.attachments[0].attachment_id)} >Download
                                                        <br />
                                                                <img
                                                                    src="/images/web/global/download.svg"
                                                                    width="35px"
                                                                    alt="Download"
                                                                />
                                                            </Button>
                                                        }
                                                        {message.entity_type && message.entity_type === 'Inquiry' && message.entity &&
                                                            message.entity.inquiry_status &&
                                                            <div className="bubble-inner">
                                                                <div className="message">{message.message}</div>
                                                                {message.entity.inquiry_status.slug === 'pending' &&
                                                                <>
                                                                    {message.entity.submitted_user_id === message.participant.user_id && message.participant.is_logged_in_user ?
                                                                        <p className="inquiry-text accept">You inquired a trip</p>
                                                                    :

                                                                        <div className="sm-button-outer">
                                                                            <Button
                                                                                className="btn-solid-grey-xs"
                                                                                onClick={() =>
                                                                                    show('ConfirmModal', {
                                                                                        title: 'Decline Inquiry',
                                                                                        body: 'Do you want to Decline this Inquiry ?',
                                                                                        button_name: 'Decline',
                                                                                        action: () => api_calls.cancelInquiry(message.entity.inquiry_id, {
                                                                                            inquiry_status: 'declined'
                                                                                        }),
                                                                                        loading: loading_state['cancelInquiry']
                                                                                    })
                                                                                } >
                                                                                Decline
                                                                                </Button>
                                                                            <Button
                                                                                className="btn-solid-orange-xs"
                                                                                onClick={() => openAcceptTradeModal(message) }
                                                                            >
                                                                                Accept
                                                                            </Button>
                                                                        </div>
                                                                    }
                                                                </>
                                                                }
                                                                {
                                                                    message.entity.inquiry_status.slug === 'accepted' &&
                                                                    <p className="inquiry-text accept">
                                                                    {message.entity.submitted_user_id === message.participant.user_id && message.participant.is_logged_in_user ?
                                                                        'Your inquiry has been accepted':'You Accepted the Inquiry'
                                                                    }
                                                                    </p>
                                                                }
                                                                {
                                                                    message.entity.inquiry_status.slug === 'declined' &&
                                                                    <p className="inquiry-text decline">
                                                                    {message.entity.submitted_user_id === message.participant.user_id && message.participant.is_logged_in_user ?
                                                                        'Your inquiry has been declined':'You Declined the Inquiry'
                                                                    }

                                                                    </p>
                                                                }
                                                            </div>

                                                        }
                                                        <div className="time">{message_status} {moment(message_status_time).format("MMM D, YYYY [-] h:mma")}</div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                </div>
                            </div>
                            <div className="chat-box--bottom">
                                {/* <div className="attachment-wrap">
                                    <span onClick={() => onAddFile()}>
                                        <img src="/images/web/global/attachment.svg" alt="" />
                                    </span>
                                    <input type="file" id="file" ref={file_ref} onChange={(e) => handleMessage(e, 'file')}  style={{display: "none"}}/>
                                </div> */}
                                <div className="type-data">
                                    <Input
                                        type="text"
                                        name="name"
                                        placeholder="Write a message..."
                                        onChange={(e) => handleMessage(e, 'text')}
                                        value={message}
                                        onKeyDown={(e) => e.key === 'Enter' && !loading_state.messages && sendMessage(e)}
                                        invalid={!isEmpty(errors) && (errors.message) && true}
                                    />
                                    <ErrorFormFeedback field={'message'} errors={errors} />
                                </div>
                                <div className="send-btn">
                                    {isEmpty(mc_threads) ?
                                        <Button
                                            color="primary"
                                            outline
                                            className="btn-outline--chat btn-disabled"
                                            disabled={true}
                                        >
                                        </Button>
                                        :
                                        <Button
                                            color="primary"
                                            outline
                                            className="btn-outline--chat"
                                            disabled={loading_state.messages ||isEmpty(mc_threads)}
                                            onClick={(e) => sendMessage(e)}>
                                            {loading_state.messages ? <Spinner size="sm" /> : 'Send'}
                                        </Button>
                                    }
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            }
        </div>
    )
}
