import React, { useState, useContext } from "react"
import { Row, Col, Form, FormGroup, Label, Input, CustomInput, Spinner, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { omit } from 'lodash';

import "js/components/legos/styles/inquire-about-module.css"
import { tripsServices } from "js/services";
import { useModal } from 'js/hooks';
import { LookupContext, AuthProvider } from 'js/contexts';
import { ErrorFormFeedback, NumberInputComponent } from 'js/components/common';
import moment from "moment";

export const  InquireAbout = (props) =>  {
    const { auth } = useContext(AuthProvider);
    const full_name = auth?.user?.full_name ? auth.user.full_name : '';
    const email = auth?.user?.email ? auth.user.email : '';
    const phone = auth?.user?.phone ? auth.user.phone : '';
    const [active, showForm] =  useState(false)
    const [ show ] = useModal();
    const [inquiry, setInquiry] = useState({ full_name: full_name, email: email, phone: phone, phone_country_code: auth?.user?.phone_country_code});
    const [ is_agreed, setAgreed ] = useState(false)
    const [form_errors, setErrors] = useState({});
    const [ loading, setLoading ] = useState({bookmark: false, inquire: false})
    const lookups = useContext(LookupContext);

    const onBookmark = () => {
        setLoading({...loading, bookmark: true})
        props.slug && tripsServices.bookmarkTrip(props.slug)
        .then(res => {
            props.callBack && props.callBack(res)
            setLoading({...loading, bookmark: false})
        }).catch(err => {
            console.log('failed to bookmark')
            setLoading({...loading, bookmark: false})
        })
    }

    const inquireTrip = (details) => {
        setLoading({...loading, inquire: true});
        tripsServices.inquiry(props.slug, details, auth.is_logged_in)
        .then(response => {
            showForm(false);
            setLoading({...loading, inquire: false})
            setInquiry({});
            setAgreed(false);
            setErrors({})
        }).catch(err => {
            err && err.data && err.data.errors && setErrors(err.data.errors)
            setLoading({...loading, inquire: false})
        })
    }

    const handleChange = (key, value) => {
        setErrors({...omit(form_errors, 'checkbox')});
        if( key === 'inquiry_type' && value === 'buying' && inquiry.inquiry_type !== value ){
            // Display Alert Modal
            show('GeneralInfoModal', {
                title: 'Interested in buying this trip ?',
                body: 'The host will be in touch with pricing. In the meantime, majority of our users are looking to trade so we recommend creating an account and adding a trip for trade.',
                button_label: 'OK',
            });

        }
        let details = {
            ...inquiry,
            [key]: value
        }
        setInquiry(details)
    }

    const handleChangePhone = (value) => {
        setInquiry({ ...inquiry, phone: value.phone, phone_country_code: value.phone_country_code });
    }

    const onSubmit = () => {
        if(is_agreed) {
            let details = {
                ...omit(inquiry, 'date'),
                requested_start_date: inquiry.date && inquiry.date[0] ? moment(inquiry.date[0]).format('YYYY-MM-DD HH:mm:ss'): '',
                requested_end_date: inquiry.date && inquiry.date[1] ? moment(inquiry.date[1]).format('YYYY-MM-DD H:m:s'): ''
            }
            inquireTrip(details)
        }
        else {
            showForm(true);
            active && setErrors({ ...form_errors, 'checkbox': ['Please Accept Terms and Conditions'] });

        }

    }

    return (
        <div>
            <div className="inquire-outer">
                <Row className={classNames("d-none", { "d-flex" : active })}>
                    <Col xs="12">
                        <div className="inquire-about-trip">
                            <Row>
                                <Col>
                                    <h4>Inquire About Trip</h4>
                                </Col>
                            </Row>
                            <Form className="tt-sidebar-from">
                                <Row form>
                                    <Col xs="12">
                                        <FormGroup>
                                            <Label for="">Full Name <span className="required">*</span></Label>
                                            <Input
                                                type="text"
                                                onChange={(e) => handleChange('full_name', e.target.value)}
                                                invalid={form_errors.full_name && true}
                                                value={inquiry.full_name||''}
                                            />
                                            <ErrorFormFeedback field={'full_name'} errors={form_errors}/>
                                        </FormGroup>
                                    </Col>
                                    <Col xs="12">
                                        <FormGroup>
                                            <Label for="">Email <span className="required">*</span></Label>
                                            <Input
                                                type="email"
                                                onChange={(e) => handleChange('email', e.target.value)}
                                                invalid={form_errors.email && true}
                                                value={inquiry.email||''}
                                            />
                                            <ErrorFormFeedback field={'email'} errors={form_errors}/>
                                        </FormGroup>
                                    </Col>
                                    <Col xs="12">
                                        <FormGroup>
                                            <Label for="">Phone <span className="required">*</span></Label>
                                            <NumberInputComponent
                                                phone={`+${inquiry.phone_country_code ?? ''}${inquiry.phone ?? ''}`}
                                                onChange={handleChangePhone}
                                                countryCode={inquiry.phone_country_code}
                                            />
                                            <ErrorFormFeedback field={'phone'} errors={form_errors} is_invalid={true}/>
                                        </FormGroup>
                                    </Col>
                                    <Col xs="12">
                                        <FormGroup>
                                            <Label for="">Requested Date Range <span className="required">*</span></Label>
                                            <DateRangePicker
                                                onChange={(e) => handleChange('date', e)}
                                                value={inquiry.date}
                                                minDate={new Date()}
                                            />
                                            <ErrorFormFeedback field={'requested_end_date'} errors={form_errors} is_invalid={true}/>
                                        </FormGroup>
                                    </Col>
                                    <Col xs="12">
                                        <FormGroup>
                                            <Label for="">Guests <span className="required">*</span></Label>
                                            <Input
                                                type="number"
                                                onChange={(e) => (e.target.value > 0 || e.target.value === '')
                                                    && handleChange('seats_count', e.target.value)}
                                                invalid={form_errors.seats_count && true}
                                                value={inquiry.seats_count||''}
                                            />
                                            <ErrorFormFeedback field={'seats_count'} errors={form_errors}/>
                                        </FormGroup>
                                    </Col>
                                    <Col xs="12">
                                        <FormGroup>
                                            <Label for="first_name">Interested In <span className="required">*</span></Label>
                                            {lookups.inquiry_types && lookups.inquiry_types.map((item, index) => (
                                                <div key={index}>
                                                    <CustomInput
                                                        type="checkbox"
                                                        id={`inquiry_${item.inquiry_type_id}`}
                                                        name="inquiry"
                                                        label={item.label}
                                                        checked={inquiry.inquiry_type === item.slug}
                                                        onChange={(e) => handleChange('inquiry_type', item.slug)}
                                                    />
                                                </div>
                                            ))}
                                            <ErrorFormFeedback field={'inquiry_type'} errors={form_errors} is_invalid={true}/>
                                        </FormGroup>
                                    </Col>
                                    <Col xs="12">
                                        <FormGroup>
                                            <Label for="">Message to Host <span className="required">*</span></Label>
                                            <Input
                                                type="textarea"
                                                onChange={(e) => handleChange('message', e.target.value)}
                                                invalid={form_errors.message && true}
                                                value={inquiry.message||''}
                                            />
                                            <ErrorFormFeedback field={'message'} errors={form_errors}/>

                                        </FormGroup>
                                    </Col>
                                    <Col xs="12">
                                        <FormGroup className="mb-0">
                                            <Label check>
                                                <CustomInput
                                                    id="agree"
                                                    type="checkbox"
                                                    className="agreement-checkbox"
                                                    onChange={(e) => setAgreed(e.target.checked)}
                                                    checked={is_agreed}
                                                />

                                                <span className="agreement-text">I agree with
                                                    <Link to={"/terms-conditions"}>Terms of Service</Link> and <Link to={"/privacy-policy"}>Privacy Policy</Link>
                                                </span>
                                            </Label>
                                            <ErrorFormFeedback field={'checkbox'} errors={form_errors} is_invalid={true}/>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Form>

                        </div>
                    </Col>
                </Row>
                {!props.is_logged_in_user &&
                <Row>
                    <Col xs="12">
                        <Button
                            to={"#"}
                            className="btn-solid btn-block"
                            onClick={() => onSubmit()}
                            disabled={loading.inquire}
                        >
                        {loading.inquire ?
                            <Spinner size="sm"/> : "INQUIRE ABOUT TRIP"
                        }
                        </Button>
                    </Col>
                </Row>
                }
                <Row>
                    <Col xs="12">
                        <Button
                            className="btn-outline btn-share"
                            onClick={() => show('ShareModal', {})}>
                            <img src="/images/web/global/share.svg" alt="" /> Share
                        </Button>
                        <Button
                            className="btn-outline btn-bookmark"
                            onClick={onBookmark}
                            disabled={loading.bookmark}
                        >
                        {loading.bookmark ?
                            <Spinner size="sm"/> :
                            props.is_bookmarked ?
                            <>
                                <img src="/images/web/global/bookmark-lg-filled.svg" alt="" /> Bookmarked
                            </>
                            :
                            <>
                            <img src="/images/web/global/bookmark-lg.svg" alt="" /> Bookmark
                            </>
                        }
                        </Button>
                    </Col>
                </Row>

            </div>
        </div>
    );

}
