import { request } from "js/helpers/api";

export const leadsServices = {
    getAll,
    create,
    exportToExcel,
    update,
    remove,
    get,
    bulkAction,
};

function getAll(params = {}){
    return request({
        method:'GET',
        url: 'leads',
        param: params,
        cancel_token: 'leadsServices.getAll',
    });
}

function create(details){
    return request({
        method:'POST',
        url: 'leads',
        param: details,
        content_type: 'json',
    });
}

function update(details, lead_id){
    return request({
        method:'PUT',
        url: `leads/${lead_id}`,
        param: details,
        content_type: 'json',
    });
}

function remove(lead_id){
    return request({
        method:'DELETE',
        url: `leads/${lead_id}`,
        param: {},
    });
}

function get(lead_id){
    return request({
        method:'GET',
        url: `leads/${lead_id}`,
        param: {},
        cancel_token: 'leadsServices.get',
    });
}

function exportToExcel(params){
    return request({
        method:'GET',
        url: `leads/generate-export-link`,
        param: params,
        cancel_token: 'leadsServices.exportToExcel',
    });
}

function bulkAction(details){
    return request({
        method:'POST',
        url: `leads/bulk-action`,
        param: details,
        content_type: 'json',
    });
}
