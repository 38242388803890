import React, { useState } from "react";
import { Row, Col, Label, Input, FormGroup, CustomInput } from 'reactstrap';

import { ErrorFormFeedback } from 'js/components/common';


export const TripDescription = (props) => {
    const [description, setDescription] = useState(props.trip);

    const form_errors = props.errors

    const handleChange = (key, value) => {
        let data = { ...description, [key]: value };
        setDescription(data);
        props.onUpdate(data);
    }

    return (
            <div className="tt-admin-form-inner">
                <Row form>
                    <Col xs="12">
                        <FormGroup>
                            <Label for="">Trip Description *</Label>
                            <Input
                                type="textarea"
                                value={description.description}
                                onChange={(e) => handleChange('description', e.target.value)}
                                invalid={form_errors && form_errors.description && true}
                            />
                            <ErrorFormFeedback field="description" errors={form_errors} />
                        </FormGroup>
                    </Col>
                </Row>
                <Row form>
                    <Col xs="12">
                        <FormGroup>
                            <Label for="">Trip Accommodations *</Label>
                            <Input
                                type="textarea"
                                value={description.accomodation_description || ''}
                                onChange={(e) => handleChange('accomodation_description', e.target.value)}
                                invalid={form_errors && form_errors.accomodation_description && true}
                            />
                            <ErrorFormFeedback field="accomodation_description" errors={form_errors} />
                        </FormGroup>
                    </Col>
                </Row>
                <Row form>
                    <Col xs="12">
                        <FormGroup>
                            <Label for="">What trips are you interested in trading for? *  </Label>
                            <Input
                                type="text"
                                value={description.interested_trips_to_trade || ''}
                                onChange={(e) => handleChange('interested_trips_to_trade', e.target.value)}
                                invalid={form_errors && form_errors.interested_trips_to_trade && true}
                            />
                            <ErrorFormFeedback field="interested_trips_to_trade" errors={form_errors} />
                        </FormGroup>
                    </Col>
                </Row>
                <Row form>
                    <Col xs="12" lg="4">
                        <FormGroup>
                            <Label for="">Fair Market Value *</Label>
                            <Input
                                type="text"
                                value={description.fair_market_value || ''}
                                onChange={(e) => handleChange('fair_market_value', e.target.value)}
                                invalid={form_errors && form_errors.fair_market_value && true}
                            />
                            <ErrorFormFeedback field="fair_market_value" errors={form_errors} />
                        </FormGroup>
                    </Col>
                </Row>
                <Row form>
                    <Col xs="12" lg="6" className="pt-4">
                        <FormGroup>
                            <Label for="">In addition to trading this trip, are you interested in selling it?</Label>
                            <div className="listing-form-radio">
                                <Row>
                                    <Col xs="6" md="4">
                                        <CustomInput
                                            type="radio"
                                            id="customRadio1"
                                            name="customRadio"
                                            label="Yes"
                                            onChange={() => handleChange('is_interested_in_selling', true)}
                                            checked={description.is_interested_in_selling || false}
                                        />
                                        <span className="ml-2">
                                            <img src="/images/admin/global/info.svg" alt="" />
                                        </span>
                                    </Col>
                                    <Col xs="6" md="4">
                                        <CustomInput
                                            type="radio"
                                            id="customRadio2"
                                            name="customRadio"
                                            label="No"
                                            onChange={() => handleChange('is_interested_in_selling', false)}
                                            checked={!description.is_interested_in_selling}
                                        />
                                    </Col>
                                </Row>
                                {form_errors &&
                                <div className="invalid-feedback d-block">{form_errors['is_interested_in_selling']}</div>
                                }
                            </div>
                        </FormGroup>
                    </Col>
                </Row>
            </div>

    );
}
