import React, { useState, useContext } from 'react';
import { Row, Col, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import { Helmet } from "react-helmet";
import { toast } from 'react-toastify';
import { isEmpty } from 'lodash';

import { supportServices } from 'js/services';
import { NavigationSidebar } from "js/components/web/components/layouts/components/navigation-sidebar";
import { ErrorFormFeedback, RelatedExperience} from 'js/components/common';
import { AuthProvider } from 'js/contexts';

export const RequestNewTrips = () => {
    const [ state, setState ] = useState({
        description : ''
    });
    const [ errors, setErrors ] = useState({});
    const { auth } = useContext(AuthProvider);

    const handleChange = ( value, key) => {
        const newState = { ...state };
        newState[key] = value;
        setState(newState);
    }
    const submit = () => {
        let data = { description : state.description };
        supportServices.submitRequest(data)
        .then((response) => {
            setState({
                description : ''
            });
            setErrors('');
            toast.success('Request Submitted Successfully');
        }).catch((error) => {
            if( error.data && error.data.errors ){
                setErrors(error.data.errors);
            }
            else{
                toast.error('Failed Submit Your Request');
            }
        });
    }
    return (
        <div>
            <Helmet>
                <title>Trip4Trade :: Request New Trips</title>
                <title>Contact - Trips4Trade - Thanks for checking out Trips4Trade.</title>
                <meta name="description" content="Drop us a note. Need help with something? Have a suggestion for a new feature on the site? Just want to say hello? Thanks for checking out Trips4Trade." />
            </Helmet>

            <div className="inner-banner-profile" style={{ backgroundImage: `url(/images/web/global/banners/profile-banner.png)` }}>

            </div>
            <div className="wider-data-frame wider-data-frame--grey pt-0">
                <div className="container">
                    <div className="profile-outer-wrapper">
                        <Row className="no-gutters">
                            <Col xs="12" lg="9">
                                <div className="profile-data-section">
                                    <div className="profile-data-section--top">
                                        <Row>
                                            <Col xs="12" className="pb-4">
                                                <h3><img src="/images/web/global/side-navigation/request-features.svg" alt="" style={{ width: `30px` }} /> Request New Trips / Features</h3>
                                            </Col>
                                        </Row>

                                    </div>
                                    <div className="profile-data-section--bottom pt-5 pb-5">
                                        <Row>
                                            <Col xs="12">
                                                <p>
                                                We are constantly trying to improve Trips4Trade. Your feedback (good or bad) will help us do that. Let us know what you would like to see added by leaving a comment in the box below.
                                                </p>
                                            </Col>
                                            <Col xs="12" className="mb-1">
                                                <Form className="invite-form mt-3">
                                                    <Row>
                                                        <Col xs="12"  className="mb-3">
                                                            <FormGroup>
                                                                <Label for="" >Please describe request or feature</Label>
                                                                <Input
                                                                    type="textarea"
                                                                    value={state.description}
                                                                    onChange={ (e) => handleChange(e.target.value, 'description') }
                                                                    invalid={ !isEmpty(errors) && (errors.description) && true }
                                                                />
                                                                <ErrorFormFeedback field={'description'} errors={errors} />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col xs="12" className="d-flex justify-content-end">
                                                            <div>
                                                                <Button className="btn-outline" onClick={submit}>Send Request</Button>
                                                            </div>

                                                        </Col>
                                                    </Row>
                                                </Form>
                                            </Col>
                                        </Row>

                                    </div>
                                </div>
                            </Col>
                            <NavigationSidebar />
                        </Row>
                    </div>
                </div>
            </div>
            <RelatedExperience user_id={auth.user.user_id}/>

        </div>


    );
}

