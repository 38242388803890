import React from "react"
import { Link } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import "js/components/legos/styles/experience-card-module.css"
import "js/components/legos/styles/trade-in-progress-card-module.css"
import { isEmpty, forEach } from 'lodash';
import moment from "moment";

import { ExperienceCard } from "js/components/legos/experienceCard"
import { useModal } from 'js/hooks';
import { StaticLink } from 'js/components/common';
import { utilFunctions } from 'js/components/common/fucntions';

export const TradeInProgressComponent = (props) => {
    const dateFormat = (start_date, end_date) => {
        let start_date_moment = moment(start_date);
        let end_date_moment = moment(end_date);
        let day_diff = end_date_moment.diff(start_date_moment, 'days');
        let month_diff = end_date_moment.diff(start_date_moment, 'months');
        let year_diff = end_date_moment.diff(start_date_moment, 'years');

        //if(start_date_moment.diff(end_date_moment, 'days'));
        if (day_diff > 0 && month_diff === 0) {
            return `${start_date_moment.format('MMMM Do')} - ${end_date_moment.format('Do, YYYY')}`
            // March 25th - 31st, 2020
        }
        else if (month_diff > 0) {
            return `${start_date_moment.format('MMMM Do')} - ${end_date_moment.format('MMMM Do, YYYY')}`;
            // March 25th - May 03, 2020
        }
        else if (year_diff > 0) {
            return `${start_date_moment.format('MMMM Do')} - ${end_date_moment.format('MMMM Do, YYYY')}`;
            // March 25th - May 03, 2021
        }
        else if (day_diff === 0 && month_diff === 0 && year_diff === 0) {
            return `${start_date_moment.format('MMMM Do')}, ${end_date_moment.format('YYYY')}`;
            // March 21st, 2020
        }
        else {
            return '';
        }

    }
    const [show] = useModal();

    let review_trip_trde_id = null;
    let host_id = null;
    props.trade && props.trade.trip_trades && forEach(props.trade.trip_trades, value => {
        if (value.guest_user_id === props.user_id && !value.trip?.is_reviewed) {
            review_trip_trde_id = value.trip_trade_id
        }
        else {
            if (props.type === 'my-profile') {
                host_id = value.guest_user_id
            }
        }
    })

    return (
        <div className="trip-card">
            <Row>
                { props.trade && !isEmpty( props.trade.trip_trades ) && props.trade.trip_trades.map( ( item, index ) => {
                    item.trip = {
                        ...item.trip,
                        trip_category: utilFunctions.findCategory(item.trip.trip_categories)
                    }
                    return (
                        <React.Fragment key={ index } >
                            { item.trip &&
                                <Col xs="12" sm="6" md="6" lg="6">
                                    <ExperienceCard
                                        linkName={ `/trips/details/${ item.trip.slug }` }
                                        experienceCardImage={
                                            item.trip?.trip_media?.[ 0 ]?.attachment
                                            ? item.trip.trip_media[ 0 ].attachment.thumbnail_url
                                                ?? item.trip.trip_media[ 0 ].attachment.attachment_url
                                            : item.trip?.parent_trip_category?.icon_image_url
                                                ?? item.trip?.trip_category?.featured_image_url
                                                ?? '/images/web/dummy/experience-card/1.png'
                                        }
                                        experienceIcon={ item.trip?.parent_trip_category?.icon_image_url ?? item.trip?.trip_category?.icon_image_url }
                                        experienceName={ item.trip.title }
                                        experienceLocation={
                                            `${ item.trip?.city?.long_name } ${ item.trip?.state?.long_name }, ${ item.trip?.country?.long_name }`
                                        }
                                        peopleCount={ `${ item.trip.accomodating_min_seats }-${ item.trip.accomodating_max_seats }` }
                                        days={ `${ item.trip.duration_min_days }-${ item.trip.duration_max_days }` }
                                        disable_bookmark={ true }
                                    />
                                    <div className="trip-data-wrap mt-4">
                                        <p><strong>Host:</strong> { item.trip.user && item.trip.user.full_name }</p>
                                        <p><strong>{ props.type === 'my-profile' ?
                                            ( item.trip.user && item.trip.user.user_id === props.user_id ? 'My Trip' : 'Their Trip' )
                                            :
                                            `Trip ${ index + 1 }`
                                        }
                                        </strong> { item.arrival_time && item.departure_time ? dateFormat( item.arrival_time, item.departure_time ) : '' }</p>
                                    </div>
                                </Col>
                            }
                        </React.Fragment>
                    );
                })}
            </Row>
            <Row>
                <Col xs="12" >
                    <div className="trip-btn-wrap">
                        <StaticLink
                            url={props.trade && props.trade.browse_gear ? props.trade.browse_gear : ''}
                            content={
                                <>
                                <img src="/images/web/global/gear.svg" alt="" /> Browse Gear
                                </>

                            }
                            class="btn-outline--grey"
                        />
                        <Link
                            to={"#"}
                            className="btn-outline--grey"
                            onClick={() =>
                                show('EditTradeModal', {
                                    selected_trade_id: props.trade && props.trade.trade_id ? props.trade.trade_id : '',
                                    callBack: () => {
                                        props.parentCallBack();
                                    },
                                    user_id: props.user_id ? props.user_id : '',
                                    type: props.type ? props.type : ''
                                })
                            }
                        >
                            <img src="/images/web/global/edit-details.svg" alt="" /> Edit Details
                        </Link>
                        {host_id &&
                            <Link
                                to={"#"}
                                className="btn-outline--orange"
                                onClick={() => show('MessageToHost', {
                                    host_id
                                })}
                            >
                                <img src="/images/web/global/message.svg" alt="" /> Message Host
                            </Link>
                        }
                        {props.trade && props.trade.trade_status.slug === 'completed' && review_trip_trde_id &&
                            <Link
                                to={"#"}
                                className="btn-outline--orange"
                                onClick={() => show('AddReviewModal', {
                                    trip_trade_id: review_trip_trde_id
                                })}
                            >
                                Leave a Review
                        </Link>
                        }
                    </div>
                </Col>
            </Row>
        </div>
    )
}
