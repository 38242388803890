
import React, {useState}  from 'react';
import { UncontrolledCollapse } from 'reactstrap';
import classNames from 'classnames';

import "js/components/legos/styles/faq-accordion-module.css"

const  FaqAccordion = ( props ) =>  {
    const [ open_status, setOpenStatus] = useState(false);

    const accordionToggle = () => {
        setOpenStatus(!open_status);
    }

    return (
        <div className="accordion-outer">
            <div className="accordion-head" id={`toggler${props.sl}`} onClick={accordionToggle}>
                <h5>{props.item && props.item.question} </h5>
                <span className={classNames("arrow-up", { "arrow-down" : open_status })}></span>
            </div>

            <UncontrolledCollapse toggler={`#toggler${props.sl}`} >
               <div className="accordion-data">
                    <p>{props.item && props.item.answer} </p>
               </div>
            </UncontrolledCollapse>
        </div>
    );
}

export default FaqAccordion;
