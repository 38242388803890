import React from "react"
import "js/components/legos/styles/experience-card-module.css";

import { useAdsClick } from 'js/hooks';
import { StaticLink } from 'js/components/common';

export const AdCard = (props)=> {
    const { onAdClick } = useAdsClick();

    return(
        <div className="experience-card trip-advt">
            <StaticLink
                url={props.linkName}
                adClick={true}
                content={
                    <>
                    <div className="experience-card--bottom-section">
                        <div className="name--location">
                            <h3>{props.adName}</h3>
                        </div>
                        <div className="people-days-promo">
                            <div className="links trip-advt-links">
                                <div>{props.subTitle}</div>
                                <div className="promoted float-right" >Ad</div>
                            </div>
                        </div>
                    </div>
                    <img className="experience-card--img" src={props.adImage} alt="" />
                    </>
                }
                onChange={ () => props.advertisement_id && onAdClick(props.advertisement_id)}
            />
        </div>
    )
}
