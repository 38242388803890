import React, { useEffect, useState } from "react";
import Select from 'react-select';
import { Table } from 'reactstrap';
import moment from 'moment';
import { isEmpty, forEach, find } from 'lodash';

import { GraphComponent } from 'js/components/common';
import { Link } from "react-router-dom";

const DayFilter = [
    { value: 'last_7_days', label: 'Last 7 Days' },
    { value: 'last_14_days', label: 'Last 14 Days' },
    { value: 'last_30_days', label: 'Last 30 Days' },
    { value: 'today', label: 'Today' }
];

const DashboardFilter = [
    { value: 'total_listings', label: 'Total Listings' },
    { value: 'countries', label: 'Total Countries' },
    { value: 'states', label: 'Total States' },
    { value: 'active_users', label: 'Total Active Users' },
];

const styles = {
    option: (styles, state) => ({
        ...styles,
        backgroundColor: state.isSelected ? "#FFA405 " : state.isFocused ? "#FFA40520" : ""
    })
};



export const TotalListings = (props) => {

    const [data, setData] = useState([]);
    const [params, setParams] = useState({...props.current_params});

    const title = {
        trip_categories: 'Category',
        countries: 'Country',
        states: 'State'
    }

    useEffect(() => {

        if (props.type === 'total_listings' || props.type === 'active_users') {
            setData(props.api_data);
        }
        else {
            (async function asyncFeeder() {
                await dataFeeder().then((response) => {
                    setData(response)
                })
            })();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.api_data]);


    const dataFeeder = () => {
        let modified_data = [];
        return new Promise((resolve, reject) => {
            const key = {
                countries: 'country',
                states: 'state'
            }
            let key_name = key[props.type]
            if (!isEmpty(props.api_data)) {
                forEach(props.api_data, (item) => {
                    let content = item[key_name];
                    modified_data.push({ ...item, content })
                })
            }
            resolve(modified_data);
        });
    }



    const onDateChange = (value) => {
        let current_params = { ...props.current_params };
        if (value === 'last_7_days') {
            current_params.start_date = moment().subtract(7, 'days').format('YYYY-MM-DD');
            current_params.end_date = moment().format('YYYY-MM-DD');
        }
        else if (value === 'last_14_days') {
            current_params.start_date = moment().subtract(14, 'days').format('YYYY-MM-DD');
            current_params.end_date = moment().format('YYYY-MM-DD');
        }
        else if (value === 'last_30_days') {
            current_params.start_date = moment().subtract(30, 'days').format('YYYY-MM-DD');
            current_params.end_date = moment().format('YYYY-MM-DD');
        }
        else {
            current_params.start_date = moment().format('YYYY-MM-DD');
            current_params.end_date = moment().format('YYYY-MM-DD');
        }
        props.callBack(current_params);
    }

    return (
        <div className="tt-admin-card">
            <div className="tt-admin-card--header">
                <div className="custom-react-select--lg">
                    <Select
                        className="react-select-container"
                        classNamePrefix="react-select"
                        options={DashboardFilter}
                        styles={styles}
                        onChange={(e) => props.callBack({ 'type': e.value })}
                        value={props.current_params && props.current_params.type ?
                            find(DashboardFilter, (item) => { return item.value === props.current_params.type }) : ''}
                    />
                </div>
                {
                    (props.type === 'total_listings' || props.type === 'active_users') &&
                    <div className="custom-react-select--no-border">
                        <Select
                            className="react-select-container"
                            classNamePrefix="react-select"
                            options={DayFilter}
                            styles={styles}
                            onChange={(e) => {
                                onDateChange(e.value);
                                props.callBack({ selected_date: e.value })
                            }}
                            value={props.current_params && props.current_params.selected_date ?
                                find(DayFilter, (item) => { return item.value === props.current_params.selected_date }) : ''}
                        />
                    </div>
                }
            </div>
            {
                (props.type === 'total_listings' || props.type === 'active_users') ?
                    <div className="tt-admin-card--data">
                        <div className="graph-wrap">

                            <div className="dashboard-graph">
                                <GraphComponent
                                    graph_data={data}
                                    type={`dashboard_${props.type}`}
                                    graph_type="line"
                                />
                            </div>

                        </div>
                    </div>
                    :
                    <div className="tt-admin-card--data">
                        <div className="table-wrap">
                            {data && isEmpty(data) ?
                                <div className="no-records-found">
                                    <p>No Records Found.</p>
                                </div>
                                :
                                <Table responsive className="tt-admin-table-compact">
                                    <thead>
                                        <tr>
                                            <th>{props.type ? title[props.type] : ''}</th>
                                            <th
                                                className="sortable"
                                                onClick={() => {
                                                    setParams({...params, sort: 'number_of_trips'});
                                                    props.callBack({...params, sort: params.sort === 'number_of_trips' ? `-number_of_trips` : 'number_of_trips' })
                                                }}
                                            >Number of Trips</th>
                                            <th
                                                className="sortable"
                                                onClick={() => {
                                                    setParams({...params, sort: 'percentage'});
                                                    props.callBack({...params, sort: params.sort ===  'percentage' ? `-percentage` : 'percentage' })}
                                                }
                                            >% of Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            !isEmpty(data) && data.map((item, index) => (
                                                <tr key={`list_data${index}`}>
                                                    <td>
                                                        {!isEmpty(item.content) ? item.content : ''}
                                                    </td>
                                                    <td>{item.number_of_trips ? item.number_of_trips : 0}</td>
                                                    <td>{item.percentage ? item.percentage : ''}</td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>

                                </Table>
                            }
                        </div>
                    </div>
            }
            {
                (props.type === 'total_listings' || props.type === 'active_users') &&
                <div className="tt-admin-card--footer">
                    <Link className="btn btn-solid-md" to="/admin/trip-management">View All Listings</Link>
                </div>
            }
        </div>

    );
}
