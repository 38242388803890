import forEach from "lodash/forEach";

const getLookups = (response) => {
    return new Promise((resolve, reject) => {
        let lookup_data = {
            advertisement_positions: [],
            advertisement_types: [],
            article_statuses: [],
            countries: [],
            trip_categories: [],
            trip_statuses: [],
            article_categories: [],
            lead_status: [],
            user_status: [],
            user_roles: [],
            social_media: [],
            ways_to_support: [],
            promotional_campaign_types: [],
            promotional_campaign_applicable_user_types: [],
            membership_upgrade_plan_types: [],
            issue_type: [],
            inquiry_types: [],
            referral_types: [],
            trade_status: [],
            endorsement_statuses: [],
            endorsement_types: [],
            states: [],
            settings: [],
            membership_entity_types: [],
            promotion_types: [],
            feature_tags: [],
        };
        if (response) {
            if (response.advertisement_positions) {
                response.advertisement_positions.map(item => (
                    lookup_data.advertisement_positions.push(
                        {
                            ...item,
                            value: item.advertisement_position_id,
                            advertisement_position_id: item.advertisement_position_id,
                        }
                    )
                ));
            }
            if (response.advertisement_types) {
                response.advertisement_types.map(item => (
                    lookup_data.advertisement_types.push({ ...item, value: item.slug })
                ));
            }
            if (response.article_statuses) {
                response.article_statuses.map(item => (
                    lookup_data.article_statuses.push({ ...item, value: item.article_status_id })
                ));
            }
            if (response.countries) {
                response.countries.map(item => (
                    lookup_data.countries.push(
                        {
                            ...item,
                            value: item.country_id,
                            label: item.long_name,
                        })
                ));
            }
            if (response.trip_categories) {
                response.trip_categories.map(item => (
                    lookup_data.trip_categories.push({ ...item, value: item.trip_category_id })
                ));
            }
            if (response.trip_statuses) {
                response.trip_statuses.map(item => (
                    lookup_data.trip_statuses.push({ ...item, value: item.trip_status_id })
                ));
            }
            if (response.article_categories) {
                response.article_categories.map(item => (
                    lookup_data.article_categories.push({ ...item, value: item.article_category_id })
                ));
            }
            if (response.lead_status) {
                response.lead_status.map(item => (
                    lookup_data.lead_status.push({ ...item, value: item.slug })
                ));
            }
            if (response.user_status) {
                response.user_status.map(item => (
                    lookup_data.user_status.push({ ...item, value: item.user_status_id })
                ));
            }
            if (response.user_roles) {
                response.user_roles.map(item => (
                    lookup_data.user_roles.push({ ...item, value: item.name })
                ));
            }
            if (response.social_media) {
                response.social_media.map(item => (
                    lookup_data.social_media.push({ ...item, value: item.social_media_id })
                ));
            }
            if (response.ways_to_support) {
                response.ways_to_support.map(item => (
                    lookup_data.ways_to_support.push({ ...item, value: item.way_to_support_id })
                ));
            }
            if (response.promotional_campaign_applicable_user_types) {
                response.promotional_campaign_applicable_user_types.map(item => (
                    lookup_data.promotional_campaign_applicable_user_types.push({ ...item, value: item.promotional_campaign_applicable_user_type_id })
                ));
            }
            if (response.promotional_campaign_types) {
                response.promotional_campaign_types.map(item => (
                    lookup_data.promotional_campaign_types.push({ ...item, value: item.promotional_campaign_type_id })
                ));
            }


            if (response.membership_upgrade_plan_types) {
                response.membership_upgrade_plan_types.map(item => (
                    lookup_data.membership_upgrade_plan_types.push({ ...item, value: item.membership_plan_upgrade_type_id })
                ));
            }
            if (response.issue_type) {
                response.issue_type.map(item => (
                    lookup_data.issue_type.push({ ...item, value: item.issue_type_id })
                ));
            }
            if (response.inquiry_types) {
                response.inquiry_types.map(item => (
                    lookup_data.inquiry_types.push({ ...item, value: item.inquiry_type_id })
                ));
            }
            if (response.referral_types) {
                response.referral_types.map(item => (
                    lookup_data.referral_types.push({ ...item, value: item.referral_type_id })
                ));
            }
            if (response.trade_status) {
                response.trade_status.map(item => (
                    lookup_data.trade_status.push({ ...item, value: item.slug })
                ));
            }
            if (response.endorsement_statuses) {
                response.endorsement_statuses.map(item => (
                    lookup_data.endorsement_statuses.push({ ...item, value: item.slug })
                ));
            }
            if (response.endorsement_types) {
                response.endorsement_types.map(item => (
                    lookup_data.endorsement_types.push({ ...item, value: item.slug })
                ));
            }
            if (response.states) {
                response.states.map(item => (
                    lookup_data.states.push(
                        {
                            ...item,
                            value: item.state_id,
                            label: item.long_name,
                        })
                ));
            }
            if (response.settings) {
                response.settings.map(item => (
                    lookup_data.settings.push(
                        {
                            ...item,
                            value: item.slug,
                        })
                ));
            }
            if (response.membership_entity_types) {
                response.membership_entity_types.map(item => (
                    lookup_data.membership_entity_types.push(
                        {
                            ...item,
                            value: item.slug,
                        })
                ));
            }
            if (response.promotion_types) {
                response.promotion_types.map(item => (
                    lookup_data.promotion_types.push(
                        {
                            ...item,
                            value: item.slug,
                        })
                ));
            }
            if (response.feature_tags) {
                response.feature_tags.map(item => (
                    lookup_data.feature_tags.push(
                        {
                            ...item,
                            value: item.slug,
                        })
                ));
            }
            forEach(response, (lookup, key) => {
                if(typeof(lookup) !== 'object') {
                    lookup_data[key] = lookup
                }
            })

            resolve(lookup_data);
        }
    })
}


export const lookupFucntions = {
    getLookups
}
