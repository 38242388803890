import React, { useState, useEffect } from "react";
import { Row, Col, Form, FormGroup, Label, Input, Button, Spinner } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { isEmpty, debounce } from 'lodash';
import queryString from 'query-string';


import { authServices } from 'js/services/auth-service';
import { ErrorFormFeedback } from 'js/components/common';
import { history } from 'js/helpers';
import { toast } from "react-toastify";





export const ResetPassword = (props) => {

    const [state, setState] = useState({});
    const [errors, setErrors] = useState({
        message: ''
    });
    const [ loading, setLoading ] = useState(false);

    const apiCalls = {
        resetPasswordLink: debounce(() => {
            setLoading(true);
            authServices.resetPasswordLink(state)
                .then((response) => {
                    setLoading(false);
                    setState({ ...state, message: response.message, token: '' });
                    setErrors({});
                })
                .catch((err) => {
                    setLoading(false);
                    let create_errors = err.data ? err.data.errors : '';
                    setErrors(create_errors);
                });
        }, 300),

        resetPassword: debounce(() => {
            setLoading(true);
            authServices.resetPassword(state)
                .then((response) => {
                    setLoading(false);
                    setErrors({});
                    history.push('/auth');
                    toast.success('Your password has been reset successfully')
                })
                .catch((err) => {
                    setLoading(false);
                    let create_errors = err.data ? err.data.errors : '';
                    setErrors(create_errors);
                });
        }, 300),
    }

    useEffect(() => {
        if (props.location && props.location.search) {
            let query = queryString.parse(props.location.search);
            setState({ ...state, token: query.token, email: query.email })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSubmit = (e) => {
        e.preventDefault();
        if (!isEmpty(state.token)) {
            apiCalls.resetPassword();
        }
        else {
            apiCalls.resetPasswordLink();
        }
    }
    return (
        <div>
            <Helmet>
                <title>Trip4Trade :: Login</title>
                <meta name="description" content="Login page desription" />
                <meta name="keywords" content="trip4trade, Login" />
            </Helmet>

            <div className="inner-banner inner-banner--auth">
                <div className="container">
                    <Row >
                        <Col xs="12">
                            <div className="tt-login-wrap">
                                <div className="tt-card">
                                    {
                                        isEmpty(state.message) ?
                                            <>
                                                <div className="tt-login-head-wrap">
                                                    <div className="tt-login-head-icon">
                                                        <img src="/images/web/auth/tt-contact-icon.png" width="70px" height="70px" alt="" />
                                                    </div>
                                                    <div className="tt-login-head">
                                                        <h3 className="tt-login-head--text">Reset Password</h3>
                                                    </div>
                                                </div>
                                                <Form className="tt-login-form">
                                                    <Row>
                                                        <Col xs="12">
                                                            <FormGroup>
                                                                <Label for="">Email Address</Label>
                                                                <Input
                                                                    type="email"
                                                                    name="email"
                                                                    id=""
                                                                    placeholder=""
                                                                    onChange={(e) => setState({ ...state, email: e.target.value })}
                                                                    value={state.email ? state.email : ""}
                                                                    invalid={!isEmpty(errors) && (errors.email || errors.user)
                                                                        && true
                                                                    }
                                                                    onKeyDown={(e) => e.key === 'Enter' && onSubmit(e)}
                                                                />
                                                                <ErrorFormFeedback field={'email'} errors={errors} />
                                                                <ErrorFormFeedback field={'general'} errors={errors} is_invalid={true}/>
                                                                <ErrorFormFeedback field={'user'} errors={errors} is_invalid={true}/>
                                                            </FormGroup>
                                                        </Col>
                                                        {
                                                            !isEmpty(state.token) &&
                                                            <>
                                                                <Col xs="12">
                                                                    <FormGroup>
                                                                        <Label for="">Password</Label>
                                                                        <Input
                                                                            type="password"
                                                                            name="password"
                                                                            id=""
                                                                            placeholder=""
                                                                            onChange={(e) => setState({ ...state, password: e.target.value })}
                                                                            invalid={!isEmpty(errors) && (errors.password)
                                                                                && true}
                                                                        />
                                                                        <ErrorFormFeedback field={'password'} errors={errors} />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col xs="12">
                                                                    <FormGroup>
                                                                        <Label for="">Confirm Password</Label>
                                                                        <Input
                                                                            type="password"
                                                                            name="confirm_password"
                                                                            id=""
                                                                            placeholder=""
                                                                            onChange={(e) => setState({ ...state, password_confirmation: e.target.value })}
                                                                            invalid={!isEmpty(errors) && (errors.confirm_password)
                                                                                && true}
                                                                        />
                                                                        <ErrorFormFeedback field={'confirm_password'} errors={errors} />
                                                                        <ErrorFormFeedback field={'general'} errors={errors} is_invalid={true}/>
                                                                    </FormGroup>
                                                                </Col>
                                                            </>
                                                        }
                                                        <Col xs="12" className="d-flex justify-content-center my-4">
                                                            <Button
                                                                to={"#"}
                                                                className="btn btn-outline text-capitalize"
                                                                onClick={(e) => onSubmit(e)}
                                                                disabled={loading}
                                                            >
                                                                {loading ? <Spinner size="sm"/> : 'Reset Password'}
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                </Form>
                                            </>
                                            :
                                            <p>{state.message}</p>
                                    }
                                </div>
                                <div className="login-link-wrap">
                                    <p className="tt-login-foter--text">Don't have an account yet?  <Link to={'/auth/signup'}>Sign Up</Link></p>
                                    <p className="tt-login-foter--text"><Link className="pl-1" to={"/auth/login"}>Login Here</Link></p>
                                </div>
                            </div>

                        </Col>
                    </Row>
                </div>
            </div>

        </div>
    );
}

