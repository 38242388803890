import React from "react";
import { Row, Col, Label, Input, Form, FormGroup, Button, Spinner } from 'reactstrap';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { useState, useContext, useEffect } from "react";
import DatePicker from 'react-datepicker';
import moment from "moment";
import { isEmpty, forEach, omit, map, find, isEqual } from 'lodash';
import Geosuggest from 'react-geosuggest';

import { LookupContext } from 'js/contexts';
import { UseErrors } from 'js/hooks';
import { ErrorFormFeedback, TreeContainer } from 'js/components/common';
import { tripHash } from 'js/components/common/fucntions';

const styles = {
    option: (styles, state) => ({
        ...styles,
        backgroundColor: state.isSelected ? "#FFA405 " : state.isFocused ? "#FFA40520" : ""
    })
};
export const TripDetailsTab = (props) => {
    const [trip, setTrip] = useState({});
    const detail_keys = [
        'title', 'state', 'accomodating_min_seats',
        'accomodating_max_seats', 'city',
        'duration_max_days', 'duration_min_days',
        'trip_categories', 'trip_duration'
    ];
    const lookups = useContext(LookupContext);
    const [form_errors, getErrors] = UseErrors({})
    const error_hash = [
        tripHash.title,
        tripHash.country,
        tripHash.city,
        tripHash.trip_start_date,
        tripHash.trip_end_date,
        tripHash.duration_min_days,
        tripHash.duration_max_days,
        tripHash.categories,
        tripHash.feature_tags,
        tripHash.accomodating_max_seats,
        tripHash.accomodating_min_seats
    ]

    useEffect(() => {
        if (props.trip) {
            let details = {}
            let attachments = [];
            map(detail_keys, key => {
                details[key] = props.trip[key] ? props.trip[key] : ''
            })

            let feature_tags = [];
            props.trip.feature_tags && map(props.trip.feature_tags, tag => {
                feature_tags.push({ value: tag.slug, label: tag.label })
            })
            if(props.trip.trip_media) {
                attachments = props.trip.trip_media.map(item => item.attachment && item.attachment.attachment_id)

            }
            details = {
                ...details,
                country: props.trip.country ? { ...props.trip.country, label: props.trip.country.long_name } : {},
                feature_tags,
                attachments,
                trip_start_date: moment(props.trip.trip_start_date).isValid() ? moment(props.trip.trip_start_date).toDate() : '',
                trip_end_date: moment(props.trip.trip_end_date).isValid() ? moment(props.trip.trip_end_date).toDate() : ''
            }
            setTrip(details)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.trip]);

    useEffect(() => {
        if (isEmpty(form_errors)) {
            let details = setData()
            props.onChange({ details, next_tab: 'description' })
        }
        //eslint disabled for props
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form_errors])

    const handleChange = (key, value) => {
        let data = {
            ...trip
        }
        if(key === 'country') {
            data.location = '';
            data.city = '';
        }
        data[key] = value
        setTrip(data)
    }

    const handleAddressChange = (place) => {
        let state = {};
        let city = {};
        let country = {};
        let latitude = '';
        let longitude = '';
        if (!isEmpty(place)) {
            if (place.gmaps && place.gmaps.address_components) {
                forEach(place.gmaps.address_components, (value, key) => {
                    value.types && forEach(value.types, type => {
                        let is_state = false
                        let is_city = false
                        if (type === "administrative_area_level_1") {
                            state = { ...omit(value, 'types') }
                            is_state = true
                        }
                        if (type === "administrative_area_level_2" && !is_state) {
                            state = { ...omit(value, 'types') }
                            is_state = true
                        }
                        if (type === "locality") {
                            city = { ...omit(value, 'types') }
                            is_city = true
                            if(!(is_state)) {
                                state = {...omit(value, 'types') }
                            }
                        }
                        if(type === 'sublocality_level_1' && !is_city) {
                            is_city = true
                            city = {...omit(value, 'types') }

                        }
                        if( type === "country" ){
                            country = { ...omit(value, 'types') }
                        }
                        if(type === 'route' && isEqual(state, city)){
                            city = { ...omit(value, 'types') }
                        }

                    })

                })
            }
            if( country.short_name ){
                country = find(lookups.countries, ['short_name',  country.short_name] );
            }
            if (place.location) {
                latitude = place.location.lat
                longitude = place.location.lng
            }

            let new_trip = {
                ...trip,
                state,
                city,
                latitude,
                longitude,
                country,
                location: place && place.description ? place.description : ''
            }
            setTrip(new_trip)
        }
    }

    const setData = () => {
        const {
            title, state, accomodating_min_seats, accomodating_max_seats, city,
            duration_max_days, duration_min_days, country, trip_categories,
            latitude, longitude, attachments, trip_start_date, trip_end_date,

        } = trip;
        let feature_tags = trip.feature_tags && trip.feature_tags.map( item => item.value );
        let start_date = moment( trip_start_date ).startOf( "month" ).isBefore( moment() ) ?
        moment().format( 'YYYY-MM-DD' ) : moment( trip_start_date ).startOf( "month" ).format( 'YYYY-MM-DD' );
        let details = {
            country_id: country ? country.country_id : '',
            trip_start_date: trip_start_date ? start_date : '',
            trip_end_date: trip_end_date ? moment(trip_end_date).endOf('month').format('YYYY-MM-DD') : '',
            trip_categories: trip_categories?.map( item => item?.trip_category_id ),
            feature_tags,
            title,
            state,
            accomodating_min_seats,
            accomodating_max_seats,
            city,
            duration_max_days,
            duration_min_days,
            latitude,
            longitude,
            country: trip.country ? {
                short_name: trip.country.short_name,
                long_name: trip.country.long_name
            } : '',
            attachments
        }
        return details
    }

    const handleSubmit = () => {

        let details = setData();
        if (props.trip) {
            props.onUpdate(details)
        }
        else {
            getErrors(error_hash, { ...trip, ...details })
        }
    }
    const onTreeChange = (selectedNodes) => {
        setTrip({...trip, trip_categories: selectedNodes})
    }

    let errors = form_errors;
    if(!isEmpty(props.errors)) {
        errors = props.errors
    }

    return (
        <Form className="tt-admin-form">
            <div className="tt-admin-form-inner">
                <Row form>
                    <Col xs="12">
                        <FormGroup>
                            <Label for="">Trip Title * </Label>
                            <Input
                                type="text"
                                value={trip.title || ''}
                                onChange={(e) => handleChange('title', e.target.value)}
                                invalid={errors.title && true}
                            />
                            <ErrorFormFeedback field="title" errors={errors} />
                        </FormGroup>
                    </Col>
                </Row>
                <Row form>
                    <Col xs="12" md="4">
                        <FormGroup>
                            <Label for="">Country * </Label>
                            <div className="tt-custom-react-select">
                                <Select
                                    className="react-select-container"
                                    classNamePrefix="react-select"
                                    options={lookups.countries}
                                    styles={styles}
                                    onChange={(e) => handleChange('country', e)}
                                    value={trip.country}
                                />
                                <div className="invalid-feedback d-block">{errors['country']}</div>
                            </div>
                        </FormGroup>
                    </Col>
                    <Col xs="12" md="8">
                        <FormGroup>
                            <Label for="">Closest Town/City * </Label>
                            <div className="tt-custom-react-select">
                                <Geosuggest
                                    types={['(cities)']}
                                    name="address"
                                    className="react-select-container"
                                    inputClassName={"form-control"}
                                    placeholder=""
                                    initialValue={
                                        trip.location ?
                                        trip.location :
                                        `${trip.city && trip.city.long_name ?
                                        trip.city.long_name : ''}${trip.city && trip.city.long_name && trip.state ?
                                        `, ${trip.state.long_name}` : ''
                                        }`
                                    }
                                    value={trip.location}
                                    autoComplete="nope"
                                    onSuggestSelect={handleAddressChange}
                                    country={trip.country ? trip.country.short_name : ''}
                                    onChange={(e) => handleChange('city', e)}
                                />
                                <div className="invalid-feedback d-block">{errors['city']}</div>
                                <div className="invalid-feedback d-block">{errors['state']}</div>
                            </div>
                        </FormGroup>
                    </Col>
                </Row>
                <Row form>
                    <Col xs="12" md="4">
                        <FormGroup>
                            <Label for="">Accommodates *</Label>
                            <Row>
                                <Col xs="6">
                                    <FormGroup>
                                        <div className="tt-custom-react-select">
                                            <Input
                                                placeholder="Min seats"
                                                type="text"
                                                value={trip.accomodating_min_seats || ''}
                                                onChange={(e) => handleChange('accomodating_min_seats', e.target.value)}
                                                invalid={errors.accomodating_min_seats && true}
                                            />
                                            <ErrorFormFeedback field="accomodating_min_seats" errors={errors} />
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col xs="6">
                                    <FormGroup>
                                        <div className="tt-custom-react-select">
                                            <Input
                                                placeholder="Max seats"
                                                type="text"
                                                value={trip.accomodating_max_seats || ''}
                                                onChange={(e) => handleChange('accomodating_max_seats', e.target.value)}
                                                invalid={errors.accomodating_max_seats && true}
                                            />
                                            <ErrorFormFeedback field="accomodating_max_seats" errors={errors} />
                                        </div>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </FormGroup>
                    </Col>
                    <Col xs="12" md="6" lg="4">
                        <FormGroup>
                            <Label for="">Trip Date Range *</Label>
                            <Row>
                                <Col xs="12" md="6">
                                    <div className="custom-react-select custom-month-picker">
                                        <DatePicker
                                            placeholderText="Start date"
                                            selected={trip.trip_start_date}
                                            onChange={(e) => handleChange('trip_start_date', e)}
                                            selectsStart
                                            startDate={trip.trip_start_date}
                                            endDate={trip.trip_end_date}
                                            dateFormat="MM/yyyy"
                                            showMonthYearPicker
                                            minDate={moment().subtract(1, 'months').endOf('month').toDate()}
                                            className='tt-month-range-picker'
                                        />
                                        <ErrorFormFeedback field="trip_start_date" errors={errors} is_invalid/>

                                    </div>
                                </Col>
                                <Col xs="12" md="6">
                                    <div className="custom-react-select custom-month-picker">
                                        <DatePicker
                                            placeholderText="End date"
                                            selected={trip.trip_end_date}
                                            onChange={(e) => handleChange('trip_end_date', e)}
                                            selectsEnd
                                            startDate={trip.trip_start_date}
                                            endDate={trip.trip_end_date}
                                            dateFormat="MM/yyyy"
                                            showMonthYearPicker
                                            minDate={ trip.trip_start_date }
                                            className='tt-month-range-picker'
                                        />
                                        <ErrorFormFeedback field="trip_end_date" errors={errors} is_invalid/>
                                    </div>
                                </Col>
                            </Row>
                        </FormGroup>
                    </Col>
                    <Col xs="12" md="4">
                        <FormGroup>
                            <Label for="">Trip Duration *</Label>
                            {trip.trip_duration ?
                                <Row>
                                    <Col xs="6">
                                        <FormGroup>
                                            <div className="tt-custom-react-select">
                                                <Input
                                                    placeholder="Trip Duration"
                                                    type="text"
                                                    value={trip.trip_duration || ''}
                                                />
                                            </div>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            :
                                <Row>
                                    <Col xs="6">
                                        <FormGroup>
                                            <div className="tt-custom-react-select">
                                                <Input
                                                    placeholder="Min Duration"
                                                    type="text"
                                                    value={trip.duration_min_days || ''}
                                                    onChange={(e) => handleChange('duration_min_days', e.target.value)}
                                                    invalid={errors.duration_min_days && true}
                                                />
                                                <ErrorFormFeedback field="duration_min_days" errors={errors} />
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    <Col xs="6">
                                        <FormGroup>
                                            <div className="tt-custom-react-select">
                                                <Input
                                                    className="max-duration"
                                                    placeholder="Max Duration"
                                                    type="text"
                                                    value={trip.duration_max_days || ''}
                                                    onChange={(e) => handleChange('duration_max_days', e.target.value)}
                                                    invalid={errors.duration_max_days && true}
                                                />
                                                <ErrorFormFeedback field="duration_max_days" errors={errors} />
                                            </div>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            }
                        </FormGroup>
                    </Col>
                </Row>
                <Row form>
                    <Col xs="6">
                        <FormGroup>
                            <Label for="">Trip Category * </Label>
                            <div className="tt-custom-react-select">
                                {/* <Select
                                    className="react-select-container"
                                    classNamePrefix="react-select"
                                    options={lookups.trip_categories}
                                    styles={styles}
                                    onChange={(e) => handleChange('trip_category', e)}
                                    value={trip.trip_category}
                                /> */}
                                <TreeContainer
                                    data={ lookups.trip_categories }
                                    onChange={onTreeChange}
                                    mode="multiSelect"
                                    className="tt-tree-filter-container"
                                    selectedCategories={props?.trip?.trip_categories}
                                    placeholder="Trip Category"
                                />
                                <div className="invalid-feedback d-block">{errors['trip_categories']}</div>
                            </div>
                        </FormGroup>
                    </Col>
                    <Col xs="6">
                        <FormGroup>
                            <Label for="">Features *</Label>
                            <div className="tt-custom-react-select">
                                <CreatableSelect
                                    className="react-select-multi-container"
                                    classNamePrefix="react-select"
                                    isMulti
                                    styles={styles}
                                    onChange={(e) => handleChange('feature_tags', e)}
                                    value={trip.feature_tags}
                                    options={lookups.feature_tags}
                                />
                                <div className="invalid-feedback d-block">{errors['feature_tags']}</div>
                            </div>
                        </FormGroup>
                    </Col>
                </Row>
            </div>
            <div className="tt-admin-form-footer">
                {props.trip ?
                    <Button
                        color="primary"
                        className="add-new-btn"
                        onClick={handleSubmit}
                        disabled={props.loading}
                    >{props.loading ? <Spinner size="sm" /> : 'Save Trip Details'}
                    </Button>
                    :
                    <Button
                        color="primary"
                        className="add-new-btn"
                        onClick={handleSubmit}
                    >Next
                    </Button>
                }
            </div>
        </Form>
    );
}
