import React, { useState, useEffect } from "react";
import { Row, Col, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { isEmpty } from 'lodash';
import classnames from 'classnames';

import { reportServices } from 'js/services';
import { ListLoader, GraphComponent, DateFilterComponent } from 'js/components/common';

export const ExchangesReport = () => {

    const [loading, setLoading] = useState(false);
    const [exchanges_report, setexchangesReport] = useState({});
    // eslint-disable-next-line
    const [params, setParams] = useState({
        start_date: moment().subtract(7, 'days').format('YYYY-MM-DD'),
        end_date: moment().format('YYYY-MM-DD'),
    });


    const apiCalls = {
        getExchangeReoprts: (params) => {
            setLoading(true);
            reportServices.exchangeReport(params).then((response) => {
                setexchangesReport(response);
                setLoading(false);
            }).catch((err) => {
                setLoading(false);
            });
        },
    }

    useEffect(() => {
        apiCalls.getExchangeReoprts(params);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const exportToExcel = () => {
        reportServices.exportToExcel(params, 'exchanges').then((response) => {
            window.open(response);
        });
    }

    return (
        <div>
            <div className="generic-page-header">
                <div className="header-data-section">
                    <h2 className="page-head my-0">Reports / Exchanges</h2>
                </div>
                <div className="header-data-section">
                    <Button
                        color="secondary"
                        className="export-to-excel-btn mr-3"
                        onClick={() => exportToExcel()}
                    >
                        <i>
                            <svg width="19" height="17" viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M14.728 12.8206H11.9003L9.97152 10.0926L7.94478 12.8206H5.10477L8.62819 8.38936L5.78315 4.51387H8.6517L9.98439 6.62121L11.4044 4.51387H14.3233L11.3406 8.38936L14.728 12.8206ZM18.4585 16.6071H1.66699V0.570107H18.4585V16.6071ZM3.2801 14.994H16.8426V2.18433H3.2801"
                                    fill="#10C56E"
                                />
                            </svg>
                        </i>Export to Excel
                    </Button>
                    {/* TODO_HERE */}
                    <DateFilterComponent params={ params } onChange={ ( data ) => { setParams( data ); apiCalls.getExchangeReoprts(data);}}/>
                </div>
            </div>



            <div className="tt-admin-card">
                <div className="tt-admin-card--header tt-admin-card--header__width-bg d-block">
                    <Row>
                        {
                            !isEmpty(exchanges_report) &&
                            <Col xs="12">
                                <Link to={"#"} className="exchange-card-sm">
                                    <div className="exchange-label">Total Trip <br className="d-none d-md-block" />Inquiries</div>
                                    <div className="exchange-values">
                                        <h4>{exchanges_report?.total_trip_inquiries}</h4>
                                        <p
                                            className={ classnames( "color--red", {
                                                'color--green': exchanges_report?.total_trip_inquiries_percentage?.[ 0 ] === '+'
                                            } ) }
                                        >
                                            {exchanges_report?.total_trip_inquiries_percentage}
                                        </p>
                                    </div>
                                </Link>
                                <Link to={"#"} className="exchange-card-sm">
                                    <div className="exchange-label">Total <br className="d-none d-md-block" />Interested</div>
                                    <div className="exchange-values">
                                        <h4>{exchanges_report?.total_interested}</h4>
                                        <p
                                            className={ classnames( "color--red", {
                                                'color--green': exchanges_report?.total_interested_percentage?.[ 0 ] === '+'
                                            } ) }
                                        >
                                            {exchanges_report?.total_interested_percentage}</p>
                                    </div>
                                </Link>
                                <Link to={"#"} className="exchange-card-sm">
                                    <div className="exchange-label">Total Not <br className="d-none d-md-block" />Interested</div>
                                    <div className="exchange-values">
                                        <h4>{exchanges_report?.total_not_interested }</h4>
                                        <p
                                            className={ classnames( "color--red", {
                                                'color--green': exchanges_report?.total_not_interested_percentage?.[ 0 ] === '+'
                                            } ) }
                                        >{exchanges_report?.total_not_interested_percentage}</p>
                                    </div>
                                </Link>
                                <Link to={"#"} className="exchange-card-sm">
                                    <div className="exchange-label">Total In <br className="d-none d-md-block" />Progress</div>
                                    <div className="exchange-values">
                                        <h4>{exchanges_report?.total_in_progress}</h4>
                                        <p
                                            className={ classnames( "color--red", {
                                                'color--green': exchanges_report?.total_in_progress_percentage ?. [ 0 ] === '+'
                                            } ) }
                                        >{exchanges_report?.total_in_progress_percentage}</p>
                                    </div>
                                </Link>
                                <Link to={"#"} className="exchange-card-sm">
                                    <div className="exchange-label">Total <br className="d-none d-md-block" />Completed</div>
                                    <div className="exchange-values">
                                        <h4>{exchanges_report?.total_complete}</h4>
                                        <p
                                            className={ classnames( "color--red", {
                                                'color--green': exchanges_report?.total_complete_percentage?.[ 0 ] === '+'
                                            } ) }
                                        >
                                        {exchanges_report?.total_complete_percentage}</p>
                                    </div>
                                </Link>
                                <Link to={"#"} className="exchange-card-sm">
                                    <div className="exchange-label">Total <br className="d-none d-md-block" />Cancelled</div>
                                    <div className="exchange-values">
                                        <h4>{exchanges_report?.total_cancelled}</h4>
                                        <p
                                            className={ classnames( "color--red", {
                                                'color--green': exchanges_report?.total_cancelled_percentage ?.[ 0 ] === '+'
                                            } ) }
                                        >
                                        {exchanges_report?.total_cancelled_percentage}</p>
                                    </div>
                                </Link>
                                <Link to={"#"} className="exchange-card-sm">
                                    <div className="exchange-label">Unique <br className="d-none d-md-block" />Inqueries</div>
                                    <div className="exchange-values">
                                        <h4>{exchanges_report?.unique_inquiries}</h4>
                                        <p
                                            className={ classnames( "color--red", {
                                                'color--green': exchanges_report?.unique_inquiries_percentage ?. [ 0 ] === '+'
                                            } ) }
                                        >
                                        {exchanges_report?.unique_inquiries_percentage}</p>
                                    </div>
                                </Link>
                                <Link to={"#"} className="exchange-card-sm">
                                    <div className="exchange-label">Average # of <br className="d-none d-md-block" />Inquiries per Person</div>
                                    <div className="exchange-values">
                                        <h4>{exchanges_report?.average_no_of_inquiries_per_person }</h4>
                                        <p
                                            className={ classnames( "color--red", {
                                                'color--green': exchanges_report?.average_no_of_inquiries_percentage ?.[ 0 ] === '+'
                                            } ) }

                                        >{exchanges_report?.average_no_of_inquiries_percentage}</p>
                                    </div>
                                </Link>
                                <Link to={"#"} className="exchange-card-sm">
                                    <div className="exchange-label">Average Match <br className="d-none d-md-block" />Rate</div>
                                    <div className="exchange-values">
                                        <h4>{exchanges_report?.average_match_rate}</h4>
                                        <p
                                            className={ classnames( "color--red", {
                                                'color--green': exchanges_report?.average_match_rate_percentage ?.[ 0 ] === '+'
                                            } ) }
                                        >{exchanges_report?.average_match_rate_percentage}</p>
                                    </div>
                                </Link>
                                <Link to={"#"} className="exchange-card-sm">
                                    <div className="exchange-label">Average <br className="d-none d-md-block" />Response Rate</div>
                                    <div className="exchange-values">
                                        <h4>{exchanges_report?.average_response_rate}</h4>
                                        <p
                                            className={ classnames( "color--red", {
                                                'color--green': exchanges_report?.average_response_rate_percentage ?.[ 0 ] === '+'
                                            } ) }
                                        >
                                            {exchanges_report?.average_response_rate_percentage}</p>
                                    </div>
                                </Link>

                            </Col>

                        }

                    </Row>
                </div>
                <div className="tt-admin-card--data">
                    <div className="graph-wrap">
                        <div className="sweet-loading">
                            {
                                loading ?
                                    <ListLoader />
                                    :
                                    <div className="global_graph">
                                        <GraphComponent
                                            graph_data={exchanges_report && exchanges_report.inquiry_graph_data}
                                            type='report_exchange'
                                            graph_type="bar"
                                            responsive="true"
                                            maintainAspectRatio="true"
                                        />
                                    </div>
                            }
                        </div>
                    </div>
                </div>

            </div>
        </div>


    );
}
