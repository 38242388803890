const title = {label: 'Title', key: 'title'}
const country = {label: 'Country', key: 'country'}
const city = {label: 'City', key: 'city', type: 'object'}
const date = { label: 'Date Range', type: 'object', key: 'date' }
const trip_start_date = { label: 'Start date', type: 'date', key: 'trip_start_date' }
const trip_end_date = { label: 'End Date', type: 'date', key: 'trip_end_date'}
const duration_min_days = {label: 'Min duration', type: 'number', key: 'duration_min_days'}
const duration_max_days = {label:'Max duration', type: 'number', key: 'duration_max_days'}
const trip_category_id = {label: 'Trip category', type: 'number', key: 'trip_category_id'}
const feature_tags = {label:'Trip features', type: 'object', key: 'feature_tags'}
const accomodating_max_seats = {label: 'Max accommodation', type: 'number', key: 'accomodating_max_seats'}
const accomodating_min_seats = {label:'Min accommodation', type: 'number', key: 'accomodating_min_seats'}
const description = {label: 'Trip description', key: 'description'}
const accomodation_description = {label:'Trip Accommodation', key: 'accomodation_description'}
const interested_trips_to_trade = {label: 'Interested trip to trade', key: 'interested_trips_to_trade'}
const is_interested_in_selling = {label:'interested in selling/Not', type: 'Boolean', key: 'is_interested_in_selling'}
const fair_market_value = { label: 'Fair market value', type: 'number', key: 'fair_market_value' }
const categories = {label: 'Trip Category', type: 'object', key: 'trip_categories'}

export const tripHash = {
    title,
    country,
    city,
    date,
    duration_min_days,
    duration_max_days,
    trip_category_id,
    feature_tags,
    accomodating_max_seats,
    accomodating_min_seats,
    description,
    accomodation_description,
    interested_trips_to_trade,
    is_interested_in_selling,
    fair_market_value,
    trip_start_date,
    trip_end_date,
    categories
}
