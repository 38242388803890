
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { isEmpty } from 'lodash';

import {  miscellService } from 'js/services';
import { history } from 'js/helpers';


export function useSubscribe() {
    const [errors, setErrors] = useState({});
    const [ loading, setLoading ] = useState(false);

    const subscribe = (email) => {
        setLoading(true);
        miscellService.subscribe({email})
        .then(res => {
            toast.success('You have subscribed successfully ');
            setErrors({})
            setLoading(false);
        }).catch(err => {
            err && err.data && err.data.errors && setErrors(err.data.errors)
            setLoading(false);
        })
    }
    useEffect(() => {
        if(!isEmpty(errors)) {
            setErrors({})
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [history.location])
    return {errors, subscribe, loading}
}
