import React, { useState, useContext, useEffect } from 'react';
import { Row, Col, Form, FormGroup, Label, Input, Table, Spinner } from 'reactstrap';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { isEmpty, omit, forEach, find, cloneDeep, pick, isEqual } from 'lodash';
import Geosuggest from 'react-geosuggest';
import moment from 'moment';


import { NavigationSidebar } from "js/components/web/components/layouts/components/navigation-sidebar";
import { MyCheckoutForm, RelatedExperience, NumberInputComponent, ErrorFormFeedback, CardComponent } from 'js/components/common';
import { LookupContext } from 'js/contexts';
import { invoiceServices, authServices } from 'js/services';
import { AuthProvider } from 'js/contexts';
import { toast } from "react-toastify";



export const AccountBillingPreferences = () => {

    const [account, setAccount] = useState({
        password: '',
        current_password: '',
        password_confirmation: '',
    });
    const [user_errors, setErrors] = useState({});
    const [invoices, setInvoices] = useState({});
    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
    const lookups = useContext(LookupContext);
    const [loading, setLoading] = useState({
        info: false,
        password: false,
    });
    const { auth, setAuth } = useContext(AuthProvider);
    const [ show_form, showForm ] = useState(false)
    const user_id = auth && auth.user && auth.user.user_id;

    const apiCalls = {
        get: () => {
            authServices.getProfile().then((response) => {
                setAuth({ ...auth, user: { ...response } });
            }).catch((err) => {
                setErrors(err);
            });
        },
        update: (details, loading_key) => {
            setLoading({
                ...loading,
                [loading_key]: true
            });
            authServices.updateProfile(details, user_id).then(() => {
                setErrors({});
                apiCalls.get();
                setLoading({
                    ...loading,
                    [loading_key]: false
                });
                toast.success('Updated Successfully');

                setAccount((account) => ({
                    ...account,
                    password: '',
                    current_password: '',
                    password_confirmation: '',
                }))
            })
                .catch((err) => {
                    let errors = err.data && err.data.errors;
                    setErrors(errors);
                    setLoading({
                        ...loading,
                        [loading_key]: false
                    });
                });
        },
        getAllInvoices: (params) => {
            invoiceServices.getAll(params).then((response) => {
                let details = { ...response };
                if (!isEmpty(details.data)) {
                    details.data = details.data.map((item) => {
                        let subject_name = [];
                        forEach(item.invoice_items, (subject) => {
                            subject_name.push(subject.label);
                        })
                        return {
                            ...item,
                            subject_name
                        }

                    })
                }
                setInvoices(details);
            })
        },
    }

    useEffect(() => {
        if (auth && auth.user && auth.user.user_id) {
            apiCalls.getAllInvoices({ user_id: auth.user.user_id })

            let user_data = cloneDeep(auth.user);

            const data_filter = ['first_name', 'last_name', 'email',
                'phone_country_code', 'phone', 'state', 'city', 'zip_code', 'address'];

            let selected_user = pick(user_data, data_filter);

            selected_user.country = user_data.country ?
                find(lookups.countries, ['country_id', user_data.country.country_id]) : '';

            setAccount(selected_user);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleAddressChange = (place) => {
        let state = {};
        let city = {};
        let country = {};


        if (!isEmpty(place)) {
            if (place.gmaps && place.gmaps.address_components) {
                forEach(place.gmaps.address_components, (value, key) => {
                    value.types && forEach(value.types, type => {
                        let is_state = false
                        let is_city = false
                        if (type === "administrative_area_level_1") {
                            state = { ...omit(value, 'types') }
                            is_state = true
                        }
                        if (type === "administrative_area_level_2" && !is_state) {
                            state = { ...omit(value, 'types') }
                            is_state = true
                        }
                        if (type === "locality") {
                            city = { ...omit(value, 'types') }
                            is_city = true
                            if(!(is_state)) {
                                state = {...omit(value, 'types') }
                            }
                        }
                        if(type === 'sublocality_level_1' && !is_city) {
                            is_city = true
                            city = {...omit(value, 'types') }

                        }
                        if( type === "country" ){
                            country = { ...omit(value, 'types') }
                        }
                        if(type === 'route' && isEqual(state, city)){
                            city = { ...omit(value, 'types') }
                        }
                    })
                })
            }
        }

        if (country.short_name) {
            country = find(lookups.countries, ['short_name', country.short_name]);
        }

        setAccount((account) => ({
            ...account,
            state,
            city,
            address: place && place.description ? place.description : '',
            country: !isEmpty(country) ? country : account.country
        }));
    }

    const generateInvoiceLink = (invoice_id, params) => {
        invoiceServices.generateInvoiceLinks(invoice_id, params).then((response) => {
            if (response.url) {
                window.open(response.url);
            }
        });
    }

    const handleChangePhone = (value) => {
        setAccount({ ...account, phone: value.phone, phone_country_code: value.phone_country_code });
    }


    const saveInformation = (loading_key) => {
        let details = cloneDeep(account);

        if (!isEmpty(details.city)) {
            let modified_city = pick(details.city, ['long_name', 'short_name']);
            details.city = modified_city;
        }
        if (!isEmpty(details.state)) {
            let modified_state = pick(details.state, ['long_name', 'short_name']);
            details.state = modified_state;
        }
        if (!isEmpty(details.country)) {
            details.country_id = details.country ? details.country.value : '';
        }
        if(!(isEmpty(details.password) &&
                isEmpty(details.password_confirmation) &&
                    isEmpty(details.current_password)) || loading_key === 'info'){

                        apiCalls.update({ ...omit(details, 'country', 'email') }, loading_key);
        }
        else{
            setErrors((errors) => ({
                errors,
                current_password: ['The password is incorrect.']
            }));
        }

    }
    let show_checkout = !auth.user.payment_methods ? true : isEmpty(auth.user.payment_methods.data) ? true : show_form

    return (
        <div>
            <Helmet>
                <title>Trip4Trade :: Account & Billing Preferences</title>
                <meta name="description" content=" My Listings page desription" />
                <meta name="keywords" content="trip4trade,  My Listings" />
            </Helmet>

            <div className="inner-banner-profile" style={{ backgroundImage: `url(/images/web/global/banners/profile-banner.png)` }}>

            </div>
            <div className="wider-data-frame wider-data-frame--grey pt-0">
                <div className="container">
                    <div className="profile-outer-wrapper">
                        <Row className="no-gutters">
                            <Col xs="12" lg="9">
                                <div className="profile-data-section">
                                    <div className="profile-data-section--top">
                                        <Row>
                                            <Col xs="12" className="pb-4">
                                                <h3><img src="/images/web/global/side-navigation/account-billing-preferences.svg" alt="" style={{ width: `30px` }} /> Account & Billing Preferences</h3>
                                            </Col>
                                        </Row>

                                    </div>
                                    <div className="profile-data-section--bottom pt-5 pb-5 account-billing">
                                        <Row>
                                            <Col xs="12">
                                                <h3 className="header4 mb-4">Account Information</h3>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs="12" className="mb-1">
                                                <Form className="billing-form">
                                                    <Row>
                                                        <Col xs="12" md="4">
                                                            <FormGroup>
                                                                <Label for="first_name" >First Name</Label>
                                                                <Input
                                                                    type="text"
                                                                    name="first_name"
                                                                    onChange={(e) => setAccount({ ...account, [e.target.name]: e.target.value })}
                                                                    value={account.first_name || ''}
                                                                    invalid={user_errors.first_name && true}
                                                                />
                                                                <ErrorFormFeedback field={'first_name'} errors={user_errors} />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col xs="12" md="4">
                                                            <FormGroup>
                                                                <Label for="last_name" >Last Name</Label>
                                                                <Input
                                                                    type="text"
                                                                    name="last_name"
                                                                    onChange={(e) => setAccount({ ...account, [e.target.name]: e.target.value })}
                                                                    value={account.last_name || ''}
                                                                    invalid={user_errors.last_name && true}
                                                                />
                                                                <ErrorFormFeedback field={'last_name'} errors={user_errors} />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col xs="12" md="4">
                                                            <FormGroup>
                                                                <Label for="email" >Email Address</Label>
                                                                <Input
                                                                    type="email"
                                                                    name="email"
                                                                    //onChange={(e) => setAccount({ ...account, [e.target.name]: e.target.value })}
                                                                    value={account.email || ''}
                                                                    invalid={user_errors.email && true}
                                                                    disabled
                                                                />
                                                                <ErrorFormFeedback field={'email'} errors={user_errors} />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col xs="12" md="4">
                                                            <FormGroup>
                                                                <Label for="" >Phone</Label>
                                                                <NumberInputComponent
                                                                    phone={`+${account.phone_country_code}${account.phone}`}
                                                                    onChange={handleChangePhone}
                                                                />

                                                                {!isEmpty(user_errors) && user_errors.phone && (
                                                                    <div>
                                                                        <div className="invalid-feedback d-block">{user_errors.phone}</div>
                                                                    </div>
                                                                )}
                                                                {!isEmpty(user_errors) && user_errors.phone_country_code && (
                                                                    <div>
                                                                        <div className="invalid-feedback d-block">{user_errors.phone_country_code}</div>
                                                                    </div>
                                                                )}
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xs="12">
                                                            <hr className="hr" />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xs="12" md="4">
                                                            <FormGroup>
                                                                <Label for="" >Billing Country</Label>
                                                                <div className="custom-react-select">
                                                                    <Select
                                                                        className="react-select-container"
                                                                        classNamePrefix="react-select"
                                                                        options={lookups.countries}
                                                                        onChange={(e) => {
                                                                            setAccount({ ...account, 'country': e, zip_code: '', address: '' })
                                                                        }
                                                                        }
                                                                        value={account.country || null}
                                                                    />
                                                                    {!isEmpty(user_errors) && user_errors.country && (
                                                                        <div>
                                                                            <div className="invalid-feedback d-block">{user_errors.country}</div>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col xs="12" md="8">
                                                            <FormGroup>
                                                                <Label for="" >Billing Address</Label>
                                                                <div className="tt-custom-react-select">
                                                                    <Geosuggest type="text"
                                                                        name="address"
                                                                        className="react-select-container"
                                                                        inputClassName={"form-control"}
                                                                        placeholder=""
                                                                        initialValue={account.address ? account.address :''}
                                                                        value={account.address ? account.address : ''}
                                                                        autoComplete="nope"
                                                                        onSuggestSelect={handleAddressChange}
                                                                        country={account.country ? account.country.short_name : ''}
                                                                        onChange={(e) => setAccount({
                                                                            ...account,
                                                                            address: e
                                                                        })}
                                                                    />
                                                                    <div className="invalid-feedback d-block">{user_errors['address']}</div>
                                                                </div>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col xs="12" md="4">
                                                            <FormGroup>
                                                                <Label for="" >Billing Zip Code</Label>
                                                                <Input
                                                                    type="text"
                                                                    name="zip_code"
                                                                    onChange={(e) => setAccount({ ...account, [e.target.name]: e.target.value })}
                                                                    value={account.zip_code || ''}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    {/* <Row>
                                                        <Col xs="12">
                                                            <hr className="hr" />
                                                        </Col>
                                                    </Row> */}
                                                    <Row>
                                                        <Col xs="12" className="d-flex justify-content-center my-4">
                                                            <Link
                                                                to={"#"}
                                                                className="btn btn-outline text-capitalize"
                                                                disabled={loading['info'] || loading['password']}
                                                                onClick={() => saveInformation('info')}
                                                            >
                                                                {
                                                                    loading['info'] ? <Spinner size="sm" /> : 'Save Information'
                                                                }
                                                            </Link>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xs="12">
                                                            <hr className="hr" />
                                                        </Col>
                                                    </Row>
                                                    <CardComponent callBack={() => showForm(true)} payment_methods={auth.user.payment_methods}/>
                                                    {show_checkout &&
                                                        <Elements stripe={stripePromise}>
                                                            <MyCheckoutForm
                                                                user_id={user_id}
                                                            />
                                                        </Elements>
                                                    }
                                                </Form>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs="12">
                                                <hr className="hr" />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs="12">
                                                <Form className="billing-form">
                                                    <Row>
                                                        <Col xs="12">
                                                            <h3 className="header4 mb-4">Change Password</h3>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xs="12" md="4">
                                                            <FormGroup>
                                                                <Label for="" >Current Password</Label>
                                                                <Input
                                                                    type="password"
                                                                    name="current_password"
                                                                    onChange={(e) => setAccount({ ...account, [e.target.name]: e.target.value })}
                                                                    value={account.current_password || ''}
                                                                    invalid={user_errors.current_password && true}
                                                                />
                                                                <ErrorFormFeedback field={'current_password'} errors={user_errors} />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col xs="12" md="4">
                                                            <FormGroup>
                                                                <Label for="" >New Password</Label>
                                                                <Input
                                                                    type="password"
                                                                    name="password"
                                                                    onChange={(e) => setAccount({ ...account, [e.target.name]: e.target.value })}
                                                                    value={account.password}
                                                                    invalid={user_errors.password && true}

                                                                />
                                                                <ErrorFormFeedback field={'password'} errors={user_errors} />

                                                            </FormGroup>
                                                        </Col>
                                                        <Col xs="12" md="4">
                                                            <FormGroup>
                                                                <Label for="" >New Password Confirmation</Label>
                                                                <Input
                                                                    type="password"
                                                                    name="password_confirmation"
                                                                    onChange={(e) => setAccount({ ...account, [e.target.name]: e.target.value })}
                                                                    value={account.password_confirmation}
                                                                    invalid={user_errors.password_confirmation && true}
                                                                />
                                                                <ErrorFormFeedback field={'password_confirmation'} errors={user_errors} />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xs="12" className="d-flex justify-content-center my-4">
                                                            <Link
                                                                to={"#"}
                                                                className="btn btn-outline text-capitalize"
                                                                onClick={() => saveInformation('password')}
                                                                disabled={loading['info'] || loading['password']}
                                                            >{loading['password'] ? <Spinner size="sm" /> : 'Change Password'}
                                                            </Link>
                                                        </Col>
                                                    </Row>
                                                </Form>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs="12">
                                                <hr className="hr" />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs="12">
                                                <h3 className="header4 mb-4">Payment List</h3>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs="12">
                                                <div className="table-wrap payment-table">
                                                    <Table responsive className="tt-admin-table-outline">
                                                        <thead>
                                                            <tr>
                                                                <th>Subject</th>
                                                                <th>Payment Date</th>
                                                                <th>Credit Card</th>
                                                                <th>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                !isEmpty(invoices) && !isEmpty(invoices.data) &&
                                                                invoices.data.map((item, index) => (
                                                                    <tr key={`payment_list${index}`}>
                                                                        <td>
                                                                            {
                                                                                !isEmpty(item.subject_name) && item.subject_name.map((subject, ind) => (
                                                                                    ind === item.subject_name.length - 1 && item.subject_name.length === 1 ? subject
                                                                                        : ind === item.subject_name.length - 1 ? 'and ' + subject : subject + ', '
                                                                                ))
                                                                            }
                                                                        </td>
                                                                        <td>{item.created_at ? moment(item.created_at).format('lll') : ''}</td>
                                                                        <td>**** {item.transaction_history && item.transaction_history.last4 ? item.transaction_history.last4 : ''}</td>
                                                                        <td>
                                                                            <div className="action-wrap">
                                                                                <Link
                                                                                    to={"#"}
                                                                                    onClick={() => generateInvoiceLink(item.invoice_id, { type: 'download' })}
                                                                                ><img src="/images/admin/global/download.svg" alt="" /></Link>
                                                                                <Link
                                                                                    to={"#"}
                                                                                    onClick={() => generateInvoiceLink(item.invoice_id, { type: 'preview' })}
                                                                                >
                                                                                    <img src="/images/admin/global/print.svg" alt="" />
                                                                                </Link>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                            }
                                                        </tbody>
                                                    </Table>

                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Col>
                            <NavigationSidebar />
                        </Row>
                    </div>
                </div>
            </div>
            <RelatedExperience user_id={user_id}/>
        </div>
    );
}

