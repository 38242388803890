import React, { useEffect, useState } from 'react';
import { Bar, Line } from 'react-chartjs-2';
import { forEach, isEmpty } from 'lodash';
import 'chartjs-plugin-datalabels';


export const GraphComponent = (props) => {

    const [data, setData] = useState({});

    useEffect(() => {
        (async function asyncFeeder() {
            await dataFeeder().then((response) => {
                setData(response)
            })
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.graph_data]);

    const dataFeeder = () => {
        let labels = [];
        let data = [];
        let key_of_data = {
            report_user: 'count',
            dashboard_total_listings: 'no_of_trips',
            dashboard_active_users: 'no_of_active_users',
            report_revenue: 'price',
            report_exchange: 'count',
        }
        let key_of_label = {
            report_user: 'date',
            dashboard_active_users: 'created_at',
            dashboard_total_listings: 'created_at',
            report_revenue: 'date',
            report_exchange: 'requested_start_date',
        }
        let data_value = key_of_data[props.type];
        let label_value = key_of_label[props.type];

        return new Promise((resolve, reject) => {
            if (!isEmpty(props.graph_data)) {
                forEach(props.graph_data, (item) => {
                    labels.push(item[label_value]);
                    data.push(item[data_value]);
                });
            }

            resolve({
                labels,
                datasets: [
                    {
                        //label: 'Revenue',
                        backgroundColor: props.graph_type === 'line' ? '#fef3df' : '#f9a407',
                        borderColor: '#f9a407',
                        borderWidth: 1,
                        data: data
                    }
                ]
            });
        });
    }


    return (
        <>
            {
                props.graph_type === 'bar' &&
                <Bar
                    data={data}
                    options={{
                        title: {
                            display: true,
                            // text: '',
                            fontSize: 20,
                        },
                        responsive: true,
                        maintainAspectRatio: false,
                        legend: {
                            display: false,
                        },
                        plugins: {
                            datalabels: {
                                display: 'auto',
                                color: 'black',
                                anchor: 'end',
                                clamp: true,
                                align: 'end',
                                offset: 7
                            }
                        }
                    }}
                />
            }
            {
                props.graph_type === 'line' &&
                <Line
                    data={data}
                    options={{
                        title: {
                            display: true,
                            // text: '',
                            fontSize: 20
                        },
                        legend: {
                            display: false,
                        },
                        plugins: {
                            datalabels: {
                                display: true,
                                color: 'black',
                                anchor: 'end',
                                clamp: true,
                                align: 'end',
                                offset: 7
                            }
                        }
                    }}
                />
            }

        </>
    );
}
