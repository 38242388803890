import React, { useState } from 'react';
import { Row, Col, Button, Spinner } from 'reactstrap';
// import  Recaptcha  from 'react-recaptcha';
import { TripMedia } from 'js/components/common';
import { tripsServices } from 'js/services';
import { Link } from 'react-router-dom';

export const MediaTab = (props) => {
    const [ files, addFiles ] = useState([])
    const [ form_errors, setErrors ] = useState( {} );
    const [ loading, setLoading ] = useState( false );
    // const [ captcha, verifyCaptcha ] = useState(false);

    const addNewFiles = ( response ) => {
        addFiles(files => ( [...files, response ]));
    }
    // const verifyCallback = function (response) {
    //     verifyCaptcha(true)
    // };
    const removeFile = (removed_item, final_files) => {
        addFiles(final_files)
    }

    const handlePreview = () => {
        setLoading(true)
        let attachments = files && files.map(item => item && item.attachment_id);
        tripsServices.update( { attachments }, props.trip.slug ).then(response => {
            window.open(window.location.origin+`/trips/details/${ response.slug }`, "_blank")
            setLoading(false)
        } ).catch( err => {
            setErrors( err.data.errors );
            setLoading(false)
        })
    }

    return(
        <div className="signup-tab-data-wrap add-listing-data-wrap">
            <Row>
                <Col xs="12">
                    <h3 className="header5 mb-0">Trip Photos</h3>
                    <h6 className="p-0 upload-photo-text "><b>Upload 6-10 High Quality Photos - (Essential for Credibility)</b></h6>
                </Col>
            </Row>
            <Row>
                <Col xs="12">
                    <TripMedia onChange={(file) => addFiles(file)} files={files} onAddNewFiles={addNewFiles} onRemoveFile={removeFile}/>
                    <div className="invalid-feedback d-block">{form_errors['attachments']}</div>
                </Col>
            </Row>
            {/* <Row>
                <Col xs="12" md={{ size: 6, offset: 3 }} className="recaptcha-outer">
                    <p>Are you human?</p>
                    <div className="recaptcha-wrap">
                        <Recaptcha
                            sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                            verifyCallback={verifyCallback}
                        />
                        <div className="invalid-feedback d-block">{form_errors['captcha']}</div>
                    </div>
                </Col>
            </Row> */}
            <Row>
                <Col xs="12" className="text-center add-listing-warn-message">
                    <h4>PLEASE DO NOT REFRESH OR EXIT THIS SCREEN AFTER CLICKING "PREVIEW".</h4>
                    <p>If you have any submission issues, please contact us at Support@Trips4Trade.com.</p>
                </Col>
            </Row>
            <Row>
                <Col xs="12">
                    <div className="d-flex justify-content-center add-listing-button-wrap">
                        <div className="add-listing-btn-group">
                            <Link
                                className="btn-outline btn-outline--light-grey margin-right-15"
                                to={ `/trips/edit/${ props.trip.slug }` }>Add later
                            </Link>
                            <Button
                                className="btn-outline"
                                onClick={() => handlePreview()}>
                                {loading ?
                                    <Spinner size="sm"/> : 'Preview'
                                }
                            </Button>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    )
}
