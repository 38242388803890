import React, { useContext } from 'react';
import { Link } from "react-router-dom";
import {
    Collapse,
    Navbar,
    NavbarToggler,
    Nav,
    NavItem,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu
} from 'reactstrap';

import { authServices } from 'js/services';
import { configConstants } from 'js/constants';
import { history } from 'js/helpers';
import { AuthProvider } from 'js/contexts';
import { NotificationHeader } from 'js/components/common';


export const Header = () => {

    const { auth, setAuth } = useContext(AuthProvider);


    const sidemenuToggle = () => {
        document.getElementById("sidebarNav").classList.toggle("open");
    }

    const logOut = () => {
        authServices.logout().then(() => {
            localStorage.removeItem(configConstants.TOKEN_NAME);
            setAuth({ ...auth, user: {}, is_logged_in: false });
            history.push('/auth');
        });
    }

    return (
        <Navbar color="white" light expand="lg" className="admin-navbar-head">
            {/* Mobile View Items Section Starts */}
            <div className="sidebar-sm text-left">
                <Link to={"/admin"} ><img src="/images/admin/global/logo.svg" className="sidebar__portal-logo" width="131px" alt="logo" /></Link>
            </div>
            <div className="d-flex align-items-center ml-auto">
                <UncontrolledDropdown inNavbar className="navbar__profile-dropdown navbar__profile-dropdown-sm">
                    <DropdownToggle caret className="navbar__link-btn dropdown-toggle-indicator">
                        {auth.user.attachment ?
                            <img
                                src={ auth.user.attachment.thumbnail_url ? auth.user.attachment.thumbnail_url : auth.user.attachment.attachment_url }
                                alt=""
                                className="navbar__profile-img"
                                width="35px"
                                height="35px"
                            />
                            :
                            <img
                                src={"images/web/global/logo-icon.svg"}
                                alt=""
                                className="navbar__profile-img"
                                width="35px"
                                height="35px"
                            />
                        }
                    </DropdownToggle>
                    <DropdownMenu right className="p-0 dropdown-menu--profile">
                        <ul className="navbar__profile-dropdown-item-list">
                            <li>
                                <Link to={"/my-profile"}>
                                    My Profile
                                </Link>
                            </li>
                            <li>
                                <Link to={"#"} onClick={logOut}>
                                    Log Out
                                </Link>
                            </li>
                        </ul>
                    </DropdownMenu>
                </UncontrolledDropdown>
                <NavbarToggler data-toggle="offcanvas" onClick={sidemenuToggle} />
            </div>
            {/* Mobile View Items Section Ends */}
            <Collapse navbar className="admin-main-header">
            {auth.user && auth.user && auth.is_logged_in  &&
                <Nav className="ml-auto main-header-right" navbar>
                    <NavItem className="nav-item--notif d-flex align-items-center pr-2">
                        <NotificationHeader
                            type="admin"
                        />
                    </NavItem>
                    <NavItem>
                        <UncontrolledDropdown inNavbar className="navbar__profile-dropdown">
                            <DropdownToggle caret className="navbar__link-btn dropdown-toggle-indicator">
                                <img
                                    src={auth.user.attachment ?
                                            auth.user.attachment.thumbnail_url ?
                                            auth.user.attachment.thumbnail_url : auth.user.attachment.attachment_url
                                        : "images/web/global/logo-icon.svg"
                                    }
                                    alt=""
                                    className="navbar__profile-img"
                                    width="35px"
                                    height="35px"
                                />
                                {auth.user.first_name}
                            </DropdownToggle>
                            <DropdownMenu right className="p-0 dropdown-menu--profile">
                                <ul className="navbar__profile-dropdown-item-list">
                                    <li>
                                        <Link to={"/my-profile"}>
                                            <img className="main-header__drop-menu-ico" src="/images/global/header/my-profile.svg" alt="" />
                                            My Profile
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={"#"} onClick={logOut}>
                                            <img className="main-header__drop-menu-ico" src="/images/global/header/logout.svg" alt="" />
                                            Log Out
                                        </Link>
                                    </li>
                                </ul>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </NavItem>
                </Nav>
            }
            </Collapse>
        </Navbar>
    );
}

