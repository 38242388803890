import React, { useState, useEffect } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Input, Row, Col, CustomInput, Spinner } from 'reactstrap';
import "js/components/legos/styles/owner-details-module.css"
import { debounce, isEmpty } from 'lodash';
import { userServices, tripsServices } from "js/services";
import { ListLoader } from "../list-loader";
import { toast } from 'react-toastify';

export const OwnersListModal = ( props ) => {

    const { is_open, hide, modal_header, modal_footer, slug, callBack } = props;

    const [ selected_user_id, selectUser ] = useState(null);
    const [ users, setUsers ] = useState([]);
    const [ loading, setLoading] = useState({
        users_listing: false,
        button_loading: false
    })

    useEffect(() => {
        getAllUsers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getAllUsers = debounce((params) => {
        setLoading((loading) => ({
            ...loading,
            users_listing: true
        }));
        userServices.getAll(params, hide)
        .then(response => {
            setLoading((loading) => ({
                ...loading,
                users_listing: false
            }));
            setUsers(response);
            setLoading(false)
        })
    },300);

    const changeOwner = (  ) =>{
        setLoading((loading) => ({
            ...loading,
            button_loading: true
        }));
        tripsServices.updateOwner( slug, { user_id : selected_user_id }, hide  )
        .then(response => {
            setLoading((loading) => ({
                ...loading,
                button_loading: false
            }));
            callBack && callBack();
        })
        .catch( error => {
            setLoading((loading) => ({
                ...loading,
                button_loading: false
            }));
            toast.error('Failed to Change Trip Owner')
        });
    }

    return (
        <div>
        <Modal isOpen={is_open} toggle={() => hide()} className="tt-modal--admin modal-dialog-centered">
                <ModalHeader toggle={() => hide()}>{modal_header}</ModalHeader>
                <ModalBody>
                    <Form className="tt-admin-form">
                        <div className="tt-admin-form-inner p-0">
                            <Row form>
                                <Col xs="12">
                                    <FormGroup>
                                        <Input
                                            type="text"
                                            name="text"
                                            placeholder="Search User"
                                            onChange={(e) => getAllUsers({search: e.target.value})}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row form>
                                <Col xs="12">
                                {loading['users_listing'] ?
                                <ListLoader/>
                                :
                                <>
                                {!isEmpty(users) && users.data && users.data.map((user, index) => (
                                    <div className="owner-data-wrapper">
                                        <div className="left-section">
                                            {user && user.attachment &&
                                                <img
                                                    src={ user.attachment.thumbnail_url ? user.attachment.thumbnail_url : user.attachment.attachment_url}
                                                    alt=""
                                                />
                                            }
                                            <h4>{user.full_name}</h4>
                                            <h5>{user.email}</h5>
                                        </div>
                                        <div className="right-section">
                                            <CustomInput type="radio" id={`user_${user.user_id}`} name="customRadio" label="" onChange={() => selectUser(user.user_id)}/>
                                        </div>
                                    </div>
                                ))}
                                </>
                                }
                                </Col>
                            </Row>
                        </div>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" className="btn-outline-grey" onClick={() => hide()}>Cancel</Button>
                    <Button color="primary" className="add-new-btn" onClick={changeOwner}>{
                        loading['button_loading'] ? <Spinner /> : modal_footer
                    }</Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}
