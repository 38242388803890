import React, { useState, useContext } from "react";
import { Row, Col, Button, FormGroup, Input, Label, Modal, ModalHeader, ModalBody, Spinner } from 'reactstrap';
import { isEmpty } from 'lodash';
import Select from 'react-select';

import { profileServices } from 'js/services';
import { LookupContext } from 'js/contexts';
import { ErrorFormFeedback } from 'js/components/common';

export const EndorsmentViewProfileModal = (props) => {

    const { is_open, hide, callBack, user, endorsement_id } = props;
    const [state, setState] = useState({
        description: '',
        endorsement_type_id: ''
    });

    const [loading, setLoading] = useState();
    const [errors, setErrors] = useState({});
    const lookups = useContext(LookupContext);

    const handleChange = (key, value) => {
        setState({ ...state, [key]: value });
    }
    const addEndorsement = () => {
        setLoading(true);
        let details = {
            ...state,
            is_direct_endorsement: true,
            user_id: user.user_id
        }
        profileServices.addEndorsment(details, hide)
        .then((response) => {
            setErrors({});
            setLoading(false);
            callBack && callBack();
        }).catch((error) => {
            setErrors(error.data && error.data.errors);
            setLoading(false);
        });
    }
    const updateEndorsement = () => {
        setLoading(true);
        let details = {
            ...state,
            is_direct_endorsement: false,
        }
        profileServices.updateEndorsements(endorsement_id, details, hide)
        .then((response) => {
            setErrors({});
            setLoading(false);
            callBack && callBack();
        }).catch((error) => {
            setErrors(error.data && error.data.errors);
            setLoading(false);
        });
    }
    const submit = () => {
        if(endorsement_id) {
            updateEndorsement();
        }
        else {
            addEndorsement();
        }
    }

    return (
        <div>
            <Modal
                size='lg'
                isOpen={is_open}
                toggle={() => hide()}
                className="tt-modal--profile modal-dialog-centered"
            >
                <ModalHeader toggle={() => hide()}>Endorse {user && user.first_name ? user.first_name : ''}</ModalHeader>
                <ModalBody>
                    <Row className="profile-form">
                        <Col xs="12" md="12" className="pt-4">
                            <FormGroup>
                                <Label for="">Endorse {user && user.first_name ? user.first_name : ''} for</Label>
                                <div className="tt-custom-react-select">
                                    <Select
                                        className="react-select-container"
                                        classNamePrefix="react-select"
                                        options={lookups.endorsement_types}
                                        onChange={(e) => handleChange('endorsement_type_id', e.endorsement_type_id)}
                                    />
                                    <ErrorFormFeedback field={'endorsement_type_id'} errors={errors} is_invalid={true}/>
                                </div>
                            </FormGroup>
                        </Col>
                        <Col xs="12" md="12">
                            <FormGroup>
                                <Label for="" >Optional Comment</Label>
                                <Input
                                    type="textarea"
                                    value={state.comment}
                                    onChange={(e) => handleChange('description', e.target.value)}
                                    invalid={!isEmpty(errors) && (errors.description) && true}
                                />
                                <ErrorFormFeedback field={'description'} errors={errors} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <div className="d-flex justify-content-center py-4">
                        <Button
                            className="btn-outline text-capitalize"
                            onClick={submit}
                            disabled={loading}
                        >
                            {loading ? <Spinner color="#ffa405" size="sm" /> : 'Endorse'}
                        </Button>
                    </div>
                </ModalBody>
            </Modal>
        </div>
    );
}
