import React from "react";
import { Route, Switch } from "react-router-dom";

import { Header } from "js/components/web/components/layouts/components/header";
import { Footer } from "js/components/web/components/layouts/components/footer";
import  ScrollToTop  from "js/components/web/components/layouts/components/scroll-to-top";

import { AuthRoutes } from "js/routes/Auth/auth";



const switchRoutes = (
    <Switch>
        {AuthRoutes.map((prop, key) => {
            return <Route path={prop.path} component={prop.component} key={key} />;
        })}
    </Switch>
);


export const AuthLayout = (props) => {
    return (
        <React.Fragment>
            <ScrollToTop />
            <Header />
            <div className="auth-wrap">
                {switchRoutes}
            </div>
            <Footer />
        </React.Fragment>
    )
}
