import React from "react";
import Select from 'react-select';
import moment from 'moment';
import funnelIcon from 'js/components/legos/select-icons/components/funnel-icon';


const DateFilter = [
    { value: 0, label: 'Today', icon: funnelIcon },
    { value: 7, label: 'Last 7 Days', icon: funnelIcon },
    { value: 30, label: 'Last 30 Days', icon: funnelIcon },
    { value: 90, label: 'Last 90 Days', icon: funnelIcon },
    { value: 'ytd', label: 'YTD(year to date)', icon: funnelIcon },
    { value: 365, label: 'Last Year', icon: funnelIcon },
];
const styles = {
    option: (styles, state) => ({
        ...styles,
        backgroundColor: state.isSelected ? "#FFA405 " : state.isFocused ? "#FFA40520" : ""
    })
};
const customSingleValue = ({ data }) => (
    <div className="input-select">
        <div className="input-select__single-value-selected">
            {data.icon && <span className="input-select__icon mr-2">{data.icon}</span>}
            <span>{data.label}</span>
        </div>
    </div>
);
const CustomOption = ({ data, innerProps, isDisabled }) => !isDisabled ? (
    <div {...innerProps}>
        <div className="input-select__single-value">
            {data.icon && <span className="input-select__icon mr-2">{data.icon}</span>}
            <span>{data.label}</span>
        </div>
    </div>
) : null;

export const DateFilterComponent = (props) => {
    const onDateChange = (value) => {
        let current_params = { ...props.params };
        if(value.value === 'ytd') {
            current_params.start_date = moment().startOf('year').format('YYYY-MM-DD');
            current_params.end_date = moment().format('YYYY-MM-DD');
        }
        else if ( value.value !== 0 )
        {
            current_params.start_date = moment().subtract(value.value, 'days').format('YYYY-MM-DD');
            current_params.end_date = moment().format('YYYY-MM-DD');
        }
        else
        {
            current_params.start_date = moment().format('YYYY-MM-DD');
            current_params.end_date = moment().format('YYYY-MM-DD');
        }
        props.onChange(current_params);
    }
    return (
        <div className="custom-react-select--white-w-auto">
            <Select
                components={{ SingleValue: customSingleValue, Option: CustomOption }}
                className="react-select-container"
                classNamePrefix="react-select"
                defaultValue={DateFilter[1]}
                options={DateFilter}
                styles={styles}
                onChange={(e) => onDateChange(e)}
            />
        </div>
    )

}
