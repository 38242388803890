import React, { useEffect, useState, useContext } from 'react';
import { Row, Col, UncontrolledTooltip, Nav, NavItem, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import ScrollableAnchor from 'react-scrollable-anchor';
import { configureAnchors } from 'react-scrollable-anchor';
import { concat, isEmpty, forEach } from 'lodash';
import moment from 'moment'

import { ReviewSlider } from "js/components/legos/reviewSlider";
import AboutHost from "js/components/legos/aboutHost";
import UserBadges from "js/components/legos/userBadges";
import StarRatingMinimal from "js/components/legos/starRatingMinimal";
import Verifications from "js/components/legos/verifications";
import SocialLinks from "js/components/legos/socialLinks";
import ReferenceCard from "js/components/legos/referenceCard";
import EndorsementCard from "js/components/legos/endorsementCard";

import { userServices, membershipRewardServices, tripsServices } from 'js/services';
import { ListLoader, TripListing, RelatedExperience } from 'js/components/common';
import { useModal } from 'js/hooks';
import { history } from 'js/helpers';
import { AuthProvider } from 'js/contexts';

configureAnchors({ offset: -60 });

export const ProfileListing = (props) => {
    const [trips, setTrips] = useState({});
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState({});
    const [badges, setBadges] = useState({});
    const [state, setState] = useState({
        achieved_badges: {},
    });
    const [show, hide] = useModal();
    const { auth } = useContext(AuthProvider);
    const image_name = {
        'premium-member': 'premium.svg',
        'lifetime-member': 'lifetime.svg',
        'annual-trader': 'annual.svg',
        'frequent-trader': 'frequent.svg',
        'trusted-trader': 'trusted.svg',
        'ambassador': 'ambassedor.svg',
        'perfect-profile': 'perfect.svg',
    }

    let user_id = props.match && props.match.params && props.match.params.user_id ? props.match.params.user_id : null;
    let endorsement_id =  props.match && props.match.params && props.match.params.endorsement_id ? props.match.params.endorsement_id : null;

    const apiCalls = {
        getUser: (user_id, endorsement_id) => {
            userServices.get(user_id, {public_profile:1}).then((response) => {
                setUser({ ...response });
                if( endorsement_id ){
                    show('EndorsmentViewProfileModal', {
                        callBack: () => {
                            hide('EndorsmentViewProfileModal');
                            history.push(`/profile/${user_id}`);
                        },
                        user: { ...user,...response },
                        endorsement_id: props.endorsement_id,
                    })
                }
            }).catch(err => {
                history.push('/')
            });
        },
        getUserTrips: (params, mode) => {
            setLoading(true)
            tripsServices.getAll(params).then(response => {
                if (mode === 'append') {
                    response.data = concat(trips.data, response.data);
                }
                setTrips(response);
                setLoading(false)
            }).catch(error => {
                console.log(error)
            })
        },
        getAllMembershipRewards: () => {
            membershipRewardServices.getAll().then((response) => {
                setBadges(response);
            });
        },
        badgeStatus: () => {
            let achieved_badges = {};
            membershipRewardServices.badgeStatus(user_id).then((response) => {
                if(!isEmpty(response)){
                    forEach(response, (value, key) => {
                        achieved_badges[key] = value === 0 ? false : true;
                    });
                    setState({
                        ...state,
                        achieved_badges
                    });
                }
            });
        },
    }

    useEffect(() => {
        apiCalls.getUser(user_id, endorsement_id);
        apiCalls.getUserTrips({ user: user_id, visibility: 'public' })
        apiCalls.getAllMembershipRewards();
        apiCalls.badgeStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <Helmet>
                <title>Trip4Trade :: Profile</title>
                <meta name="description" content="Profile page desription" />
                <meta name="keywords" content="trip4trade, Profile" />
            </Helmet>

            <div className="inner-banner-profile" style={{ backgroundImage: `url(/images/web/global/banners/profile-banner.png)` }}>

            </div>
            <div className="wider-data-frame wider-data-frame--grey pt-0">
                <div className="container">
                    <div className="profile-outer-wrapper">
                        <Row className="no-gutters">
                            <Col xs="12" lg="9">
                                <div className="profile-data-section">
                                    <div className="profile-data-section--top">
                                        <Row>
                                            <Col xs="12" md="8">
                                                <h3>{user.full_name ? user.full_name : ''}</h3>
                                                <p className="member-section ml-0">
                                                    <img src="/images/web/global/profile/member-since.svg" alt="" /> <b>Member Since:</b>{user.created_at ? moment(user.created_at).format('MMMM, YYYY') : ''}
                                                </p>
                                                <p className="location-section">
                                                    <img src="/images/web/global/map.svg" alt="" /> <b>{user.address}</b>
                                                </p>
                                            </Col>
                                            <Col xs="12" md="4" className="d-flex justify-content-end align-items-start">
                                                {
                                                    badges && !isEmpty(badges) && badges.map((badge, index) => {
                                                        return !isEmpty(state.achieved_badges) &&
                                                            state.achieved_badges[badge.slug] &&
                                                            <div className="badge-outer" key={'badges' + index}>
                                                                <img id={badge.slug} src={`/images/web/global/profile/badges/${image_name[badge.slug]}`} alt="" />
                                                                <UncontrolledTooltip className="tt-tooltip" placement="top" target={badge.slug}>
                                                                    {
                                                                        badge.label ? badge.label : ''
                                                                    }
                                                                </UncontrolledTooltip>
                                                            </div>
                                                    })
                                                }
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs="12">
                                                <Nav className="trip-details--sub-nav">
                                                    <NavItem >
                                                        <a href={"#biography"}>Biography</a>
                                                    </NavItem>
                                                    <NavItem>
                                                        <a href={"#listings"}>Listings</a>
                                                    </NavItem>
                                                    <NavItem>
                                                        <a href={"#trip-reviews"}>Trip Reviews</a>
                                                    </NavItem>
                                                    <NavItem>
                                                        <a href={"#endorsements"}>Endorsements & References</a>
                                                    </NavItem>

                                                </Nav>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className="profile-data-section--bottom">
                                        <Row>
                                            <Col xs="12">
                                                <ScrollableAnchor id={'biography'}>
                                                    <div>
                                                        <Row>
                                                            <Col xs="12">
                                                                <h3 className="header5"><img src="/images/web/global/profile/biography.svg" alt="" />Biography</h3>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xs="12">
                                                                <p>
                                                                    {user.biography ? user.biography : ''}
                                                                </p>
                                                            </Col>
                                                        </Row>

                                                    </div>
                                                </ScrollableAnchor>
                                                <ScrollableAnchor id={'listings'}>
                                                    <div>
                                                        <Row>
                                                            <Col xs="12">
                                                                <h3 className="header5"><img src="/images/web/global/profile/listings.svg" alt="" />Listings</h3>
                                                            </Col>
                                                        </Row>
                                                        {loading ?
                                                            <ListLoader />
                                                            :
                                                            <>
                                                                {!isEmpty(trips) && trips.data &&
                                                                    <TripListing
                                                                        class_name="col-md-6 col-xs-12"
                                                                        trips={trips}
                                                                        hide_load_mode = { true }
                                                                        onUpdate ={ ( trips) => setTrips(trips) }
                                                                    />
                                                                }
                                                            </>
                                                        }
                                                        {trips.data && trips.data.length > 0 &&
                                                            <Row>
                                                                <Col xs="12" className="d-flex justify-content-end mt-2 mb-4">
                                                                    <Link to={`/profile/${user_id}/all-listings`} className="btn btn-outline">View All Listings</Link>
                                                                </Col>
                                                            </Row>
                                                        }
                                                    </div>
                                                </ScrollableAnchor>
                                                <ScrollableAnchor id={'trip-reviews'}>
                                                    <div>
                                                        <Row>
                                                            <Col xs="12">
                                                                <h3 className="header5"><img src="/images/web/global/profile/trip-reviews.svg" alt="" />Trip Reviews</h3>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                                <ReviewSlider user_id={user_id}/>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </ScrollableAnchor>
                                                <ScrollableAnchor id={'endorsements'}>
                                                    <div>
                                                        <Row>
                                                            <Col xs="12">
                                                                <h3 className="header5"><img src="/images/web/global/profile/guard.svg" alt="" />Endorsements & References</h3>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xs="12">
                                                                <p>
                                                                    Endorsements and References provide additional verification for each host. Members and Nonmembers can provide endorsements for trip accuracy. Members can also request contact info for references from the host if they aren't listed below.
                                                                </p>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            {user.endorsements && user.endorsements && user.endorsements.map((item, index) => (
                                                                <React.Fragment key={index}>
                                                                    { item.endorsement_status_id === 20 &&
                                                                    <Col xs="12"  key={index}>
                                                                        <EndorsementCard
                                                                            data={item}
                                                                        />
                                                                    </Col>
                                                                    }
                                                                </React.Fragment>
                                                            ))}
                                                            {auth.is_logged_in &&
                                                                <Col xs="12" className="d-flex justify-content-end mt-2 mb-4">
                                                                    <Button
                                                                        className="btn btn-outline"
                                                                        onClick={ () =>
                                                                            show('EndorsmentViewProfileModal', {
                                                                                callBack: () => {
                                                                                    hide('EndorsmentViewProfileModal');
                                                                                    apiCalls.getUser(user_id);
                                                                                },
                                                                                user: user,
                                                                            })
                                                                        }
                                                                    >
                                                                        Endorse { user.full_name}
                                                                    </Button>
                                                                </Col>
                                                            }
                                                        </Row>
                                                        <Row>
                                                        { user.references && user.references  && user.references.map( (item, index) => (
                                                            <Col xs="12" md="6" key={index}>
                                                                <ReferenceCard
                                                                    refImage="images/web/global/logo-icon.svg"
                                                                    refUser={item.full_name}
                                                                    refEmail={item.email}
                                                                />
                                                            </Col>
                                                        ))}
                                                        </Row>
                                                    </div>
                                                </ScrollableAnchor>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Col>
                            <Col xs="12" lg="3">
                                <div className="profile-sidebar">
                                    <Row>
                                        <Col>
                                            <AboutHost
                                                data={user}
                                            />
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            <UserBadges
                                                data={user}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <StarRatingMinimal data={user} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs="12">
                                            <div className="profile-btn-wrap">
                                                <Link className="btn-outline btn-block" to={`/messages/${user_id}`}>Send Message</Link>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <hr className="hr-sidebar" />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Verifications data={user} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <hr className="hr-sidebar" />
                                        </Col>
                                    </Row>
                                    {
                                        auth?.is_logged_in &&
                                        <Row>
                                            <Col>
                                                <SocialLinks data={user} />
                                            </Col>
                                        </Row>
                                    }
                                    <Row>
                                        <Col>
                                            <hr className="hr-sidebar" />
                                        </Col>
                                    </Row>
                                </div>

                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
            {!loading &&
            <RelatedExperience user_id={user_id}/>
            }
        </div>


    );
}
