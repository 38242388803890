import React from "react";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';



export const NumberInputComponent = (props) => {

    const numberChange = (value, country_date) => {
        let phone_country_code = '1';
        let phone = value.replace(/[^0-9]+/g, '');;
        if( country_date ){
            phone_country_code = country_date.dialCode;
            phone = phone.replace( phone_country_code, '');
        }
        props.onChange({phone, phone_country_code});
    }
    return (
        <React.Fragment>
            <PhoneInput
                country={'us'}
                value={props.phone ? props.phone : ''}
                onChange={numberChange}
            />
        </React.Fragment>
    );
}

