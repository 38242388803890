import React, { useContext } from  "react";
import { Route, Redirect } from "react-router-dom";
import { AuthProvider } from 'js/contexts';
import { history } from 'js/helpers'


export const PrivateRoute = ({ component: Component, ...rest }) => {
    const { auth } = useContext(AuthProvider);
    return(
        <Route
            {...rest}
            render={props =>
                auth.is_logged_in ?
                    ((auth.user.is_sign_up_completed === 0 && auth.user.user_role === 'customer') ? history.push('/auth/signup') : <Component {...props} />)
                    : <Redirect to="/auth/login" />
            }
        />
    )
};

