import React, { useState, useEffect, useContext } from "react";
import { Row, Col, Input, Table, Form, FormGroup, CustomInput} from 'reactstrap';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import moment from 'moment';
import { forEach, isEmpty } from 'lodash';
import { BarLoader } from "react-spinners";

import { useParamChange, useModal } from 'js/hooks';
import { LookupContext } from 'js/contexts';
import { cmsServices, userServices } from 'js/services';
import { PaginationComponent, ListLoader } from 'js/components/common';
import { toast } from "react-toastify";
import { utilFunctions } from 'js/components/common/fucntions';

const bulk_actions = [
    { value: 'delete', label: 'Delete' }
];
const styles = {
    option: (styles, state) => ({
        ...styles,
        backgroundColor: state.isSelected ? "#FFA405 " : state.isFocused ? "#FFA40520" : ""
    })
};

export const ArticlesCMS = () => {

    const lookups = useContext(LookupContext);

    const [ articles, setArticles ] = useState([]);
    const [ state, setState ] = useState({
        checked : [],
        check_all: false,
        loading: false
    });
    const [ loader, setLoader ] = useState({});

    const getArticles = (params) => {
        setState({...state, loading: true})
        cmsServices.getArticles(params).then((response) => {
            setArticles(response);
            setState({...state, loading: false})
        }).catch((err) => {
            toast.error('Error Getting Experience');
        });
    }

    const [ show, hide ] = useModal();
    const [ params, onParamsChange ] = useParamChange( getArticles );

    useEffect(() => {
        getArticles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const deleteArticle = (article) => {
        cmsServices.deleteArticle(article).then(() => {
            getArticles(params);
            hide('ConfirmModal');
        }).catch((err) => {
            // let errors = err.data && err.data.errors;
            toast.error('Error Deleting Experience');
        })
    }

    const updatePublishStatus = ( item ) => {
        setLoader( loader => ({...loader, [item.slug] : true }));
        let data = { article_status_id : item.article_status.slug === 'published' ? 10 : 20 };
        cmsServices.updateArticle(item.slug, data)
        .then((response) => {
            setLoader( loader => ({...loader, [item.slug] : false }));
            const new_articles = utilFunctions.findAndReplaceArray(articles.data, { slug : item.slug }, response );
            setArticles(articles => ( { ...articles, data: new_articles } ));
        }).catch((error) => {
            setLoader( loader => ({...loader, [item.slug] : false }));
            toast.error('Failed To Update Status');
        });
    }

    const checkbox = (id) => {
        let { checked } = state;
        if( id === 'all'){
            if( state.check_all ) {
                checked = [];
            }
            else{
                forEach(articles.data, function(item) {
                    checked[item.article_id] = true;
                });
            }
            setState( {...state, checked, check_all: !state.check_all } );
        }
        else{
            checked[id] ? delete checked[id] :  checked[id] = true;
            setState( {...state, checked} );
        }
    }

    const bulkAction = ( type ) => {
        if( type === 'delete' ){
            show('ConfirmModal', {
                title: 'Delete Multiple Experiences',
                body: 'Do you want to delete Multiple Experience ?',
                button_name: 'Delete',
                action: bulkDelete,
            });
        }
    }

    const bulkDelete = () => {
        let data = {
            'action_type' : 'delete',
            'items' : Object.keys(state.checked)
        };
        cmsServices.articleBulkAction(data)
        .then((response) => {
            hide('ConfirmModal');
            getArticles(params);
        });
    }

    const loadAdmins = (inputValue, callback) => {
        let params = { show_admins: true };
        if(  inputValue !== '' ){
            params.search = inputValue;
        }
        userServices.getAll(params)
            .then((response) => {
                let data = response && response.data ? response.data.map((item) => ({ label: item.full_name, value: item.user_id })) : [];
                callback(data);
            });
    };

    return (
        <div>
            <div className="generic-page-header">
                <div className="header-data-section">
                    <h2 className="page-head my-0">BLOG</h2>
                </div>
                <div className="header-data-section">
                    <Link to={'/admin/static-content/experience/add-new-article'} color="primary" className="add-new-btn">
                        <i>
                            <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.03027 7.77295H0.171875V5.6001H5.03027V0.619629H7.22754V5.6001H12.0981V7.77295H7.22754V12.729H5.03027V7.77295Z" fill="#fff" />
                            </svg>
                        </i>Add New Post
                    </Link>
                </div>
            </div>
            <div className="tt-admin-card">
                <div className="tt-admin-card--header tt-admin-card--header_filter">
                    <div className="tt-filter">
                        <Form>
                            <Row className="justify-content-between">
                                <Col xs="12" lg="5">
                                    <Row>
                                        <Col xs="12" lg="6">
                                            <FormGroup>
                                                <div className="custom-react-select--no-border w-100">
                                                    <Select
                                                        className="react-select-container"
                                                        classNamePrefix="react-select"
                                                        options={bulk_actions}
                                                        styles={styles}
                                                        placeholder="Bulk Actions"
                                                        onChange={ (e) => bulkAction( e.value) }
                                                    />
                                                </div>
                                            </FormGroup>
                                        </Col>
                                        <Col xs="12" lg="6">
                                            <FormGroup>
                                                <div className="filter-input--outer">
                                                    <Input
                                                        type="text"
                                                        placeholder="Search by Title"
                                                        onChange={ (e) => onParamsChange('search', e.target.value ) }
                                                    />
                                                </div>
                                            </FormGroup>
                                        </Col>
                                    </Row>


                                </Col>
                                <Col xs="12" lg="6">
                                    <Row>
                                        <Col xs="12" md="4">
                                            <FormGroup>
                                                <div className="custom-react-select--no-border w-100">
                                                    <Select
                                                        className="react-select-container"
                                                        classNamePrefix="react-select"
                                                        options={lookups.article_categories}
                                                        styles={styles}
                                                        placeholder="Category"
                                                        onChange={ (e) => onParamsChange('category', e.value ) }
                                                    />
                                                </div>
                                            </FormGroup>
                                        </Col>
                                        <Col xs="12" md="4">
                                            <FormGroup>
                                                <div className="custom-react-select--no-border w-100">
                                                    <AsyncSelect
                                                        placeholder="Auther"
                                                        cacheOptions
                                                        defaultOptions
                                                        loadOptions={loadAdmins}
                                                        className="react-select-container"
                                                        classNamePrefix="react-select"
                                                        styles={styles}
                                                        onChange={ (e) => onParamsChange('author', e.value ) }
                                                    />
                                                </div>
                                            </FormGroup>
                                        </Col>
                                        <Col xs="12" md="4">
                                            <FormGroup>
                                                <div className="custom-react-select--no-border w-100">
                                                    <Select
                                                        className="react-select-container"
                                                        classNamePrefix="react-select"
                                                        options={lookups.article_statuses}
                                                        styles={styles}
                                                        placeholder="Status"
                                                        onChange={ (e) => onParamsChange('status', e.value ) }
                                                    />
                                                </div>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </div>
                <div className="tt-admin-card--data">
                    <div className="table-wrap">
                        <Table responsive className="tt-admin-table-compact tt-admin-table-compact--checkbox ">
                            <thead>
                                <tr>
                                    <th>
                                        <div className="tt-admin-checkbox">
                                            <CustomInput
                                                type="checkbox"
                                                id="tt-check-1"
                                                label=""
                                                className="tt-checkbox"
                                                checked={state.check_all}
                                                onChange={ () => checkbox('all') }
                                            />
                                        </div>
                                    </th>
                                    <th>Title</th>
                                    <th>Category</th>
                                    <th>Author</th>
                                    <th className="sortable" onClick={ () => onParamsChange('sort', 'published_date')} >Published</th>
                                    <th className="sortable" onClick={ () => onParamsChange('sort', 'article_status_id')} >Status</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {state.loading ?
                                    <tr>
                                        <td colSpan="7" align="center"><ListLoader /></td>
                                    </tr>
                                    :
                                    articles && isEmpty(articles.data) ?
                                        <tr>
                                            <td colSpan="7" align="center">No Records Found.</td>
                                        </tr>
                                        : articles && articles.data && articles.data.map( (item, index) => (
                                        <tr key={index} >
                                            <td>
                                                <div className="tt-admin-checkbox">
                                                    <CustomInput
                                                        type="checkbox"
                                                        id={`checkbox${item.article_id}`}
                                                        label=""
                                                        className="tt-checkbox"
                                                        checked={ state.checked && state.checked[item.article_id] ? state.checked[item.article_id] : false }
                                                        onChange={ () => checkbox(item.article_id) }
                                                    />
                                                </div>
                                            </td>
                                            <td>{item.title}</td>
                                            <td>{item.article_category && item.article_category.label}</td>
                                            <td>{item.user && item.user.full_name}</td>
                                            <td>{moment(item.published).format('M/DD/YYYY')}</td>
                                            <td>
                                                <div className="ads-switch">
                                                { loader[item.slug] ?
                                                    <BarLoader  width={40} color={"#FFA405"} loading={true} />
                                                :

                                                    <CustomInput
                                                        type="switch"
                                                        id={`publishedSwitch${index}`}
                                                        checked={item.article_status && item.article_status.slug === 'published' ? true : false }
                                                        onChange={ () => updatePublishStatus(item)}
                                                    />
                                                }
                                                </div>
                                            </td>
                                            <td>
                                                <div className="action-wrap">
                                                    <Link to={`/admin/static-content/experience/${item.slug}`}><img src="/images/admin/global/edit.svg" alt="" /></Link>
                                                    <Link
                                                        to={"#"}
                                                        onClick={() =>
                                                            show('ConfirmModal', {
                                                                title: 'Delete This Experience',
                                                                body: 'Do you want to delete this Experience ?',
                                                                button_name: 'Delete',
                                                                action: () => deleteArticle(item.slug),
                                                            })
                                                        }
                                                    >
                                                        <img src="/images/admin/global/delete.svg" alt="" />
                                                    </Link>
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </Table>
                    </div>
                </div>
                {articles && articles.meta &&
                    <PaginationComponent page={articles.meta} onChange={(page) => onParamsChange('page', page)} />
                }
            </div>
        </div>


    );
}
