
import React from "react"
import { Link } from 'react-router-dom';

import "js/components/legos/styles/contest-card-module.css"

export default props => (
    <div className="contest-card">
        <div className="contest-card--top-section">
            <div className="contest-icon">{props.contestType}</div>
            <div className="contest-big-icon">
                <img src="images/web/contests/contest.svg" alt="" />
            </div>
            <div className="contest-value"><img src="images/web/contests/ad-value.svg" alt="" /> {props.contestValue}</div>
        </div>
        <div className="contest-card--bottom-section">
            <div className="contest-name--location">
                <h3>{props.contestName}</h3>
                <h5><i><img src="images/web/contests/t4t-logo.svg" alt="" /></i>{props.memberType}</h5>
            </div>
            <div className="contest-people-days-promo">
                <div className="contest-days"><i><img src="images/web/contests/calender.svg" alt="" /></i>{props.contestDate}</div>
                <div className="contest-links">
                    <Link to={props.contestURL} className="btn-outline--sm" >Learn More</Link>
                </div>
            </div>
        </div>
        <img className="contest-card--img" src={props.contestCardImage} alt="" />
    </div>
)
