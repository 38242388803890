import React, { useState, useEffect, useContext } from "react";
import { Row, Col, Form, FormGroup, Label, Input, Button, Spinner } from 'reactstrap';
import { Helmet } from "react-helmet";
import { toast } from 'react-toastify';
import { isEmpty } from 'lodash';

import { AuthProvider } from 'js/contexts';
import { supportServices } from 'js/services';
import { ErrorFormFeedback, NumberInputComponent } from 'js/components/common';




export const ContactUs = () => {

    const { auth } = useContext(AuthProvider);

    const [state, setState] = useState({
        full_name: '',
        email: '',
        phone: '',
        subject: '',
        message: ''
    });

    const [errors, setErrors] = useState({});
    const [loader, setloader] = useState(false);

    const handleChange = (value, key) => {
        const newState = { ...state };
        newState[key] = value;
        setState(newState);
    }
    const submit = () => {
        setloader(true);
        supportServices.submitContactUs(state)
            .then((response) => {
                setloader(false);
                toast.success('Request Submitted Successfully');
                setState((state) => ({
                    ...state,
                    subject: '',
                    message: ''
                }))
            }).catch((error) => {
                setloader(false);
                if (error.data && error.data.errors) {
                    setErrors(error.data.errors);
                }
                else {
                    toast.error('Failed To Edit Article');
                }
            });
    }

    useEffect(() => {
        if (auth.is_logged_in && auth.user) {
            setState({
                full_name: auth.user.full_name,
                email: auth.user.email,
                phone: auth.user.phone,
                phone_country_code: auth.user.phone_country_code
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChangePhone = (value) => {
        setState({ ...state, phone: value.phone, phone_country_code: value.phone_country_code });
    }


    return (
        <div>
            <Helmet>
                <title>Contact - Trips4Trade - Thanks for checking out Trips4Trade.</title>
                <meta name="description" content="Drop us a note. Need help with something? Have a suggestion for a new feature on the site? Just want to say hello? Thanks for checking out Trips4Trade." />
            </Helmet>

            <div className="inner-banner" style={{ backgroundImage: `url(images/web/global/banners/static-banner.png)` }}>
                <div className="container">
                    <Row>
                        <Col xs="12">
                            <h1 className="header2 color--white text-uppercase">Contact Us</h1>
                        </Col>

                    </Row>
                </div>
            </div>
            <div className="wider-data-frame wider-data-frame--grey wider-data-frame--contact">
                <div className="container">
                    <Row>
                        <Col>
                            <p className="p">
                                Need help with something? Have a suggestion for a new feature on the site? Just want to say hello? Leave a message here or shoot us a note at hello@trips4trade.com and a Trips4Trade team member will follow up with you shortly. Thanks for checking out Trips4Trade, and we look forward to hearing from you.
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="tt-card contact-form-outer">
                                <Row>
                                    <Col>
                                        <Form className="tt-web-form">
                                            <Row form>
                                                <Col xs="12" md="6" lg="4">
                                                    <FormGroup>
                                                        <Label for="">Full Name</Label>
                                                        <Input
                                                            type="text"
                                                            value={state.full_name}
                                                            onChange={(e) => handleChange(e.target.value, 'full_name')}
                                                            invalid={!isEmpty(errors) && (errors.full_name) && true}
                                                        />
                                                        <ErrorFormFeedback field={'full_name'} errors={errors} />
                                                    </FormGroup>
                                                </Col>
                                                <Col xs="12" md="6" lg="4">
                                                    <FormGroup>
                                                        <Label for="">Your Email</Label>
                                                        <Input
                                                            type="text"
                                                            value={state.email}
                                                            onChange={(e) => handleChange(e.target.value, 'email')}
                                                            invalid={!isEmpty(errors) && (errors.email) && true}
                                                        />
                                                        <ErrorFormFeedback field={'email'} errors={errors} />
                                                    </FormGroup>
                                                </Col>
                                                <Col xs="12" md="6" lg="4">
                                                    <FormGroup>
                                                        <Label for="">Your Phone</Label>
                                                        <NumberInputComponent
                                                            phone={`+${state.phone_country_code}${state.phone}`}
                                                            onChange={handleChangePhone}
                                                        />

                                                        {!isEmpty(errors) && errors.phone && (
                                                            <div>
                                                                <div className="invalid-feedback d-block">{errors.phone}</div>
                                                            </div>
                                                        )}
                                                        {!isEmpty(errors) && errors.phone_country_code && (
                                                            <div>
                                                                <div className="invalid-feedback d-block">{errors.phone_country_code}</div>
                                                            </div>
                                                        )}
                                                    </FormGroup>
                                                </Col>
                                                <Col xs="12">
                                                    <FormGroup>
                                                        <Label for="">Subject</Label>
                                                        <Input
                                                            type="text"
                                                            value={state.subject}
                                                            onChange={(e) => handleChange(e.target.value, 'subject')}
                                                            invalid={!isEmpty(errors) && (errors.subject) && true}
                                                        />
                                                        <ErrorFormFeedback field={'subject'} errors={errors} />
                                                    </FormGroup>
                                                </Col>
                                                <Col xs="12">
                                                    <FormGroup>
                                                        <Label for="">Message</Label>
                                                        <Input
                                                            type="textarea"
                                                            value={state.message}
                                                            onChange={(e) => handleChange(e.target.value, 'message')}
                                                            invalid={!isEmpty(errors) && (errors.message) && true}
                                                        />
                                                        <ErrorFormFeedback field={'message'} errors={errors} />
                                                    </FormGroup>
                                                </Col>

                                            </Row>
                                            <Row>
                                                <Col xs="12" className="d-flex justify-content-center">
                                                    <Button
                                                        onClick={submit}
                                                        className="btn btn-outline"
                                                    >{loader ? <Spinner size="sm" /> : 'Submit'}
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
}
