import React, { useContext, useEffect, useState } from "react";
import { Table } from 'reactstrap';
import Select from 'react-select';
import { isEmpty, find, cloneDeep } from 'lodash';

import { ParamProvider } from 'js/components/admin/components/reports/trips/trips';
//import { LookupContext } from 'js/contexts';
import { GoogleMapWrapper } from "js/components/common";


const DashboardFilter = [
    { value: 'trip_categories', label: 'Total Categories' },
    { value: 'countries', label: 'Total Countries' },
    { value: 'states', label: 'Total States' },
];
const styles = {
    option: (styles, state) => ({
        ...styles,
        backgroundColor: state.isSelected ? "#FFA405 " : state.isFocused ? "#FFA40520" : ""
    })
};


export const TotalListingsReport = (props) => {

    const { params, setParams } = useContext(ParamProvider);
    const [data, setData] = useState([]);
    const [map, setMap] = useState([]);
    const [key_value, setValue] = useState('');

    // const lookups = useContext(LookupContext);

    // const image_hash = {
    //     outdoors: 'outdoors.svg',
    //     adventure: 'adventures.svg',
    //     vacation: 'vacation.svg',
    //     sports: 'sports.svg',
    // }

    const title = {
        trip_categories: 'Category',
        countries: 'Country',
        states: 'State'
    }

    useEffect(() => {
        const key = {
            trip_categories: 'trip_category',
            countries: 'country',
            states: 'state'
        }
        const value = key[props.type];
        setValue(value);
        setData(cloneDeep(props.table_data));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.table_data]);

    useEffect(() => {
        setMap(props.map_data);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.map_data])

    return (
        <div>
            <div className="tt-admin-card mb-4">
                <div style={{ height: '374px', width: '100%' }}>
                    {!isEmpty(map) &&
                        <GoogleMapWrapper data={map} fitBounds />
                    }
                </div>
            </div>
            <div className="tt-admin-card">
                <div className="tt-admin-card--header">
                    <div className="custom-react-select--lg">
                        <Select
                            className="react-select-container"
                            classNamePrefix="react-select"
                            defaultValue={DashboardFilter[0]}
                            options={DashboardFilter}
                            styles={styles}
                            onChange={(e) => setParams(() => ({
                                ...params,
                                type: e.value
                            }))}
                            value={params.type ? find(DashboardFilter, (item) => { return item.value === params.type }) : ''}
                        />
                    </div>
                </div>
                <div className="tt-admin-card--data">
                    <div className="table-wrap">
                        {
                            isEmpty(data) ?
                                <div className="no-records-found">
                                    <p>No Records Found.</p>
                                </div>
                                :
                                <Table responsive className="tt-admin-table-compact">
                                    <thead>
                                        <tr>
                                            <th>{props.type ? title[props.type] : ''}</th>
                                            <th className="sortable text-center" onClick={()=>setParams({...params, sort : params.sort === 'number_of_trips' ? '-number_of_trips' : 'number_of_trips' })} >Number of Trips</th>
                                            <th className="sortable text-center" onClick={()=>setParams({...params, sort : params.sort === 'percentage' ? '-percentage' : 'percentage' })} >% of Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            !isEmpty(data) && data.map((item, index) => (
                                                <tr key={`list_data${index}`}>
                                                    {
                                                        props.type === 'trip_categories' ?
                                                            <td>
                                                                <img src={item.icon_image_url} alt="" className="mr-1" />{item.trip_category ? item.trip_category : ''}
                                                            </td>
                                                            :
                                                            <td>
                                                                {item[key_value] ? item[key_value] : ''}
                                                            </td>
                                                    }
                                                    <td className="text-center">{item.number_of_trips ? item.number_of_trips : ''}</td>
                                                    <td className="text-center">{item.percentage ? item.percentage : ''}</td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </Table>
                        }

                    </div>
                </div>
            </div>
        </div>
    );
}
