import React, { useState, useEffect, useContext } from 'react';
import { Row, Col, TabContent, TabPane, Nav, NavItem, NavLink, Alert } from 'reactstrap';
import { Helmet } from "react-helmet";
import classnames from 'classnames';
import { isEmpty, cloneDeep } from 'lodash';

import { ListLoader } from "js/components/common";
import { CreateAccount, PaymentInformation, Confirmation } from 'js/components/auth/signup/tabs';
import { configConstants } from 'js/constants';
import { authServices } from 'js/services/auth-service';
import { AuthProvider } from 'js/contexts';

export const SignUp = (props) => {
    const [activeTab, setActiveTab] = useState('create_account');
    const [completed, setCompleted] = useState({
        create_account: true,
        payment_information: false,
        confirmation: false,
    })
    const [confirmation, setConfirmation] = useState({});
    const [loading, setLoading] = useState(true);
    const [message, setMessage] = useState('');
    const { auth, setAuth } = useContext(AuthProvider);


    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }

    useEffect(() => {
        const token = localStorage.getItem(configConstants.TOKEN_NAME);
        const partner_token = localStorage.getItem(configConstants.PARTNER_TOKEN);

        if(token && partner_token){
            authServices.getProfile().then((response) => {
                setAuth({ ...auth, user: cloneDeep(response), is_logged_in: true });
                if(!isEmpty(response) && response.is_sign_up_completed === 0){
                    toggle('payment_information');
                    setCompleted({ ...completed, 'payment_information': true });
                    setLoading(false);
                    setMessage('Please complete your payment information to start using your account.');
                }
            });
        }
        else {
            setLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    let referral_token = props.match && props.match.params && props.match.params.referral_token ? props.match.params.referral_token : null;

    return (
        <div>
            <Helmet>
                <title>Trip4Trade :: Sign Up</title>
                <meta name="description" content="Signup page desription" />
                <meta name="keywords" content="trip4trade, Signup" />
            </Helmet>
            <div className="inner-banner" style={{ backgroundImage: `url(/images/web/global/banners/static-banner.png)` }}>
                <div className="container">
                    <Row>
                        <Col xs="12">
                            <h1 className="header2 color--white text-uppercase">Sign up</h1>
                        </Col>
                    </Row>
                </div>
            </div>
            <div className="wider-data-frame wider-data-frame--grey wider-data-frame--signup">
                <div className="container">
                    <Row>
                        <Col xs="12">
                            <div className="tt-card">
                                { message && !completed.confirmation &&
                                    (<Alert color="danger">
                                        {message}
                                    </Alert>)
                                }
                                {loading ?
                                    <ListLoader />
                                    :
                                    (<>
                                        <Nav tabs className="signup-tabs">
                                            <NavItem className={classnames({ completed: completed.create_account })}>
                                                <NavLink
                                                >Step 1</NavLink>
                                            </NavItem>
                                            <NavItem className={classnames({ completed: completed.payment_information })}>
                                                <NavLink
                                                >Step 2</NavLink>
                                            </NavItem>
                                            <NavItem className={classnames({ completed: completed.confirmation })}>
                                                <NavLink
                                                >Step 3</NavLink>
                                            </NavItem>
                                        </Nav>
                                        <TabContent activeTab={activeTab}>
                                            <TabPane tabId="create_account">
                                                {
                                                    activeTab === 'create_account' &&
                                                    <CreateAccount
                                                        callBack={(tab_name) => {
                                                            toggle(tab_name);
                                                            setCompleted({ ...completed, 'payment_information': true })
                                                        }}
                                                    />
                                                }
                                            </TabPane>
                                            <TabPane tabId="payment_information">
                                                {
                                                    activeTab === 'payment_information' &&
                                                    <PaymentInformation
                                                        callBack={(tab_name) => {
                                                            toggle(tab_name);
                                                            setCompleted({ ...completed, 'confirmation': true })
                                                        }}
                                                        referral_token={referral_token}
                                                        callBackData={(response) => setConfirmation(response)}
                                                    />
                                                }
                                            </TabPane>
                                            <TabPane tabId="confirmation">
                                                {
                                                    activeTab === 'confirmation' &&
                                                    <Confirmation
                                                        confirmation_data={confirmation}
                                                    />
                                                }
                                            </TabPane>
                                        </TabContent>
                                    </>)
                                }
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
}
