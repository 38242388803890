import React, { useState, useEffect } from "react";
import { Row, Col } from 'reactstrap';
import queryString from 'query-string';

import { tradeServices } from 'js/services';

export const TradeConfirmation = (props) => {
    const [ message, setMessage] = useState('');
    const getTradeConfirmed = ( trip_trade_id, params ) => {
        tradeServices.confirmTrade( trip_trade_id, params )
        .then((response) => {
            setMessage(response.data?.message)
        }).catch((error) => {
            error.data
            && error.data.errors
            && error.data.errors.general
            && setMessage(error.data.errors.general);
        });
    }
    const { location, match } = props;
    useEffect(() => {
        if(location?.search && match?.params?.trip_trade_id) {
            const params = queryString.parse(location.search)
            const trip_trade_id = match.params.trip_trade_id;
            getTradeConfirmed(trip_trade_id, params);
        }
    }, [location, match]);

    return (
        <div>
            <div className="inner-banner" style={{ backgroundImage: `url(/images/web/global/banners/static-banner.png)` }}>
                <div className="container">
                    <Row>
                        <Col xs="12">
                            <h1 className="header2 color--white text-uppercase">Trade Confirmation</h1>
                        </Col>

                    </Row>
                </div>
            </div>
            <div className="wider-data-frame wider-data-frame--grey wider-data-frame--contact">
                <div className="container">
                    <Row>
                        <Col>
                            <p className="p">
                                {message}
                            </p>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
}
