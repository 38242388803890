import React from "react"
import "js/components/legos/styles/about-host-module.css"
import moment from "moment";
import { Spinner } from "reactstrap";


export default props => {
    const { data, loading } = props;
    return (
        <div className="about-host-outer">
        {loading? <Spinner color="warning"/> :
            <>
                {data && data.attachment ?
                    <div className="host-img">
                        <img src={  data.attachment.thumbnail_url ?  data.attachment.thumbnail_url : data.attachment.attachment_url} alt="" />
                    </div>
                    :
                    <div className="default-img">
                    <img src={'/images/web/global/logo-icon.svg' } alt="" />
                    </div>
                }
            </>
        }
            <h5>{ data ? data.full_name: ''}</h5>
            <p><img src="/images/web/global/profile/member-since.svg" alt="" /> <b>Member Since:</b>{data && data.created_at ? moment(data.created_at).format('MMM, YYYY'): ''}</p>
        </div>
    );
}
