import React, { useState, useEffect, useContext } from "react";
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { concat, isEmpty } from 'lodash';

import ExperienceSlider from "js/components/legos/experienceSlider";

import { SearchHeader } from "../search-header";
import { tripsServices } from "js/services";
import { AuthProvider } from 'js/contexts';
import { TripListing, ListLoader, StaticLink } from "js/components/common";
import { LookupContext } from 'js/contexts';
import { useAdsClick } from 'js/hooks';

export const Trips = ( props ) => {
    const [ state, setState ] = useState({
        searched: false
    });
    const { auth } = useContext(AuthProvider);
    const [ home, setHome ] = useState([]);
    const [ trips, setTrips ] = useState({});
    const [ params, setParams ] = useState({});
    const [ loading, setLoading ] = useState(false);
    const [ load_more_loading, setLoadMoreLoading ] = useState(false);
    const [ home_loading, setHomeLoading ] = useState(false);
    const lookups = useContext(LookupContext);
    const { onAdClick } = useAdsClick()

    const getAllTrips = ((params, mode) => {
        mode === 'append' ? setLoadMoreLoading(true) : setLoading(true);
        tripsServices.getAll(params).then(response => {
            mode === 'append' ? setLoadMoreLoading(false) : setLoading(false);
            if(mode === 'append') {
                response.data = concat( trips.data, response.data );
            }
            response.data = getRandomAd(response.data, response.ads)
            setTrips(response)

        }).catch(error => {
            console.log(error)
        })
    })
    function getRandomAd(array, element){
        // first select a random position
        var random_pos = Math.round(Math.random() * array.length);
        let new_array = array.map(item => {
            return{...item, type: 'trip'}
        })
        if(!isEmpty(element) && !isEmpty(element[0])) {
            new_array.splice(random_pos, 0,  {...element[0], type: 'ad'})
        }
        return new_array

    };
    const getHomePage = () => {
        setHomeLoading(true);
        tripsServices.getTripHomePage()
        .then( response => {
            setHomeLoading(false);
            setHome( response );
        })
    }
    const updateHome = (updated_trips, index ) => {
        home.splice(index,1,{ ...home[index], trips: updated_trips });
        setHome([...home]);
    }

    const handleSearch = (params) => {
        setState({...state, searched : true })
        setParams(params)
        getAllTrips({...params, visibility: 'public'})
    }
    useEffect(() => {
        getHomePage();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <div>
            <Helmet>
                <title>Trip4Trade :: Trips</title>
                <meta name="description" content="Trade For The Trip Of A Lifetime. Trips4Trade allows users to trade trips pertaining to outdoors, adventure, vacation, and sports." />
            </Helmet>

            <div className="inner-banner-advertisement inner-banner-advertisement--filter" style={{backgroundImage: `url(/images/web/home/hero-banner.png)`}}>
                <div className="container">
                    <Row>
                        <Col xs="12">
                            <h1 className="header2 color--white text-uppercase">Trips</h1>
                        </Col>
                    </Row>
                </div>
            </div>
            {
                !auth?.is_logged_in &&
                <div className="wider-data-frame wider-data-frame--community-band">
                    <div className="container">
                        <Row>
                            <Col xs="12" md="6" className="community-band-divider">
                                <div className="community-info">
                                <Row>
                                    <Col xs="12" md="12" lg="4"><p><img src="images/web/trips/stats-countries.svg" alt="" /> <b>{lookups.trip_countries_count}</b> Countries</p></Col>
                                    <Col xs="12" md="12" lg="3"><p><img src="images/web/trips/usa.svg" alt="" /> <b>{lookups.trip_states_count}</b> States</p></Col>
                                    <Col xs="12" md="12" lg="5"><p><img src="images/web/trips/users.svg" alt="" /> <img src="images/web/trips/plus.svg" alt="" /> Growing Everyday</p></Col>
                                </Row>
                                </div>

                            </Col>

                            <Col xs="12" md="6">
                                <div className="join-community">
                                    <div className="join-community--data">
                                        <h4>Join the Trips4Trade Community </h4>
                                        <p>Sign Up to use search filters, receive personal trip suggestions, and find your next bucket list trip!</p>
                                    </div>
                                    <div className="join-community--action">
                                        <Link to={"/auth/signup"} className="btn btn-solid-sm">Sign Up</Link>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            }
            {
                auth?.is_logged_in &&
                <SearchHeader onSearch={(params) => handleSearch(params)} location={ props.location} match={ props.match} disable_auth_load={!state.searched} />
            }
            { state.searched ?
                <div className="wider-data-frame wider-data-frame--grey">
                    <div className="container">
                        { loading ?
                            <ListLoader/>
                        :
                            <TripListing
                                trips={trips}
                                onUpdate ={ ( updated_trips ) => setTrips( trips=> ({...trips, data :updated_trips})) }
                                onLoadMore={(page) => getAllTrips({
                                    ...params,
                                    page,
                                    offset: trips.meta.to
                                }, 'append')}
                                load_more_loading={load_more_loading}
                            />
                        }
                    </div>
                </div>
            :
                <div className="wider-data-frame wider-data-frame--grey">
                    <div className="container">
                        { home_loading ?
                            <ListLoader />
                        :
                            <React.Fragment>
                                { home && home.map( (item, index ) =>(
                                <div className="trips-data-outer" key={index}>
                                    <Row>
                                        <Col>
                                            <h3 className="header4 text-uppercase">{item.title}</h3>
                                            {!isEmpty(item.ads) && item.ads.map((ad, index) => (
                                                ad.advertisement_type && ad.advertisement_type.slug === 'text-link' ?
                                                <StaticLink
                                                    adClick={true}
                                                    key={index}
                                                    url={ad.external_link}
                                                    content={<h5 className="featured">{ad.title}</h5>}
                                                    onChange={() => onAdClick(ad.advertisement_id)}
                                                />
                                                : null
                                            ))}
                                        </Col>

                                    </Row>
                                    <Row>
                                        <Col className="mx-0">
                                            <ExperienceSlider trips={item.trips} enable_bookmark={true} onUpdate ={ (updated_trips) => updateHome( updated_trips, index ) } />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs="12" className="d-flex justify-content-end">
                                            <Link
                                                to={ item.type === 'category' && item?.slug ?
                                                    `/trips/${ item.slug }` : '/search' }
                                                className="btn btn-outline--sm">
                                                {item.type === 'category' ? `ALL ${item.title}` : 'All Trips'}
                                                <img src="images/web/global/arrow-left-orange.svg" alt=""  />
                                            </Link>
                                        </Col>
                                    </Row>
                                </div>
                                ))}
                            </React.Fragment>
                        }
                    </div>
                </div>
            }
        </div>
    );
}
