import { request } from "js/helpers/api";

export const settingServices = {
    get,
    update
};

function get(setting){
    return request({
        method:'GET',
        url: `settings/${setting}`,
        param: {},
    });
}

function update(details, setting){
    return request({
        method:'PUT',
        url: `settings/${setting}`,
        param: details,
        content_type: 'json'
    });
}
