import React from 'react';
import { Row, Col, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import { Helmet } from "react-helmet";
import classnames from 'classnames';
import { useState } from 'react';
import { DetailsTab, DescriptionTab, MediaTab } from './components';
import { tripsServices } from 'js/services';
import { useModal } from 'js/hooks';
import { history } from 'js/helpers';

export const AddListing = () => {
    const [ active_tab, toggle ] = useState('details');
    const [ trip, setTrip ] = useState({});
    const [ state, setState ] = useState({
        loading: false,
    })
    const [ show, hide ] = useModal();
    const childchange = ( data ) => {
        const { details, next_tab} = data
        setTrip({...trip, ...details})
        if(next_tab) {
            setState({...state, [active_tab]: true, [next_tab]: true})
            toggle(next_tab)
        }
        else {
            setState({...state, [active_tab]: true})
            addTrip({...trip, ...details})
        }
    }

    const ontoggle = (tab) => {
        if(state[tab]) {
            toggle(tab)
        }
    }

    const addTrip = (details) => {
        setState({...state, loading:true})
        tripsServices.create(details)
            .then( response => {
                setTrip( response)
                toggle('media')
        //    history.push(`/trips/details/${response.slug}`)
        }).catch(error => {
            setState({...state, loading:false})
            error.data && error.data.errors && error.data.errors.general && show('ErrorModal', {
                title: 'Error',
                body: error.data.errors.general,
                callBack: () =>  { hide(); history.push('/home'); }
            });
        })
    }

    return (
        <div>
            <Helmet>
                <title>Trip4Trade :: Add Listing</title>
                <meta name="description" content="ADD LISTING Please use the form below to submit your trip information. We recommend separating each trip you have to offer into different listings so that they can be filtered appropriately. Message support@trips4trade.com if you have any questions. Add listing You must be logged in to post new listings. Log In or Sign UpTo access this page, you must purchase Trips4Trade Annual Membership." />
                <meta name="keywords" content="trip4trade, Signup" />
            </Helmet>
            <div className="inner-banner" style={{ backgroundImage: `url(/images/web/global/banners/static-banner.png)` }}>
                <div className="container">
                    <Row>
                        <Col xs="12">
                            <h1 className="header2 color--white text-uppercase">Add Listing</h1>
                            <p className="header-text">Please use the form below to submit your trip information. We recommend separating each trip you have to offer into different listings so that they can be filtered appropriately. Contact us if you have any questions.</p>
                        </Col>

                    </Row>
                </div>
            </div>
            <div className="wider-data-frame wider-data-frame--grey wider-data-frame--signup wider-data-frame--add-listing">
                <div className="container">
                    <Row>
                        <Col xs="12">
                            <div className="tt-card">
                                <Nav tabs className="signup-tabs">
                                    <NavItem className={classnames({completed: state['details']})}>
                                        <NavLink className={classnames({ active: active_tab === 'details' })} onClick={() => ontoggle('details')}>Step 1</NavLink>
                                    </NavItem>
                                    <NavItem className={classnames({completed: state['description']})}>
                                        <NavLink className={classnames({ active: active_tab === 'description' })}onClick={() => ontoggle('description')}>Step 2</NavLink>
                                    </NavItem>
                                    <NavItem className={classnames({completed: state['media']})}>
                                        <NavLink className={classnames({ active: active_tab === 'media' })}onClick={() => ontoggle('media')}>Step 3</NavLink>
                                    </NavItem>
                                </Nav>
                                <TabContent activeTab={active_tab}>
                                    <TabPane tabId="details">
                                        <Row>
                                            <Col xs="12">
                                                <DetailsTab onChange={(data) => childchange(data)}/>
                                            </Col>
                                        </Row>
                                    </TabPane>
                                    <TabPane tabId="description">
                                        <Row>
                                            <Col xs="12">
                                                <DescriptionTab onChange={(data) => childchange(data)} loading={ state.loading }/>
                                            </Col>
                                        </Row>
                                    </TabPane>
                                    <TabPane tabId="media">
                                        <Row>
                                            <Col xs="12">
                                                <MediaTab
                                                    onChange={(data) => childchange(data)}
                                                    trip={trip}
                                                />
                                            </Col>
                                        </Row>
                                    </TabPane>
                                </TabContent>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
}




