import React, { useState, useContext, useEffect, useRef } from 'react';
import { Row, Col, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { Helmet } from "react-helmet";
import { forEach, concat } from 'lodash';

import { NavigationSidebar } from "js/components/web/components/layouts/components/navigation-sidebar";
import { LookupContext, AuthProvider } from 'js/contexts';
import { tripsServices } from 'js/services';
import { ListLoader,  TripListing, RelatedExperience } from 'js/components/common';

export const MyListings = (props) => {

    const [active_tab, setActiveTab] = useState('all');
    const [trips, setTrip] = useState({})
    const lookups = useContext(LookupContext);
    let tabs = useRef({});
    const { auth } = useContext(AuthProvider);
    const user = auth.user.user_id;
    const [ state, setState ] = useState({
        loading: false,
        load_more_loading : false
    })
    const [ active_category, setCategory ] = useState(null);
    const toggle = tab => {
        let active_tab = tab === 'all' ? tab : tab.slug
        setActiveTab(active_tab);
        let params = tab === 'all' ? {} : {category: tab.trip_category_id}
        params.category && setCategory( params.category)
        getAllTrips(params, tab.slug)
    }

    const getAllTrips =  function (params, category, mode) {
        mode === 'append' ?  setState({...state, load_more_loading: true}) : setState({...state, loading: true});
        tripsServices.getAll({...params, user: user } , params.category)
        .then(response => {
            if( !category ) {
                category = 'all'
            }
            console.log( category );
            if(mode === 'append') {
                let data = category &&  trips[category] && trips[category].data  ? trips[category].data : [];
                response.data = concat( data, response.data );
            }

            mode === 'append' ?  setState({...state, load_more_loading: false}) : setState({...state, loading: false});
            setTrip( trips => ({
                ...trips,
                [category]: response
            }));
        }).catch(err => {
            console.log('failed', err)
        })
    }

    useEffect(() => {
        setState({...state, loading: true})
        getAllTrips({})
        Promise.all(forEach(lookups.trip_categories, item => {
            getAllTrips({ category: item.trip_category_id}, item.slug )

        })).then(res => {

            setState({...state, loading: false})
        }).catch(error => {
            console.log('promise failed', error)
        })
        return () => {
            //cleanup
            tabs.current = null
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onUpdate = (category, updated_trips) => {
        setTrip(trips => ({
            ...trips,
            [category] : {
                ...trips[category],
                data : updated_trips
            }
        }));
    }

    return (
        <div>
            <Helmet>
                <title>Trip4Trade :: My Listings</title>
                <meta name="description" content=" My Listings page desription" />
                <meta name="keywords" content="trip4trade,  My Listings" />
            </Helmet>

            <div className="inner-banner-profile" style={{ backgroundImage: `url(/images/web/global/banners/profile-banner.png)` }}>

            </div>
            <div className="wider-data-frame wider-data-frame--grey pt-0">
                <div className="container">
                    <div className="profile-outer-wrapper">
                        <Row className="no-gutters">
                            <Col xs="12" lg="9">
                                <div className="profile-data-section">
                                    <div className="profile-data-section--top">
                                        <Row>
                                            <Col xs="12" md="8" className="pb-4">
                                                <h3><img src="/images/web/global/profile/listings.svg" alt="" style={{ width: `37px` }} /> My Listings</h3>
                                            </Col>
                                            <Col xs="12" md="4" className="d-flex justify-content-end align-items-start">
                                                <Link to={"/add-listing"} className="btn btn-outline text-capitalize">Add Listing</Link>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs="12">
                                                <Nav tabs className="tt-tabs">
                                                    <NavItem>
                                                    <NavLink
                                                        className={classnames({ active: active_tab === 'all' })}
                                                        onClick={() => { toggle('all'); }}
                                                    >
                                                            {`All (${ trips.all && trips.all.meta ? trips.all.meta.total : '' })`}
                                                    </NavLink>
                                                    </NavItem>
                                                    {lookups.trip_categories && lookups.trip_categories.map((category, index) => (
                                                        <NavItem key={index}>
                                                            <NavLink
                                                                className={classnames({ active: active_tab === category.slug })}
                                                                onClick={() => { toggle(category); }}>
                                                                {`${category.label} (${ trips
                                                                                        && trips[category.slug]
                                                                                        && trips[category.slug].meta ?
                                                                                        trips[category.slug].meta.total : '' })`}
                                                            </NavLink>
                                                        </NavItem>
                                                    ))}
                                                </Nav>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className="profile-data-section--bottom">
                                        <Row>
                                            <Col xs="12">
                                                <TabContent activeTab={active_tab}>
                                                    <TabPane tabId="all" className="my-3">
                                                    {state.loading ?
                                                        <ListLoader/>
                                                        :
                                                        <>
                                                        { trips.all && trips.all.data &&
                                                            <TripListing
                                                                class_name="col-md-6 col-xs-12"
                                                                trips={trips.all}
                                                                onUpdate ={ ( trips) => onUpdate('all', trips) }
                                                                onLoadMore={(page) => getAllTrips({page}, 'all', 'append')}
                                                                load_more_loading={state.load_more_loading}
                                                                disable_bookmark={true}
                                                                enable_edit={ true }
                                                            />
                                                        }
                                                        </>
                                                    }
                                                    </TabPane>
                                                    {lookups.trip_categories && lookups.trip_categories.map((category, index) => (
                                                        <TabPane tabId={category.slug} className="my-3" key={index}>
                                                        {state.loading ?
                                                            <ListLoader/>
                                                            :
                                                            <>
                                                            { trips[category.slug] && trips[category.slug].data &&
                                                                <TripListing
                                                                    class_name="col-md-6 col-xs-12"
                                                                    trips={trips[category.slug]}
                                                                    onUpdate ={ ( trips) => onUpdate(category.slug, trips) }
                                                                    onLoadMore={(page) => getAllTrips({
                                                                        category: category.trip_category_id,
                                                                        page,
                                                                    }, category.slug, 'append')}
                                                                    load_more_loading={state.load_more_loading}
                                                                    disable_bookmark={true}
                                                                    enable_edit={ true }
                                                                />
                                                            }
                                                            </>
                                                        }
                                                        </TabPane>
                                                    ))}
                                                </TabContent>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Col>
                            <NavigationSidebar />
                        </Row>
                    </div>
                </div>
            </div>
            {!state.loading && active_category &&
            <RelatedExperience category_id={active_category}/>
            }
        </div>


    );
}

