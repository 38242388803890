import React, { useState, useEffect, useContext } from "react";
import { Row, Col, Button, Table, Form, FormGroup, CustomInput } from 'reactstrap';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { isEmpty, forEach } from 'lodash';
import moment from "moment";

import { advertisementServices } from 'js/services';
import { PaginationComponent, ListLoader } from 'js/components/common';
import { LookupContext } from 'js/contexts';
import { useParamChange, useModal } from 'js/hooks';



export const Advertising = () => {
    const [state, setState] = useState({
        is_modal_open: false,
        is_delete_modal_open: false,
        advertisement_id: null,
        selected_status: null,
        selected_position: null,
        advertisment_toggle_value: {},
        advertisement_type: null
    });
    const [advertisements, setAdvertisements] = useState([]);
    const [advertisment_toggle_value, setToggle] = useState({});
    const lookups = useContext(LookupContext);
    const [show, hide] = useModal();
    const [loading, setLoading] = useState(false);
    const [position_lookups, setPositionLookups] = useState([
        {
            label: 'Position',
            isOptionDisabled: true
        }
    ]);

    const status_lookups = [
        { value: 1, label: 'Active' },
        { value: 0, label: 'Inactive' }
    ];
    const styles = {
        option: (styles, state) => ({
            ...styles,
            backgroundColor: state.isSelected ? "#FFA405 " : state.isFocused ? "#FFA40520" : ""
        })
    };

    useEffect(() => {
        getAllAdvertisement(params);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        let advertisement_type_id = state.advertisement_type ?
            state.advertisement_type.advertisement_type_id : '';
        let position_lookups = [];

        forEach(lookups.advertisement_positions, (item) => {
            if (item.advertisement_type_id === advertisement_type_id) {
                position_lookups.push(item);
            }
        });

        setPositionLookups(position_lookups);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.advertisement_type]);

    const getAllAdvertisement = async (params) => {
        setLoading(true);
        await advertisementServices.getAll(params)
            .then((response) => {
                let advertisment_toggle_value = {};
                setAdvertisements(response);
                if (!isEmpty(response.data)) {
                    forEach(response.data, (item) => {
                        advertisment_toggle_value[item.advertisement_id] = item.is_active === 1 ? true : false;
                    });
                }
                setToggle(advertisment_toggle_value);
                setLoading(false);
            }).catch(() => {
                setLoading(false);
            })
    }

    const [params, onParamsChange] = useParamChange(getAllAdvertisement);

    const deleteAdvertisement = (advertisement_id) => {
        advertisementServices.remove(advertisement_id).then(() => {
            let current_params = { ...params };
            if (advertisements.data.length === 1) {
                if (current_params.page !== 1) {
                    current_params.page = current_params.page - 1;
                }
            }
            getAllAdvertisement(current_params);
        //    onParamsChange(current_params);
            hide('ConfirmModal');
        });
    }

    const toggle = (value, advertisement_id) => {
        let details = value ? { is_active: 1 } : { is_active: 0 };

        advertisementServices.update(details, advertisement_id).then(() => {
            setToggle({ ...advertisment_toggle_value, [advertisement_id]: value })
        });
    }

    return (
        <div>
            <div className="generic-page-header">
                <div className="header-data-section">
                    <h2 className="page-head my-0">Advertising</h2>
                </div>
                <div className="header-data-section">
                    <Button
                        color="primary"
                        className="add-new-btn"
                        onClick={() =>
                            show('AddEditAdvertisementModal', {
                                callBack: () => {
                                    getAllAdvertisement(params);
                                    hide('AddEditAdvertisementModal');
                                }
                            })
                        }
                    >
                        <i>
                            <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.03027 7.77295H0.171875V5.6001H5.03027V0.619629H7.22754V5.6001H12.0981V7.77295H7.22754V12.729H5.03027V7.77295Z" fill="#fff" />
                            </svg>
                        </i>Add an Advertisement
                    </Button>
                </div>
            </div>
            <div className="tt-admin-card">
                <div className="tt-admin-card--header tt-admin-card--header_filter">
                    <div className="tt-filter">
                        <Form>
                            <Row className="justify-content-end">
                                <Col xs="12" lg="5">
                                    <Row>
                                        <Col xs="12" md={!isEmpty(state.advertisement_type) ? "4" : "6"}>
                                            <FormGroup>
                                                <div className="custom-react-select--no-border w-100">
                                                    <Select
                                                        placeholder="Advertisement Type"
                                                        className="react-select-container"
                                                        classNamePrefix="react-select"
                                                        options={lookups.advertisement_types}
                                                        styles={styles}
                                                        onChange={
                                                            (e) => {
                                                                onParamsChange('type', e.value, { position: null});
                                                                setState({ ...state, advertisement_type: e, selected_position: null })
                                                            }
                                                        }
                                                        value={state.advertisement_type ? state.advertisement_type : null}
                                                    />
                                                </div>
                                            </FormGroup>
                                        </Col>
                                        {
                                            !isEmpty(state.advertisement_type) &&
                                            <Col xs="12" md="4">
                                                <FormGroup>
                                                    <div className="custom-react-select--no-border w-100">
                                                        <Select
                                                            placeholder="Postion"
                                                            className="react-select-container"
                                                            classNamePrefix="react-select"
                                                            options={position_lookups}
                                                            styles={styles}
                                                            onChange={(e) => {
                                                                onParamsChange('position', e.value);
                                                                setState({ ...state, selected_position: e })
                                                            }
                                                            }
                                                            value={state.selected_position ? state.selected_position : null}
                                                        />
                                                    </div>
                                                </FormGroup>

                                            </Col>
                                        }
                                        <Col xs="12" md={!isEmpty(state.advertisement_type) ? "4" : "6"}>
                                            <FormGroup>
                                                <div className="custom-react-select--no-border w-100">
                                                    <Select
                                                        placeholder="Status"
                                                        className="react-select-container"
                                                        classNamePrefix="react-select"
                                                        options={status_lookups}
                                                        styles={styles}
                                                        onChange={(e) => {
                                                            onParamsChange('status', e.value);
                                                            setState({ ...state, selected_status: e })
                                                        }
                                                        }
                                                        value={state.selected_status ? state.selected_status : null}
                                                    />
                                                </div>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </div>
                <div className="tt-admin-card--data">
                    <div className="table-wrap">
                            <Table responsive className="tt-admin-table-compact tt-admin-table-compact--checkbox ">
                                <thead>
                                    <tr>

                                        <th>Ad Name</th>
                                        <th>Ad Image</th>
                                        <th className="sortable" onClick={() => onParamsChange('sort', 'advertisement_position_id')}>Position</th>
                                        <th className="sortable" onClick={() => onParamsChange('sort', 'conversions')}>Conversion Rate</th>
                                        <th className="sortable" onClick={() => onParamsChange('sort', 'view_count')}>Views</th>
                                        <th className="sortable" onClick={() => onParamsChange('sort', 'click_count')}>Clicks</th>
                                        <th className="sortable" onClick={() => onParamsChange('sort', 'expiry_date')}>Expires</th>
                                        <th className="sortable" onClick={() => onParamsChange('sort', 'status')}>Status</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {loading ?
                                    <tr>
                                        <td colSpan="9" align="center"><ListLoader /></td>
                                    </tr>
                                    :
                                    advertisements && isEmpty(advertisements.data) ?
                                        <tr>
                                            <td colSpan="9" align="center">No Records Found.</td>
                                        </tr>
                                        :
                                        advertisements && !isEmpty(advertisements.data) && advertisements.data.map((item, index) => (
                                        <tr key={'advertisment' + index}>
                                            <td>
                                                <div className="advertising-table-ad-wrap">
                                                    <h5
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={() =>
                                                            show('AddEditAdvertisementModal', {
                                                                advertisement_id: item.advertisement_id,
                                                                callBack: () => {
                                                                    getAllAdvertisement(params);
                                                                    hide('AddEditAdvertisementModal');
                                                                }
                                                            })
                                                        }
                                                    >{item.title ? item.title : ''}</h5>
                                                    <p>Ad Type: {item.advertisement_type ? item.advertisement_type.label : '-'}</p>
                                                </div>
                                            </td>
                                            <td>
                                                <img
                                                    src={!isEmpty(item.attachment) ?
                                                        item.attachment.thumbnail_url ?
                                                        item.attachment.thumbnail_url :
                                                        item.attachment.attachment_url ?
                                                        item.attachment.attachment_url: '' : ''
                                                    }
                                                    alt=""
                                                    height="55px"
                                                    width="75px"
                                                />
                                            </td>
                                            <td>{item.advertisement_position ? item.advertisement_position.label : '-'}</td>
                                            <td>{item.conversions ? item.conversions : '-'}</td>
                                            <td>{item.view_count ? item.view_count : '-'}</td>
                                            <td>{item.click_count ? item.click_count : '-'}</td>
                                            <td>{item.expiry_date ? moment(item.expiry_date).format('MM/DD/YYYY') : '-'}</td>
                                            <td>
                                                <div className="ads-switch">
                                                    <CustomInput
                                                        type="switch"
                                                        id={`status${index}switch`}
                                                        name="customSwitch"
                                                        checked={advertisment_toggle_value[item.advertisement_id] ? advertisment_toggle_value[item.advertisement_id] : false}
                                                        onChange={(e) => toggle(e.target.checked, item.advertisement_id)}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="action-wrap">
                                                    <Link
                                                        to={"#"}
                                                        onClick={() =>
                                                            show('AddEditAdvertisementModal', {
                                                                advertisement_id: item.advertisement_id,
                                                                callBack: () => {
                                                                    getAllAdvertisement(params);
                                                                    hide('AddEditAdvertisementModal');
                                                                }
                                                            })
                                                        }
                                                    >
                                                        <img src="/images/admin/global/edit.svg" alt="" />
                                                    </Link>
                                                    <Link
                                                        to={"#"}
                                                        onClick={() =>
                                                            show('ConfirmModal', {
                                                                title: 'Delete Advertisement',
                                                                body: 'Do you want to delete this Advertisement ?',
                                                                button_name: 'Delete',
                                                                action: () => deleteAdvertisement(item.advertisement_id),
                                                            })
                                                        }
                                                    >
                                                        <img src="/images/admin/global/delete.svg" alt="" />
                                                    </Link>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                    </div>
                </div>
                {advertisements && advertisements.meta &&
                    <PaginationComponent page={advertisements.meta} onChange={(page) => onParamsChange('page', page)} />
                }
            </div>
        </div >
    );
}

