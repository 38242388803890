import React from "react";
import { Row, Col } from 'reactstrap';

import DashboardTabs from "js/components/admin/components/dashboard/dashboard-tabs";

export class Dashboard extends React.Component {

    render() {
        return (
            <div>
                <div className="generic-page-header">
                    <div className="header-data-section">
                        <h2 className="page-head my-0">Dashboard</h2>
                    </div>
                    
                </div>
                <Row>
                    <Col xs="12">
                        <div className="">
                            <DashboardTabs />
                        </div>
                    </Col>
                </Row>
            </div>


        );
    }

}
