import React from 'react';
import {
    Modal,
    ModalHeader,
    ModalBody,
    Button,
} from 'reactstrap';
import { history } from 'js/helpers';

export const UpgradeBagdesModal = (props) => {
    const { is_open, hide,  membership_reward, enable_upgrade_btn } = props;

    const upgradePlan = () => {
        history.push('/credibility-and-account-upgrades');
        hide()
    }

    return (
        <div>
            <Modal
                isOpen={is_open}
                toggle={() => hide()}
                className="tt-modal--admin modal-dialog-centered"
            >
                <ModalHeader toggle={() => hide()}>{membership_reward.label}</ModalHeader>
                <ModalBody>
                    <p>{membership_reward.description}</p>
                    <div className="d-flex justify-content-center py-4">
                        { enable_upgrade_btn &&
                        <Button
                            className="btn-outline"
                            onClick={upgradePlan}
                        >
                        Upgrade Now
                        </Button> }
                    </div>
                </ModalBody>
            </Modal>
        </div>
    );
}
