import { request } from "js/helpers/api";

export const adminNotesServices = {
    getAll,
    create,
    update,
    remove,
};

function getAll(params = {}){
    return request({
        method:'GET',
        url: 'admin-notes',
        param: params,
    });
}

function create(details){
    return request({
        method:'POST',
        url: 'admin-notes',
        param: details,
        content_type: 'json',
    });
}

function update(details, lead_id){
    return request({
        method:'PUT',
        url: `admin-notes/${lead_id}`,
        param: details,
        content_type: 'json',
    });
}

function remove(lead_id){
    return request({
        method:'DELETE',
        url: `admin-notes/${lead_id}`,
        param: {},
    });
}
