import React, { useEffect, useState, useContext } from "react";
import { Row, Col, Button, Table, Form, FormGroup, Input } from 'reactstrap';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import ReactTooltip from 'react-tooltip';
import { isEmpty } from 'lodash';
import moment from 'moment';

import { tradeServices } from 'js/services';
import { PaginationComponent, ListLoader } from 'js/components/common';
import { useParamChange, useModal } from 'js/hooks';
import { LookupContext } from 'js/contexts';

const styles = {
    option: (styles, state) => ({
        ...styles,
        backgroundColor: state.isSelected ? "#FFA405 " : state.isFocused ? "#FFA40520" : ""
    })
};

export const TripList = () => {
    const [trades, setTrades] = useState({});
    const [loading, setLoading] = useState(false);
    const lookups = useContext(LookupContext);
    const [show] = useModal();

    const filter_by_days = [
        { label: 'All' },
        { label: 'This week', value: 7 },
        { label: 'This Month', value: 30 },

    ]

    const image_name = {
        'in-progress': 'warning.svg',
        completed: 'success.svg',
        canceled: 'error.svg',
        tbd: 'pending.svg',
        interested: 'interested.svg',
        'not-interested': 'not-interested.svg'

    }

    const apiCalls = {
        getAll: (params) => {
            setLoading(true);
            tradeServices.getAll(params).then((response) => {
                setTrades(response);
                setLoading(false);
            }).catch((err) => {
                setLoading(false);
            });
        },
    }
    // eslint-disable-next-line
    const [params, onParamsChange] = useParamChange(apiCalls.getAll);

    useEffect(() => {
        onParamsChange('interval', 7);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const exportToExcel = () => {
        tradeServices.exportToExcel(params).then((response) => {
            window.open(response);
        });
    }


    return (
        <div className="tt-admin-card">
            <div className="tt-admin-card--header tt-admin-card--header_filter">
                <div className="tt-filter">
                    <Form>
                        <Row className="justify-content-between">
                            <Col xs="12" lg="5">
                                <Row>
                                    <Col xs="12" lg="4">
                                        <FormGroup>
                                            <div className="filter-input--outer">
                                                <Input
                                                    type="text"
                                                    placeholder="Search by Name or Email"
                                                    onChange={(e) =>
                                                        onParamsChange('search', e.target.value)
                                                    }
                                                />
                                            </div>

                                        </FormGroup>
                                    </Col>
                                    <Col xs="12" lg="4">
                                        <FormGroup>
                                            <div className="custom-react-select--no-border w-100">
                                                <Select
                                                    className="react-select-container"
                                                    classNamePrefix="react-select"
                                                    options={lookups.trade_status}
                                                    placeholder='Select Status'
                                                    onChange={(e) =>
                                                        onParamsChange('status', e.value)
                                                    }
                                                />
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    <Col xs="12" lg="4">
                                        <FormGroup>
                                            <div className="custom-react-select--no-border w-100">
                                                <Select
                                                    className="react-select-container"
                                                    classNamePrefix="react-select"
                                                    defaultValue={filter_by_days[1]}
                                                    options={filter_by_days}
                                                    onChange={(e) =>
                                                        onParamsChange('interval', e.value)
                                                    }
                                                    styles={styles}
                                                />
                                            </div>
                                        </FormGroup>
                                    </Col>
                                </Row>


                            </Col>
                            {trades && trades.meta && trades.meta.total && trades.meta.total > 0 ?
                            <Col xs="12" lg="3">
                                <Row>

                                    <Col xs="12" className="text-right">
                                        <Button
                                            color="secondary"
                                            className="export-to-excel-btn"
                                            onClick={() => exportToExcel()}
                                        >
                                            <i>
                                                <svg width="19" height="17" viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M14.728 12.8206H11.9003L9.97152 10.0926L7.94478 12.8206H5.10477L8.62819 8.38936L5.78315 4.51387H8.6517L9.98439 6.62121L11.4044 4.51387H14.3233L11.3406 8.38936L14.728 12.8206ZM18.4585 16.6071H1.66699V0.570107H18.4585V16.6071ZM3.2801 14.994H16.8426V2.18433H3.2801" fill="#10C56E" />
                                                </svg>
                                            </i>Export to Excel
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>:''
                            }
                        </Row>
                    </Form>
                </div>
            </div>
            <div className="tt-admin-card--data">
                <div className="table-wrap">
                    {loading ?
                        <ListLoader />
                        :
                        trades && isEmpty(trades.data) ?
                        <div className="no-records-found">
                            <p>No Records Found.</p>
                        </div>
                        :
                        <Table responsive className="tt-admin-table-compact tt-admin-table-big-data ">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th style={{ width: '25%' }}>Trip 1</th>
                                    <th style={{ width: '25%' }}>Trip 2</th>
                                    <th>Description</th>
                                    <th className="sortable" onClick={() => onParamsChange('sort', 'status')}>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    trades && !isEmpty(trades.data) && trades.data.map((trade) => (
                                        <tr key={`trade_${trade.trade_id}`}>
                                            <td>
                                                <Link to={"#"}
                                                    onClick={() => {
                                                        show('ExchangeDetailsModal', {
                                                            trade_id: trade.trade_id ? trade.trade_id : '',
                                                            callBack: () => apiCalls.getAll(params)
                                                        })
                                                    }}
                                                    className="td-link">
                                                    {trade.trade_id ? trade.trade_id : ''}
                                                </Link>
                                            </td>
                                            <td>
                                                <div className="td-trip-name">
                                                    {trade.trip_trades[0] && trade.trip_trades[0].trip && trade.trip_trades[0].trip.title ?
                                                        trade.trip_trades[0].trip.title : ''}
                                                </div>
                                                <div className="td-trip-data">
                                                    <div className="td-trip-user"><img src="/images/admin/global/user.svg" alt="" />
                                                        {trade.trip_trades[0] && trade.trip_trades[0].trip && trade.trip_trades[0].trip.user &&
                                                            trade.trip_trades[0].trip.user.full_name ? trade.trip_trades[0].trip.user.full_name : ''}
                                                    </div>
                                                    <div className="td-trip-date"><img src="/images/admin/global/date.svg" alt="" />     {
                                                        trade.trip_trades[0] && trade.trip_trades[0].arrival_time ?
                                                            ` ${moment(trade.trip_trades[0].arrival_time, 'YYYY-MM-DD HH:mm:ss').format('ll')}` : ''
                                                    }</div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="td-trip-name">
                                                    {trade.trip_trades[1] && trade.trip_trades[1].trip && trade.trip_trades[1].trip.title}
                                                </div>
                                                <div className="td-trip-data">
                                                    <div className="td-trip-user"><img src="/images/admin/global/user.svg" alt="" />
                                                        {trade.trip_trades[1] && trade.trip_trades[1].trip && trade.trip_trades[1].trip.user &&
                                                            trade.trip_trades[1].trip.user.full_name ? trade.trip_trades[1].trip.user.full_name : ''}
                                                    </div>
                                                    <div className="td-trip-date"><img src="/images/admin/global/date.svg" alt="" />    {
                                                        trade.trip_trades[1] && trade.trip_trades[1].arrival_time ?
                                                            ` ${moment(trade.trip_trades[1].arrival_time, 'YYYY-MM-DD HH:mm:ss').format('ll')}` : ''
                                                    }</div>
                                                </div>
                                            </td>
                                            <td data-tip={`trip-desc${trade.trade_id}`} data-for={`trip-desc${trade.trade_id}`}>
                                                {
                                                    trade.description ? trade.description : ''
                                                }
                                                {
                                                    !isEmpty(trade.description) &&
                                                    <ReactTooltip className='tt-admin-tooltip text-left fixed-width--tooltip' aria-haspopup='true' id={`trip-desc${trade.trade_id}`}>
                                                        <p>  {
                                                            trade.description ? trade.description : ''
                                                        }</p>
                                                    </ReactTooltip>
                                                }
                                            </td>
                                            <td>
                                                {
                                                    trade.trade_status &&
                                                    <img src={`/images/admin/global/status/${image_name[trade.trade_status.slug]}`} alt="" />
                                                }

                                                {
                                                    trade.trade_status ? trade.trade_status.label : ""
                                                }
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </Table>
                    }
                </div>

            </div>
            <div className="tt-admin-card--footer tt-admin-card--footer--pagination">
                <div className="tt-admin-pagination">
                    {trades && trades.meta &&
                        <PaginationComponent page={trades.meta} onChange={(page) => onParamsChange('page', page)} />
                    }
                </div>
            </div>
        </div>
    );
}




// {/* <td><img src="/images/admin/global/status/error.svg" alt="" /> Cancelled</td>
// <td><img src="/images/admin/global/status/success.svg" alt="" /> Success</td>
// <td><img src="/images/admin/global/status/warning.svg" alt="" /> In Progress</td>
// <td><img src="/images/admin/global/status/interested.svg" alt="" /> Interested</td>
// <td><img src="/images/admin/global/status/not-interested.svg" alt="" /> Not Interested</td> */}
