import { request } from "js/helpers/api";

export const membershipRewardServices = {
    getAll,
    getUser,
    badgeStatus
};

function getAll(params = {}) {
    return request({
        method: 'GET',
        url: 'membership-rewards',
        param: params,
    });
}

function getUser(params = {}) {
    return request({
        method: 'GET',
        url: 'user-membership-rewards',
        param: params,
    });
}

function badgeStatus(user_id) {
    return request({
        method: 'GET',
        url: `users/badges/${user_id}`,
        param: {},
    });
}

