import { request } from "js/helpers/api";

export const notificationServices = {
    getAll,
    getUnreadCount
};


function getAll(params = {}, hideModal) {
    return request({
        method: 'GET',
        url: 'notifications',
        param: params,
        hideModal
    });
}

function getUnreadCount(params = {}) {
    return request({
        method: 'GET',
        url: 'notifications',
        param: params,
    });
}


