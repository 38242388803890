import React, { useState, useEffect, useContext } from "react";
import { Link, NavLink as NavLinkRoute } from "react-router-dom";
import {
    Collapse,
    Navbar,
    NavbarToggler,
    Nav,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Button,
} from 'reactstrap';
import classNames from 'classnames';
import { AuthProvider } from 'js/contexts';
import { history } from 'js/helpers';
import { NotificationHeader } from 'js/components/common';



export const Header = () => {

    const { auth } = useContext(AuthProvider);

    const user_role = auth && auth.user && auth.user.user_role ? auth.user.user_role : '';

    const [state, setState] = useState({
        is_search_open: false,
        is_nav_bar_open: false,
        is_scrolled: false
    });

    const handleScroll = () => {
        if (window.scrollY === 0) {
            setState({ is_scrolled: false });
        }
        else {
            setState({ is_scrolled: true });
        }
    }
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const inputChange = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            history.push({
                pathname: '/search',
                search: e.target.value
            })
        }
    }

    return (

        <header className={classNames("main-header fixed-top", { "header-on--scroll": state.is_scrolled })} >
            <div className="container">
                <Navbar expand="lg">
                    <Link to={user_role === 'super-admin' || user_role === 'admin' ? '/admin' : '/home'}><img src="/images/logo-white.svg" alt="" width="183px" /></Link>
                    <NavbarToggler onClick={() => setState({ ...state, is_nav_bar_open: !state.is_nav_bar_open })} />
                    <Collapse isOpen={state.is_nav_bar_open} navbar>
                        <Nav className="ml-auto" navbar>
                            <UncontrolledDropdown nav inNavbar>
                                <DropdownToggle nav caret>Trips</DropdownToggle>
                                <DropdownMenu right>
                                    <DropdownItem><Link to={"/trips"}><img src="/images/web/global/menu/all-trips.svg" alt="" /> All Trips</Link></DropdownItem>
                                    <DropdownItem><Link to={'/trips/outdoors'} ><img src="/images/web/global/menu/outdoors.svg" alt="" /> Outdoors</Link></DropdownItem>
                                    <DropdownItem><Link to={'/trips/adventure'} ><img src="/images/web/global/menu/adventures.svg" alt="" /> Adventures</Link></DropdownItem>
                                    <DropdownItem><Link to={'/trips/vacation'} ><img src="/images/web/global/menu/vacation.svg" alt="" /> Vacation</Link></DropdownItem>
                                    <DropdownItem><Link to={'/trips/sports'} ><img src="/images/web/global/menu/sports.svg" alt="" /> Sports</Link></DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                            <NavItem>
                                <NavLinkRoute to={"/how-it-works"} activeClassName="active" className="nav-link">How It Works</NavLinkRoute>
                            </NavItem>
                            <NavItem>
                                <NavLinkRoute to={"/experience"} activeClassName="active" className="nav-link">Blog</NavLinkRoute>
                            </NavItem>
                            <NavItem>
                                <NavLinkRoute to={"/contact-us"} activeClassName="active" className="nav-link">Contact</NavLinkRoute>
                            </NavItem>
                            <NavItem>
                                <NavLink href="#">
                                    <div className="navbar-search-wrap" >
                                        <input
                                            className={classNames("tt-search-input", { "input-active": state.is_search_open })}
                                            type="text"
                                            id="input-search-nav-js"
                                            name="search"
                                            aria-label="Search"
                                            onKeyDown={(e) => inputChange(e)}
                                            placeholder="TYPE YOUR SEARCH..."
                                        />
                                        <Button
                                            onClick={() => setState({ ...state, is_search_open: !state.is_search_open })}
                                            className="btn btn-search btn-search-nav-js"
                                            type="button">
                                        </Button>
                                    </div>
                                </NavLink>
                            </NavItem>
                            {auth.is_logged_in && auth.user ?
                                <div className="add-listing-wrap ">
                                    <NavItem className="">
                                        <Link to={"/my-profile"}>
                                            <div className="profile-image my-on-device">
                                                <img src={auth.user.attachment ?
                                                    auth.user.attachment.thumbnail_url ?
                                                    auth.user.attachment.thumbnail_url : auth.user.attachment.attachment_url :
                                                    "images/web/global/logo-icon.svg"
                                                } alt="" />
                                            </div>
                                        </Link>
                                    </NavItem>
                                    <NavItem className="">
                                        <Link
                                            to={ "/add-listing" }
                                            className="center-on-device zero-top">
                                            <Button outline className="hexagon-button--add-listing color--white">
                                                <img src="/images/web/global/add.svg" width="15px" alt="" className="mr-1"/>Add Listing
                                            </Button>
                                        </Link>
                                    </NavItem>
                                    <NavItem className="">
                                        <NotificationHeader
                                            type="web"
                                        />
                                    </NavItem>
                                </div>
                                :
                                <NavItem>
                                    <Link to={"/auth/login"} className="center-on-device"><Button className="hexagon-button--login"><img src="/images/web/global/user.svg" width="15px" alt="" /> Login / Sign Up</Button></Link>
                                </NavItem>
                            }
                        </Nav>
                    </Collapse>
                </Navbar>
            </div>
        </header>
    );
}
