import React from 'react';
import { isEmpty, forEach } from 'lodash';
import { GoogleMap,withGoogleMap, Marker } from 'react-google-maps';
import { MarkerClusterer } from 'react-google-maps/lib/components/addons/MarkerClusterer';
import { history } from 'js/helpers';


const google = window.google;
const GoogleMapComponent =  withGoogleMap(( props ) => {

    // let mapRef = useRef();
    const data = !isEmpty(props.data) ? props.data.slice(0, 2000) : [];

    const defaultMapOptions = {
        zoomControl: true,
        zoomControlOptions: {
            position: google.maps.ControlPosition.RIGHT_TOP
        },
        mapTypeControl: true,
        mapTypeControlOptions: {
            style: google.maps.MapTypeControlStyle.DEFAULT
        },
        scaleControl: true,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false
    };
    const fitBounds = (map) => {
        if( map && data && props.fitBounds ){
            // mapRef = map;
            let bounds = new google.maps.LatLngBounds();
            forEach(data, (item) => {
                if( item.latitude && item.longitude ) {
                    bounds.extend(new google.maps.LatLng(parseFloat(item.latitude), parseFloat(item.longitude)))
                }
            });
            if( data.length === 1 ){
                let p1 = google.maps.geometry.spherical.computeOffset(new google.maps.LatLng(data[0].latitude, data[0].longitude), 5000, 0 );
                bounds.extend(p1)
                let p2 = google.maps.geometry.spherical.computeOffset(new google.maps.LatLng(data[0].latitude, data[0].longitude), 5000, 180 );
                bounds.extend(p2)
            }
            map.fitBounds(bounds);
        }
    }

    return (
        <div style={{ height: "100%", width: "100%" }} id="map">
            <GoogleMap
                defaultZoom={3}
                defaultOptions={defaultMapOptions}
                defaultCenter={{ lat: 50.771312, lng: -105.013804 }}
                ref={ ( map ) =>  fitBounds(map) }
                >
                <MarkerClusterer
                    averageCenter
                    enableRetinaIcons
                    gridSize={60}
                    styles={[
                        {
                          url: '/images/web/global/green_oval.png',
                          height: 48,
                          width: 48,
                          textSize: 18,
                          textColor: 'white',
                        }]}
                    >
                    { data && data.map((item, index) => (
                        <React.Fragment key={index}>
                        { item.latitude && item.longitude &&
                            <Marker
                                key={index}
                                position={ { lat: parseFloat( item.latitude ), lng: parseFloat( item.longitude ) } }
                                title={item.title}
                                icon='/images/web/global/green_pin.png'
                                onClick={() => {
                                    item.slug && history.push(`/trips/details/${item.slug}`);
                                }}
                            />
                        }
                        </React.Fragment>
                    ))}
                </MarkerClusterer>
            </GoogleMap>
        </div>
    );
});

export const GoogleMapWrapper =  ( props ) => {
    return (
        <GoogleMapComponent
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: `100%` }} />}
            mapElement={<div style={{ height: `100%` }} />}
            {...props}
        />
    );
};
