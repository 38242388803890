import React, { useState } from "react";
import { Row, Col } from 'reactstrap';
import { Helmet } from "react-helmet";
import Select from 'react-select';
import { concat, isEmpty } from 'lodash';
import { tripsServices } from "js/services";
import { TripListing, ListLoader, Ads } from "js/components/common";
import { SearchHeader } from "../search-header";
import { Event } from "js/components/Tracking";
import { useEffect } from "react";

const styles = {
    option: (styles, state) => ( {
        ...styles,
        backgroundColor: state.isSelected ? "#FFA405 " : state.isFocused ? "#FFA40520" : "#ffffff"
    })
};
const selectFilter = [
    { value: '-created_at', label: 'Latest', type: 'filter' },
    { value: 'created_at', label: 'Older', type: 'filter' }
];

export const Search = (props) => {
    const [ trips, setTrips ] = useState({});
    const [ params, setParams ] = useState({});
    const [ loading, setLoading ] = useState(false);
    const [ load_more_loading, setLoadMoreLoading ] = useState( false );
    const [ search_head, setSearchHead ] = useState('')

    const ad_params = {
        limit:1,
        type:'text-link'
    }

    const getAllTrips = ((params, mode) => {
        Event("Search", params.search, "Trips")
        mode === 'append' ? setLoadMoreLoading(true) : setLoading(true);
        tripsServices.getAll(params).then(response => {
            mode === 'append' ? setLoadMoreLoading(false) : setLoading(false);
            if(mode === 'append') {
                response.data = concat( trips.data, response.data );
            }
            response.data = getRandomAd(response.data, response.ads)
            setTrips(response)

        }).catch(error => {
            console.log(error)
        })
    })
    function getRandomAd(array, element){
        // first select a random position
        var random_pos = Math.round(Math.random() * array.length);
        let new_array = array.map(item => {
            return{...item, type: 'trip'}
        })
        if(!isEmpty(element) && !isEmpty(element[0])) {
            new_array.splice(random_pos, 0,  {...element[0], type: 'ad'})
        }
        return new_array

    };

    const handleSearch = (params) => {
        setParams( params );
        setSearchHead('')
        getAllTrips({...params, visibility: 'public'})
    }

    const capitalize = (string) => {
        if (typeof string !== 'string') return ''
        return string.charAt(0).toUpperCase() + string.slice(1)
    }
    useEffect( () => {
        setSearchHead(capitalize(props.match?.params?.category))
    }, [props])

    return (
        <div>
            <Helmet>
                <title>Trip4Trade :: Search Results</title>
                <meta name="description" content="Trade For The Trip Of A Lifetime. Trips4Trade allows users to trade trips pertaining to outdoors, adventure, vacation, and sports." />
            </Helmet>

            <div className="inner-banner-advertisement inner-banner-advertisement--filter" style={{backgroundImage: `url(/images/web/home/hero-banner.png)`}}>
                <div className="container">
                <Row>
                    <Col xs="12">
                        <h1 className="header2 color--white text-uppercase">Trips</h1>
                    </Col>
                </Row>
                </div>
            </div>
            <SearchHeader onSearch={ ( params ) => handleSearch( params ) } location={ props.location } match={ props.match } />

            <div className="wider-data-frame wider-data-frame--grey">
                <div className="container">
                    <Row>
                        <Col xs="12" md="8" className="search-head">
                            <div><h1>{search_head}</h1></div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12" md="8" className="search-head">
                            {params.search &&
                                <h3 className="header4 text-uppercase">Search Results for
                                    <span>: </span>
                                    <em>{params.search}</em>
                                </h3>
                            }
                            {/* TODO_HERE */}
                            {/* <h5 className="featured">Preferred Partner : <Link to={"#"}>Mossy Oak</Link></h5> */}
                            <Ads
                                params={ad_params}
                            />
                        </Col>

                        <Col xs="12" md="2" className="ml-auto d-flex align-items-center">

                        <div className="custom-react-select">
                            <Select
                                className="react-select-container"
                                classNamePrefix="react-select"
                                onChange={(e) => handleSearch({...params, sort: e.value})}
                                options={selectFilter}
                                styles= {styles}
                            />
                        </div>

                        </Col>
                    </Row>
                    {loading ?
                        <ListLoader/>
                        :
                        <>
                        <TripListing
                            trips={trips}
                            onUpdate ={ ( updated_trips ) => setTrips( trips=> ({...trips, data :updated_trips})) }
                            onLoadMore={(page) => getAllTrips({
                                ...params,
                                page,
                                offset: trips.meta.to
                            }, 'append')}
                            load_more_loading={load_more_loading}
                        />
                        </>
                    }
                </div>
            </div>
        </div>
    );
}
