import React, { useState, useEffect } from "react";
import { Row, Col, Label, Input, Form, FormGroup, Button, CustomInput, Spinner } from 'reactstrap';
import { isEmpty, keys, map } from "lodash";

import { ErrorFormFeedback } from 'js/components/common';
import { UseErrors } from 'js/hooks';
import { tripHash } from 'js/components/common/fucntions';


export const TripDescriptionTab = (props) => {
    const [description, setDescription] = useState({
        is_interested_in_selling: false
    });
    const [form_errors, getErrors] = UseErrors()

    const detail_keys = {
        'description': 'Trip description',
        'accomodation_description': 'Trip Accommodation',
        'interested_trips_to_trade': 'Interested trip to trade',
        'is_interested_in_selling': 'interested in selling/Not ',
        'fair_market_value': 'fair market value',
    };
    const error_hash = [
        tripHash.description,
        tripHash.accomodation_description,
        tripHash.interested_trips_to_trade,
        tripHash.is_interested_in_selling,
        tripHash.fair_market_value,
    ];

    useEffect(() => {
        if (isEmpty(form_errors)) {
            props.onChange({ details: description, next_tab: 'photos' })
        }
        //eslint disabled for props
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form_errors])

    useEffect(() => {
        if (props.trip) {
            let details = {}
            let attachments = [];
            map(keys(detail_keys), key => {
                details[key] = props.trip[key]
            })
            if(props.trip.trip_media) {
                attachments = props.trip.trip_media.map(item => item.attachment && item.attachment.attachment_id)
                details.attachments = attachments
            }

            setDescription(details)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.trip])

    const handleChange = (key, value) => {
        let data = { ...description, [key]: value };
        setDescription(data)
    }

    const handleSubmit = () => {
        getErrors(error_hash, description)
    }
    let errors = form_errors;
    if(!isEmpty(props.errors)) {
        errors = props.errors
    }
    return (
        <Form className="tt-admin-form">
            <div className="tt-admin-form-inner">
                <Row form>
                    <Col xs="12">
                        <FormGroup>
                            <Label for="">Trip Description *</Label>
                            <Input
                                type="textarea"
                                value={description.description}
                                onChange={(e) => handleChange('description', e.target.value)}
                                invalid={errors.description && true}
                            />
                            <ErrorFormFeedback field="description" errors={errors} />
                        </FormGroup>
                    </Col>
                </Row>
                <Row form>
                    <Col xs="12">
                        <FormGroup>
                            <Label for="">Trip Accommodations *</Label>
                            <Input
                                type="textarea"
                                value={description.accomodation_description || ''}
                                onChange={(e) => handleChange('accomodation_description', e.target.value)}
                                invalid={errors.accomodation_description && true}
                            />
                            <ErrorFormFeedback field="accomodation_description" errors={errors} />
                        </FormGroup>
                    </Col>
                </Row>
                <Row form>
                    <Col xs="12">
                        <FormGroup>
                            <Label for="">What trips are you interested in trading for? *  </Label>
                            <Input
                                type="text"
                                value={description.interested_trips_to_trade || ''}
                                onChange={(e) => handleChange('interested_trips_to_trade', e.target.value)}
                                invalid={errors.interested_trips_to_trade && true}
                            />
                            <ErrorFormFeedback field="interested_trips_to_trade" errors={errors} />
                        </FormGroup>
                    </Col>
                </Row>
                <Row form>
                    <Col xs="12" lg="4">
                        <FormGroup>
                            <Label for="">Fair Market Value *</Label>
                            <Input
                                type="text"
                                value={description.fair_market_value || ''}
                                onChange={(e) => handleChange('fair_market_value', e.target.value)}
                                invalid={errors.fair_market_value && true}
                            />
                            <ErrorFormFeedback field="fair_market_value" errors={errors} />
                        </FormGroup>
                    </Col>
                </Row>
                <Row form>
                    <Col xs="12" lg="6" className="pt-4">
                        <FormGroup>
                            <Label for="">In addition to trading this trip, are you interested in selling it?</Label>
                            <div className="listing-form-radio">
                                <Row>
                                    <Col xs="6" md="4">
                                        <CustomInput
                                            type="radio"
                                            id="customRadio1"
                                            name="customRadio"
                                            label="Yes"
                                            onChange={() => handleChange('is_interested_in_selling', true)}
                                            checked={description.is_interested_in_selling || false}
                                        />
                                        <span className="ml-2">
                                            <img src="/images/admin/global/info.svg" alt="" />
                                        </span>
                                    </Col>
                                    <Col xs="6" md="4">
                                        <CustomInput
                                            type="radio"
                                            id="customRadio2"
                                            name="customRadio"
                                            label="No"
                                            onChange={() => handleChange('is_interested_in_selling', false)}
                                            checked={!description.is_interested_in_selling}
                                        />
                                    </Col>
                                </Row>
                                <div className="invalid-feedback d-block">{errors['is_interested_in_selling']}</div>
                            </div>
                        </FormGroup>
                    </Col>
                </Row>
            </div>
            <div className="tt-admin-form-footer">
                {props.trip ?
                    <Button
                        color="primary"
                        className="add-new-btn"
                        onClick={() => props.onUpdate(description)}
                        disabled={props.loading}
                    >{props.loading ? <Spinner size="sm" /> : 'Save Description'}
                    </Button>
                    :
                    <Button
                        color="primary"
                        className="add-new-btn"
                        onClick={handleSubmit}
                    >Next
                    </Button>
                }
            </div>
        </Form>
    );
}
