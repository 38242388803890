import React, { useState, useContext } from "react";
import { Row, Col, Label, Input, Form, FormGroup, Spinner, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import { debounce, find, isEmpty } from 'lodash';
import { toast } from 'react-toastify';
import moment from 'moment';
import classnames from 'classnames';

import { history } from 'js/helpers';
import { LookupContext } from 'js/contexts';
import { contestServices } from 'js/services';
import { FileUploadComponent, ImageSortable, ErrorFormFeedback } from 'js/components/common';

const styles = {
    option: (styles, state) => ({
        ...styles,
        backgroundColor: state.isSelected ? "#FFA405 " : state.isFocused ? "#FFA40520" : ""
    })
};

export const AddContest = ( props ) => {

    const lookup = useContext(LookupContext);
    const [ state, setState ] = useState({
        title: '',
        promotional_campaign_applicable_user_type_id:'',
        promotional_campaign_type_id : '',
        scheduled_at : '',
        value:'',
        short_description:'',
        description: '',
        attachments : []
    });
    const [ errors, setErrors ] = useState({});
    const [loading, setLoading] = useState(false);
    const [fileUploadLoading, setFileUploadLoading] = useState(false);

    const addFile = ( response ) => {
        setState( state => ( { ...state, attachments : [ ...state.attachments, response ]}));
    }
    const removeCampaignMedia = ( items, final_files ) => {
        setState( state => ( { ...state, attachments : final_files } ));
    }
    const handleInput = ( key, value ) => {
        setState( state => ({
            ...state,
            [key] : value
        }));
    }


    const addContest = debounce(() => {
        let data = {
            ...state,
            attachments: state.attachments ? state.attachments.map( (item) => (item.attachment_id)) : [],
            scheduled_at : state.scheduled_at ? moment(state.scheduled_at).format("YYYY-MM-DD") : '',
        };
        setLoading(true);
        contestServices.create(data)
        .then((response) => {
            setLoading(false);
            toast.success('Contest Added Successfully');
            history.push(`/admin/contest-giveaways/${response.promotional_campaign_id}`);
        }).catch((error) => {
            setLoading(false);
            error.data && setErrors(error.data.errors);
        });
    },300);

    return (
        <div>
            <div className="generic-page-header">
                <div className="header-data-section">
                    <Link to={"/admin/contest-giveaways"} className="back-btn"><img src="/images/admin/global/back.svg" alt="" /></Link>
                    <h2 className="page-head my-0"> { props.match && props.match.params && props.match.params.contest ? state.title : 'Add a Contest' }</h2>
                </div>
            </div>
            <div className="tt-admin-card">
                <div className="tt-admin-card--data">
                    <Row className="no-gutters">
                        <Col xs="12" md="12" lg="12" xl="12">
                            <div className="trip-details-data-section">
                                <Form className="tt-admin-form">
                                    <div className="tt-admin-form-inner">
                                        <Row form>
                                            <Col xs="12">
                                                <FormGroup>
                                                    <Label for="">Contest Title *</Label>
                                                    <Input
                                                        type="text"
                                                        value={ state.title }
                                                        onChange={ (e) => handleInput('title',e.target.value) }
                                                        invalid={ !isEmpty(errors) && (errors.title) && true }
                                                    />
                                                    <ErrorFormFeedback field={'title'} errors={errors} />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col xs="12" md="4">
                                                <FormGroup>
                                                    <Label for="">Contest Type *</Label>
                                                    <div className="tt-custom-react-select">
                                                        <Select
                                                            className={classnames("react-select-container", { 'is-invalid' : errors.promotional_campaign_type_id })}
                                                            classNamePrefix="react-select"
                                                            options={lookup.promotional_campaign_types}
                                                            styles={styles}
                                                            value={find(lookup.promotional_campaign_types, ['value',  state.promotional_campaign_type_id] )}
                                                            onChange={ (e) => handleInput('promotional_campaign_type_id',e.value) }
                                                        />
                                                        <ErrorFormFeedback field={'promotional_campaign_type_id'} errors={errors} />
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                            <Col xs="12" md="4">
                                                <FormGroup>
                                                    <Label for="">Member Type *</Label>
                                                    <div className="tt-custom-react-select">
                                                        <Select
                                                            className={classnames("react-select-container", { 'is-invalid' : errors.promotional_campaign_applicable_user_type_id })}
                                                            classNamePrefix="react-select"
                                                            options={lookup.promotional_campaign_applicable_user_types}
                                                            styles={styles}
                                                            value={find(lookup.promotional_campaign_applicable_user_types, ['value',  state.promotional_campaign_applicable_user_type_id] )}
                                                            onChange={ (e) => handleInput('promotional_campaign_applicable_user_type_id',e.value) }
                                                        />
                                                        <ErrorFormFeedback field={'promotional_campaign_applicable_user_type_id'} errors={errors} />
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                            <Col xs="12" md="4">
                                                <FormGroup>
                                                    <Label for="">Drawing Deadline *</Label>
                                                    <DatePicker
                                                        className={classnames("custom-date-picker", { 'is-invalid' : errors.scheduled_at })}
                                                        selected={state.scheduled_at ?  moment(state.scheduled_at).toDate() : ''}
                                                        onChange={ (e) => handleInput('scheduled_at', e ) }
                                                        showYearDropdown
                                                    />
                                                    <div className="invalid-feedback d-block">{errors.scheduled_at}</div>
                                                </FormGroup>
                                            </Col>
                                            <Col xs="12" md="4">
                                                <FormGroup>
                                                    <Label for="">Value</Label>
                                                    <Input
                                                        type="text"
                                                        value={ state.value }
                                                        onChange={ (e) => handleInput('value',e.target.value) }
                                                        invalid={ !isEmpty(errors) && (errors.value) && true }
                                                    />
                                                    <ErrorFormFeedback field={'value'} errors={errors} />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col xs="12">
                                                <FormGroup>
                                                    <Label for="">Short Description *</Label>
                                                    <Input
                                                        type="textarea"
                                                        value={ state.short_description }
                                                        onChange={ (e) => handleInput('short_description',e.target.value) }
                                                        invalid={ !isEmpty(errors) && (errors.short_description) && true }
                                                    />
                                                    <ErrorFormFeedback field={'short_description'} errors={errors} />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col xs="12">
                                                <FormGroup>
                                                    <Label for="">Details *</Label>
                                                    <Input
                                                        type="textarea"
                                                        value={ state.description }
                                                        onChange={ (e) => handleInput('description',e.target.value) }
                                                        invalid={ !isEmpty(errors) && (errors.description) && true }
                                                    />
                                                    <ErrorFormFeedback field={'description'} errors={errors} />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col xs="12">
                                                <FormGroup>
                                                    <Label for="">Slide Pictures *</Label>
                                                    <div>
                                                        <FileUploadComponent
                                                            entity_type='PromotionalCampaignMedia'
                                                            multiple={true}
                                                            max={ 10 }
                                                            accepted={['image/*']}
                                                            onSuccess={addFile}
                                                            fileUploadLoading={(loading) => setFileUploadLoading(loading)}
                                                        />
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col xs="12" className="d-flex justify-content-center mt-4">
                                                <ImageSortable files={state.attachments} onRemove={removeCampaignMedia}  lock_sortable={true} />
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className="contests--footer">
                                        <Button
                                            to={"#"}
                                            className="btn btn-solid-lg"
                                            onClick={addContest}
                                            disabled={loading || fileUploadLoading}
                                        >
                                            {loading || fileUploadLoading ? <Spinner size="sm" /> : 'Save Changes'}
                                        </Button>
                                    </div>
                                </Form>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
}
