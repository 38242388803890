import { request } from "js/helpers/api";

export const supportEmailTemplateServices = {
    getAll,
    get,
    update,
    remove,
};

function getAll(params = {}){
    return request({
        method:'GET',
        url: 'support-email-templates',
        param: params,
    });
}

function get(support_email_id, hideModal){
    return request({
        method:'GET',
        url: `support-email-templates/${support_email_id}`,
        param: {},
        hideModal
    });
}


function update(details, support_email_id, hideModal){
    return request({
        method:'PUT',
        url: `support-email-templates/${support_email_id}`,
        param: details,
        content_type: 'json',
        cancel_token: 'supportEmailTemplateServices.update',
        hideModal
    });
}

function remove(support_email_id){
    return request({
        method:'DELETE',
        url: `support-email-templates/${support_email_id}`,
        param: {},
    });
}


