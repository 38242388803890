import React from "react";
import { Row, Col, Button, Table, Form, FormGroup } from 'reactstrap';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import moment from 'moment';
import { isEmpty } from 'lodash';

import { reportServices, membershipPlanService } from "js/services";
import { useState, useEffect } from "react";
import { ListLoader, GraphComponent, PaginationComponent, DateFilterComponent } from "js/components/common";

export const RevenueReport = () => {
    const [ params, setParams ] = useState({
        start_date: moment().subtract(7, 'days').format('YYYY-MM-DD'),
        end_date: moment().format('YYYY-MM-DD'),
    });
    const [revenue_reports, setReport] = useState({});
    const [list, setList] = useState({});
    const [ loading, setLoading ] = useState(false);
    const [ list_loading, setListLoading ] = useState(false);
    const [membership_plans, setMembershipPlans] = useState([])
    const [ list_param, setListParam ] = useState({});
    const apiCalls = {
        getRevenueReport: (params) => {
            setLoading(true);
            reportServices.revenueReport(params)
            .then(response => {
                setReport(response);
                setLoading(false);
            })
            .catch(err => {
                console.log('failed to fetch', err)
            })
        },
        getRevenueSource: (params) => {
            setListLoading(true);
            reportServices.revenueSource({...params, ...list_param })
            .then(response => {
                setList(response);
                setListLoading(false);
            })
            .catch(err => {
                setListLoading(false);
                console.log('failed to fetch', err)
            })
        },
        getMembershipPlans: () => {
            membershipPlanService.getAll()
            .then(res => {
                let plans = res.map(item => {
                    return {...item, value: item.membership_plan_id, label: item.label}
                })
                setMembershipPlans(plans)
            })
        }
    }

    useEffect(() => {
        apiCalls.getRevenueReport(params);
        apiCalls.getRevenueSource(params);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params]);
    useEffect(() => {
        apiCalls.getMembershipPlans();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        apiCalls.getRevenueSource(params);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [list_param]);

    const exportToExcel = () => {
        reportServices.exportToExcel(params, 'revenues').then((response) => {
            window.open(response);
        });
    }


    return (
        <div>
            <div className="generic-page-header">
                <div className="header-data-section">
                    <h2 className="page-head my-0">Reports / Revenue</h2>
                </div>
                <div className="header-data-section">
                    <Button
                        color="secondary"
                        className="export-to-excel-btn mr-3"
                        onClick={() => exportToExcel()}
                    >
                        <i>
                            <svg width="19" height="17" viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M14.728 12.8206H11.9003L9.97152 10.0926L7.94478 12.8206H5.10477L8.62819 8.38936L5.78315 4.51387H8.6517L9.98439 6.62121L11.4044 4.51387H14.3233L11.3406 8.38936L14.728 12.8206ZM18.4585 16.6071H1.66699V0.570107H18.4585V16.6071ZM3.2801 14.994H16.8426V2.18433H3.2801" fill="#10C56E" />
                            </svg>
                        </i>Export to Excel</Button>
                        {/* TODO_HERE */}
                    <DateFilterComponent params={ params } onChange={ ( data ) =>  setParams( data ) }/>
                </div>
            </div>

            <div className="tt-admin-card">
                <Row className="no-gutters">
                    <Col xs="12" md="4" className="d-flex">
                        <div className="revenue-info-wrapper">
                        {loading ?
                            <ListLoader/>
                            :
                            revenue_reports &&
                                <>
                                <div className="info-inner-row border-bottom">
                                    <label>Revenue</label>
                                    <h2>{`$${revenue_reports.current_revenue}`}<span style={{color: revenue_reports.percentage < 0 ? 'red' : '#10C56E'}}>{`(${revenue_reports.percentage}%)`}</span></h2>
                                </div>
                                <div className="info-inner-row custom-pb-mt">
                                    <label>Total Revenue</label>
                                    <h3>{`$${revenue_reports.total_revenue}`}</h3>
                                </div>
                                <div className="info-inner-row custom-pb-mt">
                                    <label>30 Days Projected Revenue</label>
                                    <h3>{`$${revenue_reports.thirty_days_projected_revenue}`}</h3>
                                </div>
                                <div className="info-inner-row custom-pb-mt">
                                    <label>60 Days Projected Revenue</label>
                                    <h3>{`$${revenue_reports.sixty_days_projected_revenue}`}</h3>
                                </div>
                                <div className="info-inner-row custom-pb-mt">
                                    <label>90 Days Projected Revenue</label>
                                    <h3>{`$${revenue_reports.ninety_days_projected_revenue}`}</h3>
                                </div>
                                </>

                        }
                        </div>
                    </Col>

                    <Col xs="12" md="8" className="d-flex">
                        <div className="revenue-graph-card">
                            <div className="sweet-loading">
                                {loading ?
                                <ListLoader/>
                                :
                                <div className="global_graph">
                                    {revenue_reports &&
                                    <GraphComponent
                                        graph_data={revenue_reports.graph_data}
                                        type={'report_revenue'}
                                        graph_type="bar"
                                        responsive="true"
                                        maintainAspectRatio="false"
                                    />
                                    }
                                </div>

                                }
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>

            <div className="tt-admin-card mt-4">
                <div className="tt-admin-card--header tt-admin-card--header_filter">
                    <div className="tt-filter">
                        <Form>
                            <Row className="justify-content-between">
                                <Col xs="12" lg="5">
                                    <Row>
                                        <Col xs="12" lg="6">
                                            <FormGroup>
                                                <div className="custom-react-select--no-border w-100">
                                                    <Select
                                                        className="react-select-container"
                                                        classNamePrefix="react-select"
                                                        options={membership_plans}
                                                        placeholder="Membership"
                                                        onChange={ ( e ) =>
                                                            setListParam( { ...list_param, membership_plan_id: e.value } )
                                                        }
                                                    />
                                                </div>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </div>
                <div className="tt-admin-card--data">
                    <div className="table-wrap">
                        {list_loading ?
                        <ListLoader/>
                        :
                        list && isEmpty(list.data) ?
                            <div className="no-records-found">
                                <p>No Records Found.</p>
                            </div>
                        :
                            <Table responsive className="tt-admin-table-compact ">
                                <thead>
                                    <tr>
                                        <th>Source</th>
                                        <th className="text-center">User</th>
                                        <th className="text-center">Date</th>
                                        <th
                                            className="sortable text-center"
                                            onClick={() => setListParam({...list_param, sort: list_param.sort === 'price' ? '-price' : 'price'})}
                                        >Amount
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {list && !isEmpty(list.data) && list.data.map((item, index) => (
                                        <tr key={index}>
                                            <td>
                                                {item.source ? item.source : 'TODO'}
                                            </td>
                                            <td className="text-center">
                                                <Link to={`/admin/user-management/${item.user_id}`} className="orange-link">
                                                    {`${item.first_name} ${item.last_name}`}
                                                </Link>
                                            </td>
                                            <td className="text-center">{moment(item.created_at).format('MMM DD, YYYY - HH:mm a') }</td>
                                            <td className="text-center">{`$${item.price}`}</td>
                                        </tr>
                                    ))}
                                </tbody>
                        </Table>
                        }
                        {list && list.meta && !list_loading &&
                            <PaginationComponent page={list.meta} onChange={(page) => setListParam({...list_param, 'page': page})} />
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}
