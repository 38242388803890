import React from 'react';
import { Route, Switch } from "react-router-dom";
import { UserIndex } from "js/routes/Web/index";

export default class AppWeb extends React.Component {
	render() {
		return (
            <div>
                <Switch>
                    {UserIndex.map((prop, key) => {
                        return <Route path={prop.path} component={prop.component} key={key} />;
                    })}
                </Switch>
            </div>
		);
	}
}
