import React from "react";
import {
    FormFeedback,
} from 'reactstrap';

export class ErrorFormFeedback extends React.Component {

    render(){
        const { field, errors, is_invalid } = this.props;
        return (
            <React.Fragment>
                { errors
                    && errors[field]
                    && errors[field].map((error, index) => (
                    is_invalid ?
                    <div
                        className="invalid-feedback d-block"
                        key={index}
                    >
                    {error}
                    </div>
                    :
                    <FormFeedback key={index}>
                        { error }
                    </FormFeedback>
                ))}
            </React.Fragment>
        );
    }

}
