import React from "react";
import { Link } from "react-router-dom";
import { utilFunctions } from 'js/components/common/fucntions';

export const StaticLink = (props) => {

    const handleUrl = ( url ) => {
        return (url && url.startsWith('/')) ? url.substr(1) : url;
    }

    return (
        <React.Fragment>
            { utilFunctions.checkExtenalUrl( props.url ).external_url ?
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={props.url}
                    className={props.class}
                    onClick={() => props.onChange && props.onChange()}
                >
                    {props.content}
                </a>

                :
                <React.Fragment>
                    { props.adClick ?
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={window.location.origin +'/'+handleUrl(props.url)}
                            className={props.class}
                            onClick={() => props.onChange && props.onChange()}
                        >
                            {props.content}
                        </a>
                    :
                        <Link
                            to={utilFunctions.checkExtenalUrl( props.url ).path ? utilFunctions.checkExtenalUrl( props.url ).path : props.url?props.url : '#'}
                            className={props.class}
                            onClick={() => props.onChange && props.onChange()}
                        >
                            {props.content}
                        </Link>
                    }
                </React.Fragment>
            }
        </React.Fragment>
    );
}
