import React, { useState, useContext, useEffect } from 'react';
import { Row, Col, Form, FormGroup, Label, Input, Button, Spinner } from 'reactstrap';
import { Link } from 'react-router-dom';
import { isEmpty, cloneDeep } from 'lodash';

import { configConstants } from 'js/constants';
import { authServices } from 'js/services/auth-service';
import { ErrorFormFeedback, NumberInputComponent } from 'js/components/common';
import { AuthProvider } from 'js/contexts';





export const CreateAccount = (props) => {

    const [state, setState] = useState({});
    const [create_errors, set_create_errors] = useState({});
    const [loading, setLoading] = useState(false);
    const { auth, setAuth } = useContext(AuthProvider);


    const onCreateAccount = () => {
        setLoading(true);
        authServices.signUpCreateAccount(state).then((create_response) => {
            setLoading(false);
            localStorage.setItem(configConstants.TOKEN_NAME, create_response.token);
            props.callBack('payment_information');

            const token = localStorage.getItem(configConstants.TOKEN_NAME);
            const partner_token = localStorage.getItem(configConstants.PARTNER_TOKEN);

            if (token && partner_token) {
                authServices.getProfile().then((response) => {
                    setAuth({ ...auth, user: cloneDeep(response), is_logged_in: true, ...create_response });
                });
            }
        }).catch((err) => {
            setLoading(false);
            let errors = err.data && err.data.errors;
            set_create_errors(errors);
        });
    }


    useEffect(() => {

        const token = localStorage.getItem(configConstants.TOKEN_NAME);
        const partner_token = localStorage.getItem(configConstants.PARTNER_TOKEN);

        if (token && partner_token) {
            authServices.getProfile().then((response) => {
                setAuth({ ...auth, user: response, is_logged_in: true });
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const handleChange = (key, value) => {
        setState({ ...state, [key]: value })
    }

    const handleChangePhone = (value) => {
        setState({ ...state, phone: value.phone, phone_country_code: value.phone_country_code });
    }

    return (
        <Row>
            <Col xs="12">
                <div className="signup-tab-data-wrap">
                    <Row>
                        <Col xs="12">
                            <h3 className="header5">Create Account</h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12">
                            <Form className="profile-form mt-3">
                                <Row>
                                    <Col xs="12" md="3">
                                        <FormGroup>
                                            <Label for="" >Full Name *</Label>
                                            <Input
                                                type="text"
                                                name="full_name"
                                                onChange={(e) => handleChange('full_name', e.target.value)}
                                                invalid={!isEmpty(create_errors) && (create_errors.full_name)
                                                    && true}
                                            />
                                            <ErrorFormFeedback field={'full_name'} errors={create_errors} />
                                        </FormGroup>
                                    </Col>
                                    <Col xs="12" md="3">
                                        <FormGroup>
                                            <Label for="" >Email *</Label>
                                            <Input
                                                type="email"
                                                name="email"
                                                onChange={(e) => handleChange('email', e.target.value)}
                                                invalid={!isEmpty(create_errors) && (create_errors.email)
                                                    && true}
                                            />
                                            <ErrorFormFeedback field={'email'} errors={create_errors} />
                                        </FormGroup>
                                    </Col>
                                    <Col xs="12" md="3">
                                        <FormGroup >
                                            <Label for="" >Phone *</Label>

                                            <NumberInputComponent
                                                phone={`+${state.phone_country_code}${state.phone}`}
                                                onChange={handleChangePhone}
                                            />

                                            {!isEmpty(create_errors) && create_errors.phone && (
                                                <div>
                                                    <div className="invalid-feedback d-block">{create_errors.phone}</div>
                                                </div>
                                            )}
                                        </FormGroup>
                                    </Col>
                                    <Col xs="12" md="3">
                                        <FormGroup>
                                            <Label for="" >Password *</Label>
                                            <Input
                                                type="password"
                                                name="password"
                                                onChange={(e) => setState({ ...state, [e.target.name]: e.target.value })}
                                                invalid={!isEmpty(create_errors) && (create_errors.password)
                                                    && true}
                                            />
                                            <ErrorFormFeedback field={'password'} errors={create_errors} />
                                        </FormGroup>
                                    </Col>

                                </Row>
                            </Form>
                            <div className="d-flex justify-content-center">
                                <div className="signup-btn--group">
                                    <Button
                                        className="btn-outline text-capitalize"
                                        onClick={() => onCreateAccount()}
                                    >{
                                            loading ? <Spinner size="sm"/> : 'Continue'
                                        }

                                    </Button>
                                    {/* <h5>OR</h5> */}
                                    {/* <Button className="btn-google text-capitalize">Sign in with Google</Button> */}
                                    <h6>Already have an account? <Link to={"/auth/login"}>Sign in</Link></h6>
                                </div>

                            </div>
                        </Col>
                    </Row>
                </div>
            </Col>
        </Row>
    )
}
