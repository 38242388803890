import React from "react";
import { Row, Col, FormGroup, Label, Input, Button } from 'reactstrap';
import { isEmpty } from 'lodash';

export const CardComponent = (props) => {

    const options = {
        style: {
            base: {
                color: "#424770",
                letterSpacing: "0.025em",
                fontFamily: "Source Code Pro, monospace",
                "::placeholder": {
                    color: "#aab7c4"
                },
                borderRadius: '4px',
                height: 40,
                backgroundColor: '#ffff',
                fontSize:"17px",
                lineHeight:"40px",
            },
            invalid: {
                color: "#9e2146"
            }
        }

    }

    const formated_style = props.type && props.type === 'admin' ? {} : options;
    const {payment_methods, callBack} = props

    return (
        <React.Fragment>
        {payment_methods
            && payment_methods.data
            && !isEmpty(payment_methods.data)
            &&
            <div>
                {payment_methods.data.map((item, index) => (
                    item.card &&
                <React.Fragment key={index}>
                <Row form>
                    <Col xs="12" md="8">
                        <FormGroup>
                            <Label for="">Card Number</Label>
                            <Input
                                type="text"
                                value={`XXXX XXXX XXXX ${item.card.last4}`}
                                readOnly
                                options={formated_style}
                            />
                        </FormGroup>
                    </Col>
                    <Col xs="12" md="4">
                        <FormGroup>
                            <Label for="">Expiration Date</Label>
                            <div className="tt-custom-react-select">
                                <Input
                                    type="text"
                                    value={`${item.card.exp_month < 10 ? `0${item.card.exp_month}` : item.card.exp_month}/${item.card.exp_year}`}
                                    readOnly
                                    options={formated_style}
                                />
                            </div>
                        </FormGroup>
                    </Col>
                </Row>

                </React.Fragment>
                ))}

                <div className="d-flex justify-content-center mt-1">
                    <Button
                        className="btn btn-outline text-capitalize"
                        onClick={callBack}
                    >
                    Add new
                    </Button>
                </div>
            </div>
        }
        </React.Fragment>
    );
}
