import React, { useState, useEffect, useContext } from "react";
import { Row, Col, TabContent, TabPane, Nav, NavItem, NavLink, CustomInput } from 'reactstrap';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import classnames from 'classnames';
import { isEmpty, find } from 'lodash';
import moment from "moment";

import {TripIdSlider} from "js/components/legos/tripIdSlider"
import TripNameLocation from "js/components/legos/tripNameLocation"
import OwnerDetails from "js/components/legos/ownerDetails"
import TripSpecs from "js/components/legos/tripSpecs"
import { TripDetailsTab, TripDescriptionTab, TripPhotosTab } from 'js/components/admin/components/trip-management/details/components'
import approved  from 'js/components/legos/select-icons/components/approved-icon'
import pending from 'js/components/legos/select-icons/components/pending-icon'
import rejected from 'js/components/legos/select-icons/components/error-icon'
import { tripsServices } from "js/services";
import { LookupContext } from 'js/contexts';
import { toast } from "react-toastify";
import { ListLoader } from "js/components/common";
import { history } from 'js/helpers';

const icons = {
    approved,
    pending,
    rejected
}
const customSingleValue = ({ data }) => (
    <div className="input-select">
        <div className="input-select__single-value-selected">
            {data.icon && <span className="input-select__icon mr-2">{data.icon}</span>}
            <span>{data.label}</span>
        </div>
    </div>
);
const CustomOption = ({ data, innerProps, isDisabled }) => !isDisabled ? (
    <div {...innerProps}>
        <div className="input-select__single-value">
            {data.icon && <span className="input-select__icon mr-2">{data.icon}</span>}
            <span>{data.label}</span>
        </div>
    </div>
) : null;


export const TripDetails = (props) => {
    const [ active_tab, toggle ] = useState('details');
    const [ trip, setTrip ] = useState({});
    const [ errors, setErrors ] = useState(false);
    const [ state, setState ] = useState({
        loading: false,
        slug: null,
        page_loading: false
    });

    const lookups = useContext(LookupContext);
    const { match, location } = props;
    const apiCalls = {

        getSelectedTrip: (slug) => {
            setState({...state, page_loading: true})
            tripsServices.get(slug).then(response => {
                setState({slug, page_loading: false})
                setTrip(response)
            }).catch(err => {
                console.log(err);
                history.push('/admin')
            })
        },

        updateTrip: (details) => {
            setState({...state, loading: true})
            tripsServices.update(details, state.slug).then(response => {
                setState({...state, loading: false});
                setTrip(response);
                toast.success('Trip has been updated successfully')
            }).catch(err => {
                setState({...state, loading: false})
                err && err.data && err.data.errors && setErrors(err.data.errors)
            })
        },
        updateStatus: (status) => {
            let details = {trip_status_id: status.value}
            let new_trip = {
                ...trip,
                trip_status: status
            }
            setTrip(new_trip)
            tripsServices.updateStatus(details, state.slug)
            .then(
            //    apiCalls.getSelectedTrip(state.trip_id)
            ).catch(err => {
                console.log('status update failed', err)
            })
        },
        updateTripMedia: (attachments) => {
            let cover_image_id = attachments.attachments[0] ? attachments.attachments[0] : null
            setState({...state, loading: true})
            tripsServices.update(attachments, state.slug).then(response => {
                setState({...state, loading: false});
                if(cover_image_id) {
                    let cover_trip_media = find(response.trip_media, function(o) {
                        return o.attachment.attachment_id === cover_image_id;
                    })
                    if(cover_trip_media) {
                        apiCalls.changeCover(cover_trip_media.trip_media_id)
                    }

                }

            }).catch(err => {
                setState({...state, loading: false})
                setErrors(err)
            })
        },
        changeCover: (trip_media_id) => {
            tripsServices.changeCover(state.slug, trip_media_id)
            .then(res => {
                setTrip(res);
                toast.success('Trip has been updated successfully')
            })
        }
    }
    useEffect(() => {



        if(match && match.params && match.params.slug){

            apiCalls.getSelectedTrip(match.params.slug);

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const status_lookups = lookups.trip_statuses && lookups.trip_statuses.map(item => {
        return {...item, icon: icons[item.slug]}
    });
    const current_page = location && location.state && location.state.current_page ? location.state.current_page : '';
    const default_status = trip.trip_status ? {
        ...trip.trip_status,
        value: trip.trip_status.trip_status_id,
        icon: icons[trip.trip_status.slug]
    }
    : {}

    return (
        <div>
            <div className="generic-page-header">
                <div className="header-data-section">
                    <Link to={{
                        pathname:"/admin/trip-management",
                        state: { current_page }
                    }} className="back-btn"><img src="/images/admin/global/back.svg" alt="" /></Link>
                    {trip.user &&
                        <h2 className="page-head my-0">
                            { trip.user.full_name }
                            <span>{` (${trip.user.email} )`}</span>
                        </h2>
                    }
                </div>

                <div className="header-data-section">
                    <div className="custom-react-select--white mr-3">
                        <Select
                            components={{ SingleValue: customSingleValue, Option: CustomOption }}
                            className="react-select-container"
                            classNamePrefix="react-select"
                            options={status_lookups}
                            value={default_status}
                            onChange={(e) => apiCalls.updateStatus(e)}
                            isDisabled={state.loading || state.page_loading}
                        />
                    </div>

                    <Link to={ `/trips/details/${ trip.slug }` } color="primary" className="add-new-btn">View Trip</Link>
                    <div className="user-status ml-3">
                    <span> {trip.is_featured ?  'Featured Trip' :'Feature this trip'}
                    <CustomInput
                        type="switch"
                        id="exampleCustomSwitch"
                        name="customSwitch"
                        label=""
                        onChange={ ( e ) => apiCalls.updateTrip( { is_featured: e.target.checked ? 1 : 0 } ) }
                        checked={trip.is_featured}
                    />
                    </span>
                    </div>
                </div>
            </div>
            <div className="tt-admin-card-lead-create">
                <div className="tt-admin-card--data">

                    <Row className="no-gutters">
                        <Col xs="12" md="12" lg="12" xl="9">
                            <div className="trip-details-data-section">
                                <div className="nav-tabs-scroll">
                                    <Nav tabs className="tt-admin-tabs">
                                        <NavItem>
                                            <NavLink className={classnames({ active: active_tab === 'details' })} onClick={() => { toggle('details'); }}>
                                                <div className="tab-label">Trip Details</div>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink className={classnames({ active: active_tab === 'description' })} onClick={() => { toggle('description'); }}>
                                                <div className="tab-label">Trip Description</div>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink className={classnames({ active: active_tab === 'photos' })} onClick={() => { toggle('photos'); }}>
                                                <div className="tab-label">Trip Photos</div>
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                </div>
                                {state.page_loading ? <ListLoader/>
                                :
                                !isEmpty(trip) &&
                                <TabContent activeTab={active_tab}>
                                    <TabPane tabId="details">
                                        <TripDetailsTab
                                            trip={trip}
                                            onUpdate={(details) => apiCalls.updateTrip(details)}
                                            loading={state.loading}
                                            errors={errors}
                                        />
                                    </TabPane>
                                    <TabPane tabId="description">
                                        <TripDescriptionTab
                                            trip={trip}
                                            onUpdate={(details) => apiCalls.updateTrip(details)}
                                            loading={state.loading}
                                            errors={errors}
                                        />
                                    </TabPane>
                                    <TabPane tabId="photos">
                                        {!isEmpty(trip) &&
                                            <TripPhotosTab
                                                trip={trip}
                                                onUpdate={(details) => apiCalls.updateTripMedia(details)}
                                                loading={state.loading}
                                                errors={errors}
                                            />
                                        }
                                    </TabPane>
                                </TabContent>
                                }
                            </div>
                        </Col>
                        {!isEmpty(trip) &&
                        <Col xs="12" md="12" lg="12" xl="3">
                            <div className="trip-details-sidebar">
                                <Row>
                                    <Col>
                                        {TripIdSlider (trip.trip_media, trip.trip_no)}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <TripNameLocation
                                            tripName={trip.title}
                                            tripLocation={ `${trip.city ? trip.city.long_name: ''}, ${trip.country ? trip.country.long_name: ''}`}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <OwnerDetails
                                            ownerImage={
                                                trip.user && trip.user.attachment ?
                                                trip.user.attachment.thumbnail_url ?
                                                trip.user.attachment.thumbnail_url :
                                                trip.user.attachment.attachment_url: ''
                                            }
                                            ownerName={trip.user ? trip.user.full_name : ''}
                                            tip_id={trip.trip_id}
                                            slug={trip.slug}
                                            onChange={ () => apiCalls.getSelectedTrip(trip.slug)}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <TripSpecs
                                            availability={ `${trip.trip_start_date ? moment(trip.trip_start_date).format('DD MMM'): ''}. -
                                                ${trip.trip_end_date ? moment(trip.trip_end_date).format('DD MMM'): ''}.`
                                            }
                                            duration={trip.trip_duration ? trip.trip_duration : `${trip.duration_min_days ? trip.duration_min_days : ''} -
                                                ${trip.duration_max_days ? trip.duration_max_days : ''} Days`
                                            }
                                            accomodates={`${trip.accomodating_min_seats ? trip.accomodating_min_seats : ''} -
                                                ${trip.accomodating_max_seats ? trip.accomodating_max_seats : ''} People`
                                            }
                                            spec_sale={trip.is_interested_in_selling ? 'Yes' : 'No'}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        }
                    </Row>

                </div>

            </div>
        </div>
    );

}
