import React, { useState, useEffect, useContext } from 'react';
import {
    Row,
    Col,
    Button,
    Form,
    FormGroup,
    Label,
    Modal,
    ModalHeader,
    ModalBody,
    Input,
    Progress,
    Spinner
} from 'reactstrap';

import 'filepond/dist/filepond.min.css';
import { isObject, isEmpty, forEach, keys, pick, cloneDeep, omit, find, isNumber } from 'lodash';
import { toast } from "react-toastify";
import Select from 'react-select';
import Geosuggest from 'react-geosuggest';

import { authServices } from 'js/services';
import { LookupContext } from 'js/contexts';
import { ErrorFormFeedback, NumberInputComponent } from 'js/components/common';
import { AuthProvider } from 'js/contexts';



export const EditProfileModal = (props) => {
    const { is_open, hide } = props;
    const [user, setUser] = useState({});
    let [state, setState] = useState({});
    const [user_errors, setErrors] = useState({});

    const lookups = useContext(LookupContext);
    const [loading, setLoading] = useState(false);
    const { auth, setAuth } = useContext(AuthProvider);


    const apiCalls = {
        get: (callback) => {
            authServices.getProfile().then((response) => {
                let details = { ...response }

                if (!isEmpty(details.country)) {
                    let selected_country = find(lookups.countries, (o) => { return o.value === details.country });
                    details.country = selected_country
                }
                setAuth({ ...auth, user: { ...response } });
                callback && callback();
            }).catch((err) => {
                let errors = err && err.data && err.data.errors;
                setErrors(errors);
            });
        },
        update: (details) => {
            setLoading(true);
            let user_id = auth && auth.user && auth.user.user_id;
            authServices.updateProfile(details, user_id, hide).then(() => {
                const updateCallback = () => {
                    setLoading(false);
                    hide();
                    toast.success('Updated Successfully');
                }
                setErrors({});
                apiCalls.get(updateCallback);
            })
                .catch((err) => {
                    let errors = err.data && err.data.errors;
                    setErrors(errors);
                    setLoading(false);
                });
        },
    }



    useEffect(() => {
        if (auth && auth.user) {
            let user_data = cloneDeep(auth.user);
            const data_filter = ['first_name', 'last_name', 'email',
                'phone_country_code', 'phone', 'social_media', 'biography',
                'state', 'city', 'zip_code', 'address', 'attachment'];
            let selected_user = pick(user_data, data_filter);

            selected_user.country = user_data.country ?
            find(lookups.countries, ['country_id', user_data.country.country_id]) : '';

            if (!isEmpty(selected_user.social_media)) {
                let social_media = {};
                forEach(selected_user.social_media, (item) => {
                    social_media[item.social_media_id] = item.social_media_link
                });

                selected_user.social_media = social_media;
            }

            setUser(selected_user);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        let completed_filed_array = [];
        let selected_field =  {...omit(user, ['city','state', 'phone_country_code'])};

        forEach(selected_field, (item, key )=> {
            if (!isEmpty(item) || isNumber(item)) {
                if (isObject(item)) {
                    let keys_array = [];
                    forEach(item, (data) => {
                        if (!isEmpty(data)) {
                            keys_array.push(data);
                        }
                    });
                    if (keys_array.length > 0) {
                        completed_filed_array.push(item);
                    }
                }
                else {
                    completed_filed_array.push(item);
                }
            }
        });
        let percentage_completed = percentCalculator(completed_filed_array.length);
        setState({
            ...state,
            percentage_completed,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);


    const percentCalculator = (length) => {
        let percentage_completed = (length / 10) * 100;

        return percentage_completed;
    }

    const handleChangePhone = (value) => {
        setUser({ ...user, phone: value.phone, phone_country_code: value.phone_country_code });
    }

    const handleAddressChange = (place) => {
        let state = {};
        let city = {};
        let country = {};

        if (!isEmpty(place)) {
            if (place.gmaps && place.gmaps.address_components) {
                forEach(place.gmaps.address_components, (value, key) => {
                    value.types && forEach(value.types, type => {
                        if (type === "administrative_area_level_1") {
                            state = { ...omit(value, 'types') }
                        }
                        if (type === "locality") {
                            city = { ...omit(value, 'types') }
                        }
                        if( type === "country" ){
                            country = { ...omit(value, 'types') }
                        }
                    })
                })
            }
        }

        if( country.short_name ){
            country = find(lookups.countries, ['short_name',  country.short_name] );

        }

        setUser((user) => ({
            ...user,
            state,
            city,
            address: place && place.description ? place.description : '',
            country: !isEmpty(country) ? country : user.country
        }));
    }

    const submit = () => {
        let details = cloneDeep(user);
        let social_media = [];

        if (details.social_media && !isEmpty(details.social_media)) {

            let social_media_keys = keys(details.social_media);

            forEach(social_media_keys, (item) => {
                if (!isEmpty(details.social_media[item])) {
                    social_media.push({
                        social_media_id: item,
                        social_media_link: details.social_media[item]
                    });
                }
            });
        }

        details.social_media = social_media;

        if (!isEmpty(details.city)) {
            let modified_city = pick(details.city, ['long_name', 'short_name']);
            details.city = modified_city;
        }
        if (!isEmpty(details.state)) {
            let modified_state = pick(details.state, ['long_name', 'short_name']);
            details.state = modified_state;
        }
        if (!isEmpty(details.country)) {
            details.country_id = details.country ? details.country.value: '';
        }

        apiCalls.update({...omit(details, 'country', 'email')});
    }

    return (
        <div>
            <Modal
                isOpen={is_open}
                toggle={() => hide()}
                className="tt-modal--admin modal-dialog-centered"
            >
                <ModalHeader toggle={() => hide()}>Edit Profile</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col xs="12">
                            <div className="progress-wrap">
                                <div className="progress-count">Profile Credibility: <b>{state.percentage_completed ? Math.round(state.percentage_completed) : '0'}%</b> Completed</div>
                                <Progress className="tt-progress" value={state.percentage_completed ? state.percentage_completed : '0'} max="100" />
                            </div>
                        </Col>
                    </Row>
                    <Form className="profile-form">
                        <Row>
                            <Col xs="12" md="6">
                                <FormGroup>
                                    <Label for="" >First Name</Label>
                                    <Input
                                        type="text"
                                        name="first_name"
                                        onChange={(e) => { setUser({ ...user, [e.target.name]: e.target.value }) }}
                                        value={user.first_name || ''}
                                        invalid={user_errors.first_name && true}
                                    />
                                    <ErrorFormFeedback field={'first_name'} errors={user_errors} />
                                </FormGroup>
                            </Col>
                            <Col xs="12" md="6">
                                <FormGroup>
                                    <Label for="" >Last Name</Label>
                                    <Input
                                        type="text"
                                        name="last_name"
                                        onChange={(e) => { setUser({ ...user, [e.target.name]: e.target.value }) }}
                                        value={user.last_name || ''}
                                        invalid={user_errors.last_name && true}
                                    />
                                    <ErrorFormFeedback field={'last_name'} errors={user_errors} />
                                </FormGroup>
                            </Col>
                            <Col xs="12" md="6">
                                <FormGroup>
                                    <Label for="" >Phone</Label>
                                    <NumberInputComponent
                                        phone={`+${user.phone_country_code}${user.phone}`}
                                        onChange={handleChangePhone}
                                    />

                                    {!isEmpty(user_errors) && user_errors.phone && (
                                        <div>
                                            <div className="invalid-feedback d-block">{user_errors.phone}</div>
                                        </div>
                                    )}
                                    {!isEmpty(user_errors) && user_errors.phone_country_code && (
                                        <div>
                                            <div className="invalid-feedback d-block">{user_errors.phone_country_code}</div>
                                        </div>
                                    )}
                                </FormGroup>
                            </Col>
                            <Col xs="12" md="6">
                                <FormGroup>
                                    <Label for="" >Email Address</Label>
                                    <Input
                                        type="text"
                                        name="email"
                                        //onChange={(e) => { setUser({ ...user, [e.target.name]: e.target.value }) }}
                                        value={user.email || ''}
                                        invalid={user_errors.email && true}
                                        disabled
                                    />
                                    <ErrorFormFeedback field={'email'} errors={user_errors} />
                                </FormGroup>
                            </Col>
                            <Col xs="12" md="6">
                                <FormGroup>
                                    <Label for="">Country</Label>
                                    <div className="custom-react-select--no-border w-100 height-50">
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            options={lookups.countries}
                                            onChange={(e) => {
                                                setUser({ ...user, 'country': e })
                                            }
                                            }
                                            value={user.country || null}
                                        />
                                        {!isEmpty(user_errors) && user_errors.country && (
                                            <div>
                                                <div className="invalid-feedback d-block">{user_errors.country}</div>
                                            </div>
                                        )}
                                    </div>
                                </FormGroup>
                            </Col>
                            <Col xs="12" md="6">
                                <FormGroup>
                                    <Label for="" >Address</Label>
                                    <div className="tt-custom-react-select">
                                        <Geosuggest type="text"
                                            name="address"
                                            className="react-select-container"
                                            inputClassName={"form-control"}
                                            placeholder=""
                                            initialValue={user.address ? user.address :  ''}
                                            value={user.address ? user.address : ''}
                                            autoComplete="nope"
                                            onSuggestSelect={handleAddressChange}
                                            country={user.country ? user.country.short_name : ''}
                                        />
                                        <div className="invalid-feedback d-block">{user_errors['address']}</div>
                                        <div className="invalid-feedback d-block">{user_errors['city']}</div>
                                        <div className="invalid-feedback d-block">{user_errors['state']}</div>
                                    </div>
                                </FormGroup>
                            </Col>
                            <Col xs="12" md="3">
                                <FormGroup>
                                    <Label for="" >Zip Code</Label>
                                    <Input
                                        type="text"
                                        name="zip_code"
                                        onChange={(e) => { setUser({ ...user, [e.target.name]: e.target.value }) }}
                                        value={user.zip_code || ''}
                                        invalid={user_errors.zip_code && true}
                                    />
                                    <ErrorFormFeedback field={'zip_code'} errors={user_errors} />
                                </FormGroup>
                            </Col>
                            <Col xs="12">
                                <FormGroup>
                                    <Label for="" >Biography</Label>
                                    <Input
                                        type="textarea"
                                        name="biography"
                                        onChange={(e) => { setUser({ ...user, [e.target.name]: e.target.value }) }}
                                        value={user.biography || ''}
                                        invalid={user_errors.biography && true}
                                    />
                                    <ErrorFormFeedback field={'biography'} errors={user_errors} />
                                </FormGroup>
                            </Col>
                            {
                                lookups.social_media.map((item, index) => (
                                    <Col xs="12" md="6" key={index}>
                                        <FormGroup>
                                            <Label for="">{item.label}</Label>
                                            <Input
                                                type="text"
                                                name="social_media"
                                                onChange={(e) => {
                                                    setUser(
                                                        {
                                                            ...user,
                                                            [e.target.name]: { ...user[e.target.name], [item.value]: e.target.value }
                                                        }
                                                    )
                                                }
                                                }
                                                value={user['social_media'] && user['social_media'][item.value]
                                                    ? user['social_media'][item.value] : ''}
                                                invalid={user_errors[`social_media.${index}.social_media_link`] && true}
                                            />
                                            <ErrorFormFeedback field={`social_media.${index}.social_media_link`} errors={user_errors} />
                                        </FormGroup>
                                    </Col>
                                ))
                            }
                        </Row>
                    </Form>
                    <div className="d-flex justify-content-center py-4">
                        <Button className="btn-outline text-capitalize" onClick={submit} disabled={loading}>
                            {loading ?
                                <Spinner size="sm" color="#ffa405" /> : 'Save Information'
                            }
                        </Button>
                    </div>
                </ModalBody>
            </Modal>
        </div>
    );
}
