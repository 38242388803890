import { request } from "js/helpers/api";

export const stripeServices = {
    customerCardMapping,
};

function customerCardMapping(user_id, params = {}) {
    return request({
        method: 'POST',
        url: `payment/customer-card-mapping/${user_id}`,
        param: params,
        cancel_token: 'stripeServices.customerCardMapping',
        content_type: 'json',
    });
}

