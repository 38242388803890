import React, { useState, useEffect, useContext } from 'react';
import { Row, Col } from 'reactstrap';

import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import classnames from 'classnames';
import { forEach, isEmpty } from 'lodash';

import { NavigationSidebar } from "js/components/web/components/layouts/components/navigation-sidebar";
import { membershipRewardServices } from 'js/services';
import { AuthProvider } from 'js/contexts';
import { useModal } from 'js/hooks';
import { ListLoader, RelatedExperience } from 'js/components/common';

export const BadgesAndPerks = () => {
    const [badges, setBadges] = useState({});
    const [loading, setLoading] = useState(false);
    const { auth } = useContext(AuthProvider);
    const [state, setState] = useState({});
    const [show] = useModal();


    const user_id = auth && auth.user && auth.user.user_id;

    const image_name = {
        'premium-member': 'premium.svg',
        'lifetime-member': 'lifetime.svg',
        'annual-trader': 'annual.svg',
        'frequent-trader': 'frequent.svg',
        'trusted-trader': 'trusted.svg',
        'ambassador': 'ambassador.svg',
        'perfect-profile': 'perfect.svg',
    }

    const apiCalls = {
        getAll: () => {
            let membership_badges = [];
            let activity_badges = [];
            let perks = [];

            setLoading(true);
            membershipRewardServices.getAll().then((response) => {
                setLoading(false);
                forEach(response, (item) => {
                    if (item.membership_reward_type && item.membership_reward_type.slug === 'membership-badge') {
                        membership_badges.push(item);
                    }
                    else if (item.membership_reward_type && item.membership_reward_type.slug === 'activity-badge') {
                        activity_badges.push(item);
                    }
                    else {
                        perks.push(item);
                    }
                });
                setBadges({
                    membership_badges,
                    activity_badges,
                    perks
                });
            })
                .catch((err) => {
                    setLoading(false);
                })
        },
        badgeStatus: () => {
            let membership_rewards_hash = {};
            membershipRewardServices.badgeStatus(user_id).then((response) => {
                forEach(response, (value, key) => {
                    membership_rewards_hash[key] = value === 0 ? false : true;
                });
                setState({
                    ...state,
                    membership_rewards_hash
                });
            });
        },
    }

    useEffect(() => {
        apiCalls.getAll();
        apiCalls.badgeStatus();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <Helmet>
                <title>Trip4Trade :: Badges & Perks</title>
                <meta name="description" content=" My Listings page desription" />
                <meta name="keywords" content="trip4trade,  My Listings" />
            </Helmet>

            <div className="inner-banner-profile" style={{ backgroundImage: `url(/images/web/global/banners/profile-banner.png)` }}>

            </div>
            <div className="wider-data-frame wider-data-frame--grey pt-0">
                <div className="container">
                    <div className="profile-outer-wrapper">
                        <Row className="no-gutters">
                            <Col xs="12" lg="9">
                                <div className="profile-data-section">
                                    <div className="profile-data-section--top">
                                        <Row>
                                            <Col xs="12" className="pb-4">
                                                <h3><img src="/images/web/global/side-navigation/badges.svg" alt="" style={{ width: `30px` }} /> Badges & Perks</h3>
                                            </Col>
                                        </Row>

                                    </div>
                                    <div className="profile-data-section--bottom pt-5 pb-5">
                                        {loading ?
                                            <ListLoader />
                                            :
                                            <Row>
                                                <Col xs="12" className="mb-1">
                                                    <div className="badges-outer">
                                                        <div className="badge-header">Membership Badges</div>
                                                        <Row>
                                                            {
                                                                badges && !isEmpty(badges.membership_badges) &&
                                                                badges.membership_badges.map((item, index) => (
                                                                    <Col className="badge-item" key={index + 'membership_badges'}>
                                                                        <div
                                                                            className={classnames('badges',
                                                                                {
                                                                                    active: state.membership_rewards_hash && state.membership_rewards_hash[item.slug] ?
                                                                                        state.membership_rewards_hash[item.slug] : false
                                                                                })
                                                                            }
                                                                        >
                                                                            <img src={`/images/web/global/badges/${image_name[item.slug]}`} alt="" />
                                                                            <h5>{item.label ? item.label : ''}</h5>
                                                                            <Link
                                                                                to={"#"}
                                                                                onClick={() => {
                                                                                    show('UpgradeBagdesModal', {
                                                                                        membership_reward: item,
                                                                                        enable_upgrade_btn: state.membership_rewards_hash && state.membership_rewards_hash[item.slug] ?
                                                                                        false : true
                                                                                    })

                                                                                }}
                                                                            >Learn More
                                                                        </Link>
                                                                        </div>
                                                                    </Col>
                                                                ))
                                                            }
                                                        </Row>
                                                    </div>
                                                    <div className="badges-outer">
                                                        <div className="badge-header">Activity Badges</div>
                                                        <Row>
                                                            {
                                                                badges && !isEmpty(badges.activity_badges) &&
                                                                badges.activity_badges.map((item, index) => (
                                                                    <Col className="badge-item" key={index + 'membership_badges'}>
                                                                        <div
                                                                            className={classnames('badges',
                                                                                {
                                                                                    active: state.membership_rewards_hash && state.membership_rewards_hash[item.slug] ?
                                                                                        state.membership_rewards_hash[item.slug] : false
                                                                                })
                                                                            }
                                                                        >
                                                                            <img src={`/images/web/global/badges/${image_name[item.slug]}`} alt="" />
                                                                            <h5>{item.label ? item.label : ''}</h5>
                                                                            <Link
                                                                                to={"#"}
                                                                                onClick={() => {
                                                                                    show('UpgradeBagdesModal', {
                                                                                        membership_reward: item,
                                                                                        enable_upgrade_btn: false
                                                                                    })
                                                                                }}
                                                                            >Learn More
                                                                        </Link>
                                                                        </div>
                                                                    </Col>
                                                                ))
                                                            }
                                                        </Row>
                                                    </div>
                                                    <div className="badges-outer">
                                                        <div className="badge-header">Other Badges</div>
                                                        <Row>
                                                            {
                                                                badges && !isEmpty(badges.perks) &&
                                                                badges.perks.map((item, index) => (
                                                                    <Col className="badge-item" key={index + 'perks'}>
                                                                        <div
                                                                            className={classnames('badges',
                                                                                {
                                                                                    active: state.membership_rewards_hash && state.membership_rewards_hash[item.slug] ?
                                                                                        state.membership_rewards_hash[item.slug] : false
                                                                                })
                                                                            }
                                                                        >
                                                                            <img src={`/images/web/global/badges/${image_name[item.slug]}`} alt="" />
                                                                            <h5>{item.label ? item.label : ''}</h5>
                                                                            <Link
                                                                                to={"#"}
                                                                                onClick={() => {
                                                                                    show('UpgradeBagdesModal', {
                                                                                        membership_reward: item,
                                                                                        enable_upgrade_btn: false
                                                                                    })
                                                                                }}
                                                                            >Learn More
                                                                        </Link>
                                                                        </div>
                                                                    </Col>
                                                                ))
                                                            }
                                                        </Row>
                                                    </div>
                                                </Col>
                                            </Row>
                                        }
                                    </div>
                                </div>
                            </Col>
                            <NavigationSidebar />
                        </Row>
                    </div>
                </div>
            </div>
            <RelatedExperience user_id={user_id}/>
        </div>
    );
}








