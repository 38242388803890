import React, { useState, useEffect, useContext } from "react";
import { Row, Col, Form, FormGroup, Label, Input, Button, Spinner} from 'reactstrap';
import { Helmet } from "react-helmet";
import { toast } from 'react-toastify';
import { isEmpty } from 'lodash';
import Select from 'react-select';
import classnames from 'classnames';

import { AuthProvider, LookupContext } from 'js/contexts';
import { supportServices, cmsServices } from 'js/services';
import { ErrorFormFeedback } from 'js/components/common';

import PartnerSlider from "js/components/legos/partnersSlider"
import SectionHeader from "js/components/legos/sectionHeader"

const styles = {
    option: (styles, state) => ({
        ...styles,
        backgroundColor: state.isSelected ? "#FFA405 " : state.isFocused ? "#FFA40520" : ""
    })
};

export const SupportConversation = () => {

    const { auth } = useContext(AuthProvider);
    const lookup = useContext(LookupContext);

    const [ state, setState ] = useState({
        name: '',
        email:'',
        phone : '',
        conservation_partner_id:'',
        way_to_support_id:''
    });

    const [ errors, setErrors ] = useState({});
    const [ logos, setLogos ] = useState({});
    const [ partners, setPartners ] = useState({});
    const [ loading, setLoading ] =  useState(false)

    const handleChange = ( value, key) => {
        const newState = { ...state };
        newState[key] = value;
        setState(newState);
    }
    const submit = () => {
        setLoading(true)
        supportServices.submitSupport(state)
        .then((response) => {
            toast.success('Support conservation has been sent');
            setLoading(false);
            setErrors({});
            setState({});
        }).catch((error) => {
            if( error.data && error.data.errors ){
                setErrors(error.data.errors);
            }
            else{
                toast.error('Failed To send support conservation');
            }
            setLoading(false)
        });
    }
    const getLogos = (params) => {
        cmsServices.getPartnerLogos(params)
        .then((response) => {
            setLogos(response);
            setPartners(response ? response.map((item) => ({ label: item.name, value: item.partner_logo_id })) : []);
        }).catch((err) => {
            toast.error('Error Getting Partner Logs');
        });
    }

    useEffect(() => {
        if( auth.is_logged_in && auth.user ){
            setState({
                name: auth.user.full_name,
                email: auth.user.email,
                phone: auth.user.phone,
            });
        }
        getLogos({ show_all: true });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <div>
            <Helmet>
                <title>Support Conservation - Trips4Trade</title>
                <meta name="description" content="Support Conservation - Trips4Trade" />
                <meta name="keywords" content="" />
            </Helmet>

            <div className="inner-banner" style={{backgroundImage: `url(images/web/global/banners/static-banner.png)`}}>
                <div className="container">
                    <Row>
                        <Col xs="12">
                            <h1 className="header2 color--white text-uppercase">Support Conservation</h1>
                        </Col>

                    </Row>
                </div>
            </div>

            <div className="wider-data-frame wider-data-frame--grey wider-data-frame--support">
                <div className="container">
                    <Row>
                        <Col xs="12" md="6" className="d-flex align-items-center">
                            <div>
                                <p className="p">A core goal at Trips4Trade is to help increase the number of outdoorsmen to ensure hunting and fishing sustainability. We do that primarily in two ways. First, by allowing members to swap trips that are not hunting or fishing related to get access to a hunting or fishing trip. Secondly, by partnering with as many Outdoor Non-Profits as possible.</p>
                                <p className="p">By being a member of Trips4Trade you are already helping us in the first area. If you are passionate about conversation, then we ask that you help us in the second area as well by supporting one of these organization. </p>
                            </div>

                        </Col>
                        <Col xs="12" md="6" className="support-col-outer">
                            <div className="support-image-section">
                                <img src="images/web/support-conversation/intro-img.png" alt="" />
                            </div>

                        </Col>
                    </Row>
                </div>
            </div>
            <div className="wider-data-frame">
                <div className="container">
                    <Row>
                        <SectionHeader
                            HeaderText="How it Works"
                        />
                    </Row>
                    <Row>
                        <Col xs="12" md="4">
                            <div className="how-it-works-card first-child">
                                <div className="how-it-works-card--box how-it-works-card--box-support">
                                    <img src="images/web/support-conversation/select-organization.svg" alt="" />
                                </div>
                                <h5>1</h5>
                                <h4 className="header4">Select the Organization that <br className="d-none d-md-block" />you would like to support</h4>
                            </div>
                        </Col>
                        <Col xs="12" md="4">
                            <div className="how-it-works-card">
                                <div className="how-it-works-card--box how-it-works-card--box-support">
                                    <img src="images/web/support-conversation/select-support.svg" alt="" />
                                </div>
                                <h5>2</h5>
                                <h4 className="header4">Select how you would like <br className="d-none d-md-block" />to support</h4>
                            </div>
                        </Col>
                        <Col xs="12" md="4">
                            <div className="how-it-works-card">
                                <div className="how-it-works-card--box how-it-works-card--box-support">
                                    <img src="images/web/support-conversation/receive-email.svg" alt="" />
                                </div>
                                <h5>3</h5>
                                <h4 className="header4">Receive an email with <br className="d-none d-md-block" />confirmation and next steps</h4>
                            </div>
                        </Col>
                    </Row>
                </div>


            </div>
            <div className="wider-data-frame wider-data-frame--grey">
                <div className="container">
                    <Row>
                        <SectionHeader
                            HeaderText="501(c)(3) Partners"
                        />
                    </Row>
                    <Row>
                        <Col>
                            <PartnerSlider logos={logos} />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12">
                            <div className="tt-card tax-deduct-form">
                                <Row>
                                    <Col xs="12" className="text-center">
                                        <h3 className="header3">Tax-Deductible Ways to Support Conservation through Trips4Trade</h3>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="12">
                                        <ul className="tax-deduct-form--features">
                                            <li>
                                                <div className="feature-list-icon"><span>1</span></div>
                                                <h4 className="header4">Donate a Trip to Conservation</h4>
                                                <p className="p">Each organization has multiple live auctions at their annual banquets to raise money for conservation. You can offer to take someone on your trip listed through Trips4Trade to help that org raise money at one of their banquets.
</p>
                                            </li>
                                            <li>
                                                <div className="feature-list-icon"><span>2</span></div>
                                                <h4 className="header4">Donate Monetarily to Conservation</h4>
                                                <p className="p">Every dollar donated will go straight to the conservation efforts of the organization of your choice. </p>
                                            </li>
                                            <li>
                                                <div className="feature-list-icon"><span>3</span></div>
                                                <h4 className="header4">Join an Outdoor Organization </h4>
                                                <p className="p">Each membership will give you certain benefits through that organization as well as put your membership dollars towards their conservation efforts. </p>
                                            </li>
                                        </ul>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <hr className="hr-dashed" />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form className="tt-web-form">
                                            <Row form>
                                                <Col xs="12" md="6" lg="4">
                                                    <FormGroup>
                                                        <Label for="">Name</Label>
                                                        <Input
                                                            type="text"
                                                            value={state.name ? state.name : ''}
                                                            onChange = { (e) => handleChange( e.target.value, 'name' ) }
                                                            invalid={ !isEmpty(errors) && (errors.name) && true }
                                                        />
                                                        <ErrorFormFeedback field={'name'} errors={errors} />
                                                    </FormGroup>
                                                </Col>
                                                <Col xs="12" md="6" lg="4">
                                                    <FormGroup>
                                                        <Label for="">Email</Label>
                                                        <Input
                                                            type="text"
                                                            value={state.email ? state.email : '' }
                                                            onChange = { (e) => handleChange( e.target.value, 'email' ) }
                                                            invalid={ !isEmpty(errors) && (errors.email) && true }
                                                        />
                                                        <ErrorFormFeedback field={'email'} errors={errors} />
                                                    </FormGroup>
                                                </Col>
                                                <Col xs="12" md="6" lg="4">
                                                    <FormGroup>
                                                        <Label for="">Phone</Label>
                                                        <Input
                                                            type="text"
                                                            value={state.phone ? state.phone : '' }
                                                            onChange = { (e) => handleChange( e.target.value, 'phone' ) }
                                                            invalid={ !isEmpty(errors) && (errors.phone) && true }
                                                        />
                                                        <ErrorFormFeedback field={'phone'} errors={errors} />
                                                    </FormGroup>
                                                </Col>
                                                <Col xs="12" md="6" lg="4">
                                                    <FormGroup>
                                                        <Label for="">Partner</Label>
                                                        <Select
                                                            className={classnames("react-select-container", { 'is-invalid': errors.conservation_partner_id })}
                                                            classNamePrefix="react-select"
                                                            value={!isEmpty(partners) && partners.filter(option => option.value === state.conservation_partner_id)}
                                                            options={partners}
                                                            styles={styles}
                                                            onChange={(e) => handleChange(e.value, 'conservation_partner_id')}
                                                        />
                                                        <ErrorFormFeedback field={'conservation_partner_id'} errors={errors} />
                                                    </FormGroup>
                                                </Col>
                                                <Col xs="12" md="6" lg="4">
                                                    <FormGroup>
                                                        <Label for="">Way to Support</Label>
                                                        <Select
                                                            className={classnames("react-select-container", { 'is-invalid' : errors.way_to_support_id })}
                                                            classNamePrefix="react-select"
                                                            value={lookup?.ways_to_support?.filter(option => option.way_to_support_id === state.way_to_support_id)}
                                                            options={lookup && lookup.ways_to_support}
                                                            styles={styles}
                                                            onChange = { (e) => handleChange( e.value, 'way_to_support_id' ) }
                                                        />
                                                        <ErrorFormFeedback field={'way_to_support_id'} errors={errors} />
                                                    </FormGroup>
                                                </Col>

                                            </Row>
                                            <Row>
                                                <Col xs="12" className="d-flex justify-content-center">
                                                    <Button
                                                        onClick={submit}
                                                        className="btn btn-outline"
                                                        disabled={loading}
                                                    >
                                                        {loading ? <Spinner size="sm"/> : 'Send'}
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
}
