import React, {useState} from "react";
import DropdownTreeSelect from 'react-dropdown-tree-select'
import 'react-dropdown-tree-select/dist/styles.css';
import { useEffect } from "react";
import { map } from 'lodash';

export const TreeContainer = (props) => {
    const { data, onChange, mode, className, selectedCategories, placeholder } = props;
    const [ category_lookups, setCategory ] = useState( [] );

    useEffect( () => {
        if ( data )
        {
            if ( selectedCategories )
            {
                let category_options = data.map( item => {
                    return findSelectedNode(item)
                } );
                setCategory( category_options );
            }
            else
            {
                setCategory( data );
            }
        }


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ data ] );

    const findSelectedNode = ( item) => {
        let selected_category = {};
            map( selectedCategories, category => {
                selected_category[ category.trip_category_id ] = category;
            } );
        if ( selected_category[ item.trip_category_id ] )
        {
            return { ...item, checked: true, value: item.trip_category_id };
        }
        else
        {
            let children = item?.children?.map( child => {
                return findSelectedNode(child)
            } )
            return { ...item, children, value: item.trip_category_id}
        }

    }

    const findNode = ( currentNode, item ) => {
        if ( item.trip_category_id === currentNode.trip_category_id )
        {
            return { ...item, value: item.trip_category_id, checked: currentNode.checked };
        }
        else if ( item.trip_category_id === currentNode.parent_id )
        {
            let children = item?.children?.map( child => {
                return findNode( currentNode, child );
            } );
            return { ...item, value: item.trip_category_id, children };
        }
        else return { ...item, value: item.trip_category_id }
    }

    const onTreeChange = ( currentNode, selectedNodes ) => {
        let lookups = mode === 'radioSelect' ? data : category_lookups
        let category_options = lookups.map( item => {
            return findNode(currentNode, item)
        } );

        setCategory(category_options)

        onChange(selectedNodes)
    }

    return (
        <DropdownTreeSelect
            data={ category_lookups }
            onChange={ onTreeChange }
            mode={ mode }
            className={ className }
            keepTreeOnSearch
            keepChildrenOnSearch
            texts={{placeholder}}
        />
    );
}
