import React, { useState, useContext } from "react";
import { Row, Col, Label, Input, Form, FormGroup, Button, Spinner } from 'reactstrap';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { isEmpty } from 'lodash';
import { toast } from "react-toastify";

import { ErrorFormFeedback, NumberInputComponent } from 'js/components/common';
import { userServices } from 'js/services';
import { history } from 'js/helpers';
import { LookupContext } from 'js/contexts';




export const CreateUser = (props) => {

    const [user_details, setDetails] = useState({});
    const [user_errors, setErrors] = useState({});
    const lookups = useContext(LookupContext);
    const [loading, setLoading] = useState(false)

    const apiCalls = {
        createUser: async (details) => {
            setLoading(true);
            await userServices.create(details).then((response) => {
                setDetails(response);
                history.push(`/admin/user-management/${response.user_id}`);
                toast.success('Created Successfully');
                setLoading(false);

            })
                .catch((err) => {
                    let errors = {};
                    if (err.data) {
                        errors = err.data && err.data.errors;
                    }
                    setErrors(errors);
                    setLoading(false);
                })
        }
    }

    const inputChange = (key, value, sub_key) => {
        let details = {};
        if (sub_key) {
            details = {
                ...user_details,
                [key]: { ...user_details[key], [sub_key]: value }
            }
        }
        else {
            details = {
                ...user_details,
                [key]: value
            }
        }

        setDetails(details)
    }


    const handleSubmit = () => {
        apiCalls.createUser(user_details);
    }

    const handleChangePhone = (value) => {
        setDetails({ ...user_details, phone: value.phone, phone_country_code: value.phone_country_code });
    }

    return (
        <>
            <div className="generic-page-header">
                <div className="header-data-section">
                    <Link to={"/admin/user-management"} className="back-btn"><img src="/images/admin/global/back.svg" alt="" /></Link>
                    <h2 className="page-head my-0">Add User</h2>
                </div>
            </div>
            <div className="tt-admin-card">
                <div className="tt-admin-card--data">
                    <Form className="tt-admin-form">
                        <div className="tt-admin-form-inner">
                            <Row form>
                                <Col xs="12" md="4">
                                    <FormGroup>
                                        <Label for="">First Name</Label>
                                        <Input
                                            type="text"
                                            name="first_name"
                                            onChange={(e) => inputChange(e.target.name, e.target.value)}
                                            value={user_details.first_name || ''}
                                            invalid={user_errors.first_name && true}
                                        />
                                        <ErrorFormFeedback field={'first_name'} errors={user_errors} />
                                    </FormGroup>
                                </Col>
                                <Col xs="12" md="4">
                                    <FormGroup>
                                        <Label for="">Last Name</Label>
                                        <Input
                                            type="text"
                                            name="last_name"
                                            onChange={(e) => inputChange(e.target.name, e.target.value)}
                                            value={user_details.last_name || ''}
                                            invalid={user_errors.last_name && true}
                                        />
                                        <ErrorFormFeedback field={'last_name'} errors={user_errors} />
                                    </FormGroup>
                                </Col>
                                <Col xs="12" md="4">
                                    <FormGroup>
                                        <Label for="">Email Address</Label>
                                        <Input
                                            type="text"
                                            name="email"
                                            onChange={(e) => inputChange(e.target.name, e.target.value)}
                                            value={user_details.email || ''}
                                            invalid={user_errors.email && true}
                                        />
                                        <ErrorFormFeedback field={'email'} errors={user_errors} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row form>
                                <Col xs="12" md="4">
                                    <FormGroup>
                                        <Label>Phone</Label>
                                        <NumberInputComponent
                                            phone={`+${user_details.phone_country_code}${user_details.phone}`}
                                            onChange={handleChangePhone}
                                        />

                                        {!isEmpty(user_errors) && user_errors.phone && (
                                            <div>
                                                <div className="invalid-feedback d-block">{user_errors.phone}</div>
                                            </div>
                                        )}
                                        {!isEmpty(user_errors) && user_errors.phone_country_code && (
                                            <div>
                                                <div className="invalid-feedback d-block">{user_errors.phone_country_code}</div>
                                            </div>
                                        )}
                                    </FormGroup>
                                </Col>
                                <Col xs="12" md="4">
                                    <FormGroup>
                                        <Label>User Status</Label>
                                        <div className="tt-custom-react-select">
                                            <Select
                                                className="react-select-container"
                                                classNamePrefix="react-select"
                                                options={lookups.user_status}
                                                onChange={(e) => inputChange('user_status_id', e.value)}
                                            />
                                            {!isEmpty(user_errors) && user_errors.user_status_id && (
                                                <div>
                                                    <div className="invalid-feedback d-block">{user_errors.user_status_id}</div>
                                                </div>
                                            )}
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col xs="12" md="4">
                                    <FormGroup>
                                        <Label>User Role</Label>
                                        <div className="tt-custom-react-select">
                                            <Select
                                                className="react-select-container"
                                                classNamePrefix="react-select"
                                                options={lookups.user_roles}
                                                onChange={(e) => inputChange('role_name', e.value)}
                                            />
                                            {!isEmpty(user_errors) && user_errors.role_name && (
                                                <div>
                                                    <div className="invalid-feedback d-block">{user_errors.role_name}</div>
                                                </div>
                                            )}
                                        </div>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row form>
                                <Col xs="12">
                                    <FormGroup>
                                        <Label for="">Biography</Label>
                                        <Input
                                            type="textarea"
                                            name="biography"
                                            onChange={(e) => inputChange(e.target.name, e.target.value)}
                                            value={user_details.biography || ''}
                                            invalid={user_errors.biography && true}
                                        />
                                        <ErrorFormFeedback field={'biography'} errors={user_errors} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row form>
                                <Col xs="12" md="6">
                                    <FormGroup>
                                        <Label for="">Password</Label>
                                        <Input
                                            type="password"
                                            name="password"
                                            onChange={(e) => inputChange(e.target.name, e.target.value)}
                                            value={user_details.password || ''}
                                            invalid={user_errors.password && true}
                                        />
                                        <ErrorFormFeedback field={'password'} errors={user_errors} />
                                    </FormGroup>
                                </Col>
                                <Col xs="12" md="6">
                                    <FormGroup>
                                        <Label for="">Repeat Password</Label>
                                        <Input
                                            type="password"
                                            name="password_confirmation"
                                            onChange={(e) => inputChange(e.target.name, e.target.value)}
                                            value={user_details.password_confirmation || ''}
                                            invalid={user_errors.password_confirmation && true}
                                        />
                                        <ErrorFormFeedback field={'password_confirmation'} errors={user_errors} />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </div>
                        <div className="tt-admin-form-footer">
                            <Button color="primary" className="add-new-btn" onClick={handleSubmit}>
                                {loading ?
                                    <Spinner size="sm"/> : 'Create User'
                                }
                            </Button>
                        </div>
                    </Form>
                </div>
            </div>

        </>
    );

}
