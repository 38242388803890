
import { advertisementServices } from 'js/services';

export function useAdsClick() {

    const onAdClick = (advertisement_id) => {
        advertisementServices.updateClickCount(advertisement_id)
        .then(res => {
       //     toast.success('You have subscribed successfully ');
        }).catch(err => {
            console.log(err)
        })
    }
    return {onAdClick}
}
