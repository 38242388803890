import React, { useContext} from "react";
import { Row, Col, Button } from 'reactstrap';
import { Helmet } from "react-helmet";

import { NavigationSidebar } from "js/components/web/components/layouts/components/navigation-sidebar";
import ReferenceCard from "js/components/legos/referenceCard";
import EndorsementCard from "js/components/legos/endorsementCard";
import { RelatedExperience } from 'js/components/common';
import { AuthProvider } from 'js/contexts';
import { useModal } from 'js/hooks';
import { authServices } from 'js/services';

export const EndorsementReferences = (props) => {
    const { auth, setAuth } = useContext(AuthProvider);
    const [show, hide] = useModal();
    const onCallBack  = (modal) => {
        authServices.getProfile().then((response) => {
            setAuth({ ...auth, user: { ...response } });
            hide(modal)
        });
    }

    const user = auth && auth.user;
    return (
        <div>
            <Helmet>
                <title>Trip4Trade :: My Listings</title>
                <meta name="description" content=" My Listings page desription" />
                <meta name="keywords" content="trip4trade,  My Listings" />
            </Helmet>

            <div className="inner-banner-profile" style={{ backgroundImage: `url(/images/web/global/banners/profile-banner.png)` }}>

            </div>
            <div className="wider-data-frame wider-data-frame--grey pt-0">
                <div className="container">
                    <div className="profile-outer-wrapper">
                        <Row className="no-gutters">
                            <Col xs="12" lg="9">
                                <div className="profile-data-section">
                                    <div className="profile-data-section--top">
                                        <Row>
                                            <Col xs="12" className="pb-4">
                                                <h3><img src="/images/web/global/side-navigation/endorsements.svg" alt="" style={{ width: `30px` }} /> Endorsements & References</h3>
                                            </Col>
                                        </Row>

                                    </div>
                                    <div className="profile-data-section--bottom pt-5">
                                        <Row>
                                            <Col xs="12" className="pb-4">
                                                <p>
                                                    Endorsements and References provide additional verification for each host. Members and Nonmembers can provide endorsements for trip accuracy. Members can also request contact info for references from the host if they aren't listed below.
                                                </p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs="12">
                                            <h5 class="header5 mt-1 mb-4">Endorsements</h5>
                                            </Col>
                                        </Row>
                                        <Row>
                                            {user.endorsements && user.endorsements && user.endorsements.map((item, index) => (
                                                <Col xs="12">
                                                    <EndorsementCard
                                                        data={item}
                                                    />
                                                </Col>
                                            ))}
                                            <Col xs="12" className="d-flex justify-content-end mt-2 mb-4">
                                                <Button
                                                    className="btn btn-outline"
                                                    onClick={ () =>
                                                        show('EndorsmentModal', {
                                                            callBack: (modal) => onCallBack(modal)
                                                        })
                                                    }
                                                >
                                                    Request Endorsement
                                                </Button>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs="12">
                                            <h5 class="header5 mt-1 mb-4">References</h5>
                                            </Col>
                                        </Row>
                                        <Row>
                                        { user.references && user.references  && user.references.map( (item, index) => (
                                            <Col xs="12" md="6" key={index}>
                                                <ReferenceCard
                                                    refImage="images/web/global/logo-icon.svg"
                                                    refUser={item.full_name}
                                                    refEmail={item.email}
                                                />
                                            </Col>
                                        ))}
                                        </Row>
                                        <Row>
                                            <Col xs="12" className="d-flex justify-content-end mt-2 mb-5">
                                                <Button
                                                    onClick={() =>
                                                        show('ReferenceModal',{
                                                            callBack: (modal) => onCallBack(modal)
                                                        })
                                                    }
                                                    className="btn btn-outline"
                                                >Add Reference
                                                </Button>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Col>
                            <NavigationSidebar />
                        </Row>
                    </div>
                </div>
            </div>
            <RelatedExperience user_id={user.user_id}/>
        </div>
    );
}

