import React, { useContext } from 'react';
import { Row, Col, Form, FormGroup, Label, Input, Button, Spinner } from 'reactstrap';

import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { NavigationSidebar } from "js/components/web/components/layouts/components/navigation-sidebar";
import { LookupContext, AuthProvider } from 'js/contexts';
import { useState } from 'react';
import { forEach } from 'lodash'
import { toast } from 'react-toastify';
import {
    FacebookShareButton,
    FacebookIcon,
    TwitterShareButton,
    TwitterIcon,

    FacebookMessengerShareButton,
    FacebookMessengerIcon
} from "react-share";
import { ErrorFormFeedback, NumberInputComponent, RelatedExperience } from 'js/components/common';
import { miscellService } from 'js/services';
import { settingServices } from 'js/services/setting-service';
import { useEffect } from 'react';

export const InviteFriends = (props) => {
    const [ state, setState ] = useState({
        email: '',
        sms: '',
        loading: false,
        errors: {}
    });
    const [loading, setLoading] = useState({
        email_loader: false,
        sms_loader: false
    });
    const [referral_advantage, setReferrel] = useState();

    const lookups = useContext(LookupContext);
    const { auth } = useContext(AuthProvider)
    const createReferrel = (details, key) => {
        setLoading({
            ...loading,
            [`${key}_loader`]: true
        });
        let errors = {}
        miscellService.createReferrel(details)
        .then(response => {
            setState({...state, loading: false, errors});
            setLoading({
                ...loading,
                [`${key}_loader`]: false
            });
            toast.success('Invitation sent successfully');
        }).catch(err => {
            errors[key] =  err && err.data && err.data.errors
            setState({...state, errors});
            setLoading({
                ...loading,
                [`${key}_loader`]: false
            });
        })
    }

    const getReferralAdv = () => {
        settingServices.get('referral-advantage')
        .then(response => {
            response
            && response.content
            && response.content['referral_advantage']
            && setReferrel(response.content['referral_advantage'])
        })
    }

    const onInvite = (e, key) => {
        e.preventDefault();
        let referral_type_id = ''
        forEach(lookups.referral_types, value => {
            if(value.slug === key)
            referral_type_id = value.referral_type_id
        })
        let details = {
            value: state[key],
            referral_type_id
        }
        createReferrel(details, key)
    }
    useEffect(() => {
        getReferralAdv();
    }, [])

    let invite_url = process.env.REACT_APP_URL+ `auth/signup/${auth.user.referral_promotion}`;
    return (
        <div>
            <Helmet>
                <title>Trip4Trade :: Invite Friends</title>
                <meta name="description" content="Invite friends page desription" />
                <meta name="keywords" content="trip4trade,  Invite Friends" />
            </Helmet>

            <div className="inner-banner-profile" style={{ backgroundImage: `url(/images/web/global/banners/profile-banner.png)` }}>

            </div>
            <div className="wider-data-frame wider-data-frame--grey pt-0">
                <div className="container">
                    <div className="profile-outer-wrapper">
                        <Row className="no-gutters">
                            <Col xs="12" lg="9">
                                <div className="profile-data-section">
                                    <div className="profile-data-section--top">
                                        <Row>
                                            <Col xs="12" className="pb-4">
                                                <h3><img src="/images/web/global/side-navigation/invite-friends.svg" alt="" style={{ width: `30px` }} /> Invite Friends</h3>
                                            </Col>
                                        </Row>

                                    </div>
                                    {referral_advantage &&
                                    <div className="profile-data-section--bottom pt-5 pb-5">
                                        <Row>
                                            <Col xs="12" className="mb-1">
                                                <h5 className="header5">Social Media</h5>

                                                <p>{`For every friend you refer that registers in our website, you’ll get ${referral_advantage}.`}</p>

                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="social-link">
                                                <Link to={"#"}>
                                                    {/* <img src="/images/web/global/facebook.svg" alt="" /> */}
                                                    <FacebookShareButton
                                                        url={invite_url} >
                                                        <FacebookIcon size={60} borderRadius={6} />
                                                    </FacebookShareButton>
                                                    <p>Facebook</p>
                                                </Link>
                                            </Col>
                                            <Col className="social-link">
                                                <Link to={"#"}>
                                                    {/* <img src="/images/web/global/twitter.svg" alt="" /> */}
                                                    <TwitterShareButton
                                                        url={invite_url} >
                                                        <TwitterIcon size={60} borderRadius={6} />
                                                    </TwitterShareButton>
                                                    <p>Twitter</p>
                                                </Link>
                                            </Col>
                                            <Col className="social-link">
                                                <Link to={"#"}>
                                                    {/* <img src="/images/web/global/messanger.svg" alt="" /> */}
                                                    <FacebookMessengerShareButton
                                                        url={invite_url} >
                                                        <FacebookMessengerIcon size={60} borderRadius={6} />
                                                    </FacebookMessengerShareButton>
                                                    <p>Messenger</p>
                                                </Link>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs="12">
                                                <div className="or">
                                                    <h6 className="or-header">OR</h6>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs="12" className="mb-1">
                                                <h5 className="header5">Email Invitation</h5>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form className="invite-form">
                                                    <Row>
                                                        <Col xs="12">
                                                            <Label for="" >Email Address</Label>
                                                        </Col>
                                                        <Col xs="12" md="9">
                                                            <FormGroup>
                                                                <Input
                                                                    type="email"
                                                                    placeholder="Ex: john@smith.com"
                                                                    onChange={(e) => setState({...state, email: e.target.value})}
                                                                />
                                                                {state.errors && state.errors.email &&
                                                                    <ErrorFormFeedback field={'value'} errors={state.errors.email} is_invalid={true}/>
                                                                }
                                                            </FormGroup>
                                                        </Col>
                                                        <Col xs="12" md="3">
                                                            <Button
                                                                className="btn-outline"
                                                                onClick={(e) => onInvite(e, 'email')}
                                                                >{loading['email_loader'] ? <Spinner size="sm" /> : 'Invite Friend'}
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                </Form>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs="12">
                                                <div className="or">
                                                    <h6 className="or-header">OR</h6>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs="12" className="mb-1">
                                                <h5 className="header5">SMS Invitation</h5>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form className="invite-form">
                                                    <Row>
                                                        <Col xs="12">
                                                            <Label for="" >Phone Number</Label>
                                                        </Col>
                                                        <Col xs="12" md="9">
                                                            <FormGroup>
                                                                <NumberInputComponent
                                                                    phone={state.sms}
                                                                    onChange={(e) => setState({...state,sms: `+${e.phone_country_code}${e.phone}`})}
                                                                />
                                                                {state.errors && state.errors.sms &&
                                                                    <ErrorFormFeedback field={'value'} errors={state.errors.sms} is_invalid={true}/>
                                                                }
                                                            </FormGroup>
                                                        </Col>
                                                        <Col xs="12" md="3">
                                                            <Button
                                                                className="btn-outline"
                                                                onClick={(e) => onInvite(e, 'sms')}
                                                            >{loading['sms_loader'] ? <Spinner size="sm" /> : 'Invite Friend'}
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                </Form>
                                            </Col>
                                        </Row>
                                    </div>
                                    }
                                </div>
                            </Col>
                            <NavigationSidebar />
                        </Row>
                    </div>
                </div>
            </div>
            <RelatedExperience user_id={auth.user.user_id}/>
        </div>


    );
}

