import React, { useState } from "react";
import { Row, Col, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { membershipPlanService } from 'js/services';
import { useEffect } from "react";
import { useModal, useParamChange } from 'js/hooks';
import { ListLoader, PaginationComponent } from "js/components/common";
import { isEmpty  } from 'lodash';




export const MembershipPlans = () => {

    const [membership_plans, setMembershipPlan] = useState([]);
    const [state, setState] = useState({
        title: '',
        loading: false
    });
    const [show] = useModal();

    const apiCalls = {
        getAll: () => {
            setState({ ...state, loading: true })
            membershipPlanService.getAll(params).then((response) => {
                setState({ ...state, loading: false })
                setMembershipPlan(response);
            }).catch((err) => {
                setState({ ...state, loading: false })
            });
        }
    }

    const [params, onParamsChange] = useParamChange(apiCalls.getAll);

    useEffect(() => {
        apiCalls.getAll(params);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <div className="generic-page-header">
                <div className="header-data-section justify-content-start">
                    <h2 className="page-head my-0">Membership Plans</h2>
                </div>
            </div>
            <div>
                <div className="tt-admin-card membership-card">
                    <div className="tt-admin-card--data tt-admin-card--data_membership">
                        <Row>
                            <Col xs="12">
                                <div className="membership-card-outer">
                                    <Row className="justify-content-between align-items-center">
                                        <Col xs="12" lg="5">
                                        </Col>
                                        <Col xs="12" lg="5" className="text-right">
                                            <Button
                                                color="primary"
                                                className="add-new-btn"
                                                onClick={() =>
                                                    show('AddEditMembershipPlanModal',
                                                        {
                                                            ...state,
                                                            title: 'Add Membership Plan',
                                                            callBack: () => apiCalls.getAll(),
                                                        })
                                                }
                                            >
                                                <i>
                                                    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M5.03027 7.77295H0.171875V5.6001H5.03027V0.619629H7.22754V5.6001H12.0981V7.77295H7.22754V12.729H5.03027V7.77295Z" fill="#fff" />
                                                    </svg>
                                                </i>
                                                Add Plan
                                            </Button>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                            {state.loading ?
                                <ListLoader />
                                :
                                isEmpty(membership_plans) ?
                                <div className="no-records-found">
                                    <p>No Records Found.</p>
                                </div>
                                :
                                !isEmpty(membership_plans) && membership_plans.map((item, index) => (
                                    <div className='membership-plan-data-wrap' key={`membership_pan_${index}`}>
                                        <Col xs="12" className="mt-5" >
                                            <div className="annual-membership">
                                                <h5 className="header5 text-capitalize"><img src="/images/web/auth/tick.svg" alt="" />
                                                    {
                                                        item.label ? item.label : ''
                                                    }( <Link to={`/admin/membership-plan-edit/${item.membership_plan_id}`} >Edit</Link> )
                                                </h5>
                                                <ul className="trade-resources">
                                                    {
                                                        item.content.map((item, ind) => (
                                                            <li key={`content_${ind}`}>
                                                                {item}
                                                            </li>
                                                        ))
                                                    }
                                                </ul>
                                            </div>
                                        </Col>
                                    </div>
                                ))
                            }
                        </Row>
                    </div>
                </div>
            </div>
            {membership_plans && membership_plans.meta &&
                <PaginationComponent page={membership_plans.meta} onChange={(page) => onParamsChange('page', page)} />
            }
        </div>
    );
}


