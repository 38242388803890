import React, { useEffect, useState, useContext } from "react";
import { Row, Col, Button, Label, Input, Form, FormGroup, Spinner } from 'reactstrap';
import { Link } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import { debounce, omit, isEmpty, find } from 'lodash';
import { toast } from "react-toastify";
import classnames from 'classnames';
import Select from 'react-select';


import AdminNotes from "js/components/legos/adminNotes";
import { leadsServices, userServices, supportEmailServices } from 'js/services';
import { ErrorFormFeedback, NumberInputComponent, ListLoader } from 'js/components/common';
import { history } from 'js/helpers';
import { useModal } from 'js/hooks';
import { LookupContext } from "js/contexts";
import moment from "moment";



const styles = {
    option: (styles, state) => ({
        ...styles,
        backgroundColor: state.isSelected ? "#FFA405 " : state.isFocused ? "#FFA40520" : ""
    })
};
const customSingleValue = ({ data }) => (
    <div className="input-select">
        <div className="input-select__single-value-selected">
            {data.icon && <span className="input-select__icon mr-2">{data.icon}</span>}
            <span>{data.label}</span>
        </div>
    </div>
);
const CustomOption = ({ data, innerProps, isDisabled }) => !isDisabled ? (
    <div {...innerProps}>
        <div className="input-select__single-value">
            {data.icon && <span className="input-select__icon mr-2">{data.icon}</span>}
            <span>{data.label}</span>
        </div>
    </div>
) : null;

export const LeadsDetails = (props) => {
    const { match, location } = props;
    const [lead, setLead] = useState({});
    let [state, setState] = useState({ lead_id: '' });
    const [lead_errors, setErrors] = useState({});
    const [loading, setLoading] = useState({
        page_loading: false,
        button_loading: false,
        lead_status: false
    });
    const [support_emails, setSupportEmails] = useState({});
    const [show] = useModal();
    const [ admin_lookups, setAdminLookups ] = useState([]);


    const lead_id = match && match.params && match.params.lead_id ? match.params.lead_id : '';
    const current_page = location && location.state && location.state.current_page ? location.state.current_page : '';
    const lookups = useContext(LookupContext);
    const image_url_status = {
        registered: 'success.svg',
        pending: 'warning.svg',
        rejected: 'error.svg'
    }


    const apiCalls = {
        getSelectedLead: (params) => {
            setLoading((loading) => ({
                ...loading,
                page_loading: true
            }));
            leadsServices.get(params).then((response) => {
                setLoading((loading) => ({
                    ...loading,
                    page_loading: false
                }));
                setSelectedLead(response)

            }).catch((err) => {
                setLoading((loading) => ({
                    ...loading,
                    page_loading: false
                }));
                setErrors(err);
                history.push('/admin')
            });
        },
        createLead: debounce(async (details, key) => {
            setLoading((loading) => ({
                ...loading,
                [key]: true
            }));
            await leadsServices.create(details).then((response) => {
                history.push(`/admin/leads`)
                setErrors({});
                toast.success('Created Successfully');
                setLoading((loading) => ({
                    ...loading,
                    [key]: false
                }));
            })
                .catch((err) => {
                    let errors = {};
                    if (err.data) {
                        errors = err.data && err.data.errors;
                    }
                    setErrors(errors);
                    setLoading((loading) => ({
                        ...loading,
                        button_loading: false
                    }));
                });
        }, 300),
        updateLead: debounce((details, key) => {
            setLoading((loading) => ({
                ...loading,
                [key]: true
            }));
            leadsServices.update(details, state.lead_id)
                .then((res) => {
                    setErrors({});
                    toast.success('Updated Successfully');
                    setLoading((loading) => ({
                        ...loading,
                        [key]: false
                    }));
                    setSelectedLead(res)
                })
                .catch((err) => {
                    let errors = err.data && err.data.errors;
                    setErrors(errors);
                    setLoading((loading) => ({
                        ...loading,
                        [key]: false
                    }));
                });
        }, 300),
        getAllSupportEmails: () => {
            supportEmailServices.getAll({ notifiable_id: lead_id, notifiable_type: 'lead' })
                .then((response) => {
                    setSupportEmails(response);
                })
                .catch((err) => {
                    let errors = err.data && err.data.errors;
                    setErrors(errors);
                });
        },
        getAllAdmins: (params) => {
            return new Promise((resolve) => {
                userServices.getAll(params)
                    .then((response) => {
                        let data = response && response.data ?
                            response.data.map((item) => ({ label: item.full_name, value: item.user_id })) : [];
                        resolve(data);
                        setAdminLookups(data);
                    });
            })
        },
    }

    const setSelectedLead = (data) => {
        setLead({
            ...data,
            support_user_id: !isEmpty(data.support_user) ? data.support_user.user_id : ''
        });
    }

    useEffect(() => {
        if (lead_id) {
            apiCalls.getSelectedLead(lead_id);
            apiCalls.getAllSupportEmails();
            setState({ ...state, lead_id: lead_id });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChange = (key, value) => {
        setLead({ ...lead, [key]: value });
    }
    const handleChangePhone = (value) => {
        setLead({ ...lead, phone: value.phone, phone_country_code: value.phone_country_code });
    }

    const saveChanges = (e) => {
        e.preventDefault();
        let details = { ...omit(lead, 'full_name', 'lead_status', 'partner',
            'admin_notes', 'inquiries','partner','support_user') };


        if (state.lead_id) {
            apiCalls.updateLead(details, 'button_loading');
        }
        else {
            apiCalls.createLead(details, 'button_loading');
        }

    }

    const loadAdmins = (inputValue, callback) => {
        let params = { show_admins: true };
        if (inputValue !== '') {
            params.search = inputValue;
        }

        apiCalls.getAllAdmins(params)
            .then((response) => {
                callback(response);
            });
    };
    let lead_statuses = lookups.lead_status.map(item => (
        {...item, icon: <img src={`/images/admin/global/status/${image_url_status[item.slug]}`} alt="" />}
    ))

    return (
        <>
            {
                loading['page_loading'] ?
                    <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
                        <ListLoader />
                    </div>
                    :
                    <div>
                        <div className="generic-page-header">
                            <div className="header-data-section">
                                <Link
                                    to={{
                                        pathname: '/admin/leads',
                                        state: { current_page }
                                    }}
                                    className="back-btn"
                                >

                                    <img src="/images/admin/global/back.svg" alt="" />
                                </Link>
                                <h2 className="page-head my-0">{lead.first_name ? lead.first_name : ''} {lead.last_name ? lead.last_name : ''}
                                    <span>{lead.email ? `( ${lead.email} )` : ''}</span></h2>
                            </div>

                            <div className="header-data-section">

                                    {!isEmpty(lead) && lead.lead_status &&
                                    <div className="custom-react-select--lead mr-3">
                                    {loading.lead_status ?  <Spinner size="sm"/>:
                                        <Select
                                            components={{ SingleValue: customSingleValue, Option: CustomOption }}
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            options={lead_statuses}
                                            styles={styles}
                                            value={find(lead_statuses, ['value',  lead.lead_status.slug] )}
                                            onChange={(e) => apiCalls.updateLead({lead_status_id: e.lead_status_id}, 'lead_status')}
                                        />
                                    }
                                </div>
                                    }
                                <Button
                                    color="primary"
                                    className="add-new-btn"
                                    onClick={(e) => saveChanges(e)}
                                    disabled={loading.button_loading || loading.lead_status}
                                >
                                    {
                                        loading['button_loading'] ? <Spinner size="sm" /> : 'Save Changes'
                                    }

                                </Button>
                                { lead.registered_user &&
                                    <Link
                                        color="primary"
                                        className="add-new-btn ml-3"
                                        to={`/profile/${lead.registered_user.user_id}`}
                                    >
                                        View Profile
                                    </Link>
                                }
                            </div>
                        </div>
                        <div className="tt-admin-card-lead-create">
                            <div className="tt-admin-card--data">
                                <Row className="no-gutters">
                                    <Col xs="12" md="12" lg="12" xl={lead_id ? "9" : "12"}>
                                        <div className="trip-details-data-section">
                                            <Form className="tt-admin-form">
                                                <div className="tt-admin-form-inner">
                                                    <Row form>
                                                        <Col xs="12" md="6">
                                                            <FormGroup>
                                                                <Label for="">First Name</Label>
                                                                <Input
                                                                    type="text"
                                                                    name="first_name"
                                                                    id="first_name"
                                                                    onChange={
                                                                        (e) => {
                                                                            handleChange(e.target.name, e.target.value);
                                                                        }
                                                                    }
                                                                    value={lead.first_name ? lead.first_name : ''}
                                                                    invalid={!isEmpty(lead_errors) && (lead_errors.first_name)
                                                                        && true}
                                                                />
                                                                <ErrorFormFeedback field={'first_name'} errors={lead_errors} />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col xs="12" md="6">
                                                            <FormGroup>
                                                                <Label for="">Last Name</Label>
                                                                <Input
                                                                    type="text"
                                                                    name="last_name"
                                                                    id="last_name"
                                                                    onChange={
                                                                        (e) => {
                                                                            handleChange(e.target.name, e.target.value);
                                                                        }
                                                                    }
                                                                    value={lead.last_name ? lead.last_name : ''}
                                                                    invalid={!isEmpty(lead_errors) && (lead_errors.last_name)
                                                                        && true}
                                                                />
                                                                <ErrorFormFeedback field={'last_name'} errors={lead_errors} />
                                                            </FormGroup>
                                                        </Col>

                                                    </Row>
                                                    <Row form>
                                                        <Col xs="12" md="6">
                                                            <FormGroup>
                                                                <Label for="">Email Address</Label>
                                                                <Input
                                                                    type="text"
                                                                    name="email"
                                                                    id="email"
                                                                    onChange={
                                                                        (e) => {
                                                                            handleChange(e.target.name, e.target.value);
                                                                        }
                                                                    }
                                                                    value={lead.email ? lead.email : ''}
                                                                    invalid={!isEmpty(lead_errors) && (lead_errors.email)
                                                                        && true}
                                                                />
                                                                <ErrorFormFeedback field={'email'} errors={lead_errors} />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col xs="12" md="6">
                                                            <FormGroup>
                                                                <Label for="">Phone Number</Label>
                                                                <NumberInputComponent
                                                                    phone={`+${lead.phone_country_code}${lead.phone}`}
                                                                    onChange={handleChangePhone}
                                                                />

                                                                {!isEmpty(lead_errors) && lead_errors.phone && (
                                                                    <div>
                                                                        <div className="invalid-feedback d-block">{lead_errors.phone}</div>
                                                                    </div>
                                                                )}
                                                                {!isEmpty(lead_errors) && lead_errors.phone_country_code && (
                                                                    <div>
                                                                        <div className="invalid-feedback d-block">{lead_errors.phone_country_code}</div>
                                                                    </div>
                                                                )}
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row form>
                                                        <Col xs="12" md="6">
                                                            <FormGroup>
                                                                <Label for="">Admin (Sales Person)</Label>
                                                                <div className="tt-custom-react-select">
                                                                    <AsyncSelect
                                                                        cacheOptions
                                                                        defaultOptions
                                                                        loadOptions={loadAdmins}
                                                                        className="react-select-container"
                                                                        classNamePrefix="react-select"
                                                                        styles={styles}
                                                                        onChange={(e) => {
                                                                            handleChange('support_user_id', e.value);
                                                                        }
                                                                        }
                                                                        value={find(admin_lookups, ['value',  lead.support_user_id] )}
                                                                    />
                                                                    {!isEmpty(lead_errors) && lead_errors.support_user_id &&
                                                                        <ErrorFormFeedback errors={lead_errors} field={'support_user_id'} is_invalid={true}/>
                                                                    }
                                                                </div>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    {
                                                        !isEmpty(lead_id) &&
                                                        <>
                                                            <Row>
                                                                <Col xs="12">
                                                                    <h4 className="in-form-heading"><span>Inquiry Detail</span></h4>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col xs="12">
                                                                {lead.inquiries && !isEmpty(lead.inquiries) && lead.inquiries.map((item, index) => (
                                                                    <div className="lead-inquiry-details" key={index}>
                                                                        {item.trip &&
                                                                            <h5>{`${item.trip.title} - ${item.trip.trip_no}`}</h5>
                                                                        }
                                                                        <p>{item.message}</p>
                                                                        <small>{moment(item.created_at).format('MMM DD, YYYY - h:mm:ss a')}</small>
                                                                    </div>
                                                                ))}
                                                                    {/* <div className="lead-inquiry-details">
                                                                        <h5>Trip Name Goes Here – 918723</h5>
                                                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consectetur amet veritatis mollitia dolor eaque totam. Consectetur amet veritatis mollitia dolor eaque totam. Velit fugiat eveniet provident suscipit voluptatum optio facilis vitae, quod ipsa reiciendis laborum distinctio natus et blanditiis itaque ea sint omnis. Beatae corporis delectus maxime omnis itaque iure repellat, iste suscipit.</p>
                                                                        <small>Nov 27, 2019 – 7:10:12</small>
                                                                    </div> */}
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col xs="12">
                                                                    <h4 className="in-form-heading"><span>Support Emails</span></h4>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col xs="12">
                                                                    {
                                                                        !isEmpty(support_emails) && !isEmpty(support_emails.data) &&
                                                                        support_emails.data.map((emails, index) => (
                                                                            <div
                                                                                className={classnames("lead-support-email", { 'email-sent': emails.support_email_status && emails.support_email_status.slug === 'sent' ? true : false })}
                                                                                key={`support_emails${index}`}
                                                                            >
                                                                                <h4>{emails.subject ? emails.subject : ''} (<Link to={"#"} onClick={() => {
                                                                                    show('EditSupportEmail', {
                                                                                        support_email_id: emails.support_email_id,
                                                                                        status: emails.support_email_status && emails.support_email_status.slug ? emails.support_email_status.slug : '',
                                                                                        callBack: () => {
                                                                                            apiCalls.getAllSupportEmails();
                                                                                        }
                                                                                    })
                                                                                }}> {emails.support_email_status && emails.support_email_status.slug === 'sent' ? 'View Message' : 'Edit Message'}</Link>)</h4>
                                                                                <h5>{emails.support_email_status && emails.support_email_status.label ? emails.support_email_status.label : ''}</h5>
                                                                            </div>
                                                                        ))
                                                                    }
                                                                </Col>
                                                                {/* <div className="lead-support-email email-sent">
                                                                        <h4>Exchange Confirmed Email (<Link to={"#"}> View Message</Link>)</h4>
                                                                        <h5>Sent</h5>
                                                                    </div>
                                                                    <div className="lead-support-email">
                                                                        <h4>Lorem Ipsum (<Link to={"#"}> Edit Message</Link>)</h4>
                                                                        <h5>Pending</h5>
                                                                    </div>
                                                                    <div className="lead-support-email">
                                                                        <h4>Dolor Set Amet (<Link to={"#"}> Edit Message</Link>)</h4>
                                                                        <h5>Scheduled ( Jan 10, 2020)</h5>
                                                                    </div> */}
                                                            </Row>
                                                        </>
                                                    }

                                                </div>
                                            </Form>
                                        </div>
                                    </Col>
                                    {
                                        !isEmpty(lead_id) &&
                                        <Col xs="12" md="12" lg="12" xl="3">
                                            <div className="trip-details-sidebar user-details-sidebar leads-sidebar">
                                                <Row>
                                                    <Col>
                                                        {
                                                            props.match && props.match.params && props.match.params.lead_id && !isEmpty(lead) &&
                                                            <AdminNotes
                                                                entity_type="Lead"
                                                                entity_id={props.match.params.lead_id}
                                                                admin_notes={lead.admin_notes}
                                                            />
                                                        }
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col>
                                    }
                                </Row>
                            </div>

                        </div>
                    </div>
            }
        </>
    );
}


