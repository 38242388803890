import React, { useState, useEffect, useContext } from "react";
import { Row, Col } from 'reactstrap';
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';
import { toast } from "react-toastify";
import dompurify from 'dompurify';

import { isEmpty } from 'lodash';

import SectionHeader from "js/components/legos/sectionHeader";
import BigImageSlider from "js/components/legos/bigImageSlider";
import FaqAccordion from "js/components/legos/faqAccordion";

import { StaticLink, RelatedExperience } from 'js/components/common';

import { cmsServices } from 'js/services';
import { AuthProvider } from 'js/contexts';
import { history } from 'js/helpers';

export const HowItWorks = () =>  {
    const [ state, setState] = useState({
        content: {
            page_title: '',
            steps : [],
            real_example : {
                quote : '',
                images : [],
                section_title : '',
                testimonials : []
            },
            popular_questions : {
                section_title : '',
                questions : [],
            },
            trade_resources: {
                section_title : '',
                resources : [],
            }
        },
    } );
    const [ display_all, setFaq] = useState(false)
    const { auth } = useContext(AuthProvider);


    const getStaticPage = () => {
        cmsServices.getStaticPage('how-it-works')
        .then((response) => {
            setState({ ...state, ...response })
        }).catch((err) => {
            toast.error('Failed to Load Page');
            history.push('/')
        });
    }
    useEffect(() => {
        getStaticPage();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const content = state.content;
    const sanitizer = dompurify.sanitize;
    return (
        <div>
            <Helmet>
                <title>{ content && content.page_title ? content.page_title : '' } - Trips4Trade</title>
                <meta name="description" content="Trade For The Trip Of A Lifetime. Trips4Trade allows users to trade trips pertaining to outdoors, adventure, vacation, and sports." />
            </Helmet>

            <div className="inner-banner" style={{backgroundImage: `url(images/web/global/banners/static-banner.png)`}}>
                <div className="container">
                    <Row>
                        <Col xs="12">
                            <h1 className="header2 color--white text-uppercase">{content.page_title}</h1>
                        </Col>

                    </Row>
                </div>
            </div>
            <div className="wider-data-frame">
                <div className="container">
                    <Row>
                        { content.steps && content.steps[0] &&
                        <Col xs="12" md="4">
                            <div className="how-it-works-card first-child how-it-works-card--lg">
                                <div className="how-it-works-card--box">
                                    <img src="images/web/how-it-works/add-trip.svg" alt="" />
                                </div>
                                <h5>1</h5>
                                <h4 className="header4">{content.steps[0].section_title}</h4>
                                <p>{content.steps[0].description}</p>
                            </div>
                        </Col>
                        }
                        { content.steps && content.steps[1] &&
                        <Col xs="12" md="4">
                            <div className="how-it-works-card how-it-works-card--lg">
                                <div className="how-it-works-card--box">
                                    <img src="images/web/how-it-works/discover.svg" alt="" />
                                </div>
                                <h5>2</h5>
                                <h4 className="header4">{content.steps[1].section_title}</h4>
                                <p>{content.steps[1].description}</p>
                            </div>
                        </Col>
                        }
                        { content.steps && content.steps[2] &&
                        <Col xs="12" md="4">
                            <div className="how-it-works-card how-it-works-card--lg">
                                <div className="how-it-works-card--box">
                                    <img src="images/web/how-it-works/trade.svg" alt="" />
                                </div>
                                <h5>3</h5>
                                <h4 className="header4">{content.steps[2].section_title}</h4>
                                <p>{content.steps[2].description}</p>
                            </div>
                        </Col>
                        }
                    </Row>

                </div>
            </div>
            { content.real_example &&
            <div className="wider-data-frame wider-data-frame--grey">
                <div className="container">
                    <Row>
                        <SectionHeader
                            HeaderText={ content.real_example && content.real_example.section_title }
                        />
                    </Row>
                    <Row>
                        <Col xs="12" md="12" lg={{ size: 10, offset: 1 }}>
                            <div dangerouslySetInnerHTML={{__html: sanitizer(content.real_example.quote) }}></div>
                            { !isEmpty( content.real_example.images ) &&
                            <div>
                                <Row>
                                    <Col>
                                    <BigImageSlider images={content.real_example.images}/>
                                    </Col>
                                </Row>
                            </div>
                            }
                            <div className="dual-btn-wrapper">
                                <Row>
                                    <Col xs="12" className=" mt-5">
                                        <div className="btn-inner">
                                            { content.real_example.testimonials &&  content.real_example.testimonials.map( (item, index) => (
                                                <StaticLink url={item.url} content={item.label} key={index} class="btn btn-outline mr-3" />
                                            ))}

                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
            }
            { content.popular_questions &&
            <div className="wider-data-frame">
                <div className="container">
                    <Row>
                        <SectionHeader
                            HeaderText={ content.popular_questions && content.popular_questions.section_title }
                        />
                    </Row>
                    <Row>
                        <Col className="mt-5">
                            { content.popular_questions?.questions?.map( ( item, index ) => (
                                display_all ?
                                <FaqAccordion sl={ index + 1 } item={ item } key={ index } />
                                    :
                                    index < 5 &&
                                <FaqAccordion sl={ index + 1 } item={ item } key={ index } />
                            ))}
                        </Col>
                    </Row>
                    { !display_all &&
                        <div className="dual-btn-wrapper">
                            <Row>
                                <Col xs="12" className="d-flex justify-content-center mt-5">
                                    <Link to={ "#" } className="btn btn-outline" onClick={ () => setFaq( true ) }>View All FAQs</Link>
                                </Col>
                            </Row>
                        </div>
                    }
                </div>
            </div>
            }
            { content.trade_resources &&
            <div className="wider-data-frame wider-data-frame--grey">
                <div className="container">
                    <Row>
                        <SectionHeader
                            HeaderText={ content.trade_resources && content.trade_resources.section_title }
                        />
                    </Row>
                    <Row>
                        <Col  xs="12" md="12" lg={{ size: 10, offset: 1 }} className="mt-5">
                            <div className="tt-card">
                                <ul className="trade-resources">
                                { content.trade_resources && content.trade_resources.resources && content.trade_resources.resources.map( (item, index) => (
                                    <li key={index} ><Link to={item.url ? item.url : '#'} >{item.resource}</Link></li>
                                ))}
                                </ul>
                            </div>
                        </Col>
                    </Row>
                    <div className="dual-btn-wrapper">
                        <Row>
                            <Col xs="12" className="mt-4">
                                <div className="btn-inner">
                                    <Link to={"/trips"} className="btn btn-outline mr-3">View Trips</Link>
                                    {
                                        auth && isEmpty(auth.user) &&
                                        <Link to={"/auth/signup"} className="btn btn-outline">Join Now</Link>
                                    }
                                </div>

                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
            }
            <RelatedExperience />
        </div>


    );
}
