import React, { useState, useEffect, useContext } from 'react';
import {
    Row,
    Col,
    Button,
    Form,
    FormGroup,
    CustomInput,
    Label,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Input,
    Spinner
} from 'reactstrap';

import Select from 'react-select';
import { debounce, omit, isEmpty, cloneDeep } from 'lodash';
import DatePicker from "react-datepicker";
import moment from 'moment';
import { toast } from "react-toastify";

import { promotionServices } from 'js/services';
import { LookupContext } from 'js/contexts';
import { ErrorFormFeedback } from 'js/components/common';

export const AddEditPromotionModal = (props) => {
    const { is_open, hide, callBack } = props;

    const [ promotion, setPromotion] = useState({});
    const [is_edit, setEdit] = useState(false);
    const [createErrors, setErrors] = useState({});
    const lookups = useContext(LookupContext);
    const [loading, setLoading] = useState(false);

    const apiCalls = {
        getPromotion: (params) => {
            promotionServices.get(params, hide).then((response) => {
                let selected_promotion = cloneDeep(response);

                if (!isEmpty(selected_promotion.expire_at)) {
                    selected_promotion.expire_at = new Date(selected_promotion.expire_at);
                }

                selected_promotion.promotion_type_id = selected_promotion.promotion_type ? selected_promotion.promotion_type.promotion_type_id : '';
                selected_promotion.promotion_status_id = selected_promotion.promotion_status ? selected_promotion.promotion_status.promotion_status_id : '';
                selected_promotion.promotion_value_type_id = selected_promotion.promotion_value_type ? selected_promotion.promotion_value_type.promotion_value_type_id : '';

                setPromotion(selected_promotion);
            }).catch((err) => {
                setErrors(err);
            });
        },
        createPromotion: debounce((details) => {
            setLoading(true)
            promotionServices.create(details, hide).then(() => {
                setErrors({});
                callBack();
                setLoading(false)
                toast.success('Created Successfully');
            })
            .catch((err) => {
                let errors = err && err.data && err.data.errors;
                setErrors(errors);
                setLoading(false)
            });
        }, 300),
        updatePromotion: debounce((details, promotion_id) => {
            setLoading(true)
            promotionServices.update(details, promotion_id).then(() => {
                setErrors({});
                callBack();
                setLoading(false)
                toast.success('Updated Successfully');
            })
            .catch((err) => {
                let errors = err.data && err.data.errors;
                setErrors(errors);
                setLoading(false)
            });
        }, 300),
    }

    useEffect(() => {
        if (props && props.promotion_id) {
            setEdit(true)
            apiCalls.getPromotion(props.promotion_id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const submit = () => {
        let details = cloneDeep(promotion);

        details.expire_at = details.expire_at ? moment(details.expire_at).format('YYYY-MM-DD') : null;

        if (!(isEmpty(details.promotion_type))) {
            details.promotion_type_id = details.promotion_type.promotion_type_id;
        }

        if (props && props.promotion_id) {
            apiCalls.updatePromotion(
                { ...omit(details, 'promotion_id', 'code', 'referrer_user_id', 'promotion_status', 'promotion_type', 'promotion_value_type') },
                props.promotion_id
            );
        }
        else {
            apiCalls.createPromotion(details);
        }
    }

    return (
        <div>
            <Modal
                isOpen={is_open}
                toggle={() => hide()}
                className="tt-modal--admin modal-dialog-centered"
            >
                <ModalHeader toggle={() => hide()}>{!props.promotion_id ? 'Add' : 'Edit'} Promotion</ModalHeader>
                <ModalBody>
                    <Form className="tt-admin-form">
                        <div className="tt-admin-form-inner p-0">
                            <Row form>
                                <Col xs="12" md="6">
                                    <FormGroup>
                                        <Label for="">Promotion Type</Label>
                                        <div className="tt-custom-react-select">
                                            <Select
                                                className="react-select-container"
                                                classNamePrefix="react-select"
                                                options={lookups.promotion_types}
                                                onChange={
                                                    (e) => {
                                                        setPromotion({ ...promotion, 'promotion_type': e });
                                                    }
                                                }
                                                invalid={!isEmpty(createErrors) && (createErrors.promotion_type_id) && true}
                                                value={promotion.promotion_type ? promotion.promotion_type : null}
                                            />
                                            <ErrorFormFeedback field={'promotion_type_id'} errors={createErrors} is_invalid={true}/>
                                        </div>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="12">
                                    <hr className="hr-solid mt-1 mb-3" />
                                </Col>
                            </Row>
                            <Row form>
                                <Col xs="12" md="6">
                                    <FormGroup>
                                        <Label for="">Label</Label>
                                        <Input
                                            type="text"
                                            name="text"
                                            onChange={
                                                (e) => {
                                                    setPromotion({ ...promotion, 'label': e.target.value });
                                                }
                                            }
                                            value={promotion.label ? promotion.label : ''}
                                            invalid={!isEmpty(createErrors) && (createErrors.label) && true }
                                        />
                                        <ErrorFormFeedback field={'label'} errors={createErrors} />
                                    </FormGroup>
                                </Col>
                                <Col xs="12" md="6">
                                    <FormGroup>
                                        <Label for="">Code</Label>
                                        <Input
                                            type="text"
                                            name="text"
                                            onChange={
                                                (e) => {
                                                    setPromotion({ ...promotion, 'code': e.target.value });
                                                }
                                            }
                                            value={promotion.code ? promotion.code : ''}
                                            invalid={!isEmpty(createErrors) && (createErrors.code) && true }
                                            readOnly={is_edit}
                                        />
                                        <ErrorFormFeedback field={'code'} errors={createErrors} />
                                    </FormGroup>
                                </Col>
                                <Col xs="12" md="6">
                                    <FormGroup>
                                        <Label for="">Value</Label>
                                        <Input
                                            type="number"
                                            name="text"
                                            onChange={
                                                (e) => {
                                                    setPromotion({ ...promotion, 'value': e.target.value });
                                                }
                                            }
                                            value={promotion.value ? promotion.value : ''}
                                            invalid={!isEmpty(createErrors) && (createErrors.value) && true }
                                        />
                                        <ErrorFormFeedback field={'value'} errors={createErrors} />
                                    </FormGroup>
                                </Col>
                                <Col xs="12" md="6">
                                    <FormGroup>
                                        <Label for="">Expiration Date</Label>
                                        <div className="tt-custom-react-select">
                                            <DatePicker
                                                className='custom-date-picker'
                                                selected={promotion.expire_at ? moment(promotion.expire_at).toDate() : ''}
                                                onChange={
                                                    (e) => {
                                                        setPromotion({ ...promotion, 'expire_at': e });
                                                    }
                                                }
                                                showYearDropdown
                                                invalid={!isEmpty(createErrors) && (createErrors.expire_at) && true}
                                            />
                                            <ErrorFormFeedback field={'expire_at'} errors={createErrors} is_invalid={true} />
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col xs="12" md="6">
                                    <FormGroup>
                                        <Label for="">Maximum Attempts</Label>
                                        <Input
                                            type="number"
                                            name="text"
                                            onChange={
                                                (e) => {
                                                    setPromotion({ ...promotion, 'maximum_attempts': e.target.value });
                                                }
                                            }
                                            value={promotion.maximum_attempts ? promotion.maximum_attempts : ''}
                                            invalid={!isEmpty(createErrors) && (createErrors.maximum_attempts) && true }
                                        />
                                        <ErrorFormFeedback field={'maximum_attempts'} errors={createErrors} />
                                    </FormGroup>
                                </Col>
                                <Col xs="12" md="6">
                                    <FormGroup>
                                        <Label for="">Maximum Attempts Per User</Label>
                                        <Input
                                            type="number"
                                            name="text"
                                            onChange={
                                                (e) => {
                                                    setPromotion({ ...promotion, 'maximum_attempts_per_user': e.target.value });
                                                }
                                            }
                                            value={promotion.maximum_attempts_per_user ? promotion.maximum_attempts_per_user : ''}
                                            invalid={!isEmpty(createErrors) && (createErrors.maximum_attempts_per_user) && true }
                                        />
                                        <ErrorFormFeedback field={'maximum_attempts_per_user'} errors={createErrors} />
                                    </FormGroup>
                                </Col>
                                <Col xs="12" md="6">
                                    <FormGroup>
                                        <Label for="">Applicable Minimum Price</Label>
                                        <Input
                                            type="number"
                                            name="text"
                                            onChange={
                                                (e) => {
                                                    setPromotion({ ...promotion, 'applicable_minimum_price': e.target.value });
                                                }
                                            }
                                            value={promotion.applicable_minimum_price ? promotion.applicable_minimum_price : ''}
                                            invalid={!isEmpty(createErrors) && (createErrors.applicable_minimum_price) && true }
                                        />
                                        <ErrorFormFeedback field={'applicable_minimum_price'} errors={createErrors} />
                                    </FormGroup>
                                </Col>
                                <Col xs="12" md="6">
                                    <FormGroup>
                                        <Label for="">Applicable Maximum Price</Label>
                                        <Input
                                            type="number"
                                            name="text"
                                            onChange={
                                                (e) => {
                                                    setPromotion({ ...promotion, 'applicable_maximum_price': e.target.value });
                                                }
                                            }
                                            value={promotion.applicable_maximum_price ? promotion.applicable_maximum_price : ''}
                                            invalid={!isEmpty(createErrors) && (createErrors.applicable_maximum_price) && true }
                                        />
                                        <ErrorFormFeedback field={'applicable_maximum_price'} errors={createErrors} />
                                    </FormGroup>
                                </Col>
                                <Col xs="12">
                                    <FormGroup>
                                        <Label for="">Description</Label>
                                        <Input
                                            type="textarea"
                                            value={promotion.description}
                                            onChange={
                                                (e) => {
                                                    setPromotion({ ...promotion, 'description': e.target.value });
                                                }
                                            }
                                            invalid={!isEmpty(createErrors) && (createErrors.description) && true}
                                        />
                                        <ErrorFormFeedback field={'description'} errors={createErrors} />
                                    </FormGroup>
                                </Col>
                                { is_edit &&
                                    <Col xs="12" md="6">
                                        <div>
                                            <Label for="">Initial State</Label>
                                        </div>
                                        <FormGroup check inline>
                                            <CustomInput
                                                type="radio"
                                                id="initialState1"
                                                name="customRadio"
                                                label="Active"
                                                className="mr-3"
                                                value={1}
                                                onChange={
                                                    (e) => {
                                                        setPromotion({ ...promotion, 'is_active': Number(e.target.value) });
                                                    }
                                                }
                                            checked={Number(promotion.is_active) === 1}
                                            />
                                            <CustomInput
                                                type="radio"
                                                id="initialState2"
                                                name="customRadio"
                                                label="Inactive"
                                                value={0}
                                                onChange={
                                                    (e) => {
                                                        setPromotion({ ...promotion, 'is_active': Number(e.target.value) });
                                                    }
                                                }
                                            checked={Number(promotion.is_active) === 0}
                                            />
                                        </FormGroup>
                                        <ErrorFormFeedback field={'is_active'} errors={createErrors} is_invalid={true} />
                                    </Col>
                                }
                            </Row>
                        </div>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        className="btn-outline-grey"
                        onClick={() => hide()}
                        disabled={loading}
                    >Cancel
                    </Button>
                    <Button
                        color="primary"
                        className="add-new-btn"
                        onClick={() => submit()}
                        disabled={loading}
                    >
                    {loading ?
                        <Spinner size="sm"/> : props.promotion_id ? 'Submit' : 'Add a Promotion'
                    }
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}
