import React from 'react';

import { NotificationList } from 'js/components/common';


export const Notifications = () => {
    return (
        <>
            <NotificationList
                type="admin"
            />
        </>
    );
}