import React, {useCallback, useEffect, useState} from "react";import Slider from "react-slick";

import "js/components/legos/styles/review-slider-module.css"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { isEmpty } from 'lodash';

import TripsReviewCard from "js/components/legos/tripsReviewCard"
import { tripsServices } from "js/services";

export const  ReviewSlider =(props) => {
    const [reviews, setReviews] = useState([])
    const { user_id, trip_id } = props;

    const getReviews = useCallback((params) =>{
        if ( !isEmpty( params ) ) {
            tripsServices.getReviews(params)
            .then(res => {
                setReviews(res)
            } ).catch( err => {
                console.log('failed to fetch', err)
            })
        }
    }, []);

    useEffect(() => {
        let params = {}
        if ( user_id )
        {
            params = {
                user_id,
                is_active: 1
            }
        }
        if ( trip_id )
        {
            params = {
                trip_id,
                is_active: 1
            }
        }
        getReviews(params);

    }, [getReviews, trip_id, user_id]);

    const settings = {
        dots: false,
        infinite: true,
        autoplay: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        className: 'review-slider'
    };
    return (
        <div>
            <Slider {...settings}>
                {!isEmpty(reviews) && reviews.data && reviews.data.map((item, index) => (
                    <div key={index}>
                        <TripsReviewCard
                            tripUserImage={item.reviewed_by_user  && item.reviewed_by_user.attachment ?
                                item.reviewed_by_user.attachment.thumbnail_url ?
                                item.reviewed_by_user.attachment.thumbnail_url: item.reviewed_by_user.attachment.attachment_url : ''
                            }
                            tripUser={item.reviewed_by_user ? item.reviewed_by_user.full_name : ''}
                            tripUserType="Guest"
                            tripUserReview={item.description}
                            trip_rating={Number(item.rating)}
                        />
                    </div>
                ))}
            </Slider>
        </div>
    );

}
