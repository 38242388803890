import React, {useEffect, useState} from "react";
import { isEmpty } from 'lodash';
import { Col } from 'reactstrap';

import { advertisementServices } from 'js/services';
import { ListLoader, StaticLink } from 'js/components/common';
import { useAdsClick } from 'js/hooks';


export const Ads = (props) => {
    const [ ads, setAds ] = useState([]);
    const [ loading, setLoading ] = useState(false);
    const { onAdClick } = useAdsClick();

    const getAds = (params) => {

        setLoading(true);
        advertisementServices.getRandomisedLimited(params)
        .then(response => {
            setAds(response);
            setLoading(false);
        }).catch((err) => {
            console.log(err)
        });
    }
    useEffect(() => {
        getAds(props.params)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const itemToDisplay = (ad) => {
        if( props.params.type === 'text-link' ) {
            return (
                <h5 className="featured">{ad.title}</h5>
            )
        }
        if( props.params.type === 'picture') {
            return (
                <img
                    className="experience-card--img"
                    src={ad.attachment ? ad.attachment.thumbnail_url ? ad.attachment.thumbnail_url : ad.attachment.attachment_url: ''}
                    alt={ad.title}
                />
            )
        }
    }

    return (
        <React.Fragment>
        {loading ?
            <ListLoader/>

        :
            !isEmpty(ads) && ads.map((ad, index) => (
                props.position === 'blog' ?
                <div className="blog-sidebar--card sidebar-advertisement" key={index}>
                    <StaticLink
                        adClick={true}
                        url={ad.external_link}
                        content={itemToDisplay(ad)}
                        onChange={() => onAdClick(ad.advertisement_id)}
                    />
                </div>
            :
                props.position === 'trades-in-progress' ?
                <Col xs="12" className="mb-5" key={index}>
                    <StaticLink
                        adClick={true}
                        url={ad.external_link}
                        content={itemToDisplay(ad)}
                        onChange={() => onAdClick(ad.advertisement_id)}
                    />
                </Col>
                :
                <StaticLink
                    adClick={true}
                    key={index}
                    url={ad.external_link}
                    content={itemToDisplay(ad)}
                    onChange={() => onAdClick(ad.advertisement_id)}
                />
            ))
        }
        </React.Fragment>
    );
}
