import React, { useState, useEffect, useContext } from 'react';
import {
    Row,
    Col,
    Button,
    Form,
    FormGroup,
    Label,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Input,
    Spinner
} from 'reactstrap';

import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { pick, isEmpty, forEach } from 'lodash';

import { membershipPlanService } from 'js/services';
import { LookupContext } from 'js/contexts';
import { ErrorFormFeedback, ListLoader } from 'js/components/common';
import { useModal } from 'js/hooks';

const styles = {
    option: (styles, state) => ({
        ...styles,
        backgroundColor: state.isSelected ? "#FFA405 " : state.isFocused ? "#FFA40520" : ""
    })
};

export const AddEditMembershipPlanUpgradeModal = (props) => {
    const { is_open, hide, callBack } = props;
    const [state, setState] = useState({});
    const [credibility_upgrade_array, setCredibilityUpgradeArray] = useState([]);
    const [membership_plan_upgrade, SetMembershipPlanUpgrade] = useState([]);
    const [createErrors, setErrors] = useState({});
    const lookups = useContext(LookupContext);
    const [loading, setLoading] = useState({
        loading_modal: false,
        loading_button: false,
        loading_credibility: false
    });
    const [show] = useModal();


    const apiCalls = {
        getMembershipPlanUpgrades: (membership_plan_upgrade_id) => {
            setLoading((loading) => ({
                ...loading,
                loading_modal: true
            }));
            membershipPlanService.getMembershipPlanUpgrades(membership_plan_upgrade_id, hide).then((response) => {
                if (!isEmpty(response)) {
                    let credibility_upgrades = [];
                    let membership_plan_type = {};

                    if (!isEmpty(response.credibility_upgrades)) {
                        forEach(response.credibility_upgrades, (credibility) => {
                            credibility_upgrades.push({
                                value: credibility.credibility_upgrade_id,
                                label: credibility.label
                            });
                        });

                    }

                    if (!isEmpty(response.membership_plan_upgrade_type)) {
                        membership_plan_type = {
                            ...response.membership_plan_upgrade_type,
                            value: response.membership_plan_upgrade_type.membership_plan_upgrade_type_id
                        }

                    }

                    setState({
                        ...state,
                        credibility_upgrades,
                        membership_plan_type
                    });

                    setLoading((loading) => ({
                        ...loading,
                        loading_modal: false
                    }));
                    SetMembershipPlanUpgrade(response);
                }
            })
                .catch((err) => {
                    let errors = err.data && err.data.errors;
                    setErrors(errors);
                    setLoading((loading) => ({
                        ...loading,
                        loading_modal: false
                    }));
                });
        },
        createMembershipPlanUpgrades: (details) => {
            setLoading((loading) => ({
                ...loading,
                loading_button: true
            }));
            membershipPlanService.createMembershipPlanUpgrades(details, hide).then((response) => {
                props.createInsideModal && props.createInsideModal(response);
                setLoading((loading) => ({
                    ...loading,
                    loading_button: false
                }));
                setErrors({});
                hide();
                callBack();
            })
                .catch((err) => {
                    let errors = err.data && err.data.errors;
                    setErrors(errors);
                    setLoading((loading) => ({
                        ...loading,
                        loading_button: false
                    }));
                });
        },
        updateMembershipPlanUpgrades: (details, membership_plan_upgrade_id) => {
            setLoading((loading) => ({
                ...loading,
                loading_button: true
            }));
            membershipPlanService.updateMembershipPlanUpgrades(details, membership_plan_upgrade_id, hide).then(() => {
                hide();
                callBack();
                setLoading((loading) => ({
                    ...loading,
                    loading_button: false
                }));
            })
                .catch((err) => {
                    setLoading((loading) => ({
                        ...loading,
                        loading_button: false
                    }));
                    let errors = err.data && err.data.errors;
                    setErrors(errors);
                });
        },
        getAllCredibilityUpgrades: () => {
            setLoading((loading) => ({
                ...loading,
                loading_credibility: true
            }));
            membershipPlanService.getAllCredibilityUpgrades({}, hide).then((response) => {
                setLoading((loading) => ({
                    ...loading,
                    loading_credibility: false
                }));
                if (!isEmpty(response)) {
                    let credibility_upgrade_array = [];
                    forEach(response, (credibility) => {
                        credibility_upgrade_array.push({
                            value: credibility.credibility_upgrade_id,
                            label: credibility.label
                        });
                    });

                    setCredibilityUpgradeArray(credibility_upgrade_array);
                }
            })
                .catch((err) => {
                    setLoading((loading) => ({
                        ...loading,
                        loading_credibility: false
                    }));
                    let errors = err.data && err.data.errors;
                    setErrors(errors);
                });
        },
    }

    useEffect(() => {
        if (props && props.membership_plan_upgrade_id) {
            apiCalls.getMembershipPlanUpgrades(props.membership_plan_upgrade_id);
        }
        if (props.label) {
            SetMembershipPlanUpgrade({
                ...membership_plan_upgrade,
                label: props.label
            })
        }
        apiCalls.getAllCredibilityUpgrades();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const multipleCreateSelect = (key, value) => {

        let data_form_api = [];

        forEach(value, (item) => {
            if (item.__isNew__) {
                if (key === 'credibility_upgrades') {
                    show('AddEditCredibilityUpgradeModal', {
                        title: 'Add Credibility Upgrade',
                        callBack: () => apiCalls.getAllCredibilityUpgrades(),
                        createInsideModal: (credibility) => {
                            data_form_api.push({
                                value: credibility.credibility_upgrade_id,
                                label: credibility.label
                            });
                        },
                        label: item.value
                    });
                }
            }
            else {
                data_form_api.push(item);
            }
        });

        setState({
            ...state,
            [key]: data_form_api
        });
    }

    const submit = () => {
        const data_picker = ['membership_plan_upgrade_type_id', 'label', 'price',
            'description', 'credibility_upgrade_ids', 'order', 'expiry_days'];

        let details = pick(membership_plan_upgrade, data_picker)
        let credibility_upgrade_ids = [];

        if (!isEmpty(state.credibility_upgrades)) {
            forEach(state.credibility_upgrades, (item) => {
                credibility_upgrade_ids.push(item.value);
            });
        }

        details.credibility_upgrade_ids = credibility_upgrade_ids;
        details.membership_plan_upgrade_type_id = !isEmpty(state.membership_plan_type) ?
            state.membership_plan_type.value : '';

        if (props && props.membership_plan_upgrade_id) {
            apiCalls.updateMembershipPlanUpgrades({ ...details }, props.membership_plan_upgrade_id);
        }
        else {
            apiCalls.createMembershipPlanUpgrades({ ...details });
        }
    }

    return (
        <div>
            <Modal
                isOpen={is_open}
                toggle={() => hide()}
                className="tt-modal--admin modal-dialog-centered"
            >
                <ModalHeader toggle={() => hide()}>{props.title}</ModalHeader>
                <ModalBody>
                    {
                        loading['loading_modal'] ? <ListLoader />
                            :
                            <Form className="tt-admin-form">
                                <div className="tt-admin-form-inner p-0">
                                    <Row form>
                                        <Col xs="12" md="8">
                                            <FormGroup>
                                                <Label for="">Plan Title</Label>
                                                <Input
                                                    type="text"
                                                    name="label"
                                                    onChange={(e) => SetMembershipPlanUpgrade({ ...membership_plan_upgrade, [e.target.name]: e.target.value })}
                                                    value={membership_plan_upgrade.label ? membership_plan_upgrade.label : ''}
                                                    invalid={!isEmpty(createErrors) && (createErrors.label)
                                                        && true
                                                    }
                                                />
                                                <ErrorFormFeedback field={'label'} errors={createErrors} />
                                            </FormGroup>
                                        </Col>
                                        <Col xs="12" md="4">
                                            <FormGroup>
                                                <Label for="">Cost</Label>
                                                <Input
                                                    type="text"
                                                    name="price"
                                                    placeholder='$'
                                                    onChange={(e) => SetMembershipPlanUpgrade({ ...membership_plan_upgrade, [e.target.name]: e.target.value })}
                                                    value={membership_plan_upgrade.price ? membership_plan_upgrade.price : ''}
                                                    invalid={!isEmpty(createErrors) && (createErrors.price)
                                                        && true
                                                    }
                                                />
                                                <ErrorFormFeedback field={'price'} errors={createErrors} />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row form>
                                        <Col xs="12" >
                                            {
                                                loading['loading_credibility'] ?
                                                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
                                                        <Spinner size="sm" />
                                                    </div>
                                                    :
                                                    <FormGroup>
                                                        <Label for="">Credibility Upgrade</Label>
                                                        <div className="tt-custom-react-select">
                                                        <CreatableSelect
                                                            className="react-select-container"
                                                        //    classNamePrefix="react-select"
                                                            isMulti
                                                            options={credibility_upgrade_array}
                                                            onChange={(e) => multipleCreateSelect('credibility_upgrades', e)}
                                                            value={state.credibility_upgrades ? state.credibility_upgrades : ''}
                                                            styles={styles}
                                                        />
                                                        </div>
                                                        {/* <div className="invalid-feedback d-block">{form_errors.appointment_with}</div> */}
                                                    </FormGroup>
                                            }
                                        </Col>
                                    </Row>
                                    <Row form>
                                    <Col xs="12" md="6">
                                            <FormGroup>
                                                <Label for="">Membership Plan Upgrade Type</Label>
                                                <div className="tt-custom-react-select">
                                                <Select
                                                    className="react-select-container"
                                                    classNamePrefix="react-select"
                                                    options={lookups.membership_upgrade_plan_types}
                                                    onChange={(e) => {
                                                        setState({
                                                            ...state,
                                                            membership_plan_type: e
                                                        });
                                                    }}
                                                    value={state.membership_plan_type ? state.membership_plan_type : ''}
                                                    styles={styles}
                                                />
                                                </div>
                                                {
                                                    !isEmpty(createErrors.membership_plan_upgrade_type_id) &&
                                                    <div className="invalid-feedback d-block">{createErrors.membership_plan_upgrade_type_id}</div>
                                                }
                                            </FormGroup>
                                        </Col>
                                        <Col xs="12" md="6">
                                            <FormGroup>
                                                <Label for="">Expiry Days</Label>
                                                <Input
                                                    type="number"
                                                    name="expiry_days"
                                                    onChange={(e) => SetMembershipPlanUpgrade({ ...membership_plan_upgrade, [e.target.name]: e.target.value })}
                                                    value={membership_plan_upgrade.expiry_days ? membership_plan_upgrade.expiry_days : ''}
                                                    invalid={!isEmpty(createErrors) && (createErrors.expiry_days)
                                                        && true
                                                    }
                                                />
                                                <ErrorFormFeedback field={'expiry_days'} errors={createErrors} />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row form>
                                        <Col xs="12">
                                            <FormGroup>
                                                <Label for="">Description</Label>
                                                <Input
                                                    type="textarea"
                                                    name="description"
                                                    onChange={(e) => SetMembershipPlanUpgrade({ ...membership_plan_upgrade, [e.target.name]: e.target.value })}
                                                    value={membership_plan_upgrade.description ? membership_plan_upgrade.description : ''}
                                                    invalid={!isEmpty(createErrors) && (createErrors.description)
                                                        && true
                                                    }
                                                />
                                                <ErrorFormFeedback field={'description'} errors={createErrors} />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </div>
                            </Form>

                    }
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        className="btn-outline-grey"
                        onClick={() => hide()}
                        disabled={loading['loading_button'] || loading['loading_modal']}
                    >Cancel
                    </Button>
                    <Button
                        color="primary"
                        className="add-new-btn"
                        onClick={() => submit()}
                        disabled={loading['loading_button'] || loading['loading_modal']}
                    >
                        {loading['loading_button'] ?
                            <Spinner size="sm" /> : 'Submit'
                        }
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}
