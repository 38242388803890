import { request } from "js/helpers/api";

export const supportServices = {
    submitContactUs,
    submitIssue,
    submitRequest,
    submitSupport
};

function submitContactUs(params = {}){
    return request({
        method:'POST',
        url: 'contact-us',
        param: params,
        content_type: 'json',
    });
}
function submitIssue (params = {}){
    return request({
        method:'POST',
        url: 'issues',
        param: params,
        content_type: 'json',
    });
}
function submitRequest (params = {}){
    return request({
        method:'POST',
        url: 'feature-requests',
        param: params,
        content_type: 'json',
    });
}
function submitSupport (params = {}){
    return request({
        method:'POST',
        url: 'support-conservations',
        param: params,
        content_type: 'json',
    });
}

