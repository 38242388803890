import { request } from "js/helpers/api";

export const contestServices = {
    getAll,
    create,
    update,
    remove,
    get,
    bulkAction,

    addMileStone,
    editMileStone,
    removeMilestone,
    removeCampaignMedia,

    submitEntry
};

function getAll(params = {}, cancel_token){
    return request({
        method:'GET',
        url: 'promotional-campaigns',
        param: params,
        cancel_token : `contestServices.getAll${cancel_token}`
    });
}

function create(details){
    return request({
        method:'POST',
        url: 'promotional-campaigns',
        param: details,
        content_type: 'json',
    });
}

function update(details, contest_slug){
    return request({
        method:'PUT',
        url: `promotional-campaigns/${contest_slug}`,
        param: details,
        content_type: 'json',
    });
}

function remove(contest_slug){
    return request({
        method:'DELETE',
        url: `promotional-campaigns/${contest_slug}`,
        param: {},
    });
}

function get(contest_slug){
    return request({
        method:'GET',
        url: `promotional-campaigns/${contest_slug}`,
        param: {},
    });
}


function bulkAction(details){
    return request({
        method:'POST',
        url: 'promotional-campaigns/bulk-action',
        param: details,
        content_type: 'json',
    });
}

function addMileStone ( details, hideModal ){
    return request({
        method:'POST',
        url: 'promotional-campaign-milestone',
        param: details,
        content_type: 'json',
        hideModal
    });
}

function editMileStone ( details, milestone_id, hideModal ){
    return request({
        method:'PUT',
        url: `promotional-campaign-milestone/${milestone_id}`,
        param: details,
        content_type: 'json',
        hideModal
    });
}

function removeMilestone(milestone_id){
    return request({
        method:'DELETE',
        url: `promotional-campaign-milestone/${milestone_id}`,
        param: {},
    });
}

function removeCampaignMedia( media_id ) {
    return request({
        method:'DELETE',
        url: `promotional-campaign-media/${media_id}`,
        param: {},
    });
}

function submitEntry ( details ) {
    return request({
        method:'POST',
        url: 'promotional-campaigns-entry',
        param: details,
        content_type: 'json',
    });
}
