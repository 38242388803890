import React, { useEffect, useState } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import moment from 'moment';
import { isEmpty } from 'lodash';

import { dashboardServices } from 'js/services';
import { ListLoader } from 'js/components/common';
import { TotalListings } from 'js/components/admin/components/dashboard/components';

const DashboardTabs = () => {
    const [loading, setLoading] = useState(false);
    const [dashboard_reports, setDashboardReports] = useState({});
    const [state, setState] = useState({
        activeTab: 'total_listings'
    })

    const [params, setParams] = useState({
        start_date: moment().subtract(7, 'days').format('YYYY-MM-DD'),
        end_date: moment().format('YYYY-MM-DD'),
        type: 'total_listings',
        selected_date: 'last_7_days'
    });


    const apiCalls = {
        getDashboard: (params) => {
            setLoading(true);
            dashboardServices.getDashboard(params).then((response) => {
                setDashboardReports(response);
                setLoading(false);
            }).catch((err) => {
                setLoading(false);
            });
        },
    }

    useEffect(() => {
        apiCalls.getDashboard(params);

        setState((state) => ({
            ...state,
            activeTab: params.type
        }))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params]);


    return (
        <div>
            {
                loading ?
                    <ListLoader />
                    :
                    !isEmpty(dashboard_reports) && dashboard_reports.all_counts &&
                    <>
                        <Nav tabs className="tt-dashboard-tabs">
                            <NavItem>
                                <NavLink>
                                    <div className="tab-label"><img src="/images/admin/dashboard/total_listings.svg" alt="" /> Total Listings</div>
                                    <div className="tab-count">{dashboard_reports.all_counts.total_listings ? dashboard_reports.all_counts.total_listings : 0 }</div>
                                </NavLink>
                            </NavItem>

                            <NavItem>
                                <NavLink>
                                    <div className="tab-label"><img src="/images/admin/dashboard/total_countries.svg" alt="" /> Total Countries</div>
                                    <div className="tab-count">{dashboard_reports.all_counts.total_countries ? dashboard_reports.all_counts.total_countries : 0 }</div>
                                </NavLink>
                            </NavItem>

                            <NavItem>
                                <NavLink>
                                    <div className="tab-label"><img src="/images/admin/dashboard/total_states.svg" alt="" /> Total States</div>
                                    <div className="tab-count">{dashboard_reports.all_counts.total_states ? dashboard_reports.all_counts.total_states : 0 }</div>
                                </NavLink>
                            </NavItem>

                            <NavItem>
                                <NavLink>
                                    <div className="tab-label"><img src="/images/admin/dashboard/total_active_users.svg" alt="" /> Total Active Users</div>
                                    <div className="tab-count">{dashboard_reports.all_counts.total_active_users ? dashboard_reports.all_counts.total_active_users : 0 }</div>
                                </NavLink>
                            </NavItem>

                        </Nav>
                        <TabContent activeTab={state.activeTab}>
                            <TabPane tabId={params.type}>
                                {
                                    <TotalListings
                                        type={params.type}
                                        api_data={dashboard_reports && dashboard_reports.data}
                                        callBack={(value) => setParams((params) => ({
                                            ...params,
                                            ...value
                                        }))}
                                        current_params={params}
                                    />
                                }

                            </TabPane>
                        </TabContent>
                    </>
            }

        </div>

    );
}

export default DashboardTabs;

