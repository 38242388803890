import React, { useState, useEffect }  from "react"
import Slider from "react-slick";
 import "js/components/legos/styles/testimonial-slider-module.css"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { cmsServices } from 'js/services';

const TestimonialSlider = () => {
    const [ testimonials, setTestimonials ] = useState([]);
    const getTestimonials = (params) => {
        cmsServices.getTestimonials(params)
        .then((response) => {
            setTestimonials(response);
        });
    }
    useEffect(() => {
        getTestimonials();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        autoplay: true,
        autoplaySpeed: 5000,
        className: 'testimonial-slider'
    };
    return (
        <div>

            <Slider {...settings}>
                { testimonials && testimonials.data && testimonials.data.map((item, index) => (
                    <div key={index}>
                        <div className="testimonial-content">
                            <p>{(item.description).trim()}</p>
                        </div>
                        <div className="author">
                            <img src={item.attachment ? item.attachment.optimized_url?item.attachment.optimized_url:item.attachment.attachment_url :''} alt={item.name} style={{ width: '50px', height : '50px', overflow: 'hidden' }} />
                            <span>{item.full_name}</span>
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    );
}
export default TestimonialSlider;
