import React, { useState, useEffect, useContext } from 'react';
import { Row, Col, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import { Helmet } from "react-helmet";
import { forEach,remove } from 'lodash';

import { NavigationSidebar } from "js/components/web/components/layouts/components/navigation-sidebar";
import { tripsServices } from 'js/services';
import { LookupContext, AuthProvider } from 'js/contexts';
import { ListLoader, TripListing, RelatedExperience } from 'js/components/common';
import { utilFunctions } from 'js/components/common/fucntions';

export const BookmarkedTrips = (props) => {

    const [active_tab, setActiveTab] = useState('all');
    const [trips, setTrip] = useState({})
    const lookups = useContext(LookupContext);
    const [ state, setState ] = useState({
        loading: false
    })
    const { auth } = useContext(AuthProvider);
    const [ active_category, setCategory ] = useState(null);
    const user_id = auth.user ? auth.user.user_id : null
    const toggle = tab => {
        let active_tab = tab === 'all' ? tab : tab.trip_category_id
        setActiveTab(active_tab);
        tab.trip_category_id && setCategory(tab.trip_category_id)
    }

    const getAllTrips =  function (params) {
        setState({...state, loading: true});

        tripsServices.getBookmarkedTrips({ ...params, user: user_id } )
        .then(response => {
            groupAndUpdate(response);
            setState({...state, loading: false});
        }).catch(err => {
            console.log('failed', err)
        })
    }

    const groupAndUpdate = (response ) => {
        let data = {};
        forEach(response, item => {
            // if( item.bookmarked ){
            item.trip_category = utilFunctions.findCategory(item.trip_categories)
            if ( item.trip_category )
            {
                if ( item.trip_category.parent_id )
                {
                    if( ! data[item.trip_category.parent_id] ){
                        data[item.trip_category.parent_id] = {
                            data : []
                        };
                    }
                    data[item.trip_category.parent_id].data = [...data[item.trip_category.parent_id].data, item];
                }
                else
                {
                    if( ! data[item.trip_category.trip_category_id] ){
                        data[item.trip_category.trip_category_id] = {
                            data : []
                        };
                    }
                    data[item.trip_category.trip_category_id].data = [...data[item.trip_category.trip_category_id].data, item];

                }
            }
            if( ! data.all ){
                data.all = {
                    data : []
                };
                console.log(item);
            }
            data.all.data = [...data.all.data, item];
            // }
        } );

        setTrip(data);
    }

    useEffect(() => {
        getAllTrips({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onUpdate = ( new_trips, updated_item ) => {
        remove( new_trips, function(item) {
            return item.trip_id === updated_item.trip_id;
        });
        groupAndUpdate(new_trips);
    }
    console.log( trips );
    return (
        <div>
            <Helmet>
                <title>Trip4Trade :: Bookmarked Trips</title>
                <meta name="description" content=" My Listings page desription" />
                <meta name="keywords" content="trip4trade,  My Listings" />
            </Helmet>

            <div className="inner-banner-profile" style={{ backgroundImage: `url(/images/web/global/banners/profile-banner.png)` }}>

            </div>
            <div className="wider-data-frame wider-data-frame--grey pt-0">
                <div className="container">
                    <div className="profile-outer-wrapper">
                        <Row className="no-gutters">
                            <Col xs="12" lg="9">
                                <div className="profile-data-section">
                                    <div className="profile-data-section--top">
                                        <Row>
                                            <Col xs="12" md="8" className="pb-4">
                                                <h3><img src="/images/web/global/side-navigation/bookmark.svg" alt="" style={{ width: `30px` }} /> Bookmarked Trips</h3>
                                            </Col>
                                            <Col xs="12" md="4" className="d-flex justify-content-end align-items-start">

                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs="12">
                                                <Nav tabs className="tt-tabs">
                                                    <NavItem>
                                                    <NavLink
                                                        className={classnames({ active: active_tab === 'all' })}
                                                        onClick={() => { toggle('all'); }}>{`All (${trips.all && trips.all.data ? trips.all.data.length : '' })`}
                                                    </NavLink>
                                                    </NavItem>
                                                    {lookups.trip_categories && lookups.trip_categories.map((category, index) => (
                                                        <NavItem key={index}>
                                                            <NavLink
                                                                className={classnames({ active: active_tab === category.trip_category_id })}
                                                                onClick={() => { toggle(category); }}
                                                            >
                                                                {`${category.label} (${trips[category.trip_category_id]?.data?.length || 0}  )`}
                                                            </NavLink>
                                                        </NavItem>
                                                    ))}
                                                </Nav>

                                            </Col>
                                        </Row>
                                    </div>
                                    <div className="profile-data-section--bottom">
                                        <Row>
                                            <Col xs="12">
                                            <TabContent activeTab={active_tab}>
                                                    <TabPane tabId="all" className="my-3">
                                                    {state.loading ?
                                                        <ListLoader/>
                                                        :
                                                        <>
                                                        {trips.all && trips.all.data &&
                                                            <TripListing
                                                                class_name="col-md-6 col-xs-12"
                                                                trips={trips.all}
                                                                onUpdate ={ ( trips, updated_trip ) => onUpdate(trips, updated_trip ) }
                                                            />

                                                        }
                                                        </>
                                                    }
                                                    </TabPane>
                                                    {lookups.trip_categories && lookups.trip_categories.map((category, index) => (
                                                        <TabPane tabId={category.trip_category_id} className="my-3" key={index}>
                                                        {state.loading ?
                                                            <ListLoader/>
                                                            :
                                                            <>
                                                            {  trips[category.trip_category_id]?.data &&
                                                                <TripListing
                                                                    class_name="col-md-6 col-xs-12"
                                                                    trips={trips[category.trip_category_id]}
                                                                    onUpdate ={ ( trips ) => onUpdate(trips) }
                                                                />
                                                            }

                                                            </>

                                                        }
                                                        </TabPane>
                                                    ))}
                                                </TabContent>
                                            </Col>
                                        </Row>

                                    </div>
                                </div>
                            </Col>
                            <NavigationSidebar />
                        </Row>
                    </div>
                </div>
            </div>
            {!state.loading && active_category &&
                <RelatedExperience category_id={active_category}/>
            }

        </div>


    );
}

