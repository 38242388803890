import { request } from "js/helpers/api";

export const invoiceServices = {
    generateInvoiceLinks,
    getAll,
};

function getAll(params = {}) {
    return request({
        method: 'GET',
        url: 'invoices',
        param: params,
        cancel_token: 'invoiceServices.userReport'
    });
}


function generateInvoiceLinks(invoice_id, params = {}) {
    return request({
        method: 'GET',
        url: `invoice/generate-link/${invoice_id}`,
        param: params,
    });
}
