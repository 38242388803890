import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Input, Button, Form, FormGroup, Spinner} from 'reactstrap';
import { orderBy } from 'lodash';
import moment from 'moment';

import { cmsServices } from 'js/services';
import { StaticLink, ErrorFormFeedback } from 'js/components/common';
import { useSubscribe } from 'js/hooks'
export const Footer = () => {
    const [ footer_menu, setFooterMenu] = useState();
    const [ email, setEmail ] = useState('');
    const {errors, subscribe, loading} = useSubscribe();


    const getFooterMenu = () => {
        cmsServices.getFooterMenu({ status : 1 })
        .then((response) => {
            setFooterMenu(response)
        });
    }

    useEffect(() => {
        getFooterMenu();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <footer>
            <div className="footer-top-section">
                <div className="container">
                    {/* TODO_HERE */}
                    <Row>
                        <Col xs="12" md="5" lg="6" className="d-flex align-items-center">
                            <h3>Subscribe for info on new listings!</h3>
                        </Col>
                        <Col xs="12" md="7" lg="6" className="ml-auto">
                            <div className="subscribe-wrapper">
                                <Form>
                                    <FormGroup className="mr-sm-2 mb-0">
                                        <Input
                                            type="email"
                                            name="email"
                                            id=""
                                            placeholder="Enter your Email Address"
                                            onChange={(e) => setEmail(e.target.value)}
                                            invalid={errors.email && true}
                                        />
                                        <ErrorFormFeedback field={'email'} errors={errors}/>
                                    </FormGroup>
                                    <Button
                                        className="btn-solid float-right"
                                        onClick={
                                            () => subscribe(email)
                                        }
                                        disabled={loading}
                                    >{loading ? <Spinner size="sm"/> : 'Subscribe'}
                                    </Button>
                                    <ErrorFormFeedback field={'subscribe'} errors={errors} is_invalid={true}/>
                                </Form>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
            <div className="footer-middle-section">
                <div className="container">
                    <Row>
                        <Col xs="12" md="6" lg="4">
                            <div className="footer-logo">
                                <img src="/images/logo-white.svg" alt="" width="183px" />
                            </div>
                            <div className="connect-wrapper">
                                {/* TODO_HERE */}
                                <h4>Connect</h4>
                                <Link to={"#"}><img src="/images/web/global/footer/fb.svg" alt="" /></Link>
                                <Link to={"#"}><img src="/images/web/global/footer/tw.svg" alt="" /></Link>
                                <Link to={"#"}><img src="/images/web/global/footer/in.svg" alt="" /></Link>
                                <Link to={"#"}><img src="/images/web/global/footer/ins.svg" alt="" /></Link>
                                <Link to={"#"}><img src="/images/web/global/footer/yt.svg" alt="" /></Link>
                                <Link to={"#"}><img src="/images/web/global/footer/gw.svg" alt="" /></Link>
                                <Link to={"#"}><img src="/images/web/global/footer/yd.svg" alt="" /></Link>
                            </div>
                            <div className="quote-wrapper">
                                <p>“Do unto others as you would have them do unto you.” Luke 6:31</p>
                            </div>
                        </Col>
                        <Col xs="12" md="6" lg="8">
                            <h3>Navigation</h3>
                            <ul>
                                { footer_menu && orderBy(footer_menu, ['order'], ['asc']).map( (item, index) => (
                                    <li key={index} > <StaticLink url={item.page_to_link} content={item.label} /></li>
                                ))}
                            </ul>
                        </Col>

                    </Row>
                </div>
            </div>
            <div className="footer-bottom-section">
                <div className="container">
                    <Row>
                        <Col>
                            <div className="copyright">
                                <p>Copyright © Trips4Trade {moment().format('YYYY')}. All Rights Reserved.</p>
                            </div>

                        </Col>
                    </Row>
                </div>
            </div>
        </footer>
    );
}
