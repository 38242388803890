import React, { useState, useEffect } from 'react';
import {
    Row,
    Col,
    Button,
    Form,
    FormGroup,
    Label,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Spinner
} from 'reactstrap';
import { omit, isEmpty, forEach } from 'lodash';

import { membershipPlanService } from 'js/services';
import CreatableSelect from 'react-select/creatable';
import { useModal } from 'js/hooks';
import { ListLoader } from "js/components/common";

const styles = {
    option: (styles, state) => ({
        ...styles,
        backgroundColor: state.isSelected ? "#FFA405 " : state.isFocused ? "#FFA40520" : ""
    })
};


export const AddPlanModal = (props) => {
    const { is_open, hide, callBack } = props;

    const [upgrades_array, setUpgradeArray] = useState([]);
    const [state, setState] = useState([]);

    //    const [createErrors, setErrors] = useState({});
    const [loading, setLoading] = useState({
        modal_loading: false,
        button_loading: false
    });
    const [show] = useModal();

    const apiCalls = {
        updateMembershipPlan: (details, membership_plan_id) => {
            setLoading((loading) => ({
                ...loading,
                button_loading: true
            }));
            membershipPlanService.update(details, membership_plan_id).then(() => {
                hide();
                callBack();
                setLoading((loading) => ({
                    ...loading,
                    button_loading: false
                }));
            })
                .catch((err) => {
                    setLoading((loading) => ({
                        ...loading,
                        button_loading: false
                    }));
                    //    let errors = err.data && err.data.errors;
                    //    setErrors(errors);
                });
        },
        getAllCredibilityUpgrades: () => {
            setLoading((loading) => ({
                ...loading,
                modal_loading: true
            }));
            membershipPlanService.getAllCredibilityUpgrades().then((response) => {
                setLoading((loading) => ({
                    ...loading,
                    modal_loading: false
                }));
                if (!isEmpty(response)) {
                    let credibility_upgrade_array = [];
                    forEach(response, (credibility) => {
                        credibility_upgrade_array.push({
                            value: credibility.credibility_upgrade_id,
                            label: credibility.label
                        });
                    });

                    setUpgradeArray(credibility_upgrade_array);
                }
            })
                .catch((err) => {
                    setLoading((loading) => ({
                        ...loading,
                        modal_loading: false
                    }));
                    //    let errors = err.data && err.data.errors;
                    //    setErrors(errors);
                });
        },
        getAllMembershipPlanUpgrades: () => {
            setLoading((loading) => ({
                ...loading,
                modal_loading: true
            }));
            membershipPlanService.getAllMembershipPlanUpgrades().then((response) => {
                setLoading((loading) => ({
                    ...loading,
                    modal_loading: false
                }));
                if (!isEmpty(response)) {
                    let membership_plan_upgrades_array = [];
                    forEach(response, (membership_plan_upgrade) => {
                        membership_plan_upgrades_array.push({
                            value: membership_plan_upgrade.membership_plan_upgrade_id,
                            label: membership_plan_upgrade.label
                        });
                    });
                    setUpgradeArray(membership_plan_upgrades_array);
                }
            })
                .catch((err) => {
                    setLoading((loading) => ({
                        ...loading,
                        modal_loading: false
                    }));
                    //    let errors = err.data && err.data.errors;
                    //    setErrors(errors);
                });
        },

    }

    useEffect(() => {
        if (props.type === 'membership_plan_upgrades') {
            apiCalls.getAllMembershipPlanUpgrades();
        }
        else {
            apiCalls.getAllCredibilityUpgrades();
        }

        if (props.data) {
            let credibility_upgrades = [];
            let membership_plan_upgrades = [];

            if (!isEmpty(props.data.credibility_upgrades)) {
                forEach(props.data.credibility_upgrades, (credibility) => {
                    credibility_upgrades.push({
                        value: credibility.credibility_upgrade_id,
                        label: credibility.label
                    });
                });

            }

            if (!isEmpty(props.data.membership_plan_upgrades)) {
                forEach(props.data.membership_plan_upgrades, (membership_plan_upgrade) => {
                    membership_plan_upgrades.push({
                        value: membership_plan_upgrade.membership_plan_upgrade_id,
                        label: membership_plan_upgrade.label
                    });
                });

            }


            if (!isEmpty(credibility_upgrades) || !isEmpty(membership_plan_upgrades))
                setState({
                    ...state,
                    credibility_upgrades,
                    membership_plan_upgrades
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const multipleCreateSelect = (value) => {
        let data_form_api = [];

        forEach(value, (item) => {
            if (item.__isNew__) {
                if (props.type === 'membership_plan_upgrades') {
                    show('AddEditMembershipPlanUpgradeModal', {
                        title: 'Add Membership Upgrade',
                        callBack: () => apiCalls.getAllMembershipPlanUpgrades(),
                        createInsideModal: (membership_plan_upgrade) => {
                            data_form_api.push({
                                value: membership_plan_upgrade.membership_plan_upgrade_id,
                                label: membership_plan_upgrade.label
                            });
                        },
                        label: item.value
                    });
                }
                else {
                    show('AddEditCredibilityUpgradeModal', {
                        title: 'Add Credibility Upgrade',
                        callBack: () => apiCalls.getAllCredibilityUpgrades(),
                        createInsideModal: (credibility) => {
                            data_form_api.push({
                                value: credibility.credibility_upgrade_id,
                                label: credibility.label
                            });
                        },
                        label: item.value
                    });
                }
            }
            else {
                data_form_api.push(item);
            }
        });

        setState({
            ...state,
            [props.type]: data_form_api
        });

    }

    const submit = () => {
        const data_remover = ['membership_plan_id', 'slug', 'credibility_upgrades', 'membership_plan_upgrades']
        let details = { ...omit(props.data, data_remover) };
        let credibility_upgrade_ids = [];
        let membership_plan_upgrade_ids = [];

        if (!isEmpty(state.credibility_upgrades)) {
            forEach(state.credibility_upgrades, (item) => {
                credibility_upgrade_ids.push(item.value);
            });
        }

        if (!isEmpty(state.membership_plan_upgrades)) {
            forEach(state.membership_plan_upgrades, (item) => {
                membership_plan_upgrade_ids.push(item.value);
            });
        }

        details.credibility_upgrade_ids = credibility_upgrade_ids;
        details.membership_plan_upgrade_ids = membership_plan_upgrade_ids;

        apiCalls.updateMembershipPlan(details, props.membership_plan_id);

    }

    let select_label = props.type === 'membership_plan_upgrades' ? 'Select Membership Plan Upgrade' : 'Select Credibility Upgrade'

    return (
        <div>
            <Modal
                isOpen={is_open}
                toggle={() => hide()}
                className="tt-modal--admin modal-dialog-centered"
            >
                <ModalHeader toggle={() => hide()}>{props.title}</ModalHeader>
                <ModalBody>
                    {
                        loading['modal_loading'] ? <ListLoader />
                            :
                            <Form className="tt-admin-form">
                                <div className="tt-admin-form-inner p-0">
                                    <Row form>
                                        <Col xs="12" md="12">
                                            <FormGroup>
                                                <Label for="">{select_label}</Label>
                                                <div className="tt-custom-react-select">
                                                <CreatableSelect
                                                    className="react-select-container"
                                                    isMulti
                                                    options={upgrades_array}
                                                    onChange={(e) => multipleCreateSelect(e)}
                                                    value={state && state[props.type] ? state[props.type] : ''}
                                                    placeholder={select_label}
                                                    styles={styles}
                                                />
                                                </div>
                                                {/* <div className="invalid-feedback d-block">{form_errors.appointment_with}</div> */}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </div>
                            </Form>
                    }
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        className="btn-outline-grey"
                        onClick={() => hide()}
                        disabled={loading['button_loading'] || loading['modal_loading']}
                    >Cancel
                    </Button>
                    <Button
                        color="primary"
                        className="add-new-btn"
                        onClick={() => submit()}
                        disabled={loading['button_loading'] || loading['modal_loading']}
                    >
                        {loading['button_loading'] ?
                            <Spinner size="sm" /> : 'Submit'
                        }
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}
