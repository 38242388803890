import React from 'react';
import {ExperienceCard} from "js/components/legos/experienceCard";
import { Row, Col } from 'reactstrap';
import { isEmpty } from 'lodash';
import { LoadMoreComponent, ListLoader, AdCard } from 'js/components/common';
import { utilFunctions } from 'js/components/common/fucntions';

export const TripListing = ( props ) => {
    let { trips, onUpdate, disable_bookmark, onLoadMore, hide_load_mode, load_more_loading, enable_edit } = props;
    const onBookmarkSuccess = ( updated_trip) => {
        let updated_trips = trips.data.map(item => {
            if(item.trip_id === updated_trip.trip_id) {
                return updated_trip
            }
            else return item
        })
        onUpdate && onUpdate(updated_trips, updated_trip )
    }
    const onPageChange = ( page ) => {
        onLoadMore && onLoadMore(page);
    }
    return(
        <React.Fragment>
            <Row>
                { trips && trips.data && !isEmpty( trips.data ) && trips.data.map( ( trip, index ) => {
                    trip.trip_category = utilFunctions.findCategory( trip.trip_categories );
                    return(
                        <Col xs="12" className={props.class_name ? props.class_name : 'col-xs-12 col-md-4'}  key={index}>
                            {trip.type && trip.type === 'ad' ?
                            <AdCard
                                linkName={`${trip.external_link}`}
                                adImage={trip.attachment ?
                                    trip.attachment.thumbnail_url ?
                                    trip.attachment.thumbnail_url : trip.attachment.attachment_url
                                    :'/images/web/dummy/experience-card/1.png' }
                                adName={trip.title}
                                subTitle={trip.sub_title}
                                advertisement_id={trip.advertisement_id}
                            />
                            :
                            <ExperienceCard
                                linkName={`/trips/details/${trip.slug}`}
                                experienceCardImage={
                                    trip?.trip_media[0]?.attachment
                                    ? trip.trip_media[0].attachment.thumbnail_url
                                        ?? trip.trip_media[0].attachment.attachment_url
                                    : trip?.parent_trip_category?.icon_image_url
                                        ?? trip?.trip_category?.featured_image_url
                                        ?? '/images/web/dummy/experience-card/1.png'
                                }
                                experienceIcon={trip?.parent_trip_category?.icon_image_url ?? trip.trip_category?.icon_image_url}
                                experienceName={trip.title}
                                experienceLocation={`${ trip.city?.long_name }, ${ trip.state?.long_name }, ${trip.country?.short_name }`}
                                peopleCount={`${trip.accomodating_min_seats} - ${trip.accomodating_max_seats}`}
                                days={`${trip.duration_min_days} - ${trip.duration_max_days}`}
                                bookmark={trip.is_bookmarked}
                                onBookMarkToggle={(data) => onBookmarkSuccess( data )}
                                disable_bookmark={disable_bookmark}
                                trip_id={trip.trip_id}
                                slug={trip.slug}
                                enable_edit={enable_edit}
                                edit_link={`/trips/edit/${trip.slug}`}
                            />
                            }
                        </Col>
                    )
                })}
            </Row>
            { !hide_load_mode &&
                <React.Fragment>
                    { load_more_loading  ?
                        <ListLoader />
                    :
                        <LoadMoreComponent
                            page={trips.meta}
                            onChange={(page) => onPageChange(page)}
                        />
                    }
                </React.Fragment>
            }
        </React.Fragment>

    );
}
