import React, { useState, useEffect, useContext } from "react";
import { Row, Col, Input, Table, Form, FormGroup, CustomInput } from 'reactstrap';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { forEach, isEmpty } from 'lodash';
import moment from 'moment';

import { LookupContext } from 'js/contexts';
import { useParamChange, useModal } from 'js/hooks';
import { contestServices } from 'js/services';
import { PaginationComponent, ListLoader } from 'js/components/common';
import { history } from 'js/helpers';

const bulk_actions = [
    { value: 'delete', label: 'Delete' }
];

const styles = {
    option: (styles, state) => ({
        ...styles,
        backgroundColor: state.isSelected ? "#FFA405 " : state.isFocused ? "#FFA40520" : ""
    })
};
export const ContestsGiveaways = () => {

    const lookup = useContext(LookupContext);
    const [ contests, setConstants ] = useState({});
    const [ state, setState ] = useState({
        checked : [],
        check_all: false,
        loading: false
    });

    const getContests = (params) => {
        setState({...state, loading: true})
        contestServices.getAll(params)
        .then((response) => {
            setConstants(response);
            setState({...state, loading: false})
        }).catch((err) => {
        });
    }

    const [ show, hide ] = useModal();
    const [ params, onParamsChange ] = useParamChange( getContests );

    const remove = ( selected ) => {
        if( selected ){
            contestServices.remove(selected.slug).then(() => {
                getContests(params);
                hide('ConfirmModal');
            }).catch((err) => {
            })
        }
    }


    const checkbox = (id) => {
        let { checked } = state;
        if( id === 'all'){
            if( state.check_all ) {
                checked = [];
            }
            else{
                forEach(contests.data, function(item) {
                    checked[item.promotional_campaign_id] = true;
                });
            }
            setState( {...state, checked, check_all: !state.check_all } );
        }
        else{
            checked[id] ? delete checked[id] :  checked[id] = true;
            setState( {...state, checked} );
        }
    }


    const bulkAction = ( type ) => {
        if( type === 'delete') {
            let selected_items = Object.keys(state.checked);
            if(!isEmpty(selected_items)){
                show( 'ConfirmModal', {
                    title: 'Delete Contest / Giveaway ',
                    body: 'Do you want to delete Contest / Giveaway ?',
                    button_name: 'Delete',
                    action: () => bulkDelete(),
                });
            }
        }
    }

    const bulkDelete = () => {
        let data = {
            'action_type' : 'delete',
            'items' : Object.keys(state.checked)
        };
        contestServices.bulkAction(data)
        .then((response) => {
            hide('ConfirmModal');
            getContests(params);
        });
    }

    useEffect(() => {
        getContests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <div>
            <div className="generic-page-header">
                <div className="header-data-section">
                    <h2 className="page-head my-0">Contests & Giveaways</h2>
                </div>
                <div  className="header-data-section">
                    <Link to={"/admin/contest-giveaways/add-contest"} className="add-new-btn">
                        <i>
                            <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.03027 7.77295H0.171875V5.6001H5.03027V0.619629H7.22754V5.6001H12.0981V7.77295H7.22754V12.729H5.03027V7.77295Z" fill="#fff" />
                            </svg>
                        </i>Add a Contest
                    </Link>
                </div>
            </div>
            <div className="tt-admin-card">
                <div className="tt-admin-card--header tt-admin-card--header_filter">
                    <div className="tt-filter">
                        <Form>
                            <Row className="justify-content-between">
                                <Col xs="12" lg="5">
                                    <Row>
                                        <Col xs="12" lg="6">
                                            <FormGroup>
                                                <div className="custom-react-select--no-border w-100">
                                                    <Select
                                                        className="react-select-container"
                                                        classNamePrefix="react-select"
                                                        options={bulk_actions}
                                                        placeholder="Bulk Actions"
                                                        styles={styles}
                                                        onChange={ (e) => bulkAction(e.value) }
                                                    />
                                                </div>
                                            </FormGroup>

                                        </Col>
                                        <Col xs="12" lg="6">
                                            <FormGroup>
                                                <div className="filter-input--outer">
                                                    <Input
                                                        type="text"
                                                        placeholder="Search by Contest Name"
                                                        onChange={ (e) => onParamsChange('search', e.target.value )}
                                                    />
                                                </div>
                                            </FormGroup>
                                        </Col>
                                    </Row>


                                </Col>
                                <Col xs="12" lg="2">
                                    <Row>
                                        <Col xs="12">
                                            <FormGroup>
                                                <div className="custom-react-select--no-border w-100">
                                                    <Select
                                                        className="react-select-container"
                                                        classNamePrefix="react-select"
                                                        placeholder="Type"
                                                        styles={styles}
                                                        options={lookup.promotional_campaign_applicable_user_types}
                                                        onChange={ (e) => onParamsChange('applicable_user_type', e.slug )}
                                                    />
                                                </div>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </div>
                <div className="tt-admin-card--data">
                    <div className="table-wrap">
                        <Table responsive className="tt-admin-table-compact tt-admin-table-compact--checkbox ">
                            <thead>
                                <tr>
                                    <th>
                                        <div className="tt-admin-checkbox">
                                            <CustomInput
                                                type="checkbox"
                                                id="tt-check-1"
                                                label=""
                                                className="tt-checkbox"
                                                onChange={ () => checkbox('all') }
                                            />
                                        </div>
                                    </th>
                                    <th>Title</th>
                                    <th className="sortable text-center" onClick={() => onParamsChange('sort', 'applicable_user_type')} >Type</th>
                                    <th className="sortable text-center" onClick={() => onParamsChange('sort', 'scheduled_at')}  >Drawing Deadline</th>
                                    <th className="sortable text-center" onClick={() => onParamsChange('sort', 'value')}  >Value</th>
                                    <th className="text-center">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {state.loading ?
                                    <tr>
                                        <td colSpan="6" align="center"><ListLoader /></td>
                                    </tr>
                                    :
                                    contests && isEmpty(contests.data) ?
                                        <tr>
                                            <td colSpan="6" align="center">No Records Found.</td>
                                        </tr>
                                        :
                                        contests && contests.data && contests.data.map( (item, index) => (
                                        <tr key={index}>
                                            <td>
                                                <div className="tt-admin-checkbox">
                                                    <CustomInput
                                                        type="checkbox"
                                                        id={`checkbox${item.promotional_campaign_id}`}
                                                        label=""
                                                        className="tt-checkbox"
                                                        checked={state.checked[item.promotional_campaign_id] ? state.checked[item.promotional_campaign_id] : '' }
                                                        onChange={ () => checkbox(item.promotional_campaign_id) }
                                                    />
                                                </div>
                                            </td>
                                            <td
                                                style={{cursor:'pointer'}}
                                                onClick={() => history.push(`/admin/contest-giveaways/${item.slug}`)}

                                            >{item.title}</td>
                                            <td className="text-center">
                                                {item.promotional_campaign_applicable_user_type ?
                                                    item.promotional_campaign_applicable_user_type.label: '-'
                                                }</td>
                                            <td className="text-center">{item.scheduled_at ? moment(item.scheduled_at).format("MMMM DD, YYYY") : '' }</td>
                                            <td className="text-center">{item.value}</td>
                                            <td className="text-center">
                                                <div className="action-wrap">
                                                    <Link
                                                        to={{
                                                            pathname: `/contests-giveaways/details/${item.slug}`,
                                                            state: { from: 'admin' }
                                                        }}
                                                    >
                                                        <img src="/images/admin/global/view.svg" alt="" />
                                                    </Link>
                                                    <Link to={`/admin/contest-giveaways/${item.slug}`}><img src="/images/admin/global/edit.svg" alt="" /></Link>
                                                    <Link
                                                        to={"#"}
                                                        onClick={() =>
                                                            show( 'ConfirmModal', {
                                                                    title: 'Delete Contest / Giveaway ',
                                                                    body: `Do you want to delete ${item.title} ?`,
                                                                    button_name: 'Delete',
                                                                    action: () => remove( item )
                                                            })
                                                        }
                                                    >
                                                        <img src="/images/admin/global/delete.svg" alt="" />
                                                    </Link>
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </Table>
                    </div>
                </div>
                { contests && contests.meta &&
                    <PaginationComponent page={contests.meta} onChange={(page) => onParamsChange('page', page)} />
                }
            </div>
        </div>
    );
}
