import React, { useState, useEffect } from "react";
import { Table, CustomInput } from 'reactstrap';
import { Link } from 'react-router-dom';
import { isEmpty, forEach } from 'lodash';
import { toast } from "react-toastify";

import { supportEmailTemplateServices } from 'js/services';
import { useModal } from 'js/hooks';
import { ListLoader } from 'js/components/common';



export const SupportEmails = () => {

    const [state, setState] = useState({
        is_modal_open: false,
        loading: false
    });
    const [emails, setEmails] = useState([]);
    //    const [errors, setErrors] = useState({});
    const [activated_toggle, setActivatedToggle] = useState({});
    const [show] = useModal();


    const apiCalls = {
        getAll: () => {
            setState({ ...state, loading: true })
            supportEmailTemplateServices.getAll()
                .then((response) => {
                    let activated_toggle = {};
                    setEmails(response);
                    forEach(response.data, (item) => {
                        activated_toggle[item.support_email_template_id] =
                            item.is_active === 1 ? true : false;
                    });
                    setState({ ...state, loading: false })
                    setActivatedToggle(activated_toggle);
                }
                )
                .catch((err) => {
                    //    let errors = err.data && err.data.errors;
                    //    setErrors(errors);
                })
        },
        update: (details, support_email_id, value) => {
            supportEmailTemplateServices.update(details, support_email_id)
                .then(() => {
                    setActivatedToggle({
                        ...activated_toggle,
                        [support_email_id]: value
                    })
                })
                .catch(() => {
                    toast.error('Failed to Update')
                });
        }
    }

    useEffect(() => {
        apiCalls.getAll();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const statusToggle = (value, support_email_id) => {
        let details = value ? { is_active: 1 } : { is_active: 0 }
        apiCalls.update(details, support_email_id, value);
    }

    return (
        <div>
            <div className="generic-page-header">
                <div className="header-data-section">
                    <h2 className="page-head my-0">Support Emails</h2>
                </div>
            </div>
            <div className="tt-admin-card">
                <div className="tt-admin-card--data">
                    <div className="table-wrap">
                        {state.loading ?
                            <ListLoader />
                            :
                            emails && isEmpty(emails.data) ?
                                <div className="no-records-found">
                                    <p>No Records Found.</p>
                                </div>
                                :
                                <Table responsive className="tt-admin-table-compact tt-admin-table-compact--checkbox ">
                                    <thead>
                                        <tr>
                                            <th>Email Title</th>
                                            <th>Description</th>
                                            <th>Status</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            emails && !isEmpty(emails.data) &&
                                            emails.data.map((item, index) => (
                                                <tr key={'support_emial' + index}>
                                                    <td><b>{item.title ? item.title : ''}</b></td>
                                                    <td>{item.description ? item.description : ''}</td>
                                                    {
                                                        !isEmpty(activated_toggle) &&
                                                        <td>
                                                            <div className="ads-switch">
                                                                <CustomInput
                                                                    type="switch"
                                                                    id={`adSwitch${index}`}
                                                                    name="customSwitch"
                                                                    checked={activated_toggle[item.support_email_template_id] ? activated_toggle[item.support_email_template_id] : false}
                                                                    onChange={(e) => statusToggle(e.target.checked, item.support_email_template_id)}
                                                                />
                                                            </div>
                                                        </td>
                                                    }
                                                    <td>
                                                        <div className="action-wrap">
                                                            <Link
                                                                to={"#"}
                                                                onClick={() =>
                                                                    show('EditSupportEmailTemplate', {
                                                                        support_email_id: item.support_email_template_id,
                                                                        callBack: () => apiCalls.getAll()
                                                                    })
                                                                }
                                                            ><img src="/images/admin/global/edit.svg" alt="" /></Link>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </Table>
                        }
                    </div>
                </div>

            </div>
        </div>
    );
}
