import { request } from "js/helpers/api";

export const promotionServices = {
    getAll,
    create,
    update,
    remove,
    get,
    bulkAction
};

function getAll(params = {}) {
    return request({
        method: 'GET',
        url: 'promotions',
        param: params,
    });
}

function create(details, hideModal) {
    return request({
        method: 'POST',
        url: 'promotions',
        param: details,
        content_type: 'json',
        hideModal
    });
}

function update(details, promotion_id, hideModal) {
    return request({
        method: 'PUT',
        url: `promotions/${promotion_id}`,
        param: details,
        content_type: 'json',
        cancel_token: 'promotionServices.update',
        hideModal
    });
}

function get(promotion_id, hideModal) {
    return request({
        method: 'GET',
        url: `promotions/${promotion_id}`,
        param: {},
        hideModal
    });
}

function remove(promotion_id) {
    return request({
        method: 'DELETE',
        url: `promotions/${promotion_id}`,
        param: {},
    });
}

function bulkAction(details) {
    return request({
        method: 'POST',
        url: `promotions/bulk-action`,
        param: details,
        content_type: 'json',
    });
}
