import React, { useState} from "react";
import { Row, Col, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';

import { TripDetailsTab, TripDescriptionTab, TripPhotosTab } from 'js/components/admin/components/trip-management/details/components'
import { tripsServices } from "js/services";
import { history } from 'js/helpers';

export const AddTrip = () => {
    const [ active_tab, toggle ] = useState('details');
    const [ trip, setTrip ] = useState({});
    const [ errors, setErrors ] = useState(false);
    const [ state, setState ] = useState({
        loading: false,
    })
    const apiCalls = {

        addTrip: (details) => {
            setState({loading: true})
            tripsServices.create(details)
            .then(response => {
                setState({ loading: false});
                history.push('/admin/trip-management')
            }).catch(err => {
                setState({...state, loading: false})
                setErrors(err?.data?.errors)
            })
        },
    }

    const childChange = (data) => {
        const { details, next_tab} = data
        setTrip({...trip, ...details})
        setState({...state, [active_tab]: true, [next_tab]: true})
        toggle(next_tab)

    }
    const ontoggle = (tab) => {
        if(state[tab]) {
            toggle(tab)
        }
    }

    const handleSubmit = (attachments) => {
        let details = {
            ...trip,
            attachments
        }
        setTrip(details)
        apiCalls.addTrip(details)
    }

    return (
        <div>
            <div className="tt-admin-card-lead-create">
                <div className="tt-admin-card--data">
                    <Row className="no-gutters">
                        <Col xs="12">
                            <div className="trip-details-data-section">
                                <div className="nav-tabs-scroll">
                                    <Nav tabs className="tt-admin-tabs">
                                        <NavItem>
                                            <NavLink className={classnames({ active: active_tab === 'details' })} onClick={() => ontoggle('details')}>
                                                <div className="tab-label">Trip Details</div>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink className={classnames({ active: active_tab === 'description' })} onClick={() => ontoggle('description')} >
                                                <div className="tab-label">Trip Description</div>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink className={classnames({ active: active_tab === 'photos' })} onClick={() => ontoggle('photos')}>
                                                <div className="tab-label">Trip Photos</div>
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                </div>
                                <TabContent activeTab={active_tab}>
                                    <TabPane tabId="details">
                                        <TripDetailsTab
                                            onChange={(details) => childChange(details)}
                                            loading={state.loading}
                                            errors={errors}
                                        />
                                    </TabPane>
                                    <TabPane tabId="description">
                                        <TripDescriptionTab
                                            onChange={(details) => childChange(details)}
                                            loading={state.loading}
                                            errors={errors}
                                        />
                                    </TabPane>
                                    <TabPane tabId="photos">
                                        <TripPhotosTab
                                            onChange={(details) => handleSubmit(details)}
                                            loading={state.loading}
                                            errors={errors}
                                        />
                                    </TabPane>
                                </TabContent>

                            </div>
                        </Col>
                    </Row>
                </div>

            </div>
        </div>
    );

}
