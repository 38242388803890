import React from "react";
import { Row, Col } from 'reactstrap';
import { Helmet } from "react-helmet";


export const PrivacyPolicy = () => {
    return (
        <div>
            <Helmet>
                <title>Privacy Policy - Trips4Trade</title>
                <meta name="description" content="Privacy Policy - Trips4Trade" />
                <meta name="keywords" content="" />
            </Helmet>

            <div className="inner-banner" style={{backgroundImage: `url(images/web/global/banners/static-banner.png)`}}>
                <div className="container">
                    <Row>
                        <Col xs="12">
                            <h1 className="header2 color--white text-uppercase">Privacy Policy</h1>
                        </Col>

                    </Row>
                </div>
            </div>
            <div className="wider-data-frame wider-data-frame--grey">
                <div className="container">
                    <Row>
                        <Col>
                            <p className="p">
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consectetur amet veritatis mollitia dolor eaque totam velit fugiat eveniet provident suscipit voluptatum optio facilis vitae, quod ipsa reiciendis laborum distinctio natus et blanditiis itaque ea sint omnis. Beatae corporis delectus maxime omnis itaque iure repellat, iste suscipit, perferendis possimus sit aspernatur, harum saepe facilis culpa. Optio eveniet esse eligendi magni delectus. Natus a eaque nisi totam nesciunt. Harum, sed autem? Enim dolore temporibus facilis iste, ut repellat. Fugiat a obcaecati aliquid itaque maxime voluptas nostrum odit eaque nesciunt distinctio quod velit, illo, suscipit sed dolor asperiores. Architecto doloremque tempora obcaecati ea.
                            </p>
                            <p className="p">
                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consectetur amet veritatis mollitia dolor eaque totam velit fugiat eveniet provident suscipit voluptatum optio facilis vitae, quod ipsa reiciendis laborum distinctio natus et blanditiis itaque ea sint omnis. Beatae corporis delectus maxime omnis itaque iure repellat, iste suscipit, perferendis possimus sit aspernatur.
                            </p>
                            <p className="p">
                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consectetur amet veritatis mollitia dolor eaque totam velit fugiat eveniet provident suscipit voluptatum optio facilis vitae, quod ipsa reiciendis laborum distinctio natus et blanditiis itaque ea sint omnis. Beatae corporis delectus maxime omnis itaque iure repellat, iste suscipit, perferendis possimus sit aspernatur, harum saepe facilis culpa. Optio eveniet esse eligendi magni delectus. Natus a eaque nisi totam nesciunt. Harum, sed autem? Enim dolore temporibus facilis iste, ut repellat. Fugiat a obcaecati aliquid itaque maxime voluptas nostrum odit eaque nesciunt distinctio quod velit, illo, suscipit sed dolor asperiores. Architecto doloremque tempora obcaecati ea.
                            </p>
                            <p className="p">
                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consectetur amet veritatis mollitia dolor eaque totam velit fugiat eveniet provident suscipit voluptatum optio facilis vitae, quod ipsa reiciendis laborum distinctio natus et blanditiis itaque ea sint omnis. Beatae corporis delectus maxime omnis itaque iure repellat, iste suscipit, perferendis possimus sit aspernatur, harum saepe facilis culpa. Optio eveniet esse eligendi magni delectus. Natus a eaque nisi totam nesciunt. Harum, sed autem? Enim dolore temporibus facilis iste, ut repellat. Fugiat a obcaecati aliquid itaque maxime voluptas nostrum odit eaque nesciunt distinctio quod velit, illo, suscipit sed dolor asperiores. Architecto doloremque tempora obcaecati ea  sed autem? Enim dolore temporibus facilis iste, ut repellat. Fugiat a obcaecati aliquid itaque maxime voluptas nostrum odit eaque nesciunt distinctio quod velit, illo, suscipit sed dolor asperiores.
                            </p>

                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
}
