import React from "react";
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';


export class StaticContent extends React.Component {

    render() {
        return (
            <div>
                <div className="generic-page-header">
                    <div className="header-data-section">
                        <h2 className="page-head my-0">Static Content</h2>
                    </div>

                    <div className="header-data-section">


                    </div>
                </div>
                <div className="tt-admin-card no-bg no-border no-shadow">
                    <div className="tt-admin-card--data">
                        <Row>
                            <Col xs="12" md="3">
                                <Link className="static-content-card" to={"/admin/static-content/testimonials"}>
                                     <div>
                                        <img src="/images/admin/static-content/testimonials.svg" alt="" />
                                        <h4>Testimonials</h4>
                                     </div>
                                </Link>
                            </Col>
                            <Col xs="12" md="3">
                                <Link className="static-content-card" to={"/admin/static-content/partner-logos"}>
                                    <div>
                                        <img src="/images/admin/static-content/partner-logos.svg" alt="" />
                                        <h4>Partner Logos</h4>
                                    </div>
                                </Link>
                            </Col>
                            <Col xs="12" md="3">
                                <Link className="static-content-card" to={"/admin/static-content/experience"}>
                                    <div>
                                        <img src="/images/admin/static-content/experiences.svg" alt="" />
                                        <h4>Blog</h4>
                                    </div>
                                </Link>
                            </Col>
                            <Col xs="12" md="3">
                                <Link className="static-content-card" to={"/admin/static-content/about-us"}>
                                    <div>
                                        <img src="/images/admin/static-content/about-us.svg" alt="" />
                                        <h4>About Us</h4>
                                    </div>
                                </Link>
                            </Col>
                            <Col xs="12" md="3">
                                <Link className="static-content-card" to={"/admin/static-content/how-it-works"}>
                                    <div>
                                        <img src="/images/admin/static-content/how-it-works.svg" alt="" />
                                        <h4>How it Works</h4>
                                    </div>
                                </Link>
                            </Col>
                            <Col xs="12" md="3">
                                <Link className="static-content-card" to={"/admin/static-content/terms-conditions"}>
                                    <div>
                                        <img src="/images/admin/static-content/terms-conditions.svg" alt="" />
                                        <h4>Terms & Conditions</h4>
                                    </div>
                                </Link>
                            </Col>
                            <Col xs="12" md="3">
                                <Link className="static-content-card" to={"/admin/static-content/footer-menu"}>
                                    <div>
                                        <img src="/images/admin/static-content/footer-menu.svg" alt="" />
                                        <h4>Footer Menu</h4>
                                    </div>
                                </Link>
                            </Col>

                        </Row>
                    </div>

                </div>
            </div>


        );
    }

}
