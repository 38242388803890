import React from "react"
import { Link } from 'react-router-dom';
import "js/components/legos/styles/blog-card-module.css"

export default class BlogCard extends React.Component {
    render() {
        const props = this.props;
       
        return (
            <div>
                <Link to={props.blogLink} className="blog-card" >
                    <div className="blog-card-top">
                        <div className="date">
                            <i>
                                <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M12.05 0.75C12.9575 0.75 13.7 1.4925 13.7 2.4C15.515 2.4 17 3.885 17 5.7V13.95C17 15.765 15.515 17.25 13.7 17.25H3.8C1.985 17.25 0.5 15.765 0.5 13.95V5.7C0.5 3.885 1.985 2.4 3.8 2.4C3.8 1.4925 4.5425 0.75 5.45 0.75C6.3575 0.75 7.1 1.4925 7.1 2.4H10.4C10.4 1.4925 11.1425 0.75 12.05 0.75ZM14.525 15.6C15.02 15.6 15.35 15.27 15.35 14.775V8.175C15.35 7.68 15.02 7.35 14.525 7.35H2.975C2.48 7.35 2.15 7.68 2.15 8.175V14.775C2.15 15.27 2.48 15.6 2.975 15.6H14.525Z" fill="#FFA405" />
                                </svg>
                            </i>
                            {props.blogDate}</div>
                        <div className="read-time">
                            <i>
                                <svg width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M5.98091 0.498413H11.9618V2.49205H5.98091V0.498413ZM7.97455 13.457V7.47614H9.96818V13.457H7.97455ZM15.9491 6.87805L17.4443 5.4825C16.9459 4.98409 16.4475 4.48569 15.9491 4.08696L14.5535 5.4825C13.0583 4.28632 11.0647 3.48887 8.97136 3.48887C3.98727 3.48887 0 7.47614 0 12.4602C0 17.4443 3.98727 21.4316 8.97136 21.4316C13.9555 21.4316 17.9427 17.4443 17.9427 12.4602C17.9427 10.3669 17.245 8.37328 15.9491 6.87805ZM1.99364 12.4602C1.99364 16.3478 5.08377 19.438 8.97136 19.438C12.859 19.438 15.9491 16.3478 15.9491 12.4602C15.9491 8.57264 12.859 5.4825 8.97136 5.4825C5.08377 5.4825 1.99364 8.57264 1.99364 12.4602Z" fill="#FFA405" />
                                </svg>
                            </i>
                            {props.blogTimer}</div>
                    </div>
                    <div className="blog-card-bottom">
                        <h3>{props.blogTitle}</h3>

                        <p>{props.blogText}</p>
                    </div>
                    <img className="blog-card--img" src={props.blogImage ? props.blogImage : ''} alt="" />
                </Link>
            </div>
        )
    }
}