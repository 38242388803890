import React from "react";
import { Table } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useContext } from "react";
import { isEmpty, forEach } from 'lodash';
import { LookupContext } from 'js/contexts';
import { useModal } from 'js/hooks';

export const Settings = () => {
    const [ show ] = useModal();
    const lookups = useContext(LookupContext);

    return (
        <div>
            <div className="generic-page-header">
                <div className="header-data-section">
                    <h2 className="page-head my-0">Settings</h2>
                </div>
                {/* <div className="header-data-section">
                    <Link
                        color="primary"
                        className="add-new-btn"
                        to={'#'}
                        onClick={() =>
                            show('SettingModal', {
                                modal_header: 'Add setting',
                                modal_footer: 'Submit',
                            })
                        }
                    >
                        <i>
                            <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.03027 7.77295H0.171875V5.6001H5.03027V0.619629H7.22754V5.6001H12.0981V7.77295H7.22754V12.729H5.03027V7.77295Z" fill="#fff" />
                            </svg>
                        </i>Add new setting
                    </Link>
                </div> */}
            </div>
            <div className="tt-admin-card">
                <div className="tt-admin-card--data">
                    <div className="table-wrap">

                        {lookups.settings && isEmpty(lookups.settings) ?
                        <div className="no-records-found">
                            <p>No Records Found.</p>
                        </div>
                        :
                        <Table responsive className="tt-admin-table-compact tt-admin-table-compact--checkbox ">
                            <thead>
                                <tr>

                                    <th>Setting</th>
                                    <th>Content</th>
                                    <th>Actions</th>

                                </tr>
                            </thead>
                            <tbody>
                                {lookups.settings.map((setting, index) => {
                                    let content = ''
                                    forEach(setting.content, value => {
                                        content = value
                                    })
                                    return(
                                        <tr key={index}>
                                        <td>{setting.label}</td>
                                        <td>{content}</td>
                                        <td>
                                            <div className="action-wrap">
                                                <Link
                                                    to={`#`}
                                                    onClick={() =>
                                                        show('SettingModal', {
                                                            modal_header: 'Edit setting',
                                                            modal_footer: ' Save changes',
                                                            setting_slug: setting.slug
                                                        })
                                                    }
                                                >
                                                    <img src="/images/admin/global/edit.svg" alt="" />
                                                </Link>
                                                <Link
                                                    to={"#"}
                                                    onClick={() =>
                                                        show('ConfirmModal', {
                                                                body: `Do you want to delete ${setting.label}?`,
                                                            }
                                                        )
                                                    }>
                                                    <img src="/images/admin/global/delete.svg" alt="" />
                                                </Link>
                                            </div>
                                        </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}
