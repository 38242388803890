import React, { useState, useEffect, useContext } from 'react';
import { Row, Col, Progress, Form, FormGroup, CustomInput, Button, Spinner } from 'reactstrap';
import classNames from 'classnames';
import { Helmet } from "react-helmet";
import queryString from 'query-string';
import { toast } from 'react-toastify';
import { forEach, isEmpty, find, pick } from 'lodash';

import { NavigationSidebar } from "js/components/web/components/layouts/components/navigation-sidebar";
import { Verification } from './components';
import { AuthProvider } from 'js/contexts';
import { RelatedExperience, ErrorFormFeedback, ListLoader } from 'js/components/common';
import { profileServices, authServices, membershipPlanService, userServices } from 'js/services';
import { history } from 'js/helpers'

export const CredibilityAndAccountUpgrades = (props) => {

    const [ credibility_upgrades, setCredibilty] = useState([]);
    const [ membership_upgrades, setMembership ] = useState([])
    const { auth, setAuth } = useContext(AuthProvider);
    const [ errors, setErrors ] = useState({});
    const [ verification_completed, setVerification_completed ] = useState(0);
    const [ loading, setLoading ] = useState({plans: false , checkout: false})

    const apiCalls = {
        verifyEmail: (params) => {
            profileServices.verifyEmail(auth.user.user_id, params)
            .then(response => {
                toast.success('Email verified');
                apiCalls.getProfile();
            })
        },
        getCredibiltyUpgrades: () => {

            membershipPlanService.getAllCredibilityUpgrades()
                .then(response => {
                    filterData(response, 'credibility')
                })
        },
        getMembershipUpgrades: () => {
            membershipPlanService.getAllMembershipPlanUpgrades()
                .then(response => {
                    filterData(response, 'membership')
                })
        },
        upgradePlans: (details) => {
            setLoading({...loading, checkout: true});
            userServices.selectPlan(details, auth.user.user_id)
            .then(res => {
                toast.success('Credibilty and membership are upgraded');
                apiCalls.getProfile('plans');
                setLoading({...loading, checkout: false});
                history.push('/my-profile')
            }).catch(err => {
                err && err.data && err.data.errors && setErrors(err.data.errors);
                setLoading({...loading, checkout: false});
            })
        },
        getProfile: (key) => {
            setLoading({...loading, [key]: true})
            authServices.getProfile()
            .then(res => {
                setAuth({ ...auth, user: { ...res } });
                filterData(credibility_upgrades, 'credibility')
                filterData(membership_upgrades, 'membership')

                if(!isEmpty(res)){
                    verificationFinder({ ...res });
                }
            })
        }
    }
    useEffect(() => {
        if (props.location && props.location.search) {
            let params = queryString.parse(props.location.search)
            apiCalls.verifyEmail(params)
        }
        apiCalls.getMembershipUpgrades();
        apiCalls.getCredibiltyUpgrades();
        setLoading({...loading, plans: true})

        if(!isEmpty(auth) && !isEmpty(auth.user)){
            verificationFinder(auth.user);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const verificationFinder = (user) => {
        if(!isEmpty(user)){
            let verification_completed_array = [];
            let selected_item = ['background_check_status', 'is_email_verified',
                'is_phone_number_verified', 'user_document_verification'];
            let data = pick(user, selected_item);
            forEach(data, (value, key) => {
                if(value){
                    verification_completed_array.push(key);
                }
            })
            setVerification_completed(verification_completed_array.length)
        }
    }

    const handleSelect = (key, id, value) => {
        if(key === 'membership') {
            let data = membership_upgrades.map(item => {
                if(item.membership_plan_upgrade_id === id) {
                    item.is_checked = value
                }

                return item
            })
            setMembership(data)
        }
        else {
            let data = credibility_upgrades.map(item => {
                if(item.credibility_upgrade_id === id) {
                    item.is_checked = value
                }

                return item
            })
            setCredibilty(data)
        }
    }
    const handleSubmit = () => {
        let membership_plan_upgrade_ids = membership_upgrades
        .filter(item => item.is_checked)
        .map(item => item.membership_plan_upgrade_id);
        let credibility_upgrade_ids = credibility_upgrades
        .filter(item => item.is_checked)
        .map(item => item.credibility_upgrade_id);
        let details = {
            membership_plan_upgrade_ids,
            credibility_upgrade_ids,
            action_type: 'upgrade'
        }
        apiCalls.upgradePlans(details)
    }

    function filterData (data, type) {
        if(type === 'membership') {
            let membership_data = [];
            forEach(data, membership => {
                if(!find(auth.user.user_membership_plan_upgrades,
                    ['membership_plan_upgrade_id', membership.membership_plan_upgrade_id]
                )) {
                    membership_data.push(membership)
                }
            })
            setMembership(membership_data)
        }
        if(type === 'credibility') {
            let credibilty_data = [];
            forEach(data, credibility => {

                if(!find(auth.user.user_credibility_upgrades,
                    ['credibility_upgrade_id', credibility.credibility_upgrade_id]
                )) {
                    credibilty_data.push(credibility)
                }
            })
            setCredibilty(credibilty_data)
            setLoading({...loading, plans: false})
        }
    }

    return (
        <div>
            <Helmet>
                <title>Trip4Trade :: Credibility and Account Upgrades</title>
                <meta name="description" content=" My Listings page desription" />
                <meta name="keywords" content="trip4trade,  My Listings" />
            </Helmet>
            <div className="inner-banner-profile" style={{ backgroundImage: `url(/images/web/global/banners/profile-banner.png)` }}>

            </div>
            <div className="wider-data-frame wider-data-frame--grey pt-0">
                <div className="container">
                    <div className="profile-outer-wrapper">
                        <Row className="no-gutters">
                            <Col xs="12" lg="9">
                                <div className="profile-data-section">
                                    <div className="profile-data-section--top">
                                        <Row>
                                            <Col xs="12" className="pb-4">
                                                <h3><img src="/images/web/global/side-navigation/credibility.svg" alt="" style={{ width: `30px` }} /> Credibility & Account Upgrades</h3>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className="profile-data-section--bottom">
                                        <Row>
                                            <Col xs="12">
                                                <div className="progress-wrap">
                                                    <div className="progress-count"><b>{verification_completed * 25}%</b> Completed</div>
                                                    {/* TODO_HERE */}
                                                    <Progress
                                                        className="tt-progress"
                                                        value={verification_completed * 25}
                                                        max="100"
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Verification
                                            callBack={() => apiCalls.getProfile()}
                                        />
                                        {loading.plans ?
                                        <ListLoader/>
                                        :
                                        <Form className="tt-membership-form">
                                            {!isEmpty(membership_upgrades) &&
                                            <Row>
                                                <Col className="credibility-sub-head">
                                                    <h4 className="header4">Membership Upgrades <span>(Optional)</span></h4>
                                                </Col>
                                            </Row>
                                            }
                                            <Row>
                                                <Col>
                                                {membership_upgrades.map((item, index) => (
                                                    <div className="membership-outer" key={index}>
                                                        <FormGroup>
                                                            <Row>
                                                                <Col>
                                                                    <CustomInput
                                                                        type="checkbox"
                                                                        id={`membership_${item.membership_plan_upgrade_id}`}
                                                                        label={item.label}
                                                                        onChange={(e) => handleSelect('membership', item.membership_plan_upgrade_id, e.target.checked)}
                                                                        className={classNames("", { "selected": item.is_checked })}
                                                                        checked={item.is_checked ? item.is_checked: false}
                                                                    />
                                                                    <p className="small-text">{item.description}</p>
                                                                </Col>
                                                                <Col className="d-flex justify-content-end align-items-center">
                                                                    <div className={classNames("membership-amount", { "selected": item.is_checked })} >{`$${item.price}`}</div>
                                                                </Col>
                                                            </Row>
                                                        </FormGroup>
                                                    </div>
                                                ))}
                                                </Col>
                                            </Row>
                                            {!isEmpty(credibility_upgrades) &&
                                            <Row>
                                                <Col className="credibility-sub-head">
                                                    <h4 className="header4">Credibility Upgrades <span>(Optional)</span></h4>
                                                </Col>
                                            </Row>
                                            }
                                            <Row>
                                                <Col>
                                                {credibility_upgrades.map((item, index) => (
                                                    <div className="membership-outer" key={index}>
                                                        <FormGroup>
                                                            <Row>
                                                                <Col>
                                                                    <CustomInput
                                                                        type="checkbox"
                                                                        id={`credibility_${item.credibility_upgrade_id}`}
                                                                        label={item.label}
                                                                        className={classNames("", { "selected": item.is_checked })}
                                                                        checked={item.is_checked ? item.is_checked: false}
                                                                        onChange={(e) => handleSelect('credibility', item.credibility_upgrade_id, e.target.checked)}
                                                                    />
                                                                    <p className="small-text">{item.description}</p>
                                                                </Col>
                                                                <Col className="d-flex justify-content-end align-items-center">
                                                                    <div className={classNames("membership-amount", { "selected": item.is_checked })} >{`$${item.price}`}</div>
                                                                </Col>
                                                            </Row>
                                                        </FormGroup>
                                                    </div>
                                                ))}
                                                </Col>
                                            </Row>
                                            <ErrorFormFeedback field={'membership_plan_upgrade_ids'} errors={errors} is_invalid={true}/>
                                            <ErrorFormFeedback field={'credibility_upgrade_ids'} errors={errors} is_invalid={true}/>
                                            <ErrorFormFeedback field={'calculation_error'} errors={errors} is_invalid={true}/>
                                            <ErrorFormFeedback field={'general'} errors={errors} is_invalid={true}/>
                                            {(!isEmpty(credibility_upgrades) || !isEmpty(membership_upgrades)) &&
                                            <Row>
                                                <Col className="d-flex justify-content-center py-5">
                                                    <Button
                                                        className="btn btn-outline text-uppercase"
                                                        disabled={loading.checkout}
                                                        onClick={handleSubmit}>
                                                        {loading.checkout ? <Spinner size="sm"/> : 'Proceed to checkout' }
                                                    </Button>
                                                </Col>
                                            </Row>
                                            }
                                        </Form>
                                        }
                                    </div>
                                </div>
                            </Col>
                            <NavigationSidebar />
                        </Row>
                    </div>
                </div>
            </div>
            <RelatedExperience user_id={auth.user.usre_id}/>
        </div>

    );

}

