import React from "react";
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { Link } from 'react-router-dom';
import {
    FacebookShareButton,
    FacebookIcon,
    TwitterShareButton,
    TwitterIcon,
    LinkedinShareButton,
    LinkedinIcon,
    PinterestShareButton,
    PinterestIcon,
    RedditShareButton,
    RedditIcon,
    WhatsappShareButton,
    WhatsappIcon,
    EmailShareButton,
    EmailIcon
} from "react-share";

export const ShareModal = ( props ) => {
    const { is_open, hide } = props;

    return (
        <div>
            <Modal
                isOpen={is_open}
                toggle={() => hide()}
                className="tt-modal--admin modal-dialog-centered"
            >
                <ModalHeader toggle={() => hide()}>Share</ModalHeader>
                <ModalBody>
                    <div className="blog-share--icons">
                        <Link to={"#"}><FacebookShareButton url={window.location.href} >  <FacebookIcon size={31} round /> </FacebookShareButton></Link>
                        <Link to={"#"}><TwitterShareButton url={window.location.href} >  <TwitterIcon size={31} round /> </TwitterShareButton></Link>
                        <Link to={"#"}><LinkedinShareButton url={window.location.href} >  <LinkedinIcon size={31} round /> </LinkedinShareButton></Link>
                        <Link to={"#"}><PinterestShareButton url={window.location.href} media={window.location.href}>  <PinterestIcon size={31} round /> </PinterestShareButton></Link>
                        <Link to={"#"}><RedditShareButton url={window.location.href} >  <RedditIcon size={31} round /> </RedditShareButton></Link>
                        <Link to={"#"}><WhatsappShareButton url={window.location.href} >  <WhatsappIcon size={31} round /> </WhatsappShareButton></Link>
                        <Link to={"#"}><EmailShareButton url={window.location.href} >  <EmailIcon size={31} round /> </EmailShareButton></Link>
                        {/* <Link to={"#"}>
                            <img src="/images/web/blog/social/sh.png" alt="" />
                            <span>
                                <h4>41M</h4>
                                <small>Shares</small>
                            </span>
                        </Link> */}
                    </div>
                </ModalBody>
            </Modal>
        </div>
    )
}
