import React from "react";
import { Row, Col, Label, Input, FormGroup } from 'reactstrap';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { useState, useContext, useEffect } from "react";
import DatePicker from 'react-datepicker';
import { isEmpty, forEach, omit, find, isEqual } from 'lodash';
import Geosuggest from 'react-geosuggest';
import moment from "moment";

import { LookupContext } from 'js/contexts';
import { ErrorFormFeedback, TreeContainer } from 'js/components/common';
const styles = {
    option: (styles, state) => ({
        ...styles,
        backgroundColor: state.isSelected ? "#FFA405 " : state.isFocused ? "#FFA40520" : ""
    })
};
export const TripDetails = (props) => {
    const [trip, setTrip] = useState({});

    const lookups = useContext(LookupContext);
    const form_errors = props.errors


    const handleChange = (key, value) => {
        let data = {
            ...trip
        }
        if(key === 'country') {
            data.location = '';
            data.city = '';
        }
        data[key] = value
        setTrip(data)
    }

    const handleAddressChange = (place) => {
        let state = {};
        let city = {};
        let country = {};
        let latitude = '';
        let longitude = '';
        if (!isEmpty(place)) {
            if (place.gmaps && place.gmaps.address_components) {
                forEach(place.gmaps.address_components, (value, key) => {
                    value.types && forEach(value.types, type => {
                        let is_state = false
                        let is_city = false
                        if (type === "administrative_area_level_1") {
                            state = { ...omit(value, 'types') }
                            is_state = true
                        }
                        if (type === "administrative_area_level_2" && !is_state) {
                            state = { ...omit(value, 'types') }
                            is_state = true
                        }
                        if (type === "locality") {
                            city = { ...omit(value, 'types') }
                            is_city = true
                            if(!(is_state)) {
                                state = {...omit(value, 'types') }
                            }
                        }
                        if(type === 'sublocality_level_1' && !is_city) {
                            is_city = true
                            city = {...omit(value, 'types') }

                        }
                        if( type === "country" ){
                            country = { ...omit(value, 'types') }
                        }
                        if(type === 'route' && isEqual(state, city)){
                            city = { ...omit(value, 'types') }
                        }
                    })
                })
            }
            if( country.short_name ){
                country = find(lookups.countries, ['short_name',  country.short_name] );
            }
            if (place.location) {
                latitude = place.location.lat
                longitude = place.location.lng
            }

            let new_trip = {
                ...trip,
                state,
                city,
                latitude,
                longitude,
                country,
                location: place && place.description ? place.description : ''
            }
            setTrip(new_trip)
        }
    }
    const onTreeChange = (selectedNodes) => {
        setTrip({...trip, trip_categories: selectedNodes})
    }

    useEffect(() => {
        props.onUpdate(trip);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ trip ] )
    useEffect( () => {
        setTrip(props.trip)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    return (

            <div className="tt-admin-form-inner">
                <Row form>
                    <Col xs="12">
                        <FormGroup>
                            <Label for="">Trip Title * </Label>
                            <Input
                                type="text"
                                value={trip.title || ''}
                                onChange={(e) => handleChange('title', e.target.value)}
                                invalid={form_errors.title && true}
                            />
                            <ErrorFormFeedback field="title" errors={form_errors} />
                        </FormGroup>
                    </Col>
                </Row>
                <Row form>
                    <Col xs="12" md="4">
                        <FormGroup>
                            <Label for="">Country * </Label>
                            <div className="tt-custom-react-select">
                                <Select
                                    className="react-select-container"
                                    classNamePrefix="react-select"
                                    options={lookups.countries}
                                    styles={styles}
                                    onChange={(e) => handleChange('country', e)}
                                    value={trip.country}
                                />
                                {form_errors &&
                                <div className="invalid-feedback d-block">{form_errors['country']}</div>
                                }
                            </div>
                        </FormGroup>
                    </Col>
                    <Col xs="12" md="8">
                        <FormGroup>
                            <Label for="">Closest Town/City * </Label>
                            <div className="tt-custom-react-select">
                                <Geosuggest
                                    types={['(cities)']}
                                    name="address"
                                    className="react-select-container geosuggest-tc"
                                    inputClassName={"form-control"}
                                    placeholder=""
                                    initialValue={trip.location ?
                                        trip.location :
                                        `${trip.city && trip.city.long_name ?
                                        trip.city.long_name : ''}${trip.city && trip.city.long_name && trip.state ?
                                        `, ${trip.state.long_name}` : ''
                                        }`}
                                    value={trip.location}
                                    autoComplete="nope"
                                    onSuggestSelect={handleAddressChange}
                                    country={trip.country ? trip.country.short_name : ''}
                                    onChange={(e) => handleChange('city', e)}
                                />
                                {form_errors &&
                                <div className="invalid-feedback d-block">{form_errors['city']}</div>
                                }
                            </div>
                        </FormGroup>
                    </Col>
                </Row>
                <Row form>
                    <Col xs="12" md="4">
                        <FormGroup>
                            <Label for="">Accommodates *</Label>
                            <Row>
                                <Col xs="6">
                                    <FormGroup>
                                        <div className="tt-custom-react-select">
                                            <Input
                                                placeholder="Min seats"
                                                type="text"
                                                value={trip.accomodating_min_seats || ''}
                                                onChange={(e) => handleChange('accomodating_min_seats', e.target.value)}
                                                invalid={form_errors && form_errors.duration_max_days && true}
                                            />
                                            <ErrorFormFeedback field="accomodating_min_seats" errors={form_errors} />
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col xs="6">
                                    <FormGroup>
                                        <div className="tt-custom-react-select">
                                            <Input
                                                placeholder="Max seats"
                                                type="text"
                                                value={trip.accomodating_max_seats || ''}
                                                onChange={(e) => handleChange('accomodating_max_seats', e.target.value)}
                                                invalid={form_errors && form_errors.accomodating_max_seats && true}
                                            />
                                            <ErrorFormFeedback field="accomodating_max_seats" errors={form_errors} />
                                        </div>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </FormGroup>
                    </Col>
                    <Col xs="12" md="6" lg="4">
                        <FormGroup>
                            <Label for="">Trip Date Range *</Label>
                            {/* <div className="custom-react-select">
                                <DateRangePicker
                                    onChange={(e) => handleChange('date', e)}
                                    value={trip.date}
                                    minDate={new Date()}
                                />
                                {form_errors &&
                                <div className="invalid-feedback d-block">{form_errors['date']}</div>
                                }
                            </div> */}
                            <Row>
                                <Col xs="12" md="6">
                                    <div className="custom-react-select custom-month-picker">
                                        <DatePicker
                                            placeholderText="Start date"
                                            selected={trip.trip_start_date}
                                            onChange={(e) => handleChange('trip_start_date', e)}
                                            selectsStart
                                            startDate={trip.trip_start_date}
                                            endDate={trip.trip_end_date}
                                            dateFormat="MM/yyyy"
                                            showMonthYearPicker
                                            minDate={moment().subtract(1, 'months').endOf('month').toDate()}
                                            className='tt-month-range-picker-edit'
                                        />
                                        <ErrorFormFeedback field="trip_start_date" errors={form_errors} is_invalid/>
                                    </div>
                                </Col>
                                <Col xs="12" md="6">
                                    <div className="custom-react-select custom-month-picker">
                                        <DatePicker
                                            placeholderText="End date"
                                            selected={trip.trip_end_date}
                                            onChange={(e) => handleChange('trip_end_date', e)}
                                            selectsEnd
                                            startDate={trip.trip_start_date}
                                            endDate={trip.trip_end_date}
                                            dateFormat="MM/yyyy"
                                            showMonthYearPicker
                                            minDate={trip.trip_start_date}
                                            className='tt-month-range-picker-edit'
                                        />
                                        <ErrorFormFeedback field="trip_end_date" errors={form_errors} is_invalid/>
                                    </div>
                                </Col>
                            </Row>
                        </FormGroup>
                    </Col>
                    <Col xs="12" md="4">
                        <FormGroup>
                            <Label for="">Trip Duration *</Label>
                            <Row>
                                {trip.trip_duration ? (
                                    <Col xs="6">
                                        <FormGroup>
                                            <div className="tt-custom-react-select">
                                                <Input
                                                    placeholder="Trip Duration"
                                                    type="text"
                                                    value={trip.trip_duration || ''}
                                                />
                                            </div>
                                        </FormGroup>
                                    </Col>) : (
                                    <>
                                    <Col xs="6">
                                        <FormGroup>
                                            <div className="tt-custom-react-select">
                                                <Input
                                                    placeholder="Min Duration"
                                                    type="text"
                                                    value={trip.duration_min_days || ''}
                                                    onChange={(e) => handleChange('duration_min_days', e.target.value)}
                                                    invalid={form_errors && form_errors.duration_min_days && true}
                                                />
                                                <ErrorFormFeedback field="duration_min_days" errors={form_errors} />
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    <Col xs="6">
                                        <FormGroup>
                                            <div className="tt-custom-react-select">
                                                <Input
                                                    className="max-duration"
                                                    placeholder="Max Duration"
                                                    type="text"
                                                    value={trip.duration_max_days || ''}
                                                    onChange={(e) => handleChange('duration_max_days', e.target.value)}
                                                    invalid={form_errors && form_errors.duration_max_days && true}
                                                />
                                                <ErrorFormFeedback field="duration_max_days" errors={form_errors} />
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    </>
                                )}
                            </Row>
                        </FormGroup>
                    </Col>
                </Row>
                <Row form>
                    <Col xs="6">
                        <FormGroup>
                            <Label for="">Trip Category * </Label>
                            <div className="tt-custom-react-select">
                                {/* <Select
                                    className="react-select-container"
                                    classNamePrefix="react-select"
                                    options={lookups.trip_categories}
                                    styles={styles}
                                    onChange={(e) => handleChange('trip_category', e)}
                                    value={trip.trip_category}
                            /> */}
                            <TreeContainer
                                data={ lookups.trip_categories }
                                onChange={onTreeChange}
                                mode="multiSelect"
                                className="tt-tree-container"
                                selectedCategories={props.trip.trip_categories}
                                placeholder="Trip Category"
                            />
                                {form_errors &&
                                <div className="invalid-feedback d-block">{form_errors['trip_categories']}</div>
                                }
                            </div>
                        </FormGroup>
                    </Col>
                    <Col xs="6">
                        <FormGroup>
                            <Label for="">Features *</Label>
                            <div className="tt-custom-react-select">
                                <CreatableSelect
                                    className="react-select-multi-container"
                                    classNamePrefix="react-select"
                                    isMulti
                                    styles={styles}
                                    onChange={(e) => handleChange('feature_tags', e)}
                                    value={trip.feature_tags}
                                    options={lookups.feature_tags}
                                />
                                {form_errors &&
                                <div className="invalid-feedback d-block">{form_errors['feature_tags']}</div>
                                }
                            </div>
                        </FormGroup>
                    </Col>
                </Row>
            </div>

    );
}
