import React from "react"
import { Link } from 'react-router-dom';
import "js/components/legos/styles/hero-card-module.css"
export default props => (
	<div className="hero-card" >
		<Link to={props.linkName} >
			<img className="hero-card--img" src={props.cardImage} alt="" />
			<div className="hero-card--text">
				<img src={props.cardIcon}  alt={props.tripName} />
				<h3>{props.tripName}</h3>
			</div>
		</Link>
	</div>
)