import React from 'react';
import {
    Modal,
    ModalHeader,
    ModalBody,
    Button,
} from 'reactstrap';

export const GeneralInfoModal = (props) => {
    const { is_open, hide,  title, body, button_label } = props;
    const closeModal = () => {
        hide()
    }
    return (
        <div>
            <Modal
                isOpen={is_open}
                toggle={() => hide()}
                className="tt-modal--admin modal-dialog-centered"
            >
                <ModalHeader toggle={() => hide()}>{title}</ModalHeader>
                <ModalBody>
                    <p>{body}</p>
                    <div className="d-flex justify-content-center py-4">
                        { button_label &&
                        <Button
                            className="btn-outline"
                            onClick={closeModal}
                        >
                        {button_label}
                        </Button> }
                    </div>
                </ModalBody>
            </Modal>
        </div>
    );
}
