import React, { useState, useEffect, useContext } from "react";
import { Row, Col, Label, Input, Form, FormGroup, Spinner, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import { debounce, find, isEmpty, omit, forEach } from 'lodash';
import { toast } from 'react-toastify';
import moment from 'moment';
import classnames from 'classnames';

import { history } from 'js/helpers';
import { contestServices } from 'js/services';
import { FileUploadComponent, ImageSortable, ErrorFormFeedback, EditorComponent, StaticLink } from 'js/components/common';
import { LookupContext } from 'js/contexts';


import {  useModal } from 'js/hooks';


const styles = {
    option: (styles, state) => ({
        ...styles,
        backgroundColor: state.isSelected ? "#FFA405 " : state.isFocused ? "#FFA40520" : ""
    })
};

export const EditContest = ( props ) => {

    const lookup = useContext(LookupContext);
    const [ state, setState ] = useState({
        title: '',
        promotional_campaign_applicable_user_type_id:'',
        promotional_campaign_type_id : '',
        scheduled_at : '',
        value:'',
        short_description:'',
        description: '',
        rules_for_entry : '',
        milestones: [],
        checklist : [],
        attachments : [],
        existing_attachment_ids: [],
        promotional_campaign_id: ''
    });
    const [ errors, setErrors ] = useState({});
    const [ show, hide ] = useModal();
    const [ loading, setLoading ] = useState(false);

    const getContest = ( slug ) => {
        contestServices.get( slug )
        .then((response) => {
            setState({
                ...response,
                attachments: response.promotional_campaign_media ? response.promotional_campaign_media.map( (item) => ( { ...item.attachment, ...omit(item, 'attachment')} ) ) : [],
                checklist : response.checklist ? response.checklist : [],
                rules_for_entry : response.rules_for_entry ? response.rules_for_entry : '<ol><li></li></ol>',
                milestones : response.promotional_campaign_milestones ? response.promotional_campaign_milestones : [],
                existing_attachment_ids: response.promotional_campaign_media ? response.promotional_campaign_media.map( ( item ) => ( item.attachment.attachment_id ) ) : [],
                promotional_campaign_id: response.promotional_campaign_id
            });
        }).catch(err => {
            history.push('/admin')
        });
    }

    const addFile = ( response ) => {
        setState( state => ( { ...state, attachments : [ ...state.attachments, response ]}));
    }
    const removeCampaignMedia = ( items, final_files ) => {
        setState( state => ( { ...state, attachments : final_files } ));
        items && forEach( items, (item) => {
            if( item.promotional_campaign_media_id ){
                contestServices.removeCampaignMedia( item.promotional_campaign_media_id )
                .then( (response) =>{

                })
                .catch( (response )=> {
                    alert('Failed to Delete Media');
                });
            }
        });
    }
    // const attachmentUpdate = ( attachments ) => {
    //     setState( state => ( { ...state, attachments : attachments } ));
    // }
    const handleInput = ( key, value ) => {
        setState( state => ({
            ...state,
            [key] : value
        }));
    }

    const addEditMileStone = ( response, index ) => {
        if( index || index === 0 ) {
            let milestones = state.milestones;
            milestones && milestones.splice(index, 1, response);
            setState( state => ( { ...state, milestones : milestones}));
        }
        else{
            setState( state => ( { ...state, milestones : [ ...state.milestones, response ]}));
        }
        hide('MilestoneAddEditModal')
    }
    const removeMileStone = ( index ) => {
        let milestones = state.milestones;
        let [ item ] = milestones.splice(index, 1);
        if( item && item.promotional_campaign_milestone_id ){
            contestServices.removeMilestone( item.promotional_campaign_milestone_id )
            .then((response) => {
                setState( state => ( { ...state, milestones : milestones }));
            }).catch((error) => {
                error.data && error.data.errors && setErrors(error.data.errors);
                toast.error('Failed To Remove Milestone');
            });
        }
    }

    const handleArrayChange = ( value, key, parent_key, index ) => {
        let newState = { ...state };
        if( newState[parent_key] && newState[parent_key][index] ) {
            newState[parent_key][index][key] = value;
            setState(newState);
        }
    }

    const addNewCheckList = () => {
        setState( state => ( { ...state, checklist : [ ...state.checklist, { label :'', link : '' } ]}));
    }

    const removeCheckList = ( index ) => {
        let checklist = state.checklist;
        checklist.splice(index, 1);
        setState( state => ( { ...state, checklist : checklist }));
    }


    const editContest = debounce(() => {
        let data = {
            ...omit(state, ['existing_attachment_ids', 'milestones', 'promotional_campaign_id']),
            attachments: state.attachments ? state.attachments
                .map( ( item ) => ( item.attachment_id ) )
                .filter( ( item ) => ( !state.existing_attachment_ids
                .includes( item ) ) ) : [],
            scheduled_at : state.scheduled_at ? moment(state.scheduled_at).format("YYYY-MM-DD") : '',
        };
        setLoading(true);
        contestServices.update(data, props.match.params.slug )
        .then((response) => {
            setLoading(false);
            toast.success('Contest Updated Successfully');
            history.push('/admin/contest-giveaways');
        }).catch((error) => {
            setLoading(false);
            error.data && error.data.errors && setErrors(error.data.errors);
        });
    },300);

    useEffect(() => {
        if( props.match && props.match.params && props.match.params.slug  ){
            getContest(props.match.params.slug);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <div className="generic-page-header">
                <div className="header-data-section">
                    <Link to={"/admin/contest-giveaways"} className="back-btn"><img src="/images/admin/global/back.svg" alt="" /></Link>
                    <h2 className="page-head my-0"> { state.title  }</h2>
                </div>
            </div>
            <div className="tt-admin-card">
                <div className="tt-admin-card--data">
                    <Row className="no-gutters">
                        <Col xs="12" md="12" lg="12" xl="9">
                            <div className="trip-details-data-section">
                                <Form className="tt-admin-form">
                                    <div className="tt-admin-form-inner">
                                        <Row form>
                                            <Col xs="12">
                                                <FormGroup>
                                                    <Label for="">Contest Title *</Label>
                                                    <Input
                                                        type="text"
                                                        value={ state.title }
                                                        onChange={ (e) => handleInput('title',e.target.value) }
                                                        invalid={ !isEmpty(errors) && (errors.title) && true }
                                                    />
                                                    <ErrorFormFeedback field={'title'} errors={errors} />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col xs="12" md="4">
                                                <FormGroup>
                                                    <Label for="">Contest Type *</Label>
                                                    <div className="tt-custom-react-select">
                                                        <Select
                                                            className={classnames("react-select-container", { 'is-invalid' : errors.promotional_campaign_type_id })}
                                                            classNamePrefix="react-select"
                                                            options={lookup.promotional_campaign_types}
                                                            styles={styles}
                                                            value={find(lookup.promotional_campaign_types, ['value',  state.promotional_campaign_type_id] )}
                                                            onChange={ (e) => handleInput('promotional_campaign_type_id',e.value) }
                                                        />
                                                        <ErrorFormFeedback field={'promotional_campaign_type_id'} errors={errors} />
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                            <Col xs="12" md="4">
                                                <FormGroup>
                                                    <Label for="">Member Type *</Label>
                                                    <div className="tt-custom-react-select">
                                                        <Select
                                                            className={classnames("react-select-container", { 'is-invalid' : errors.promotional_campaign_applicable_user_type_id })}
                                                            classNamePrefix="react-select"
                                                            options={lookup.promotional_campaign_applicable_user_types}
                                                            styles={styles}
                                                            value={find(lookup.promotional_campaign_applicable_user_types, ['value',  state.promotional_campaign_applicable_user_type_id] )}
                                                            onChange={ (e) => handleInput('promotional_campaign_applicable_user_type_id',e.value) }
                                                        />
                                                        <ErrorFormFeedback field={'promotional_campaign_applicable_user_type_id'} errors={errors} />
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                            <Col xs="12" md="4">
                                                <FormGroup>
                                                    <Label for="">Drawing Deadline *</Label>
                                                    <DatePicker
                                                        className={classnames("custom-date-picker")}
                                                        selected={state.scheduled_at ?  moment(state.scheduled_at).toDate() : ''}
                                                        onChange={ (e) => handleInput('scheduled_at', e ) }
                                                        showYearDropdown
                                                    />
                                                    <div className="invalid-feedback d-block">{errors.scheduled_at}</div>
                                                </FormGroup>
                                            </Col>
                                            <Col xs="12" md="4">
                                                <FormGroup>
                                                    <Label for="">Value</Label>
                                                    <Input
                                                        type="text"
                                                        value={ state.value }
                                                        onChange={ (e) => handleInput('value',e.target.value) }
                                                        invalid={ !isEmpty(errors) && (errors.value) && true }
                                                    />
                                                    <ErrorFormFeedback field={'value'} errors={errors} />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col xs="12">
                                                <FormGroup>
                                                    <Label for="">Short Description *</Label>
                                                    <Input
                                                        type="textarea"
                                                        value={ state.short_description }
                                                        onChange={ (e) => handleInput('short_description',e.target.value) }
                                                        invalid={ !isEmpty(errors) && (errors.short_description) && true }
                                                    />
                                                    <ErrorFormFeedback field={'short_description'} errors={errors} />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col xs="12">
                                                <FormGroup>
                                                    <Label for="">Details *</Label>
                                                    <Input
                                                        type="textarea"
                                                        value={ state.description }
                                                        onChange={ (e) => handleInput('description',e.target.value) }
                                                        invalid={ !isEmpty(errors) && (errors.description) && true }
                                                    />
                                                    <ErrorFormFeedback field={'description'} errors={errors} />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col xs="12">
                                                <FormGroup>
                                                    <Label for="">Slide Pictures *</Label>
                                                    <div>
                                                        <FileUploadComponent
                                                            entity_type='PromotionalCampaignMedia'
                                                            multiple={true}
                                                            max={ 10 }
                                                            accepted={['image/*']}
                                                            onSuccess={addFile}
                                                        />
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col xs="12" className="d-flex justify-content-center mt-4">
                                                <ImageSortable files={state.attachments} onRemove={removeCampaignMedia} lock_sortable={true} />
                                                {/* <Link to={"#"} className="btn btn-solid-md">Upload Photo</Link> */}
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className="contests--footer">
                                        <Button
                                            to={"#"}
                                            className="btn btn-solid-lg"
                                            onClick={editContest}
                                            disabled={loading}
                                        >
                                            { loading ? <Spinner size="sm" /> : 'Save Changes'}
                                        </Button>
                                    </div>
                                </Form>
                            </div>
                        </Col>
                        <Col xs="12" md="12" lg="12" xl="3">
                            <div className="trip-details-sidebar user-details-sidebar">
                                <Row>
                                    <Col>
                                        <div className="contest-sidebar--inner">
                                            <h3>Rules for Entry</h3>
                                            <EditorComponent
                                                id="top_section"
                                                height="300"
                                                hide_toolbar={true}
                                                content={state.rules_for_entry}
                                                onChange={ (content) => handleInput( 'rules_for_entry', content ) }
                                            />
                                            <div className="invalid-feedback d-block">{errors.rules_for_entry}</div>
                                            <h3>Giveaway Milestones</h3>
                                            <div className="giveaway-milestone-wrapper">
                                                { state.milestones && state.milestones.map( (item,index) => (
                                                    <div className="giveaway-milestone-card" key={index}>
                                                        <div className="giveaway-top-section">
                                                            <span
                                                                onClick={() => show('MilestoneAddEditModal', {
                                                                    type  : 'edit',
                                                                    callBack : (response) => addEditMileStone(response, index),
                                                                    state : item,
                                                                })}
                                                            >
                                                                Edit
                                                            </span>
                                                            <Link
                                                                to={"#"}
                                                                onClick={() => removeMileStone(index) }
                                                            >
                                                                    Remove
                                                            </Link>
                                                        </div>
                                                        <div className="giveaway-bottom-section">
                                                            { item.attachment &&
                                                                <div className="logo-wrap">
                                                                <StaticLink
                                                                    url={item.url}
                                                                    content={
                                                                        <img
                                                                            src={ item.attachment.thumbnail_url ?
                                                                                item.attachment.thumbnail_url : item.attachment.attachment_url
                                                                            }
                                                                            alt="Milestone Logo"
                                                                            style={ { width: '100%' } }
                                                                        />
                                                                    }
                                                                />


                                                            </div>
                                                            }
                                                            <div className="giveaway-text">
                                                                <p>{item.description}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )) }
                                                <div className="milestone-btn-wrap">
                                                    <Link
                                                        to={"#"}
                                                        onClick={() => show('MilestoneAddEditModal', {
                                                            callBack : addEditMileStone,
                                                            promotional_campaign_id : state.promotional_campaign_id
                                                        })}
                                                        className="btn btn-solid-md"
                                                    >
                                                        Add New Milestones
                                                    </Link>
                                                </div>
                                            </div>
                                            <h3>Checklist to Win</h3>
                                            <div className="checklist-win-wrapper">
                                                <Form>
                                                { state.checklist && state.checklist.map( (item,index) => (
                                                    <div className="checklist-win-card" key={index} >
                                                        <div className="checklist-win-top-section">
                                                            <span>Button Label</span>
                                                            <Link
                                                                to={"#"}
                                                                onClick={ () => removeCheckList( index) }
                                                            >
                                                                Remove
                                                            </Link>
                                                        </div>
                                                        <div className="checklist-win-bottom-section">
                                                            <div className="form-row--custom">
                                                                <FormGroup>
                                                                    <Input
                                                                        type="text"
                                                                        value={item.label}
                                                                        onChange={ (e) => handleArrayChange( e.target.value, 'label', 'checklist', index ) }
                                                                        invalid={!isEmpty(errors) && (errors[`checklist.${index}.label`]) && true}
                                                                    />
                                                                    <ErrorFormFeedback field={`checklist.${index}.label`} errors={errors} />
                                                                </FormGroup>
                                                            </div>
                                                            <div className="form-row--custom">
                                                                <FormGroup>
                                                                    <label>Button Link</label>
                                                                    <Input
                                                                        type="text"
                                                                        value={item.link}
                                                                        onChange={ (e) => handleArrayChange( e.target.value, 'link', 'checklist', index ) }
                                                                        invalid={!isEmpty(errors) && (errors[`checklist.${index}.link`]) && true}
                                                                    />
                                                                    <ErrorFormFeedback field={`checklist.${index}.link`} errors={errors} />
                                                                </FormGroup>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    )) }
                                                    <div className="invalid-feedback d-block">{errors.checklist}</div>
                                                    <div className="milestone-btn-wrap">
                                                        <Link
                                                            to={"#"}
                                                            onClick={addNewCheckList}
                                                            className="btn btn-solid-md"
                                                        >
                                                            Add New Check List
                                                        </Link>
                                                    </div>
                                                </Form>

                                            </div>
                                        </div>

                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
}
