import React, { useEffect, useState } from "react"
import Slider from "react-slick";
import { isEmpty } from 'lodash';
import Lightbox from 'react-image-lightbox'

import 'react-image-lightbox/style.css';
import "js/components/legos/styles/gallery-slider-module.css"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export const GallerySlider = (props) => {

    const [ light_box_settings, setLightBoxSettings ] = useState({ open: false, photo_ndex: 0 });
    const [ images, setImages ] = useState([]);
    const [ settings, setSettings ] = useState({
        dots: false,
        infinite: true,
        autoplay: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: true,
        className: 'gallery-slider',
        responsive: [
            {
                breakpoint: 1199.98,
                settings: {
                    slidesToShow: 3,
                //    arrows: false
                }
            },
            {
                breakpoint: 767.98,
                settings: {
                    slidesToShow: 2,
                //    arrows: false
                }
            },
            {
                breakpoint: 565.98,
                settings: {
                    slidesToShow: 1,
                //    arrows: false
                }
            }
        ]
    });

    useEffect(() => {
        let all_images = [];

        if (!isEmpty(props.images)) {
            all_images = props.images
                .filter(img => img.attachment)
                .map(item => item.attachment);

            setImages(all_images);

            if (props.images.length < 4) {
                setSettings(s => ({ ...settings, slidesToShow: props.images.length }));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.images])
    let main_src = images[light_box_settings.photo_ndex] ?
    images[light_box_settings.photo_ndex]['optimized_url'] ?
        images[light_box_settings.photo_ndex]['optimized_url']
        :
        images[light_box_settings.photo_ndex]['attachment_url']
    :''
    let next_src = images[(light_box_settings.photo_ndex + 1) % images.length] ?
    images[(light_box_settings.photo_ndex + 1) % images.length]['optimized_url'] ?
        images[(light_box_settings.photo_ndex + 1) % images.length]['optimized_url']
        :
        images[(light_box_settings.photo_ndex + 1) % images.length]['attachment_url']
    :''
    let prev_src = images[(light_box_settings.photo_ndex + images.length - 1) % images.length] ?
    images[(light_box_settings.photo_ndex + images.length - 1) % images.length]['optimized_url'] ?
        images[(light_box_settings.photo_ndex + images.length - 1) % images.length]['optimized_url']
        :
        images[(light_box_settings.photo_ndex + images.length - 1) % images.length]['attachment_url']
    :''
    return (
        <div>
            {images && !isEmpty(images) &&
            <Slider {...settings}>
                {images.map((img, index) => (
                <div key={index}>
                    <div className="gallery-slider-wrapper">
                            <img
                                src={img.thumbnail_url ? img.thumbnail_url :img.attachment_url}
                                alt=""
                                onClick={() => setLightBoxSettings({ ...light_box_settings, photo_ndex: index, open: true })}
                            />
                    </div>
                </div>
                ))}
            </Slider>
        }
            {light_box_settings.open &&
        <Lightbox
            mainSrc={main_src}
            nextSrc={next_src}
            prevSrc={prev_src}
            onCloseRequest={() => setLightBoxSettings({ ...light_box_settings, open : false })}
            onMovePrevRequest = {() =>
                setLightBoxSettings({
                    ...light_box_settings,
                    photo_ndex: (light_box_settings.photo_ndex + images.length - 1) % images.length,
                })
            }
            onMoveNextRequest = {() =>
                setLightBoxSettings({
                    ...light_box_settings,
                    photo_ndex: (light_box_settings.photo_ndex + 1) % images.length,
                })
            }
        />
        }
        </div>
    );
}
