import React from 'react';
import {
    Row,
    Col,
    Button,
    Form,
    FormGroup,
    Label,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Spinner
} from 'reactstrap';
import { ErrorFormFeedback } from 'js/components/common'

export const ConfirmModal = (props) => {
    const { is_open, hide, action, loading, errors } = props;
    // const [state, setState] = useState({ modal_is_open: props.data.modal_state ? props.data.modal_state : false });

    return (
        <div>
            <Modal
                isOpen={is_open}
                toggle={() => hide()}
                className="tt-modal--admin modal-dialog-centered"
            >
                <ModalHeader toggle={() => hide()}>{props.title}</ModalHeader>
                <ModalBody>
                    <Form className="tt-admin-form">
                        <div className="tt-admin-form-inner p-0">
                            <Row form>
                                <Col xs="12" md="12">
                                    <FormGroup>
                                        <Label for="">{props.body}</Label>
                                        <div className="tt-custom-react-select">
                                        </div>

                                    </FormGroup>
                                    {errors &&
                                        <ErrorFormFeedback field={'general'} errors={errors} is_invalid={true}/>
                                    }
                                </Col>
                            </Row>
                        </div>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        className="btn-outline-grey"
                        onClick={() => hide()}
                        disabled={loading}
                    >
                        Cancel
                    </Button>
                    <Button
                        color="primary"
                        className="add-new-btn"
                        onClick={action}
                        disabled={loading}
                    >
                    {loading ?
                        <Spinner size="sm"/> : props.button_name
                    }
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}
