import React, { useState, useEffect } from "react";
import {
    Row,
    Col,
    Button,
    Form,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Spinner
} from 'reactstrap';
import { toast } from "react-toastify";
import { debounce,  isEmpty } from 'lodash';

import { cmsServices } from 'js/services';
import { ErrorFormFeedback } from 'js/components/common';

export const FooterMenuAddEditModal = (props) => {

    const { is_open, hide, callBack } = props;
    const [ state, setState] = useState({
        label: "",
        page_to_link: "",
        is_visible: 1
    });
    const [ loading, setLoading ] = useState();
    const [ footerErrors, setError ] = useState();

    const addEditFooterMenu = debounce(() =>{
        setLoading(true);
        if( props.type === 'add' ){
            cmsServices.addFooterMenu(state, hide)
            .then((response) => {
                toast.success('Footer Menu Added Successfully');
                setError({});
                callBack();
                hide();
                setLoading(false);
            }).catch((error) => {
                setError(error.data && error.data.errors);
                setLoading(false);
            });
        }
        else if( props.type === 'edit' && state.slug ) {
            cmsServices.editFooterMenu(state.slug, state, hide)
            .then((response) => {
                toast.success('Footer Menu Updated Successfully');
                setError({});
                callBack();
                hide();
                setLoading(false);
            }).catch((error) => {
                setError(error.data && error.data.errors);
                setLoading(false);
            });
        }
    }, 300 );

    const handleChange = ( value, key ) => {
        setState({ ...state, [key] : value });
    }

    useEffect(() => {
        if( props.type === 'edit' ){
            setState(props.state);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <Modal
                isOpen={is_open}
                toggle={() => hide()}
                className="tt-modal--admin modal-dialog-centered"
            >
                    <ModalHeader toggle={() => hide()}>{ props.type === 'edit'  ? 'Edit Menu Item' : 'Add Menu Item'}</ModalHeader>
                    <ModalBody>
                        <Form className="tt-admin-form">
                            <div className="tt-admin-form-inner p-0">
                                <Row form>
                                    <Col xs="12">
                                        <FormGroup>
                                            <Label for="">Menu Label</Label>
                                            <Input
                                                type="text"
                                                name="label"
                                                id="label"
                                                value={state.label}
                                                onChange={ (e) => handleChange(e.target.value, 'label') }
                                                invalid={ !isEmpty(footerErrors) && (footerErrors.label) && true }
                                            />
                                            <ErrorFormFeedback field={'label'} errors={footerErrors} />
                                        </FormGroup>
                                    </Col>
                                    <Col xs="12">
                                        <FormGroup>
                                            <Label for="">Link to Page</Label>
                                            <Input
                                                type="text"
                                                name="page_to_link"
                                                id="page_to_link"
                                                value={state.page_to_link}
                                                onChange={ (e) => handleChange(e.target.value, 'page_to_link') }
                                                invalid={ !isEmpty(footerErrors) && (footerErrors.page_to_link) && true }
                                            />
                                            <ErrorFormFeedback field={'page_to_link'} errors={footerErrors} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </div>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="primary"
                            className="btn-outline-grey"
                            onClick={() => hide()}
                            disabled={loading}
                        >
                            Cancel
                        </Button>
                        <Button
                            color="primary"
                            className="add-new-btn"
                            onClick={addEditFooterMenu}
                            disabled={loading}
                        >
                            { loading ? <Spinner color="#ffa405" size="sm" /> : 'Save' }
                        </Button>
                    </ModalFooter>
                </Modal>
        </div>
    );
}
