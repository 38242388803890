import { request } from "js/helpers/api";
import ParsedDomain from 'parse-domain';

export const authServices = {
    partnerLogin,
    login,
    resetPasswordLink,
    resetPassword,
    logout,
    getProfile,
    updateProfile,
    lookups,
    signUpCreateAccount,
    signUpTwo,
    signUpThree,
    calculatePlan
};
function partnerLogin(params = {}) {
    let subdomain = window.location.hostname;
    let response = ParsedDomain(subdomain);
    if (response && response.subdomain) {
        params.sub_domain = response.subdomain;
    }
    else {
        params.sub_domain = 'dev';
    }
    return request({
        method: 'GET',
        url: 'partners/token',
        param: params,
        is_authenticated: false,
        content_type: 'json',
    });
}
function login(params) {
    return request({
        method: 'POST',
        url: 'auth/login',
        param: params,
        is_authenticated: false,
        content_type: 'json',
    });
}

function logout() {
    return request({
        method: 'DELETE',
        url: 'auth/logout',
        param: {},
    });
}

function resetPasswordLink(params) {
    return request({
        method: 'POST',
        url: 'auth/send-reset-password',
        param: params,
        is_authenticated: false,
        content_type: 'json',
    });
}

function resetPassword(details) {
    return request({
        method: 'POST',
        url: 'auth/reset-password',
        param: details,
        is_authenticated: false,
        content_type: 'json',

    });
}

function getProfile() {
    return request({
        method: 'GET',
        url: 'users/profile',
        param: {},
    });
}

function updateProfile(details, hideModal) {
    return request({
        method: 'PUT',
        url: 'users/profile/update',
        param: details,
        content_type: 'json',
        hideModal
    });
}

function lookups() {
    return request({
        method: 'GET',
        url: 'lookups',
        param: {},
    });
}

function signUpCreateAccount(params) {
    return request({
        method: 'POST',
        url: 'auth/signup/create-account',
        param: params,
        is_authenticated: false,
        content_type: 'json',
    });
}

function signUpTwo(params, user_id) {
    return request({
        method: 'POST',
        url: `auth/signup/select-plan/${user_id}`,
        param: params,
        content_type: 'json',
    });
}

function signUpThree(params) {
    return request({
        method: 'POST',
        url: 'auth/login',
        param: params,
        is_authenticated: false,
        content_type: 'json',
    });
}

function calculatePlan(params) {
    return request({
        method: 'POST',
        url: `auth/signup/calculate-plan`,
        param: params,
        content_type: 'json',
        cancel_token: 'authServices.calculatePlan'
    });
}
