import React from "react"

import "js/components/legos/styles/owner-details-module.css"
import { Link } from 'react-router-dom';
import { useModal } from 'js/hooks'

export default props => {
    const [show, hide] = useModal();

    return (
        <div>
            <div className="owner-details-outer">
                <div className="owner-detail">
                    <img alt="user" src={props.ownerImage ? props.ownerImage : '/images/web/global/logo-icon.svg' } alta="" />
                    <div className="owner-name">
                        <h5>Owner</h5>
                        <h4>{props.ownerName}</h4>
                    </div>
                </div>
                <div className="change-link">
                    <Link onClick={() => show('OwnersListModal', {
                        modal_header: 'Change Trip Owner',
                        modal_footer: 'Change Owner',
                        trip_id: props.tip_id,
                        slug:props.slug,
                        callBack: (user_id) => {
                            hide();
                            props.onChange && props.onChange(user_id)
                        }
                    })} to={"#"}>Change</Link>
                </div>
            </div>
        </div>
    );
}



