import React from 'react';
import { Row, Col } from 'reactstrap';

import ExperienceSlider from "js/components/legos/experienceSlider";
import SectionHeader from "js/components/legos/sectionHeader";
import { useEffect } from 'react';
import { join, isEmpty } from 'lodash';
import { tripsServices } from 'js/services';
import { useState } from 'react';

export const RelatedExperience = (props) => {
    const [ trips, setTrips ] = useState([]);
    const { user_id, feature_tags, category_id, categories, trip_id } =  props;
    useEffect(() => {
        let tags = feature_tags ?  join(feature_tags.map(item => item.slug)) : null;
        let params = {
            related_experience_by_user_id: user_id ? user_id : null,
            related_experience_by_tags: tags,
            related_experience_by_category_id: category_id ? category_id : null,
            related_experience_by_categories: categories ? categories.join(',') : null,
            related_experience_by_trip_id: trip_id ? trip_id : null,
            visibility: 'public'
        }
        getAllTrips(params)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user_id, feature_tags, category_id, categories, trip_id])
    const getAllTrips = (params) => {
        tripsServices.getAll(params)
        .then(response => {
            setTrips(response)
        }).catch(err => {
            console.log('failed to fetch', err)
        })
    }

    return (
        <div className="graph-wrap">
            <div className="sweet-loading">
            <div className="wider-data-frame ">
            {trips && !isEmpty(trips.data) &&
                <div className="container">
                    <Row>
                        <SectionHeader
                            HeaderText="Related Experiences"
                        />
                    </Row>
                    <Row>
                        <Col className="mt-0">
                            <ExperienceSlider trips={trips.data}/>
                        </Col>
                    </Row>
                </div>
            }
            </div>
            </div>
        </div>
    )
}
