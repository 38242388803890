import React from "react"
import "js/components/legos/styles/verifications-module.css"
import classnames from 'classnames';


export default props => {
    const { data} = props;
    return (

        <div className="verifications-outer">
            { data &&
            <ul className="verification-list">
                <li>
                    <div className={classnames("verify-icon", { "verified": data.user_document_verification })}>
                        <img src="/images/web/global/profile/id-proof.svg" alt="" />
                    </div>
                    <div className="verify-data">
                            <p>ID and Proof of Address</p>
                            <h5>{ data.user_document_verification ? 'Verified' : 'Not Verified Yet' }</h5>
                    </div>
                </li>
                <li>
                    <div className={classnames("verify-icon", { "verified": data.is_phone_number_verified })}>
                    <img src="/images/web/global/profile/phone.svg" alt="" />
                    </div>
                    <div className="verify-data">
                            <p>Telephone Number</p>
                            <h5>{ data.is_phone_number_verified ? 'Verified' : 'Not Verified Yet' }</h5>
                    </div>
                </li>
                <li>
                    <div className={classnames("verify-icon", { "verified": data.is_email_verified })}>
                        <img src="/images/web/global/profile/email.svg" alt="" />
                    </div>
                    <div className="verify-data">
                            <p>Email Address </p>
                            <h5>{ data.is_email_verified ? 'Verified' : 'Not Verified Yet' }</h5>
                    </div>
                </li>
                <li>
                    <div className={classnames("verify-icon", { "verified": data.background_check_status })}>
                        <img src="/images/web/global/profile/background-check.svg" alt="" />
                    </div>
                    <div className="verify-data">
                            <p>Background Check</p>
                            <h5>{ data.background_check_status ? 'Verified' : 'Not Verified Yet' }</h5>
                    </div>
                </li>
            </ul>
            }
        </div>

    );
}
