import React, { useState, useContext } from 'react';
import { Row, Col, UncontrolledCollapse, Button, Form, FormGroup, Input, Spinner } from 'reactstrap';
import { FilePond } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import classnames from 'classnames';
import { toast } from 'react-toastify';

import { profileServices } from 'js/services';
import { AuthProvider } from 'js/contexts';
import { ErrorFormFeedback } from 'js/components/common';

export const Verification = (props) => {
    const [state, setState] = useState({
        email:{sucess_message: ''},
        phone:{ sucess_message: '', value: '', code:''},
        id_proof:{ is_sent: false, value: ''}
    });
    const [ loading, setLoading ] = useState({
        email: false,
        phone: false,
        verify: false
    })
    const { auth } = useContext(AuthProvider);
    const [ errors, setErrors ] = useState({})

    const api_calls = {
        sendVerification: () => {
            setLoading({...loading, phone: true})
            profileServices.sendVerification()
            .then(response => {
                setState({
                    ...state, phone: {...state.phone, sucess_message: "Please check Your Phone to get a verification code"}
                })
                setLoading({...loading, phone: false})
            }).catch(err => {
                err && err.data && err.data.errors && setErrors(err.data.errors)
                setLoading({...loading, phone: false})
            })
        },
        verify: () => {
            let details = {
                verify_type: 'verify_token',
                code: state.phone.code
            }
            setLoading({...loading, verify: true})
            profileServices.verifyCode(details)
            .then(res => {
                toast.success('Yor Phonenumber has been verified');
                setLoading({...loading, verify: false})
                props.callBack && props.callBack();
            }).catch(setLoading({...loading, verify: false}))
        },
        sendLink: () => {
            setLoading({...loading, email: true})
            profileServices.sendEmailLink()
            .then(res => {
                setState({...state, email: {sucess_message: res.message}})
                setLoading({...loading, email: false})
            }).catch(
                setLoading({...loading, email: false})
            )
        },
        uploadProof: (file) => {
            profileServices.uploadIdProof(file)
            .then(res => {
                toast.success('ID and Proof of address uploaded');
                props.callBack && props.callBack();
            })
        },
    }

    const handleChange = (key, sub_key, value) => {
        let details = {
            ...state,
            [key]: {
                ...state[key],
                [sub_key]: value
            }
        }
        setState(details)
    }
    const onAddFile = (file) => {
        const formData = new FormData();
        formData.append('filename', file.file);
        api_calls.uploadProof(formData)
    }
    const {user} = auth;
    return(
        <Row>
            <Col xs="12">
                <div className={classnames("credibility-accordion-outer", {'verified-credibility': user.user_document_verification})}>
                    <div className="accordion-heading" id="id-proof">
                        <div className="accordion-icon">
                            <img src="/images/web/global/id-proof.svg" alt="" />
                        </div>
                        <div className="accordion-top-data">
                            <h4>ID and Proof of Address</h4>
                            <p>{user.user_document_verification ? 'Verified': 'Not verified'}</p>
                        </div>
                        <div className="accordion-arrow-wrap">
                            <div className="arrow-up"></div>
                        </div>
                    </div>
                    {!user.user_document_verification &&
                    <div>
                        <UncontrolledCollapse toggler="#id-proof">
                            <div className="accordion-inner-data">
                                <FilePond
                                    allowMultiple={true}
                                    labelIdle={`<div class="drag-files">Drag ID or Proof of Address Files to Upload or</div><div class="filepond-btn">Browse File</div>`}
                                    maxFiles={10}
                                    className={"tt-file-upload"}
                                    onaddfile={(err, file) => onAddFile(file)}
                                >
                                </FilePond>
                            </div>
                        </UncontrolledCollapse >
                    </div>
                    }
                </div>
                <div className={classnames("credibility-accordion-outer", {'verified-credibility': user.is_phone_number_verified})}>
                    <div className="accordion-heading" id="telephone">
                        <div className="accordion-icon">
                            <img src="/images/web/global/phone.svg" alt="" />
                        </div>
                        <div className="accordion-top-data">
                            <h4>Telephone Number</h4>
                            <p>{user.is_phone_number_verified ? 'Verified': 'Not verified'}</p>
                        </div>
                        <div className="accordion-arrow-wrap">
                            <div className="arrow-up"></div>
                        </div>

                    </div>
                    {!user.is_phone_number_verified &&
                    <div>
                        <UncontrolledCollapse toggler="#telephone">
                            <div className="accordion-inner-data">
                                <Row>
                                    <Col xs="12">
                                        <Button
                                            className="accordion-btn-outline"
                                            onClick={() => api_calls.sendVerification()}
                                            disabled={loading.phone}
                                            >
                                            {loading.phone ? <Spinner size="sm"/> : 'Send Verification Code' }
                                        </Button>
                                    </Col>
                                    <Col xs="12">
                                        {errors && errors.verify &&
                                            <ErrorFormFeedback field={'verify'} errors={errors} is_invalid={true}/>
                                        }
                                        <p>{state.phone.sucess_message}</p>
                                    </Col>
                                </Row>
                                {state.phone.sucess_message &&
                                    <Row>
                                        {/* <Col xs="12">
                                            <Label for="" >Verification Code</Label>
                                        </Col> */}
                                        <Col xs="12" md="7">
                                            <FormGroup>
                                                <Input
                                                    placeholder="Verification code"
                                                    type="text"
                                                    onChange={(e) => handleChange('phone', 'code',e.target.value)}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col xs="12" md="5">
                                            <Button
                                                className="accordion-btn-outline"
                                                onClick={(e) => api_calls.verify()}
                                                disabled={loading.verify}
                                            >
                                                {loading.verify ? <Spinner size="sm"/> : 'Verify Phone number' }
                                            </Button>
                                        </Col>
                                    </Row>
                                }

                            </div>
                        </UncontrolledCollapse >
                    </div>
                    }
                </div>
                <div className={classnames("credibility-accordion-outer", {'verified-credibility': user.is_email_verified})}>
                    <div className="accordion-heading" id="email">
                        <div className="accordion-icon">
                            <img src="/images/web/global/email.svg" alt="" />
                        </div>
                        <div className="accordion-top-data">
                            <h4>Email Address </h4>
                            <p>{user.is_email_verified ? 'Verified': 'Not verified'}</p>
                        </div>
                        <div className="accordion-arrow-wrap">
                            <div className="arrow-up"></div>
                        </div>
                    </div>
                    {!user.is_email_verified &&
                    <div>
                        <UncontrolledCollapse toggler="#email">
                            <div className="accordion-inner-data">
                            <Form className="invite-form">
                                    <Row>
                                        <Col xs="12">
                                            <Row>
                                                {/* <Col xs="12">
                                                    <Label for="" >Phone Number</Label>
                                                </Col>
                                                <Col xs="12" md="8">
                                                    <FormGroup>
                                                        <Input
                                                            type="email"
                                                            placeholder="Send Verification Link"
                                                            onChange={(e) => handleChange('email', 'value',e.target.value)}
                                                        />
                                                    </FormGroup>
                                                </Col> */}
                                                <Col xs="12" className="pull-right">
                                                    <Button
                                                        className="accordion-btn-outline"
                                                        onClick={(e) => api_calls.sendLink()}
                                                        disabled={loading.email}
                                                    >
                                                    {loading.email ? <Spinner size="sm"/> : 'Send Verification Link' }
                                                    </Button>
                                                </Col>


                                                <Col>
                                                {state.email.sucess_message &&
                                                    <p>{state.email.sucess_message}</p>
                                                }
                                                </Col>

                                            </Row>
                                        </Col>
                                    </Row>
                                </Form>
                            </div>
                        </UncontrolledCollapse >
                    </div>
                    }
                </div>
                <div className={classnames("credibility-accordion-outer", {'verified-credibility': user.background_check_status})}>
                    <div className="accordion-heading" >
                        <div className="accordion-icon">
                            <img src="/images/web/global/background-check.svg" alt="" />
                        </div>
                        <div className="accordion-top-data">
                            <h4>Background Check</h4>
                            <p>{user.background_check_status ? 'Verified': 'Not verified'}</p>
                        </div>
                    </div>
                </div>
            </Col>
        </Row>
    )
}
