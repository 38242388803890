import React from "react"
import "js/components/legos/styles/star-rating-module.css"


export default props => {
    const { data} = props;
    const rating = data && data.average_reviews ? Math.round(data.average_reviews) : 0;
    return (
        <>
            { data &&
                <div className="star-rating-minimal">
                    <div className="rating">{rating ? rating : '0.0' }</div>
                    <div className="stars">
                        <img src={ rating >= 1 ? "/images/web/global/star-lg.svg" : "/images/web/global/star-grey-lg.svg" } alt="" />
                        <img src={ rating >= 2 ? "/images/web/global/star-lg.svg" : "/images/web/global/star-grey-lg.svg" } alt="" />
                        <img src={ rating >= 3 ? "/images/web/global/star-lg.svg" : "/images/web/global/star-grey-lg.svg" } alt="" />
                        <img src={ rating >= 4 ? "/images/web/global/star-lg.svg" : "/images/web/global/star-grey-lg.svg" } alt="" />
                        <img src={ rating >= 5 ? "/images/web/global/star-lg.svg" : "/images/web/global/star-grey-lg.svg" } alt="" />
                    </div>
                    <div className="reviews">{data.total_reviews &&  ` ${data.total_reviews} Reviews`}</div>
                </div>
            }
        </>

    );
}
