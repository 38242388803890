import React, { useState, useEffect, useContext } from "react";
import { Row, Col, Button, Input, Table, Form, FormGroup, CustomInput } from 'reactstrap';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { isEmpty, debounce, forEach, keys } from 'lodash';
import ReactTooltip from 'react-tooltip';

import { userServices, membershipPlanService } from 'js/services';
import { LookupContext } from 'js/contexts';
import { PaginationComponent, ListLoader } from 'js/components/common';
import { useParamChange, useModal } from 'js/hooks';
import { toast } from "react-toastify";
import moment from "moment";
import { history } from 'js/helpers';

const styles = {
    option: (styles, state) => ({
        ...styles,
        backgroundColor: state.isSelected ? "#FFA405 " : state.isFocused ? "#FFA40520" : ""
    })
};
const customSingleValue = ({ data }) => (
    <div className="input-select">
        <div className="input-select__single-value-selected">
            {data.icon && <span className="input-select__icon mr-2">{data.icon}</span>}
            <span>{data.label}</span>
        </div>
    </div>
);
const CustomOption = ({ data, innerProps, isDisabled }) => !isDisabled ? (
    <div {...innerProps}>
        <div className="input-select__single-value">
            {data.icon && <span className="input-select__icon mr-2">{data.icon}</span>}
            <span>{data.label}</span>
        </div>
    </div>
) : null;
export const UserManagement = (props) => {

    const [users, setUsers] = useState([]);
    const [show, hide] = useModal();

    const [state, setState] = useState({
        user_id: null,
        selected_user: {},
    });

    const [loading, setLoading] = useState(false);
    const lookups = useContext(LookupContext);
    const bulk_action_lookup = [
        { value: 'delete', label: 'Delete' },
    ];
    const image_url_status = {
        approved: 'success.svg',
        pending: 'warning.svg',
        rejected: 'error.svg'
    }
    const [membership_plans, setMembershipPlans] = useState([])
    const [ user_roles_lookups, setUserRolesLookups ] = useState( [] );

    const apiCalls = {
        getAll: (params) => {
            setLoading(true);
            userServices.getAll(params).then((response) => {
                setUsers(response);
                setLoading(false);
            }).catch((err) => {
                setLoading(false);
            });
        },
        remove: debounce((user_id) => {
            userServices.remove(user_id).then(() => {
                let current_params = { ...params };
                if (users.data.length === 1) {
                    if (current_params.page !== 1) {
                        current_params.page = current_params.page - 1;
                    }
                }
                apiCalls.getAll(current_params);
                hide('ConfirmModal');
                toast.success('Deleted Successfully');
            }).catch((err) => {
                console.log('failed to fetch users', err)

            });
        }, 300),
        bulkAction: (value, selected_items_array) => {
            userServices.bulkAction({ action_type: value, items: selected_items_array })
                .then(() => {
                    apiCalls.getAll(params);
                    setState({ ...state, selected_user: {}, action_type: null, select_all: false });
                    hide('ConfirmModal');
                })
                .catch((error) => {
                    setState({ ...state, action_type: null });
                    hide('ConfirmModal');
                    error.data && error.data.errors && error.data.errors.general && show('ErrorModal', {
                        title: 'Error',
                        body: error.data.errors.general,
                    });
                })
        },
        getMembershipPlans: () => {
            membershipPlanService.getAll()
                .then(res => {
                    let plans = res.map(item => {
                        return { ...item, value: item.membership_plan_id, label: item.label }
                    })
                    setMembershipPlans(plans)
                })
        }
    }

    const [params, onParamsChange] = useParamChange(apiCalls.getAll);

    useEffect(() => {
        const { location: { state } } = props;
        if (state && state.current_page) {
            onParamsChange('page', props.location.state.current_page);
        }
        else {
            apiCalls.getAll();
        }
        if(lookups.user_roles){
            const user_roles_lookups = [ {
                label: 'All users',
                icon: <img src={ `/images/admin/global/users.svg` } width="16px" alt="" />,
            }];
            forEach(lookups.user_roles, (item) => {
                user_roles_lookups.push({
                    ...item,
                    label:`${item.label}`,
                    icon: <img src={`/images/admin/global/users.svg`} width="16px" alt="" />
                })
            });
            setUserRolesLookups(user_roles_lookups);
        }
        apiCalls.getMembershipPlans();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const deleteUser = (user) => {
        apiCalls.remove(user.user_id);
    }

    const exportToExcel = () => {
        userServices.exportToExcel(params).then((response) => {
            window.open(response);
        });
    }

    const bulkAction = (value) => {
        let selected_keys = keys(state.selected_user);
        let selected_items_array = [];

        forEach(selected_keys, (item) => {
            if (state.selected_user[item]) {
                selected_items_array.push(item);
            }
        });

        if (value === 'delete') {
            if (!isEmpty(selected_items_array)) {
                let title = selected_items_array.length > 1 ? 'Delete the Users' : 'Delete the User';
                show('ConfirmModal', {
                    title,
                    body: `Do you want to ${title} ?`,
                    button_name: 'Delete',
                    action: () => apiCalls.bulkAction(value, selected_items_array),
                });
            }
        }
    }

    const selectAll = (value) => {
        let selected_user = {};

        if (!isEmpty(users.data)) {
            forEach(users.data, (user) => {
                selected_user[user.user_id] = value;
            });
        }
        setState({ ...state, selected_user, select_all: value });
    }


    return (
        <div>
            <div className="generic-page-header">
                <div className="header-data-section">
                    <h2 className="page-head my-0">User Management</h2>
                </div>
                <div className="header-data-section">
                    {users && users.meta && users.meta.total > 0 &&
                    <Button
                        color="secondary"
                        className="export-to-excel-btn mr-3"
                        onClick={() => exportToExcel()}
                    >
                        <i>
                            <svg width="19" height="17" viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M14.728 12.8206H11.9003L9.97152 10.0926L7.94478 12.8206H5.10477L8.62819 8.38936L5.78315 4.51387H8.6517L9.98439 6.62121L11.4044 4.51387H14.3233L11.3406 8.38936L14.728 12.8206ZM18.4585 16.6071H1.66699V0.570107H18.4585V16.6071ZM3.2801 14.994H16.8426V2.18433H3.2801" fill="#10C56E" />
                            </svg>
                        </i>Export to Excel
                    </Button>
                    }
                    <div className="custom-react-select--white mr-3">
                        <Select
                            components={{ SingleValue: customSingleValue, Option: CustomOption }}
                            className="react-select-container"
                            classNamePrefix="react-select"
                            options={user_roles_lookups}
                            styles={styles}
                            onChange={(e) => {
                                onParamsChange('role', e.value);
                                setState({ ...state, 'role': e });
                            } }
                            defaultValue={{
                                label: 'All users',
                                icon: <img src={ `/images/admin/global/users.svg` } width="16px" alt="" />,
                            }}
                        />
                    </div>
                    <Button
                        onClick={() => history.push('/admin/user-management/add-user')}
                        color="primary"
                        className="add-new-btn"
                    >
                        <i>
                            <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.03027 7.77295H0.171875V5.6001H5.03027V0.619629H7.22754V5.6001H12.0981V7.77295H7.22754V12.729H5.03027V7.77295Z" fill="#fff" />
                            </svg>
                        </i>Add a User</Button>
                </div>
            </div>
            <div className="tt-admin-card">
                <div className="tt-admin-card--header tt-admin-card--header_filter">
                    <div className="tt-filter">
                        <Form>
                            <Row className="justify-content-between">
                                <Col xs="12" lg="5">
                                    <Row>
                                        <Col xs="12" lg="6">
                                            <FormGroup>
                                                <div className="custom-react-select--no-border w-100">
                                                    <Select
                                                        placeholder="Bulk Action"
                                                        className="react-select-container"
                                                        classNamePrefix="react-select"
                                                        options={bulk_action_lookup}
                                                        styles={styles}
                                                        onChange={(e) => {
                                                            setState({ ...state, 'action_type': e });
                                                            bulkAction(e.value);
                                                        }}
                                                        value={state.action_type ? state.action_type : null}
                                                    />
                                                </div>
                                            </FormGroup>

                                        </Col>
                                        <Col xs="12" lg="6">
                                            <FormGroup>
                                                <div className="filter-input--outer">
                                                    <Input
                                                        type="text"
                                                        name=""
                                                        id=""
                                                        placeholder="Search by Name or Email"
                                                        onChange={(e) => onParamsChange('search', e.target.value)}
                                                    />
                                                </div>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs="12" lg="5">
                                    <Row>
                                        <Col xs="12" md="6">
                                            <FormGroup>
                                                <div className="custom-react-select--no-border w-100">
                                                    <Select
                                                        placeholder="Membership"
                                                        className="react-select-container"
                                                        classNamePrefix="react-select"
                                                        options={membership_plans}
                                                        styles={styles}
                                                        onChange={(e) => {
                                                            onParamsChange('membership_plan_id', e.value);
                                                            setState({ ...state, 'selected_membership': e });
                                                        }}
                                                    //value={state.selected_membership ? state.selected_membership : null}
                                                    />
                                                </div>
                                            </FormGroup>
                                        </Col>
                                        <Col xs="12" md="6">
                                            <FormGroup>
                                                <div className="custom-react-select--no-border w-100">
                                                    <Select
                                                        placeholder="Status"
                                                        className="react-select-container"
                                                        classNamePrefix="react-select"
                                                        options={lookups.user_status}
                                                        styles={styles}
                                                        onChange={(e) => {
                                                            onParamsChange('status', e.slug);
                                                            setState({ ...state, 'selected_status': e })
                                                        }}
                                                        value={state.selected_status ? state.selected_status : null}
                                                    />
                                                </div>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </div>
                <div className="tt-admin-card--data">
                    <div className="table-wrap">
                        <Table responsive className="tt-admin-table-compact tt-admin-table-compact--checkbox ">
                            <thead>
                                <tr>
                                    <th>
                                        <div className="tt-admin-checkbox">
                                            <CustomInput
                                                type="checkbox"
                                                id="tt-check-2"
                                                label=""
                                                className="tt-checkbox"
                                                onChange={(e) => selectAll(e.target.checked)}
                                                checked={state.select_all ? state.select_all : false}
                                            />
                                        </div>
                                    </th>
                                    <th>Name</th>
                                    <th className="sortable" onClick={() => onParamsChange('sort', 'email')}>Email</th>
                                    <th className="sortable" onClick={() => onParamsChange('sort', 'phone')}>Phone</th>
                                    <th className="sortable" onClick={() => onParamsChange('sort', 'inquiries_received')}>Inqs. Received</th>
                                    <th className="sortable" onClick={() => onParamsChange('sort', 'inquiries_sent')}>Inqs. Sent</th>
                                    <th className="sortable" onClick={() => onParamsChange('sort', 'matches')}>Matches</th>
                                    <th className="sortable" onClick={() => onParamsChange('sort', 'last_logged_in_at')}>Last Login</th>
                                    <th className="sortable" onClick={() => onParamsChange('sort', 'promo_code')}>Promo Code</th>
                                    <th className="sortable" onClick={() => onParamsChange('sort', 'status')}>Status</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                            {loading ?
                                <tr>
                                    <td colSpan="9" align="center"><ListLoader /></td>
                                </tr>
                                :
                                users && isEmpty(users.data) ?
                                    <tr>
                                        <td colSpan="9" align="center">No Records Found.</td>
                                    </tr>
                                    :
                                    users && !isEmpty(users.data) && users.data.map((user, index) => (
                                        <tr key={`userIndex${index}`}>
                                            <td>
                                                <div className="tt-admin-checkbox">
                                                    <CustomInput
                                                        type="checkbox"
                                                        id={`user_checkbox_${index}`}
                                                        label=""
                                                        className="tt-checkbox"
                                                        onChange={(e) => {
                                                            setState({
                                                                ...state, selected_user: { ...state.selected_user, [user.user_id]: e.target.checked }
                                                            });
                                                        }}
                                                        checked={state.selected_user[user.user_id] ? state.selected_user[user.user_id] : false}
                                                    />
                                                </div>
                                            </td>
                                            <td><Link to={`/admin/user-management/${user.user_id}`}>{user.full_name ? user.full_name : '-'}</Link></td>
                                            <td>{user.email ? user.email : '-'}</td>
                                            <td>{user.phone ? user.phone : '-'}</td>
                                            <td>{user.inquiries_received ? user.inquiries_received : '-'}</td>
                                            <td>{user.inquiries_sent ? user.inquiries_sent : '-'}</td>
                                            <td>{user.matches ? user.matches : '-'}</td>
                                            <td>
                                                {user.last_logged_in_at ?
                                                    <>
                                                        {moment(user.last_logged_in_at, 'yyyy-mm-dd h:m:s').format('L')}
                                                        <br />
                                                        {moment(user.last_logged_in_at, 'yyyy-mm-dd h:m:s').format('LTS')}
                                                    </> : '-'
                                                }
                                            </td>
                                            <td>{user.promo_code ? user.promo_code : '-'}</td>
                                            {
                                                user.user_status &&
                                                <td><img src={`/images/admin/global/status/${image_url_status[user.user_status.slug]}`} alt="" data-tip="status" data-for={user.user_status.slug}/>
                                                    <ReactTooltip className='tt-admin-tooltip' aria-haspopup='true' id={user.user_status.slug} role=''>{user.user_status.label ? user.user_status.label : ''}</ReactTooltip>
                                                </td>
                                            }
                                            <td>
                                                <div className="action-wrap">
                                                    <Link to={`/profile/${user.user_id}`}><img src="/images/admin/global/view.svg" alt="" /></Link>
                                                    <Link to={`/admin/user-management/${user.user_id}`}><img src="/images/admin/global/edit.svg" alt="" /></Link>
                                                    <Link
                                                        to={"#"}
                                                        onClick={() => {
                                                            show('ConfirmModal', {
                                                                title: 'Delete User',
                                                                body: 'Do you want to delete this User ?',
                                                                button_name: 'Delete',
                                                                action: () => deleteUser(user)
                                                            })
                                                        }}
                                                    >
                                                        <img src="/images/admin/global/delete.svg" alt="" />
                                                    </Link>
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </Table>
                    </div>
                </div>
                {users && users.meta &&
                    <PaginationComponent page={users.meta} onChange={(page) => onParamsChange('page', page)} />
                }
            </div>
        </div>
    );
}
