import { request } from "js/helpers/api";

export const membershipPlanService = {
    getAll,
    get,
    create,
    update,
    remove,

    getAllCredibilityUpgrades,
    getCredibilityUpgrades,
    updateCredibilityUpgrades,
    removeCredibilityUpgrades,
    createCredibilityUpgrades,

    getAllMembershipPlanUpgrades,
    getMembershipPlanUpgrades,
    updateMembershipPlanUpgrades,
    removeMembershipPlanUpgrades,
    createMembershipPlanUpgrades,
};

function getAll(params = {}){
    return request({
        method:'GET',
        url: 'membership-plans',
        param: params,
    });
}

function get(membership_plan_id){
    return request({
        method:'GET',
        url: `membership-plans/${membership_plan_id}`,
    });
}

function create(detials, hideModal){
    return request({
        method:'POST',
        url: 'membership-plans',
        param: detials,
        content_type: 'json',
        hideModal
    });
}

function update(details, membership_plan_id){
    return request({
        method:'PUT',
        url: `membership-plans/${membership_plan_id}`,
        param: details,
        content_type: 'json',
    });
}

function remove(membership_plan_id){
    return request({
        method:'DELETE',
        url: `membership-plans/${membership_plan_id}`,
        param: {},
    });
}


function getAllCredibilityUpgrades(params = {}, hideModal){
    return request({
        method:'GET',
        url: 'credibility-upgrades',
        param: params,
        hideModal
    });
}

function getCredibilityUpgrades(credibility_upgrade_id, hideModal){
    return request({
        method:'GET',
        url: `credibility-upgrades/${credibility_upgrade_id}`,
        hideModal
    });
}

function createCredibilityUpgrades(detials, hideModal){
    return request({
        method:'POST',
        url: 'credibility-upgrades',
        param: detials,
        content_type: 'json',
        hideModal
    });
}

function updateCredibilityUpgrades(details, credibility_upgrade_id, hideModal){
    return request({
        method:'PUT',
        url: `credibility-upgrades/${credibility_upgrade_id}`,
        param: details,
        content_type: 'json',
        hideModal
    });
}

function removeCredibilityUpgrades(credibility_upgrade_id){
    return request({
        method:'DELETE',
        url: `credibility-upgrades/${credibility_upgrade_id}`,
        param: {},
    });
}

function getAllMembershipPlanUpgrades(params = {}){
    return request({
        method:'GET',
        url: 'membership-plan-upgrades',
        param: params,
    });
}

function getMembershipPlanUpgrades(credibility_upgrade_id, hideModal){
    return request({
        method:'GET',
        url: `membership-plan-upgrades/${credibility_upgrade_id}`,
        hideModal
    });
}

function createMembershipPlanUpgrades(detials,hideModal){
    return request({
        method:'POST',
        url: 'membership-plan-upgrades',
        param: detials,
        content_type: 'json',
        hideModal
    });
}

function updateMembershipPlanUpgrades(details, credibility_upgrade_id, hideModal){
    return request({
        method:'PUT',
        url: `membership-plan-upgrades/${credibility_upgrade_id}`,
        param: details,
        content_type: 'json',
        hideModal
    });
}

function removeMembershipPlanUpgrades(credibility_upgrade_id){
    return request({
        method:'DELETE',
        url: `membership-plan-upgrades/${credibility_upgrade_id}`,
        param: {},
    });
}



