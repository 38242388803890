import React from "react"
import Slider from "react-slick";

import "js/components/legos/styles/giveaway-slider-module.css"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useRef } from "react";

const GiveAwaySlider = ( props ) => {
    const sliderRef = useRef();
    const listRef = useRef();
    const settings = {
        customPaging: function (i) {
            return (
                <a href="/">
                    <img
                        style={ { width: "50px" } }
                        src={ props.items
                            && props.items[ i ]
                            && props.items[ i ].attachment
                            && props.items[ i ].attachment.optimized_url ?
                            props.items[ i ].attachment.optimized_url:  props.items[ i ].attachment.attachment_url
                        } alt="slider" />
                </a>
            );
        },
        className: 'giveaway-slider ',
        speed: 500,
        responsive: [
            {
                breakpoint: 767.98,
                settings: {
                    arrows: false
                }
            }
        ],
        slidesToShow:3,
        swipeToSlide: true,
        focusOnSelect:true,
        arrows: false,
    };
    return (
        <div>

            <Slider
                className='giveaway-slider'
                asNavFor={listRef.current}
                ref={sliderRef}
            >
                { props.items && props.items.map( ( item, index ) => (
                    <div key={index}>
                        <img src={item.attachment ? item.attachment.optimized_url?item.attachment.optimized_url:item.attachment.attachment_url :''} alt="" />
                    </div>
                ) ) }
            </Slider>
            <Slider

                {...settings}
                asNavFor={sliderRef.current}
                ref={listRef}
            >
                { props.items && props.items.map( ( item, index ) => (
                <div key={index} className='giveaway-slider--thumb'>
                    <img src={item.attachment ? item.attachment.optimized_url?item.attachment.optimized_url:item.attachment.attachment_url :''} alt="" />
                </div>
            ) ) }
            </Slider>
        </div>

    );
}
export default GiveAwaySlider;
