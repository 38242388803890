import React, { useEffect, useState } from "react";
import {
    Row,
    Col,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Table
} from 'reactstrap';
import { isEmpty } from 'lodash';
import { Link } from 'react-router-dom';
import { toast } from "react-toastify";


import { reportServices } from 'js/services';
import { ListLoader, PaginationComponent } from 'js/components/common';


export const PromoCodeModal = ( props ) => {
    const { is_open, hide, promo_code} = props;
    const [ loading, setLoading ] = useState(false);
    const [ promocode_users, setDetails] = useState('');

    useEffect(() => {
        if(promo_code)
        getPromoDetails()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const getPromoDetails = (params) => {
        setLoading(true)
        reportServices.promoCodeDetails(promo_code, params)
        .then(res => {
            setDetails(res);
            setLoading(false)
        }).catch(() => {
            toast.error('Failed to fetch promo code details')
        })
    }

    return (
        <div>
            <Modal isOpen={is_open} toggle={hide} className="modal-dialog-centered modal-lg">
                <ModalHeader toggle={hide}>{`Users Used ${promo_code}`}</ModalHeader>
                <ModalBody>
                    <div className="tt-admin-form">
                        <Row>
                            <Col xs="12">
                                <div className="table-wrap">
                                {loading ?
                                    <ListLoader />
                                    :
                                    promocode_users && isEmpty(promocode_users.data) ?
                                        <div className="no-records-found">
                                            <p>No Records Found.</p>
                                        </div>
                                        :
                                        <Table className="tt-admin-table-stroke">
                                            <tbody>
                                                {promocode_users.data?.map((item, index) => (
                                                    <tr key={index}>
                                                        <td>{item.full_name ? item.full_name : '-'}</td>
                                                        <td>{item.email ? item.email : '-'}</td>
                                                        <td>
                                                            {item.phone_country_code ? `+${item.phone_country_code} `: ''}{item.phone ? item.phone: ''}
                                                        </td>
                                                        <td>
                                                            <Link
                                                                to={`/admin/user-management/${item.user_id}`}
                                                                onClick={() => hide()}
                                                            >
                                                                <img src="/images/admin/global/edit_square.svg" alt="" />
                                                            </Link>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                }
                                </div>
                                {promocode_users?.meta &&
                                    <PaginationComponent page={promocode_users.meta} onChange={(page) => getPromoDetails({page})} />
                                }
                            </Col>
                        </Row>
                    </div>

                </ModalBody>
                <ModalFooter>
                    <Button color="primary" className="btn-outline-grey">Close</Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}
