import { request } from "js/helpers/api";

export const miscellService = {
    createReferrel,
    subscribe
};

function createReferrel(details){
    return request({
        method:'POST',
        url: 'referrals',
        param: details,
        content_type: 'json'
    });
}

function subscribe(details){
    return request({
        method:'POST',
        url: 'subscribe',
        param: details,
        content_type: 'json'
    });
}


