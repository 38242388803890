import React, { useState, useEffect } from "react";
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import dompurify from 'dompurify';

import { cmsServices } from 'js/services';

export const About = (props) => {
    const [ state, setState] = useState({
        content : {
            page_title :'',
            top_section : '',
            quote: '',
            bottom_section : ''
        }
    });
    const getStaticPage = () => {
        cmsServices.getStaticPage('about-us')
        .then((response) => {
            setState({ ...state, ...response })
        }).catch((err) => {
            toast.error('Failed to Load Page');
        });
    }
    useEffect(() => {
        getStaticPage();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const content = state.content;
    const sanitizer = dompurify.sanitize;
    return (
        <div>
            <Helmet>
                <title>About Us - Trips4Trade</title>
                <meta name="description" content="Trips4Trade is a community of outdoorsmen that are brought together by their enjoyment of the outdoors in one aspect or another." />
                <meta name="keywords" content="trips4Trade" />
            </Helmet>

            <div className="inner-banner" style={{backgroundImage: `url(images/web/global/banners/static-banner.png)`}}>
                <div className="container">
                    <Row>
                        <Col xs="12">
                            <h1 className="header2 color--white text-uppercase">{content.page_title}</h1>
                        </Col>

                    </Row>
                </div>
            </div>
            <div className="wider-data-frame wider-data-frame--grey wider-data-frame--about">
                <div className="container" dangerouslySetInnerHTML={{__html: sanitizer(content.top_section) }} ></div>
            </div>
            <div className="wider-data-frame wider-data-frame--grey wider-data-frame--strive">
                <div className="container">
                    <Row>
                        <Col>
                            <h3 className="header3 color--white mb-0">
                                <i className="mr-2"><img src="images/web/global/quote-start-lg.svg" alt="" /></i>{content.quote}<i className="ml-2"><img src="images/web/global/quote-end-lg.svg" alt="" /></i>
                            </h3>
                        </Col>
                    </Row>
                </div>
            </div>
            <div className="wider-data-frame wider-data-frame--grey wider-data-frame--about">
                <div className="container">
                    <Row>
                        <Col>
                            <p className="p" dangerouslySetInnerHTML={{__html: sanitizer(content.bottom_section) }} ></p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="d-flex justify-content-center">
                            <Link to={"/auth/signup"} className="btn btn-outline">START YOUR ADVENTURE</Link>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
}
