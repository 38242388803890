
import React, { useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import moment from 'moment';



export const Confirmation = (props) => {
    const [confirmation, setConfirmation] = useState({});

    useEffect(() => {
        setConfirmation(props.confirmation_data);
        window.scrollTo(0, 0)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.confirmation_data]);

    return (

        <Row>
            <Col xs="12">
                <div className="signup-tab-data-wrap">
                    <Row>
                        <Col xs="12">
                            <h3 className="header5">Confirmation</h3>
                            <p className="p p-0 text-left">Thank you for joining Trips4Trade! You will receive a receipt via email. Next, add a trip so that you can start trading.</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12" md="4">
                            <div className="confirmation-card">
                                <div className="corfirm-img"><img src="/images/web/auth/order.svg" alt="" /></div>
                                <div className="confirm-text">
                                    <h5>Order Number</h5>
                                    <h4>{confirmation.invoice && confirmation.invoice.invoice_no ? confirmation.invoice.invoice_no : ''}</h4>
                                </div>
                            </div>
                        </Col>
                        <Col xs="12" md="5">
                            <div className="confirmation-card">
                                <div className="corfirm-img"><img src="/images/web/auth/date.svg" alt="" /></div>
                                <div className="confirm-text">
                                    <h5>Date</h5>
                                    <h4>{confirmation.invoice && confirmation.invoice.created_at && confirmation.invoice.created_at ? moment(confirmation.invoice.created_at).format('LL') : ''}</h4>
                                </div>
                            </div>
                        </Col>
                        <Col xs="12" md="3">
                            <div className="confirmation-card">
                                <div className="corfirm-img"><img src="/images/web/auth/dollar.svg" alt="" /></div>
                                <div className="confirm-text">
                                    <h5>Total</h5>
                                    <h4>{confirmation.invoice && confirmation.invoice.total_price && confirmation.invoice.total_price ? `$${confirmation.invoice.total_price}` : ''}</h4>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="d-flex justify-content-center py-5">
                            <a className="btn btn-outline text-uppercase" href="/add-listing">Add a Trip</a>
                        </Col>
                    </Row>
                </div>
            </Col>
        </Row>
    )
}




