import React, { useEffect, useState } from "react";
import { Row, Col } from 'reactstrap';
import { Helmet } from "react-helmet";

import ContestSlider from "js/components/legos/contestSlider";
import { contestServices } from 'js/services';



export const ContestsGiveaways = () => {
    const [ contests, setConstants ] = useState({
        active : {
            mode : 'active',
            data : [],
            meta : {},
        },
        expired : {
            mode : 'expired',
            data : [],
            meta : {},
        },
    });

    const getContests = ( params, mode ) => {
        contestServices.getAll(params, mode)
        .then((response) => {
            setConstants(contests => ({
                ...contests,
                [mode] : {
                    ...contests[mode],
                    data : [ ...contests[mode].data, ...response.data ]
                }
            }));
        }).catch((err) => {
        });
    }
    const getActiveContests = ( ) => {
        getContests({ is_active : 1 },'active' );
    }
    const getInactiveContests = ( ) => {
        getContests({ is_active : 0 },'expired' )
    }

    useEffect(() => {
        getActiveContests();
        getInactiveContests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <Helmet>
                <title>CONTESTS & GIVEAWAYS - Trips4Trade</title>
                <meta name="description" content="CONTESTS & GIVEAWAYS Below are the current contest and giveaways that we are running. Click on the “Contest Details” button to see how you can win!  Free Hunt with Suburban Whitetail Contest Details" />
                <meta name="keywords" content="trip4trade, contests, giveaways" />
            </Helmet>

            <div className="inner-banner" style={{ backgroundImage: `url(/images/web/global/banners/static-banner.png)` }}>
                <div className="container">
                    <Row>
                        <Col xs="12">
                            <h1 className="header2 color--white text-uppercase">CONTESTS & GIVEAWAYS</h1>
                            <p className="header-text">Below are the contests and giveaways that we are running. Click on each block for more details and to see how to enter.</p>
                        </Col>

                    </Row>
                </div>
            </div>
            <div className="wider-data-frame">
                <div className="container">
                    <Row>
                        <Col>
                            <h3 className="header4 text-uppercase">Current</h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12" className="mx-0">
                            <ContestSlider { ...contests.active }  LoadMore={getActiveContests}  />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h3 className="header4 text-uppercase">Expired</h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12" className="mx-0">
                            <ContestSlider { ...contests.expired } LoadMore={getInactiveContests}  />
                        </Col>
                    </Row>
                </div>
            </div>

        </div>
    );
}
