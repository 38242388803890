import { Home } from "js/components/web/components/home/home";
import { About } from "js/components/web/components/about/about";
import { TermsConditions } from "js/components/web/components/terms-conditions/terms-conditions";
import { HowItWorks } from "js/components/web/components/how-it-works/how-it-works";
import { SupportConversation } from "js/components/web/components/support-conversation/support-conversation";
import { ContactUs } from "js/components/web/components/contact-us/contact-us";
import { Trips } from "js/components/web/components/trips/listing/listing";
import { Search } from "js/components/web/components/trips/search/search";
import { TripDetails } from "js/components/web/components/trips/details/details";
import { ProfileListing } from "js/components/web/components/profile/profile";

import { ViewListing } from "js/components/web/components/members/all-listings/all-listings";
import { MyProfile } from "js/components/web/components/members/my-profile/my-profile";
import { MyListings } from "js/components/web/components/members/my-listings/my-listings";
import { BookmarkedTrips } from "js/components/web/components/members/bookmarked-trips/bookmarked-trips";
import { EndorsementReferences } from "js/components/web/components/members/endorsement-references/endorsement-references";
import { Messages } from "js/components/web/components/members/messages/messages";
import { TradesInProgress } from "js/components/web/components/members/trades-in-progress/trades-in-progress";
import { InviteFriends } from "js/components/web/components/members/invite-friends/invite-friends";
import { BadgesAndPerks } from "js/components/web/components/members/badges-perks/badges-perks";
import { ReportIssues } from "js/components/web/components/members/report-issues/report-issues";
import { RequestNewTrips } from "js/components/web/components/members/request-new-trips/request-new-trips";
import { CredibilityAndAccountUpgrades } from "js/components/web/components/members/credibility-account-upgrades/credibility-account-upgrades";
import { AccountBillingPreferences } from "js/components/web/components/members/account-billing-preference/account-billing-preference";
import { ContestsGiveaways } from "js/components/web/components/contests-giveaways/listing/listing";
import { ContestsGiveawayDetails } from "js/components/web/components/contests-giveaways/details/details";
import { BlogHome } from "js/components/web/components/blog/listing/listing";
import { BlogDetail } from "js/components/web/components/blog/detail/detail";
import { AddListing } from "js/components/web/components/add-listing/add-listing";
import { PrivacyPolicy } from "js/components/web/components/privacy-policy/privacy-policy";
import { EditTrip } from 'js/components/web/components/members/edit-trip/edit-trip';
import { TradeConfirmation } from "js/components/web/components/trade-confirmation/trade-confirmation";


export const portalRoutes = [
    {
        path: "/add-listing",
        component: AddListing,
        needsAuth: true,
    },
    {
        path: "/experience/detail/:article",
        component: BlogDetail
    },
    {
        path: "/experience/tag/:tag",
        component: BlogHome
    },
    {
        path: "/experience/search/:search",
        component: BlogHome
    },
    {
        path: "/experience/:category",
        component: BlogHome
    },
    {
        path: "/experience",
        component: BlogHome
    },
    {
        path: "/contests-giveaways/details/:slug",
        component: ContestsGiveawayDetails
    },
    {
        path: "/contests-giveaways",
        component: ContestsGiveaways
    },
    {
        path: "/account-billing-preferences",
        component: AccountBillingPreferences,
        needsAuth: true,
    },
    {
        path: "/credibility-and-account-upgrades/verify-email",
        component: CredibilityAndAccountUpgrades,
        needsAuth: true,
    },
    {
        path: "/credibility-and-account-upgrades",
        component: CredibilityAndAccountUpgrades,
        needsAuth: true,
    },
    {
        path: "/request-new-trips",
        component: RequestNewTrips,
        needsAuth: true,
    },
    {
        path: "/report-issues",
        component: ReportIssues
    },
    {
        path: "/badges-perks",
        component: BadgesAndPerks,
        needsAuth: true,
    },
    {
        path: "/invite-friends",
        component: InviteFriends,
        needsAuth: true,
    },
    {
        path: "/trades-in-progress",
        component: TradesInProgress,
        needsAuth: true,
    },
    {
        path: "/messages/threads/:thread_id",
        component: Messages,
        needsAuth: true,
    },
    {
        path: "/messages/:user_id",
        component: Messages,
        needsAuth: true,
    },

    {
        path: "/messages",
        component: Messages,
        needsAuth: true,
    },
    {
        path: "/bookmarked-trips",
        component: BookmarkedTrips,
        needsAuth: true,
    },
    {
        path: "/endorsement-references",
        component: EndorsementReferences,
        needsAuth: true,
    },
    {
        path: "/my-listings",
        component: MyListings,
        needsAuth: true,
    },
    {
        path: "/my-profile",
        component: MyProfile,
        needsAuth: true,
    },
    {
        path: "/profile/:user_id/endorsements/:endorsement_id",
        component: ProfileListing,
        needsAuth: true,
    },
    {
        path: "/profile/:user_id/all-listings",
        component: ViewListing,
        needsAuth: true,
    },

    {
        path: "/profile/:user_id",
        component: ProfileListing
    },
    {
        path: "/trips/details/:slug",
        component: TripDetails,
        needsAuth: false,
    },
    {
        path: "/trips/edit/:slug",
        component: EditTrip,
        needsAuth: true,
    },
    {
        path: "/search/:category",
        component: Search
    },
    {
        path: "/search",
        component: Search
    },
    {
        path: "/trips/:category",
        component: Search,
    },
    {
        path: "/trips",
        component: Trips,
        needsAuth: false,
    },
    {
        path: "/contact-us",
        component: ContactUs
    },
    {
        path: "/support-conservation",
        component: SupportConversation
    },
    {
        path: "/how-it-works",
        component: HowItWorks,
        needsAuth: false
    },
    {
        path: "/terms-conditions",
        component: TermsConditions
    },
    {
        path: "/privacy-policy",
        component: PrivacyPolicy
    },
    {
        path: "/about-us",
        component: About
    },
    {
        path: "/trade-confirmation/:trip_trade_id",
        component: TradeConfirmation
    },
    {
        path: "/",
        component: Home
    }


];
