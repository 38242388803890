import React, { useState, useEffect, useContext } from "react";
import { Row, Col, Button, Table } from 'reactstrap';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import moment from 'moment';
import { isEmpty } from 'lodash';
import classnames from 'classnames';


import { reportServices } from 'js/services';
import { ListLoader, GraphComponent, PaginationComponent, DateFilterComponent } from 'js/components/common';
import { useParamChange, useModal } from 'js/hooks';
import { LookupContext } from 'js/contexts';


const styles = {
    option: (styles, state) => ({
        ...styles,
        backgroundColor: state.isSelected ? "#FFA405 " : state.isFocused ? "#FFA40520" : ""
    })
};


export const UsersReport = () => {

    const [loading, setLoading] = useState({report: false, promo_code: false});
    const [userReports, setUserReports] = useState({});
    // eslint-disable-next-line
    const [report_params, setParams] = useState({
        start_date: moment().subtract(7, 'days').format('YYYY-MM-DD'),
        end_date: moment().format('YYYY-MM-DD'),
    });
    const [ promo_codes, setPromoCodes ] =  useState({})

    const lookups = useContext(LookupContext);
    const [show] = useModal()

    const apiCalls = {
        getUserReoprts: (params) => {
            setLoading({...loading, report: false});
            reportServices.userReport(params).then((response) => {
                setUserReports(response);
                setLoading({...loading, report: false});
            }).catch((err) => {
                setLoading({...loading, report: false});
            });
        },
        getUserPromoCodes : (params) => {
            let promo_params = {
                ...params,
                ...report_params
            }
            setLoading({...loading, promo_code: true});
            reportServices.usersPromoCodes(promo_params)
            .then(res => {
                setPromoCodes(res)
                setLoading({...loading, promo_code: false});
            })
        }
    }

    // eslint-disable-next-line no-unused-vars
    const [params, onParamsChange] = useParamChange(apiCalls.getUserPromoCodes);

    useEffect(() => {
        apiCalls.getUserReoprts(report_params);
    //    apiCalls.getUserPromoCodes()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        apiCalls.getUserReoprts(report_params);
        apiCalls.getUserPromoCodes()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [report_params])

    const exportToExcel = () => {
        reportServices.exportToExcel(report_params, 'users').then((response) => {
            window.open(response);
        });
    }
    let filter_options = lookups.membership_entity_types
        ? lookups.membership_entity_types.concat( { label: 'All'} ): []
    return (
        <div>
            <div className="generic-page-header">
                <div className="header-data-section">
                    <h2 className="page-head my-0">Reports / Users</h2>
                </div>
                <div className="header-data-section">
                    <Button
                        color="secondary"
                        className="export-to-excel-btn mr-3"
                        onClick={() => exportToExcel()}
                    >
                        <i>
                            <svg width="19" height="17" viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M14.728 12.8206H11.9003L9.97152 10.0926L7.94478 12.8206H5.10477L8.62819 8.38936L5.78315 4.51387H8.6517L9.98439 6.62121L11.4044 4.51387H14.3233L11.3406 8.38936L14.728 12.8206ZM18.4585 16.6071H1.66699V0.570107H18.4585V16.6071ZM3.2801 14.994H16.8426V2.18433H3.2801" fill="#10C56E" />
                            </svg>
                        </i>Export to Excel
                    </Button>
                    <DateFilterComponent params={ report_params } onChange={ (data) => setParams(data)}/>
                </div>
            </div>

            <div className="tt-admin-card">
                <div className="tt-admin-card--header tt-admin-card--header__width-bg d-block">
                    <Row>
                        {
                            !isEmpty(userReports) &&
                            <Col xs="12">
                                <Link to={"#"} className="exchange-card-sm">
                                    <div className="exchange-label">Total <br className="d-none d-md-block" />Users</div>
                                    <div className="exchange-values">
                                        <h4>{userReports?.total_users}</h4>
                                        <p
                                            className={ classnames( "color--red", {
                                                'color--green': userReports?.total_users_percentage ?.[ 0 ] === '+'
                                            } ) }
                                        >
                                            {userReports?.total_users_percentage }
                                        </p>
                                    </div>
                                </Link>
                                <Link to={"#"} className="exchange-card-sm">
                                    <div className="exchange-label"># of Users with <br className="d-none d-md-block" />Trips Listed</div>
                                    <div className="exchange-values">
                                        <h4>{userReports?.users_with_trips_listed }</h4>
                                        <p
                                            className={ classnames( "color--red", {
                                                'color--green': userReports?.users_with_trips_percentage ?.[ 0 ] === '+'
                                            } ) }
                                        >
                                            {userReports?.users_with_trips_percentage}
                                        </p>
                                    </div>
                                </Link>
                                <Link to={"#"} className="exchange-card-sm">
                                    <div className="exchange-label">Renewel <br className="d-none d-md-block" />Rate</div>
                                    <div className="exchange-values">
                                        <h4>{userReports?.renewal_rate}</h4>
                                        <p
                                            className={ classnames( "color--red", {
                                                'color--green': userReports?.renewal_rate_percentage ?.[ 0 ] === '+'
                                            } ) }
                                        >
                                            {userReports?.renewal_rate_percentage}
                                        </p>
                                    </div>
                                </Link>
                                <Link to={"#"} className="exchange-card-sm">
                                    <div className="exchange-label">Active Users <br className="d-none d-md-block" />(Signed in Last Month)</div>
                                    <div className="exchange-values">
                                        <h4>{userReports?.active_users}</h4>
                                        <p
                                            className={ classnames( "color--red", {
                                                'color--green': userReports?.active_users_percentage?.[ 0 ] === '+'
                                            } ) }
                                        >
                                            {userReports?.active_users_percentage}
                                        </p>
                                    </div>
                                </Link>
                                <Link to={"#"} className="exchange-card-sm">
                                    <div className="exchange-label">Cancelled <br className="d-none d-md-block" />Accounts</div>
                                    <div className="exchange-values">
                                        <h4>{userReports?.cancelled_users}</h4>
                                        <p
                                            className={ classnames( "color--red", {
                                                'color--green': userReports?.cancelled_users_percentage ?.[ 0 ] === '+'
                                            } ) }
                                        >
                                            {userReports?.cancelled_users_percentage}
                                        </p>
                                    </div>
                                </Link>


                            </Col>

                        }

                    </Row>
                </div>
                <div className="tt-admin-card--data">
                    <div className="graph-wrap">
                        <div className="sweet-loading">
                            {
                                loading.report ?
                                    <ListLoader />
                                    :
                                    <div style={{ height: '374px', width: '100%' }}>
                                        <GraphComponent
                                            graph_data={userReports && userReports.graph_data}
                                            type='report_user'
                                            graph_type="bar"
                                        />
                                    </div>
                            }
                        </div>
                    </div>
                </div>
            </div>

            <div className="tt-admin-card mt-4">
                <div className="tt-admin-card--header justify-content-between">
                    <h4 className="tt-admin-card-subhead">Breakdown of Promo Codes Used</h4>
                    <div className="custom-react-select--no-border">
                        <Select
                            className="react-select-container"
                            classNamePrefix="react-select"
                            placeholder="Membership"
                            options={filter_options}
                            styles={styles}
                            onChange={ ( e ) => onParamsChange( 'type', e.value ) }
                            defaultValue={ { label:'All'}}
                        />
                    </div>
                </div>
                <div className="tt-admin-card--data">
                    <div className="table-wrap">
                    {loading.promo_code ?
                        <ListLoader />
                        :
                        promo_codes && isEmpty(promo_codes.data) ?
                            <div className="no-records-found">
                                <p>No Records Found.</p>
                            </div>
                            :
                            <Table responsive className="tt-admin-table-compact">
                                <thead>
                                    <tr>
                                        <th>Promo Code</th>
                                        <th className="text-center">Created at</th>
                                        <th className="text-center">Valid Until</th>
                                        <th className="sortable" onClick={() => onParamsChange('sort', 'user_count')}>Used</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        !isEmpty(promo_codes)  &&
                                        promo_codes.data.map((item, index) => (
                                            <tr key={index}>
                                                <td>{item.code ? item.code: '-'}</td>
                                                <td className="text-center">
                                                    {item.created_at ?
                                                        moment(item.created_at).format('MMM DD, YYYY - HH:MM A') : '-'
                                                    }
                                                </td>
                                                <td className="text-center">
                                                    {item.valid_until ?
                                                        moment(item.promotion.valid_until).format('MMM DD, YYYY - HH:MM A') : '-'
                                                    }
                                                </td>
                                                <td className="text-center">
                                                    <Link
                                                        to={"#"}
                                                        className="link"
                                                        onClick={() => show('PromoCodeModal', {
                                                            promo_code: item.code
                                                        })}
                                                    >
                                                    {item.user_count ? item.user_count : '-'}
                                                    </Link></td>
                                            </tr>

                                        ))
                                    }
                                </tbody>
                            </Table>


                    }
                    {promo_codes && promo_codes.meta &&
                        <PaginationComponent page={promo_codes.meta} onChange={(page) => onParamsChange('page', page)} />
                    }
                    </div>
                </div>
            </div>

        </div>


    );
}


