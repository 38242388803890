import React, { useEffect, useState } from "react";
import { Row, Col, TabContent, TabPane, Nav, NavItem, NavLink, Form, FormGroup, Input } from 'reactstrap';
import classnames from 'classnames';
import { isEmpty, forEach } from 'lodash';
import { TripList } from './trade-list';

import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment';
import { tradeServices } from 'js/services';
import { useParamChange, useModal } from 'js/hooks';


const localizer = momentLocalizer(moment);


export const Exchanges = () => {

    const [state, setState] = useState({
        activeTab: 'list',
        start_date: moment().startOf('month').format('YYYY-MM-DD'),
        end_date: moment().endOf('month').format('YYYY-MM-DD'),
    });

    const [show] = useModal();
    const [events, setEvents] = useState([]);


    const apiCalls = {
        getAll: (params) => {
            tradeServices.calendar(params).then((response) => {
                let events = [];
                if (!isEmpty(response)) {
                    forEach(response, (trade) => {
                        if (trade.trip) {
                            events.push({
                                ...trade.trip,
                                id: `app_${trade.trip.trip_id}`,
                                start: trade.arrival_time ? moment(trade.arrival_time, 'YYYY-MM-DD HH:mm:ss').toDate() : '',
                                end: trade.departure_time ? moment(trade.departure_time, 'YYYY-MM-DD HH:mm:ss').toDate() : '',
                                allDay: false,
                                title_display: <p className="rbc-event--appointment" >{trade.trip.title ? trade.trip.title : ''}</p>,
                                tooltip: trade.trip.title ? trade.trip.title : '',
                                trade_id: trade.trade_id ? trade.trade_id : '',
                            });
                        }
                    })
                }
                setEvents(events);
            }).catch((err) => {
            });
        },
    }
    // eslint-disable-next-line
    const [params, onParamsChange] = useParamChange(apiCalls.getAll);


    const toggle = (tab) => {
        if (state.activeTab !== tab) {
            setState({
                activeTab: tab
            });
        }
    }

    useEffect(() => {
        apiCalls.getAll(
            {
                start_date: state.start_date,
                end_date: state.end_date,
            }
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const eventView = (event) => {
        if (event.trade_id) {
            show('ExchangeDetailsModal', {
                trade_id: event.trade_id,
                callBack: () => {
                    apiCalls.getAll(
                        {
                            start_date: state.start_date,
                            end_date: state.end_date,
                        }
                    );
                }
            })
        }

    }

    const onRangeChange = (event) => {
        if (event.start && event.end) {
            let start_date = moment(event.start).format("YYYY-MM-DD");
            let end_date = moment(event.end).format("YYYY-MM-DD");
            setState({
                ...state,
                start_date,
                end_date
            })
            apiCalls.getAll({ start_date, end_date });
        }
        else if (event) {
            if (event.length > 1) {
                let start_date = moment(event[0]).format("YYYY-MM-DD");
                let end_date = moment(event[event.length - 1]).format("YYYY-MM-DD");
                setState({
                    ...state,
                    start_date,
                    end_date
                })
                apiCalls.getAll({ start_date, end_date });
            }
            else {
                let start_date = moment(event[0]).format("YYYY-MM-DD");
                let end_date = moment(event[0]).format("YYYY-MM-DD");
                setState({
                    ...state,
                    start_date,
                    end_date
                })
                apiCalls.getAll({ start_date, end_date });
            }
        }
    }

    return (
        <div>
            <div className="generic-page-header">
                <div className="header-data-section">
                    <h2 className="page-head my-0">Exhanges</h2>
                </div>
                <div className="header-data-section">
                    <Nav tabs className="tt-exchange-tabs">
                        <NavItem>
                            <NavLink className={classnames({ active: state.activeTab === 'calendar' })} onClick={() => { toggle('calendar'); }}>
                                <div className="tab-label">
                                    <i>
                                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M0.87207 12.3144C0.87207 12.9016 1.34772 13.3772 1.93486 13.3772H12.5634C13.1506 13.3772 13.6262 12.9016 13.6262 12.3144V3.01445C13.6262 2.42722 13.1506 1.95157 12.5634 1.95157H11.5005V1.28731C11.5005 0.920682 11.2029 0.623047 10.8363 0.623047C10.4696 0.623047 10.1719 0.920682 10.1719 1.28731V1.95157H9.1091V1.28731C9.1091 0.920682 8.81141 0.623047 8.44478 0.623047C8.07815 0.623047 7.78055 0.920682 7.78055 1.28731V1.95157H6.71775V1.28731C6.71775 0.920682 6.42012 0.623047 6.05349 0.623047C5.68678 0.623047 5.38915 0.920682 5.38915 1.28731V1.95157H4.32635V1.28731C4.32635 0.920682 4.02872 0.623047 3.66203 0.623047C3.29541 0.623047 2.99777 0.920682 2.99777 1.28731V1.95157H1.93489C1.34772 1.95157 0.8721 2.42722 0.8721 3.01445V12.3144H0.87207ZM2.78527 3.28012H2.99777V4.21011C2.99777 4.57679 3.29541 4.87442 3.66203 4.87442C4.02875 4.87442 4.32635 4.57679 4.32635 4.21011V3.28012H5.38915V4.21011C5.38915 4.57679 5.68678 4.87442 6.05349 4.87442C6.42012 4.87442 6.71776 4.57679 6.71776 4.21011V3.28012H7.78061V4.21011C7.78061 4.57679 8.07821 4.87442 8.44484 4.87442C8.81146 4.87442 9.10916 4.57679 9.10916 4.21011V3.28012H10.172V4.21011C10.172 4.57679 10.4697 4.87442 10.8363 4.87442C11.2029 4.87442 11.5005 4.57679 11.5005 4.21011V3.28012H11.7131C12.0372 3.28012 12.2977 3.54067 12.2977 3.86477V6.6812C12.2977 7.0053 12.0372 7.26586 11.7131 7.26586H2.78527C2.46105 7.26586 2.20062 7.0053 2.20062 6.6812V3.86477C2.20059 3.54067 2.46103 3.28012 2.78527 3.28012Z" fill="#A4ADB9" />
                                        </svg>
                                    </i> Calender
                                    </div>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={classnames({ active: state.activeTab === 'list' })} onClick={() => { toggle('list'); }}>
                                <div className="tab-label">
                                    <i>
                                        <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M2.05589 2.49249H1.94374C1.32693 2.49249 0.822266 1.98782 0.822266 1.37101C0.822266 0.698129 1.32693 0.193466 1.94374 0.193466H2.05589C2.6727 0.193466 3.23343 0.698129 3.23343 1.37101C3.23343 1.98782 2.72877 2.49249 2.05589 2.49249Z" fill="#A4ADB9" />
                                            <path d="M2.0563 6.36156H1.94415C1.32734 6.36156 0.766602 5.8569 0.766602 5.18402C0.766602 4.56721 1.27126 4.06255 1.94415 4.06255H2.0563C2.67311 4.06255 3.23384 4.56721 3.23384 5.18402C3.23384 5.8569 2.72918 6.36156 2.0563 6.36156Z" fill="#A4ADB9" />
                                            <path d="M2.0563 10.2306H1.94415C1.32734 10.2306 0.766602 9.72598 0.766602 9.0531C0.766602 8.43629 1.27126 7.87555 1.94415 7.87555H2.0563C2.67311 7.87555 3.23384 8.38021 3.23384 9.0531C3.23384 9.72598 2.72918 10.2306 2.0563 10.2306Z" fill="#A4ADB9" />
                                            <path d="M11.6447 2.49249H5.30841C4.63552 2.49249 4.13086 1.98782 4.13086 1.37101C4.13086 0.698129 4.63552 0.193466 5.30841 0.193466H11.7008C12.3176 0.193466 12.8783 0.698129 12.8783 1.37101C12.8223 1.98782 12.3176 2.49249 11.6447 2.49249Z" fill="#A4ADB9" />
                                            <path d="M11.6447 6.36156H5.30841C4.63552 6.36156 4.13086 5.8569 4.13086 5.18402C4.13086 4.56721 4.63552 4.06255 5.30841 4.06255H11.7008C12.3176 4.06255 12.8783 4.56721 12.8783 5.18402C12.8223 5.8569 12.3176 6.36156 11.6447 6.36156Z" fill="#A4ADB9" />
                                            <path d="M11.6447 10.2306H5.30841C4.63552 10.2306 4.13086 9.72598 4.13086 9.0531C4.13086 8.43629 4.63552 7.87555 5.30841 7.87555H11.7008C12.3176 7.87555 12.8783 8.38021 12.8783 9.0531C12.8223 9.72598 12.3176 10.2306 11.6447 10.2306Z" fill="#A4ADB9" />
                                        </svg>

                                    </i> Trip List</div>
                            </NavLink>
                        </NavItem>
                    </Nav>
                </div>
            </div>
            <TabContent activeTab={state.activeTab}>
                <TabPane tabId="calendar">
                    <div className="tt-admin-card">
                        <div className="tt-admin-card--header tt-admin-card--header_filter">
                            <div className="tt-filter">
                                <Form>
                                    <Row className="justify-content-between">
                                        <Col xs="12" lg="5">
                                            <Row>
                                                <Col xs="12" lg="6">
                                                    <FormGroup>
                                                        <div className="filter-input--outer">
                                                            <Input
                                                                type="text"
                                                                placeholder="Search by User"
                                                                onChange={(e) => onParamsChange('search', e.target.value)}
                                                            />
                                                        </div>

                                                    </FormGroup>

                                                </Col>

                                            </Row>
                                        </Col>
                                    </Row>
                                </Form>
                            </div>
                        </div>
                        <div className="tt-admin-card--data">
                            <Row>
                                <Col xs="12">
                                    <div className="rbc-calendar">
                                        <Calendar
                                            localizer={localizer}
                                            events={events}
                                            startAccessor="start"
                                            endAccessor="end"
                                            tooltipAccessor="tooltip"
                                            titleAccessor="title_display"
                                            showMultiDayTimes
                                            views={{
                                                month: true,
                                                week: true,
                                                day: true,
                                            }}
                                            onSelectEvent={event => eventView(event)}
                                            onRangeChange={(event) => onRangeChange(event)}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </TabPane>
                <TabPane tabId="list">
                    <TripList />
                </TabPane>
            </TabContent>
        </div>
    );
}

