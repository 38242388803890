import { request } from "js/helpers/api";

export const profileServices = {
    addEndorsment,
    updateEndorsements,

    addReference,

    sendVerification,
    sendEmailLink,
    verifyCode,
    verifyEmail,
    uploadIdProof
};

// function getEndorsements(params = {}){
//     return request({
//         method:'GET',
//         url: 'endorsements',
//         param: params,
//         is_authenticated: true
//     });
// }
function addEndorsment(params = {}, hideModal){
    return request({
        method:'POST',
        url: 'endorsements',
        param: params,
        is_authenticated: true,
        content_type: 'json',
        hideModal
    });
}


// function getReferences(params = {}){
//     return request({
//         method:'GET',
//         url: 'references',
//         param: params,
//         is_authenticated: true
//     });
// }
function addReference(params = {}, hideModal){
    return request({
        method:'POST',
        url: 'references',
        param: params,
        is_authenticated: true,
        content_type: 'json',
        hideModal
    });
}

function updateEndorsements(endorsement_id, details, hideModal){
    return request({
        method:'PUT',
        url: `endorsements/${endorsement_id}`,
        param: details,
        is_authenticated: true,
        content_type: 'json',
        hideModal
    });
}

function sendVerification(){
    return request({
        method:'GET',
        url: 'verify-phone/send-code',
        param: {},

    });
}

function verifyEmail(user_id, params){
    return request({
        method:'GET',
        url: `email/verify/${user_id}`,
        param: params,
    });
}

function verifyCode(details){
    return request({
        method:'POST',
        url: 'verify-phone/verify-code',
        param: details,
        content_type: 'json'
    });
}

function sendEmailLink(){
    return request({
        method:'GET',
        url: `email/send-verification`,
        param: {},
    });
}

function uploadIdProof(details){
    return request({
        method:'POST',
        url: 'user-document',
        param: details,
    });
}

