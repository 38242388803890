import { useState } from 'react';
import { debounce, isEmpty, forEach, omit } from 'lodash';

export function useParamChange(callBack) {
    const [params, setParam] = useState({});

    const onParamsChange = debounce((key, value, extra_params = {}) => {
        let current_params = { ...params }

        if (!isEmpty(extra_params)) {
            forEach(extra_params, (param, index) => {
                current_params[index] = param;
                if (isEmpty(param)) {
                    current_params = { ...omit(current_params, index) };
                }
            });
        }

        if( key === 'sort' && params.sort && params.sort === value ){
            value = `-${value}`;
        }
        current_params[key] = value ;
        value === '' && delete current_params[key];
        key !== 'page' &&  delete current_params['page'];
        setParam({ ...current_params });
        callBack && callBack(current_params);
    },300);

    return [ params, onParamsChange ];
}
