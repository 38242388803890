import React, { useContext, useState } from "react";
import { Row, Col, Form, FormGroup, Label, Input, CustomInput, Button, Spinner } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { AuthProvider } from 'js/contexts';

import { authServices } from 'js/services/auth-service';
import { history } from 'js/helpers';
import { configConstants } from 'js/constants';
import { isEmpty } from 'lodash';
import { ErrorFormFeedback } from 'js/components/common';
import { useEffect } from "react";



export const AuthLogin = () => {

    const { auth, setAuth } = useContext(AuthProvider);

    const [login_details, set_login] = useState({});
    const [auth_errors, set_auth_error] = useState({});
    const [loading, setLoading] = useState(false);

    const handleChange = (key, value) => {
        set_login({
            ...login_details, [key]: value
        });
    }

    const submit = (e) => {
        e.preventDefault();

        const details = { ...login_details };
            setLoading(true);
        authServices.login(details).then((response) => {
            setLoading(false);
            localStorage.setItem(configConstants.TOKEN_NAME, response.token);
            setAuth({ ...auth, is_logged_in: true, ...response });
            if(auth.previous_location) {
                history.push(auth.previous_location)
            }
            else {
                if (response.user_role === 'super-admin' || 'admin') {
                    history.push('/admin');
                }
                else {
                    history.push('/');
                }
            }

        }).catch((err) => {
            setLoading(false);
            let errors = {};
            if (err && err.data) {
                errors = err.data.errors;
            }
            set_auth_error(errors);
        });

    }
    useEffect(() => {
        setAuth({...auth, is_logged_in: false, user: {}})
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div>
            <Helmet>
                <title>Trip4Trade :: Login</title>
                <meta name="description" content="Trips4Trade Login" />
                <meta name="keywords" content="trip4trade, Login" />
            </Helmet>

            <div className="inner-banner inner-banner--auth">
                <div className="container">
                    <Row>
                        <Col xs="12">
                            <div className="tt-login-wrap">
                                <div className="tt-card">
                                    <div className="tt-login-head-wrap">
                                        <div className="tt-login-head-icon">
                                            <img src="/images/web/auth/tt-contact-icon.png" width="70px" height="70px" alt="" />
                                        </div>
                                        <div className="tt-login-head">
                                            <h3 className="tt-login-head--text">Login to Your Account</h3>
                                        </div>
                                    </div>
                                    <Form className="tt-login-form">
                                        <Row>
                                            <Col xs="12">
                                                <FormGroup>
                                                    <Label for="">Email Address</Label>
                                                    <Input
                                                        type="email"
                                                        name="email"
                                                        id=""
                                                        placeholder=""
                                                        onChange={(e) => handleChange(e.target.name, e.target.value)}
                                                        invalid={!isEmpty(auth_errors) && (auth_errors.email || auth_errors.general)
                                                            && true}
                                                    />
                                                    <ErrorFormFeedback field={'email'} errors={auth_errors} />
                                                </FormGroup>
                                            </Col>
                                            <Col xs="12">
                                                <FormGroup>
                                                    <Label for="">Password</Label>
                                                    <Input
                                                        type="password"
                                                        name="password"
                                                        id=""
                                                        placeholder=""
                                                        onChange={(e) => handleChange(e.target.name, e.target.value)}
                                                        invalid={!isEmpty(auth_errors) && (auth_errors.password || auth_errors.general)
                                                            && true
                                                        }
                                                        onKeyDown={(e) => e.key === 'Enter' && submit(e)}
                                                    />
                                                    <ErrorFormFeedback field={'password'} errors={auth_errors} />
                                                    <ErrorFormFeedback field={'general'} errors={auth_errors} />
                                                </FormGroup>
                                            </Col>
                                            <Col xs="12">
                                                <FormGroup>
                                                    <CustomInput type="checkbox" id="exampleCustomCheckbox" label="Remember Me" />
                                                </FormGroup>
                                            </Col>
                                            <Col xs="12" className="d-flex justify-content-center mb-3">
                                                <Button
                                                    className="btn btn-outline text-capitalize"
                                                    onClick={(e) => submit(e)}
                                                >
                                                    {
                                                        loading ? <Spinner size="sm"/> : 'Login Now'
                                                    }
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </div>
                                <div className="login-link-wrap">
                                    <p className="tt-login-foter--text">Don't have an account yet?  <Link to={'/auth/signup'}>Sign Up</Link></p>
                                    <p className="tt-login-foter--text"> <Link to={"/auth/reset-password"}>Forgot Password?</Link></p>
                                </div>
                            </div>


                        </Col>
                    </Row>
                </div>
            </div>

        </div>
    );
}
