import React, { useState, useEffect } from "react";
import { Row, Col, Button, Table, Form, FormGroup, CustomInput } from 'reactstrap';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { debounce,forEach, isEmpty } from 'lodash';
import { toast } from "react-toastify";

import { useParamChange, useModal } from 'js/hooks';
import { cmsServices } from 'js/services';
import { PaginationComponent, ListLoader } from 'js/components/common';


const bulk_actions = [
    { value: 'delete', label: 'Delete' }
];
const status_dropdown = [
    { value : 1, label : 'Active '},
    { value : 0, label : 'Inactive'},
];

const styles = {
    option: (styles, state) => ({
        ...styles,
        backgroundColor: state.isSelected ? "#FFA405 " : state.isFocused ? "#FFA40520" : ""
    })
};
export const PartnerLogosCMS = () => {

    const [ logos, setLogos ] = useState([]);
    const [ state, setState ] = useState({
        is_add_edit_modal_open: false,
        selected: null,
        checked : [],
        check_all: false,
        loading: false,
        loaded:false,
    });

    const getLogos = (params) => {
        !state.loaded && setState({...state, loading: true})
        cmsServices.getPartnerLogos(params)
        .then((response) => {
            setState({...state, loading: false, loaded : true })
            setLogos(response);
        }).catch((err) => {
            toast.error('Error Getting Partner Logs');
        });
    }

    const [ show, hide ] = useModal();
    const [ params, onParamsChange ] = useParamChange( getLogos );

    useEffect(() => {
        refresh();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const deleteLogo = ( selected ) => {
        if( selected ){
            cmsServices.deletePartnerLogo(selected.partner_logo_id).then(() => {
                refresh();
                hide('ConfirmModal');
            }).catch((err) => {
                toast.error('Error Deleting Logo');
            })
        }
    }
    const refresh = () => {
        getLogos(params);
    }

    const updateStatus = debounce(( item ) => {
        let data = { is_active : item.is_active === '1' ? 0 : 1 };
        cmsServices.updatePartnerLogo(item.partner_logo_id, data)
        .then((response) => {
            refresh();
        }).catch((error) => {
            toast.error('Failed To Update Logo');
        });
    },300);
    const checkbox = (id) => {
        let { checked } = state;
        if( id === 'all'){
            if( state.check_all ) {
                checked = [];
            }
            else{
                forEach(logos.data, function(item) {
                    checked[item.partner_logo_id] = true;
                });
            }
            setState( {...state, checked, check_all: !state.check_all } );
        }
        else{
            checked[id] ? delete checked[id] :  checked[id] = true;
            setState( {...state, checked} );
        }
    }

    const bulkAction = ( type ) => {
        if( type === 'delete' ){
            show('ConfirmModal', {
                title: 'Delete Multiple Partner Logos',
                body: 'Do you want to delete Multiple Partner Logos ?',
                button_name: 'Delete',
                action:  bulkDelete,
            });
        }
    }

    const bulkDelete = ( ) => {
        let data = {
            'action_type' : 'delete',
            'items' : Object.keys(state.checked)
        };
        cmsServices.partnerLogoBulkAction(data)
        .then((response) => {
            hide('ConfirmModal');
            getLogos(params);
            setState( {...state, checked  :[], check_all:false } );
        });
    }


    return (
        <div>
            <div className="generic-page-header">
                <div className="header-data-section">
                    <h2 className="page-head my-0">Partner Logos</h2>
                </div>
                <div className="header-data-section">
                    <Button
                        color="primary"
                        className="add-new-btn"
                        onClick={() =>
                            show('PartnerLogoAddEditModal',{
                                callBack: () => refresh(),
                                type: 'add',
                            })
                        }
                    >
                        <i>
                            <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.03027 7.77295H0.171875V5.6001H5.03027V0.619629H7.22754V5.6001H12.0981V7.77295H7.22754V12.729H5.03027V7.77295Z" fill="#fff" />
                            </svg>
                        </i>Add Logo
                    </Button>
                </div>
            </div>
            <div className="tt-admin-card">
                <div className="tt-admin-card--header tt-admin-card--header_filter">
                    <div className="tt-filter">
                        <Form>
                            <Row className="justify-content-between">
                                <Col xs="12" lg="5">
                                    <Row>
                                        <Col xs="12" lg="6">
                                            <FormGroup>
                                                <div className="custom-react-select--no-border w-100">
                                                    <Select
                                                        className="react-select-container"
                                                        classNamePrefix="react-select"
                                                        options={bulk_actions}
                                                        styles={styles}
                                                        placeholder="Bulk Actions"
                                                        onChange={ (e) => bulkAction( e.value )}
                                                    />
                                                </div>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs="12" lg="2">
                                    <Row>
                                        <Col xs="12">
                                            <FormGroup>
                                                <div className="custom-react-select--no-border w-100">
                                                    <Select
                                                        className="react-select-container"
                                                        classNamePrefix="react-select"
                                                        options={status_dropdown}
                                                        styles={styles}
                                                        onChange={ (e) => onParamsChange('status', e.value)}
                                                    />
                                                </div>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </div>
                <div className="tt-admin-card--data">
                    <div className="table-wrap">
                    {state.loading ?
                        <ListLoader/>
                        :
                        logos && isEmpty(logos.data) ?
                        <div className="no-records-found">
                            <p>No Records Found.</p>
                        </div>
                        :
                        <Table responsive className="tt-admin-table-compact tt-admin-table-compact--checkbox ">
                            <thead>
                                <tr>
                                    <th>
                                        <div className="tt-admin-checkbox">
                                            <CustomInput
                                                type="checkbox"
                                                id="tt-check-1"
                                                label=""
                                                className="tt-checkbox"
                                                checked={state.check_all}
                                                onChange={ () => checkbox('all') }
                                            />
                                        </div>
                                    </th>
                                    <th>Logo</th>
                                    <th>Name</th>
                                    <th className="text-left">External Link</th>
                                    <th className="sortable" onClick={ () => onParamsChange('sort', 'order')} >Order</th>
                                    <th className="sortable" onClick={ () => onParamsChange('sort', 'status')} >Status</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                { logos && logos.data && logos.data.map((item, index) => (
                                <tr key={index}>
                                    <td>
                                        <div className="tt-admin-checkbox">
                                            <CustomInput
                                                type="checkbox"
                                                id={`checkbox${item.partner_logo_id}`}
                                                label=""
                                                className="tt-checkbox"
                                                checked={state.checked[item.partner_logo_id] ? state.checked[item.partner_logo_id] : '' }
                                                onChange={ () => checkbox(item.partner_logo_id) }
                                            />
                                        </div>
                                    </td>
                                    <td>
                                        <img
                                            src={item.attachment ? item.attachment.thumbnail_url ? item.attachment.thumbnail_url : item.attachment.attachment_url : ''}
                                            alt={item.name}
                                            style={{ width: '100px'}}
                                        />
                                    </td>
                                    <td>{item.name}</td>
                                    <td className="text-left">{item.external_link}</td>
                                    <td>{item.order}</td>
                                    <td>
                                        <div className="ads-switch">
                                            <CustomInput
                                                type="switch"
                                                id={item.slug}
                                                checked={item.is_active === '1' ? true : false }
                                                onChange={ () => updateStatus(item)}
                                            />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="action-wrap">
                                            <Link
                                                to={"#"}
                                                onClick={() =>
                                                    show('PartnerLogoAddEditModal', {
                                                        callBack: () => refresh(),
                                                        state: item,
                                                        type: 'edit',
                                                    })
                                                }
                                            >
                                                <img src="/images/admin/global/edit.svg" alt="" />
                                            </Link>
                                            <Link
                                                to={"#"}
                                                onClick={() =>
                                                    show('ConfirmModal', {
                                                        title: 'Delete Footer Partner Logo',
                                                        body: 'Do you want to delete this Partner Logo ?',
                                                        button_name: 'Delete',
                                                        action: () => deleteLogo( item ),
                                                    })
                                                }
                                            >
                                                <img src="/images/admin/global/delete.svg" alt="" />
                                            </Link>
                                        </div>
                                    </td>
                                </tr>
                                ))}
                            </tbody>
                        </Table>
                    }
                    </div>
                </div>
                { logos && logos.meta &&
                    <PaginationComponent page={logos.meta} onChange={(page) => onParamsChange('page', page)} />
                }
            </div>
        </div>
    );
}
