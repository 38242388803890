import { request } from "js/helpers/api";

export const partnerAccountServices = {
    getAll,
    create,
    update,
    remove,
    get,
    bulkAction,
};

function getAll(params = {}){
    return request({
        method:'GET',
        url: 'partners',
        param: params,
    });
}

function create(details, hideModal){
    return request({
        method:'POST',
        url: 'partners',
        param: details,
        content_type: 'json',
        hideModal
    });
}

function update(details, partner_id, hideModal){
    return request({
        method:'PUT',
        url: `partners/${partner_id}`,
        param: details,
        content_type: 'json',
        hideModal
    });
}

function remove(partner_id){
    return request({
        method:'DELETE',
        url: `partners/${partner_id}`,
        param: {},
    });
}

function get(partner_id, hideModal){
    return request({
        method:'GET',
        url: `partners/${partner_id}`,
        param: {},
        hideModal
    });
}

function bulkAction(details){
    return request({
        method:'POST',
        url: `partners/bulk-action`,
        param: details,
        content_type: 'json',

    });
}
