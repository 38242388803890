import React, { useState, useEffect, useContext } from "react";
import { Row, Col, Input } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { isEmpty } from 'lodash';

import HeroCard from "js/components/legos/heroCard"
import SectionHeader from "js/components/legos/sectionHeader"
import TestimonialSlider from "js/components/legos/testimonialSlider"
import PartnerSlider from "js/components/legos/partnersSlider"
import { history } from 'js/helpers';
import { tripsServices, cmsServices } from "js/services";
import { ListLoader, GoogleMapWrapper, TripListing } from "js/components/common";
import { LookupContext } from 'js/contexts';

export const Home = (props) => {
    const [ trips, setTrips ] = useState({});
    const [ loading, setLoading ] = useState(false);
    const [ map_data, setMap ] = useState([]);
    const [ logos, setLogos ] = useState({});
    const lookups = useContext(LookupContext);

    useEffect(() => {
        getAllTrips();
        getMapData();
        getLogos({ show_all: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const inputChange = (e) => {
        if(e.key === 'Enter') {
            e.preventDefault();
            history.push({
                pathname: '/search',
                search: e.target.value
            })
        }
    }

    const getAllTrips = () => {
        setLoading(true);
        tripsServices.getAll({visibility: 'public'}).then(response => {
            setLoading(false)
            response.data = getRandomAd(response.data, response.ads)
            setTrips(response)

        }).catch(error => {
            console.log(error)
        })
    }
    const getMapData = () => {
        tripsServices.getMapData()
        .then(response => {
            setMap(response)
        })
    }

    const getLogos = (params) => {
        cmsServices.getPartnerLogos(params)
            .then((response) => {
                setLogos(response);
            });
    }

    function getRandomAd(array, element){
        // first select a random position
        var random_pos = Math.round(Math.random() * array.length);
        let new_array = array.map(item => {
            return{...item, type: 'trip'}
        })
        if(!isEmpty(element) && !isEmpty(element[0])) {
            new_array.splice(random_pos, 0,  {...element[0], type: 'ad'})
        }
        return new_array

    };

    return (
        <div>
            <Helmet>
                <title>Trips4Trade - Trade For The Trip Of A Lifetime</title>
                <meta name="description" content="Trade For The Trip Of A Lifetime. Trips4Trade allows users to trade trips pertaining to outdoors, adventure, vacation, and sports." />
            </Helmet>

            <div className="hero-banner hero-banner--home">
                <div className="container">
                    <Row>
                        <Col xs="12" className="text-center">
                            <h1 className="header1 color--white">Trade For The Experience Of A Lifetime</h1>
                        </Col>
                        <Col className="d-flex justify-content-center">
                            <div className="search-xl">
                                <Input type="search" placeholder="Find Your Next Bucket List Trip Here..." onKeyDown={(e) => inputChange(e)}/>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        { lookups.trip_categories && lookups.trip_categories.map( ( item, index ) => (
                            index < 4 &&
                            <Col xs="6" md="6" lg="3" key={index}>
                                <HeroCard
                                    cardImage={item.featured_image_url}
                                    cardIcon={item.icon_image_url}
                                    tripName={item.label}
                                    linkName={`/trips/${item.slug}`}
                                />
                            </Col>
                        ))}
                    </Row>
                </div>

            </div>
            <div className="wider-data-frame wider-data-frame--sm">
                <div className="container">
                    <Row>
                        <Col xs="12" md={{ size: 10, offset: 1 }}>
                            <Row>

                                <Col sm="4" md="4">
                                    <div className="infographics">
                                        <div className="twisted-square"><div>{lookups.trip_countries_count}</div></div>
                                        <h4 className="infographics-head">Countries</h4>
                                    </div>
                                </Col>
                                <Col sm="4"  md="4">
                                    <div className="infographics">
                                        <div className="twisted-square"><div>{lookups.trip_states_count}</div></div>
                                        <h4 className="infographics-head">States</h4>
                                    </div>
                                </Col>
                                <Col sm="4"  md="4">
                                    <div className="infographics">
                                        <div className="twisted-square"><div><img src="images/web/global/add.svg" alt="" /></div></div>
                                        <h4 className="infographics-head">Growing Everyday</h4>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </div>
            <div className="wider-data-frame wider-data-frame--grey">
                <div className="container">
                    <Row>
                        <Col xs="12" md="6" lg="7" className="order-1">
                            <div className="intro-section">
                                <h3 className="header3">SPEND LESS. EXPERIENCE MORE.</h3>
                                <p>
                                    Trips4Trade allows users to travel at a fraction of the traditional cost by trading trips pertaining to outdoors, adventure, vacation, and sports.
                                    This includes hunting, fishing, skiing, surfing, condos, cabins, sports tickets, box seats, and much more. Simply list a trip you have to trade and
                                    exchange it for a trip you want to go on. By using Trips4Trade, you can save hundreds if not thousands on the trips you take just by exchanging what you already have.
                                    You can go on as many swaps as you want each year.</p>
                                <div>
                                    <Link to={"/add-listing"} className="btn btn-outline mr-3">START YOUR ADVENTURE</Link>
                                    {/* TODO_HERE */}
                                    {/* <Link to={"#"} className="btn btn-outline">VIEW PRICING</Link> */}
                                </div>
                            </div>
                        </Col>
                        <Col xs="12" md="6" lg="5" className="order-0">
                            <div className="spend-less">
                                <Link to={"#"}><img src="images/web/global/video-play.svg" alt="" /></Link>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
            <div className="wider-data-frame">
                <div className="container">
                    <Row>
                        <SectionHeader
                            HeaderText="How it Works"
                        />
                    </Row>
                    <Row>
                        <Col xs="12" md="4">
                            <div className="how-it-works-card first-child">
                                <div className="how-it-works-card--box">
                                    <img src="images/web/home/add-trip.svg" alt="" />

                                </div>
                                <h5>1</h5>
                                <h4 className="header4">Add a trip that you are <br className="d-none d-md-block" />willing to trade</h4>
                            </div>
                        </Col>
                        <Col xs="12" md="4">
                            <div className="how-it-works-card">
                                <div className="how-it-works-card--box">
                                    <img src="images/web/home/discover.svg" alt="" />

                                </div>
                                <h5>2</h5>
                                <h4 className="header4">Discover a trip that <br className="d-none d-md-block" />interests you</h4>
                            </div>
                        </Col>
                        <Col xs="12" md="4">
                            <div className="how-it-works-card">
                                <div className="how-it-works-card--box">
                                    <img src="images/web/home/trade.svg" alt="" />

                                </div>
                                <h5>3</h5>
                                <h4 className="header4">Trade your trip directly <br className="d-none d-md-block" />for theirs</h4>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12" className="d-flex justify-content-center">
                            <Link to={"/how-it-works"} className="btn btn-outline">Learn More</Link>
                        </Col>
                    </Row>
                </div>


            </div>
            <div className="wider-data-frame wider-data-frame--grey pb-0">
                <div className="container">
                    <Row>
                        <SectionHeader
                            HeaderText="Featured Experiences"
                        />
                    </Row>
                    {loading ?
                        <ListLoader/>
                        :
                        <>
                        <TripListing
                            trips={trips}
                            onUpdate ={ ( updated_trips ) => setTrips( trips=> ({...trips, data :updated_trips})) }
                            hide_load_mode={true}
                        />
                        </>
                    }
                    <Row>
                        <Col xs="12" className="d-flex justify-content-center mt-5 pb-1">
                            <Link to={"/trips"} className="btn btn-outline">Explore More</Link>
                        </Col>
                    </Row>
                </div>
            </div>
            <div className="wider-data-frame wider-data-frame--grey  pb-0">
                <div className="container-fluid px-0">
                    <Row className="no-gutters">
                        <SectionHeader
                            HeaderText="Explore by Location"
                        />
                    </Row>
                    <Row className="no-gutters">
                        <Col className="mt-4 map-outer">
                            <div style={{ height: '552px', width: '100%' }}>
                                {/* <GoogleMapReact defaultCenter={
                                    {lat:59.955413,
                                        lng:30.337844}
                                }   defaultZoom={15} >
                                    <AnyReactComponent
                                        lat={59.955413}
                                        lng={30.337844}
                                        text="My Marker"
                                    />
                                </GoogleMapReact> */}
                                {!isEmpty(map_data) &&
                                <GoogleMapWrapper data={map_data} />
                                }
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
            <div className="wider-data-frame wider-data-frame--grey">
                <div className="container">
                    <Row>
                        <SectionHeader
                            HeaderText="What Others are Saying"
                        />
                    </Row>
                    <Row>
                        <Col xs="12" md="12" lg={{ size: 8, offset: 2 }}>
                            <TestimonialSlider />
                        </Col>
                    </Row>
                </div>
            </div>
            <div className="wider-data-frame">
                <div className="container">
                    <Row>
                        <SectionHeader
                            HeaderText="Our Partners"
                        />
                    </Row>
                    {/* TODO_HERE */}
                    <Row>
                        <Col>
                            <PartnerSlider logos={ logos } />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12" className="d-flex justify-content-center">
                            <Link to={"/support-conservation"} className="btn btn-outline">Support Conservation</Link>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>


    );
}
