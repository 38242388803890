import React from "react"
import "js/components/legos/styles/user-badge-module.css"


const UserBadges = (props) => {
    const { data } = props;

    return (
        <ul className="user-badges-outer">
            <li>
                <div className="badge-label">
                    <img src="/images/web/global/profile/response.svg" alt="" />
                    <span>Response Rate</span>
                </div>
                <div className="status">
                    <span>{data && data.response_rate ? `${data.response_rate}%` : '0'}</span>
                </div>
            </li>
            <li>
                <div className="badge-label">
                    <img src="/images/web/global/profile/exchange.svg" alt="" />
                    <span># of Exchanges</span>
                </div>
                <div className="status">
                    <span>{data && data.exchange_count ? data.exchange_count : '0'}</span>
                </div>
            </li>
            <li>
                <div className="badge-label">
                    <img src="/images/web/global/profile/endorsements.svg" alt="" />
                    <span>Endorsements</span>
                </div>
                <div className="status">
                    <span>{data && data.endorsement_count ? data.endorsement_count : '0'}</span>
                </div>
            </li>
        </ul>


    );
}

export default UserBadges;
