import React, { useState, useContext } from "react";
import {Row, Col, Button, Form, FormGroup, Input, Label, Modal, ModalHeader, ModalBody, Spinner} from 'reactstrap';
import { isEmpty, forEach } from 'lodash';

import { profileServices } from 'js/services';
import { ErrorFormFeedback, NumberInputComponent } from 'js/components/common';
import { LookupContext } from 'js/contexts';
import { toast } from "react-toastify";

export const EndorsmentModal = (props) => {

    const { is_open, hide, callBack } = props;
    const [ state, setState] = useState({
        full_name: '',
        email: '',
        phone: '',
    });
    const [ loading, setLoading ] = useState();
    const [ error, setError ] = useState();
    const lookups = useContext(LookupContext);

    const handleChange = ( value, key ) => {
        setState({ ...state, [key] : value });
    }

    const submit = () => {
        setLoading(true);
        let endorsement_status_id = '';
        forEach(lookups.endorsement_statuses, value => {
            if(value.slug === 'requested') {
                endorsement_status_id = value.endorsement_status_id
            }
        })
        let details = { ...state, endorsement_status_id}
        profileServices.addEndorsment(details, hide)
        .then((response) => {
            setError({});
            toast.success('Endorsement has been added');
            setLoading(false);
            callBack('EndorsmentModal');
        }).catch((error) => {
            setError(error.data && error.data.errors);
            setLoading(false);
        });
    }
    return (
        <div>
            <Modal
                size='lg'
                isOpen={is_open}
                toggle={() => hide()}
                className="tt-modal--profile modal-dialog-centered"
            >
                    <ModalHeader toggle={() => hide()}>Request Endorsement</ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col xs="12">
                                <p className="">We will send your custom Endorsement Link to your friends along with a 10% Off promo code for their first annual membership with Trips4Trade. Just tell us where to send it.</p>
                            </Col>
                        </Row>
                        <Form className="profile-form">
                            <Row>
                                <Col xs="12" md="4">
                                    <FormGroup>
                                        <Label for="" >Name</Label>
                                        <Input
                                            type="text"
                                            value={state.full_name}
                                            onChange={  (e) => handleChange(e.target.value, 'full_name')}
                                            invalid={ !isEmpty(error) && (error.full_name) && true }
                                        />
                                        <ErrorFormFeedback field={'full_name'} errors={error} />
                                    </FormGroup>
                                </Col>
                                <Col xs="12" md="4">
                                    <FormGroup>
                                        <Label for="" >Email Address</Label>
                                        <Input
                                            type="email"
                                            value={state.email}
                                            onChange={  (e) => handleChange(e.target.value, 'email')}
                                            invalid={ !isEmpty(error) && (error.email) && true }
                                        />
                                        <ErrorFormFeedback field={'email'} errors={error} />
                                    </FormGroup>
                                </Col>
                                <Col xs="12" md="4">
                                    <FormGroup>
                                        <Label for="" >Phone</Label>
                                        <NumberInputComponent
                                            phone= {`${state.phone}`}
                                            onChange={(e) => handleChange(`+${e.phone_country_code}${e.phone}`, 'phone')}
                                        />
                                        <ErrorFormFeedback field={'phone'} errors={error} is_invalid={true}/>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Form>
                        <div className="d-flex justify-content-center py-4">
                            <Button
                                className="btn-outline text-capitalize"
                                onClick={submit}
                                disabled={loading}
                            >
                            { loading ? <Spinner color="#ffa405" size="sm" /> : 'Send Request' }
                            </Button>
                        </div>
                    </ModalBody>
                </Modal>
        </div>
    );
}
