import React, { useState, useEffect } from 'react';
import { BarLoader } from "react-spinners";
import { membershipRewardServices, userServices } from 'js/services';
import { Link } from 'react-router-dom';
import { Table, Spinner } from 'reactstrap';
import { forEach, isEmpty } from 'lodash';
import classnames from 'classnames';
import { toast } from 'react-toastify';

export const BadgesComponent = (props) => {

    const [badges, setBadges] = useState({});
    const [badge_width, setBadgeWidth] = useState(100);
    const [loading, setLoading] = useState({rewards: false, assign: false});
    const [state, setState] = useState({
        membership_rewards_hash: {}
    });

    const image_name = {
        'premium-member': 'premium.png',
        'lifetime-member': 'lifetime.png',
        'annual-trader': 'annual-trader.png',
        'frequent-trader': 'frequent-trader.png',
        'trusted-trader': 'trusted-trader.png',
        'ambassador': 'ambassador.png',
        'perfect-profile': 'perfect-profile.png',
    }

    const apiCalls = {
        getAll: () => {
            let membership_badges = [];
            let activity_badges = [];
            let perks = [];

            setLoading({...loading, rewards: true});
            membershipRewardServices.getAll().then((response) => {
                setLoading({...loading, rewards: false});
                forEach(response, (item) => {
                    if (item.membership_reward_type && item.membership_reward_type.slug === 'membership-badge') {
                        membership_badges.push(item);
                    }
                    else if (item.membership_reward_type && item.membership_reward_type.slug === 'activity-badge') {
                        activity_badges.push(item);
                    }
                    else {
                        perks.push(item);
                    }
                });
                setBadges({
                    membership_badges,
                    activity_badges,
                    perks
                });
                let max_badge = Math.max(membership_badges.length, activity_badges.length, perks.length);
                setBadgeWidth(100 / max_badge);

            })
                .catch((err) => {
                    setLoading(false);
                })
        },
        badgeStatus: () => {
            let membership_rewards_hash = {};
            membershipRewardServices.badgeStatus(props.user_id).then((response) => {
                if(!isEmpty(response)){
                    forEach(response, (value, key) => {
                        setLoading({...loading, assign: false});
                        membership_rewards_hash[key] = value === 0 ? false : true;
                    });
                    setState({
                        ...state,
                        membership_rewards_hash
                    });
                }
            });
        },
        assignPerk: (reward) => {
            setLoading({...loading, assign: true});
            userServices.assignPerk(props.user_id, reward)
            .then(res => {
                apiCalls.badgeStatus()
            }).catch(err => {
                setLoading({...loading, assign: false});
                toast.error('Failed to assign perk')
            })
        }
    }


    useEffect(() => {
        apiCalls.getAll();
        apiCalls.badgeStatus();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.user_id]);

    return (
        <div className="table-wrap">
            {
                loading.rewards ?
                    <BarLoader />
                    :
                    <Table responsive className="tt-admin-table-badge">
                        <tbody>
                            <tr>
                                {
                                    badges && !isEmpty(badges.membership_badges) &&
                                    badges.membership_badges.map((item, index) => (
                                        <td key={index + 'membership_badges'}>
                                            <div
                                                className={classnames('badge',
                                                    {
                                                        earned: state.membership_rewards_hash[item.slug] ?
                                                            state.membership_rewards_hash[item.slug] : false
                                                    })
                                                }
                                            >
                                                <img src={`/images/admin/user-management/${image_name[item.slug]}`} alt="" /> {item.label ? item.label : ''}
                                            </div>
                                        </td>
                                    ))
                                }
                            </tr>
                            <tr>
                                {
                                    badges && !isEmpty(badges.activity_badges) &&
                                    badges.activity_badges.map((item, index) => (
                                        <td key={index + 'activity_badges'}>
                                            <div
                                                className={classnames('badge',
                                                    {
                                                        earned: state.membership_rewards_hash[item.slug] ?
                                                            state.membership_rewards_hash[item.slug] : false
                                                    })
                                                }
                                            >

                                                <img src={`/images/admin/user-management/${image_name[item.slug]}`} alt="" /> {item.label ? item.label : ''}
                                            </div>
                                        </td>
                                    ))
                                }
                            </tr>
                            <tr>
                                {
                                    badges && !isEmpty(badges.perks) &&
                                    badges.perks.map((item, index) => (
                                        <td style={{ width: badge_width + '%' }} key={index + 'perks'}>
                                            <div
                                                className={classnames('badge',
                                                    {
                                                        earned: state.membership_rewards_hash[item.slug] ?
                                                            state.membership_rewards_hash[item.slug] : false
                                                    })
                                                }
                                            >
                                                <img src={`/images/admin/user-management/${image_name[item.slug]}`} alt="" /> {item.label ? item.label : ''}
                                            </div>
                                            {
                                                item.is_admin_assignable ?
                                                    <Link
                                                        to={"#"}
                                                        onClick={() => {
                                                            apiCalls.assignPerk(item.membership_reward_id);
                                                        }}
                                                    >
                                                        {loading.assign? <Spinner size="sm"/> :
                                                            state.membership_rewards_hash[item.slug] ? 'Withdraw' : 'Assign'
                                                        }
                                                    </Link>
                                                : ''
                                            }

                                        </td>
                                    ))
                                }
                            </tr>
                        </tbody>
                    </Table>
            }
        </div>
    )
}
