import React, { useState, useEffect } from 'react';
import {
    Row,
    Col,
    Button,
    Form,
    FormGroup,
    Label,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Input,
    Spinner
} from 'reactstrap';

import { isEmpty, debounce, pick } from "lodash";
import { ErrorFormFeedback, ListLoader } from 'js/components/common';
import { toast } from "react-toastify";

import { supportEmailServices } from 'js/services';


export const EditSupportEmail = (props) => {

    let [state, setState] = useState({});
    const [createErrors, setErrors] = useState({});
    const { is_open, hide, support_email_id, callBack } = props;
    const [loading, setLoading] = useState({
        modal_loading: false,
        button_loading: false
    });

    const apiCalls = {
        update: debounce((details) => {
            setLoading((loading) => ({
                ...loading,
                button_loading: true
            }));
            supportEmailServices.update(details, support_email_id,hide)
                .then(() => {
                    toast.success('Updated Successfully');
                    hide();
                    callBack();
                    setLoading((loading) => ({
                        ...loading,
                        button_loading: false
                    }));
                })
                .catch((err) => {
                    let errors = err.data && err.data.errors;
                    setErrors(errors);
                    setLoading((loading) => ({
                        ...loading,
                        button_loading: false
                    }));
                });
        }, 300),
        get: debounce((support_email_id) => {
            setLoading((loading) => ({
                ...loading,
                modal_loading: true
            }));
            supportEmailServices.get(support_email_id, hide)
                .then((response) => {
                    setLoading((loading) => ({
                        ...loading,
                        modal_loading: false
                    }));
                    let selected_data = pick(response, 'content');
                    setState(selected_data);
                })
                .catch((err) => {
                    setLoading((loading) => ({
                        ...loading,
                        modal_loading: false
                    }));
                    let errors = err.data && err.data.errors;
                    setErrors(errors);
                });
        }, 300),

    }

    useEffect(() => {
        if (props && props.support_email_id) {
            apiCalls.get(props.support_email_id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const submit = () => {
        if(props.support_email_id){
            apiCalls.update({...state }, support_email_id);
        }
    }

    return (
        <React.Fragment>
            <Modal
                isOpen={is_open}
                toggle={() => hide()}
                className="tt-modal--admin modal-dialog-centered"
            >
                <ModalHeader toggle={() => hide()}>Edit Support Email</ModalHeader>
                <ModalBody>
                    {
                        loading['modal_loading'] ? 
                            <ListLoader />
                            :
                            <Form className="tt-admin-form">
                            <div className="tt-admin-form-inner p-0">
                                <Row form>
                                    <Col xs="12">
                                        <FormGroup>
                                            <Label for="">Email Content</Label>
                                            <Input
                                                type="textarea"
                                                name="content"
                                                onChange={(e) => setState({ ...state, [e.target.name]: e.target.value })}
                                                value={state.content ? state.content : ''}
                                                invalid={!isEmpty(createErrors) && (createErrors.content || createErrors.scheduled_at)
                                                    && true
                                                }
                                                readOnly={props.status && props.status==='sent'}
                                            />
                                            <ErrorFormFeedback field={'content'} errors={createErrors} />
                                            <ErrorFormFeedback field={'scheduled_at'} errors={createErrors} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </div>
                        </Form>
                    }

                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        className="btn-outline-grey"
                        onClick={() => hide()}
                        disabled={loading['button_loading'] || loading['modal_loading']}
                    >Cancel
                    </Button>
                    <Button
                        color="primary"
                        className="add-new-btn"
                        onClick={() => submit()}
                        disabled={loading['button_loading'] || loading['modal_loading']}
                    >
                        {loading['button_loading'] ?
                            <Spinner size="sm" /> : 'Update'
                        }
                    </Button>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    );
}
