import React from "react"
import Slider from "react-slick";
import moment from 'moment';

import ContestCard from "../legos/contestCard"
import "js/components/legos/styles/contest-slider-module.css"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ContestSlider = (props) => {

    const loadMore = ( current_slider) => {
        if( props.meta && props.meta.to && current_slider === props.meta.to ){
            props.LoadMore && props.LoadMore();
        }
    }
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        centerMode: false,
        arrows: true,
        className: 'contest-slider',
        afterChange :loadMore,
        responsive : [
            {
                breakpoint: 1199.98,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 991.98,
                settings: {
                    slidesToShow: 1
                }
            },
            {
                breakpoint: 575.98,
                settings: {
                    slidesToShow: 1,
                    arrows: false
                }
            }
        ]
    };
    return (
        <div>

            <Slider {...settings}>
                { props.data && props.data.map ( (item, index ) => (
                <div key={index}>
                    <ContestCard
                        contestURL={`/contests-giveaways/details/${item.slug}`}
                        contestValue={`$${item.value}`}
                        contestName={item.title}
                        memberType={item.type_label}
                        contestDate={item.scheduled_at ? moment(item.scheduled_at).format("MMMM DD, YYYY") : '' }
                        contestCardImage={item.promotional_campaign_media && item.promotional_campaign_media.length > 0 ? item.promotional_campaign_media[0].attachment.attachment_url: '' }
                        contestType={item.promotional_campaign_type ? item.promotional_campaign_type.label : ''}
                    />
                </div>
                ))}

            </Slider>
        </div>
    );
}
export default ContestSlider;
