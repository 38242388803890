import React from "react";

import "js/components/legos/styles/reference-card-module.css"

export default props => (
	<div className="reference-card">
        <div className="reference-img">
            <img src={props.refImage} alt="" />
        </div>    
        <div className="reference-data">
            <h5>{props.refUser}</h5>
            <p>{props.refEmail}</p>
        </div>


    </div>





         
    
)